import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const issueLogUrl = baseUrl + 'issuelog';
const feedbackUrl = baseUrl + 'feedback';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Feedback'] && userModules.mod_feats['Feedback'].features ? userModules.mod_feats['Feedback'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http: HttpClient) { }

  getIssueLogDetails(offset,limit,search) {
    return this.http.get('assets/samples/issue-log.json');
  }

  createIssueLog(obj) {
    return this.http.post('issueLogUrl', obj);
  }
  addFeedBack(obj:any){
    return this.http.post(feedbackUrl, obj);
  }

  getFeedbackFeature(){
    const headerDict = {
      feature
    };
    return this.http.get(feedbackUrl, { headers: headerDict });
  }
}
