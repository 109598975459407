import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-idtreceipt-invoice-info-dialog',
  templateUrl: './idtreceipt-invoice-info-dialog.component.html',
  styleUrls: ['./idtreceipt-invoice-info-dialog.component.scss']
})
export class IDTReceiptInvoiceInfoDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'no',
    'invoiceNumber',
    'productCode',
    'brandName',
    'quantity',
    'unitCost',
    'totalCost'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  constructor(
    public dialogRef: MatDialogRef<IDTReceiptInvoiceInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA)public data
  ) { }

  ngOnInit(): void {
    console.log(this.data, '26:::');
    this.dataSource = new MatTableDataSource(this.data);
  }

  dialogClose(): void{
    this.dialogRef.close();
  }

}
