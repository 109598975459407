import { ArrayDataSource } from '@angular/cdk/collections';
import { Inject, OnChanges } from '@angular/core';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { Packing } from 'src/app/core/models/packing/packing-feature.model';
import { CommonsService } from 'src/app/core/services/commons.service';
import { PackingService } from 'src/app/core/services/packing.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
const parsedUrl = new URL(window.location.href);
const baseUrl = parsedUrl.origin;
@Component({
  selector: 'app-import-packing-action-dialog',
  templateUrl: './import-packing-action-dialog.component.html',
  styleUrls: ['./import-packing-action-dialog.component.scss']
})
export class ImportPackingActionDialogComponent implements OnInit, OnChanges {

  @Input() writeAccess: boolean;
  @Input() actions: string[];
  @Input() featuresData: any;

  displayedColumns: string[] = [
    'sNo',
    'ofsCode',
    'product',
    'brandName',
    'sizeInMl',
    'unitsPerCase',
    'packType',
    'mrp',
    'batchNo',
    'batchQty',
    'packedQty',
    'breakageQty',
    'productionCases'
  ];

  dataSource: MatTableDataSource<Packing> = new MatTableDataSource([]);
  importedPackingForm: FormGroup;
  error: string;
  isEditable: boolean = false;
  packingLength: number;
  saveDisabled: boolean;
  printers = ['Desktop Printer', 'Network Printer'];
  // productions = ['Manual', 'Semi Manual', 'Automation'];
  productions = ['Manual','Automation'];
  newEdit: string = 'New';
  enableBatch = [];
  productDetails:any;
  selectedProductIndex: number = -1;
  packedQty:string;
  fieldError:any=[];
  isValidate: any = false;
  isImportProd:any=false;
  selectedConsIndex:any;

  @ViewChild('input') input: ElementRef;
  spoolBarCodesForm: FormGroup;
  sHForm: FormGroup;
  manualHealForm: FormGroup;
  limitMessage='Case Codes count should not be more than Units per case';
  showError:Boolean=true;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ImportPackingActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private packingService: PackingService,
    private commonsService: CommonsService,
    public utilService: UtilService,
    private messageService:MessageService,
    private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    // this.getPackingDetails();
    console.log('this.data', this.data);
    this.spoolBarCodesForm = this.fb.group({
      startHealCode: ['',Validators.required],
      endHealCode: ['',Validators.required]
    });
    this.sHForm = this.fb.group({
     startHealCode: ''
    });
    this.manualHealForm = this.fb.group({
      healCodes: ''
     });
    this.getPermitDetails()
    this.importedPackingForm = this.fb.group({
      importPermitNo: [''],
      printer: ['',[Validators.required]],
      importCasesCount: [''],
      typeOfProduction: ['',[Validators.required]],
      batchQuantity: [''],
      packedQty: ['',[Validators.required]],
      productionCases: [''],
      scanSH: [''],
      spoolBarcodes: [''],
      productDetails: ['']
    });
    if (this.data) {
      this.newEdit = 'Edit';
      this.importedPackingForm.patchValue({
        importPermitNo: this.data.ipNo,
        printer: this.data.printer,
        importCasesCount: this.data.totalCases,
        typeOfProduction: this.data.typeOfProduction,
        batchQuantity: this.data.batchQuantity,
        packedQty: this.data.packedQty,
        productionCases: this.data.productionCases,
        scanSH: this.data.scanSH,
        spoolBarcodes: this.data.spoolBarcodes,
        productDetails: this.data.productDetails
      });
    }
    this.importedPackingForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
    //this.enableBatch.length = this.data.productDetails && this.data.productDetails.length;
    this.enableBatch.fill(false);
  }

  getPermitDetails(){
    const req_Data = {
      action: 'common_apis',
      q: `{"data": {"action": "get_import_permit_details", "value": "${this.data.ipNo}"}}`
    }
    this.spinner.show();
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {
      console.log('result', result);
      if(result && result['get_permit_details']){
        this.productDetails = result['get_permit_details'];
        this.getBatches();
        let datas: any = [];
        if(this.productDetails.consignment && this.productDetails.consignment.length){
          this.productDetails.consignment.forEach((item:any)=>{
            if(item.productDetails && item.productDetails.length){
              item.productDetails.forEach((prod:any)=>{
                prod.ofsCode = item.ofsCode;
                datas.push(prod);
              })
            }
          })
        }
        
        this.dataSource = new MatTableDataSource(datas);
        this.spinner.hide();
      }


    })
  }

  getBatches(){
    let paramObj = `?action=common_apis&q= {"data": {"action": "get_batches", "value" : "${this.data.sourceEntityId}"}}`;
    this.commonsService.getBatchProdList(paramObj).subscribe((data:any) => {
      if(this.productDetails.consignment && this.productDetails.consignment[0].productDetails.length)
      this.productDetails.consignment[0].productDetails.map((item:any)=>{
        data['get_batches'].forEach((batch:any)=>{
          if(item.brandCode === batch.brandCode){
            console.log(batch)
            item.batchId = batch.batchId;
          }
        })
      })
    })
  }

  updateEnableBatch(e, i) {
    console.log(e,i)
    this.enableBatch.fill(false);
    let checkboxes = document.getElementsByClassName('enableProduct');
    console.log('checkboxes', checkboxes);
    for (let k = 0; k < checkboxes.length; k++) {
      if (i !== k) {
        let elt = checkboxes[k] as HTMLInputElement;
        elt.checked = false;
      }
    }
    this.enableBatch[i] = e.target.checked;
    if(e.target.checked){
      this.selectedProductIndex = i;
    }else{
      this.selectedProductIndex = -1;
    }
    this.productDetails.consignment.forEach((item:any,i)=>{
      item.productDetails.forEach((prod:any)=>{
        if(this.dataSource.filteredData[this.selectedProductIndex]['productCode'] === prod.productCode && this.dataSource.filteredData[this.selectedProductIndex]['batchId'] === prod.batchId && this.dataSource.filteredData[this.selectedProductIndex]['ofsCode'] === prod.ofsCode){
          this.selectedConsIndex =i;
        }
      })
    })
  }

  isBatchEditable(i) {
    return this.enableBatch[i];
  }

  getProductDetails(product){
    return this.utilService.productCodeToDetails(product);
  }

  onScanSH(productionType) {    
    let formValues = productionType === 'manual' ? this.manualHealForm.value : this.sHForm.value;
    const unitPerCase = this.getProductDetails(this.dataSource.filteredData[this.selectedProductIndex]['productCode']).unitsPerCase;
    if(this.manualHealForm.value.healCodes.length <= unitPerCase && productionType === 'manual'){
      formValues = {...formValues, scannedBottles:this.dataSource.filteredData[this.selectedProductIndex]['packedQty']}
      const req_Data = {
        action: productionType === 'manual' ?  'add_cases_manual' :'add_cases_automated',
        data: {
          ...this.dataSource.filteredData[this.selectedProductIndex], 
          ofsCode: this.productDetails.consignment[this.selectedConsIndex].ofsCode,
          ipNo: this.data.ipNo,
          // packedQty: 1,
          importPermitNo: this.data.importPermitNo,
          destnEntityId: this.productDetails.destnEntityId,
          sourceEntityId: this.productDetails.sourceEntityId,
          unitsPerCase: this.getProductDetails(this.dataSource.filteredData[this.selectedProductIndex]['productCode']).unitsPerCase,
          ...formValues
        }
      }
      if(productionType === 'manual' && baseUrl && baseUrl !== 'https://apsbcltd.ap.gov.in'){
        req_Data.data.isImportProd = 1;
      }
      console.log(req_Data)
      this.spinner.show();
      this.packingService.postPacking(req_Data).subscribe((res: any) => {
        if(res.status === 'SUCCESS'){
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Scanned Successfully' });
          this.getPermitDetails();
          this.manualHealForm.get('healCodes').setValue([])
          this.spinner.hide();
        }else{
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Scanning failed' });
        }
        console.log(res);
      }, (err) => {
        this.spinner.hide();
        this.error = err.error.message;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Scanning failed' });
      });
    }
    else if(this.manualHealForm.value.healCodes.length > unitPerCase && productionType === 'manual'){
      this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Case Codes count should not be more than Units per case' });
    }
    else if(productionType !== 'manual'){
      const req_Data = {
        action: productionType === 'manual' ?  'add_cases_manual ' :'add_cases_automated',
        data: {
          ...this.dataSource.filteredData[this.selectedProductIndex], 
          ofsCode: this.productDetails.consignment[this.selectedConsIndex].ofsCode,
          ipNo: this.data.ipNo,
          // packedQty: this.packedQty,
          importPermitNo: this.data.importPermitNo,
          destnEntityId: this.productDetails.destnEntityId,
          sourceEntityId: this.productDetails.sourceEntityId,
          unitsPerCase: this.getProductDetails(this.dataSource.filteredData[this.selectedProductIndex]['productCode']).unitsPerCase,
          ...formValues
        }
      }
      if(productionType === 'automation' && baseUrl && baseUrl !== 'https://apsbcltd.ap.gov.in'){
        req_Data.data.isImportProd = 1;
      }
      console.log(req_Data)
      this.spinner.show();
      this.packingService.postPacking(req_Data).subscribe((res: any) => {
          if(res.status === 'SUCCESS'){
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Scanned Successfully' });
            this.sHForm.get('startHealCode').setValue([])
            this.getPermitDetails();
          }else{
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Scanning failed' });
          }
        console.log(res);
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        this.error = err.error.message;
      });
    }

  }

  scanCases() {
    const req_Data = {
      action:'add_cases_semimanual',
      data: {
        ...this.dataSource.filteredData[this.selectedProductIndex], 
        ofsCode: this.productDetails.consignment[this.selectedConsIndex].ofsCode,
        ipNo: this.data.ipNo,
        packedQty: this.packedQty,
        importPermitNo: this.data.importPermitNo,
        destnEntityId: this.productDetails.destnEntityId,
        sourceEntityId: this.productDetails.sourceEntityId,
        unitsPerCase: this.getProductDetails(this.dataSource.filteredData[this.selectedProductIndex]['productCode']).unitsPerCase,
        ...this.spoolBarCodesForm.value
      }
    }
    this.spinner.show();
    this.packingService.postPacking(req_Data).subscribe((res: any) => {
      if(res.status === 'SUCCESS'){
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Scanned Successfully' });
        this.getPermitDetails();
        this.spinner.hide();
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Scanning failed' });
      }
      console.log(res);
    }, (err) => {
      this.spinner.hide();
      this.error = err.error.message;
    });
  }

  ngOnChanges() {
    this.getPackingDetails();
  }
  getPackingDetails() {
    this.spinner.show();
    this.packingService.getImportedPackingDetails().subscribe(res => {
      console.log('Packing', res);
      this.spinner.hide();
      if (this.data) {

      }
    });
  }

  updateValues(field, e, i,data) {
    this.fieldError = [];
    if(!this.fieldError[i]){
      this.fieldError[i] = {};
    }
    this.isValidate = false;
    this.dataSource.filteredData[i][field] = parseInt(e.target.value);
    // this.dataSource.filteredData.forEach((item:any)=>{
    //     console.log(item)
    //     item[i][field] = parseInt(e.target.value);
    // })
    // this.productDetails.consignment[0].productDetails[i][field] = parseInt(e.target.value);

    // const scannedCases = (data.approvedQty ? data.approvedQty : 0) - (data.casesCount ? data.casesCount : 0);
    // const breakages = (data.unitsPerCase -data.breakages.breakages);
    // const availableQty = scannedCases + breakages;
    if(this.importedPackingForm.value.typeOfProduction === 'Manual'){
      if((data.packedQty ? data.packedQty : 0) > (data?.unitsPerCase - 1)){
        this.fieldError[i].error = true;
        this.isValidate = true;
      }
    }else{
      if(((data?.casesCount ? data?.casesCount :0) + (data?.breakages && data?.breakages.bottles ? 1 :0) + (data.packedQty ? data.packedQty : 0)) > (data.approvedQty)){
        this.fieldError[i].error = true;
        this.isValidate = true;
      }
    }

  }
  isDisabled(){
    if(this.selectedProductIndex >= 0){
      return !this.dataSource.filteredData[this.selectedProductIndex]['packedQty']
      // return !this.productDetails.consignment[0].productDetails[this.selectedProductIndex].packedQty
    }
    return false;
  }
  receivedBtls(row:any){
    const approvedQty = (row.batchApprovedQty ? row.batchApprovedQty :row.shipmentQty) * row?.unitsPerCase;
    const receivedQty = (row?.casesCount ? row?.casesCount :0) * row?.unitsPerCase;
    let breakagesCases =0;
    if(row?.breakages && row?.breakages?.cases){
      breakagesCases = row?.breakages? (row?.breakages?.cases * row?.unitsPerCase) :0
    }
    const breakageBottles = row?.breakages ? row?.breakages?.bottles :0;
    let receivedBottles =0;
    if(breakageBottles){
      // receivedBottles = (approvedQty - (receivedQty + breakageBottles));
      receivedBottles = row?.scannedBottles ? row?.scannedBottles :0;

    }
    const totalBottles = receivedQty + breakageBottles + receivedBottles + breakagesCases;
    const data:any ={
      cases:row?.casesCount ? row?.casesCount :0,
      bottles:receivedBottles,
      breakageCaseBtls:breakagesCases,
      breakageBottles:breakageBottles,
      totalBottles:totalBottles,
      approvedBtlQty:approvedQty
    };
    // console.log(data)
    return data;

  }

  disableScan(){
    if(this.selectedProductIndex !== undefined){
      const unitsPerCase = this.getProductDetails(this.dataSource.filteredData[this.selectedProductIndex]['productCode']).unitsPerCase;
      const spoolBarcodesLength = parseInt(this.spoolBarCodesForm.get('endHealCode').value) - parseInt(this.spoolBarCodesForm.get('startHealCode').value);
      return spoolBarcodesLength >= unitsPerCase;
    }
    return true;
  }

  get f() {
    return this.importedPackingForm.controls;
  }

  // getBrandCodes() {
  //   this.productionService.getProduct(1, 100).subscribe((res) => {
  //     this.products.push(...res);
  //   });
  // }

  selectProduct(e) {

  }
  completeImport(){
    const req_Data:any={
      action:'',
      data:this.productDetails
    }
    console.log(req_Data)
    // this.spinner.show();
    // this.packingService.postPacking(req_Data).subscribe((res: any) => {
    //   if(res.status === 'SUCCESS'){
    //     this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Import Complted Successfully' });
    //     this.spinner.hide();
    //   }else{
    //     this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Import Compltion Failed' });
    //   }
    //   console.log(res);
    // }, (err) => {
    //   this.spinner.hide();
    //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Import Compltion Failed' });
    //   this.error = err.error.message;
    // });
  }


}
