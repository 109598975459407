import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonsService } from 'src/app/core/services/commons.service';
import { RationingService } from 'src/app/core/services/rationing.service';
import { formatDate } from '@angular/common';
import { BottleSizes } from 'src/app/core/constants/bottle-sizes.constants';
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { MessageService } from 'primeng/api';
import { packTypes } from 'src/app/core/constants/pack-type.constants';

@Component({
  selector: 'app-add-rationing-dialog',
  templateUrl: './add-rationing-dialog.component.html',
  styleUrls: ['./add-rationing-dialog.component.scss']
})
export class AddRationingDialogComponent implements OnInit {

  rationingForm: FormGroup;
  error: string;
  today: Date;
  nextDate: Date;
  page: number;
  limit: number;
  saveDisabled: boolean;
  // packTypes=  {'Glass': 'G', 'Pet Bottle': 'P', 'Tetra Pack': 'K', 'Barrel': 'C', 'Can': 'C'};
  errMessage="Enter valid ration quantity";
  isInvalid:any;
  features: any;
  featuresData: any;
  filteredData:any=[];
  userRole:any;
  isError:any;
  currentDateTime:any;
  now: any = Date.now();
  packTypesList:any=[];
  // sizes = {'BL': 1, 'AQ': 12, 'AA': 40, 'AB': 60, 'AJ': 80, 'AD': 96, 'AC': 120, 'AF': 192, 'OO': 150, 'DD': 96,
  // 'VB': 60, 'NP': 24, 'NN': 48, 'CP': 24, 'CN': 48, 'BY': 24, 'ZP': 24, 'GP': 24, 'BV': 20, 'IT': 30,
  // 'RP': 24, 'UP': 24, 'DP': 24, 'BI': 24, 'SA': 20, 'SP': 24, 'PM': 6, 'WQ': 12, 'PP': 24, 'HM': 6,
  // 'BW': 8, 'HQ': 12, 'HE': 18, 'BX': 20, 'AP': 24, 'BS': 12, 'BA': 20, 'JK': 1, 'JD': 2, 'JR': 3,
  // 'JG': 4, 'JM': 6, 'JQ': 12, 'JH': 15, 'QK': 1, 'QR': 3, 'QG': 4, 'QM': 6, 'QQ': 12, 'LR': 3,
  // 'LG': 4, 'LM': 6, 'LL': 9, 'LQ': 12, 'LK': 1, 'RB': 3, 'BM': 6, 'TG': 4, 'TM': 6, 'XG': 4,
  // 'MD': 2, 'MR': 3, 'MM': 6, 'EK': 1, 'KK': 1, 'FK': 1};
  bottleSizes:any;
  packTypes: any;
  products: any[];
  brands: any = [];
  filteredProduct: any = [];
  loader: any = false;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<AddRationingDialogComponent>, private commonService: CommonsService,
              @Inject(MAT_DIALOG_DATA) public data, private rationingService: RationingService,private datePipe: DatePipe,private spinner: NgxSpinnerService,
              public util: UtilService,private messageService:MessageService) { }

  ngOnInit(): void {
    this.getFeatures();
    this.currentDateTime = this.datePipe.transform(this.now, 'MM-dd-yyyy h:mm:ss')
    this.today = new Date();
    this.nextDate  = new Date();
    this.nextDate.setDate(this.nextDate.getDate() + 1);
    this.bottleSizes = BottleSizes;
    this.packTypes = packTypes;
    this.rationingForm = this.fb.group({
      brandName: ['', [Validators.required, ]],
      size: ['', [Validators.required, ]],
      packType: ['', [Validators.required, ]],
      ration: ['', [Validators.required, Validators.min(0) ]],
      // productCode: ['', [Validators.required]],
      applicableFrom: ['', [Validators.required, ]],
      applicableTo: ['', [Validators.required, ]],
      licenseeType: ['', [Validators.required, ]]
    });
    if(this.data) {
      this.rationingForm.patchValue({
        brandName: this.data.brandName,
        packType: this.data.packType,
        size: this.data.size,
        ration: this.data.ration,
        // productCode: this.data.productCode,
        applicableFrom: new Date(this.data.applicableFrom),
        applicableTo: new Date(this.data.applicableTo),
        licenseeType: this.data.licenseeType ? this.data.licenseeType : ''
      });
    }
    this.rationingForm.valueChanges.subscribe((change) => {
      this.checkDuplicate(change)
      if(change.applicableFrom > change.applicableTo){
        this.saveDisabled = true;
      }else{
        this.saveDisabled = false;
      }
    });

    let qData = {
      "data": ["get_distinct_brands"]
    }
    const req_Data = {
      "action": "common_apis",
      "q": JSON.stringify(qData)
    };
    this.brands = [];
    this.spinner.show();
    this.commonService.getCommonEntites(req_Data).subscribe((res) => {
      this.products = res['get_distinct_brands'];
      const datas: any = [];
      this.products.forEach(el => {
        if (!el.productCode) {
          return;
        }
        const data: any = {};
        if (!data.products) {
          data.products = [];
        }
        const existItem: any = datas.find(e => e.brandCode === el.brandCode);
        if (existItem) {
          existItem.products.push(el);
          return;
        }
        data.brandCode = el.brandCode;
        data.brandName = el.brandName;

        data.products.push(el);
        datas.push(data);
      });
      this.brands = datas;
      this.spinner.hide();
    });
  }

  get f(){
    return this.rationingForm.controls;
  }
  rationQty(e){
    const value = e.target.value
    if(this.data){
      const rationFrom = this.datePipe.transform(this.data.applicableFrom, 'MM-dd-yyyy h:mm:ss');
      const rationTo = this.datePipe.transform(this.data.applicableTo, 'MM-dd-yyyy h:mm:ss');
      if(this.currentDateTime > rationFrom && this.currentDateTime > rationTo){
        console.log("Expired")
        return true
      }else{
        return this.isInvalid = value < this.data.ration

      }
    }
  }
  getFeatures() {
    this.spinner.show();
    const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).mod_feats;
    this.features = userModules['Rationing'].features;
    this.userRole = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;    
    this.rationingService.getRationingFeatures().subscribe(res => {
      this.featuresData = res;
      this.filterData();
      this.spinner.hide();
    });
  
  }

  filterData(){
    if(this.features.length){
      this.features.map((feature:any)=>{
        if(feature.feature_name === 'Rationing'){
          this.filteredData = this.featuresData['Rationing'];
        }
      })
    }
  }


  createRationing() {
    this.saveDisabled = true;
    // this.rationingForm.get('applicableFrom').setValue(new Date(this.rationingForm.value.applicableFrom))
    // this.rationingForm.get('applicableTo').setValue(new Date(this.rationingForm.value.applicableTo))
    // this.rationingForm.get('applicableFrom').setValue(this.datePipe.transform(this.rationingForm.value.applicableFrom, 'MM-dd-yyyy'));
    // this.rationingForm.get('applicableTo').setValue(this.datePipe.transform(this.rationingForm.value.applicableTo, 'MM-dd-yyyy'));
    this.rationingForm.get('applicableFrom').setValue(this.util.getDateWithStartTime(this.rationingForm.value.applicableFrom));
    this.rationingForm.get('applicableTo').setValue(this.util.getDateWithEndTime(this.rationingForm.value.applicableTo));

    const req_Data = {
      action : 'add_ration',
      data : this.rationingForm.value
    }
    // req_Data.data.applicableFrom = req_Data.data.applicableFrom.toISOString();
    // req_Data.data.applicableTo = req_Data.data.applicableTo.toISOString();
    console.log(req_Data)
    this.loader= true;
    this.rationingService.createRationing(req_Data).subscribe((res: any) => {
      if (res && res.status === 'SUCCESS') {
        this.dialogRef.close('Rationing Created Successfully');
        this.loader= false;
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "Not Created" });
        this.loader= false;
      }
    }, (err) => {
      this.loader= false;
      this.error = err.error.message;
    });
  }

  updateRationing() {
    this.saveDisabled = true;
    // this.rationingForm.get('applicableFrom').setValue(this.datePipe.transform(this.rationingForm.value.applicableFrom, 'MM-dd-yyyy h:mm:ss'));
    // this.rationingForm.get('applicableTo').setValue(this.datePipe.transform(this.rationingForm.value.applicableTo, 'MM-dd-yyyy h:mm:ss'));
    this.rationingForm.get('applicableFrom').setValue(this.util.getDateWithStartTime(this.rationingForm.value.applicableFrom));
    this.rationingForm.get('applicableTo').setValue(this.util.getDateWithEndTime(this.rationingForm.value.applicableTo));
    const obj = {
      action : 'update_ration',
      data : this.rationingForm.value
    };
    // console.log(new Date(this.rationingForm.value.applicableFrom).toISOString())
    // obj.data.applicableFrom = obj.data.applicableFrom.toISOString();
    // obj.data.applicableTo = obj.data.applicableTo.toISOString();
    obj.data.f
    console.log(obj)
    this.rationingService.updateRationing(obj).subscribe((res: any) => {
      if (res && res.status === 'SUCCESS') {
        this.dialogRef.close('Rationing Udated Successfully');
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "Not Updated" });
      }
    }, (err) => {
      this.error = err.error.message;
    });
  }
checkDuplicate(brand){
  this.filteredData.forEach((item:any)=>{
    if(item.brandName === brand.brandName && item.licenseeType === brand.licenseeType && item.packType === brand.packType && item.size === brand.size){
      this.today = new Date(item.applicableTo);
      this.today.setDate(this.today.getDate() + 1)
    }
  })
}
  selectProduct(brand) {
    console.log('brand', brand);
    this.today = new Date();
    const datas: any = []
    this.filteredProduct = [];
    // if (brand.products && brand.products.length) {
    //   this.filteredProduct = brand.products;
    // }
    this.rationingForm.patchValue({
      size: brand.products && brand.products.length === 1 ? brand.products[0].size : '',
      packType: ''
    });
    brand.products.forEach(el => {
      el.packTypes = [];
      // if (!el.packTypes) {
        
      // }
      el.sizeCode = this.util.getSizeCode(el.productCode);
      const isExistSizeCode = this.filteredProduct.find(fp => fp.sizeCode === el.sizeCode);
      if (isExistSizeCode) {
        isExistSizeCode.packTypes.push(el.packType);
        return;
      }
      // el.packTypes.push(el.packType);
      el.packTypes.push(el.packType);
      this.filteredProduct.push(el);
    });
    // this.filteredProduct.forEach((data:any)=>{
    //   const isExist = datas.find(el=> el.size === data.size);
    //   if(!isExist){
    //     const arr = {size:data.size,data:[data]}
    //     datas.push(arr)
    //   }else{
    //     isExist.data.push(data)
    //   }
    // })
    // this.filteredProduct = datas
    if (this.filteredProduct && this.filteredProduct.length === 1) {
      this.selectSizeAndPack(this.filteredProduct[0]);
    }
    console.log('this.filteredProduct', this.filteredProduct);
  }
  selectSizeAndPack(product) {
    this.packTypesList = [];
    this.packTypesList = product.packTypes;
    if (this.packTypesList && this.packTypesList.length === 1) {
      this.rationingForm.patchValue({
        packType: this.packTypesList[0]
      });
    }
    // this.today = new Date();
    // this.packTypesList =[];
    // this.packTypesList= this.filteredProduct.filter(el => el.size === value);
    // // this.packTypesList = this.packTypesList[0].data;
    // this.packTypesList = this.packTypesList[0].data.filter((obj, index, self) => 
    // index === self.findIndex((t) => (t.packType === obj.packType))
// );

    // const packList:any=[]
    // data.forEach((item:any)=>{
    //   packList.push(this.packTypes[item.packType]);
    //   this.packTypesList=  packList.filter((value, index) => {
    //     return packList.indexOf(value) === index;
    //   });
    //   console.log(this.packTypesList)
    // })

    // this.rationingForm.patchValue({
    //   packType: data.packType,
    // });
    // this.isError = this.filteredData.some(el=> el.brandName === this.rationingForm.value.brandName && el.size === this.rationingForm.value.size && el.licenseeType === this.rationingForm.value.licenseeType && el.packType === this.rationingForm.value.packType);
    // if(this.isError){
    //   this.messageService.add({ severity: 'error', summary: 'Error', detail: `${this.rationingForm.value.brandName} brand already exists` });
    //   this.resetCtrls();
    // }
  }
 
  changeLicensee(): any {
    this.resetCtrls();
  }
  getSizeCodeByValue(value): any {
    return Object.keys(this.bottleSizes).find(k => this.bottleSizes[k] === value);
  }
  resetCtrls(): any {
    this.rationingForm.patchValue({
      packType: '',
      size: '',
      brandName: ''
    })
  }
  


}
