import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ExportService } from 'src/app/core/services/export.service';

@Component({
  selector: 'app-export-challan-dialog',
  templateUrl: './export-challan-dialog.component.html',
  styleUrls: ['./export-challan-dialog.component.scss']
})
export class ExportChallanDialogComponent implements OnInit {
  exportfeeChallanForm: FormGroup;
  commissionChallanForm: FormGroup;
  challanTypes= ['Commission Fee','Export Fee'];
  selectedChallan: any = '';

  constructor(private fb: FormBuilder,private exportService: ExportService,
    public dialogRef: MatDialogRef<ExportChallanDialogComponent>,) { }

  ngOnInit(): void {
    this.exportfeeChallanForm = this.fb.group({
      challanType: ['',Validators.required],
      depositedAmount: ['',Validators.required],
      challanDate: ['',Validators.required],
      challanNumber: ['',Validators.required],
      bankName: ['',Validators.required],
      challanPlace: ['',Validators.required],
    });
    this.commissionChallanForm = this.fb.group({
      challanType: ['',Validators.required],
      depositedAmount: ['',Validators.required],
      ddType: ['',Validators.required],
      ddDate: ['',Validators.required],
      ddNumber: ['',Validators.required],
      ddPlace: ['',Validators.required],
    });
  }

  addChallan(){
    const reqData = {
      action:'add_export_challan',
      data: ''
    };
    if (this.selectedChallan === 'Commission Fee') {
      reqData.data = this.commissionChallanForm.value
    } else if (this.selectedChallan === 'Export Fee') {
      reqData.data = this.exportfeeChallanForm.value
    } 
    console.log(reqData)
    this.exportService.createIndent(reqData).subscribe((res:any)=>{
      if(res.status=='SUCCESS'){
          this.dialogRef.close('Challan Created Successfully');
      }else if(res.status=='Challan Already Exists'){
        this.dialogRef.close('Challan Already Exists');
      }
      
    })
  }
  challanChange(): any {
    if (this.selectedChallan === 'Commission Fee') {
      this.commissionChallanForm.controls.challanType.setValue(this.selectedChallan);
      this.reset('Export Fee');
    } else if (this.selectedChallan === 'Export Fee') {
      this.exportfeeChallanForm.controls.challanType.setValue(this.selectedChallan);
      this.reset('Commission Fee');
    }
    console.log(this.exportfeeChallanForm);
    console.log(this.commissionChallanForm);
  }
  reset(challanType: any = ''): any {
    if (challanType === 'Commission Fee') {
      this.commissionChallanForm.reset();
    } else if (challanType === 'Export Fee') {
      this.exportfeeChallanForm.reset();
    } else {
      this.commissionChallanForm.reset();
      this.exportfeeChallanForm.reset();
    }    
  }
 
}
