import { InterDepotService } from 'src/app/core/services/inter-depot.service';
import { Component, Inject, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { unitsPerCases } from 'src/app/core/constants/units-per-case.constatnts';
import { MessageService } from 'primeng/api';
import { UtilService } from 'src/app/core/utils/utility-helper';
declare const JsBarcode: any;

@Component({
  selector: 'app-idtreceipt-checklist-info-dialog',
  templateUrl: './idtreceipt-checklist-info-dialog.component.html',
  styleUrls: ['./idtreceipt-checklist-info-dialog.component.scss']
})
export class IDTReceiptChecklistInfoDialogComponent implements OnInit, AfterViewInit {
  valid: any;
  ofidtData: any [];
  caseCode: any;
  totalShipped: any = 0;
  receivedQtyFull: any = 0;
  receivedQtyPart: any = 0;
  breakageBtl: any = 0;
  selectedOfidt:any;
  displayedColumns: string[] = [
    'no',
    'productCode',
    'brandName',
    // 'batchId',
    'sizeCode',
    'shippedCases',
    'recievedCases',
    'recievedBottles',
    'damagedCases',
    'damagedBottles',
    'shortageCases',
    'shortageBottles'
  ];
  user: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  productData: any = [];
  address: any;
  filteredProductDetails: any[];
  @Input() evcData: any;
  roleName: any;
  // @ViewChild('icdcCode') icdcCode: any;
  caseCodeType: any = '';
  caseTypes: any = ['Manual', 'Automatic'];
  unitsPerCases = unitsPerCases;
  caseCodeCount: any;
  showSubmit = true;
  depotName: any;
  userDetails: any = [];
  distilleryName: any = [];
  distilleryAddress: any = [];
    constructor(
      public dialogRef: MatDialogRef<IDTReceiptChecklistInfoDialogComponent>,
      private interDepotService: InterDepotService,
      @Inject(MAT_DIALOG_DATA)public data,
      private messageService: MessageService,
      public utilService: UtilService
    ) {
      this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
      this.depotName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).entity_id;
      this.address = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).depot_address;
    }

  ngOnInit(): void {
    console.log(this.data, '33::::');
    this.productData = [];
    this.ofidtData = this.data.consignment;
    this.user = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
    this.totavlValue();
    if (this.userDetails.distillery_unit && this.userDetails.distillery_address) {
      this.distilleryName = this.utilService.formatString(this.userDetails.distillery_unit);
      this.distilleryAddress = this.userDetails.distillery_address[0];
    }
    this.dataSource = new MatTableDataSource(this.filteredProductDetails);
  }
  totavlValue(): any{
    this.totalShipped = 0;
    this.ofidtData.map((ofidt: any) => {
      ofidt.productDetails.map((item: any) => {
        this.totalShipped += parseInt(item.approvedCases, 10);
      });
    });

  }
  ngAfterViewInit(): any {
    if (this.data.chkNO) {
      JsBarcode('#barcode-chkNO', this.data.chkNO,
        {
          width: 1,
          height: 40
        }
      );
    }
    JsBarcode('#barcode-bar-icdc', 'ICDC086160522001379',
      {
        width: 1,
        height: 40
      }
    );
    JsBarcode('#barcode-test', this.data.chkNO,
      {
        width: 1,
        height: 40
      }
    );
  }

  updateAction(): any {
    const payload: any = {
      action: this.data.actionLink === 'Check List' ? 'generate_checklist' : 'receive_tp',
      data: {
        tpNo: this.data.tpNo
      }
    };
    if (this.data.btpNo) {
      delete payload.data.tpNo;
      payload.data.btpNo = this.data.btpNo;
    } else if (this.data.bipNo) {
      delete payload.data.tpNo;
      payload.data.bipNo = this.data.bipNo;
    }
    this.interDepotService.generateActions(payload).subscribe(result => {
     this.dialogRef.close('Action Generated successfully');
    });
  }

  getRecievedCases(row): any {
    const cases = 0;

    return !this.showSubmit ? row.casesCount : '' ;
  }
  dialogClose(): void{
    this.dialogRef.close();
  }
  print(data,id): any {

    if(document.getElementById(id)){
      this.selectedOfidt = data;
      setTimeout(() => {
        let printContents;
        let popupWin;
        console.log(this.selectedOfidt)
        printContents = document.getElementById(id).innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.write(`${printContents}`);
        popupWin.document.close();
      }, 500);
    }

  
  }
}
