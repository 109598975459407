import { CommonsService } from 'src/app/core/services/commons.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { OtpVerificationDialogComponent } from 'src/app/core/components/otp-verification-dialog/otp-verification-dialog.component';
import { DepotHolograms } from 'src/app/core/models/holograms/depot-holograms.model';
import { HologramsService } from 'src/app/core/services/holograms.service';
import { MessageService } from 'primeng/api';
declare const JsBarcode: any;

@Component({
  selector: 'app-open-depot-status-dialog',
  templateUrl: './open-depot-status-dialog.component.html',
  styleUrls: ['./open-depot-status-dialog.component.scss']
})
export class OpenDepotStatusDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'no',
    'depot',
    'quantity'
  ];
  dataSource: MatTableDataSource<DepotHolograms> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;
  vehicleNo:any;

  approveHPIForm: FormGroup;
  error: string;
  locationForm: FormGroup;
  cartons: FormArray;
  statusData: any;
  colors = {
    "Local": "Light Green",
    "Export": "Light Yellow",
    "CSD": "Light Yellow",
    "CSD Local": "Light Brown",
    "CSD Export": "Light Brown"
  }
  spoolsQty: number;
  roleName: any;
  disableDownLoad = false;

  constructor(public dialogRef: MatDialogRef<OpenDepotStatusDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,private dialog: MatDialog,private commponService:CommonsService,
    private hologramsService: HologramsService, private fb: FormBuilder,public util:UtilService,
    private messageService: MessageService,) {
      this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
     }

  ngOnInit(): void {
    console.log('data', this.data);
    if (this.data.status === 'Dispatched' || this.data.status === 'InProgress') {
      this.getHpiDetails();
    }

    if (this.data.status === 'Reviewed') {
      this.approveHPIForm = this.fb.group({
        requestedEntity: this.data.entityType,
        depotCode: this.data.entityId,
        healIndentId: this.data.healIndentId,
        requestedQuantity: this.data.requestedQuantity,
        approvedQuantity: this.data.requestedQuantity
      })
    }
    else if (this.data.status === 'Completed') {
      this.locationForm = this.fb.group({
        requestedEntity: this.data.entityType,
        depotCode: this.data.entityId,
        healIndentId: this.data.healIndentId,
        requestedQuantity: this.data.requestedQuantity,
        vehicleNo:this.vehicleNo,
        cartons: this.fb.array([this.createBarCode()]),
        location: this.data.location,
      });
      this.cartons = this.locationForm.get('cartons') as FormArray;
    }
  }

  ngAfterViewInit(): any {
    if (this.data.indentNo) {
      JsBarcode('#barcode-dm2', this.data.indentNo,
        {
          width: 1,
          height: 40
        }
      );
    }
    if (this.data.deliveryChllan) {
      JsBarcode('#barcode-delivery-challan', this.data.deliveryChllan,
        {
          width: 1,
          height: 40
        }
      );
    }
  }

  getHpiDetails() {
    const qdata = {
      entityType: 'Depot',
      supplierId: this.data.supplierId,
      healIndentId: this.data.healIndentId,
      status: this.data.status
    }
    this.hologramsService.getHpiDetails(JSON.stringify(qdata), 'get_hpi_by_id').subscribe(res => {
      console.log('res', res);
      this.data = { ...this.data, ...res };
    });
  }

  createBarCode() {
    return this.fb.group({
      cartonBarcode: '',
      spoolBarcodes: ''
    });
  }

  getOFSDetails() {
    this.hologramsService.getDepotHologramsDetails(this.offset, this.limit, this.search).subscribe((res: any) => {
      console.log('res', res);
      this.dataSource = new MatTableDataSource(res.data);
    });
  }

  downLoadHeals(){
    this.disableDownLoad =  true;
    const req_Data = {
      action: "get_spool_bundles",
      data: {
        depotCode: this.data.entityId,
        entityType: this.data.entityType,
        approvedQty: JSON.parse(this.data.approvedQuantity),
        healDownloadStatus : 'Downloaded',
        healIndentId : this.data.healIndentId,
        updatedDateTime : this.data.updatedDateTime
      }
    }
    this.hologramsService.getDownloadHeals(req_Data).subscribe((res: any) => {
      this.export(res['get_spool_bundles']);
    }, (err) => {
      this.error = err.error.message;
    });
  }

  export(healData){
    const spoolBarCodeList = Object.keys(healData);
     spoolBarCodeList.forEach((spoolBarCode) => {
      const data = healData[spoolBarCode];
      this.downloadFile(data, spoolBarCode);
    });
  }

  downloadFile(data, filename) {
    let csvData = this.ConvertToCSV(data);
    console.log(csvData);
    let blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray) {
    let str = '';
    for (let i = 0; i < objArray.length; i++) {
      let line = objArray[i];
      str += line + '\r\n';
    }
    return str;
  }

  approveQty(action) {
    if(action =='approve_hpi'){
   // this.util.openModalDialog(OtpVerificationDialogComponent, null, '25%', '45%', false, false, null);
  //  const req_otp ={
  //   action: "send_otp",
  //   data:{
  //    ...this.data
  //   }
  // }
  
  // this.commponService.sendVerifyOtp(req_otp).subscribe((res:any)=>{
    
    
  // })
  // const dialogRef = this.dialog.open(OtpVerificationDialogComponent, {
  //   width: '25%',
  //   height:'45%',
  //   autoFocus: false
  // });

  // dialogRef.afterClosed().subscribe((result) => {
  //   if(result.message == 'SUCCESS'){
      const req_Data = {
        action: action,
        data: this.approveHPIForm.value
      }
      this.hologramsService.updateHealPurchaseIndents(req_Data).subscribe((res: any) => {
        let message = action == 'approve_hpi' ? 'Heal Purchase Indent approved Successfully' : 'Heal Purchase Indent Rejected Successfully';
        this.dialogRef.close(message);
      }, (err) => {
        this.error = err.error.message;
      });
  //   }
  // });
    }else{
      const req_Data = {
        action: action,
        data: this.approveHPIForm.value
      }
      this.hologramsService.updateHealPurchaseIndents(req_Data).subscribe((res: any) => {
        let message = action == 'approve_hpi' ? 'Heal Purchase Indent approved Successfully' : 'Heal Purchase Indent Rejected Successfully';
        this.dialogRef.close(message);
      }, (err) => {
        this.error = err.error.message;
      });
    }
 

  }

  getTitle() {
    return this.data.status === 'Reviewed' ? 'Enter Qunatity to Approve' :
      this.data.status === 'Approved' ? 'Scan CartonBarrcode and SpoolBarcode' : this.data.status === 'InProgress' ? 'Dispatch' : '';

  }

  onDispatch() {
    const req_Data = {
      action: 'dispatch_spools',
      data: this.locationForm.value
    }
    if(req_Data.data.vehicleNo === null || req_Data.data.vehicleNo === '' || req_Data.data.vehicleNo === undefined){
      this.messageService.add({severity: 'error', summary: '', detail: 'Please Enter Vehicle number'});
      return;
    }
    this.hologramsService.updateHealPurchaseIndents(req_Data).subscribe((res: any) => {
      if(res.status ==='SUCCESS'){
        this.dialogRef.close('Spools dispatched Successfully');
      }else{
        this.messageService.add({ severity: 'error', detail: 'Spools not dispatched' });
      }
    }, (err) => {
      this.error = err.error.message;
    });
  }

  checkSpoolsQty() {
    let disableSubmit = [];
    if (this.cartons.value.length > 0) {
      this.spoolsQty = 0;
      for (let carton of this.cartons.value) {
        this.spoolsQty += carton.spoolBarcodes.length * 20000;
        disableSubmit.push(!!Object.values(carton).filter(item => !item).length);
      }
      disableSubmit.push(!(this.spoolsQty === Number(this.data.approvedQuantity)));
    }
    return !!disableSubmit.filter(item => item).length;
  }

  dialogClose(): void {
    this.dialogRef.close();
  }
  getPrint(id): any {
    let printContents;
    let popupWin;
    setTimeout(() => {
      if (document.getElementById(id)) {
        printContents = document.getElementById(id).innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.write(`${printContents}`);
        popupWin.document.close();
      }
    }, 500);
  }
  removeZero(value){
    const valueStr = value.toString();
    const valueWithoutZerosStr = valueStr.replace(/0/g, '');
    return parseInt(valueWithoutZerosStr, 10);
  }
  noOfCartoons(approvedQuantity:any){  
    const approvedQty = approvedQuantity ? parseInt(approvedQuantity) : 0;
    return Math.ceil(approvedQty / 100000);
  
  }

}
