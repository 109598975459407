import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { ImportService } from 'src/app/core/services/import.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';

@Component({
  selector: 'app-manage-challan-information',
  templateUrl: './manage-challan-information.component.html',
  styleUrls: ['./manage-challan-information.component.scss']
})
export class ManageChallanInformationComponent implements OnInit {
  challanForm:FormGroup;
  locationsList:any=[];
  public locationFitlerCtrl: FormControl = new FormControl();
  public _onDestroy = new Subject<void>();
  public filteredLocations: ReplaySubject<any> = new ReplaySubject<any>(1);
  locationId:any;
  loader: any = false;

  constructor(private fb:FormBuilder,public util:UtilService,
    private importService:ImportService,
    private messageService:MessageService,
    private dialogRef: MatDialogRef<ManageChallanInformationComponent>) { }

  ngOnInit(): void {
    this.challanForm = this.fb.group({
      locationName:['',[Validators.required]],
      challanType:['ImportFee',[Validators.required]],
      depositedAmount:['',[Validators.required]],
      challanDate:['',[Validators.required]],
      challanNumber:['',[Validators.required]],
      bankName:['',[Validators.required]],
      challanPlace:['',[Validators.required]]
    })
    this.getLocations();
    this.locationFitlerCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterLocations();
    });
  }
  filterLocations() {
    if (!this.locationsList || (this.locationsList && this.locationsList.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.locationFitlerCtrl.value;
    if (!search) {
      this.filteredLocations.next(this.locationsList);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredLocations.next(
      this.locationsList.filter(el => JSON.stringify(el.locationName).toLowerCase().indexOf(search) > -1)
    );
  }
  getLocations(){
    this.importService.getIndents().subscribe((res:any)=>{
      this.locationsList = res['Pastors Location'];
      console.log(this.locationsList)
      this.filterLocations();
    })
  }
  selectLocation(event:any){
    console.log(event.value)
    this.locationsList.forEach((item:any)=>{
      if(item.locationName === event.value){
        this.locationId = item.locationID
      }
    })
  }
  saveChallan(){
    console.log(this.challanForm.value)
    const data = {...this.challanForm.value}
    data.locationID =this.locationId
    data.entityId = `${this.locationId}-${this.challanForm.value.locationName}`
    const req_Data:any={
      action:'add_challan_data',
      data:data
    }
    console.log(req_Data)
    this.loader=true;
    this.importService.createIndent(req_Data).subscribe((res:any)=>{
      if(res.status === 'SUCCESS'){
        this.loader=false;
        this.dialogRef.close('Challan added successfully');
      }else{
        this.loader=false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Challan adding failed' });
      }
    }, (err) => {
      this.loader = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Challan adding failed' });
    })
  }

}
