import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShipmentsService } from 'src/app/core/services/shipments.service';

@Component({
  selector: 'app-manage-tp-vehicle-accident-dialog',
  templateUrl: './manage-tp-vehicle-accident-dialog.component.html',
  styleUrls: ['./manage-tp-vehicle-accident-dialog.component.scss']
})
export class ManageTpVehicleAccidentDialogComponent implements OnInit {

  tpVehicleAccidentForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  tpNos:any = [];


  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageTpVehicleAccidentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private shipmentsService: ShipmentsService) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.shipmentsService.getTP('{}','get_tps').subscribe(tps => {
      this.tpNos = tps['tpList'];
    })
    this.tpVehicleAccidentForm = this.fb.group({
      tpNo: ['',[Validators.required]],
      oldVehicleNo: ['',[Validators.required]],
      vehicleNo: ['',[Validators.required]],
      oldValidPeriod: ['',[Validators.required]],
      validPeriod: ['',[Validators.required]],
      oldTransporter: ['',[Validators.required]],
      transporterName: ['',[Validators.required]],
      oldLRNumber: ['',[Validators.required]],
      lrNumber: ['',[Validators.required]],
      oldLRDate: ['',[Validators.required]],
      lrDate: ['',[Validators.required]],
      fir: ['',[Validators.required]],
      policeStation: ['',[Validators.required]],
      remarks: ['',[Validators.required]],
    });
    if (this.data && this.data._id) {
      this.tpVehicleAccidentForm.patchValue({
        tpNo : this.data.tpNo,
        oldVehicleNo: this.data.oldVehicleNo,
        vehicleNo: this.data.vehicleNo,
        oldValidPeriod: this.data.oldValidPeriod,
        validPeriod : this.data.validPeriod,
        oldTransporter: this.data.oldTransporter,
        transporterName: this.data.transporterName,
        oldLRNumber: this.data.oldLRNumber,
        lrNumber : this.data.lrNumber,
        oldLRDate: this.data.oldLRDate,
        lrDate: this.data.lrDate,
        fir: this.data.fir,
        policeStation: this.data.policeStation,
        remarks: this.data.remarks,
      });
    }
    this.tpVehicleAccidentForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    })
  }

  get f(){
    return this.tpVehicleAccidentForm.controls;
  }

  createTPVehicle() {
    this.saveDisabled = true;
    const req_data ={
      action: 'add_vehicle_accident',
      data: {...this.tpVehicleAccidentForm.value}
    };
    this.shipmentsService.createTPVehicle(req_data).subscribe((res: any) => {
      this.dialogRef.close('TP Vehicle Accident Created Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  updateTPVehicle() {
    // this.saveDisabled = true;
    // const obj = [{
    //   _id: this.data._id,
    //   update: this.tpVehicleAccidentForm.value
    // }]
    // this.spiritService.putBatch(obj).subscribe((res: any) => {
    //   this.dialogRef.close('Batch Udated Successfully');
    // }, (err) => {
    //   this.error = err.error.message;
    // });
  }


}
