import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { DistilleryInfoService } from 'src/app/core/services/distillery-info.service';
import { InputValidator } from 'src/app/core/utils/input.validator';

@Component({
  selector: 'app-manage-licences-dialog',
  templateUrl: './manage-licences-dialog.component.html',
  styleUrls: ['./manage-licences-dialog.component.scss']
})
export class ManageLicencesDialogComponent implements OnInit {
  userInfo: any;
  supplierCode: any;
  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageLicencesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private distilleryInfoService: DistilleryInfoService, private messageService: MessageService) { 
                this.userInfo = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
                this.supplierCode = this.userInfo['entity_id'];
              }

  get f(){
    return this.licencesForm.controls;
  }

  licencesForm: FormGroup;
  error: string;
  saveDisabled: boolean;
  todayDate = new Date();

  ngOnInit(): void {
    console.log(this.data),
  this.licencesForm = this.fb.group({
      // code: ['', [Validators.required]],
      // licenseType: ['', [Validators.required]],
      supplierCode: [this.supplierCode, [Validators.required]],
      licenceNumber: ['', [Validators.required]],
      expiryDate: ['', [Validators.required]],
      licenceDate: ['', [Validators.required]],
      // purpose: [''],
      // description: ['']
    });
    if (this.data && this.data._id) {
      this.licencesForm.patchValue({
        // code: this.data.code,
        supplierCode : this.data.supplierCode,
        licenceNumber: this.data.licenceNumber,
        expiryDate: this.data.expiryDate,
        licenceDate: this.data.licenceDate,
        // purpose: this.data.purpose,
        // description: this.data.description
      });
    }
    this.licencesForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
  }

  createLicences() {
    this.saveDisabled = true;
    const req_Data:any = {
      action: 'add_licence_details',
      data: this.licencesForm.value
    }
    this.distilleryInfoService.createLicense(req_Data).subscribe((res: any) => {
      if (res && res.status === 'SUCCESS') {
        this.dialogRef.close('Created Successfully');
      } else {
        this.messageService.add({severity: 'error', summary: '', detail: 'Not Created.'});
      }
    }, (err) => {
      this.error = err.error.message;
    });
  }

  updateLicences() {
    this.saveDisabled = true;
    const req_Data:any = {
      action: 'update_licence_details',
      data: this.licencesForm.value
    }
    this.distilleryInfoService.putLicense(req_Data).subscribe((res: any) => {
      console.log(res);
      this.dialogRef.close('Licence Updated Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  // getTitle() {
  //   return this.data && this.data._id ? `Update Licence for ${this.data.licenceName}` : 'Create Licence';
  // }
}
