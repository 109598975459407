import { ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormArray } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Depot } from 'src/app/core/models/depot-info/depot.model';
import { Retailer } from 'src/app/core/models/reports/retailer.model';
import { Get_Retailer } from 'src/app/core/models/retailers/retailers.model';
import { PurchaseOrder } from 'src/app/core/models/shipments/purchase-order.model';
import { Product } from 'src/app/core/models/stock/hold-on-stock.model';
import { CommonsService } from 'src/app/core/services/commons.service';
import { DepotInfoService } from 'src/app/core/services/depot-info.service';
import { IndentManagementService } from 'src/app/core/services/indent-management.service';
import { ProductionService } from 'src/app/core/services/production.service';
  import { RetailersService } from 'src/app/core/services/retailers.service';
import { InputValidator } from 'src/app/core/utils/input.validator';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { DatePipe } from "@angular/common";


@Component({
  templateUrl: './manage-licensee-indents-dialog.component.html',
  styleUrls: ['./manage-licensee-indents-dialog.component.scss']
})
export class ManageLicenseeIndentsDialogComponent implements OnInit {
  compositionAddFields: any = [];
  spiritData: any = [];
  unitsData: any = [];
  licenseeIndentsForm: FormGroup;
  licenseeIndentsFormBottlesIndent: FormGroup;
  error: string;
  page: number;
  selectedTabIndex: any = 0;
  limit: number;
  purchaseOrders: PurchaseOrder[];
  saveDisabled: boolean;
  saveDisabledBottlesIndent: boolean;
  requestedQuantities: FormArray;
  requestedQuantitiesBottlesIndent: FormArray;
  products: Product[];
  indents: any;
  depots: Depot;
  retailers: any[];
  loader: any = false;
  public filteredProducts: ReplaySubject<any> = new ReplaySubject<any>(1);
  public productFitlerCtrl: FormControl = new FormControl();
  public filteredRetailers: ReplaySubject<any> = new ReplaySubject<any>(1);
  public retailerFitlerCtrl: FormControl = new FormControl();
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public _onDestroy = new Subject<void>();
  displayedColumns: string[] = ['no', 'productCode', 'bottles', 'cases', 'quantity', 'rationQuantity'];
  @ViewChild('productSelect') productSelect: MatSelect;
  @ViewChild('retailerSelect') retailerSelect: MatSelect;
  @ViewChild('retailerSelectBottlesIndent') retailerSelectBottlesIndent: MatSelect;
  @ViewChild('dialogRefTemplate') dialogRefTemplate: TemplateRef<any>;

  productDataList: any = [];
  quantity = [];
  userInfo: any;
  disableCreate: boolean = true;
  productList: any;
  brandList: any;
  dialogRef1: MatDialogRef<any, any>;
  tabIndex: number = 0;
  sizeList: any = [];
  isValidate: any = false;
  retailerIndentsList:any=[];
  retailerIndentsListRoot: any = [];
  isDuplicate:any;
  errorText ="Indent Already Exist";
  now: any = Date.now();
  currentDateTime:any;
  rationApplicable:any = false;
  fieldError:any=[];
  productsList:any=[];
  productListData:any=[];
  totalValue:any;
  totalCases:any;
  isStatusActive:any=false;
  productsRootCases: any = [];
  productsRootBottles: any = [];

  constructor(private fb: FormBuilder, private fb2: FormBuilder, private dialogRef: MatDialogRef<ManageLicenseeIndentsDialogComponent>, private alertDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data, private depotService: DepotInfoService,
    private productionService: ProductionService, private indentManagementService: IndentManagementService,
    private commonService: CommonsService, private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    public util:UtilService,
    private messageService:MessageService,
    private retailerService: RetailersService,private datePipe: DatePipe,
    private myElement: ElementRef) {
    this.userInfo = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
  }

  ngOnInit(): void {

    console.log('data==>', this.data);

    this.getProducts();
    // this.getSupplyIndentDetails();
    //this.getRetailers(); // check
    this.currentDateTime = this.datePipe.transform(this.now, 'MM-dd-yyyy h:mm:ss')
    this.licenseeIndentsForm = this.fb.group({
      product: ['', Validators.required],
      destnEntityId: ['', Validators.required],
      // indentNumber: ['', [Validators.required, InputValidator.cannotStartWithSpace]],
      // requestedQuantities: this.fb.array([this.createProduct()]),
      // productDetails: [],
      //  retailer: ['', [Validators.required, InputValidator.cannotStartWithSpace]],
      // raisedOn: ['', [Validators.required]];

    });
    this.licenseeIndentsFormBottlesIndent = this.fb2.group({
      product: ['', Validators.required],
      destnEntityId: ['', Validators.required],
    });
  
    // if (this.data && this.data._id) {
    //   this.licenseeIndentsForm.patchValue({
    //     // indentNumber: this.data.indentNumber,
    //     requestedQuantities: this.data.requestedQuantities,
    //     retailer: this.data.retailer._id,
    //     // productDetails: this.data.productDetails,
    //     // raisedOn: this.data.raisedOn,

    //   });
    //   const requestedQuantities = this.licenseeIndentsForm.get('requestedQuantities') as FormArray;
    //   requestedQuantities.clear();
    //   this.data.requestedQuantities.forEach(b => {
    //     requestedQuantities.push(
    //       this.fb.group({
    //         product: '',
    //         //  quantity: b.quantity,
    //       })
    //     )
    //   });
    // }
    if (this.tabIndex === 0) {
      this.requestedQuantities = this.licenseeIndentsForm.get('requestedQuantities') as FormArray;
      this.licenseeIndentsForm.valueChanges.subscribe((change) => {
        this.saveDisabled = false;
      });
    } else if (this.tabIndex === 1) {
      this.requestedQuantitiesBottlesIndent = this.licenseeIndentsFormBottlesIndent.get('requestedQuantities') as FormArray;
      this.licenseeIndentsFormBottlesIndent.valueChanges.subscribe((change) => {
        this.saveDisabledBottlesIndent = false;
      });
    }

    

    this.productFitlerCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProducts();
      });

    this.retailerFitlerCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRetailers();
      });
  }


  onTabChanged(event) {
    
    this.tabIndex = event.index;
    this.saveDisabled = false;
    this.saveDisabledBottlesIndent = false;
    this.isDuplicate = false;
    this.compositionAddFields = [];
    this.isValidate = false;
    this.totalCases = 0;
    this.totalValue = 0;
    // this.licenseeIndentsForm.reset();
    // this.licenseeIndentsFormBottlesIndent.reset();
    this.licenseeIndentsForm.patchValue({
      destnEntityId: '',
      product: ''
    });
    this.licenseeIndentsFormBottlesIndent.patchValue({
      destnEntityId: '',
      product: ''
    });
    this.setProductList();
    // this.getProducts();
    /*  this.dialogRef1 = this.dialog.open( this.dialogRefTemplate, {
       width: '50%',
       // height: '75%',
       autoFocus: false,
       data: event.index
     });

     this.dialogRef1.afterClosed().subscribe((result) => {
       console.log(`Dialog result: ${result}`);

     }); */
  }

  dataFill(data) {
    let flag = false;
    Object.keys(data).forEach(val => {
      if (!flag) {
        flag = !data[val] && val !== 'rationQuantity' && val !== 'usedRation' &&  val !== 'remainingQuantity';
      }

    })
    return flag;
  }

  validateQty(isValid) {    
    this.isValidate = isValid;
    return isValid;
  }

  addComposition() {

    this.compositionAddFields.push({ productCode: '', brandName: '', size: '', depotStock: '', rationQuantity: '', requestedQuantity: '', mrp: '', totalValue: '' })
  }
  gotoTop() {
    let el = this.myElement.nativeElement.querySelector('nav');
    if (this.tabIndex === 1) {
      el = document.getElementById('nav2');
    }
    el.scrollIntoView({behavior: "smooth"})
    this.addComposition()
  }

  deleteComposition(i) {
    this.compositionAddFields.splice(i, 1);
    this.fieldError.splice(i, 1);
    this.getFormValues();
    this.isValidate=false
  }


  productChange(event, type) {
    let qData = {
      "data": { "value": event.value, "action": "get_depot_stock_prod" }
    };
    const req_Data = {
      "action": "common_apis",
      "q": JSON.stringify(qData)
    };

    this.commonService.getCommonEntites(req_Data).subscribe((res: Get_Retailer) => {
      this.productDataList.push(res['get_depot_stock_prod']);
      this.dataSource = new MatTableDataSource(this.productDataList);
      //  this.filteredProducts.next(this.products.slice());

    });
  }



  quantityChange(i, e) {
    this.quantity[i] = e.target.value;
    this.productDataList[i]['quantity'] = this.quantity[i];
    delete this.productDataList[i]['isEdit']
  }

  quantityCheck(i) {
    if (this.quantity[i] > 0) {
      if (this.productDataList[i]['ration']) {
        this.disableCreate = this.productDataList[i]['ration'] < this.quantity[i];
        return this.productDataList[i]['ration'] < this.quantity[i];
      }
      else {
        if (this.licenseeIndentsForm.get('action').value === 'create_retailer_parts_indent') {
          this.disableCreate = this.productDataList[i]['bottles'] < this.quantity[i];
          return this.productDataList[i]['bottles'] < this.quantity[i];
        } else {
          this.disableCreate = this.productDataList[i]['cases'] < this.quantity[i];
          return this.productDataList[i]['cases'] < this.quantity[i];
        }
      }
    } else {
      this.disableCreate = true;
    }

  }

  filterProducts() {
    if (!this.brandList || (this.brandList && this.brandList.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.productFitlerCtrl.value;
    if (!search) {
      this.filteredProducts.next(this.brandList);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredProducts.next(
      this.brandList.filter(product => JSON.stringify(product).toLowerCase().indexOf(search) > -1)
    );
  }

  filterRetailers() {
    if (!this.retailers || (this.retailers && this.retailers.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.retailerFitlerCtrl.value;
    if (!search) {
      this.filteredRetailers.next(this.retailers);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredRetailers.next(
      this.retailers.filter(retailer => JSON.stringify(retailer.name).toLowerCase().indexOf(search) > -1)
    );
  }

  getProducts() {
    this.spinner.show();
    let req_Data: any = {};
    this.productsRootCases = [];
    this.productsRootBottles = [];
    if (this.data.title === 'Manual Indents') {
      req_Data = {
        "action": "common_apis",
        "q": '{"data": [ "get_retailers_by_depot", "get_depot_products", "get_ctel_stock", "get_ret_indents", "get_ctel_stock_bottle"]}'
      };
    } else {
      req_Data = {
        "action": "common_apis",
        "q": '{"data": [ "get_retailers_by_depot", "get_depot_products", "get_depot_stock","get_ret_indents", "get_depot_stock_bottle"]}'
      };
    }
    this.commonService.getCommonEntites(req_Data).subscribe((res: Get_Retailer) => {
      if (!res['get_ctel_stock'] && !res['get_depot_stock'] && !res['get_retailers_by_depot'] && !res['get_all_vendors']) {
        return;
      }
      if (this.data.title === 'Manual Indents') {
        this.productsRootCases = res['get_ctel_stock'];
        this.productsRootBottles = res['get_ctel_stock_bottle'];
      } else {
        this.productsRootCases = res['get_depot_stock'];
        this.productsRootBottles = res['get_depot_stock_bottle'];
      }
      this.retailers = res['get_retailers_by_depot'];
      if (!this.retailers) {
        this.retailers = res['get_all_vendors'];
      }
      if (this.retailers) {
        this.retailers = this.retailers.filter((res, i) => {
          return i == this.retailers.lastIndexOf(res);
        });
      } else {
        this.retailers = [];
      }
      this.filteredRetailers.next(this.retailers);
      this.setProductList();
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }
  setProductList(): any {
    if (this.tabIndex === 0) {
      this.productList = this.productsRootCases;
    } else {
      this.productList = this.productsRootBottles;
    }
    this.brandList = this.productList.map(res => res.brandCode);
    this.products = this.productList.map(res => res.productCode);
    this.products = this.products.filter((res, i) => {
      return i == this.products.lastIndexOf(res);
    });
    this.brandList = this.brandList.filter((res, i) => {
      return i == this.brandList.lastIndexOf(res);
    });
    this.filteredProducts.next(this.brandList);
    this.brandList.forEach(res => {
      if (this.brandList.indexOf(res.brandName) !== -1 && this.sizeList.indexOf(res.size) == -1) {
        this.sizeList.push(res.size);
      }
    });
  }
  getDepots() {
    this.depotService.getDepotInfo(true).subscribe(res => {
      this.depots = res.data;
    });
  }
  getRetailers() {
    const req_Data = {
      "action": "common_apis",
      "q": "{'data': ['get_products']}"
    };
    this.commonService.getCommonEntites(req_Data).subscribe((res: Get_Retailer) => {
      this.retailers = res.data;
    });
  }
  createProduct() {
    return this.fb.group({
      product: ['', [Validators.required, InputValidator.cannotStartWithSpace]],
      // quantity: ['', [Validators.required, InputValidator.cannotStartWithSpace]],
    });
  }

  get f() {
    return this.licenseeIndentsForm.controls;
  }
  selectedRetailer(event){
    this.isValidate = false;
    if (this.tabIndex === 0) {
      this.isDuplicate = this.retailerIndentsListRoot.some(res=>res.indentType === 'cases_indent' && res.destnEntityId === event.value);
    } else if (this.tabIndex === 1) {      
      this.isDuplicate = this.retailerIndentsListRoot.some(res=>res.indentType === 'bottles_indent' && res.destnEntityId === event.value);
    }
    // this.isDuplicate = this.retailerIndentsList.some(res=>res === event.value)
  }
  getFormValues(){
    this.totalValue =0;
    this.totalCases=0;
    this.compositionAddFields.forEach((item:any)=>{
      this.totalValue += item.totalValue;
      this.totalCases += item.requestedQuantity;
    })
  }

  createIndent() {
    this.saveDisabled = true;
    let data: any = {};
    data['productDetails'] = [];
    data['type'] = "Retailer";
    if (this.tabIndex === 0) {
      data.indentType = 'cases_indent';
      data['destnEntityId'] = this.licenseeIndentsForm.get('destnEntityId').value;
      data['productDetails'] = this.compositionAddFields.map(prod => {
        return { "size": prod.size, "productType": this.util.getProductType(prod.productCode), "productCode": prod.productCode, "brandName": prod.brandName, "requestedCases": parseInt(prod.requestedQuantity), "mrp": prod.mrp, "value": prod.totalValue,"casesCount":0,"casesCountNumber":0 }
      });
    } else if (this.tabIndex === 1) {
      data.indentType = 'bottles_indent';
      data['destnEntityId'] = this.licenseeIndentsFormBottlesIndent.get('destnEntityId').value;
      data['productDetails'] = this.compositionAddFields.map(prod => {
        return { "size": prod.size, "productType": this.util.getProductType(prod.productCode), "productCode": prod.productCode, "brandName": prod.brandName, "requestedBottles": parseInt(prod.requestedQuantity), "mrp": prod.mrp, "value": prod.totalValue,"casesCount":0,"casesCountNumber":0 }
      });
    }
    const req_Data = {
      action: this.data.title === 'Manual Indents' ? 'create_manual_indent' : 'create_retailer_indent',
      data
    }
    req_Data.data.productDetails.sort((a, b) => a.size < b.size ? -1 : 1);
    req_Data.data.productDetails.sort((a, b) => a.productType === 'Beer' ? -1 : 1);
    this.loader = true;
    this.indentManagementService.createRetailerIndent(req_Data).subscribe((res: any) => {
      if(res.status){
        this.dialogRef.close(`${this.data.title} Created Successfully`);
        this.loader = false;
      }else if(res.error){
        this.messageService.add({ severity: 'error', summary: 'Error', detail: res.error });
        this.loader = false;
      }
      this.loader = false;
    }, (err) => {
      this.loader = false;
      this.error = err.error.message;
    });
  }

  updateIndent() {
    // this.saveDisabled = true;
    // this.purchaseOrdersService.putPurchaseOrder(this.licenseeIndentsForm.value).subscribe((res: any) => {
    //   this.dialogRef.close('Supply Updated Successfully');
    // }, (err) => {
    //   this.error = err.message;
    // });
  }
  selectProduct(field, i){
    this.productList.forEach(res => {
      if (res.productCode == field.productCode) {
        // this.productsList.push(res.productCode);

        this.compositionAddFields[i]['size'] = res.size;
        // this.compositionAddFields[i]['productCode'] = res.productCode;
        this.compositionAddFields[i]['mrp'] = res['retailPricings'];
        // this.compositionAddFields[i]['issuePrice'] = res.issuePrice;
        
        this.compositionAddFields[i]['unitsPerCase'] = res.unitsPerCase;
        this.compositionAddFields[i]['brandName'] = res.brandCode;
        this.compositionAddFields[i]['brandCode'] = res.brandCode;
        // this.compositionAddFields[i]['closingBalCases'] = res.closingBalCases;
        if (this.tabIndex === 1) {
          let closingBalBottles = 0;
          if (this.data.title === 'Manual Indents') {
            closingBalBottles = res.ctelOnlineClosingBottles && res.ctelOnlineClosingBottles ? res.ctelOnlineClosingBottles : 0;
          } else {
            closingBalBottles = res.onlineClosingBalBottles && res.onlineClosingBalBottles > 0 ? res.onlineClosingBalBottles : res.closingBalBottles;
          }
          this.compositionAddFields[i]['depotStock'] = closingBalBottles;
        }
        else {
          let closingBalCases = 0;
          if (this.data.title === 'Manual Indents') {
            closingBalCases = res.ctelOnlineClosingCases && res.ctelOnlineClosingCases ? res.ctelOnlineClosingCases : 0;
          } else {
            closingBalCases = res.onlineClosingBalCases && res.onlineClosingBalCases >= 0 ? res.onlineClosingBalCases : res.closingBalCases;
          }
          this.compositionAddFields[i]['depotStock'] = closingBalCases;
        }
        this.compositionAddFields[i]['rationQuantity'] = res.ration ? res.ration :0;  
        if(res.rationing && res.rationing.length){
          res.rationing.forEach((data:any)=>{
            if((this.tabIndex === 0 && this.licenseeIndentsForm.get('destnEntityId').value === data.name) || (this.tabIndex === 1 && this.licenseeIndentsFormBottlesIndent.get('destnEntityId').value === data.name)){
              // const rationFrom = this.datePipe.transform(data.applicableFrom, 'MM-dd-yyyy h:mm:ss');
              // const rationTo = this.datePipe.transform(data.applicableTo, 'MM-dd-yyyy h:mm:ss');
              const rationFrom = data.applicableFrom;
              const rationTo = data.applicableTo;
              const todayDateTime: any = (new Date()).toISOString();
              if(todayDateTime > rationFrom && todayDateTime < rationTo){
                this.rationApplicable = true;
                this.compositionAddFields[i]['usedRation'] = data.usedRation ? data.usedRation : 0;
                this.compositionAddFields[i]['remainingQuantity'] = data.ration - data.usedRation

              }
              else{
                this.compositionAddFields[i]['rationQuantity'] = 0;
                this.compositionAddFields[i]['usedRation'] =  0;
                this.compositionAddFields[i]['remainingQuantity'] =  0;
              }
              
            }
          })
        }else{
          this.compositionAddFields[i]['usedRation'] = 0;
          this.compositionAddFields[i]['remainingQuantity'] = 0;
        }   
      }
    })
  }

  selectBrand(product, i) {
    this.productListData[i] = [];
    this.productList.forEach(res => {
      if (product.brandName == res.brandCode ) {
        this.productListData[i].push(res.productCode);
        this.compositionAddFields[i]['size'] = '';
        this.compositionAddFields[i]['productCode'] = '';
        this.compositionAddFields[i]['mrp'] = '';
        // this.compositionAddFields[i]['issuePrice'] = '';
        
        // this.compositionAddFields[i]['unitsPerCase'] = '';
        // this.compositionAddFields[i]['brandName'] = '';
        // this.compositionAddFields[i]['brandCode'] = '';
        // this.compositionAddFields[i]['closingBalCases'] = '';
        this.compositionAddFields[i]['depotStock'] = 0;
        this.compositionAddFields[i]['rationQuantity'] = 0; 
        this.compositionAddFields[i]['usedRation'] = 0;
        this.compositionAddFields[i]['remainingQuantity'] = 0;
        this.compositionAddFields[i]['totalValue'] = 0;
        this.compositionAddFields[i]['requestedQuantity'] = 0;
        this.fieldError[i] = {};

        // this.compositionAddFields[i]['size'] = res.size;
        // // this.compositionAddFields[i]['productCode'] = res.productCode;
        // this.compositionAddFields[i]['mrp'] = res['retailPricings'];
        // this.compositionAddFields[i]['issuePrice'] = res.issuePrice;
        
        // this.compositionAddFields[i]['unitsPerCase'] = res.unitsPerCase;
        // this.compositionAddFields[i]['brandName'] = res.brandCode;
        // this.compositionAddFields[i]['brandCode'] = res.brandCode;
        // if (this.tabIndex === 1) {
        //   this.compositionAddFields[i]['depotStock'] = res.closingBalBottles;
        // }
        // else {
        //   this.compositionAddFields[i]['depotStock'] = res.onlineClosingBalCases;
        // }
        // this.compositionAddFields[i]['rationQuantity'] = res.ration ? res.ration :0;  
        
        // if(res.rationing && res.rationing.length){
        //   res.rationing.forEach((data:any)=>{
        //     if(this.userInfo.entity_id === data.name){
        //       const rationFrom = this.datePipe.transform(data.applicableFrom, 'MM-dd-yyyy h:mm:ss');
        //       const rationTo = this.datePipe.transform(data.applicableTo, 'MM-dd-yyyy h:mm:ss');
        //       if(this.currentDateTime > rationFrom && this.currentDateTime < rationTo){
               
        //         this.rationApplicable = true;
        //         this.compositionAddFields[i]['usedRation'] = data.usedRation ? data.usedRation : 0;
        //         this.compositionAddFields[i]['remainingQuantity'] = data.ration - data.usedRation
        //         console.log("Between")

        //       }
        //       else{
        //         this.compositionAddFields[i]['rationQuantity'] = 0;
        //         this.compositionAddFields[i]['usedRation'] =  0;
        //         this.compositionAddFields[i]['remainingQuantity'] =  0;
        //         console.log("Not Between")
        //       }
              
        //     }
        //   })
        // }else{
        //   this.compositionAddFields[i]['usedRation'] = 0;
        //   this.compositionAddFields[i]['remainingQuantity'] = 0;
        // }   

      }
    /*  let qData;
     if(this.userInfo.entity_type === 'Retailer') {
       qData = {
         "data": {"value": product, "action" : "get_depot_stock_prod", "entityType":"Retailer"}
       }
     } else if (this.userInfo.entity_type === 'Depot') {
       qData = {
         "data": {"value": product, "action" : "get_depot_stock_prod"}
       }
     }
     const req_Data = {
       "action": "common_apis",
       "q": JSON.stringify(qData)
     }; */
    //  this.compositionAddFields[i]['requestedQuantity'] = '';
    // this.productList.forEach(res => {
    //   if (res.productCode == product) {
    //     this.compositionAddFields[i]['size'] = res.size;
    //     this.compositionAddFields[i]['productCode'] = res.productCode;
    //     //  if(this.tabIndex === 1) {
    //     this.compositionAddFields[i]['mrp'] = res['retailPricings'];
    //     //  }
    //     //  else {
    //     //    this.compositionAddFields[i]['mrp'] = res['basicPricing'][0]['price'];
    //     //  }
    //     this.compositionAddFields[i]['unitsPerCase'] = res.unitsPerCase;
    //     this.compositionAddFields[i]['brandName'] = res.brandName;
    //     this.compositionAddFields[i]['brandCode'] = res.brandCode;
    //     if (this.tabIndex === 1) {
    //       this.compositionAddFields[i]['depotStock'] = res.closingBalBottles;
    //     }
    //     else {          
    //       this.compositionAddFields[i]['depotStock'] = res.onlineClosingBalCases >= 0 ? res.onlineClosingBalCases : res.closingBalCases;
    //     }
    //     this.compositionAddFields[i]['rationQuantity'] = res.ration ? res.ration :0;
    //     if(res.rationing && res.rationing.length){
    //       res.rationing.forEach((data:any)=>{
    //         if(this.licenseeIndentsForm.get('destnEntityId').value === data.name){
    //           const rationFrom = this.datePipe.transform(data.applicableFrom, 'MM-dd-yyyy h:mm:ss');
    //           const rationTo = this.datePipe.transform(data.applicableTo, 'MM-dd-yyyy h:mm:ss');
    //           if(this.currentDateTime > rationFrom && this.currentDateTime < rationTo){
                
    //             this.rationApplicable = true;
    //             this.compositionAddFields[i]['usedRation'] = data.usedRation ? data.usedRation : 0;
    //             this.compositionAddFields[i]['remainingQuantity'] = data.ration - data.usedRation

    //           }
    //           else{
    //             this.compositionAddFields[i]['rationQuantity'] = 0;
    //             this.compositionAddFields[i]['usedRation'] =  0;
    //             this.compositionAddFields[i]['remainingQuantity'] =  0;
    //           }
              
    //         }
    //       })
    //     }else{
    //       this.compositionAddFields[i]['usedRation'] = 0;
    //       this.compositionAddFields[i]['remainingQuantity'] = 0;
    //     }
    //   }
    // })
    /*  this.commonService.getproductList(req_Data).subscribe((res: Get_Retailer) => {
      // const idx = this.productDataList.findIndex(product => product.productCode === this.licenseeIndentsForm.get('product').value);
      // if(idx === -1) {
       //  this.productDataList.push(res['get_depot_stock_prod']);
         //this.dataSource = new MatTableDataSource(this.productDataList);
         // this.licenseeIndentsForm.patchValue({product: ''})
    //   }
     //  this.filteredProducts.next(this.products.slice());

     }) */
    //(this.licenseeIndentsForm.controls['requestedQuantities'] as FormArray).push(this.createProduct())
  })
}

  removeProducts(index) {
    this.saveDisabled = false;
    if (this.tabIndex === 0) {
      this.requestedQuantities.removeAt(index);
    } else if (this.tabIndex === 1) {
      this.requestedQuantitiesBottlesIndent.removeAt(index);
    }
  }
  isDisabled(value){
    return this.compositionAddFields.some(item => item.productCode === value) 
  }
  setTotal(field, i, currentQty, enteredQty) {
    const totalValue = this.tabIndex === 1 ? field.mrp * field.requestedQuantity : field.mrp * field.unitsPerCase * field.requestedQuantity;
    this.compositionAddFields[i]['totalValue'] = totalValue;
    this.getFormValues()
    // if(field.depotStock > field.remainingQuantity){
    //   console.log("REM")
    //   this.isValidate = enteredQty > field.remainingQuantity;
    // }else if(field.depotStock < field.remainingQuantity){
    //   console.log("DEP")
    //   this.isValidate = enteredQty > field.depotStock;
    // }else {
    //   console.log("Else")
    //     this.isValidate = true;
    //   }

    this.isValidate = false;
    this.fieldError = [];
    if(!this.fieldError[i]){
      this.fieldError[i] = {};
    }
    if(field.rationQuantity > 0){
      // if(this.rationApplicable == false){
      //   this.messageService.add({ severity: 'error', summary: 'Error', detail: "Ration Expired" });
      //   this.isValidate = true;       
      //   return;
      // }
      if((field.depotStock > 0)&& (field.remainingQuantity == 0 && enteredQty > field.depotStock)){
        this.isValidate = true;
        this.fieldError[i].error = true;
        console.log("0.5",this.isValidate)
        console.log(field)
      }
      else if (field.remainingQuantity > 0 &&(field.depotStock > field.remainingQuantity) && (enteredQty > field.remainingQuantity)) {
        this.isValidate = true;
        this.fieldError[i].error = true;
        console.log("1",this.isValidate)
      }else if(field.remainingQuantity > 0 && (field.depotStock < field.remainingQuantity) && (enteredQty > field.depotStock)){
        this.isValidate = true;
        this.fieldError[i].error = true;
        console.log("2.5")
      }
    }else if(field.rationQuantity == 0){
      if (enteredQty > field.depotStock) {
        this.isValidate = true;
        this.fieldError[i].error = true;
        console.log("2",this.isValidate)
      }
    }
    // if(enteredQty > 0) {
    //   this.isValidate = currentQty < enteredQty
    // } else {
    //   this.isValidate = true;
    // }
  }

  editQuantity(i) {
    this.productDataList[i]['isEdit'] = true;
  }

  isSelected(product) {
    // console.log(this.requestedQuantities.value);
    if (this.tabIndex === 0) {
      const index = this.requestedQuantities.value.findIndex(rq => rq.product === product._id);  
      return index >= 0;
    } else if (this.tabIndex === 1) {
      const index = this.requestedQuantitiesBottlesIndent.value.findIndex(rq => rq.product === product._id);  
      return index >= 0;
    }
  }
  isRationing(productCode): any {
    let isStatus: any = false;
    this.productList.forEach(el => {
      if(el.status && el.status != 'InActive'){
        this.isStatusActive = true;
      }
      if (el.productCode === productCode && el.rationing && el.rationing.length) {
        if(el.ration === 0){
          this.saveDisabled = true;
        }
        el.rationing.forEach((data:any) => {
          if (isStatus) {
            return;
          }
          if ((this.tabIndex === 0 && this.licenseeIndentsForm.get('destnEntityId').value === data.name) || (this.tabIndex === 1 && this.licenseeIndentsFormBottlesIndent.get('destnEntityId').value === data.name)) {
            // const rationFrom = this.datePipe.transform(data.applicableFrom, 'MM-dd-yyyy h:mm:ss');
            // const rationTo = this.datePipe.transform(data.applicableTo, 'MM-dd-yyyy h:mm:ss');
            const rationFrom = data.applicableFrom;
            const rationTo = data.applicableTo;
            const todayDateTime: any = (new Date()).toISOString();
            if (todayDateTime > rationFrom && todayDateTime < rationTo) {
              isStatus = true;
            }
          }
        });
      }
    });
    return isStatus;
  }
  onlyNumeric(event):boolean {   
    const charCode = (event.which) ? event.which : event.keyCode
       if (charCode > 31  && charCode > 31 && (charCode < 48 || charCode > 57)){
           return false;
       }else{
           return true;
       }
   }
   getFieldError(){
    let error= true;
    if(this.fieldError.length){
      error= this.fieldError.every(err=>err.error ? err.error : true);
    }
    return !error
   }
  // getTitle() {
  //   return this.data && this.data._id ? `Update Indent for  ${this.data.indentNumber}` : 'Create Licensee Indents';
  // }
}
