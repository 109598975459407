import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonsService } from 'src/app/core/services/commons.service';
import { MessageService } from 'primeng/api';
import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { StockService } from 'src/app/core/services/stock.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-manage-outlet-return-stock',
  templateUrl: './manage-outlet-return-stock.component.html',
  styleUrls: ['./manage-outlet-return-stock.component.scss']
})
export class ManageOutletReturnStockComponent implements OnInit {
  retailerScanForm:FormGroup;
  allRetailers = [];
  filteredRetailers = [];
  userDetails:any;
  typesList:any=['IML'];
  valueType:any;
  public _onDestroy = new Subject<void>();
  public filteredDepotNames: ReplaySubject<any> = new ReplaySubject<any>(1);
  public filteredSourceRetailerNames: ReplaySubject<any> = new ReplaySubject<any>(1);
  compositionAddFields: any = [];
  productListData:any=[];
  productList: any=[];
  brandList: any;
  public filteredProducts: ReplaySubject<any> = new ReplaySubject<any>(1);
  public retailerFitlerCtrl: FormControl = new FormControl();




  constructor(private fb: FormBuilder,
  public spinner: NgxSpinnerService,
  private dialogRef: MatDialogRef<ManageOutletReturnStockComponent>,
  @Inject(MAT_DIALOG_DATA) public data,
  private commonsService: CommonsService,
  private util: UtilService,
  private stockService:StockService,
  private messageService:MessageService) { 
  this.userDetails = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));

  }

  ngOnInit(): void {
    this.retailerScanForm = this.fb.group({
      retailerId: ['',Validators.required],
      // retailerName: ['',Validators.required],
      retailerCode: ['',Validators.required],
      depot: ['',Validators.required],
      circleName: ['',Validators.required],
      type: ['',Validators.required],
      imlCaseCode:['']
    });
    this.getRetailers();
    this.getStockData();
    this.retailerFitlerCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterProducts();
    });
  }
  closePopup(){
    this.dialogRef.close(false);
  }
  getRetailers() {
    this.spinner.show();
    const qData = {
      data: 'get_all_retailers'
    };
    // tslint:disable-next-line:variable-name
    const req_Data = {
      action: 'common_apis',
      q: JSON.stringify(qData)
    };
    this.commonsService.getCommonEntites(req_Data).subscribe((res) => {
      console.log(res)
      this.allRetailers = res.get_all_retailers.map(ret => ret.retailerId);
      this.filteredRetailers = this.allRetailers;
      this.filteredSourceRetailerNames.next(this.allRetailers);
      this.spinner.hide();

    });
  }
  dataFill(data) {
    let flag = false;
    Object.keys(data).forEach(val => {
      if (!flag) {
        flag = !data[val] && val !== 'qtyCases' && val !== 'qtyBtls';
      }

    })
    return flag;
  }
  getStockData(){
    this.spinner.show();
    let qData = {
      "data": ["get_depot_stock"]
    }
    const req_Data = {
      "action": "common_apis",
      "q": JSON.stringify(qData)
    };
    
    this.commonsService.getCommonEntites(req_Data).subscribe((res) => {
      res['get_depot_stock'].forEach((item:any)=>{
        console.log(item.productCode.charAt(4))
        if(item.productCode.charAt(4) === 'B'){
          this.productList.push(item);
        }
      })

      this.spinner.hide();
      // this.filteredProducts.next(this.brandList);
    });
  }
  filterProducts() {
    if (!this.allRetailers || (this.allRetailers && this.allRetailers.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.retailerFitlerCtrl.value;
    if (!search) {
      this.filteredSourceRetailerNames.next(this.allRetailers);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredSourceRetailerNames.next(
      this.allRetailers.filter(retailer => JSON.stringify(retailer).toLowerCase().indexOf(search) > -1)
    );
  }
  selectType(e){
    this.valueType = e.value;
    if(this.valueType === 'BEER'){
      this.validateForm();
    }
    console.log(this.valueType)
  }
  selectRetailer(e){
    console.log(e.value);
    const retailerCode = this.util.formatStringByParamAndIndex(e.value,'-',0);
    const circleName = this.util.formatStringByParamAndIndex(e.value,'-',1);

    this.retailerScanForm.get('depot').setValue(this.userDetails.entity_id);
    // this.retailerScanForm.get('retailerName').setValue(e.value);
    this.retailerScanForm.get('retailerCode').setValue(retailerCode);
    this.retailerScanForm.get('circleName').setValue(circleName);

  }
  scanBarCode(){
    const req_Data:any={
      action:'add_outlet_stock_return',
      data:this.retailerScanForm.value
    }
    delete req_Data.data.type;
    if(this.retailerScanForm.value.imlCaseCode !== ''){
      this.spinner.show();
      this.stockService.createBrandBarcode(req_Data).subscribe((res:any)=>{
        if(res.status==='SUCCESS'){
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Barcode Scanned Successfully'});
          this.retailerScanForm.get('imlCaseCode').setValue('');
        }else if(res.status === 'Code Already Exists'){
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Barcode already scanned' });
        }else{
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Barcode Scanning failed' });
        }
        this.spinner.hide();
      })
    }
  }
  addComposition() {

    this.compositionAddFields.push({ productCode: '', brandName: '', qtyCases:'', qtyBtls:'' });
    this.validateForm();
  }
  deleteComposition(i) {
    this.compositionAddFields.splice(i, 1);
    this.validateForm();
  }
  isDisabled(value){
    return this.compositionAddFields.some(item => item.productCode === value) 
  }

  selectProduct(field, i){
    this.productList.forEach(res => {
      if (res.productCode == field.productCode) {
        console.log(res)
        this.compositionAddFields[i]['brandName'] = res.brandName;
        this.compositionAddFields[i]['unitsPerCase'] = res.unitsPerCase;
        this.compositionAddFields[i]['size'] = res.size;
      }
      
    })
  }
  validateForm(){
    console.log(this.compositionAddFields)
    return this.compositionAddFields.some((el=>(el.qtyCases == '' && el.qtyBtls == '') || (el.qtyCases == null || el.qtyBtls == null)))
  }
  createIndnet(){
    this.spinner.show();
    console.log(this.retailerScanForm.value)
    let data: any = {};
    data['productDetails'] = [];
    const req_Data:any={
      action:'add_beer_data',
      data:this.retailerScanForm.value
    }
    req_Data.data['productDetails'] = this.compositionAddFields.map(prod => {
      let product: any = {"beerCode": prod.productCode,"unitsPerCase": prod.unitsPerCase,"size": prod.size, "brandName": prod.brandName, "qtyCases": prod.qtyCases ? parseInt(prod.qtyCases) :0, "qtyBtls": prod.qtyBtls ? prod.qtyBtls :0 };
      return product;
    })
    req_Data.data.type = this.valueType;
    // delete req_Data.data.type;
    delete req_Data.data.imlCaseCode;
    console.log(req_Data)
    this.stockService.createBrandBarcode(req_Data).subscribe((res:any)=>{
      console.log(res)
      if(res.status === 'SUCCESS'){
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Created successfully' });
        this.dialogRef.close(true);
        // this.dialogRef.close('Created Successfully');
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: '' });
      }
      this.spinner.hide();
    })
  
  }
}
''