import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { StockService } from 'src/app/core/services/stock.service';

@Component({
  selector: 'app-add-brand-barcode-dialog',
  templateUrl: './add-brand-barcode-dialog.component.html',
  styleUrls: ['./add-brand-barcode-dialog.component.scss']
})
export class AddBrandBarcodeDialogComponent implements OnInit {

  brandBarcodeForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<AddBrandBarcodeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private stockService: StockService) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.brandBarcodeForm = this.fb.group({
      brandBarcode: ['', [Validators.required, ]],
    });
    if (this.data && this.data._id) {
      this.brandBarcodeForm.patchValue({
        brandBarcode : this.data.referenceNumber,
      });
    }
    this.brandBarcodeForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
  }

  get f(){
    return this.brandBarcodeForm.controls;
  }

  createBrandBarcode() {
    this.saveDisabled = true;
    const req_Data = {
      action : 'add_brand_barcode',
      data : this.brandBarcodeForm.value
    }
    this.stockService.createBrandBarcode(req_Data).subscribe((res: any) => {
      this.dialogRef.close('Batch Created Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  // updateBrandBarcode() {
  //   this.saveDisabled = true;
  //   const obj = [{
  //     _id: this.data._id,
  //     update: this.brandBarcodeForm.value
  //   }]
  //   this.spiritService.putBatch(obj).subscribe((res: any) => {
  //     this.dialogRef.close('Batch Udated Successfully');
  //   }, (err) => {
  //     this.error = err.error.message;
  //   });
  // }

}
