import { IndentManagementService } from './../../../../core/services/indent-management.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './manage-rs-indent-dialog.component.html',
  styleUrls: ['./manage-rs-indent-dialog.component.scss']
})
export class ManageRsIndentDialogComponent implements OnInit {

  RSIndentForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  kindOfSpirits = ['RS'];


  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageRsIndentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private indentManagementService: IndentManagementService) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.RSIndentForm = this.fb.group({
      indentNo: ['',[Validators.required]],
      indentDate: ['',[Validators.required]],
      serialNo: ['',[Validators.required]],
      spiritKind: 'RS',
      storageTankNo: ['',[Validators.required]],
      rsTankNo: ['',[Validators.required]],
      quantityInBls: ['',[Validators.required]],
      quantityInPls: ['',[Validators.required]],
      brandName: ['',[Validators.required]],
      category: ['',[Validators.required]],
      batchNo: ['',[Validators.required]],
    });
    if (this.data && this.data._id) {
      this.RSIndentForm.patchValue({
        indentNo : this.data.indentNo,
        indentDate: this.data.tank._id,
        serialNo: this.data.serialNo,
        spiritKind: this.data.spiritKind,
        storageTankNo: this.data.storageTankNo,
        rsTankNo: this.data.rsTankNo,
        quantityInBls: this.data.quantityInBls,
        quantityInPls: this.data.quantityInPls,
        brandName: this.data.brandName,
        category: this.data.category,
        batchNo: this.data.batchNo,
      });
    }
    this.RSIndentForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    })
  }

  get f(){
    return this.RSIndentForm.controls;
  }

  createRSIndent() {
    this.saveDisabled = true;
    const req_data ={
      action: 'add_blend_indents',
      data: {...this.RSIndentForm.value}
    };
    this.indentManagementService.createRSIndent(req_data).subscribe((res: any) => {
      this.dialogRef.close('RSIndent Created Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  updateRSIndent() {
    // this.saveDisabled = true;
    // const obj = [{
    //   _id: this.data._id,
    //   update: this.RSIndentForm.value
    // }]
    // this.spiritService.putBatch(obj).subscribe((res: any) => {
    //   this.dialogRef.close('Batch Udated Successfully');
    // }, (err) => {
    //   this.error = err.error.message;
    // });
  }


}
