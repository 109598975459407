import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const userAccountsUrl = baseUrl + 'user_accounts';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['User Accounts'] && userModules.mod_feats['User Accounts'].features ? userModules.mod_feats['User Accounts'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class UserAccountsService {

  constructor(private http: HttpClient) { }

  getUserFeature(payLoad: any = ''){
    const headerDict = {
      feature
    };
    return this.http.get(userAccountsUrl, { headers: headerDict, params: payLoad });
  }

  createRetailer(payload: any) {
    return this.http.post(userAccountsUrl, payload);
  }
  create(payload: any) {
    return this.http.post(userAccountsUrl, payload);
  }

  updateUserAccounts(payload: any) {
    return this.http.put(userAccountsUrl, payload);
  }
  update(payload: any) {
    return this.http.put(userAccountsUrl, payload);
  }
  
}