export const  distilleryConstants = {
  "D2DIS_ADD_TANK" : "add_tank",
  "D2DIS_EDIT_TANK" : "edit_tank",
  "D2DIS_ADD_LINE" : "add_line",
  "D2DIS_EDIT_LINE" : "edit_line",
  "D2DIS_GATE_REGISTER" : "add_gate_register",
  "D2LAB_SAMPLE" : "add_sample",
  "D2LAB_FINISHED_GOODS" : "add_finished_goods",
  "D2LAB_BATCH_DEACTIV" : "batch_deactivation",
  "D2SPIR_PROCURE" : "add_spirit_procurement",
  "D2SPIR_RS_STOCK" : "add_rs_stock",
  "D2SPIR_RS_REDISTIL" : "add_rs_redistillation",
  "D2SPIR_ENA_STOCK" : "add_ena_stock",
  "D2SPIR_ENA_REDISTIL" : "add_ena_redistillation",
  "D2SPIR_BLEND_STOCK" : "add_blend_stock",
  "D2SPIR_BLEND_BOTTLE" : "add_blend_for_bottling",
  "D2HEAL_PURCHASE_IND" : "add_heal_purchase_indent",
  "D2HEAL_PURCHASE_IND_UPDATE" : "update_heal_purchase_indent",
  "D2HEAL_DAILY_IND" : "add_daily_indent",
  "D2HEAL_ISSUE_SPOOLS" : "issue_spools",
  "D2HEAL_MARK_DAMAGE" : "mark_heal_damage",
  "D2PROD_REPRINT_CASE" : "reprint_case",
  "D2PROD_BATCH" : "batch",
  "D2PROD_LINE" : "add_production_line",
  "D2PROD_LINE_EDIT" : "edit_production_line",
  "D2PROD_TARGET" : "target_prod",
  "D2PROD_HEAL_FEED" : "heal_feed",
  "D2PROD_START" : "start_prod",
  "D2PROD_STOP" : "stop_prod",
  "D2PROD_CASE_CODE" : "generate_case_code",
  "D2PROD_ADD_CASE" : "add_case",
  "D2PROD_ENABLE_PRINTER" : "enable_printer",
  "D2INDM_RS_ADD" : "add_rs_indent",
  "D2INDM_ENA_ADD" : "add_ena_indent",
  "D2INDM_BLEND_ADD" : "add_blend_indent",
  "D2INDM_OTSPIRIT_ADD" : "add_other_spirits_indent",
  "D2INDM_IFS_ADD" : "add_indent_for_supply",
  "D2EXC_REMIT_INFO" : "get_remittance_info",
  "D2EXC_DUTY_REMIT_ADD" : "add_excise_duty_remittance",
  "D2SHIP_TP_PRODS" : "get_tp_products",
  "D2SHIP_NEW_TP" : "add_new_tp",
  "D2SHIP_EDIT_TP" : "edit_tp",
  "D2SHIP_ADD_OFS" : "add_tp_ofs",
  "D2SHIP_TRACK_TP" : "track_tp",
  "D2SHIP_PACK_LIST" : "generate_packing_list",
  "D2SHIP_TP_CHANGE" : "tp_change_request",
  "D2SHIP_ADD_ROUTE" : "add_route",
  "D2CSD_IND_ADD" : "add_csd_indent",
  "D2CSD_IND_LOCN" : "add_csd_location"
}
