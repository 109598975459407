import { FeedbackService } from 'src/app/core/services/feedback.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './manage-issue-log-dialog.component.html',
  styleUrls: ['./manage-issue-log-dialog.component.scss']
})
export class ManageIssueLogDialogComponent implements OnInit {

  issueLogForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  sources = ['Source', 'Distillery', 'Location'];
  challanTypes = ['CSDs', 'Local', 'Breakage'];


  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageIssueLogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private feedbackService: FeedbackService) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.issueLogForm = this.fb.group({
      name: [''],
      contactNumber: [''],
      issue: [''],
      bankName: ['', [Validators.required, ]],
      challanType: ['', [Validators.required, ]],
      transactionDate: ['', [Validators.required, ]],
      description: ['', [Validators.required, ]],
    });
    if (this.data && this.data._id) {
      this.issueLogForm.patchValue({
        name : this.data.name,
        contactNumber: this.data.contactNumber,
        issue: this.data.issue,
        bankName: this.data.bankName,
        challanType: this.data.challanType,
        transactionDate: this.data.transactionDate,
        description: this.data.description,
      });
    }
    this.issueLogForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    })
  }

  get f(){
    return this.issueLogForm.controls;
  }

  createIssueLog() {
    this.saveDisabled = true;
    const req_Data = {
      action : '',
      data : this.issueLogForm.value
    }
    this.feedbackService.createIssueLog(req_Data).subscribe((res: any) => {
      this.dialogRef.close('Route Created Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  updateIssueLog() {
    // this.saveDisabled = true;
    // const obj = [{
    //   _id: this.data._id,
    //   update: this.issueLogForm.value
    // }]
    // this.spiritService.putBatch(obj).subscribe((res: any) => {
    //   this.dialogRef.close('Batch Udated Successfully');
    // }, (err) => {
    //   this.error = err.error.message;
    // });
  }


}
