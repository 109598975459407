export const labAnalysis = {
    getBlendLiquortypeData(liquorType){
        switch (liquorType) {
            case 'Whisky':
            return [
                {
                  characteristic: 'Alcohol content %v/v (Min)',
                  requirement: '42.86',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Residue on evaporation % (m/v) Max',
                  requirement: '2.0',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Volatile acids as acetic acid (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement: '50',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Esters as ethyl acetate (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement:'100',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Higher alcohols as amyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement: '350',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Aldehydes as acetaldehyde (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement:'15',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Furfural (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement: '6',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Copper (as Cu) parts per million (Max)',
                  requirement: '10',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Methyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement: '10',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                },
                
            ];
            case 'Vodka':
            return [
                    {
                      characteristic: 'Alcohol content %v/v (Min)',
                      requirement: '42.86',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Residue on evaporation % (m/v) Max',
                      requirement: '2.0',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Volatile acids as acetic acid (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement: '10',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Esters as ethyl acetate (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement: '50' ,
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Higher alcohols as amyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement:'50',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Aldehydes as acetaldehyde (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement: '20',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Furfural (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement: '12',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Copper (as Cu) parts per million (Max)',
                      requirement: '10',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Methyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement: '10',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                      // samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                    },
                    
            ];
            case 'Brandy':
            return [
                {
                  characteristic: 'Alcohol content %v/v (Min)',
                  requirement: '42.86',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Residue on evaporation % (m/v) Max',
                  requirement: '2.0',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Volatile acids as acetic acid (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement:'100',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Esters as ethyl acetate (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement:'100',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Higher alcohols as amyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement:'200',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Aldehydes as acetaldehyde (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement:'15',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Furfural (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement: '12',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Copper (as Cu) parts per million (Max)',
                  requirement: '10',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Methyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement: '10',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                },                
            ];
            case 'Rum':
            return [
                {
                  characteristic: 'Alcohol Content, %v/v, Min. *',
                  requirement: '42.86',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Residue on Evaporation, % (m/v), Max.',
                  requirement: '2',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Total Acids as Tartaric Acid (expressed in terms of g/100 litres of Absolute Alcohol), Max.',
                  requirement: '200',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Volatile Acids as Acetic Acid (expressed in terms of g/100 litres of Absolute Alcohol), Max.',
                  requirement: '50',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Esters as Ethyl Acetate (expressed in terms of g/100 litres of Absolute Alcohol), Max.',
                  requirement: '150',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Higher Alcohols as Amyl Alcohol (expressed in terms of g/100 litres of Absolute Alcohol) Max',
                  requirement: '350',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Aldehydes as Acetaldehyde (expressed in terms of g/100 litres of Absolute Alcohol) Max.',
                  requirement: '30',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Furfural (expressed in terms of g/100 litre of Absolute Alcohol) Max.',
                  requirement: '10',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Copper (as Cu), parts per million (Max)',
                  requirement: '10',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Methyl Alcohol (expressed in terms of g/100 litres of Absolute Alcohol), Max.',
                  requirement: '20',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                },
                
            ];
            case 'Malt/grain whisky':
            return [
                {
                  characteristic: 'Alcohol content %v/v (Min)',
                  requirement: '42.86',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Residue on evaporation % (m/v) Max',
                  requirement: '2.0',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Volatile acids as acetic acid (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement: '150',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Esters as ethyl acetate (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement:'200',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Higher alcohols as amyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement: '0',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Aldehydes as acetaldehyde (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement:'50',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Furfural (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement: '12',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Copper (as Cu) parts per million (Max)',
                  requirement: '10',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Methyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement: '30',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                },
                    
            ];
            case 'Blended malt/grain whisky':
            return [
                {
                  characteristic: 'Alcohol content %v/v (Min)',
                  requirement: '42.86',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Residue on evaporation % (m/v) Max',
                  requirement: '2.0',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Volatile acids as acetic acid (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement: '100',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Esters as ethyl acetate (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement:'150',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Higher alcohols as amyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement: '750',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Aldehydes as acetaldehyde (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement:'35',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Furfural (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement: '6',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Copper (as Cu) parts per million (Max)',
                  requirement: '10',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Methyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement: '20',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                },
                    
            ];
            case 'Blended grape brandy':
            return [
                {
                  characteristic: 'Alcohol content %v/v (Min)',
                  requirement: '42.86',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Residue on evaporation % (m/v) Max',
                  requirement: '2.0',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Volatile acids as acetic acid (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement:'100',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Esters as ethyl acetate (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement:'150',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Higher alcohols as amyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement:'350',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Aldehydes as acetaldehyde (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement:'45',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Furfural (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement: '12',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Copper (as Cu) parts per million (Max)',
                  requirement: '10',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Methyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                  requirement: '100',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                },                
            ];
            case 'White rum':
            return [
                {
                  characteristic: 'Alcohol Content, %v/v, Min. *',
                  requirement: '42.86',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Residue on Evaporation, % (m/v), Max.',
                  requirement: '1',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Total Acids as Tartaric Acid (expressed in terms of g/100 litres of Absolute Alcohol), Max.',
                  requirement: '200',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Volatile Acids as Acetic Acid (expressed in terms of g/100 litres of Absolute Alcohol), Max.',
                  requirement: '50',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Esters as Ethyl Acetate (expressed in terms of g/100 litres of Absolute Alcohol), Max.',
                  requirement: '150',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Higher Alcohols as Amyl Alcohol (expressed in terms of g/100 litres of Absolute Alcohol) Max',
                  requirement: '200',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Aldehydes as Acetaldehyde (expressed in terms of g/100 litres of Absolute Alcohol) Max.',
                  requirement: '30',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Furfural (expressed in terms of g/100 litre of Absolute Alcohol) Max.',
                  requirement: '5',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Copper (as Cu), parts per million (Max)',
                  requirement: '5',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Methyl Alcohol (expressed in terms of g/100 litres of Absolute Alcohol), Max.',
                  requirement: '10',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                },
                
            ];
            case 'Premix vodka':
            return [
                    {
                      characteristic: 'Alcohol content %v/v (Min)',
                      requirement: '42.86',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Residue on evaporation % (m/v) Max',
                      requirement: '25',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Volatile acids as acetic acid (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement: '10',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Esters as ethyl acetate (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement: '100' ,
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Higher alcohols as amyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement:'100',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Aldehydes as acetaldehyde (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement: '20',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Furfural (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement: '12',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Copper (as Cu) parts per million (Max)',
                      requirement: '10',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Methyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement: '10',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                      // samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                    },
                    
            ];
            case 'Premix rum':
            return [
                {
                  characteristic: 'Alcohol Content, %v/v, Min. *',
                  requirement: '42.86',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Residue on Evaporation, % (m/v), Max.',
                  requirement: '25',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Total Acids as Tartaric Acid (expressed in terms of g/100 litres of Absolute Alcohol), Max.',
                  requirement: '2000',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Volatile Acids as Acetic Acid (expressed in terms of g/100 litres of Absolute Alcohol), Max.',
                  requirement: '100',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Esters as Ethyl Acetate (expressed in terms of g/100 litres of Absolute Alcohol), Max.',
                  requirement: '150',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Higher Alcohols as Amyl Alcohol (expressed in terms of g/100 litres of Absolute Alcohol) Max',
                  requirement: '200',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Aldehydes as Acetaldehyde (expressed in terms of g/100 litres of Absolute Alcohol) Max.',
                  requirement: '30',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Furfural (expressed in terms of g/100 litre of Absolute Alcohol) Max.',
                  requirement: '5',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Copper (as Cu), parts per million (Max)',
                  requirement: '5',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                 
                },
                {
                  characteristic: 'Methyl Alcohol (expressed in terms of g/100 litres of Absolute Alcohol), Max.',
                  requirement: '10',
                  samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                },
                
            ];
            case 'Grape brandy':
            return [
                    {
                      characteristic: 'Alcohol content %v/v (Min)',
                      requirement: '42.86',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Residue on evaporation % (m/v) Max',
                      requirement: '2.0',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Volatile acids as acetic acid (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement:'100',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Esters as ethyl acetate (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement:'200',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Higher alcohols as amyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement:'600',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Aldehydes as acetaldehyde (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement:'45',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Furfural (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement: '12',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Copper (as Cu) parts per million (Max)',
                      requirement: '10',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Methyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement: '10',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                    },                
            ];
            case 'Gin':
            return [
                    {
                      characteristic: 'Alcohol content %v/v (Min)',
                      requirement: '42.86',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Residue on evaporation % (m/v) Max',
                      requirement: '2.0',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Volatile acids as acetic acid (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement:'100',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Esters as ethyl acetate (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement:'100',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Higher alcohols as amyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement:'200',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Aldehydes as acetaldehyde (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement:'15',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Furfural (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement: '12',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Copper (as Cu) parts per million (Max)',
                      requirement: '10',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Methyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement: '10',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                    },                
            ];
            case 'Premix gin':
            return [
                    {
                      characteristic: 'Alcohol content %v/v (Min)',
                      requirement: '42.86',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Residue on evaporation % (m/v) Max',
                      requirement: '2.0',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Volatile acids as acetic acid (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement:'100',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Esters as ethyl acetate (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement:'100',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Higher alcohols as amyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement:'200',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Aldehydes as acetaldehyde (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement:'15',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Furfural (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement: '12',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Copper (as Cu) parts per million (Max)',
                      requirement: '10',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                     
                    },
                    {
                      characteristic: 'Methyl alcohol (expressed in terms of g per 100 litres of absolute alcohol) (Max)',
                      requirement: '10',
                      samples: [{ brand: '', batchId: '', value: '', serialNo:'',sampleStatus:'' }],
                    },                
            ];
        }
    },
    getEnaLiquortypeData(){
        return [
            {
              characteristic: 'Relative Density at 20/20 degree celsius',
              requirement: '0.80692',
              // value:''
              samples: [{ descriptionOfSample: '', dtpNoLotNo: '', value: '',serialNo:'',sampleStatus:'' }],
             
            },
            {
              characteristic: 'Ethanol % (v/v at 20 degree celsius) Min',
              requirement: '96%',
              //  value:''
              samples: [{ descriptionOfSample: '', dtpNoLotNo: '', value: '',serialNo:'',sampleStatus:'' }],
             
            },
            {
              characteristic: 'Ethanol miscibility in water',
              requirement: 'Miscible',
              //  value:''
              samples: [{ descriptionOfSample: '', dtpNoLotNo: '', value: '',serialNo:'',sampleStatus:'' }],
             
            },
            {
              characteristic: 'Acidity as acetic acid, g/100 Lts, absolute alcohol, Max',
              requirement: '1.5',
              //  value:''
              samples: [{ descriptionOfSample: '', dtpNoLotNo: '', value: '',serialNo:'',sampleStatus:'' }],
             
            },
            {
              characteristic: 'Residue on evaporation, g/100 Lts, absolute alcohol, Max',
              requirement: '1.5',
              //  value:''
              samples: [{ descriptionOfSample: '', dtpNoLotNo: '', value: '',serialNo:'',sampleStatus:'' }],
             
            },
            {
              characteristic: 'Easters as CH3 COOC2H5,G/100 Lts. absolute alcohol, Max',
              requirement: '1.3',
              //  value:''
              samples: [{ descriptionOfSample: '', dtpNoLotNo: '', value: '',serialNo:'',sampleStatus:'' }],
             
            },
            {
              characteristic: 'Lead, g/100 Lt. Absolute alcohol, Max',
              requirement: '0.1',
              //  value:''
              samples: [{ descriptionOfSample: '', dtpNoLotNo: '', value: '',serialNo:'',sampleStatus:'' }],
             
            },
            {
              characteristic: 'Methyl alcohol g/100 Lt. of absolute alcohol',
              requirement: '50',
              //  value:''
              samples: [{ descriptionOfSample: '', dtpNoLotNo: '', value: '',serialNo:'',sampleStatus:'' }],
             
            },
            {
              characteristic: 'Furfural',
              requirement: 'Not Detectable',
              //  value:''
              samples: [{ descriptionOfSample: '', dtpNoLotNo: '', value: '',serialNo:'',sampleStatus:'' }],
            },
            {
              characteristic: 'Aldehyde as acetaldehyde g/100 Lt. of absolute alcohol, Max',
              requirement: '0.0005',
              //  value:''
              samples: [{ descriptionOfSample: '', dtpNoLotNo: '', value: '',serialNo:'',sampleStatus:'' }],
            },
            {
              characteristic: 'Permanganate reaction time(in minutes)',
              requirement: '30',
              //  value:''
              samples: [{ descriptionOfSample: '', dtpNoLotNo: '', value: '',serialNo:'',sampleStatus:'' }],
            },
            {
              characteristic: 'Copper (as Cu),g/100 Lts.',
              requirement: '0.002',
              //  value:''
              samples: [{ descriptionOfSample: '', dtpNoLotNo: '', value: '',serialNo:'',sampleStatus:'' }],
            },
            {
              characteristic: 'Higher alcohol as iso-amyl alcohol,g/100 Lts.',
              requirement: '30',
              //  value:''
              samples: [{ descriptionOfSample: '', dtpNoLotNo: '', value: '',serialNo:'',sampleStatus:'' }],
            },
            
          ];
    }
}