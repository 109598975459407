import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const baseUrl = environment.baseUrl;
const outletsUrl = baseUrl + 'outlets';
const outletAbstractUrl = baseUrl + 'outletabstractreport';
const bclUrl = baseUrl + 'bcl_outlet';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['BCL Outlet'] && userModules.mod_feats['BCL Outlet'].features ? userModules.mod_feats['BCL Outlet'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class BclOutletsService {

  currentUser: any;

  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(
      sessionStorage.getItem('lappCurrentUserDetails')
    );
  }

  getOutletsDetails(offset,limit,search) {
    return this.http.get('assets/samples/outlets.json');
  }

  getOutletProfiles(offset, limit, search){
    return this.http.get('assets/samples/outlet-profiles.json');
  }

  getOutletSales(offset, limit, search){
    return this.http.get('assets/samples/outlet-sales.json');
  }

  getOutletReturn(offset, limit, search){
    return this.http.get('assets/samples/outlet-returns.json');
  }

  getOutletStatus(offset, limit, search){
    return this.http.get('assets/samples/outlet-status.json');
  }
  getOutletAbsctractDetails(search?: string): Observable<any>{
    const paramObj: any = {};
    search ? paramObj.search = search : search = null;
    return this.http.get(outletAbstractUrl, {params: paramObj});
  }

  getBCLOutletFeatures() {
    const headerDict = {
      feature
    }
    return this.http.get(bclUrl, { headers: headerDict });
  }

}
