import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Form3BreakagesEntry } from 'src/app/core/models/stock-management/form3-breakages-entry.model';
import { StockManagementService } from 'src/app/core/services/stock-management.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-form3-breakages-entry',
  templateUrl: './form3-breakages-entry.component.html',
  styleUrls: ['./form3-breakages-entry.component.scss']
})
export class Form3BreakagesEntryComponent implements OnInit, OnChanges {
  @Input() writeAccess: boolean;
  @Input() actions: string[];
  @Output() refreshPage = new EventEmitter<boolean>();
  @Input() featuresData: any;

  displayedColumns: string[] = ['no', 'indentNumber', 'tpIndentNumber', 'retailerName', 'tpGenerateDate', 'action'];
  dataSource: MatTableDataSource<Form3BreakagesEntry> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;
  form3BreakagesEntryLength: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  error: string;

  constructor(
    public util: UtilService, private stockManagementService: StockManagementService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.limit = 10;
    this.offset = 0;
    this.getForm3BreakagesEntryDetails();
  }
  ngOnChanges(): void {
    this.getForm3BreakagesEntryDetails();
  }

  getForm3BreakagesEntryDetails() {
    if (this.featuresData && this.featuresData.length > 0) {
      this.dataSource = new MatTableDataSource(this.featuresData);
      this.form3BreakagesEntryLength = this.featuresData.length;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  ngAfterViewInit() {
    this.util.ngAfterViewInit(this.dataSource, this.paginator, this.sort, null, null);
  }

  applyFilter() {
    this.util.applyFilter(this.dataSource, this.search);
  }




  convertToDate(date) {
    return moment(date).format('DD/MM/YY, h:mm a');
  }

  onPaginateChange(e) {
    this.limit = e.pageSize;
    this.offset = e.pageIndex * e.pageSize;
    this.getForm3BreakagesEntryDetails();
  }

}
