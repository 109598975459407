import { VendorIndentStatusDialogComponent } from './../../features/indent-management/vendor-indents/vendor-indent-status-dialog/vendor-indent-status-dialog.component';
import { CfmsPaymentVerifyDialogComponent } from './../../features/payment/cfms-payment/cfms-payment-verify-dialog/cfms-payment-verify-dialog.component';
import { IDTReceiptOfidtInfoDialogComponent } from './../../features/inter-depot/inter-depot-transfer/idt-receipt-dialog/idtreceipt-ofidt-info-dialog/idtreceipt-ofidt-info-dialog.component';
import { IDTReceiptInvoiceInfoDialogComponent } from './../../features/inter-depot/inter-depot-transfer/idt-receipt-dialog/idtreceipt-invoice-info-dialog/idtreceipt-invoice-info-dialog.component';
import { IDTReceiptShipmentShipmentInfoDialogComponent } from './../../features/inter-depot/inter-depot-transfer/idt-receipt-dialog/idtreceipt-shipment-shipment-info-dialog/idtreceipt-shipment-shipment-info-dialog.component';
import { IDTReceiptGatepassInfoDialogComponent } from './../../features/inter-depot/inter-depot-transfer/idt-receipt-dialog/idtreceipt-gatepass-info-dialog/idtreceipt-gatepass-info-dialog.component';
import { IDTReceiptChecklistInfoDialogComponent } from './../../features/inter-depot/inter-depot-transfer/idt-receipt-dialog/idtreceipt-checklist-info-dialog/idtreceipt-checklist-info-dialog.component';
import { IDTReceiptEvcInfoDialogComponent } from './../../features/inter-depot/inter-depot-transfer/idt-receipt-dialog/idtreceipt-evc-info-dialog/idtreceipt-evc-info-dialog.component';
import { IDTReceiptApnDetailsDialogComponent } from './../../features/inter-depot/inter-depot-transfer/idt-receipt-dialog/idtreceipt-apn-details-dialog/idtreceipt-apn-details-dialog.component';
import { ManageRetailersUserDialogComponent } from './../../features/user-roles/retailers/manage-retailers-user-dialog/manage-retailers-user-dialog.component';
import { ManageSegmentRegistrationDialogComponent } from './../../features/brand-registration/brand-registration-feature/manage-segment-registration-dialog/manage-segment-registration-dialog.component';
import { ManageInterRetailerTransferSupplierComponent } from './../../features/inter-retailer/manage-inter-retailer-transfer-supplier/manage-inter-retailer-transfer-supplier.component';
import { ManageVendorsFeatureDialogComponent } from './../../features/user-roles/vendors/manage-vendors-feature-dialog/manage-vendors-feature-dialog.component';
import { ManageInwardGateRegisterDialogComponent } from './../../features/gate-register/inward-register/manage-inward-gate-register-dialog/manage-inward-gate-register-dialog.component';
import { Injectable } from '@angular/core';
import { ManageOutwardGateRegisterDialogComponent } from 'src/app/features/gate-register/outward-register/manage-outward-gate-register-dialog/manage-outward-gate-register-dialog.component';
import { OpenIndentsDialogComponent } from 'src/app/features/bcl-outlet/indents/open-indents-dialog/open-indents-dialog.component';
import { OpenRetailerNamesDialogComponent } from 'src/app/features/bcl-outlet/outlet-sales/open-retailer-names-dialog/open-retailer-names-dialog.component';
import { ApnDetailsDialogComponent } from 'src/app/features/beer/beer-consignment/apn-details-dialog/apn-details-dialog.component';
import { OpenInvoiceDetailsDialogComponent } from 'src/app/features/beer/beer-consignment/open-invoice-details-dialog/open-invoice-details-dialog.component';
import { OpenOfsDetailsDialogComponent } from 'src/app/features/beer/beer-consignment/open-ofs-details-dialog/open-ofs-details-dialog.component';
import { OpenTpDetailsDialogComponent } from 'src/app/features/beer/beer-consignment/open-tp-details-dialog/open-tp-details-dialog.component';
import { BeerProductCodeDialogComponent } from 'src/app/features/beer/beer-feature/beer-product-code-dialog/beer-product-code-dialog.component';
import { ManageBrandRegistrationDialogComponent } from 'src/app/features/brand-registration/brand-registration-feature/manage-brand-registration-dialog/manage-brand-registration-dialog.component';
import { ManageTransitDialogComponent } from 'src/app/features/breakages/transit/manage-transit-dialog/manage-transit-dialog.component';
import { ManageCsdFeatureDialogComponent } from 'src/app/features/csd/csd-feature/manage-csd-feature-dialog/manage-csd-feature-dialog.component';
import { ManageCsdDialogComponent } from 'src/app/features/csd/csd-indents/manage-csd-dialog/manage-csd-dialog.component';
import { StatusCsdDialogComponent } from 'src/app/features/csd/csd-indents/status-csd-dialog/status-csd-dialog.component';
import { ManageCsdLocationsDialogComponent } from 'src/app/features/csd/csd-locations/manage-csd-locations-dialog/manage-csd-locations-dialog.component';
import { ManageBlendTankDialogComponent } from 'src/app/features/distillery-info/blend-tanks/manage-blend-tank-dialog/manage-blend-tank-dialog.component';
import { ManageBottlingLineDialogComponent } from 'src/app/features/distillery-info/bottling-lines/manage-bottling-line-dialog/manage-bottling-line-dialog.component';
import { ManageGateRegisterDialogComponent } from 'src/app/features/distillery-info/gate-register/manage-gate-register-dialog/manage-gate-register-dialog.component';
import { ManageStaffUsersDialogComponent } from 'src/app/features/distillery-info/staff-users/manage-staff-users-dialog/manage-staff-users-dialog.component';
import { ManageRemitanceDialogComponent } from 'src/app/features/excise-duty/remitance/manage-remitance-dialog/manage-remitance-dialog.component';
import { LicenseeBankDetailsForBarsDialogComponent } from 'src/app/features/excise-licensee/bars/licensee-bank-details-for-bars-dialog/licensee-bank-details-for-bars-dialog.component';
import { EditRegistrationFormDialogComponent } from 'src/app/features/excise-licensee/shared/components/edit-registration-form-dialog/edit-registration-form-dialog.component';
import { ShowAddressDetailsDialogComponent } from 'src/app/features/excise-licensee/shared/components/show-address-details-dialog/show-address-details-dialog.component';
import { ShowBankDetailsDialogComponent } from 'src/app/features/excise-licensee/shared/components/show-bank-details-dialog/show-bank-details-dialog.component';
import { LicenseeAddressDetailsDialogComponent } from 'src/app/features/excise-licensee/shops/licensee-address-details-dialog/licensee-address-details-dialog.component';
import { LicenseeBankDetailsDialogComponent } from 'src/app/features/excise-licensee/shops/licensee-bank-details-dialog/licensee-bank-details-dialog.component';
import { BankDetailsForTwofDialogComponent } from 'src/app/features/excise-licensee/two-f/bank-details-for-twof-dialog/bank-details-for-twof-dialog.component';
import { EditRegistrationForTwofDialogComponent } from 'src/app/features/excise-licensee/two-f/edit-registration-for-twof-dialog/edit-registration-for-twof-dialog.component';
import { LicenseeDetailsForTwofDialogComponent } from 'src/app/features/excise-licensee/two-f/licensee-details-for-twof-dialog/licensee-details-for-twof-dialog.component';
import { ExportFeatureDetailsDialogComponent } from 'src/app/features/export/export-feature/export-feature-details-dialog/export-feature-details-dialog.component';
import { ManageAdminFeatureDialogComponent } from 'src/app/features/feature-permissions/admin-feature/manage-admin-feature-dialog/manage-admin-feature-dialog.component';
import { ManageDepotFeatureDialogComponent } from 'src/app/features/feature-permissions/depot-feature/manage-depot-feature-dialog/manage-depot-feature-dialog.component';
import { ManageDistilleryFeatureDialogComponent } from 'src/app/features/feature-permissions/distillery-feature/manage-distillery-feature-dialog/manage-distillery-feature-dialog.component';
import { ManageRetailerFeatureDialogComponent } from 'src/app/features/feature-permissions/retailers-feature/manage-retailer-feature-dialog/manage-retailer-feature-dialog.component';
import { ManageIssueLogDialogComponent } from 'src/app/features/feedback/issue-log/manage-issue-log-dialog/manage-issue-log-dialog.component';
import { OpenDescriptionDialogComponent } from 'src/app/features/gate-register/inward-register/open-description-dialog/open-description-dialog.component';
import { OpenDepotStatusDialogComponent } from 'src/app/features/holograms/depot-in-holograms/open-depot-status-dialog/open-depot-status-dialog.component';
import { DistilleryHologramStatusDetailsDialogComponent } from 'src/app/features/holograms/distillery-in-holograms/distillery-hologram-status-details-dialog/distillery-hologram-status-details-dialog.component';
import { ManageHealDailyIndentDialogComponent } from 'src/app/features/holograms/heal-daily-indent/manage-heal-daily-indent-dialog/manage-heal-daily-indent-dialog.component';
import { ScanSpoolsDialogComponent } from 'src/app/features/holograms/heal-daily-indent/scan-spools-dialog/scan-spools-dialog.component';
import { ManageHealDamageDialogComponent } from 'src/app/features/holograms/heal-damage/manage-heal-damage-dialog/manage-heal-damage-dialog.component';
import { ManageHealPurchaseIndentDialogComponent } from 'src/app/features/holograms/heal-purchase-indent/manage-heal-purchase-indent-dialog/manage-heal-purchase-indent-dialog.component';
import { CancelImportPermitDetailsDialogComponent } from 'src/app/features/import/cancel-import-permit/cancel-import-permit-details-dialog/cancel-import-permit-details-dialog.component';
import { ImportIndentsDetailsDialogComponent } from 'src/app/features/import/import-indents/import-indents-details-dialog/import-indents-details-dialog.component';
import { ManageShipmentDialogComponent } from 'src/app/features/import/shipment/manage-shipment-dialog/manage-shipment-dialog.component';
import { ShipmentDetailsDialogComponent } from 'src/app/features/import/shipment/shipment-details-dialog/shipment-details-dialog.component';
import { ManageBlendIndentDialogComponent } from 'src/app/features/indent-management/blend-indent/manage-blend-indent-dialog/manage-blend-indent-dialog.component';
import { ManageDistilleryIndentDialogComponent } from 'src/app/features/indent-management/distillery-indent/manage-distillery-indent-dialog/manage-distillery-indent-dialog.component';
import { OpenDistilleryIndentStatusDialogComponent } from 'src/app/features/indent-management/distillery-indent/open-distillery-indent-status-dialog/open-distillery-indent-status-dialog.component';
import { ManageEnaIndentDialogComponent } from 'src/app/features/indent-management/ena-indent/manage-ena-indent-dialog/manage-ena-indent-dialog.component';
import { AddEventPermitDialogComponent } from 'src/app/features/indent-management/event-permit/add-event-permit-dialog/add-event-permit-dialog.component';
import { IcdcScanStatusDialogComponent } from 'src/app/features/indent-management/icdc/icdc-scan-status-dialog/icdc-scan-status-dialog.component';
import { ManageIcdcDialogComponent } from 'src/app/features/shipments/icdc/manage-icdc-dialog/manage-icdc-dialog.component';
import { LicenseeStatusDialogComponent } from 'src/app/features/indent-management/licensee-indents/licensee-status-dialog/licensee-status-dialog.component';
import { ManageLicenseeIndentsDialogComponent } from 'src/app/features/indent-management/licensee-indents/manage-licensee-indents-dialog/manage-licensee-indents-dialog.component';
import { ManageOtherSpiritIndentDialogComponent } from 'src/app/features/indent-management/other-spirit-indent/manage-other-spirit-indent-dialog/manage-other-spirit-indent-dialog.component';
import { ManageRsIndentDialogComponent } from 'src/app/features/indent-management/rs-indent/manage-rs-indent-dialog/manage-rs-indent-dialog.component';
import { ManageVendorIndentsDialogComponent } from 'src/app/features/indent-management/vendor-indents/manage-vendor-indents-dialog/manage-vendor-indents-dialog.component';
import { IcdcReasonDialogComponent } from 'src/app/features/inter-depot/cancelled-icdc/icdc-reason-dialog/icdc-reason-dialog.component';
import { AsnDetailsForDepotReceiptDialogComponent } from 'src/app/features/inter-depot/inter-depot-receipt/asn-details-for-depot-receipt-dialog/asn-details-for-depot-receipt-dialog.component';
import { ManageInterDepotSupplierDialogComponent } from 'src/app/features/inter-depot/inter-depot-transfer-supplier/manage-inter-depot-supplier-dialog/manage-inter-depot-supplier-dialog.component';
import { EvcDetailsDialogComponent } from 'src/app/features/inter-depot/inter-depot-transfer/evc-details-dialog/evc-details-dialog.component';
import { GrnDetailsDialogComponent } from 'src/app/features/inter-depot/inter-depot-transfer/grn-details-dialog/grn-details-dialog.component';
import { OfidtReasonDialogComponent } from 'src/app/features/inter-depot/ofidt/ofidt-reason-dialog/ofidt-reason-dialog.component';
import { OrdersDetailsDialogComponent } from 'src/app/features/inter-depot/orders/orders-details-dialog/orders-details-dialog.component';
import { FailedSamplesDialogComponent } from 'src/app/features/lab/failed-samples/failed-samples-dialog/failed-samples-dialog.component';
import { ManageFinishedGoodsDialogComponent } from 'src/app/features/lab/finished-goods/manage-finished-goods-dialog/manage-finished-goods-dialog.component';
import { ManageSamplesDialogComponent } from 'src/app/features/lab/samples/manage-samples-dialog/manage-samples-dialog.component';
import { OpenSamplesStatusDialogComponent } from 'src/app/features/lab/samples/open-samples-status-dialog/open-samples-status-dialog.component';
import { ManageLabelRegistrationDialogComponent } from 'src/app/features/label-registration/label-registration-feature/manage-label-registration-dialog/manage-label-registration-dialog.component';
import { OpenBarsBankDetailsDialogComponent } from 'src/app/features/licensee-profiles/bars/open-bars-bank-details-dialog/open-bars-bank-details-dialog.component';
import { OpenBarsCustomerDetailsDialogComponent } from 'src/app/features/licensee-profiles/bars/open-bars-customer-details-dialog/open-bars-customer-details-dialog.component';
import { OpenClubsBankDetailsDialogComponent } from 'src/app/features/licensee-profiles/clubs/open-clubs-bank-details-dialog/open-clubs-bank-details-dialog.component';
import { OpenClubsCustomerDetailsDialogComponent } from 'src/app/features/licensee-profiles/clubs/open-clubs-customer-details-dialog/open-clubs-customer-details-dialog.component';
import { OpenBankDetailsDialogComponent } from 'src/app/features/licensee-profiles/shops/open-bank-details-dialog/open-bank-details-dialog.component';
import { OpenCustomerDetailsDialogComponent } from 'src/app/features/licensee-profiles/shops/open-customer-details-dialog/open-customer-details-dialog.component';
import { OpenTargetDialogComponent } from 'src/app/features/licensee-profiles/shops/open-target-dialog/open-target-dialog.component';
import { OpenTd1BankDetailsDialogComponent } from 'src/app/features/licensee-profiles/td1/open-td1-bank-details-dialog/open-td1-bank-details-dialog.component';
import { OpenTd1CustomerDetailsDialogComponent } from 'src/app/features/licensee-profiles/td1/open-td1-customer-details-dialog/open-td1-customer-details-dialog.component';
import { OpenTwotBankDetailsDialogComponent } from 'src/app/features/licensee-profiles/two-t/open-twot-bank-details-dialog/open-twot-bank-details-dialog.component';
import { OpenTwotCustomerDetailsDialogComponent } from 'src/app/features/licensee-profiles/two-t/open-twot-customer-details-dialog/open-twot-customer-details-dialog.component';
import { ManageLocationDialogComponent } from 'src/app/features/locations/location/manage-location-dialog/manage-location-dialog.component';
import { ImportPackingActionDialogComponent } from 'src/app/features/packing/packing-feature/import-packing-action-dialog/import-packing-action-dialog.component';
import { ManageBatchProductionDialogComponent } from 'src/app/features/production/batch-production/manage-batch-production-dialog/manage-batch-production-dialog.component';
import { ManageBatchesDialogComponent } from 'src/app/features/production/batches/manage-batches-dialog/manage-batches-dialog.component';
import { ManageProductionLineDialogComponent } from 'src/app/features/production/production-line/manage-production-line-dialog/manage-production-line-dialog.component';
import { ProductionLineDetailsDialogComponent } from 'src/app/features/production/production-line/production-line-details-dialog/production-line-details-dialog.component';
import { TargetProductionDetailsDialogComponent } from 'src/app/features/production/production-line/target-production-details-dialog/target-production-details-dialog.component';
import { BrandBarcodeDetailsDialogComponent } from 'src/app/features/products/brand-barcodes/brand-barcode-details-dialog/brand-barcode-details-dialog.component';
import { ProductStatusDialogComponent } from 'src/app/features/products/product-prices/product-status-dialog/product-status-dialog.component';
import { AddRationingDialogComponent } from 'src/app/features/rationing/rationing-feature/add-rationing-dialog/add-rationing-dialog.component';
import { ShowIcdcDetailsDialogComponent } from 'src/app/features/shipments/depot-shipment/show-icdc-details-dialog/show-icdc-details-dialog.component';
import { ShowTpDetailsDialogComponent } from 'src/app/features/shipments/depot-shipment/show-tp-details-dialog/show-tp-details-dialog.component';
import { IcdcInfoDialogComponent } from 'src/app/features/shipments/icdc/icdc-info-dialog/icdc-info-dialog.component';
import { IcdcCaseBarcodeDialogComponent } from 'src/app/features/shipments/licensee-shipments/case-barcode-dialog/icdc-case-barcode-dialog.component';
import { IcdcDetailDialogComponent } from 'src/app/features/shipments/licensee-shipments/icdc-details-dialog/icdc-details-dialog.component';
import { ManageRepackingDialogComponent } from 'src/app/features/shipments/manage-repacking-dialog/manage-repacking-dialog.component';
import { OpenOfsDialogComponent } from 'src/app/features/shipments/ofs/open-ofs-dialog/open-ofs-dialog.component';
import { ManageRouteDialogComponent } from 'src/app/features/shipments/route/manage-route-dialog/manage-route-dialog.component';
import { CaseBarcodeDialogComponent } from 'src/app/features/shipments/shipments-feature/case-barcode-dialog/case-barcode-dialog.component';
import { IpStatusDialogComponent } from 'src/app/features/shipments/shipments-feature/ip-status-dialog/ip-status-dialog.component';
import { ManageIpRequestDialogComponent } from 'src/app/features/shipments/shipments-feature/manage-ip-request-dialog/manage-ip-request-dialog.component';
import { ManageTpRequestDialogComponent } from 'src/app/features/shipments/shipments-feature/manage-tp-request-dialog/manage-tp-request-dialog.component';
import { OfsDetailsDialogComponent } from 'src/app/features/shipments/shipments-feature/ofs-details-dialog/ofs-details-dialog.component';
import { AsnDetailsForSupplierShipmentDialogComponent } from 'src/app/features/shipments/supplier-shipments/asn-details-for-supplier-receipt-dialog/asn-details-for-supplier-shipment-dialog.component';
import { SupplierAdvancedInboundReportsDialogComponent } from 'src/app/features/shipments/supplier-shipments/supplier-advanced-inbound-reports-dialog/supplier-advanced-inbound-reports-dialog.component';
import { ManageTpVehicleAccidentDialogComponent } from 'src/app/features/shipments/tp-vehicle-accident/manage-tp-vehicle-accident-dialog/manage-tp-vehicle-accident-dialog.component';
import { ManageBlendStockDialogComponent } from 'src/app/features/spirit-register/blend/manage-blend-stock-dialog/manage-blend-stock-dialog.component';
import { ManageEnaStockDialogComponent } from 'src/app/features/spirit-register/ena/manage-ena-stock-dialog/manage-ena-stock-dialog.component';
import { ManageProcurementDialogComponent } from 'src/app/features/spirit-register/procurement/manage-procurement-dialog/manage-procurement-dialog.component';
import { ProcurementStatusDialogComponent } from 'src/app/features/spirit-register/procurement/procurement-status-dialog/procurement-status-dialog.component';
import { ManageRsStockDialogComponent } from 'src/app/features/spirit-register/rs/manage-rs-stock-dialog/manage-rs-stock-dialog.component';
import { ManageBreakagesEntryDialogComponent } from 'src/app/features/stock-management/manage-breakages-entry-dialog/manage-breakages-entry-dialog.component';
import { ManageF4BreakagesEntryDialogComponent } from 'src/app/features/stock-management/manage-f4-breakages-entry-dialog/manage-f4-breakages-entry-dialog.component';
import { AddBrandBarcodeDialogComponent } from 'src/app/features/stock/brand-barcode/add-brand-barcode-dialog/add-brand-barcode-dialog.component';
import { ManageHoldOnStockDialogComponent } from 'src/app/features/stock/hold-on-stock/manage-hold-on-stock-dialog/manage-hold-on-stock-dialog.component';
import { ClosingStockDetailsDialogComponent } from 'src/app/features/stock/local/closing-stock-details-dialog/closing-stock-details-dialog.component';
import { ProductCodeDialogComponent } from 'src/app/features/stock/local/product-code-dialog/product-code-dialog.component';
import { ProductDetailsDialogComponent } from 'src/app/features/stock/stock-inventory/product-details-dialog/product-details-dialog.component';
import { OpenSupplierDialogComponent } from 'src/app/features/users/supplier/open-supplier-dialog/open-supplier-dialog.component';
import { HealCodesDialogComponent } from '../commons/heal-codes-dialog/heal-codes-dialog.component';
import { OutletReturnStockDialogComponent } from 'src/app/features/stock/outlet-return-stock/outlet-return-stock-dialog/outlet-return-stock-dialog.component';
import { EditRegistrationForBarsComponent } from 'src/app/features/excise-licensee/bars/edit-registration-for-bars/edit-registration-for-bars.component';
import { LicenseeAddressDetailsForBarsComponent } from 'src/app/features/excise-licensee/bars/licensee-address-details-for-bars/licensee-address-details-for-bars.component';
import { EditRegistrationFormComponent } from 'src/app/features/excise-licensee/shops/edit-registration-form/edit-registration-form.component';
import { IssuedSpoolsDialogComponent } from 'src/app/features/holograms/heal-daily-indent/issued-spools-dialog/issued-spools-dialog.component';
import { CartoonBarcodeDialogComponent } from 'src/app/features/holograms/heal-inventory/cartoon-barcode-dialog/cartoon-barcode-dialog.component';
import { HealPurchaseIndentStatusDetailsDialogComponent } from 'src/app/features/holograms/heal-purchase-indent/heal-purchase-indent-status-details-dialog/heal-purchase-indent-status-details-dialog.component';
import { IcdcStatusDialogComponent } from 'src/app/features/inter-depot/cancelled-icdc/icdc-status-dialog/icdc-status-dialog.component';
import { OpenChecklistDetailsDialogComponent } from 'src/app/features/inter-depot/inter-depot-receipt/open-checklist-details-dialog/open-checklist-details-dialog.component';
import { OpenDepotReceiptDetailsDialogComponent } from 'src/app/features/inter-depot/inter-depot-receipt/open-depot-receipt-details-dialog/open-depot-receipt-details-dialog.component';
import { OpenGatepassDetailsDialogComponent } from 'src/app/features/inter-depot/inter-depot-receipt/open-gatepass-details-dialog/open-gatepass-details-dialog.component';
import { OpenIcdcDetailsDialogComponent } from 'src/app/features/inter-depot/inter-depot-receipt/open-icdc-details-dialog/open-icdc-details-dialog.component';
import { OpenLabDetailsDialogComponent } from 'src/app/features/inter-depot/inter-depot-receipt/open-lab-details-dialog/open-lab-details-dialog.component';
import { OpenOfidtDetailsDialogComponent } from 'src/app/features/inter-depot/inter-depot-receipt/open-ofidt-details-dialog/open-ofidt-details-dialog.component';
import { OpenStockRecentDetailsDialogComponent } from 'src/app/features/inter-depot/inter-depot-receipt/open-stock-recent-details-dialog/open-stock-recent-details-dialog.component';
import { OpenTpForIdrDialogComponent } from 'src/app/features/inter-depot/inter-depot-receipt/open-tp-for-idr-dialog/open-tp-for-idr-dialog.component';
import { InterDepotTransferLocalDialogDetailsComponent } from 'src/app/features/inter-depot/inter-depot-transfer-local/inter-depot-transfer-local-dialog-details/inter-depot-transfer-local-dialog-details.component';
import { ManageInterDepotTransferLocalComponent } from 'src/app/features/inter-depot/inter-depot-transfer-local/manage-inter-depot-transfer-local/manage-inter-depot-transfer-local.component';
import { InterDepotTrnasferSuplierDialogDetailsComponent } from 'src/app/features/inter-depot/inter-depot-transfer-supplier/inter-depot-trnasfer-suplier-dialog-details/inter-depot-trnasfer-suplier-dialog-details.component';
import { OpenIdtStatusDialogComponent } from 'src/app/features/inter-depot/inter-depot-transfer/open-idt-status-dialog/open-idt-status-dialog.component';
import { OfidtStatusDialogComponent } from 'src/app/features/inter-depot/ofidt/ofidt-status-dialog/ofidt-status-dialog.component';
import { OpenFinishedGoodsStatusDialogComponent } from 'src/app/features/lab/finished-goods/open-finished-goods-status-dialog/open-finished-goods-status-dialog.component';
import { BatchProductionInfoComponent } from 'src/app/features/production/batch-production/batch-production-info/batch-production-info.component';
import { SpoolDetailsDialogComponent } from 'src/app/features/production/production-line/spool-details-dialog/spool-details-dialog.component';
import { AddBrandBarcodeComponent } from 'src/app/features/products/product-prices/add-brand-barcode/add-brand-barcode.component';
import { ManageTpDetailsComponent } from 'src/app/features/shipments/distillery-shipment/manage-tp-details/manage-tp-details.component';
import { IcdcPackingListDialogComponent } from 'src/app/features/shipments/licensee-shipments/icdc-packing-list-dialog/icdc-packing-list-dialog.component';
import { ApnDetailsForSupplierDialogComponent } from 'src/app/features/shipments/supplier-shipments/apn-details-for-supplier-dialog/apn-details-for-supplier-dialog.component';
import { SupplierChecklistInfoDialogComponent } from 'src/app/features/shipments/supplier-shipments/supplier-checklist-info-dialog/supplier-checklist-info-dialog.component';
import { SupplierEvcInfoDialogComponent } from 'src/app/features/shipments/supplier-shipments/supplier-evc-info-dialog/supplier-evc-info-dialog.component';
import { SupplierGatepassInfoDialogComponent } from 'src/app/features/shipments/supplier-shipments/supplier-gatepass-info-dialog/supplier-gatepass-info-dialog.component';
import { SupplierInvoiceInfoDialogComponent } from 'src/app/features/shipments/supplier-shipments/supplier-invoice-info-dialog/supplier-invoice-info-dialog.component';
import { SupplierOfsInfoDialogComponent } from 'src/app/features/shipments/supplier-shipments/supplier-ofs-info-dialog/supplier-ofs-info-dialog.component';
import { SupplierShipmentShipmentInfoDialogComponent } from 'src/app/features/shipments/supplier-shipments/supplier-shipment-shipment-info-dialog/supplier-shipment-shipment-info-dialog.component';
import { Form3BreakagesEntryComponent } from 'src/app/features/stock-management/form3-breakages-entry/form3-breakages-entry.component';
import { TpDetailsDialogComponent } from 'src/app/features/tp-revalidation/tp-revalidation-feature/tp-details-dialog/tp-details-dialog.component';
import { StatusDetailsDialogComponent } from '../commons/status-details-dialog';
import { ManageVendorsDialogComponent } from 'src/app/features/locations/manage-vendors-dialog/manage-vendors-dialog.component';
import { ManageLocationCsdDialogComponent } from 'src/app/features/locations/manage-location-csd-dialog/manage-location-csd-dialog.component';
import { ManageDepotsDialogComponent } from 'src/app/features/locations/manage-depots-dialog/manage-depots-dialog.component';
import { ManageLabsDialogComponent } from 'src/app/features/locations/manage-labs-dialog/manage-labs-dialog.component';
import { OpenDepotDialogComponent } from 'src/app/features/users/depot/open-depot-dialog/open-depot-dialog.component';
import { OpenApbclDialogComponent } from 'src/app/features/users/apbcl/open-apbcl-dialog/open-apbcl-dialog.component';
import { OpenVendorDialogComponent } from 'src/app/features/users/open-vendor-dialog/open-vendor-dialog.component';
import { OpenBasicPricesDialogComponent } from 'src/app/features/liquor-pricing/open-basic-prices-dialog/open-basic-prices-dialog.component';
import { OtpVerificationDialogComponent } from '../components/otp-verification-dialog/otp-verification-dialog.component';
import { ManageLabelDocumentsVerificationDialogComponent } from 'src/app/features/label-registration/label-registration-feature/manage-label-documents-verification-dialog/manage-label-documents-verification-dialog.component';
import { HologramSpoolsDetailsDialogComponent } from 'src/app/features/holograms/hologram-spools-details-dialog/hologram-spools-details-dialog.component';
import { PaymentConfomationDialogComponent } from 'src/app/features/excise-duty/payment-confomation-dialog/payment-confomation-dialog.component';
import { InderDepotTransferDistilleryComponent } from 'src/app/features/inter-depot/inder-depot-transfer-distillery/inder-depot-transfer-distillery.component';
import { TpRequestDialogComponent } from 'src/app/features/inter-depot/inter-depot-transfer/tp-request-dialog/tp-request-dialog.component';
import { OfidtDetailsComponent } from 'src/app/features/inter-depot/inter-depot-transfer/ofidt-details/ofidt-details.component'
import { OpenActionUpdateShipmentsDialogComponent } from 'src/app/features/shipments/updated-indent/open-action-update-shipments-dialog/open-action-update-shipments-dialog.component';
import { OpenActionUpdateIndentDialogComponent } from 'src/app/features/shipments/updated-indent/open-update-indent-dialog/open-action-update-indent-dialog.component';
import { ExportChallanDialogComponent } from 'src/app/features/export/export-feature/export-challan-dialog/export-challan-dialog.component';
import { ExportChallanDetailsComponent } from 'src/app/features/export/export-feature/export-challan-details/export-challan-details.component';
import { ManageTpRequestsDialogComponent } from 'src/app/features/indent-management/licensee-indents/manage-tp-requests-dialog/manage-tp-requests-dialog.component';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { OpenActionUpdateBarIndentComponent } from 'src/app/features/indent-management/open-action-update-bar-indent/open-action-update-bar-indent.component';
import { OfidtDetailsDialogComponent } from 'src/app/features/inter-depot/inter-depot-transfer/ofidt-details-dialog/ofidt-details-dialog.component';
import { ManageRetailerRegistrationComponent } from './../../features/user-accounts/manage-retailer-registration/manage-retailer-registration.component';
import { ManageOutletReturnStockDialogComponent } from './../../features/stock/outlet-return-stock/manage-outlet-return-stock-dialog/manage-outlet-return-stock-dialog.component';
import { ManageChallanDialogComponent } from 'src/app/features/shipments/import/manage-challan-dialog/manage-challan-dialog.component';
import { ManageImportIndentComponent } from 'src/app/features/shipments/manage-import-indent/manage-import-indent.component';
import { ManageStockReturnDrainoutDialogComponent } from 'src/app/features/stock-return/stock-return-or-drainout/manage-stock-return-drainout-dialog/manage-stock-return-drainout-dialog.component';
import { StockReturnIcdcPackingListDialogComponent } from './../../features/stock-return/stock-return-or-drainout/stock-return-icdc-packing-list-dialog/stock-return-icdc-packing-list-dialog.component';
import { ManageOutletReturnStockComponent } from 'src/app/features/stock/manage-outlet-return-stock/manage-outlet-return-stock.component';
import { ReportIssuesDialogComponent } from 'src/app/features/report-issues/report-issues-dialog/report-issues-dialog.component';
import { ManageReleaseNotesDialogComponent } from 'src/app/features/release-notes/manage-release-notes-dialog/manage-release-notes-dialog.component';
import { ProductionProductsDialogComponent } from './../../features/production/products/production-products-dialog/production-products-dialog.component';
import { ReportIssuesViewComponent } from 'src/app/features/report-issues/report-issues-view/report-issues-view.component';
import { OfsPurchaseOrderDialogComponent } from 'src/app/features/shipments/ofs/ofs-purchase-order-dialog/ofs-purchase-order-dialog.component';
import { ManagePastorsLocationComponent } from 'src/app/features/import/pastors/manage-pastors-location/manage-pastors-location.component';
import { ManageChallanInformationComponent } from 'src/app/features/import/pastors/manage-challan-information/manage-challan-information.component';
import { ManageSacramentalWineDialogComponent } from 'src/app/features/import/pastors/manage-sacramental-wine-dialog/manage-sacramental-wine-dialog.component';
import { ManageRouteDpoDialogComponent } from './../../features/shipments/route/manage-route-dpo-dialog/manage-route-dpo-dialog.component';
import { ManageRecoveryDialogComponent } from 'src/app/features/recovery/manage-recovery-dialog/manage-recovery-dialog.component';
import { ManageFeedbackDialogComponent } from 'src/app/features/feedback/manage-feedback-dialog/manage-feedback-dialog.component';
import { ManageTtpDialogComponent } from 'src/app/features/through-transport-permit/manage-ttp-dialog/manage-ttp-dialog.component';
import { TtpDetailsDialogComponent } from 'src/app/features/through-transport-permit/manage-ttp-dialog/ttp-details-dialog/ttp-details-dialog.component';
import { ManageTtpRevalidationDialogComponent } from 'src/app/features/through-transport-permit/manage-ttp-revalidation-dialog/manage-ttp-revalidation-dialog.component';
import { ManageTtpRouteDialogComponent } from 'src/app/features/through-transport-permit/manage-ttp-route-dialog/manage-ttp-route-dialog.component';
import { ManageTtpUsersDialogComponent } from 'src/app/features/through-transport-permit/manage-ttp-users-dialog/manage-ttp-users-dialog.component';
import { ManageTtpLocationDialogComponent } from 'src/app/features/through-transport-permit/manage-ttp-location-dialog/manage-ttp-location-dialog.component';
import { ExciseLicenseeDialogComponent } from 'src/app/features/excise-licensee/excise-licensee-dialog/excise-licensee-dialog.component';
import { ManageManualIndentsComponent } from 'src/app/features/indent-management/vendor-indents/manage-manual-indents/manage-manual-indents.component';
import { ManageVirtualStockDialogComponent } from 'src/app/features/stock/manage-virtual-stock-dialog/manage-virtual-stock-dialog.component';
import { ManageCsdChallanComponent } from 'src/app/features/csd/manage-csd-challan/manage-csd-challan.component';
import { ManageFeaturePermissionDialogComponent } from 'src/app/features/feature-permissions/manage-feature-permission-dialog/manage-feature-permission-dialog.component';
import { GetChallanDetailsComponent } from '../components/get-challan-details/get-challan-details.component';
import { ViewPriceLabelRegistrationComponent } from 'src/app/features/label-registration/label-registration-feature/manage-label-registration-dialog/view-price-label-registration/view-price-label-registration.component';
import { RetailerChallanComponent } from 'src/app/features/indent-management/retailer-challan/retailer-challan.component';
import { RetailerChallanPayDialogComponent } from 'src/app/features/indent-management/retailer-challan/retailer-challan-pay-dialog/retailer-challan-pay-dialog.component';
import { ManageManualIdtComponent } from 'src/app/features/inter-depot/manage-manual-idt/manage-manual-idt.component';
import { InsuranceDetailsComponent } from 'src/app/features/distillery-info/insurance-details/insurance-details.component';
import { StockReturnToDistilleryComponent } from 'src/app/features/stock-return/stock-return-to-distillery/stock-return-to-distillery.component';
import { CreateTpForStockReturnComponent } from 'src/app/features/stock-return/create-tp-for-stock-return/create-tp-for-stock-return.component';
import { ManageLicencesDialogComponent } from 'src/app/features/distillery-info/licences/manage-licences-dialog/manage-licences-dialog.component';
import { BarsUpdateDialogComponent } from 'src/app/features/payment/bars-update-dialog/bars-update-dialog.component';


@Injectable({
  providedIn: 'root'
})
export class ComponentInstanceService {

  componentInstances: Map<string, any> = new Map();

  constructor() {
    this.setComponent('ManageInwardGateRegisterDialogComponent', ManageInwardGateRegisterDialogComponent);
    this.setComponent('ManageOutwardGateRegisterDialogComponent', ManageOutwardGateRegisterDialogComponent);
    this.setComponent('AddBrandBarcodeDialogComponent', AddBrandBarcodeDialogComponent);
    this.setComponent('ManageHoldOnStockDialogComponent', ManageHoldOnStockDialogComponent);
    this.setComponent('ManageStaffUsersDialogComponent', ManageStaffUsersDialogComponent);
    this.setComponent('ManageStaffUsersDialogComponent', ManageStaffUsersDialogComponent);
    this.setComponent('ManageBlendTankDialogComponent', ManageBlendTankDialogComponent);
    this.setComponent('ManageBottlingLineDialogComponent', ManageBottlingLineDialogComponent);
    this.setComponent('ManageGateRegisterDialogComponent', ManageGateRegisterDialogComponent);
    this.setComponent('OpenSupplierDialogComponent', OpenSupplierDialogComponent);
    this.setComponent('ManageLocationDialogComponent', ManageLocationDialogComponent);
    this.setComponent('AddRationingDialogComponent', AddRationingDialogComponent);
    this.setComponent('OpenIndentsDialogComponent', OpenIndentsDialogComponent);
    this.setComponent('ManageHealDailyIndentDialogComponent', ManageHealDailyIndentDialogComponent);
    this.setComponent('ManageHealPurchaseIndentDialogComponent', ManageHealPurchaseIndentDialogComponent);
    this.setComponent('ManageHealDamageDialogComponent', ManageHealDamageDialogComponent);
    this.setComponent('ManageBatchesDialogComponent', ManageBatchesDialogComponent);
    this.setComponent('ManageProductionLineDialogComponent', ManageProductionLineDialogComponent);
    this.setComponent('ManageRemitanceDialogComponent', ManageRemitanceDialogComponent);
    this.setComponent('ManageProcurementDialogComponent', ManageProcurementDialogComponent);
    this.setComponent('ManageDistilleryIndentDialogComponent', ManageDistilleryIndentDialogComponent);
    this.setComponent('ManageRsIndentDialogComponent', ManageRsIndentDialogComponent);
    this.setComponent('ManageEnaIndentDialogComponent', ManageEnaIndentDialogComponent);
    this.setComponent('ManageBlendIndentDialogComponent', ManageBlendIndentDialogComponent);
    this.setComponent('ManageOtherSpiritIndentDialogComponent', ManageOtherSpiritIndentDialogComponent);
    this.setComponent('ManageLicenseeIndentsDialogComponent', ManageLicenseeIndentsDialogComponent);
    this.setComponent('ManageVendorIndentsDialogComponent', ManageVendorIndentsDialogComponent);
    this.setComponent('ManageIssueLogDialogComponent', ManageIssueLogDialogComponent);
    this.setComponent('AddEventPermitDialogComponent', AddEventPermitDialogComponent);
    this.setComponent('ManageSamplesDialogComponent', ManageSamplesDialogComponent);
    this.setComponent('FailedSamplesDialogComponent', FailedSamplesDialogComponent);
    this.setComponent('ManageFinishedGoodsDialogComponent', ManageFinishedGoodsDialogComponent);
    this.setComponent('ManageRsStockDialogComponent', ManageRsStockDialogComponent);
    this.setComponent('ManageEnaStockDialogComponent', ManageEnaStockDialogComponent);
    this.setComponent('ManageBlendStockDialogComponent', ManageBlendStockDialogComponent);
    this.setComponent('SupplierAdvancedInboundReportsDialogComponent', SupplierAdvancedInboundReportsDialogComponent);
    this.setComponent('ManageIcdcDialogComponent', ManageIcdcDialogComponent);
    this.setComponent('ManageTpRequestDialogComponent', ManageTpRequestDialogComponent);
    this.setComponent('ManageIpRequestDialogComponent', ManageIpRequestDialogComponent);
    this.setComponent('ManageTpVehicleAccidentDialogComponent', ManageTpVehicleAccidentDialogComponent);
    this.setComponent('ManageRepackingDialogComponent', ManageRepackingDialogComponent);
    this.setComponent('ManageRouteDialogComponent', ManageRouteDialogComponent);
    this.setComponent('ManageInterDepotTransferLocalComponent', ManageInterDepotTransferLocalComponent);
    this.setComponent('InterDepotTransferLocalDialogDetailsComponent', InterDepotTransferLocalDialogDetailsComponent);
    this.setComponent('ManageBreakagesEntryDialogComponent', ManageBreakagesEntryDialogComponent);
    this.setComponent('ManageF4BreakagesEntryDialogComponent', ManageF4BreakagesEntryDialogComponent);
    this.setComponent('ManageCsdFeatureDialogComponent', ManageCsdFeatureDialogComponent);
    this.setComponent('ManageCsdDialogComponent', ManageCsdDialogComponent);
    this.setComponent('ManageCsdLocationsDialogComponent', ManageCsdLocationsDialogComponent);
    this.setComponent('ManageTransitDialogComponent', ManageTransitDialogComponent);
    this.setComponent('BrandBarcodeDetailsDialogComponent', BrandBarcodeDetailsDialogComponent);
    this.setComponent('ManageBrandRegistrationDialogComponent', ManageBrandRegistrationDialogComponent);
    this.setComponent('ManageLabelRegistrationDialogComponent', ManageLabelRegistrationDialogComponent);
    this.setComponent('ManageLabelDocumentsVerificationDialogComponent', ManageLabelDocumentsVerificationDialogComponent);
    this.setComponent('ManageDistilleryFeatureDialogComponent', ManageDistilleryFeatureDialogComponent);
    this.setComponent('ManageDepotFeatureDialogComponent', ManageDepotFeatureDialogComponent);
    this.setComponent('ManageRetailerFeatureDialogComponent', ManageRetailerFeatureDialogComponent);
    this.setComponent('ManageVendorsFeatureDialogComponent', ManageVendorsFeatureDialogComponent);
    this.setComponent('ManageAdminFeatureDialogComponent', ManageAdminFeatureDialogComponent);
    this.setComponent('ManageHoldOnStockDialogComponent', ManageHoldOnStockDialogComponent);
    this.setComponent('IssuedSpoolsDialogComponent', IssuedSpoolsDialogComponent);
    this.setComponent('HealPurchaseIndentStatusDetailsDialogComponent', HealPurchaseIndentStatusDetailsDialogComponent);
    this.setComponent('CartoonBarcodeDialogComponent', CartoonBarcodeDialogComponent);
    this.setComponent('SpoolDetailsDialogComponent', SpoolDetailsDialogComponent);
    this.setComponent('StatusDetailsDialogComponent', StatusDetailsDialogComponent);
    this.setComponent('OpenFinishedGoodsStatusDialogComponent', OpenFinishedGoodsStatusDialogComponent);
    this.setComponent('ProcurementStatusDialogComponent', ProcurementStatusDialogComponent);
    this.setComponent('SupplierOfsInfoDialogComponent', SupplierOfsInfoDialogComponent);
    this.setComponent('SupplierInvoiceInfoDialogComponent', SupplierInvoiceInfoDialogComponent);
    this.setComponent('SupplierShipmentShipmentInfoDialogComponent', SupplierShipmentShipmentInfoDialogComponent);
    this.setComponent('SupplierGatepassInfoDialogComponent', SupplierGatepassInfoDialogComponent);
    this.setComponent('SupplierChecklistInfoDialogComponent', SupplierChecklistInfoDialogComponent);
    this.setComponent('SupplierEvcInfoDialogComponent', SupplierEvcInfoDialogComponent);
    this.setComponent('ApnDetailsForSupplierDialogComponent', ApnDetailsForSupplierDialogComponent);
    this.setComponent('ManageIcdcDialogComponent', ManageIcdcDialogComponent);
    this.setComponent('IcdcPackingListDialogComponent', IcdcPackingListDialogComponent);
    this.setComponent('IcdcCaseBarcodeDialogComponent', IcdcCaseBarcodeDialogComponent);
    this.setComponent('ManageTpRequestDialogComponent', ManageTpRequestDialogComponent);
    this.setComponent('OpenActionUpdateShipmentsDialogComponent', OpenActionUpdateShipmentsDialogComponent);
    this.setComponent('OpenActionUpdateIndentDialogComponent', OpenActionUpdateIndentDialogComponent);
    this.setComponent('OpenIdtStatusDialogComponent', OpenIdtStatusDialogComponent);
    this.setComponent('OpenChecklistDetailsDialogComponent', OpenChecklistDetailsDialogComponent);
    this.setComponent('OpenOfidtDetailsDialogComponent', OpenOfidtDetailsDialogComponent);
    this.setComponent('OpenTpForIdrDialogComponent', OpenTpForIdrDialogComponent);
    this.setComponent('OpenLabDetailsDialogComponent', OpenLabDetailsDialogComponent);
    this.setComponent('OpenIcdcDetailsDialogComponent', OpenIcdcDetailsDialogComponent);
    this.setComponent('EvcDetailsDialogComponent', EvcDetailsDialogComponent);
    this.setComponent('OpenStockRecentDetailsDialogComponent', OpenStockRecentDetailsDialogComponent);
    this.setComponent('OpenGatepassDetailsDialogComponent', OpenGatepassDetailsDialogComponent);
    this.setComponent('GrnDetailsDialogComponent', GrnDetailsDialogComponent);
    this.setComponent('OpenDepotReceiptDetailsDialogComponent', OpenDepotReceiptDetailsDialogComponent);
    this.setComponent('OfidtStatusDialogComponent', OfidtStatusDialogComponent);
    this.setComponent('IcdcStatusDialogComponent', IcdcStatusDialogComponent);
    this.setComponent('ProductStatusDialogComponent', ProductStatusDialogComponent);
    this.setComponent('TpDetailsDialogComponent', TpDetailsDialogComponent);
    this.setComponent('OpenDescriptionDialogComponent', OpenDescriptionDialogComponent);
    this.setComponent('OpenOfsDialogComponent', OpenOfsDialogComponent);
    this.setComponent('LicenseeAddressDetailsDialogComponent', LicenseeAddressDetailsDialogComponent);
    this.setComponent('LicenseeBankDetailsDialogComponent', LicenseeBankDetailsDialogComponent);
    this.setComponent('EditRegistrationFormComponent', EditRegistrationFormComponent);
    this.setComponent('LicenseeAddressDetailsForBarsComponent', LicenseeAddressDetailsForBarsComponent);
    this.setComponent('LicenseeBankDetailsForBarsDialogComponent', LicenseeBankDetailsForBarsDialogComponent);
    this.setComponent('EditRegistrationForBarsComponent', EditRegistrationForBarsComponent);
    this.setComponent('ShowAddressDetailsDialogComponent', ShowAddressDetailsDialogComponent);
    this.setComponent('ShowBankDetailsDialogComponent', ShowBankDetailsDialogComponent);
    this.setComponent('EditRegistrationFormDialogComponent', EditRegistrationFormDialogComponent);
    this.setComponent('LicenseeDetailsForTwofDialogComponent', LicenseeDetailsForTwofDialogComponent);
    this.setComponent('BankDetailsForTwofDialogComponent', BankDetailsForTwofDialogComponent);
    this.setComponent('EditRegistrationForTwofDialogComponent', EditRegistrationForTwofDialogComponent);
    this.setComponent('ProductCodeDialogComponent', ProductCodeDialogComponent);
    this.setComponent('ClosingStockDetailsDialogComponent', ClosingStockDetailsDialogComponent);
    this.setComponent('AddBrandBarcodeDialogComponent', AddBrandBarcodeDialogComponent);
    this.setComponent('OutletReturnStockDialogComponent', OutletReturnStockDialogComponent);
    this.setComponent('ApnDetailsDialogComponent', ApnDetailsDialogComponent);
    this.setComponent('OpenTpDetailsDialogComponent', OpenTpDetailsDialogComponent);
    this.setComponent('OpenInvoiceDetailsDialogComponent', OpenInvoiceDetailsDialogComponent);
    this.setComponent('OpenOfsDetailsDialogComponent', OpenOfsDetailsDialogComponent);
    this.setComponent('ManageLocationDialogComponent', ManageLocationDialogComponent);
    this.setComponent('BeerProductCodeDialogComponent', BeerProductCodeDialogComponent);
    this.setComponent('OpenTargetDialogComponent', OpenTargetDialogComponent);
    this.setComponent('OpenCustomerDetailsDialogComponent', OpenCustomerDetailsDialogComponent);
    this.setComponent('OpenBankDetailsDialogComponent', OpenBankDetailsDialogComponent);
    this.setComponent('OpenBarsCustomerDetailsDialogComponent', OpenBarsCustomerDetailsDialogComponent);
    this.setComponent('OpenBarsBankDetailsDialogComponent', OpenBarsBankDetailsDialogComponent);
    this.setComponent('OpenTwotCustomerDetailsDialogComponent', OpenTwotCustomerDetailsDialogComponent);
    this.setComponent('OpenTwotBankDetailsDialogComponent', OpenTwotBankDetailsDialogComponent);
    this.setComponent('OpenClubsCustomerDetailsDialogComponent', OpenClubsCustomerDetailsDialogComponent);
    this.setComponent('OpenClubsBankDetailsDialogComponent', OpenClubsBankDetailsDialogComponent);
    this.setComponent('OpenTd1CustomerDetailsDialogComponent', OpenTd1CustomerDetailsDialogComponent);
    this.setComponent('OpenTd1BankDetailsDialogComponent', OpenTd1BankDetailsDialogComponent);
    this.setComponent('AddRationingDialogComponent', AddRationingDialogComponent);
    this.setComponent('OpenRetailerNamesDialogComponent', OpenRetailerNamesDialogComponent);
    this.setComponent('DistilleryHologramStatusDetailsDialogComponent', DistilleryHologramStatusDetailsDialogComponent);
    this.setComponent('OpenDepotStatusDialogComponent', OpenDepotStatusDialogComponent);
    this.setComponent('ScanSpoolsDialogComponent', ScanSpoolsDialogComponent);
    this.setComponent('ProductionLineDetailsDialogComponent', ProductionLineDetailsDialogComponent);
    this.setComponent('ManageProductionLineDialogComponent', ManageProductionLineDialogComponent);
    this.setComponent('TargetProductionDetailsDialogComponent', TargetProductionDetailsDialogComponent);
    this.setComponent('BatchProductionInfoComponent', BatchProductionInfoComponent);
    this.setComponent('ManageBatchProductionDialogComponent', ManageBatchProductionDialogComponent);
    this.setComponent('OpenDistilleryIndentStatusDialogComponent', OpenDistilleryIndentStatusDialogComponent);
    this.setComponent('IcdcScanStatusDialogComponent', IcdcScanStatusDialogComponent);
    this.setComponent('OpenDistilleryIndentStatusDialogComponent', OpenDistilleryIndentStatusDialogComponent);
    this.setComponent('LicenseeStatusDialogComponent', LicenseeStatusDialogComponent);
    this.setComponent('OpenSamplesStatusDialogComponent', OpenSamplesStatusDialogComponent);
    this.setComponent('FailedSamplesDialogComponent', FailedSamplesDialogComponent);
    this.setComponent('ManageTpDetailsComponent', ManageTpDetailsComponent);
    this.setComponent('ShowIcdcDetailsDialogComponent', ShowIcdcDetailsDialogComponent);
    this.setComponent('ShowTpDetailsDialogComponent', ShowTpDetailsDialogComponent);
    this.setComponent('AsnDetailsForSupplierShipmentDialogComponent', AsnDetailsForSupplierShipmentDialogComponent);
    this.setComponent('IcdcInfoDialogComponent', IcdcInfoDialogComponent);
    this.setComponent('IcdcDetailDialogComponent', IcdcDetailDialogComponent);
    this.setComponent('CaseBarcodeDialogComponent', CaseBarcodeDialogComponent);
    this.setComponent('OfsDetailsDialogComponent', OfsDetailsDialogComponent);
    this.setComponent('CaseBarcodeDialogComponent', CaseBarcodeDialogComponent);
    this.setComponent('IpStatusDialogComponent', IpStatusDialogComponent);
    this.setComponent('IpStatusDialogComponent', IpStatusDialogComponent);
    this.setComponent('InterDepotTrnasferSuplierDialogDetailsComponent', InterDepotTrnasferSuplierDialogDetailsComponent);
    this.setComponent('ManageInterDepotSupplierDialogComponent', ManageInterDepotSupplierDialogComponent);
    this.setComponent('InterDepotTransferLocalDialogDetailsComponent', InterDepotTransferLocalDialogDetailsComponent);
    this.setComponent('OrdersDetailsDialogComponent', OrdersDetailsDialogComponent);
    this.setComponent('AsnDetailsForDepotReceiptDialogComponent', AsnDetailsForDepotReceiptDialogComponent);
    this.setComponent('OfidtReasonDialogComponent', OfidtReasonDialogComponent);
    this.setComponent('IcdcReasonDialogComponent', IcdcReasonDialogComponent);
    this.setComponent('ProductDetailsDialogComponent', ProductDetailsDialogComponent);
    this.setComponent('Form3BreakagesEntryComponent', Form3BreakagesEntryComponent);
    this.setComponent('ShipmentDetailsDialogComponent', ShipmentDetailsDialogComponent);
    this.setComponent('ManageShipmentDialogComponent', ManageShipmentDialogComponent);
    this.setComponent('CancelImportPermitDetailsDialogComponent', CancelImportPermitDetailsDialogComponent);
    this.setComponent('ImportIndentsDetailsDialogComponent', ImportIndentsDetailsDialogComponent);
    this.setComponent('ImportPackingActionDialogComponent', ImportPackingActionDialogComponent);
    this.setComponent('ExportFeatureDetailsDialogComponent', ExportFeatureDetailsDialogComponent);
    this.setComponent('StatusCsdDialogComponent', StatusCsdDialogComponent);
    this.setComponent('HealCodesDialogComponent', HealCodesDialogComponent);
    this.setComponent('AddBrandBarcodeComponent', AddBrandBarcodeComponent);
    this.setComponent('ManageVendorsDialogComponent', ManageVendorsDialogComponent);
    this.setComponent('ManageLocationCsdDialogComponent', ManageLocationCsdDialogComponent);
    this.setComponent('ManageDepotsDialogComponent', ManageDepotsDialogComponent);
    this.setComponent('ManageLabsDialogComponent', ManageLabsDialogComponent);
    this.setComponent('OpenApbclDialogComponent', OpenApbclDialogComponent);
    this.setComponent('OpenDepotDialogComponent', OpenDepotDialogComponent);
    this.setComponent('OpenVendorDialogComponent', OpenVendorDialogComponent);
    this.setComponent('ManageInterRetailerTransferSupplierComponent', ManageInterRetailerTransferSupplierComponent);
    this.setComponent('OpenBasicPricesDialogComponent', OpenBasicPricesDialogComponent);
    this.setComponent('OtpVerificationDialogComponent',OtpVerificationDialogComponent)
    this.setComponent('ManageSegmentRegistrationDialogComponent', ManageSegmentRegistrationDialogComponent);
    this.setComponent('HologramSpoolsDetailsDialogComponent', HologramSpoolsDetailsDialogComponent);
    this.setComponent('PaymentConfomationDialogComponent',PaymentConfomationDialogComponent);
    this.setComponent('InderDepotTransferDistilleryComponent',InderDepotTransferDistilleryComponent);
    this.setComponent('TpRequestDialogComponent',TpRequestDialogComponent);
    this.setComponent('OfidtDetailsComponent',OfidtDetailsComponent);
    this.setComponent('ManageRetailersUserDialogComponent', ManageRetailersUserDialogComponent);
    this.setComponent('IDTReceiptOfidtInfoDialogComponent', IDTReceiptOfidtInfoDialogComponent);
    this.setComponent('IDTReceiptInvoiceInfoDialogComponent', IDTReceiptInvoiceInfoDialogComponent);
    this.setComponent('IDTReceiptShipmentShipmentInfoDialogComponent', IDTReceiptShipmentShipmentInfoDialogComponent);
    this.setComponent('IDTReceiptGatepassInfoDialogComponent', IDTReceiptGatepassInfoDialogComponent);
    this.setComponent('IDTReceiptChecklistInfoDialogComponent', IDTReceiptChecklistInfoDialogComponent);
    this.setComponent('IDTReceiptEvcInfoDialogComponent', IDTReceiptEvcInfoDialogComponent);
    this.setComponent('IDTReceiptApnDetailsDialogComponent', IDTReceiptApnDetailsDialogComponent);
    this.setComponent('CfmsPaymentVerifyDialogComponent', CfmsPaymentVerifyDialogComponent);
    this.setComponent('VendorIndentStatusDialogComponent', VendorIndentStatusDialogComponent);
    this.setComponent('ExportChallanDialogComponent', ExportChallanDialogComponent);
    this.setComponent('ExportChallanDetailsComponent', ExportChallanDetailsComponent);
    this.setComponent('ManageTpRequestsDialogComponent', ManageTpRequestsDialogComponent);
    this.setComponent('ConfirmationDialogComponent', ConfirmationDialogComponent);
    this.setComponent('OpenActionUpdateBarIndentComponent', OpenActionUpdateBarIndentComponent);
    this.setComponent('OfidtDetailsDialogComponent', OfidtDetailsDialogComponent);
    this.setComponent('ManageRetailerRegistrationComponent', ManageRetailerRegistrationComponent);
    this.setComponent('ManageOutletReturnStockDialogComponent', ManageOutletReturnStockDialogComponent);
    this.setComponent('ManageChallanDialogComponent', ManageChallanDialogComponent);
    this.setComponent('ManageImportIndentComponent', ManageImportIndentComponent);
    this.setComponent('ManageStockReturnDrainoutDialogComponent', ManageStockReturnDrainoutDialogComponent);
    this.setComponent('StockReturnIcdcPackingListDialogComponent', StockReturnIcdcPackingListDialogComponent);
    this.setComponent('ManageOutletReturnStockComponent', ManageOutletReturnStockComponent);
    this.setComponent('ReportIssuesDialogComponent', ReportIssuesDialogComponent);
    this.setComponent('ProductionProductsDialogComponent', ProductionProductsDialogComponent);
    this.setComponent('ManageReleaseNotesDialogComponent', ManageReleaseNotesDialogComponent);
    this.setComponent('ReportIssuesViewComponent', ReportIssuesViewComponent);
    this.setComponent('OfsPurchaseOrderDialogComponent', OfsPurchaseOrderDialogComponent);
    this.setComponent('ManagePastorsLocationComponent', ManagePastorsLocationComponent);
    this.setComponent('ManageChallanInformationComponent',ManageChallanInformationComponent);
    this.setComponent('ManageSacramentalWineDialogComponent',ManageSacramentalWineDialogComponent);
    this.setComponent('ManageRouteDpoDialogComponent', ManageRouteDpoDialogComponent);
    this.setComponent('ManageRecoveryDialogComponent', ManageRecoveryDialogComponent);
    this.setComponent('ManageFeedbackDialogComponent',ManageFeedbackDialogComponent);
    this.setComponent('ManageTtpDialogComponent', ManageTtpDialogComponent);
    this.setComponent('TtpDetailsDialogComponent', TtpDetailsDialogComponent);
    this.setComponent('ManageTtpRevalidationDialogComponent', ManageTtpRevalidationDialogComponent);
    this.setComponent('ManageTtpRouteDialogComponent', ManageTtpRouteDialogComponent);
    this.setComponent('ManageTtpUsersDialogComponent', ManageTtpUsersDialogComponent);
    this.setComponent('ManageTtpLocationDialogComponent', ManageTtpLocationDialogComponent);
    this.setComponent('ExciseLicenseeDialogComponent', ExciseLicenseeDialogComponent);
    this.setComponent('ManageVirtualStockDialogComponent', ManageVirtualStockDialogComponent);
    this.setComponent('ManageManualIndentsComponent', ManageManualIndentsComponent);    
    this.setComponent('ManageCsdChallanComponent',ManageCsdChallanComponent )
    this.setComponent('ManageFeaturePermissionDialogComponent', ManageFeaturePermissionDialogComponent);
    this.setComponent('GetChallanDetailsComponent', GetChallanDetailsComponent);
    this.setComponent('ViewPriceLabelRegistrationComponent' ,ViewPriceLabelRegistrationComponent)
    this.setComponent('RetailerChallanComponent', RetailerChallanComponent);
    this.setComponent('RetailerChallanPayDialogComponent', RetailerChallanPayDialogComponent);
    this.setComponent('ManageManualIdtComponent', ManageManualIdtComponent)
    this.setComponent('InsuranceDetailsComponent', InsuranceDetailsComponent);
    this.setComponent('StockReturnToDistilleryComponent', StockReturnToDistilleryComponent);
    this.setComponent('CreateTpForStockReturnComponent', CreateTpForStockReturnComponent);
    this.setComponent('ManageLicencesDialogComponent', ManageLicencesDialogComponent);
    this.setComponent('BarsUpdateDialogComponent',BarsUpdateDialogComponent)
  }
  setComponent(name: string, component: any): void {
    this.componentInstances.set(name, component);
  }

  getComponent(name: string): any {
    return this.componentInstances.get(name);
  }
}
