import { CommonsService } from './../../../core/services/commons.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormArray, FormGroup } from '@angular/forms';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { take, takeUntil } from 'rxjs/operators';
import { InterDepotService } from 'src/app/core/services/inter-depot.service';
import { MessageService } from 'primeng/api';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicConfirmationDialogComponent } from 'src/app/core/components/dynamic-confirmation-dialog/dynamic-confirmation-dialog.component';
import { StockReturnService } from 'src/app/core/services/stock-return.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-stock-return-to-distillery',
  templateUrl: './stock-return-to-distillery.component.html',
  styleUrls: ['./stock-return-to-distillery.component.scss']
})
export class StockReturnToDistilleryComponent implements OnInit {
  public _onDestroy = new Subject<void>();
  interDepotTransferDistilleryForm:FormGroup;
  productDetails:FormArray;
  consignmentTypes=['STOCKRETURN'];
  productData:any = [];
  depots = [];
  filteredData: any[];
  filteredDepots: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  selectedDepotName:any;
  selectedSDestinationDepotName:any;
  public productFitlerCtrl: FormControl = new FormControl();
  @Output() refreshPage = new EventEmitter<boolean>();
  loader: any = false;
  totalDatas: any = {
    cases: 0,
    bottles: 0
  }
  roleName: any = '';
  displayedColumns: any = [
    'productCode',
    'brandCode',
    'size',
    'requestedCases',
    'requestedBottles',
    'approvedCases',
    'approvedBottles'
  ];

  constructor(
    private fb: FormBuilder,
    private commonsService: CommonsService,
    private stockReturnService: StockReturnService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<StockReturnToDistilleryComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialog,
    public utilService: UtilService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    this.interDepotTransferDistilleryForm = this.fb.group({
      consignmentType: ['STOCKRETURN', Validators.required],
      depotCode:['', Validators.required],
      stockType: 'STOCKRETURN',
      productDetails: this.fb.array([this.updateProductsList()]),
    });
    this.productDetails = this.interDepotTransferDistilleryForm.get('productDetails') as FormArray;
    this.getDepotsData();
    this.productFitlerCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterProducts();
    });
    if (this.data) {
      this.data.productDetails.forEach(el => {
        el.approvedCases = el.requestedCases;
        el.approvedBottles = el.requestedBottles;
        this.totalDatas.cases += el.approvedCases;
        this.totalDatas.bottles += el.approvedBottles;
      });
      this.displayedColumns = this.displayedColumns.filter(el => el !== 'requestedCases' && el !== 'requestedBottles');
      // this.totalDatas.cases = this.data.productDetails.reduce((n, {approvedCases}) => n + approvedCases, 0);
      // this.totalDatas.bottles = this.data.productDetails.reduce((n, {approvedBottles}) => n + approvedBottles, 0);
    }
  }
  addProduct(){
    const control = <FormArray>this.interDepotTransferDistilleryForm.controls["productDetails"];
    control.push(this.updateProductsList());
  }
  updateProductsList() {
    return this.fb.group({
      productCode: [''],
      brandName: [''],
      size: [''],
      availableCases:[''],
      availableBottles:[''],
      requestedCases:[0],
      requestedBottles:[0]
    });
  }
  removeProduct(i:number){
    const control = <FormArray>this.interDepotTransferDistilleryForm.controls["productDetails"];
    control.removeAt(i);
  }
  getDepotsData(){
    let paramObj = {
      action: 'common_apis',
      q: '{"data": ["get_depots"]}'
    }
    this.commonsService.getCommonEntites(paramObj).subscribe((res) => {
      this.depots = res['get_depots']
      this.filteredDepots.next(this.depots);
    });
  }
  filterProducts() {
    if (!this.depots || (this.depots && this.depots.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.productFitlerCtrl.value;
    if (!search) {
      this.filteredDepots.next(this.depots);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredDepots.next(
      this.depots.filter(product => JSON.stringify(product).toLowerCase().indexOf(search) > -1)
    );
  }
  selectedDepot(event:any){
    this.selectedDepotName = event.value;
    let qData = {
      "data": { "value": event.value, "action": "get_depot_stock_code" }
    };
    const req_Data = {
      "action": "common_apis",
      "q": JSON.stringify(qData)
    };
    
    this.commonsService.getCommonEntites(req_Data).subscribe((res) => {
      this.productData = res['get_depot_stock_code'];
    });

  }

  getSelectedDestinationDepot(event:any){
    this.selectedSDestinationDepotName = event.value;
  }

  brandCodeChange(event:any){

  }
  productCodeChange(event,i){    
    const productDetails = (<FormArray>this.interDepotTransferDistilleryForm.get("productDetails")).at(i);
    let [bindData] = this.productData.filter(data => {
      return data.productCode == event.value;
    })

    productDetails.patchValue({
      size: bindData.size,
      brandName:bindData.brandName,
      availableCases:bindData.onlineClosingBalCases,
      availableBottles:bindData.onlineClosingBalBottles
    });
    
  }
  save(){
    this.loader = true;
    const reqData = {
      action:"add_stock_return",
      data: this.interDepotTransferDistilleryForm.value
    }
    reqData.data.productDetails.forEach(item => {
      delete item.availableCases;
      delete item.availableBottles;
    });
    this.stockReturnService.create(reqData).subscribe((res:any)=>{
      if(res.status == 'SUCCESS'){
        this.dialogRef.close('Created successfully');
      }
      this.loader = false;
    }, (err) => {
      this.loader = false;
    });
    

  }
  qtyCheck(){
    const control = <FormArray>this.interDepotTransferDistilleryForm.controls["productDetails"];
    return control.value.some(el=>el.requestedCases > el.availableCases || el.requestedBottles > el.availableBottles);
  }
  checkDuplicate(productCode){
    const control = <FormArray>this.interDepotTransferDistilleryForm.controls["productDetails"];
      return control.value.some(el=>el.productCode === productCode);
  }
  dialogClose(): void{
    this.dialogRef.close();
  }
  dialogConfirm(status, label): any {
    const data: any = {};
    data.content = `${label} ..?`;
    const dialogRef = this.dialog.open(DynamicConfirmationDialogComponent, {
      width: '25%',
      data:data,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result.message}`);
      if (result && result.message && result.message === 'Success') {
        this.updateStatus(status, label);
      }
    });
  }
  updateStatus(status, label): any {
    this.spinner.show();
    this.loader = true;
    const reqData = {
      action:"update_stock_return_distillery",
      data: this.data
    };
    reqData.data.status = status;
    delete reqData.data.colName;
    this.stockReturnService.update(reqData).subscribe((res:any)=>{
      if(res.status == 'SUCCESS'){
        this.dialogRef.close(`${label} successfully`);
      } else {
        this.messageService.add({severity: 'error', summary: '', detail: `${label} Failed`});
        this.data.status = 'Created';
      }
      this.spinner.hide()
      this.loader = false;
    }, (err) => {
      this.spinner.hide();
      this.loader = false;
      this.messageService.add({severity: 'error', summary: '', detail: `${label} Failed`});
      this.data.status = 'Created';
    });
    

  }
  isDisable() {
    return this.data.productDetails.some(el => el.approvedCases === null  || el.approvedBottles === null  || el.approvedCases === '' || el.approvedBottles === '' || el.approvedCases < 0 || el.approvedBottles < 0 || (el.approvedCases > el.requestedCases) || (el.approvedBottles > el.requestedBottles) || (el.approvedCases === 0 && el.approvedBottles === 0) || (el.requestedCases > 0 && el.approvedCases === 0) || (el.requestedBottles > 0 && el.approvedBottles === 0));
  }
  isDisableCreated() {
    return this.interDepotTransferDistilleryForm.value.productDetails.some(el => el.requestedCases === null  || el.requestedBottles === null  || el.requestedCases === '' || el.requestedBottles === '' || el.requestedCases < 0 || el.requestedBottles < 0 || (el.requestedCases > el.availableCases) || (el.requestedBottles > el.availableBottles) || (el.requestedCases === 0 && el.requestedBottles === 0) || (el.availableCases === 0 && el.requestedCases > 0) || (el.availableBottles === 0 && el.requestedBottles > 0));
  }
  print(id){
    setTimeout(() => {
      this.getPrint(id);
    }, 100);
  }
  getPrint(id){
    let printContents, popupWin;
    printContents = document.getElementById(id).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.write(`${printContents}`);
    popupWin.document.close();
  }
}
