import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-userinfo-retailers-dialog',
  templateUrl: './userinfo-retailers-dialog.component.html',
  styleUrls: ['./userinfo-retailers-dialog.component.scss']
})
export class UserinfoRetailersDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<UserinfoRetailersDialogComponent>,
  ) { }

  ngOnInit(): void {
    console.log(this.data)
  }
  confirm(){
    this.dialogRef.close({message: 'SUCCESS'});
  }

}
