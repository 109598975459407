import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { IndentManagementService } from 'src/app/core/services/indent-management.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

const baseUrl = environment.baseUrl;
@Component({
  selector: 'app-retailer-challan-pay-dialog',
  templateUrl: './retailer-challan-pay-dialog.component.html',
  styleUrls: ['./retailer-challan-pay-dialog.component.scss']
})
export class RetailerChallanPayDialogComponent implements OnInit {
  url = "https://apexcise.nic.in/ApsbclChallanV2.jsp";
  paymentFormFields: any;
  expressServerUrl: any = '';
  userInfo: any;
  vendorDetails: any;
  totalAmountPayable: number = 0;
  taxData:any={};
  isDisabled:any=true;
  

  constructor(
    @Inject(MAT_DIALOG_DATA)public data,
    public dialogRef: MatDialogRef<RetailerChallanPayDialogComponent>,
    private util:UtilService,
    private indentManagementService: IndentManagementService,
    private messageService:MessageService,
    private spinner: NgxSpinnerService
  ) { 
    this.userInfo = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
  }

  ngOnInit(): void {
    this.getPaymentFormData();
    // this.setForm();
    this.expressServerUrl = this.util.formatStringByParamAndIndex(baseUrl, ':', 0)
    + ':' + this.util.formatStringByParamAndIndex(baseUrl, ':', 1);
  }

  setForm(): any {
    this.paymentFormFields = [{
      label:"aed_amount",
      value: 0
    },
    {
      label:"ed_amount",
      value: 0
    },
    {
      label:"afee_amount",
      value: 0
    },
    {
      label:"dept_trnsid",
      value: this.data.departmentId
    },
    {
      label:"vat_amount",
      value: 0
    },
    {
      label:"rpm",
      value: 0
    },
    {
      label:"aret_amount",
      value: 0
    },{
      label:"pd_amount",
      value: this.data.amount
    },
    {
      label:"amnt_pay",
      value: this.data.amount
    },{
      label:"depot_code",
      value: this.data?.depotCode.split('-')[0]
    },{
      label:"retailer_code",
      value: this.data?.entityId.split('-')[0]
    },{
      label:"remitter_id",
      value: this.data?.entityId.split('-')[0]
    },{
      label:"mobile",
      value: "0"
    },{
      label:"email",
      value: "sample99999111@gmail.com"
    },{
      label:"remitter_name",
      value: this.userInfo.entity_id
    },{
      label:"return_url",
      value: `${this.expressServerUrl}:4214/challan-status`
    }];
  }

  getPaymentFormData(): any {
    const req_Data = {
      action: 'generate_new_dept_id',
      q: JSON.stringify({indentId: this.data.indentId})
    };
    this.spinner.show();
    this.indentManagementService.getIndentManagementFeaturesByParams(req_Data).subscribe((res) => {
      if (res && res.data && res.data.departmentId) {
        this.data.departmentId = res.data.departmentId;
        this.setForm();
      } else {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Error'});
      }
      this.spinner.hide();
    }, err => {
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'Server Error'});
      this.spinner.hide();
    });
  }
  dialogClose(): void{
    this.dialogRef.close();
  }

}
