import { ElementRef, ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonsService } from 'src/app/core/services/commons.service';
import { MessageService } from 'primeng/api';
import { ThroughTransportPermitService } from './../../../core/services/through-transport-permit.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { DynamicConfirmationDialogComponent } from 'src/app/core/components/dynamic-confirmation-dialog/dynamic-confirmation-dialog.component';

@Component({
  selector: 'app-manage-ttp-dialog',
  templateUrl: './manage-ttp-dialog.component.html',
  styleUrls: ['./manage-ttp-dialog.component.scss']
})
export class ManageTtpDialogComponent implements OnInit {
  
  @ViewChild('ttpHtmlForm') ttpHtmlForm: any;
  @ViewChild('fileInput') fileInput: ElementRef; 
  ttpForm: FormGroup;
  userInfo: any;
  loader: any = false;
  brandDetails: FormArray;
  intoxicantTypes: any = ['IML', 'Beer', 'Other'];
  selectedFile: any = '';
  roleName: any = '';
  comments: any = '';
  commentsDialogRef: any;
  s3Url: any = '';

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ManageTtpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private ttpService: ThroughTransportPermitService,
    private messageService:MessageService,
    private commonService: CommonsService,
    private spinner: NgxSpinnerService,
    private util: UtilService,
    private dialog: MatDialog
  ) {
    this.userInfo = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
    this.s3Url = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).s3_url;
  }

  ngOnInit(): void {
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    this.ttpForm = this.fb.group({
      transporterName: ['', Validators.required],
      transporterAddress: ['', Validators.required],
      importPermitNo: ['', Validators.required],
      exportPermitNo: ['', Validators.required],
      importPermitDate: ['', Validators.required],
      exportPermitDate: ['', Validators.required],
      importPermitValidFrom: ['', Validators.required],
      exportPermitValidFrom: ['', Validators.required],
      importPermitValidTo: ['', Validators.required],
      exportPermitValidTo: ['', Validators.required],
      importerName: ['', Validators.required],
      exporterName: ['', Validators.required],
      importPermitIssuedBy: ['', Validators.required],
      exportPermitIssuedBy: ['', Validators.required],
      consignmentOrigin: ['', Validators.required],
      consignmentDestination: ['', Validators.required],
      route: ['', Validators.required],
      conveyanceMode: ['', Validators.required],
      startingCheckpoint: ['', Validators.required],
      endCheckpoint: ['', Validators.required],
      vehicleNumber: ['', Validators.required],
      file: ['', Validators.required],
      brandDetails: this.fb.array([this.updateBrandDetails()]),
      // comments: ['', this.data ? Validators.required : ''],
      validFrom: ['', this.data ? Validators.required : ''],
      validTo: ['', this.data ? Validators.required : ''],
    });
    if (this.data) {
      console.log('this.data', this.data);      
      this.ttpForm.patchValue({
        transporterName: this.data.transporterName,
        transporterAddress: this.data.transporterAddress,
        importPermitNo: this.data.importPermitNo,
        exportPermitNo: this.data.exportPermitNo,
        importPermitDate: this.data.importPermitDate,
        exportPermitDate: this.data.exportPermitDate,
        importPermitValidFrom: this.data.importPermitValidFrom,
        exportPermitValidFrom: this.data.exportPermitValidFrom,
        importPermitValidTo: this.data.importPermitValidTo,
        exportPermitValidTo: this.data.exportPermitValidTo,
        importerName: this.data.importerName,
        exporterName: this.data.exporterName,
        importPermitIssuedBy: this.data.importPermitIssuedBy,
        exportPermitIssuedBy: this.data.exportPermitIssuedBy,
        consignmentOrigin: this.data.consignmentOrigin,
        consignmentDestination: this.data.consignmentDestination,
        route: this.data.route,
        conveyanceMode: this.data.conveyanceMode,
        startingCheckpoint: this.data.startingCheckpoint,
        endCheckpoint: this.data.endCheckpoint,
        vehicleNumber: this.data.vehicleNumber,
        file: this.data.file,
      });
      this.data.brandDetails.forEach((b: any, i) => {
        if (i > 0) {
          this.add();
        }
        Object.keys(b).forEach(k => {
          this.ttpForm.controls.brandDetails['controls'][i].controls[k].setValue(b[k]);
        });
      });
    }
    this.brandDetails = this.ttpForm.get('brandDetails') as FormArray;
  }

  updateBrandDetails(){
    return this.fb.group({
      intoxicantType: ['',Validators.required],
      brandName: ['', Validators.required],
      unitsPerCase: ['', Validators.required],
      size: ['', Validators.required],
      strength: ['', Validators.required],
      cases: ['', Validators.required],
      bulkLitres: ['', Validators.required],
      proofLitres: [0, Validators.required]
    });
  }

  get f() {
    return this.ttpForm.controls;
  }

  add() {
    const control = <FormArray>this.ttpForm.controls['brandDetails'];
    control.push(this.updateBrandDetails());
  }

  remove(i: number) {
    const control = <FormArray>this.ttpForm.controls['brandDetails'];
    control.removeAt(i);
  }

  updateBrandProducts(ctrl, val): any {
    const brandDet = this.ttpForm.get('brandDetails') as FormArray;
    const isExist = brandDet.value.some(el => el.brandCode === val);
    if (isExist) {
      ctrl.brandCode.setValue('');
      ctrl.brandName.setValue('');
      ctrl.unitsPerCase.setValue('');
      ctrl.size.setValue('');
      ctrl.strength.setValue('');
      return;
    }
    const brandData: any = this.intoxicantTypes.find(el => el.brandCode === val);
    ctrl.brandName.setValue(brandData.brandName);
    ctrl.unitsPerCase.setValue(brandData.unitsPerCase);
    ctrl.size.setValue(brandData.size);
    ctrl.strength.setValue(brandData.strength ? brandData.strength : 0);
  }

  updateBulkLitres(ctrl): any {
    ctrl.bulkLitres.setValue(this.getBulkLitres(ctrl.size.value, ctrl.cases.value));
    // ctrl.proofLitres.setValue(this.getBulkLitres(ctrl.size.value, ctrl.cases.value));
  }

  getBulkLitres(size, qty): any {
    let bulkLiters: any = qty * 9;
    if (size === 90 || size === 180) {
      bulkLiters = qty * 8.64;
    } else {
      bulkLiters = qty * 9;
    }
    return bulkLiters;
  }
  changeBrand(i) {
    const brandDet = this.ttpForm.get('brandDetails') as FormArray;
    const brandControls = brandDet.controls[i]['controls'];
    if (brandControls.intoxicantType.value === 'Other') {
      Object.keys(brandControls).forEach(el => {
        if (el !== 'intoxicantType' && el !== 'brandName') {
          brandControls[el].setValidators(null);
          brandControls[el].updateValueAndValidity();
        }
      });
    } else {
      Object.keys(brandControls).forEach(el => {
        if (el !== 'intoxicantType' && el !== 'brandName') {
          brandControls[el].setValidators([Validators.required]);
          brandControls[el].updateValueAndValidity();
        }
      });
    }
  }

  // getBrands() {
  //   const req_Data = {
  //     "action": "common_apis",
  //     "q": '{"data": ["get_prod_info"]}'
  //   }
  //   this.commonService.getCommonEntites(req_Data).subscribe(res => {
  //     this.intoxicantTypes = res["get_prod_info"];
  //   });
  // }

  createTTP() {
    console.log('createTTP', this.ttpForm);
    if (this.ttpForm.status === 'VALID') {
      const req_Data: any = {
        action: 'create_ttp',
        data: this.ttpForm.value
      }
      delete req_Data.data.validFrom;
      delete req_Data.data.validTo;
      console.log(req_Data)
      this.loader = true;
      this.spinner.show();
      this.ttpService.postMethod(req_Data).subscribe((res: any) => {
        if (res && res.status === 'SUCCESS') {
          this.dialogRef.close('TTP Created Successfully');
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'TTP Creation Failed' });
        }
        this.loader = false;
        this.spinner.hide();
      }, (err) => {
        this.loader = false;
        this.spinner.hide();
      });
    }
  }
  addComments(): any {
    console.log('addComments', this.comments);
    if (this.comments) {
      const req_Data = {
        action: 'update_ttp',
        data: {
          comments: this.comments,
          ttpNo: this.data.ttpNo,
          status: this.data.status,
          partialUpdate: true,
        }
      }
      console.log(req_Data)
      this.loader = true;
      this.spinner.show();
      this.ttpService.putMethod(req_Data).subscribe((res: any) => {
        if (res && res.status === 'SUCCESS') {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Comments added successfully' });
          if (res.ttp_data) {
            this.data = res.ttp_data;
          }
          this.comments = '';
          this.closeCommentsDialog();
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'TTP Updation Failed' });
        }
        this.loader = false;
        this.spinner.hide();
      }, (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server Error' });
        this.closeCommentsDialog();
        this.loader = false;
        this.spinner.hide();
      });
    }
  }
  dialogConfirm(mode, statusCode: any): any {
    const data: any = {};
    data.content = `${mode} ..?`;
    const dialogRef = this.dialog.open(DynamicConfirmationDialogComponent, {
      width: '25%',
      data:data,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result.message}`);
      if (result.message === 'Success') {
        if (mode === 'Create') {
          this.createTTP();
        } else {
          this.reviewTTP(statusCode);
        }
      }
    });
  }
  reviewTTP(statusCode): any {
    console.log('reviewTTP', this.ttpForm);
    if (this.ttpForm.status === 'VALID') {
      const req_Data = {
        action: 'update_ttp',
        data: this.ttpForm.value
      }
      req_Data.data.status = statusCode;
      req_Data.data.ttpNo = this.data.ttpNo
      console.log(req_Data)
      this.loader = true;
      this.spinner.show();
      this.ttpService.putMethod(req_Data).subscribe((res: any) => {
        if (res && res.status === 'SUCCESS') {
          this.dialogRef.close('TTP Updated Successfully');
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'TTP Updation Failed' });
          this.dialogRef.close('TTP Updation Failed');
        }
        this.loader = false;
        this.spinner.hide();
      }, (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server Error' });
        this.dialogRef.close();
        this.loader = false;
        this.spinner.hide();
      });
    }
  }
  onFileSelected(event: any): any {
    console.log('ee', event);
    if (event.target.files[0] && event.target.files[0].type !== 'application/pdf') {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please upload only PDF File' });
      this.fileInput.nativeElement.value = '';
      return;
    } else if (event.target.files && event.target.files[0] && event.target.files[0].size > 5242880) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File should not exceed more than 5MB' });
      this.fileInput.nativeElement.value = '';
      return;
    }
    this.selectedFile = event.target.files[0] ?? null;
    if (this.selectedFile !== null) {
      this.getBase64(event.target.files[0]);
    }
  }
  getBase64(fileToLoad): any {
    const thiss = this;
    const fileReader = new FileReader();
    fileReader.onload = (fileLoadedEvent) => {
      let src: any = fileLoadedEvent.target.result;
      if (src.match('data:application/pdf;')) {
        src = src.replace("data:application/pdf;", "");
      }
      const obj = { name: fileToLoad.name, source: src };
      thiss.ttpForm.controls.file.setValue(obj);
    }
    fileReader.readAsDataURL(fileToLoad);
  }

  resetForm(): any {
    this.ttpHtmlForm.nativeElement.reset();
    this.selectedFile = '';
    this.fileInput.nativeElement.value = '';
  }

  downloadFile(file): any {
    var a = document.createElement('a');
    a.href = 'data:application/pdf;' + file.source;
    a.download = `${file.name}`;
    a.click();
  }
  openCommentsDialog(templateRef): any {
    this.commentsDialogRef = this.dialog.open(templateRef, {
      width:'50%',
      disableClose: true
    });    
  }
  closeCommentsDialog(): any {
    this.comments = '';
    this.commentsDialogRef.close();
  }
  isBase64(str): any {
    let status = false;
    if (str) {
      const isStatus = str.match('base64,');
      if (isStatus !== null) {
        status = true;
      }
    }
    return status;
  }

}
