import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonsService } from 'src/app/core/services/commons.service';
import { IndentManagementService } from 'src/app/core/services/indent-management.service';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-open-action-update-indent-dialog',
  templateUrl: './open-action-update-indent-dialog.component.html',
  styleUrls: ['./open-action-update-indent-dialog.component.scss']
})
export class OpenActionUpdateIndentDialogComponent implements OnInit {

  icdcData = [];
  isUpdated: boolean = false;
  productsData: any;

  constructor(public dialogRef: MatDialogRef<OpenActionUpdateIndentDialogComponent>, 
    @Inject(MAT_DIALOG_DATA)public data,
    public shipmentService: ShipmentsService,
    public indentManagementService: IndentManagementService,
    private spinner: NgxSpinnerService,
    public commonService: CommonsService,
    private messageService: MessageService,
    public util: UtilService) { }

  ngOnInit(): void {
    this.productsData = JSON.parse(JSON.stringify(this.data));
  }

  dialogClose(): void{
    this.dialogRef.close();
  }

  revertIndent(action){
    this.spinner.show();
    const icdcAction = this.productsData.icdcCode ? 'icdcs' : 'indents';
    const actionName = `${action+'_'+icdcAction}`;
    const productData = this.productsData;
    // productData.productDetails.forEach(item => {
    //   let changedProduct = this.productsData.productDetails.find(product => product.productCode === item.productCode);
    //   if(changedProduct){
    //     item.preApprovedCases = changedProduct.approvedCases
    //   }   
    //   item.isDeleted = changedProduct ? false : true;
    // });
    let productDetailsData = [];
    this.data.productDetails.forEach(item => {
      let changedProduct = productData.productDetails.find(product => product.productCode === item.productCode);
      productDetailsData.push({
          ...item,
          preApprovedCases: item.approvedCases,
          preApprovedBottles: item.approvedBottles,
          approvedCases: changedProduct ? changedProduct.approvedCases : item.approvedCases,
          approvedBottles: changedProduct ? changedProduct.approvedBottles : item.approvedBottles,
          isDeleted:changedProduct ? false: true
        });
    });
    // const changedProducts = this.productsData.productDetails.map(product =>{
    //   let changedProduct = productData.productDetails.find(item => item.productCode === product.productCode && item.approvedCases !== product.approvedCases); 
    //   if(changedProduct){
    //     return {
    //       productCode: product.productCode,
    //       approvedCases: product.approvedCases
    //     };
    //   }      
    // });
    if(this.data.icdcCode){
      const revertPayload = {
        action: actionName,
        data: {
          icdcCode: this.data.icdcCode,
          indentId: this.data.indentId,
          productDetails: productDetailsData,
          // changedProductsDetails: changedProducts.filter(item => item !== undefined)
        }
      };
      this.shipmentService.revertRequest(revertPayload).subscribe((res: any) => {
        if (res.status === 'SUCCESS') {
          this.dialogRef.close('TP Updated Successfully');
          this.isUpdated = false;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: res.error ? res.error : 'Not Updated' });
        }
        this.spinner.hide();
      }, (err) => {
        //this.error = err.error.message;
      });
    } else{
      const revertPayload = {
        action: actionName,
        data: {
          indentId: this.data.indentId,
          productDetails: productDetailsData,
          //changedProductsDetails: changedProducts.filter(item => item !== undefined)
        }
      };
      this.indentManagementService.revertRequest(revertPayload).subscribe((res: any) => {
        if (res.status === 'SUCCESS') {
          this.dialogRef.close('TP Updated Successfully');
          this.isUpdated = false;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: res.error ? res.error : 'Not Updated' });
        }
        this.spinner.hide();
      }, (err) => {
        //this.error = err.error.message;
      });
    }
  }


  quantityCheck(productData) {
    const selectedProduct = productData;
    const matchedProducts = this.data.productDetails.find(item => item.productCode === selectedProduct.productCode);
    if (selectedProduct) {
      let unitsPerCase = this.util.productCodeToDetails(selectedProduct.productCode).unitsPerCase
      const scannedQty = selectedProduct.totalScannedCases ? selectedProduct.totalScannedCases : selectedProduct.casesCount ? selectedProduct.casesCount : 0;
      const totalScannedQty = scannedQty + (selectedProduct?.totalScannedBottles ? (selectedProduct?.totalScannedBottles / unitsPerCase) : 0);
      return productData.approvedCases === null || productData.approvedCases === 0 || productData.approvedCases < totalScannedQty || productData.approvedCases > matchedProducts.approvedCases;
    }
  }
  quantityCheckPartBottles(productData) {
    const selectedProduct = productData;
    const matchedProducts = this.data.productDetails.find(item => item.productCode === selectedProduct.productCode);
    if (selectedProduct) {
      // let unitsPerCase = this.util.productCodeToDetails(selectedProduct.productCode).unitsPerCase
      const totalScannedQty = selectedProduct.bottlesCount ? selectedProduct.bottlesCount : 0;
      // const totalScannedQty = scannedQty + (selectedProduct?.totalScannedBottles ? (selectedProduct?.totalScannedBottles / unitsPerCase) : 0);
      return productData.approvedBottles === null || productData.approvedBottles === 0 || productData.approvedBottles < totalScannedQty || productData.approvedBottles > matchedProducts.approvedBottles;
    }
  }

  getCaseCount(item){
    if(item.breakages){
      if(item.breakages.bottleBreakages){
        this.util.getTotalScannedCasesBottles(item);
        // let unitsPerCase = this.util.productCodeToDetails(item.productCode).unitsPerCase
        // let totalScannedBottles = (item.breakages.bottleBreakages.length * unitsPerCase) - item.breakages.bottles;
        // item.totalScannedCases = item.casesCount + Math.floor(totalScannedBottles/unitsPerCase);
        // item.totalScannedBottles = totalScannedBottles % unitsPerCase;
      }
    }
    return item;
  }

  deleteProduct(productDetails, index){
    const icdcProductsData = this.productsData.productDetails;
    icdcProductsData.splice(index, 1);
    this.productsData.productDetails = icdcProductsData;
    this.isUpdated =  true;
  }

  checkForError(){
    if(this.productsData){
      const outofRangeData = this.productsData.productDetails.map((product,i) =>{
        return this.quantityCheck(product);
      });
  
      return outofRangeData.some(item => item === true);
    }
  }

}
