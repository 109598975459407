import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { CommonsService } from 'src/app/core/services/commons.service';
import { ExciseLicenseeService } from 'src/app/core/services/excise-licensee.service';
import { UserAccountsService } from 'src/app/core/services/user-accounts.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-edit-registration-form',
  templateUrl: './edit-registration-form.component.html',
  styleUrls: ['./edit-registration-form.component.scss']
})
export class EditRegistrationFormComponent implements OnInit {

  registrationForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  depots = [];
  exciseChemicalLabs = [];
  revenueDistricts = [];
  exciseDistricts = [];
  saveDisabled: boolean;
  offset: number;
  public depotFilterCtrl: FormControl = new FormControl();
  genderType = ['Male', 'Female', 'TG'];
  addressIds = ['Aadhaar', 'Driving License', 'Voter Id', 'Passport', 'PAN Card']
  shopDetails: any;
  filteredDepots: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);


  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef < EditRegistrationFormComponent > ,
              @Inject(MAT_DIALOG_DATA) public data, private commonsService: CommonsService,
              private exciseLicenseeService: ExciseLicenseeService, public util: UtilService, private userAccountsService: UserAccountsService) {}

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.getDepots();
    this.getCommonList();
    this.shopDetails = this.data && this.data.get_shop_details && this.data.get_shop_details.length > 0 ? this.data.get_shop_details[0] :  [];
    this.registrationForm = this.fb.group({
      revenueDistrict: ['', [Validators.required, ]],
      exciseDistrict: ['', [Validators.required, ]],
      depot: [''],
      // depot: ['', [Validators.required, ]],
      exciseCircle: ['', [Validators.required, ]],
      locationZone: ['', [Validators.required, ]],
      licenseeCategory: ['', [Validators.required, ]],
      licenseeType: ['', [Validators.required, ]],
      licenseeCode: ['', [Validators.required, ]],
      licenseeAmount: ['', [Validators.required, ]],
      licenseDate: ['', [Validators.required, ]],
      expiryDate: ['', [Validators.required, ]],
      apgstNo: ['', [Validators.required, ]],
      incomeTax: ['', [Validators.required, ]],
      purchaseTax: ['', [Validators.required, ]],
      tcsLimit: ['', [Validators.required, ]],
      tcsValidityDate: ['', [Validators.required, ]],
      retailerCode: ['', [Validators.required, ]],
      gazetteCode: ['', [Validators.required, ]],
      vbCode: ['', [Validators.required, ]],
      professionalTax: ['', [Validators.required, ]],
      addressId: ['', [Validators.required, ]],
      addressNo: ['', [Validators.required, ]],
      fathersName: ['', [Validators.required, ]],
      shopName: ['', [Validators.required, ]],
      address: ['', [Validators.required, ]],
      pinCode: ['', [Validators.required, ]],
      mobileNo: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      apgstDate: ['', [Validators.required, ]],
      licenseeName: ['', [Validators.required, ]],
      panNo: ['', [Validators.required, ]],
      aadharNo: ['', [Validators.required, ]],
      tinNo: ['', [Validators.required, ]],
      dob: ['', [Validators.required, ]],
      gender: ['', [Validators.required, ]],
      nowkarnamaId: ['', [Validators.required, ]],
      name: ['', [Validators.required, ]],
      fatherName: ['', [Validators.required, ]],
      // addressProofId: ['', [Validators.required, ]],
      addressProofId: [''],
      addressProofNo: ['', [Validators.required, ]],
      village: ['', [Validators.required, ]],
      mandal: ['', [Validators.required, ]],
      district: ['', [Validators.required, ]],

      routeName: ['', [Validators.required, ]],
      source: ['', [Validators.required, ]],
      destination: ['', [Validators.required, ]],
      routeDescription: ['', [Validators.required, ]],
      depotFilter: ''
    });
    if (this.shopDetails.length > 0) {
      this.registrationForm.patchValue({
        revenueDistrict: this.shopDetails.address.revenue_district,
        exciseDistrict: this.shopDetails.exciseDistrict,
        depot: this.shopDetails.assignedDepot,
        exciseCircle: this.shopDetails.address.excise_circle,
        locationZone: this.shopDetails.address.zone,
        licenseeCategory : this.shopDetails.licenseeCategory,
        licenseeType: this.shopDetails.locationType,
        licenseeCode: this.shopDetails.licenseeCode,
        licenseeAmount : this.shopDetails.licenseeAmount,
        licenseDate: this.shopDetails.licenseDate,
        expiryDate: this.shopDetails.expiryDate,
        apgstNo : this.shopDetails.apgstNo,
        apgstDate : this.shopDetails.apgstDate,
        incomeTax : this.shopDetails.incomeTax,
        purchaseTax : this.shopDetails.purchaseTax,
        tcsLimit : this.shopDetails.tcsLimit,
        tcsValidityDate : this.shopDetails.tcsValidityDate,
        retailerCode : this.shopDetails.retailerId,
        gazetteCode : this.shopDetails.gazetteCode,
        vbCode : this.shopDetails.vbCode,

        professionalTax : this.shopDetails.professionalTax,
        licenseeName: this.shopDetails.licenseeName,
        panNo: this.shopDetails.panNo,
        aadharNo : this.shopDetails.aadharNo,
        tinNo: this.shopDetails.tinNo,
        dob: this.shopDetails.dob,
        gender : this.shopDetails.gender,
        mobileNo : this.shopDetails?.address?.phoneNumbers[0]?.number.replace(/\D/g, ''),
        pinCode : this.shopDetails.address.pincode,
        address : this.shopDetails.address.address,
        shopName : this.shopDetails.name,
        fathersName : this.shopDetails.fathersName,
        addressNo : this.shopDetails.addressNo,
        addressId : this.shopDetails.addressId,

        nowkarnamaId: this.shopDetails.nowkarnamaId,
        name: this.shopDetails.name,
        fatherName: this.shopDetails.fatherName,
        addressProofId : this.shopDetails.addressProofId,
        addressProofNo: this.shopDetails.addressProofNo,
        village: this.shopDetails.address.village,
        mandal : this.shopDetails.address.mandal,
        district : this.shopDetails.address.revenue_district,

        routeName : this.shopDetails.routeName,
        source: this.shopDetails.source,
        destination : this.shopDetails.destination,
        routeDescription: this.shopDetails.routeDescription,
      });
    }
    this.registrationForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
    this.util.matSelectFilter(this, "depotFilterCtrl", "filteredDepots", "depots");
  }

  get f() {
    return this.registrationForm.controls;
  }

  getCommonList() {
    const req_Data = {
      action: 'common_apis',
      q: `{"data": {"action": "get_static_codes", "value": ["exciseChemicalLabs", "revenueDistricts", "exciseDistricts"]}}`
    }
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {
      for (let key in result) {
        this[key] = result[key];
      }
    })
  }

  getDepots() {
    let paramObj = {
      action: 'common_apis',
      q: '{"data": ["get_depots"]}'
    }
    this.commonsService.getCommonEntites(paramObj).subscribe((res) => {
      this.depots = res['get_depots'].map((depot) => depot.depotCode);
      this.filteredDepots.next(this.depots);
    });
  }

  createRetailer() {
    const payload = [{
      action: 'add_retailers',
      data: this.registrationForm.value
    }];
    this.userAccountsService.createRetailer(payload).subscribe((res: any) => {
      this.dialogRef.close('Created Retailer Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  dialogClose(): void{
    this.dialogRef.close();
  }

  onSave() {
    const obj = {
      action: 'edit_shop_details',
      data: { ...this.registrationForm.value }
    };
    this.exciseLicenseeService.updateLicensee(obj).subscribe(res => {
      console.log('res', res);
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

}
