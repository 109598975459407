import { MessageService } from 'primeng/api';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { RoleModuleFeaturesService } from 'src/app/core/services/role-module.features';

@Component({
  selector: 'app-manage-feature-permission-dialog',
  templateUrl: './manage-feature-permission-dialog.component.html',
  styleUrls: ['./manage-feature-permission-dialog.component.scss']
})
export class ManageFeaturePermissionDialogComponent implements OnInit {

  form: FormGroup;
  error: any;
  rmfDatas: any = [];
  statuses: any = ['Active', 'Inactive'];
  modFeatIndexDatas: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private rmfService: RoleModuleFeaturesService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<ManageFeaturePermissionDialogComponent>,
  ) { }

  ngOnInit(): void {
    console.log('data =>', this.data);
    this.form = this.fb.group({
      roleName: ['', [Validators.required ]],
      moduleName: ['', [Validators.required ]],
      featureName: ['', [Validators.required ]],
      entityType: [this.data.featureName, [Validators.required ]],
      browser_type: ['Web', [Validators.required ]],
      status: ['Active', [Validators.required ]],
      modFeatIndex: ['', [Validators.required ]]
    });
    if (this.data.formData) {
      this.form.patchValue({
        roleName: this.data.formData.roleName ? this.data.formData.roleName : '',
        moduleName: this.data.formData.moduleName,
        featureName: this.data.formData.featureName,
        entityType: this.data.featureName,
        browser_type: 'Web',
        status: this.data.formData.status !== '' ? this.data.formData.status : 'Active',
        modFeatIndex: this.data.formData.modFeatIndex,
      });
    } else {
      this.getDatas();
      // if (this.data.modFeatIndex) {
      //   let featureIndex = this.data.modFeatIndex.split('.');
      //   featureIndex =  featureIndex[0] + '.' + (parseInt(featureIndex[1], 10) + 1);
      //   this.form.patchValue({
      //     modFeatIndex: featureIndex
      //   });
      // }
    }
  }
  getDatas(): any {
    const queryObject: any = {
      data: {
        action: 'get_module_features',
        value: {
          entityType: this.data.featureName
        }
      }
    };
    const paramObj: any = {
      action: 'common_apis',
      q: JSON.stringify(queryObject)
    };

    this.rmfService.getMethod('commons', paramObj).subscribe((res) => {
      if (res && res.get_module_features) {
        this.rmfDatas = res.get_module_features;
      }
    });
  }
  createUpdate(): any {
    if (!this.form.valid) {
      return;
    }
    // tslint:disable-next-line:variable-name
    const req_Data = {
      action : 'update_feature_permission',
      data : this.form.value
    };
    console.log('req_Data', req_Data);
    this.rmfService.updateMethod(this.data.url, req_Data).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: this.data.formData ? 'Successfully updated feature permissions' : 'Successfully created feature permissions' });
        this.dialogRef.close(true);
      } else {
        this.messageService.add({ severity: 'error', detail: 'Successfully updated feature permissions' });
      }
    }, (err) => {
      this.messageService.add({ severity: 'error', detail: 'Module has not been updated' });
      this.error = err.error.message;
    });
  }
  getModFeatIndex(evt): any {
    const queryObject: any = {
      data: {
        action: 'get_module_features',
        value: {
          entityType: this.data.featureName,
          roleName: evt.value
        }
      }
    };
    const paramObj: any = {
      action: 'common_apis',
      q: JSON.stringify(queryObject)
    };
    this.modFeatIndexDatas = [];
    this.form.controls.modFeatIndex.setValue('');
    this.rmfService.getMethod('commons', paramObj).subscribe((res) => {
      if (res && res.get_module_features) {
        this.modFeatIndexDatas = res.get_module_features.modFeatIndex;
      }
    });
  }
  isValidModFeatIndex(): any {
    const modFeatIndex  = this.form.controls.modFeatIndex.value;
    const isValid = this.modFeatIndexDatas.find(res => res.modFeatIndex === modFeatIndex);
    if (isValid) {
      this.messageService.add({ severity: 'error', detail: 'Already this modFeatIndex exists, Please try a different one..' });
      this.form.controls.modFeatIndex.setValue('');
      return;
    }
  }
}
