import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


const baseUrl = environment.baseUrl;
/* const brandCodesUrl = baseUrl + 'products/brandcodes';
const productPriceUrl = baseUrl + 'production/products';
const rationValueUrl = baseUrl + 'depotstock/ration'; */
const rmpUrl = baseUrl + 'raw_material_procurement'

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Raw Material Procurement'] && userModules.mod_feats['Raw Material Procurement'].features ? userModules.mod_feats['Raw Material Procurement'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class RawMaterialProcurementService {
  currentUser: any;
  
  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(
      sessionStorage.getItem('lappCurrentUserDetails')
    );
  }

  submitProcurement(req_Data) {
    return this.http.put(rmpUrl, req_Data);

  }

  getRmpFeatures(): Observable<any> {
return this.http.get(rmpUrl);
  }

  createRmpIndent(reqData): Observable<any> {
    return this.http.post(rmpUrl, reqData);
  }
}
