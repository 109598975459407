import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { LiquorPricingService } from 'src/app/core/services/liquor-pricing.service';
import { OpenSupplierDialogComponent } from '../../users/supplier/open-supplier-dialog/open-supplier-dialog.component';

@Component({
  selector: 'app-open-basic-prices-dialog',
  templateUrl: './open-basic-prices-dialog.component.html',
  styleUrls: ['./open-basic-prices-dialog.component.scss']
})
export class OpenBasicPricesDialogComponent implements OnInit {
  basicPriceForm: FormGroup;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<OpenSupplierDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private messageService: MessageService, private liquorPricingService: LiquorPricingService) {
  }

  ngOnInit(): void {
    this.basicPriceForm = this.fb.group({
      basicPriceLowerValue: ['', Validators.required],
      basicPriceUpperValue: ['', Validators.required],
      basicValue: ['', Validators.required],
      basicPercent: ['', Validators.required],
      category: ['', Validators.required],
      priceName: ['', Validators.required],
      priceGroupId: ['', Validators.required],
    });
    if (this.data) {
      this.basicPriceForm.patchValue({
        basicPriceLowerValue: this.data.basicPriceLowerValue ? this.data.basicPriceLowerValue : '',
        basicPriceUpperValue: this.data.basicPriceUpperValue ? this.data.basicPriceUpperValue : '',
        basicValue: this.data.basicValue ? this.data.basicValue : '',
        basicPercent: this.data.basicPercent ? this.data.basicPercent : '',
        category: this.data.category ? this.data.category : '',
        priceName: this.data.priceName ? this.data.priceName : '',
        priceGroupId: this.data.priceGroupId ? this.data.priceGroupId : '',
      });
    }
  }

  createBasicPrice() {
    const req_Data = {
      action: 'add_basic_price_rules',
      data: this.basicPriceForm.value
    }

    this.liquorPricingService.createBasicPrices(req_Data).subscribe((res: any) => {
      if (res.status && res.status != 'Basic Price already exists') {
        this.dialogRef.close('Basic Price Added Sucessfully');
      }
    }, (err) => {
      this.messageService.add({ severity: 'error', detail: err.error.message });
    });
  }

  updateBasicPrice() {
    const req_Data = {
      action: 'update_basic_price_rules',
      data: this.basicPriceForm.value
    }

    this.liquorPricingService.putBasicPrices(req_Data).subscribe((res: any) => {
      if (res.status && res.status != 'Basic Price already exists') {
        this.dialogRef.close('Basic Price Updated Sucessfully');
      }
    }, (err) => {
      this.messageService.add({ severity: 'error', detail: err.error.message });
    });
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

}