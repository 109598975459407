import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Brands } from '../models/stock-management/brands.model';
import { Inventory } from '../models/stock-management/inventory.model';

const baseUrl = environment.baseUrl;
const stockManagementUrl = baseUrl+ 'stock_management'
const inventoryUrl = baseUrl + 'stock/stockinventory';
const brandsUrl = baseUrl + 'stockmanagement/brands';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Stock Management'] && userModules.mod_feats['Stock Management'].features ? userModules.mod_feats['Stock Management'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class StockManagementService {

  constructor(private http: HttpClient) { }

  createInventory(inventoryObj: Inventory){
    return this.http.post(inventoryUrl, inventoryObj)
  }
  getInventory(offset, limit, search?: string):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);

    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = '';
    return this.http.get(inventoryUrl, {params: paramObj});
  }

  putInventory(inventoryObj){
    return this.http.put<Inventory>(`${inventoryUrl}`, inventoryObj)
  }

  createBrands(brandsObj: Brands){
    return this.http.post(brandsUrl, brandsObj)
  }
  getBrands(offset, limit, search?: string):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);

    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = '';
    return this.http.get(brandsUrl, {params: paramObj});
  }

  putBrands(brandsObj){
    return this.http.put<Brands>(`${brandsUrl}`, brandsObj)
  }

  getStockManagementFeatures() {
    const headerDict = {
      feature
    };
    return this.http.get(stockManagementUrl, { headers: headerDict });
  }
}
