import { UtilService } from "./../../../../core/utils/utility-helper";
import { IndentManagementService } from "./../../../../core/services/indent-management.service";
import { MessageService } from "primeng/api";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, Inject, AfterViewInit, EventEmitter, Output } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { MatTableDataSource } from "@angular/material/table";
import { BottleSizes } from "src/app/core/constants/bottle-sizes.constants";
import { CommonsService } from "src/app/core/services/commons.service";
declare const JsBarcode: any;

@Component({
  selector: "app-vendor-indent-status-dialog",
  templateUrl: "./vendor-indent-status-dialog.component.html",
  styleUrls: ["./vendor-indent-status-dialog.component.scss"],
})
export class VendorIndentStatusDialogComponent
  implements OnInit, AfterViewInit
{
  roleName: string;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  displayedColumns = [
    "no",
    "productCode",
    "brandName",
    "casesCount",
    "approvedCasesCount",
  ];
  imlBeerCount: any = {};
  totalDatas: any = {};
  vendorDetails: any;
  bottleSizesList = BottleSizes;
  isDisable: any;
  cfmsLink: any =
    "https://prdcfms.apcfss.in:44300/sap/bc/ui5_ui5/sap/zfi_rcp_cstatus/index.html?sap-client=350";
  loader: any = false;
  checkIndent: any = false;
  checkVendorData: any;
  cfmsVerified:boolean=false;
  @Output() refreshPage = new EventEmitter<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private indentManagementService: IndentManagementService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<VendorIndentStatusDialogComponent>,
    private spinner: NgxSpinnerService,
    public util: UtilService,
    private commonsService: CommonsService
  ) {
    this.roleName = JSON.parse(
      sessionStorage.getItem("lappCurrentUserModules")
    ).role;
    this.vendorDetails = JSON.parse(
      sessionStorage.getItem("lappCurrentUserModules")
    ).vendor_address;
    console.log(this.vendorDetails);
  }

  ngOnInit(): void {
    if (
      this.data.status === "Approved" ||
      this.data.status === "SUCCESS" ||
      this.data.status === "Rejected" ||
      this.data.status === "InProgress"
    ) {
      this.displayedColumns.splice(3, 1);
      this.getProductDetails();
    }
    this.dataSource = new MatTableDataSource(this.data.productDetails);
    console.log(this.data);
    this.data.productDetails.map((item: any) => {
      if (item.requestedCases) {
        item.approvedCases = item.requestedCases;
      } else if (item.requestedBottles) {
        item.approvedBottles = item.requestedBottles;
      }
    });
  }
  ngAfterViewInit(): any {
    if (
      (this.data && this.data.indentId && this.data.status === "Approved") ||
      this.data.status === "SUCCESS" ||
      this.data.status === "Rejected" ||
      this.data.status === "InProgress"
    ) {
      JsBarcode("#barcode", this.data.indentId, {
        width: 1,
        height: 40,
      });
    }
  }
  checkApproval() {
    let data: any = {};
    data = {
      indentId: this.data.indentId,
      productDetails: this.data.productDetails,
    };
    const reqData = {
      action: "check_vendor_indent",
      data: data,
    };
    // this.loader = true;
    this.indentManagementService
      .approveRejectVendorIndentStatus(reqData)
      .subscribe((res: any) => {
        if (res && res["check_vendor_indent"]) {
          this.checkVendorData = res["check_vendor_indent"];
          const totalIndentValue = this.data.taxes[1].totalIndentValue;
          this.checkVendorData.productDetails.forEach((item: any) => {
            console.log(item.totalIndentValue, totalIndentValue);
            if (
              totalIndentValue === item.totalIndentValue ||
              totalIndentValue > item.totalIndentValue
            ) {
              this.checkIndent = true;
              // this.loader = false;
            } else {
              this.checkIndent = false;
              // this.loader = false;
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: "Indent amount is not matching reduce the cases",
              });
            }
          });
        }
      });
  }
  approve(): any {
    let data: any = {};
    data = {
      vendorId: this.data.destnEntityId,
      indentId: this.data.indentId,
      transactionAmount: this.data.transactionAmount,
      productDetails: this.data.productDetails,
    };
    data.productDetails.map((item: any) => {
      item.approvedCases = item.approvedCases
        ? item.approvedCases
        : item.requestedCases;
    });
    console.log(data);
    const reqData = {
      action: "vendor_approve",
      data: data,
    };
    console.log(reqData);
    // this.spinner.show();
    this.loader = true;
    this.indentManagementService
      .approveRejectVendorIndentStatus(reqData)
      .subscribe(
        (res: any) => {
          if (res.status === "SUCCESS") {
            this.dialogRef.close("Vendor Indent Status has been approved");
            this.loader = false;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Vendor Indent Status has not approved",
            });
            this.loader = false;
          }
          this.spinner.hide();
        },
        (err) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Vendor Indent Status has not approved",
          });
          this.loader = false;
          this.spinner.hide();
        }
      );
  }
  verifyCfms() {
    let data: any = {};
    data = {
      cfmsId: this.data.cfmsId,
      indentId: this.data.indentId,
    };
    const reqData = {
      action: "vendor_verify",
      data: data,
    };
    this.spinner.show();
    this.indentManagementService
      .approveRejectVendorIndentStatus(reqData)
      .subscribe(
        (res: any) => {
          console.log(res);
          if (res.status === "SUCCESS") {
            this.cfmsVerified = true;
            // this.dialogRef.close("CFMS Id has been verified");
            this.messageService.add({
              severity: "success",
              summary: "Success",
              detail: "CFMS Id has been verified",
            });
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "CFMS Id not verified",
            });
          }
          this.spinner.hide();
        },
        (err) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "CFMS Id not verified",
          });
          this.spinner.hide();
        }
      );
  }

  reject(): any {
    const reqData = {
      action: "vendor_reject",
      data: {
        vendorId: this.data.destnEntityId,
        indentId: this.data.indentId,
        transactionAmount: this.data.transactionAmount,
        productDetails: this.data.productDetails,
      },
    };
    this.spinner.show();
    this.indentManagementService
      .approveRejectVendorIndentStatus(reqData)
      .subscribe(
        (res: any) => {
          if (res.status === "SUCCESS") {
            this.messageService.add({
              severity: "success",
              summary: "Success",
              detail: "Vendor Indent Status has been rejected",
            });
            this.dialogRef.close("SUCCESS");
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: "Vendor Indent Status has not rejected",
            });
          }
          this.spinner.hide();
        },
        (err) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Vendor Indent Status has not rejected",
          });
          this.spinner.hide();
        }
      );
  }
  danger() {
    console.log("Test Success");
  }

  quantityCheck(i) {
    if (this.data.productDetails[i]["requestedCases"]) {
      return (
        this.data.productDetails[i]["requestedCases"] <
        this.data.productDetails[i].approvedCases
      );
    } else if (this.data.productDetails[i]["requestedBottles"]) {
      return (
        this.data.productDetails[i]["requestedBottles"] <
        this.data.productDetails[i].approvedBottles
      );
    }
  }

  changeQuantity(i, e) {
    if (this.data.productDetails[i].requestedBottles) {
      this.data.productDetails[i].approvedBottles = parseInt(e.target.value);
    } else {
      this.data.productDetails[i].approvedCases = parseInt(e.target.value);
    }
  }

  checkApprovedQty() {
    if (
      this.data.productDetails.some(
        (product) =>
          product.approvedCases <= product.requestedCases ||
          product.approvedBottles <= product.requestedBottles
      )
    ) {
      this.approve();
    } else {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Enter valid quantity",
      });
    }
  }
  print(): any {
    if (document.getElementById("print-indent-form")) {
      setTimeout(() => {
        let printContents;
        let popupWin;
        printContents = document.getElementById("print-indent-form").innerHTML;
        popupWin = window.open(
          "",
          "_blank",
          "top=0,left=0,height=100%,width=auto"
        );
        popupWin.document.write(`${printContents}`);
        popupWin.document.close();
      }, 500);
    }
  }
  getProductDetails(): any {
    this.totalDatas = {};
    // this.imlBeerCount.beer = 0;
    // this.imlBeerCount.iml = 0;
    this.totalDatas.grandTotal = 0;
    this.totalDatas.imlCases = 0;
    this.totalDatas.beerCases = 0;
    this.totalDatas.imlBottles = 0;
    this.totalDatas.beerBottles = 0;
    if (this.data && this.data.productDetails.length) {
      this.data.productDetails.forEach((res: any) => {
        const productKeys = this.util.productCodeToDetails(res.productCode);
        res.sizeCode = productKeys?.size;
        res.packQty = productKeys?.unitsPerCase;
        res.packType = productKeys?.packType;
        res.bottles = 0;
        if (res.size) {
          res.size = res.size >= 1000 ? res.size + "l" : res.size + "ml";
        }
        if (productKeys.liquorType === "B") {
          res.productType = "Beer";
          this.totalDatas.beerCases +=
            this.roleName === "Licensee"
              ? res?.requestedCases
              : res?.approvedCases;
          this.totalDatas.beerBottles += res.bottles;
        } else {
          res.productType = "IML";
          this.totalDatas.imlCases +=
            this.roleName === "Licensee"
              ? res?.requestedCases
              : res?.approvedCases;
          this.totalDatas.imlBottles += res.bottles;
        }
        this.totalDatas.grandTotal +=
          (this.roleName === "Licensee"
            ? res?.requestedCases
            : res?.approvedCases) * (res?.issuePrice ? res?.issuePrice : 0);
      });
    }
  }
}
