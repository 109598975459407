import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-new-updates-dialog',
  templateUrl: './new-updates-dialog.component.html',
  styleUrls: ['./new-updates-dialog.component.scss']
})
export class NewUpdatesDialogComponent implements OnInit {
  entityType: any = '';
  constructor(
    public dialogRef: MatDialogRef<NewUpdatesDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data,
    public util: UtilService
  ) { }

  ngOnInit(): void {
    this.entityType = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).entity_type;
  }
  convertTimestampToDate(timestamp): any {
    if (timestamp) {
      return new Date(timestamp * 1000);  
    }
    return new Date();
  }

}
