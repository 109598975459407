import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { IndentManagementService } from '../../services/indent-management.service';

@Component({
  selector: 'app-dynamic-confirmation-dialog',
  templateUrl: './dynamic-confirmation-dialog.component.html',
  styleUrls: ['./dynamic-confirmation-dialog.component.scss']
})
export class DynamicConfirmationDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data,private dialogRef: MatDialogRef<DynamicConfirmationDialogComponent>,private messageService:MessageService,private indentManagementService: IndentManagementService) { }

  ngOnInit(): void {
  }

  confirm(): any {
    this.dialogRef.close({message: 'Success'});
  }

}
