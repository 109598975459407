import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { FeedbackService } from 'src/app/core/services/feedback.service';

@Component({
  selector: 'app-manage-feedback-dialog',
  templateUrl: './manage-feedback-dialog.component.html',
  styleUrls: ['./manage-feedback-dialog.component.scss']
})
export class ManageFeedbackDialogComponent implements OnInit {
  feedbackFrom:FormGroup;
  isLanConnection:boolean=false;
  isf1Breakages:boolean=false;
  isf2Breakages:boolean=false;
  isIndent:boolean=false;
  isCrediBal:boolean=false;
  isCertifiedSales:boolean=false;
  isStockUpdate:boolean=false;
  iscbVsPhy:boolean=false;
  iscbVsMis:boolean=false;
  isRemarks:boolean=false;
  isRepack:boolean=false;
  constructor(private fb:FormBuilder,private messageService:MessageService,private feedBackService: FeedbackService,
    private dialogRef: MatDialogRef<ManageFeedbackDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.feedbackFrom = this.fb.group({
      lanConnection:['',[Validators.required]],
      lanDescription:[''],
      f1Breakages:['',[Validators.required]],
      f1Description:[''],
      f2Breakages:['',[Validators.required]],
      f2Description:[''],
      indent:['',[Validators.required]],
      indentDescription:[''],
      crediBal:['',[Validators.required]],
      crediBalDescription:[''],
      certifiedSales:['',[Validators.required]],
      certifiedSalesDescription:[''],
      stockUpdate:['',[Validators.required]],
      stockUpdateDescription:[''],
      cbVsPhysical:['',[Validators.required]],
      cbVsPhysicalDescription:[''],
      cbVsMis:['',[Validators.required]],
      cbVsMisDescription:[''],
      remarks:['',[Validators.required]],
      remarksDescription:[''],
      repack:['',[Validators.required]],
      repackDescription:[''],
    })
    console.log(this.data)
    if(this.data && this.data.feedback){
      this.feedbackFrom.patchValue({
        lanConnection:this.data.feedback.lanConnection,
        lanDescription:this.data.feedback.lanDescription,
        f1Breakages:this.data.feedback.f1Breakages,
        f1Description:this.data.feedback.f1Description,
        f2Breakages:this.data.feedback.f2Breakages,
        f2Description:this.data.feedback.f2Description,
        indent:this.data.feedback.indent,
        indentDescription:this.data.feedback.indentDescription,
        crediBal:this.data.feedback.crediBal,
        crediBalDescription:this.data.feedback.crediBalDescription,
        certifiedSales:this.data.feedback.certifiedSales,
        certifiedSalesDescription:this.data.feedback.certifiedSalesDescription,
        stockUpdate:this.data.feedback.stockUpdate,
        stockUpdateDescription:this.data.feedback.stockUpdateDescription,
        cbVsMis:this.data.feedback.cbVsMis,
        cbVsMisDescription:this.data.feedback.cbVsMisDescription,
        cbVsPhysical:this.data.feedback.cbVsPhysical,
        cbVsPhysicalDescription:this.data.feedback.cbVsPhysicalDescription,
        remarks:this.data.feedback.remarks,
        remarksDescription:this.data.feedback.remarksDescription,
        repack:this.data.feedback.repack,
        repackDescription:this.data.feedback.repackDescription,
      })
      this.data.feedback.lanConnection === 'No' ? this.isLanConnection = true : this.isLanConnection = false;
      this.data.feedback.f1Breakages === 'No' ? this.isf1Breakages = true : this.isf1Breakages = false;
      this.data.feedback.f2Breakages === 'No' ? this.isf2Breakages = true : this.isf2Breakages = false;
      this.data.feedback.indent === 'No' ? this.isIndent = true : this.isIndent = false;
      this.data.feedback.crediBal === 'No' ? this.isCrediBal = true : this.isCrediBal = false;
      this.data.feedback.certifiedSales === 'No' ? this.isCertifiedSales = true : this.isCertifiedSales = false;
      this.data.feedback.stockUpdate === 'No' ? this.isStockUpdate = true : this.isStockUpdate = false;
      this.data.feedback.cbVsMis === 'No' ? this.iscbVsMis = true : this.iscbVsMis = false;
      this.data.feedback.cbVsPhysical === 'No' ? this.iscbVsPhy = true : this.iscbVsPhy = false;
      this.data.feedback.remarks === 'No' ? this.isRemarks = true : this.isRemarks = false;
      this.data.feedback.repack === 'No' ? this.isRepack = true : this.isRepack = false;



    }
  }
  selectedLanOption(event:any,value){
    console.log(event,value)
    event === 'No' && value === 'lanConnection' ? this.isLanConnection = true : this.isLanConnection = false;
  }

  selectedF1Option(event:any,value){
    console.log(event,value)
    event === 'No' && value === 'f1Breakages' ? this.isf1Breakages = true : this.isf1Breakages = false;
  }

  selectedF2Option(event:any,value){
    console.log(event,value)
    event === 'No' && value === 'f2Breakages' ? this.isf2Breakages = true : this.isf2Breakages = false;
  }


  selectedIMOption(event:any,value){
    console.log(event,value)
    event === 'No' && value === 'indentmanagement' ? this.isIndent = true : this.isIndent = false;
  }

  selectedCBOption(event:any,value){
    console.log(event,value)
    event === 'No' && value === 'creditbalance' ? this.isCrediBal = true : this.isCrediBal = false;
  }

  selectedCSOption(event:any,value){
    console.log(event,value)
    event === 'No' && value === 'certifiedSales' ? this.isCertifiedSales = true : this.isCertifiedSales = false;
  }

  selectedStockOption(event:any,value){
    console.log(event,value)
    event === 'No' && value === 'stockUpdate' ? this.isStockUpdate = true : this.isStockUpdate = false;
  }

  selectedRepackOption(event:any,value){
    console.log(event,value)
    event === 'No' && value === 'repack' ? this.isRepack = true : this.isRepack = false;
  }

  selectedRemarksOption(event:any,value){
    console.log(event,value)
    event === 'No' && value === 'remarks' ? this.isRemarks = true : this.isRemarks = false;
  }

  
  selectedCbPhyOption(event:any,value){
    console.log(event,value)
    event === 'No' && value === 'cbVsPhysical' ? this.iscbVsPhy = true : this.iscbVsPhy = false;
  }


  selectedCbMisOption(event:any,value){
    console.log(event,value)
    event === 'No' && value === 'cbVsMis' ? this.iscbVsMis = true : this.iscbVsMis = false;
  }

  createFeedBack(){
    console.log(this.feedbackFrom.value)
    const formData = this.feedbackFrom.value;
    if((formData.lanConnection === 'No' && formData.lanDescription ==='') 
    || (formData.f1Breakages === 'No' && formData.f1Description ==='')
    || (formData.f2Breakages === 'No' && formData.f2Description ==='')
    || (formData.indent === 'No' && formData.indentDescription ==='')
    || (formData.crediBal === 'No' && formData.crediBalDescription ==='')
    || (formData.certifiedSales === 'No' && formData.certifiedSalesDescription ==='')
    || (formData.stockUpdate === 'No' && formData.stockUpdateDescription ==='')
    || (formData.cbVsPhysical === 'No' && formData.cbVsPhysicalDescription ==='')
    || (formData.cbVsMis === 'No' && formData.cbVsMisDescription ==='')
    || (formData.remarks === 'No' && formData.remarksDescription ==='')
    || (formData.repack === 'No' && formData.repackDescription ==='') )  {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter comments' });
      return;
    }
    const reqData={
      action:'add_feedback',
      data:this.feedbackFrom.value
    }
    this.feedBackService.addFeedBack(reqData).subscribe((res:any)=>{
      console.log(res)
      if(res.status === 'SUCCESS'){
        this.dialogRef.close('Form Submitted Successfully');
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Form submmtion faild' });

      }
    }, (err) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Form submmtion faild' });
    });
    

}
}
