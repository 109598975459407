import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonsService } from 'src/app/core/services/commons.service';
import { InterDepotService } from 'src/app/core/services/inter-depot.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-ofidt-details',
  templateUrl: './ofidt-details.component.html',
  styleUrls: ['./ofidt-details.component.scss']
})
export class OfidtDetailsComponent implements OnInit {
  ofidtFrom:FormGroup;
  ofidtCode;
  ofidtData:any;
  ordersList:any;
  ofidtDetails:any;
  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<OfidtDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,private commonsService: CommonsService,private interDepotService:InterDepotService,
    public util: UtilService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService) { 
      console.log(this.data);
    }

  ngOnInit(): void {
    console.log(this.data);
    
    this.ofidtFrom = this.fb.group({
      ofidtCode:['',Validators.required]
    })
    this.getOfidtData();
    this.getOrdersData();

  }
  getOrdersData(){
    this.interDepotService.getInterDepotFeatures().subscribe((res:any)=>{
      this.ordersList = res['Orders'];
      console.log(this.ordersList);
    })

  }
  getOfidtData() {
    let tpNo = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
    if (!tpNo) {
      tpNo = this.data.ipNo;
    }
    const req_Data = {
      action: 'common_apis',
      q: '{"data": {"action": "get_added_ofidt", "value": "' + tpNo + '"}}'
    };
    this.spinner.show();
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {
      this.ofidtData = result['get_added_ofidt'];
      if (this.ofidtData) {
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });

  }
  shipmentQuantityChange(item) {
    return item.approvedQty < item.shipmentQty || item.shipmentQty < item.casesCount;
  }
  shipmentEdit(item) {
    item.isEdit = true;
  }
  shipmentSave(item, ofs) {
    if (item.approvedQty < item.shipmentQty || item.shipmentQty < item.casesCount) {
      return;
    }
    const req_Data = {
      action: 'update_shipment_qty',
      data: { "tpNo": this.data.tpNo, "ofidtNo": ofs.ofidtNo, "productCode": item.productCode, "shipmentQty": item.shipmentQty, "batchId": item.batchId }
    }
    console.log(req_Data);
    
    // this.shipmentsService.saveShipmentQuantity(req_Data).subscribe(result => {

    // })
    item.isEdit = false;
  }
  addOfidt(): any {
    if (this.ofidtData && this.ofidtData.length) {
      const isDuplicate = this.ofidtData.filter(row => row.ofidtNo === this.ofidtFrom.controls.ofidtCode.value).length;
      if (isDuplicate > 0) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Duplicate OFIDT'});
        return;
      }
    }
    const isOFIDTInOrderList = this.ordersList.filter(row => row.ofidtNo === this.ofidtFrom.controls.ofidtCode.value).length;
    if (isOFIDTInOrderList === 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid OFIDT'});
      return;
    }
    this.ordersList.map((item:any)=>{
      if(item.ofidtNo == this.ofidtFrom.controls['ofidtCode'].value){
        this.ofidtDetails = item;
        if(this.ofidtDetails){
          let data = { 
            consignmentType:this.ofidtDetails.consignmentType,
            createdOn:this.ofidtDetails.createdOn,
            supplierCode:this.ofidtDetails.supplierCode,
            depotCode:this.ofidtDetails.destinationDepotCode,
            indentId:this.ofidtDetails.indentId,
            ofidtNo:this.ofidtDetails.ofidtNo,
            productDetails:this.ofidtDetails.productDetails,
            purchaseOrderDetails:'',
            status:this.ofidtDetails.status,
            sourceDepotCode:this.ofidtDetails.sourceDepotCode,
            destinationDepotCode:this.ofidtDetails.destinationDepotCode,
            tpNo:this.data.tpNo,
            updatedDateTime:this.ofidtDetails.updatedDateTime,
            updatedOn:this.ofidtDetails.updatedDateTime,
            validityDate:this.ofidtDetails.updatedDateTime
           };
          const reqData ={
            action: 'add_ofidt',
            data
          };
          this.spinner.show();
          this.interDepotService.updateIDT(reqData).subscribe((res: any) => {
            if (res.status === 'SUCCESS'){
              this.dialogRef.close('OFIDT Added Successfully');
            }
            this.spinner.hide();
          }, err => {
            this.spinner.hide();
          });
        }
      }
    });
  }

}
