import { ViewPriceLabelRegistrationComponent } from './view-price-label-registration/view-price-label-registration.component';
import { AfterViewInit, Component, HostListener, Inject, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BottleSizes, unitsPerCase } from 'src/app/core/constants/bottle-sizes.constants';
import { liquorTypes } from 'src/app/core/constants/liquor-type.constants';
import { packTypes } from 'src/app/core/constants/pack-type.constants';
import { CommonsService } from 'src/app/core/services/commons.service';
import { LabelRegistrationService } from 'src/app/core/services/label-registration.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-manage-label-registration-dialog',
  templateUrl: './manage-label-registration-dialog.component.html',
  styleUrls: ['./manage-label-registration-dialog.component.scss']
})
export class ManageLabelRegistrationDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('retailPricings') retailPricings;
  @ViewChild('basicPricing') basicPricing;
  packTypeList: string[];
  sizeList: string[];
  unitsPerCase: string[];
  packTypeConstant = {};
  sizeListConstant = {};
  unitsPerCaseConstant = {};
  brandData: any = [];
  public filteredBrandNames: ReplaySubject<any> = new ReplaySubject<any>(1);
  public filteredDistilleryNames: ReplaySubject<any> = new ReplaySubject<any>(1);
  public distilleryFilterCtrl: FormControl = new FormControl();
  public brandFilterCtrl: FormControl = new FormControl();
  @ViewChild('singleDistillerySelect') singleDistillerySelect: MatSelect;
  @ViewChild('singleBrandSelect') singleBrandSelect: MatSelect;
  public _onDestroy = new Subject<void>();
  distilleryNames: any = [];
  liquorTypesConstant: any;
  liquorList: string[];
  sizeDataList: any[];
  brandDataList: any;
  productTypes: any = ['Non-CSD', 'CSD'];
  stateLevyRules: any = ['Local', 'Non Local', 'Beer', 'Wine', 'Foriegn', 'Foriegn Beer', 'Foriegn Wines', 'Ready to Drink', 'Fruit Wines', 'Export'];
  selectedBrand: any = [];
  isDuplicate:Boolean;
  duplicateError = "Product Code shouldn't have duplicate";
  selectedSizePrice:any;
  supplierDatas: any = [];
  brandRootDatas: any = [];
  loader: any = false;


  constructor(private fb: FormBuilder, public util: UtilService, public dialogRef: MatDialogRef<ManageLabelRegistrationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,
    private commonsService: CommonsService, private spinner: NgxSpinnerService,
    private lrService: LabelRegistrationService,private messageService:MessageService,
    private dialog: MatDialog) { }

  labelRegistrationForm: FormGroup;

  async ngOnInit() {
    this.packTypeConstant = packTypes;
    this.sizeListConstant = BottleSizes;
    this.unitsPerCaseConstant = unitsPerCase;
    this.liquorTypesConstant = liquorTypes;

    this.packTypeList = Object.keys(packTypes);
    this.sizeList = Object.keys(BottleSizes);
    this.sizeDataList = Object.keys(BottleSizes).map(res => this.sizeListConstant[res]);
    this.unitsPerCase = Object.keys(unitsPerCase);
    // this.liquorList = Object.keys(liquorTypes);
    // console.log(this.data, this.packTypeList, "data::::::")

    this.labelRegistrationForm = this.fb.group({
      supplierCode: ["", Validators.required],
      packType: ["", Validators.required],
      brandCode: ['', Validators.required],
      size: ["", Validators.required],
      liquorCode: ['', Validators.required],
      // basicPricing: ['', Validators.required],

      productCode: ['', Validators.required],
      // retailPricings: ['', Validators.required],
      productType: ['', Validators.required],
      stateLevyRule: ['', Validators.required],
      effectiveDate: ['', Validators.required],
      taxes: ['', Validators.required],
      //
    })
    this.spinner.show();
    this.getLabelList();
    this.getCommonLists();

    this.util.matSelectFilter(this, "distilleryFilterCtrl", "filteredDistilleryNames", "distilleryNames");
    this.util.matSelectFilter(this, "brandFilterCtrl", "filteredBrandNames", "brandData");
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  getLabelList(){
    if (this.data && this.data.moduleName && this.data.moduleName === 'Products') {
      if (!this.lrService.featuresData) {
        this.lrService.featuresData = {};
      }
      this.lrService.featuresData['Label Registration'] = this.data.data;
      return;
    }
    this.lrService.getlrFeatures().subscribe(res => {
      this.lrService.featuresData = res;
    });
    
  }
  checkDuplicate(){
    this.isDuplicate =  this.lrService.featuresData['Label Registration'].some((data:any)=>data.productCode === this.labelRegistrationForm.controls.productCode.value)
    const ctrls = this.labelRegistrationForm.controls;
    const pricing = this.selectedBrand.filter(brand => brand.size === BottleSizes[this.labelRegistrationForm.controls.size.value] && brand.unitsPerCase === unitsPerCase[this.labelRegistrationForm.controls.size.value])
    const strength =this.selectedBrand.find(strength => strength.strength);
    ctrls.taxes.setValue('');
    const params: any = {
      supplier_code: ctrls.supplierCode.value,
      brandCode: ctrls.brandCode.value.brandCode,
      liquorType: ctrls.liquorCode.value,
      size: ctrls.size.value,
      strength: strength.strength,
      price: pricing && pricing.length && pricing[0].basicPricing && pricing[0].basicPricing.length && pricing[0].basicPricing[0]['price'],
    };
    const qData = {
      data: {
        action: this.labelRegistrationForm.value.liquorCode === 'BEER' ? 'beer_pricing' : 'get_pricing',
        value: [params]
      }
    };
    // tslint:disable-next-line:variable-name
    const req_Data = {
      action: 'common_apis',
      q: JSON.stringify(qData)
    };
    this.commonsService.getCommonEntites(req_Data).subscribe(res => {
      if (res && res.get_pricing) {
        ctrls.taxes.setValue(res.get_pricing);
      }
    });
    
  }

  getCommonLists() {
    const req_Data = {
      "action": "common_apis",
      "q": '{"data": ["get_dist_depot", "get_distinct_brands"]}'
    };
    this.commonsService.getCommonEntites(req_Data).subscribe(res => {
      this.spinner.hide();
      if (res && res.get_dist_depot.distilleries) {
        this.supplierDatas = res.get_dist_depot.distilleries;
      }
      this.distilleryNames = res['get_dist_depot']['distilleries'].map(res => res.supplierCode);
      this.filteredDistilleryNames.next(this.distilleryNames.slice());
      this.brandDataList = res["get_distinct_brands"] ? res["get_distinct_brands"] : [];
      this.brandData = res["get_distinct_brands"] ? res["get_distinct_brands"] : [];
      let BData = this.brandData.map(resp => resp.brandCode);
      let tempData = [];
      BData.forEach(data => {
        if (tempData.findIndex(item => item.brandCode == data) == -1) {
          if (this.brandData)
            tempData.push(this.brandData.find(temp => temp.brandCode == data))
        }

      });
      this.brandData = JSON.parse(JSON.stringify(tempData));
      this.brandRootDatas = JSON.parse(JSON.stringify(tempData));
      this.filteredBrandNames.next(this.brandData.slice());
    });
  }

  setInitialValue() {
    this.filteredDistilleryNames
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleDistillerySelect.compareWith = (a, b) => a && b && a === b;
      });

    this.filteredBrandNames
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleBrandSelect.compareWith = (a, b) => a && b && a.brandCode === b.brandCode;
      });

  }


  selectSize() {
    let items = this.brandDataList.filter(res => res.brandCode == this.labelRegistrationForm.get('brandCode').value.brandCode)
    if (this.labelRegistrationForm.get('supplierCode').value &&
      this.labelRegistrationForm.get('brandCode').value &&
      this.labelRegistrationForm.get('packType').value &&
      this.labelRegistrationForm.get('size').value &&
      this.labelRegistrationForm.get('liquorCode').value) {
      let data = items.filter(res => {
        return res.brandCode == this.labelRegistrationForm.get('brandCode').value.brandCode &&
          res.packType == this.labelRegistrationForm.get('packType').value &&
          res.size == this.sizeListConstant[this.labelRegistrationForm.get('size').value] &&
          res.packType == this.labelRegistrationForm.get('packType').value

      })[0]
      this.selectedSizePrice = data.basicPricing[0]['price'];
    }
    const lqrCode = Object.keys(liquorTypes).find(k => liquorTypes[k].toLowerCase() === this.selectedBrand.find(brand => brand.liquorType).liquorType.toLowerCase());
    const ctrls = this.labelRegistrationForm.controls;
    ctrls.liquorCode.setValue(this.selectedBrand.find(brand => brand.liquorType).liquorType);
    const productCode: any = ctrls.supplierCode.value.split('-')[0]
      + lqrCode + ctrls.brandCode.value.brandCode.split('-')[0]
      + ctrls.packType.value + ctrls.size.value;
    this.labelRegistrationForm.controls.productCode.setValue(productCode);
    if(ctrls.liquorCode.value === 'WINE'){
      this.viewPrice();
    }
    // if(this.labelRegistrationForm.value.liquorCode !== 'BEER'){
    this.checkDuplicate();
    // }
  }

  getCurrencyformat(event:any, type :any) {
    this.labelRegistrationForm.patchValue({
      [type]: this.util.formatCurrency(event)
    })
  }
  

  selectPackType() {
    // let items = this.brandDataList.filter(res => res.brandCode == this.labelRegistrationForm.get('brandCode').value.brandCode)
    // items = items.filter(res => res.packType == this.labelRegistrationForm.get('packType').value)
    this.sizeList = [];
    // items.forEach(res => {
    //   Object.keys(BottleSizes).forEach(k => {
    //     if (BottleSizes[k] === res.size) {
    //       this.sizeList.push(k);
    //     }
    //   });
    // });
    console.table(this.selectedBrand)
    Object.keys(this.unitsPerCaseConstant).forEach(key => {
      this.selectedBrand.forEach(brand => {
        if(brand.size === BottleSizes[key] && brand.unitsPerCase === this.unitsPerCaseConstant[key] && brand.packType === this.labelRegistrationForm.get('packType').value){
          console.log(this.labelRegistrationForm.get('packType').value)
          console.log(brand.packType,brand.unitsPerCase,brand.size)
          this.sizeList.push(key)
        }
      })
    
    })
    this.labelRegistrationForm.patchValue({

      size: '',
      // basicPricing: '',
      // retailPricings: '',
      productCode: ''
    })
    //this.sizeDataList = this.sizeList.map(res => this.sizeListConstant[res]);
  }

  async supplierChange(e): Promise<any> {
    this.filteredBrandNames.next([]);
    this.labelRegistrationForm.controls.brandCode.setValue('');
    const supplierName: any = this.labelRegistrationForm.controls.supplierCode.value;
    const filteredSupplier: any = this.supplierDatas.filter(el => el.supplierCode === supplierName);
    if (filteredSupplier.length) {
      if ((filteredSupplier[0].locationType === 'Breweries' || filteredSupplier[0].locationType === 'Non Local Breweries') && this.brandRootDatas && this.brandRootDatas.length) {
        this.brandData = this.brandRootDatas.filter(el => el.brandType === 'BEER' && el.brandCode);
        this.filteredBrandNames.next(this.brandData.slice());
      } else {
        this.brandData = this.brandRootDatas.filter(el => el.brandType !== 'BEER' && el.brandCode);
        this.filteredBrandNames.next(this.brandData.slice());
      }
    }
  }

  brandChange(e) {
    this.liquorList = [];
    this.selectedBrand = [];
    this.selectedBrand = this.brandDataList.filter(res => res.brandCode === e.value.brandCode)

    this.packTypeList = [];
    this.sizeList = [];
    if (this.selectedBrand.length) {
      this.liquorList.push(this.selectedBrand.find(brand => brand.liquorType).liquorType);
    }
    this.packTypeList = this.selectedBrand.map(res => res.packType);
    let packList = [];
    this.packTypeList.forEach((res, i) => { if (packList.indexOf(res) == -1) packList.push(res) });
    // this.packTypeList = packList;

    this.packTypeList = [];
    packList.forEach(p => {
      if (p && p != '') {
        this.packTypeList.push(p)
      }
    })

    let sizeInfoList = Object.keys(BottleSizes);


    // this.sizeList = this.selectedBrand.map(res => sizeInfoList[this.sizeDataList.indexOf(res.size)]);
    // const sizes = this.sizeDataList.filter(item => item === this.selectedBrand[0].size)

    Object.keys(this.unitsPerCaseConstant).forEach(key => {
      this.selectedBrand.forEach(brand => {
        if(brand.size === BottleSizes[key] && brand.unitsPerCase === this.unitsPerCaseConstant[key]){
          this.sizeList.push(key)
        }
      })
    
    })
    //  this.sizeDataList = this.sizeList.map(res => this.sizeListConstant[res]);
    // console.log(e, this.brandDataList, items, sizeInfoList, this.sizeDataList, this.sizeDataList.indexOf(3000), "164::::");
    this.labelRegistrationForm.patchValue({
      packType: '',
      size: '',
      productCode: ''
    })   

    this.brandData.forEach(res => {
      if (e.value.brandCode === res.brandCode) {
        let supplierCode = this.labelRegistrationForm.get('supplierCode').value.split("-")[0];
        //  let brandItem = this.data[(e['source']['_keyManager']['_activeItemIndex']-1)]
        let brandCode = res.brandCode.split('-')[0];
        this.labelRegistrationForm.patchValue({
          liquorCode: res.liquorCode,

        })
      }
    })
    this.labelRegistrationForm.patchValue({
      packType: this.packTypeList && this.packTypeList.length === 1 ? this.packTypeList[0] : '',
      size: this.sizeList && this.sizeList.length === 1 ? this.sizeList[0] : '',
    });
    if (this.sizeList.length === 1) {
      this.selectSize();
    }
    if (this.packTypeList.length === 1) {
      this.selectPackType();
    }
  }

  // addLabel() {
  //   const req_Data = {
  //     action: 'add_label',
  //     data: this.labelRegistrationForm.value
  //   }
  //   req_Data.data.brandCode = req_Data.data.brandCode.brandCode;
  //   // req_Data.data.size = Number(req_Data.data.size)
  //   this.lrService.createLabel(req_Data).subscribe(res => {
  //     console.log(res, "58:::::::::::::")
  //     this.dialogRef.close("Label registration sucessfully");
  //   })
  // }

  addLabel() {
    this.spinner.show();
    this.loader = true;
    const req_Data = {
      action: 'add_label',
      data: this.labelRegistrationForm.value
    }
    req_Data.data.brandCode = req_Data.data.brandCode.brandCode;  
    // req_Data.data["basicPricing"] = parseFloat((this.labelRegistrationForm.controls.basicPricing.value.substring(1).replace(/,/g, '')));
    if (this.isLabelPresent(req_Data.data) && req_Data.data.brandCode ) {      
      
      this.lrService.createLabel(req_Data).subscribe(res => {
      // console.log(res, "58:::::::::::::")
      this.spinner.hide();
      this.loader = false;
      this.dialogRef.close("Label registration sucessfully");
      }, err => {
        this.spinner.hide();
        this.loader = false;
      });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Label already registered' });
      this.spinner.hide();
      this.loader = false;
    }

  }


  isLabelPresent(createData) {
    let isNewLabel = true;
     this.labelRegistrationForm.controls.brandCode.setValue(createData.brandCode)
    // createData.brandCode = createData.brandCode.brandCode
    
    this.lrService.featuresData['Label Registration'].forEach(data => {
      let supplierCode = data.supplierCode == createData.supplierCode;
      let brandCode = data.brandCode == createData.brandCode
      let liquorCode = data.liquorCode == createData.liquorCode
      let size = data.size == (this.sizeListConstant ? this.sizeListConstant[createData.size] : 0)
      let unitsPerCase = data.unitsPerCase == (this.unitsPerCaseConstant ? this.unitsPerCaseConstant[createData.size] : 0)
      
      if (supplierCode && brandCode && liquorCode) {
        if (size && unitsPerCase && isNewLabel) {
          isNewLabel = false;
        }
      }
    });

    if (isNewLabel) {
      return true;
    } else {
      return false;
    }
  }


  uniqueSizes(r) {
    return r.filter(function (x, i, a) {
      return a.indexOf(x) === i;
    });
  }
  viewPrice(): any {
    const ctrls = this.labelRegistrationForm.controls;
    const pricing = this.selectedBrand.filter(brand => brand.size === BottleSizes[this.labelRegistrationForm.controls.size.value] && brand.unitsPerCase === unitsPerCase[this.labelRegistrationForm.controls.size.value])
    const strength =this.selectedBrand.find(strength => strength.strength);
    ctrls.taxes.setValue('');
    const params: any = {
      supplier_code: ctrls.supplierCode.value,
      brandCode: ctrls.brandCode.value.brandCode,
      liquorType: ctrls.liquorCode.value,
      size: ctrls.size.value,
      strength: strength.strength,
      price: pricing[0].basicPricing[0]['price'],
    };
    if( this.labelRegistrationForm.value.stateLevyRule === 'Foriegn'){
      params.customDuty = pricing[0].pricing[0]['customDuty']
    }
    
    const qData = {
      data: {
        action: this.labelRegistrationForm.value.liquorCode === 'BEER' ? 'beer_pricing' : this.labelRegistrationForm.value.stateLevyRule === 'Foriegn' ?  'fl_pricing' : this.labelRegistrationForm.value.liquorCode === 'WINE' ? 'get_pricing_wine' : this.labelRegistrationForm.value.liquorCode === 'Foriegn Wines' ? 'fl_pricing_wine' : 'get_pricing',
        value: [params]
      }
    };
    // tslint:disable-next-line:variable-name
    const req_Data = {
      action: 'common_apis',
      q: JSON.stringify(qData)
    };
    this.commonsService.getCommonEntites(req_Data).subscribe(res => {
      if (res && res.get_pricing || res.beer_pricing || res.fl_pricing || res.get_pricing_wine || res.fl_pricing_wine) {
        ctrls.taxes.setValue(this.labelRegistrationForm.value.liquorCode === 'BEER' ? res.beer_pricing : this.labelRegistrationForm.value.stateLevyRule === 'Foriegn' ?  res.fl_pricing : this.labelRegistrationForm.value.liquorCode === 'WINE' ? res.get_pricing_wine : this.labelRegistrationForm.value.liquorCode === 'Foriegn Wines' ? res.fl_pricing_wine : res.get_pricing);
        this.showViewPriceDialog(this.labelRegistrationForm.value.liquorCode === 'BEER' ? res.beer_pricing : this.labelRegistrationForm.value.stateLevyRule === 'Foriegn' ?  res.fl_pricing : this.labelRegistrationForm.value.liquorCode === 'WINE' ? res.get_pricing_wine : this.labelRegistrationForm.value.liquorCode === 'Foriegn Wines' ? res.fl_pricing_wine : res.get_pricing);
      }
    });
  }
  changeLevyRule(e:any){
    const selectedLevy = e.value
    if(selectedLevy === 'Foriegn'){
      this.viewPrice();
    }
  }
  showViewPriceDialog(priceDatas): any {
    const datas: any = {
      title: 'View Price',
      datas: priceDatas
    };
    this.dialog.open(ViewPriceLabelRegistrationComponent, {
      width: '50%',
      autoFocus: false,
      data: datas,
      panelClass: 'view-price-dialog'
    });
  }


}
