import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const sampleAnalysisUrl = baseUrl + 'sample_analysis';

const samplesToChemicalUrl = baseUrl + 'sampleanalysis/samplestochemical';
const transportPermitUrl = baseUrl + 'shipments/transportpermits';
const poUrl = baseUrl + 'shipments/purchaseorders';
const stockInUrl = baseUrl + 'shipments/transportpermits';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Sample Analysis'] && userModules.mod_feats['Sample Analysis'].features ? userModules.mod_feats['Sample Analysis'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class SampleAnalysisService {

  constructor(private http: HttpClient) { }

  getSamplesToChemical(offset, limit, search) {
    return this.http.get('assets/samples/samples-to-chemical.json');
  }

  getShipmentsDetails(offset, limit, search) {
    return this.http.get('assets/samples/shipments.json');
  }

  getRouteDetails(offset, limit, search) {
    return this.http.get('assets/samples/route.json');
  }

  getTpVehicleAccidentDetails(offset, limit, search) {
    return this.http.get('assets/samples/tp-vehicle-accident.json');
  }

  getSampleAnalysisFeaturesData() {
    const headerDict = {
      feature
    };
    return this.http.get(sampleAnalysisUrl, { headers: headerDict });
  }

  createSamplesDetails(obj: any){
    return this.http.post(sampleAnalysisUrl, obj);
  }
}
