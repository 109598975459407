import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { Product } from 'src/app/core/models/production/product.model';
import { StockService } from 'src/app/core/services/stock.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { ProductionService } from '../../../../core/services/production.service';
import { CommonsService } from 'src/app/core/services/commons.service';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-manage-hold-on-stock-dialog',
  templateUrl: './manage-hold-on-stock-dialog.component.html',
  styleUrls: ['./manage-hold-on-stock-dialog.component.scss'],
})
export class ManageHoldOnStockDialogComponent implements OnInit {
  holdOnStockForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  products: Product[];
  saveDisabled: boolean;
  // products: Product[] = [];
  roleName: any = '';
  disabled: any = false;
  public productFilterCtrl: FormControl = new FormControl();
  filteredProducts: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ManageHoldOnStockDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private productionService: ProductionService,
    private stockService: StockService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    public util: UtilService,
    private commonService: CommonsService
  ) {}

  ngOnInit(): void {
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    // this.getBrandCodes();
    this.getProducts();
    console.log('this.data', this.data);
    this.holdOnStockForm = this.fb.group({
      productCode: ['', [Validators.required]],
      // batchNo: ['', [Validators.required]],
      cases: [],
      bottles: [],
      reason: ['', [Validators.required]],
      HosNo: [''],
      location: [''],
    });
    if (this.data) {
      this.holdOnStockForm.patchValue({
        productCode: this.data.productCode,
        // batchNo: this.data.batchNo,
        cases: this.data.cases,
        bottles: this.data.bottles,
        reason: this.data.reason,
        HosNo: this.data.HosNo ? this.data.HosNo : '',
        location: this.data.location ? this.data.location : ''
      });
      // this.holdOnStockForm.disable();
    }
    this.holdOnStockForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    })
    this.util.matSelectFilter(this, "productFilterCtrl", "filteredProducts", "products");
  }

  getProducts(): any {
    let qData = {
      "data": ["get_depot_stock"]
    }
    const req_Data = {
      "action": "common_apis",
      "q": JSON.stringify(qData)
    };

    this.commonService.getCommonEntites(req_Data).subscribe((res) => { 
      this.products = res['get_depot_stock'];
      this.filteredProducts.next(this.products);
    })
  }
  getFilterByProduct(): any {
    const form: any = this.holdOnStockForm.controls;
    const product: any = this.products.find(el => el.productCode === form.productCode.value);
    if (product) {
      this.holdOnStockForm.patchValue({
        cases: product.closingBalCases,
        bottles: product.closingBalBottles
      });
    }
    this.checkCasesBottlesValidation();
  }
  checkCasesBottlesValidation(): any {
    const form: any = this.holdOnStockForm.controls;
    const product: any = this.products.find(el => el.productCode === form.productCode.value);
    if (product && (form.cases.value > product.closingBalCases)) {
      this.messageService.add({ severity: 'error', detail: 'Cases count should not be more than closing balance cases.' });
      this.disabled = true;
    } else if (product && (form.bottles.value > product.closingBalBottles)) {
      this.messageService.add({ severity: 'error', detail: 'Bottles count should not be more than closing balance bottles.' });
      this.disabled = true;
    }
     else {
      this.disabled = false;
    }
  }


  // getProducts() {
  //   this.productionService.getProduct(0,100).subscribe(res => {
  //     this.products = res.data;
  //   })
  // }

  get f() {
    return this.holdOnStockForm.controls;
  }

  // getBrandCodes() {
  //   this.productionService.getProduct(1, 100).subscribe((res) => {
  //     this.products.push(...res.data);
  //   });
  // }

  // selectProduct(e) {

  // }

  createHoldOnStock() {
    this.saveDisabled = true;
    const req_Data = {
      // update_stock_on_hold
      action : 'add_hold_on_stock',
      data : this.holdOnStockForm.value
    }
    // if (req_Data.data.productCode) {
    //   const unitsPerCase = this.util.productCodeToDetails(req_Data.data.productCode).unitsPerCase;
    //   if (req_Data.data.productCode && req_Data.data.bottles >= unitsPerCase) {
    //     const cases: any = Math.floor(req_Data.data.bottles / unitsPerCase);
    //     req_Data.data.cases = req_Data.data.cases + cases;
    //     req_Data.data.bottles = req_Data.data.bottles - (cases * unitsPerCase);
    //   }
    // }
    // const form: any = this.holdOnStockForm.controls;
    // const product: any = this.products.find(el => el.productCode === form.productCode.value);
    // if (product && (req_Data.data.cases > product.closingBalCases)) {
    //   this.messageService.add({ severity: 'error', detail: 'Please check the cases. Should not more than closing balance cases' });
    //   this.disabled = true;
    //   return;
    // } else {
    //   this.disabled = false;
    // }
    console.log(':::9999::::', req_Data);
    console.log(req_Data);
    this.spinner.show();
    this.stockService.createHoldOnStock1(req_Data).subscribe((res: any) => {
      if (res && res.status && res.status === 'SUCCESS') {
        this.dialogRef.close('Stock On Hold Created Successfully');
      } else {
        this.messageService.add({ severity: 'error', detail: 'Not Created' }); 
      }
      this.saveDisabled = false;
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      this.saveDisabled = false;
      this.error = err.error.message;
    });
  }

  updateStatus(mode = '') {
    this.saveDisabled = true; 
    const req_Data = {
      action : 'update_stock_on_hold',
      data : this.holdOnStockForm.value
    }
    // const status: any = mode === 'forward' ? 'InProgress' : 'Approved';
    //  ? req_Data.data.location = 'DrainOut' ? (mode === 'release' ? req_Data.data.location = 'Release'): ''; 
    req_Data.data.location = mode === 'drainout' ? 'DrainOut' : (mode === 'release' ? 'Release' : req_Data.data.location);
    req_Data.data.status = mode === 'forward' ? 'InProgress' : mode === 'approve' ? 'Approved' : (mode === 'release' ? 'Release' : (mode === 'drainout' ? 'DrainOut' : ''));  
    // req_Data.data.status = status;
    // delete req_Data.data.bottles;
    // delete req_Data.data.cases;
    // delete req_Data.data.reason;
    this.spinner.show();
    this.stockService.putHoldOnStock(req_Data).subscribe((res: any) => {
      if (res && res.status && res.status === 'SUCCESS') {
        const msg: any = mode === 'forward' ? 'Forward to APSBCLMM Successfully' : 'Status Updated Successfully';
        this.dialogRef.close(msg);
      } else {
        this.messageService.add({ severity: 'error', detail: 'Not Forwarded' }); 
      }
      this.saveDisabled = false;
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      this.saveDisabled = false;
      this.error = err.error.message;
    });
    // this.saveDisabled = true;
    // const obj = [
    //   {
    //     update: this.holdOnStockForm.value,
    //   },
    // ];
    // this.stockService.putHoldOnStock(obj).subscribe((res: any) => {
    //     this.dialogRef.close('Stock On Hold Updated Successfully');
    //   },
    //   (err) => {
    //     this.error = err.error.message;
    //   }
    // );
  }

  // getTitle() {
  //   return this.data && this.data._id
  //     ? `Update Hold On Stock for ${this.data.product.productName}`
  //     : 'Create Hold On Stock';
  // }
}
