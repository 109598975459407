import { TemplateRef, ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormArray } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Depot } from 'src/app/core/models/depot-info/depot.model';
import { Get_Retailer } from 'src/app/core/models/retailers/retailers.model';
import { PurchaseOrder } from 'src/app/core/models/shipments/purchase-order.model';
import { Product } from 'src/app/core/models/stock/hold-on-stock.model';
import { CommonsService } from 'src/app/core/services/commons.service';
import { DepotInfoService } from 'src/app/core/services/depot-info.service';
import { IndentManagementService } from 'src/app/core/services/indent-management.service';
import { InputValidator } from 'src/app/core/utils/input.validator';
import { MessageService } from 'primeng/api';
import { DatePipe } from "@angular/common";
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  templateUrl: './manage-vendor-indents-dialog.component.html',
  styleUrls: ['./manage-vendor-indents-dialog.component.scss']
})
export class ManageVendorIndentsDialogComponent implements OnInit {
  compositionAddFields: any = [];
  spiritData: any = [];
  unitsData: any = [];
  vendorIndentsForm: FormGroup;
  error: string;
  page: number;
  selectedTabIndex: any = 0;
  limit: number;
  purchaseOrders: PurchaseOrder[];
  saveDisabled: boolean;
  requestedQuantities: FormArray;
  products: Product[];
  indents: any;
  depots: Depot;
  vendors: any[];
  vendorDetails: any;
  public filteredProducts: ReplaySubject<any> = new ReplaySubject<any>(1);
  brandfilteredProducts: any = [];
  public productFitlerCtrl: FormControl = new FormControl();
  public filteredVendors: ReplaySubject<any> = new ReplaySubject<any>(1);
  public vendorFitlerCtrl: FormControl = new FormControl();
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public _onDestroy = new Subject<void>();
  displayedColumns: string[] = ['no', 'productCode', 'bottles', 'cases', 'quantity', 'rationQuantity'];
  @ViewChild('casesIndentForm') casesIndentForm;
  @ViewChild('bottlesIndentForm') bottlesIndentForm;
  @ViewChild('productSelect') productSelect: MatSelect;
  @ViewChild('vendorSelect') vendorSelect: MatSelect;
  @ViewChild('dialogRefTemplate') dialogRefTemplate: TemplateRef<any>;

  productDataList: any = [];
  quantity = [];
  userInfo: any;
  disableCreate: boolean = true;
  productList: any;
  brandList: any;
  dialogRef1: MatDialogRef<any, any>;
  tabIndex: number = 0;
  sizeList: any = [];
  isValidate: any = false;
  totalIssuePrice:any;
  totalSplMargin:any;
  totalAedPrice:any;
  totalVatPrice:any;
  totalRateOfMp:any;
  totalExciseDuty:any;
  totalAssesstFee:any;
  totalPdPrice:any;
  totalAreTax:any;
  totalPrevFee:any;
  totalTcsFee:any;
  totalIndentValue:any;
  productData:any;
  selectedProduct:any;
  emailId:any;
  mobileNumber:any;
  taxes:any=[];
  fieldError:any=[];
  now: any = Date.now();
  currentDateTime:any;
  rationApplicable:any = false;
  productsList:any=[];
  productListData:any=[];
  loader: any = false;
  checkIndent: any = false;
  checkVendorData: any;
  brandLoader: any = false;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageVendorIndentsDialogComponent>, private alertDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data, private depotService: DepotInfoService,
    private indentManagementService: IndentManagementService,
    private messageService:MessageService,
    private commonService: CommonsService, private spinner: NgxSpinnerService,
    private dialog: MatDialog,private datePipe: DatePipe,
    public util:UtilService,) {
    this.userInfo = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
  }

  ngOnInit(): void {
    this.getProducts();
    this.currentDateTime = this.datePipe.transform(this.now, 'MM-dd-yyyy h:mm:ss')
    this.vendorIndentsForm = this.fb.group({
      product: ['', Validators.required],
      destnEntityId: ['', Validators.required],
      totalIssuePrice:[''],
      totalSplMargin:[''],
      totalAedPrice:[''],
      totalVatPrice:[''],
      totalRateOfMp:[''],
      totalExciseDuty:[''],
      totalAssesstFee:[''],
      totalPdPrice:[''],
      totalAreTax:[''],
      totalPrevFee:[''],
      totalTcsFee:[''],
      totalIndentValue:[''],
      depotName:[''],
      date:[new Date()],
      emailId:[''],
      mobileNumber:[''],
      bankDetails:[''],
      challanAmt:[''],
      prevBal:[''],
      totalAmt:[''],
      closingCreditBal:[''],
      totalCases:[''],
      // action: ['create_vendor_indent', Validators.required],
    });
    this.requestedQuantities = this.vendorIndentsForm.get('requestedQuantities') as FormArray;

    this.vendorIndentsForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });

    this.productFitlerCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProducts();
      });

    this.vendorFitlerCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterVendors();
      });
    if (this.data.casesIndent === false) {
      this.selectedTabIndex = 1;
      this.tabIndex = 1;
    }
  }

  switchTab(tab) {

  }

  onTabChanged(event) {
    this.resetForm();
    this.tabIndex = event.index;
    this.setVendorDetails();
    this.filteredProducts.next([]);
    this.brandfilteredProducts = [];
    this.productListData = [];
    if (this.vendorDetails.assignedDepot) {
      this.selectVendor(this.vendorDetails.assignedDepot);
    }
  }

  dataFill(data) {
    let flag = false;
    Object.keys(data).forEach(val => {
      if (!flag && this.data.featureName === 'Manual Indents') {
        flag = !data[val];
      } else {
        flag = !data[val] && val !== 'rationQuantity' && val !== 'usedRation' &&  val !== 'remainingQuantity';
      }

    })
    return flag;
  }

  validateQty(item) {

    this.isValidate = item;
    return item
  }

  addComposition() {
    this.checkIndent=false;
    this.compositionAddFields.push({ productCode: '', brandName: '', size: '', depotStock: '', rationQuantity: '', requestedQuantity: '', mrp: '', totalValue: '' })
    const index = this.compositionAddFields.length - 1;
    this.brandfilteredProducts[index] = new ReplaySubject<any>(1);
    this.brandfilteredProducts[index].next(this.brandList);
  }


  deleteComposition(i) {
    this.compositionAddFields.splice(i, 1);
    this.updateTotal();
  }


  productChange(event, type) {
    let qData = {
      "data": { "value": event.value, "action": "get_depot_stock_prod" }
    };
    const req_Data = {
      "action": "common_apis",
      "q": JSON.stringify(qData)
    };

    this.commonService.getCommonEntites(req_Data).subscribe((res: Get_Retailer) => {
      this.productDataList.push(res['get_depot_stock_prod']);
      this.dataSource = new MatTableDataSource(this.productDataList);
    });
  }




  quantityChange(i, e) {
    this.quantity[i] = e.target.value;
    this.productDataList[i]['quantity'] = this.quantity[i];
    delete this.productDataList[i]['isEdit']
  }

  quantityCheck(i) {
    if (this.quantity[i] > 0) {
      if (this.productDataList[i]['ration']) {
        this.disableCreate = this.productDataList[i]['ration'] < this.quantity[i];
        return this.productDataList[i]['ration'] < this.quantity[i];
      }
      else {
        if (this.tabIndex === 1) {
          this.disableCreate = this.productDataList[i]['bottles'] < this.quantity[i];
          return this.productDataList[i]['bottles'] < this.quantity[i];
        } else {
          this.disableCreate = this.productDataList[i]['cases'] < this.quantity[i];
          return this.productDataList[i]['cases'] < this.quantity[i];
        }
      }
    } else {
      this.disableCreate = true;
    }

  }

  filterProducts() {
    if (!this.brandList || (this.brandList && this.brandList.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.productFitlerCtrl.value;
    if (!search) {
      this.filteredProducts.next(this.brandList);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredProducts.next(
      this.brandList.filter(brand => JSON.stringify(brand).toLowerCase().indexOf(search) > -1)
    );
  }
  
  filterBrandProducts(i) {
    if (!this.brandList || (this.brandList && this.brandList.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.productFitlerCtrl.value;
    if (!search) {
      this.brandfilteredProducts[i].next(this.brandList);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.brandfilteredProducts[i].next(
      this.brandList.filter(brand => JSON.stringify(brand).toLowerCase().indexOf(search) > -1)
    );
  }

  filterVendors() {
    if (!this.vendors || (this.vendors && this.vendors.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.vendorFitlerCtrl.value;
    if (!search) {
      this.filteredVendors.next(this.vendors);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredVendors.next(
      this.vendors.filter(retailer => JSON.stringify(retailer.depotCode).toLowerCase().indexOf(search) > -1)
    );
  }

  setVendorDetails(): any {
    this.vendorIndentsForm.patchValue({
      depotName:this.vendorDetails.assignedDepot,
      emailId: this.vendorDetails.address.emailIds[0].mailId,
      mobileNumber: this.vendorDetails.address.phoneNumbers[0].number,
      bankDetails: this.vendorDetails.bankDetails[0].bankName,
      prevBal: this.vendorDetails.closingBalAmount,
      totalAmt: this.vendorDetails.closingBalAmount
    });
  }

  getProducts() {
    this.spinner.show();
    const productInfoPayload = {
      "action": "common_apis",
      "q": '{"data": ["get_prod_info"]}'
    }
    const req_Data = {
      "action": "common_apis",
      "q": JSON.stringify({
        "data": { "value": this.userInfo.entity_id, "action": "get_vendor_details" }
      })
    }
    this.commonService.getCommonEntites(req_Data).subscribe((res: Get_Retailer) => {
        this.vendorDetails = res['get_vendor_details'][0];
        this.commonService.getCommonEntites(productInfoPayload).subscribe((resp: Get_Retailer) => {
          if(this.vendorDetails){
            this.selectVendor(this.vendorDetails.assignedDepot);
            this.setVendorDetails();
          }
          

          this.productData = resp['get_prod_info'];
          if (this.vendors) {
            this.vendors = this.vendors.filter((res, i) => {
              return i == this.vendors.lastIndexOf(res);
            });
            this.filterVendors();
          } else {
            this.vendors = [];
          }
     
        this.spinner.hide();
      });
    });
    
  }
  selectVendor(depotCode:any){
    let req_Data: any = {};
    this.brandList = [];
    this.products = [];
    if (this.tabIndex === 0) {
      req_Data = {
        "action": "common_apis",
        "q": '{"data": {"action": "get_depot_stock_vendor", "value":"' +depotCode+'"}}'
      };
    } else {
      req_Data = {
        "action": "common_apis",
        "q": '{"data": {"action": "get_depot_stock_bottle", "value":"' +depotCode+'"}}'
      };
    }
    this.brandLoader = true;
    this.commonService.getCommonEntites(req_Data).subscribe((res) => {
      if (this.tabIndex === 0) {
        this.productList = res['get_depot_stock_vendor'];
        if (res['get_depot_stock_vendor'] && res['get_depot_stock_vendor'].length) {
          this.brandList = res['get_depot_stock_vendor'].map(res => res.brandCode);
          this.products = res['get_depot_stock_vendor'].map(res => res.productCode); 
        }
      } else {
        this.productList = res['get_depot_stock_bottle'];
        if (res['get_depot_stock_bottle'] && res['get_depot_stock_bottle'].length) {
          this.brandList = res['get_depot_stock_bottle'].map(res => res.brandCode);
          this.products = res['get_depot_stock_bottle'].map(res => res.productCode);
        }
      }
      if (this.brandList && this.brandList.length) {
        this.products = this.products.filter((res, i) => {
          return i == this.products.lastIndexOf(res);
        });
        this.brandList = this.brandList.filter((res, i) => {
          return i == this.brandList.lastIndexOf(res);
        });
        this.filteredProducts.next(this.brandList);
        this.brandList.forEach(res => {
          if (this.brandList.indexOf(res.brandName) !== -1 && this.sizeList.indexOf(res.size) == -1) {
  
            this.sizeList.push(res.size);
          }
  
        })
      }
      this.brandLoader = false;
    }, err => {
      this.brandLoader = false;
    });
  }
  getDepots() {
    this.depotService.getDepotInfo(true).subscribe(res => {
      this.depots = res.data;
    });
  }
  getVendors() {
    const req_Data = {
      "action": "common_apis",
      "q": "{'data': ['get_products']}"
    };
    this.commonService.getCommonEntites(req_Data).subscribe((res: Get_Retailer) => {
      this.vendors = res.data;
    });
  }
  createProduct() {
    return this.fb.group({
      product: ['', [Validators.required, InputValidator.cannotStartWithSpace]],
      // quantity: ['', [Validators.required, InputValidator.cannotStartWithSpace]],
    });
  }

  get f() {
    return this.vendorIndentsForm.controls;
  }

  createIndent() {
    this.saveDisabled = true;
    let data: any = {};
    data['depotCode'] = this.vendorIndentsForm.value.depotName;
    data['productDetails'] = [];
    data['taxes']=this.taxes;
    data.previousBalance = this.vendorIndentsForm.value.prevBal;

    if (this.tabIndex == 1) {
      data.indentType = 'bottles_indent';
      data['productDetails'] = this.compositionAddFields.map(prod => {
        let product: any = { "brandCode" : prod.brandCode,"productCode": prod.productCode, "brandName": prod.brandName, "requestedBottles": parseInt(prod.requestedQuantity), "mrp": prod.mrp, "value": prod.totalValue,"issuePrice":prod.issuePrice,"casesCount":0,"casesCountNumber":0  };
        if(this.taxes[0].totalIndentValue <= this.vendorIndentsForm.value.prevBal){
          product.approvedBottles = parseInt(prod.requestedQuantity);
        }
        return product;
      })
    } else {
      data.indentType = 'cases_indent';
      data['productDetails'] = this.compositionAddFields.map(prod => {
        let product:any = {"brandCode" : prod.brandCode, "productCode": prod.productCode, "brandName": prod.brandName, "requestedCases": parseInt(prod.requestedQuantity), "mrp": prod.mrp, "value": prod.totalValue ,"issuePrice":prod.issuePrice,"casesCount":0,"casesCountNumber":0 };
        if(this.taxes[0].totalIndentValue <= this.vendorIndentsForm.value.prevBal){
          product.approvedCases = parseInt(prod.requestedQuantity);
        }
        return product;
      })
    };
    const req_Data = {
      action: this.tabIndex === 0 ? 'create_vendor_indent' : 'create_vendor_parts_indent',
      data
    }
    if (this.data.featureName === 'Manual Indents') {
      req_Data.action = this.tabIndex === 0 ? 'create_manual_indent' : 'create_manual_parts_indent';
    }
    console.log(req_Data)
    this.loader = true;
    this.indentManagementService.createRetailerIndent(req_Data).subscribe((res: any) => {
      this.dialogRef.close(`${this.data.featureName} Created Successfully`);
      this.loader =false;
    }, (err) => {
      this.loader =false;
      this.error = err.error.message;
    });
  }

  updateIndent() {
  }


  checkApproval() {
    let data: any = {};
    console.log(this.compositionAddFields)
    this.compositionAddFields.map(prod => {
      prod.requestedCases = prod.requestedQuantity
    })
    data['productDetails'] = this.compositionAddFields

    data = {
      indentVerfiy: 'indentValue',
      productDetails: data.productDetails,
    };
    const reqData = {
      action: "check_vendor_indent",
      data: data,
    };
    console.log(reqData)
    let totalIndentValue = 0;
    let productsIndentValue = 0;
    this.spinner.show();
    this.indentManagementService.approveRejectVendorIndentStatus(reqData).subscribe((res: any) => {
        if (res && res["check_vendor_indent"]) {
          this.checkVendorData = res["check_vendor_indent"];
          this.checkVendorData.productDetails.forEach((item: any) => {
            totalIndentValue += item.totalIndentValue;
            productsIndentValue += item.totalValue;
            this.checkIndent = true;
            // if (totalIndentValue === productsIndentValue || totalIndentValue > productsIndentValue) {
            //   this.checkIndent = true;
            //   console.log("True",this.checkIndent)
            //   // this.loader = false;
            // } else {
            //   this.checkIndent = false;
            //   console.log("Else",this.checkIndent)
            //   // this.loader = false;
            //   this.messageService.add({
            //     severity: "error",
            //     summary: "Error",
            //     detail: "Indent amount is not matching reduce the cases",
            //   });
            // }
          });
          this.spinner.hide();
         
        }
      });
  }
  selectBrand(field, i) {
    this.compositionAddFields[i]['size'] = '';
    this.compositionAddFields[i]['productCode'] = '';
    this.compositionAddFields[i]['mrp'] = '';
    this.compositionAddFields[i]['issuePrice'] = '';
    this.compositionAddFields[i]['closingBalCases'] = '';
    this.compositionAddFields[i]['depotStock'] = '';
    this.compositionAddFields[i]['rationQuantity'] = ''; 
    this.compositionAddFields[i]['usedRation'] = '';
    this.compositionAddFields[i]['remainingQuantity'] = '';
    this.compositionAddFields[i]['totalValue'] = '';
    this.compositionAddFields[i]['requestedQuantity'] = '';
    this.fieldError[i] = {};
    this.productListData[i] = [];
    this.productList.forEach(res => {
      if (res.brandCode == field.brandName) {
        console.log(res)
        this.productListData[i].push(res.productCode);
        // this.compositionAddFields[i]['size'] = '';
        // this.compositionAddFields[i]['productCode'] = '';
        // this.compositionAddFields[i]['mrp'] = '';
        // this.compositionAddFields[i]['issuePrice'] = '';
        
        // // this.compositionAddFields[i]['unitsPerCase'] = '';
        // // this.compositionAddFields[i]['brandName'] = '';
        // // this.compositionAddFields[i]['brandCode'] = '';
        // this.compositionAddFields[i]['closingBalCases'] = '';
        // this.compositionAddFields[i]['depotStock'] = '';
        // this.compositionAddFields[i]['rationQuantity'] = ''; 
        // this.compositionAddFields[i]['usedRation'] = '';
        // this.compositionAddFields[i]['remainingQuantity'] = '';
        // this.compositionAddFields[i]['totalValue'] = '';
        // this.compositionAddFields[i]['requestedQuantity'] = '';
        // this.fieldError[i] = {};
        // this.compositionAddFields[i]['size'] = res.size;
        // // this.compositionAddFields[i]['productCode'] = res.productCode;
        // this.compositionAddFields[i]['mrp'] = res['retailPricings'];
        // this.compositionAddFields[i]['issuePrice'] = res.issuePrice;
        
        // this.compositionAddFields[i]['unitsPerCase'] = res.unitsPerCase;
        // this.compositionAddFields[i]['brandName'] = res.brandCode;
        // this.compositionAddFields[i]['brandCode'] = res.brandCode;
        // if (this.tabIndex === 1) {
        //   this.compositionAddFields[i]['depotStock'] = res.closingBalBottles;
        // }
        // else {
        //   this.compositionAddFields[i]['depotStock'] = res.onlineClosingBalCases;
        // }
        // this.compositionAddFields[i]['rationQuantity'] = res.ration ? res.ration :0;  
        
        // if(res.rationing && res.rationing.length){
        //   res.rationing.forEach((data:any)=>{
        //     if(this.userInfo.entity_id === data.name){
        //       const rationFrom = this.datePipe.transform(data.applicableFrom, 'MM-dd-yyyy h:mm:ss');
        //       const rationTo = this.datePipe.transform(data.applicableTo, 'MM-dd-yyyy h:mm:ss');
        //       if(this.currentDateTime > rationFrom && this.currentDateTime < rationTo){
               
        //         this.rationApplicable = true;
        //         this.compositionAddFields[i]['usedRation'] = data.usedRation ? data.usedRation : 0;
        //         this.compositionAddFields[i]['remainingQuantity'] = data.ration - data.usedRation
        //         console.log("Between")

        //       }
        //       else{
        //         this.compositionAddFields[i]['rationQuantity'] = 0;
        //         this.compositionAddFields[i]['usedRation'] =  0;
        //         this.compositionAddFields[i]['remainingQuantity'] =  0;
        //         console.log("Not Between")
        //       }
              
        //     }
        //   })
        // }else{
        //   this.compositionAddFields[i]['usedRation'] = 0;
        //   this.compositionAddFields[i]['remainingQuantity'] = 0;
        // }   

      }
    })
    
  }
  selectProduct(field, i){
    this.productList.forEach(res => {
      if (res.productCode == field.productCode) {
        console.log(res)
        // this.productsList.push(res.productCode);

        this.compositionAddFields[i]['size'] = res.size;
        // this.compositionAddFields[i]['productCode'] = res.productCode;
        this.compositionAddFields[i]['mrp'] = res['retailPricings'];
        this.compositionAddFields[i]['issuePrice'] = res.issuePrice;
        
        this.compositionAddFields[i]['unitsPerCase'] = res.unitsPerCase;
        this.compositionAddFields[i]['brandName'] = res.brandCode;
        this.compositionAddFields[i]['brandCode'] = res.brandCode;
        if (this.data.featureName === 'Manual Indents') {
          this.compositionAddFields[i]['closingBalCases'] = res.ctelClosingBalCases ? res.ctelClosingBalCases : 0;
        } else {
          this.compositionAddFields[i]['closingBalCases'] = res.closingBalCases;
        }
        
        if (this.tabIndex === 1) {
          if (this.data.featureName === 'Manual Indents') {
            this.compositionAddFields[i]['depotStock'] = res.ctelOnlineClosingBottles ? res.ctelOnlineClosingBottles : 0;
          } else {
            this.compositionAddFields[i]['depotStock'] = res.onlineClosingBalBottles ? res.onlineClosingBalBottles : res.closingBalBottles;
          }
        }
        else {
          if (this.data.featureName === 'Manual Indents') {
            this.compositionAddFields[i]['depotStock'] = res.ctelOnlineClosingCases ? res.ctelOnlineClosingCases : 0;
          } else {
            this.compositionAddFields[i]['depotStock'] = res.onlineClosingBalCases;
          }
        }
        this.compositionAddFields[i]['rationQuantity'] = res.ration ? res.ration :0;  
        
        if(res.rationing && res.rationing.length){
          res.rationing.forEach((data:any)=>{
            if(this.userInfo.entity_id === data.name){
              // const rationFrom = this.datePipe.transform(data.applicableFrom, 'MM-dd-yyyy h:mm:ss');
              // const rationTo = this.datePipe.transform(data.applicableTo, 'MM-dd-yyyy h:mm:ss');
              const rationFrom = data.applicableFrom;
              const rationTo = data.applicableTo;
              const todayDateTime: any = (new Date()).toISOString();
              if(todayDateTime > rationFrom && todayDateTime < rationTo){
               
                this.rationApplicable = true;
                this.compositionAddFields[i]['usedRation'] = data.usedRation ? data.usedRation : 0;
                this.compositionAddFields[i]['remainingQuantity'] = data.ration - data.usedRation
                console.log("Between")

              }
              else{
                this.compositionAddFields[i]['rationQuantity'] = 0;
                this.compositionAddFields[i]['usedRation'] =  0;
                this.compositionAddFields[i]['remainingQuantity'] =  0;
                console.log("Not Between")
              }
              
            }
          })
        }else{
          this.compositionAddFields[i]['usedRation'] = 0;
          this.compositionAddFields[i]['remainingQuantity'] = 0;
        }   

      }
    })
  }

  getFilteredProductData(field){
    return this.productData.find((item:any)=>item.productCode == field.productCode);
  }

  resetFormTotalFields(){
    this.vendorIndentsForm.controls.totalIssuePrice.setValue(0);
    this.vendorIndentsForm.controls.totalSplMargin.setValue(0);
    this.vendorIndentsForm.controls.totalAedPrice.setValue(0);
    this.vendorIndentsForm.controls.totalVatPrice.setValue(0);
    this.vendorIndentsForm.controls.totalRateOfMp.setValue(0);
    this.vendorIndentsForm.controls.totalExciseDuty.setValue(0);
    this.vendorIndentsForm.controls.totalTcsFee.setValue(0);
    this.vendorIndentsForm.controls.totalPdPrice.setValue(0);
    this.vendorIndentsForm.controls.totalAreTax.setValue(0);
    this.vendorIndentsForm.controls.totalIndentValue.setValue(0);
    this.vendorIndentsForm.controls.totalPrevFee.setValue(0);
    this.vendorIndentsForm.controls.totalAssesstFee.setValue(0);
    this.vendorIndentsForm.controls.totalCases.setValue(0);
    this.vendorIndentsForm.controls.closingCreditBal.setValue(0);
  }


  updateTotal(){
    const ctrls = this.vendorIndentsForm.controls;
    this.resetFormTotalFields();
    if (this.tabIndex === 0) {
      if(this.compositionAddFields.length){
        this.compositionAddFields.map((data:any)=>{
          const tax = this.getFilteredProductData(data)['taxes'][0];
          tax.unitsPerCase = tax.unitsPerCase ? tax.unitsPerCase : this.getFilteredProductData(data).unitsPerCase;
          const totalAreTax = (((tax['Additional Retail Excise Tax (ARET 1)'] + tax['Additional Retail Excise Tax (ARET 2)'] + tax['Rationalization Of Rates 1'] + tax['Rationalization Of Rates 2']) + ((0.1* tax['finalIssuePrice'] / tax['unitsPerCase']))));
          //const totalTcsFee = ((tax['ISSUE_PRICE_ROUNDED'] + tax['ADD.PRIV.FEE PER CASE'] + (totalAreTax * tax['unitsPerCase'])) * 0.01);
          ctrls.totalIssuePrice.setValue(+(this.vendorIndentsForm.value.totalIssuePrice += data.requestedQuantity * (tax['finalIssuePrice'] ? tax['finalIssuePrice'] : 0)).toFixed(2));
          ctrls.totalSplMargin.setValue(+(this.vendorIndentsForm.value.totalSplMargin += data.requestedQuantity * (tax['caseWiseSpecialMargin'] ? tax['caseWiseSpecialMargin'] : 0)).toFixed(2));
          ctrls.totalAedPrice.setValue(+(this.vendorIndentsForm.value.totalAedPrice += data.requestedQuantity * (tax['Additional Excise duty'])).toFixed(2))
          ctrls.totalVatPrice.setValue(+(this.vendorIndentsForm.value.totalVatPrice += data.requestedQuantity * (tax['VAT'])).toFixed(2));
          ctrls.totalRateOfMp.setValue(+(this.vendorIndentsForm.value.totalRateOfMp += data.requestedQuantity * (tax['SPECIAL MARGIN'])).toFixed(2));
          ctrls.totalExciseDuty.setValue(+(this.vendorIndentsForm.value.totalExciseDuty += data.requestedQuantity * (tax['EXCISE DUTY'])).toFixed(2));
          //ctrls.totalTcsFee.setValue(Math.ceil(this.vendorIndentsForm.value.totalTcsFee += data.requestedQuantity * totalTcsFee));
          ctrls.totalAssesstFee.setValue(+(this.vendorIndentsForm.value.totalAssesstFee += data.requestedQuantity * (tax['assementfee'] ? tax['assementfee'] :0).toFixed(2)));
          ctrls.totalAreTax.setValue(Math.ceil(this.vendorIndentsForm.value.totalAreTax += data.requestedQuantity * (Math.ceil(totalAreTax) * tax['unitsPerCase']) ));
          ctrls.totalCases.setValue(+(this.vendorIndentsForm.value.totalCases += data.requestedQuantity ));
        })
        const formValue = this.vendorIndentsForm.value;
        if(this.userInfo.retailerType === '2C' || this.userInfo.retailerType === 'EP'){
          ctrls.totalTcsFee.setValue(0);
          ctrls.totalPrevFee.setValue(Math.ceil((formValue.totalIssuePrice + formValue.totalSplMargin + formValue.totalAreTax) * 0.204 ));
          ctrls.totalIndentValue.setValue((formValue.totalIssuePrice + formValue.totalSplMargin + formValue.totalAreTax + this.vendorIndentsForm.value.totalPrevFee).toFixed(2));  

        }else if(this.userInfo.retailerType === '2TD'){
          ctrls.totalTcsFee.setValue(0);
          ctrls.totalPrevFee.setValue(0);
          ctrls.totalIndentValue.setValue(formValue.totalIssuePrice + formValue.totalSplMargin + formValue.totalAreTax);  

        }
        else{
          ctrls.totalTcsFee.setValue(Math.ceil((formValue.totalIssuePrice + formValue.totalSplMargin + formValue.totalAreTax) * 0.01));
          ctrls.totalIndentValue.setValue((formValue.totalIssuePrice + formValue.totalSplMargin + formValue.totalAreTax + this.vendorIndentsForm.value.totalTcsFee).toFixed(2));  

        }
        ctrls.totalPdPrice.setValue(Math.ceil(this.vendorIndentsForm.value.totalIndentValue - (formValue.totalAedPrice + formValue.totalRateOfMp + formValue.totalVatPrice + formValue.totalExciseDuty + formValue.totalAreTax + formValue.totalAssesstFee)));
        ctrls.closingCreditBal.setValue(+(this.vendorIndentsForm.value.totalAmt - this.vendorIndentsForm.value.totalIndentValue ));
        this.taxes = [{
          'actualIssuePrice': ctrls.totalIssuePrice.value,
          'additionalExciseDuty': ctrls.totalAedPrice.value,
          'exciseDuty': ctrls.totalExciseDuty.value,
          'totalRateOfSmP': ctrls.totalRateOfMp.value,
          'totalSpeacialMargin':ctrls.totalSplMargin.value,
          'vat': ctrls.totalVatPrice.value,
          'totalAssestment':0,
          'afee_amount':ctrls.totalAssesstFee.value ? ctrls.totalAssesstFee.value : 0,
          'pdPrice':ctrls.totalPdPrice.value,
          'totalAreTax':ctrls.totalAreTax.value,
          'totalSpeciallMarginPrevFee':ctrls.totalPrevFee.value,
          'totalTcs':ctrls.totalTcsFee.value,
          'totalIndentValue':ctrls.totalIndentValue.value
        }]
        this.taxes[0].pdPrice = ctrls.totalPdPrice.value;  
        this.taxes[0].totalTcs = ctrls.totalTcsFee.value;  
        this.taxes[0].totalIndentValue = ctrls.totalIndentValue.value ? parseInt(ctrls.totalIndentValue.value) : 0;    
      }
    } else if (this.tabIndex === 1) {
      if(this.compositionAddFields.length){
        this.compositionAddFields.map((data:any)=>{
          const tax = this.getFilteredProductData(data)['taxes'][0];
          tax.unitsPerCase = tax.unitsPerCase ? tax.unitsPerCase : this.getFilteredProductData(data).unitsPerCase;
          const totalAreTax = (((tax['Additional Retail Excise Tax (ARET 1)'] + tax['Additional Retail Excise Tax (ARET 2)'] + (tax['Additional Retail Excise Tax (ARET 3)'] ? tax['Additional Retail Excise Tax (ARET 3)'] :0) + tax['Rationalization Of Rates 1'] + tax['Rationalization Of Rates 2']) + ((0.1* tax['finalIssuePrice'] / tax['unitsPerCase']))));
          //const totalTcsFee = ((tax['ISSUE_PRICE_ROUNDED'] + tax['ADD.PRIV.FEE PER CASE'] + (totalAreTax * tax['unitsPerCase'])) * 0.01);
          ctrls.totalIssuePrice.setValue(+(this.vendorIndentsForm.value.totalIssuePrice += data.requestedQuantity * (tax['finalIssuePrice'] / tax.unitsPerCase)).toFixed(2));
          ctrls.totalSplMargin.setValue(+(this.vendorIndentsForm.value.totalSplMargin += data.requestedQuantity * (tax['caseWiseSpecialMargin'] / tax.unitsPerCase)).toFixed(2));
          ctrls.totalAedPrice.setValue(+(this.vendorIndentsForm.value.totalAedPrice += data.requestedQuantity * (tax['Additional Excise duty'] / tax.unitsPerCase)).toFixed(2))
          ctrls.totalVatPrice.setValue(+(this.vendorIndentsForm.value.totalVatPrice += data.requestedQuantity * (tax['VAT'] / tax.unitsPerCase)).toFixed(2));
          ctrls.totalRateOfMp.setValue(+(this.vendorIndentsForm.value.totalRateOfMp += data.requestedQuantity * (tax['SPECIAL MARGIN'] / tax.unitsPerCase)).toFixed(2));
          ctrls.totalExciseDuty.setValue(+(this.vendorIndentsForm.value.totalExciseDuty += data.requestedQuantity * (tax['EXCISE DUTY'] / tax.unitsPerCase)).toFixed(2));
          //ctrls.totalTcsFee.setValue(Math.ceil(this.vendorIndentsForm.value.totalTcsFee += data.requestedQuantity * totalTcsFee));
          ctrls.totalAssesstFee.setValue(+(this.vendorIndentsForm.value.totalAssesstFee += data.requestedQuantity * (tax['ASSESSMENT_FEE'] / tax.unitsPerCase)).toFixed(2));
          ctrls.totalAreTax.setValue(Math.ceil(this.vendorIndentsForm.value.totalAreTax += data.requestedQuantity * (Math.ceil(totalAreTax)) ));
          ctrls.totalCases.setValue(+(this.vendorIndentsForm.value.totalCases += data.requestedQuantity ));

        })
        const formValue = this.vendorIndentsForm.value;
        if(this.userInfo.retailerType === '2C' || this.userInfo.retailerType === 'EP'){
          ctrls.totalTcsFee.setValue(0);
          ctrls.totalPrevFee.setValue(Math.ceil((formValue.totalIssuePrice + formValue.totalSplMargin + formValue.totalAreTax) * 0.204 ));
          ctrls.totalIndentValue.setValue((formValue.totalIssuePrice + formValue.totalSplMargin + formValue.totalAreTax + this.vendorIndentsForm.value.totalPrevFee).toFixed(2));  
  
        }else if(this.userInfo.retailerType === '2TD'){
          ctrls.totalTcsFee.setValue(0);
          ctrls.totalPrevFee.setValue(0);
          ctrls.totalIndentValue.setValue(formValue.totalIssuePrice + formValue.totalSplMargin + formValue.totalAreTax);  
  
        }
        else{
          ctrls.totalTcsFee.setValue(Math.ceil((formValue.totalIssuePrice + formValue.totalSplMargin + formValue.totalAreTax) * 0.01));
          ctrls.totalIndentValue.setValue((formValue.totalIssuePrice + formValue.totalSplMargin + formValue.totalAreTax + this.vendorIndentsForm.value.totalTcsFee).toFixed(2));  
  
        }
        ctrls.totalPdPrice.setValue(Math.ceil(this.vendorIndentsForm.value.totalIndentValue - (formValue.totalAedPrice + formValue.totalRateOfMp + formValue.totalVatPrice + formValue.totalExciseDuty + formValue.totalAreTax  + formValue.totalAssesstFee)));
        ctrls.closingCreditBal.setValue(+(this.vendorIndentsForm.value.totalAmt - this.vendorIndentsForm.value.totalIndentValue ));
        this.taxes = [{
          'actualIssuePrice': ctrls.totalIssuePrice.value,
          'additionalExciseDuty': ctrls.totalAedPrice.value,
          'exciseDuty': ctrls.totalExciseDuty.value,
          'totalRateOfSmP': ctrls.totalRateOfMp.value,
          'totalSpeacialMargin':ctrls.totalSplMargin.value,
          'vat': ctrls.totalVatPrice.value,
          'totalAssestment':0,
          'afee_amount':ctrls.totalAssesstFee.value ? ctrls.totalAssesstFee.value : 0,
          'pdPrice':ctrls.totalPdPrice.value,
          'totalAreTax':ctrls.totalAreTax.value,
          'totalSpeciallMarginPrevFee':ctrls.totalPrevFee.value,
          'totalTcs':ctrls.totalTcsFee.value,
          'totalIndentValue':ctrls.totalIndentValue.value
        }]
        this.taxes[0].pdPrice = ctrls.totalPdPrice.value;  
        this.taxes[0].totalTcs = ctrls.totalTcsFee.value;  
        this.taxes[0].totalIndentValue = ctrls.totalIndentValue.value ? parseInt(ctrls.totalIndentValue.value) : 0;    
        
      }
    }
  }

  removeProducts(index) {
    this.saveDisabled = false;
    this.requestedQuantities.removeAt(index);
    this.updateTotal();
  }

  // setTotal(field, i, currentQty, enteredQty) {
  //   const totalValue = this.tabIndex === 1 ? field.issuePrice * field.requestedQuantity : field.issuePrice * field.requestedQuantity;
  //   this.compositionAddFields[i]['totalValue'] = totalValue;

  //       // if(field.depotStock > field.remainingQuantity){
  //   //   console.log("REM")
  //   //   this.isValidate = enteredQty > field.remainingQuantity;
  //   // }else if(field.depotStock < field.remainingQuantity){
  //   //   console.log("DEP")
  //   //   this.isValidate = enteredQty > field.depotStock;
  //   // }else {
  //   //   console.log("Else")
  //   //     this.isValidate = true;
  //   //   }

  //   this.isValidate = false;
  //   this.fieldError = [];
  //   if(!this.fieldError[i]){
  //     this.fieldError[i] = {};
  //   }
  //   // if(field.rationQuantity > 0){
  //   //   // if(this.rationApplicable == false){
  //   //   //   this.messageService.add({ severity: 'error', summary: 'Error', detail: "Ration Expired" });
  //   //   //   this.isValidate = true;       
  //   //   //   return;
  //   //   // }
  //   //   if ((field.depotStock > field.remainingQuantity) && (enteredQty > field.remainingQuantity)) {
  //   //     this.isValidate = true;
  //   //     this.fieldError[i].error = true;
  //   //     console.log("1",this.isValidate)
  //   //   }else if((field.depotStock < field.remainingQuantity) && (enteredQty > field.depotStock)){
  //   //     this.isValidate = true;
  //   //     this.fieldError[i].error = true;
  //   //     console.log("2.5")
  //   //   }
  //   // }else if(field.rationQuantity == 0){
  //   //   if (enteredQty > field.depotStock) {
  //   //     this.isValidate = true;
  //   //     this.fieldError[i].error = true;
  //   //     console.log("2",this.isValidate)
  //   //   }
  //   // }
  //   console.log(currentQty,enteredQty)
  //   if(enteredQty > 0) {
  //     this.isValidate = currentQty < enteredQty;
  //     if(this.isValidate){
  //       this.fieldError[i].error = true;
  //     }else{
  //       this.fieldError[i].error = false;
  //     }
  //     console.log(this.isValidate)
  //   } else {
  //     this.isValidate = true;
  //     this.fieldError[i].error = true;
  //   }
  // }

  setTotal(field, i, currentQty, enteredQty) {
    const totalValue = this.tabIndex === 1 ? field.mrp * field.requestedQuantity : field.mrp * field.unitsPerCase * field.requestedQuantity;
    this.compositionAddFields[i]['totalValue'] = totalValue;
    // if(field.depotStock > field.remainingQuantity){
    //   console.log("REM")
    //   this.isValidate = enteredQty > field.remainingQuantity;
    // }else if(field.depotStock < field.remainingQuantity){
    //   console.log("DEP")
    //   this.isValidate = enteredQty > field.depotStock;
    // }else {
    //   console.log("Else")
    //     this.isValidate = true;
    //   }

    this.isValidate = false;
    this.checkIndent=false;
    this.fieldError = [];
    if(!this.fieldError[i]){
      this.fieldError[i] = {};
    }
    if(field.rationQuantity > 0){
      // if(this.rationApplicable == false){
      //   this.messageService.add({ severity: 'error', summary: 'Error', detail: "Ration Expired" });
      //   this.isValidate = true;       
      //   return;
      // }
      if ((field.depotStock > field.remainingQuantity) && (enteredQty > field.remainingQuantity)) {
        this.isValidate = true;
        this.fieldError[i].error = true;
        console.log("1",this.isValidate)
      }else if((field.depotStock < field.remainingQuantity) && (enteredQty > field.depotStock)){
        this.isValidate = true;
        this.fieldError[i].error = true;
        console.log("2.5")
      }
    }else if(field.rationQuantity == 0){
      console.log("Ration 0")
      if (enteredQty > field.depotStock) {
        this.isValidate = true;
        this.fieldError[i].error = true;
        console.log("2",this.isValidate)
      }
    }
    // if(enteredQty > 0) {
    //   this.isValidate = currentQty < enteredQty
    // } else {
    //   this.isValidate = true;
    // }
  }

  editQuantity(i) {
    this.productDataList[i]['isEdit'] = true;
  }

  isSelected(product) {
    const index = this.requestedQuantities.value.findIndex(rq => rq.product === product._id);
    return index >= 0;
  }
  isDisabled(value){
    return this.compositionAddFields.some(item => item.productCode === value) 
  }
  isRationing(productCode): any {
    let isStatus: any = false;
    if(this.productList && this.productList.length){
      this.productList.forEach(el => {
        if (el.productCode === productCode && el.rationing && el.rationing.length) {
          if(el.ration === 0){
            this.saveDisabled = true;
          }
          el.rationing.forEach((data:any) => {
            if (isStatus) {
              return;
            }
            if (this.userInfo.entity_id === data.name) {
              // const rationFrom = this.datePipe.transform(data.applicableFrom, 'MM-dd-yyyy h:mm:ss');
              // const rationTo = this.datePipe.transform(data.applicableTo, 'MM-dd-yyyy h:mm:ss');
              const rationFrom = data.applicableFrom;
              const rationTo = data.applicableTo;
              const todayDateTime: any = (new Date()).toISOString();
              if (todayDateTime > rationFrom && todayDateTime < rationTo) {
                isStatus = true;
              }
            }
          });
        }
      });
      return isStatus;
    }
  }
  resetForm(index = ''): any {
    // if (index === '') {
      this.bottlesIndentForm.nativeElement.reset();
      this.casesIndentForm.nativeElement.reset();
      this.compositionAddFields = [];
      this.isValidate = false;
      this.checkIndent = false;
    // }
    
  }
  resetBrandFilter(evt, i): any {
    if (evt === '') {
      this.filterBrandProducts(i);
    }    
  }

}
