import { UtilService } from 'src/app/core/utils/utility-helper';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from 'primeng/api';
import { Shipments } from 'src/app/core/models/shipments/shipments.model';
import { CommonsService } from 'src/app/core/services/commons.service';
import { InterDepotService } from 'src/app/core/services/inter-depot.service';
import { StockReturnService } from 'src/app/core/services/stock-return.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShipmentsService } from 'src/app/core/services/shipments.service';

@Component({
  selector: 'app-stock-return-icdc-packing-list-dialog',
  templateUrl: './stock-return-icdc-packing-list-dialog.component.html',
  styleUrls: ['./stock-return-icdc-packing-list-dialog.component.scss']
})
export class StockReturnIcdcPackingListDialogComponent implements OnInit {
  ofsCode = '';
  ofsScanCode = '';
  @Input() statusData: any;
  displayedColumns: string[] = ['ofsNo', 'productCode', 'productName', 'orderQty', 'allocateQty', 'action'];
  dataSource: MatTableDataSource<Shipments> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;
  icdcData = [];
  caseCode: any;
  valid: any;
  caseCount: any;
  isEdit: boolean = false;
  isQtyValid: boolean;
  cttDetails: any;
  ofsChangeData: any;
  isDisable: boolean = true;
  isScanned: boolean;
  ofsIndex: any;
  caseDetails: any;

  constructor(public dialogRef: MatDialogRef<StockReturnIcdcPackingListDialogComponent>, 
    private messageService: MessageService, 
    private orderService: InterDepotService,
    private commonsService: CommonsService, private stockReturnService: StockReturnService, @Inject(MAT_DIALOG_DATA) public data,
    public utilService: UtilService,
    private spinner: NgxSpinnerService,
    private shipmentsService: ShipmentsService
  ) { }
  ofsAddFields = [];
  ngOnInit(): void {
    console.log(this.data, this.statusData, "28::::");
    if (this.statusData) {
      this.data = this.statusData;
    }
    this.getOfsDetails();
    // this.getCttFetails();
    this.ofsAddFields = [
      { ofsCode: '', productCode: '', productName: '', casesCount: '', approvedQty: '' }
    ]
    console.log(this.data)
  }

  getCttFetails() {
    let data = JSON.stringify({ "data": ["get_ctt"] });

    const req_Data = {
      action: 'common_apis',
      q: data

    }
    this.spinner.show();
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {
      this.cttDetails = result['get_ctt'];
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    })
  }

  getOfsDetails() {
    let tpNo = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
    let action;
    if(this.data.consignmentType === 'OFIDT') {
      action = 'get_added_ofidt'
    }else if(this.data.consignmentType === 'ICDC') {
      action = 'get_added_icdc'
    } else if(this.data.consignmentType === 'STOCKRETURN') {
      action = 'get_added_icdc'
    }
    const req_Data = {
      action: 'common_apis',
      q: '{"data": {"action":"' + action + '", "value": "' + tpNo + '"}}'

    }
    this.spinner.show();
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {
      console.log(result)
      if(this.data.consignmentType === 'OFIDT'){
        this.icdcData = result['get_added_ofidt'];
      } else if(this.data.consignmentType === 'ICDC' || this.data.consignmentType === 'STOCKRETURN') {
        this.icdcData = result['get_added_icdc'];
        if (result && result['get_added_icdc'] && result['get_added_icdc'].length === 1) {
          this.ofsChangeData = result['get_added_icdc'][0];
        }

      }
      // else if (this.data.consignmentType === 'STOCKRETURN'){
      //   this.icdcData = result['get_added_icdc'];
      // }
      if (this.icdcData) {
        /*  this.icdcData.forEach( ofs => {
          ofs.productDetails.forEach( product => {
            product['shipmentQty'] = product.approvedQty;
          })
        }) */
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    })
  }

  shipmentQuantityChange(item) {
    console.log(item.approvedQty, item.shipmentQty, item.approvedQty < item.shipmentQty, "50:::::")
    return item.approvedQty < item.shipmentQty || item.shipmentQty < item.casesCount;
  }

  addOfs() {
    let data = {};
    this.data.icdc.forEach(ofs => {
      if (this.ofsCode == ofs.icdcCode) {
        data = ofs;
      }
      data['tpNo'] = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
      console.log(data, this.data, this.ofsCode, "42::::::")
    });
    const req_Data = {
      action: 'add_icdc',
      data
    }
    this.spinner.show();
    this.stockReturnService.putOfs(req_Data).subscribe(res => {
      console.log(res, "47:::")
      this.getOfsDetails();
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    })
  }

  shipmentEdit(item) {
    item.isEdit = true;
  }

  shipmentSave(item, ofs) {
    if (item.approvedQty < item.shipmentQty || item.shipmentQty < item.casesCount) {
      return;
    }
    console.log(item, "82:::::::::")
    const req_Data = {
      action: 'update_shipment_qty',
      data: { "tpNo": this.data.tpNo, "icdc": ofs.icdcCode, "productCode": item.productCode, "shipmentQty": item.shipmentQty }
    }
    this.spinner.show();
    this.stockReturnService.saveShipmentQuantity(req_Data).subscribe(result => {
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    })
    item.isEdit = false;
  }

  dataFill(data) {
    let flag = false;
    Object.keys(data).forEach(val => {
      flag = !data[val];
    })
    return flag;
  }

  deleteOfs(i) {
    this.ofsAddFields.splice(i, 1);
  }

  caseCodeChange() {
    if(this.caseCode.length === 17){
      const caseReq_Data = {
        action: 'common_apis',
        q: '{"data": {"action": "get_case_by_heal", "value": "' + this.caseCode + '"}}'
      }
      this.spinner.show();
      this.commonsService.getCommonEntites(caseReq_Data).subscribe(result => {
        this.caseDetails = result['get_case_by_heal'];
        if(this.caseDetails){
          const isTpAvailable = this.caseDetails.tpNo;
          const matchesCaseDetails = this.ofsChangeData.productDetails.some(product => {
            if (this.caseDetails.productCode.length === 11) {
              this.caseDetails.productCode = this.utilService.convertProductCodeLength11TO12(this.caseDetails.productCode);
            }
            return this.caseDetails.productCode === product.productCode;
          }); 
          const scannedQtyAllowed = this.ofsChangeData.productDetails.some(product => this.caseDetails.productCode === product.productCode && product.approvedBottles && (!product.bottlesCount || product.approvedBottles > product.bottlesCount));
          const caseError = (isTpAvailable || this.caseDetails.location !== "Depot")? 'Case already scanned' : !matchesCaseDetails ? 'Wrong Product Case Scanned' : 'Shipment Quantity Reached';
          if(isTpAvailable || !matchesCaseDetails || !scannedQtyAllowed){
            this.messageService.add({ severity: 'error', summary: 'Error', detail: caseError });
          }else if(matchesCaseDetails && scannedQtyAllowed){
            this.scanBarCode();
          }
        }else{
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid Case' });
        }
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
      })
    }else{
      if (this.caseCode && this.ofsScanCode) {

        const caseReq_Data = {
          action: 'common_apis',
          q: '{"data": {"action": "get_case_details", "value": "' + this.caseCode + '"}}'
        }
        this.spinner.show();
        this.commonsService.getCommonEntites(caseReq_Data).subscribe(result => {
          this.caseDetails = result['get_case_details'];
          if(this.caseDetails){
            const isTpAvailable = this.caseDetails.tpNo;
            const matchesCaseDetails = this.ofsChangeData.productDetails.some(product => {
              if (this.caseDetails.productCode.length === 11) {
                this.caseDetails.productCode = this.utilService.convertProductCodeLength11TO12(this.caseDetails.productCode);
              }
              return this.caseDetails.productCode === product.productCode;
            }); 
            const scannedQtyAllowed = this.ofsChangeData.productDetails.some(product => this.caseDetails.productCode === product.productCode && (!product.casesCount || product.approvedCases > product.casesCount));
            const caseError = (isTpAvailable || this.caseDetails.location !== "Depot")? 'Case already scanned' : !matchesCaseDetails ? 'Wrong Product Case Scanned' : 'Shipment Quantity Reached';
            if(isTpAvailable || !matchesCaseDetails || !scannedQtyAllowed){
              this.messageService.add({ severity: 'error', summary: 'Error', detail: caseError });
            }else if(matchesCaseDetails && scannedQtyAllowed){
              this.scanBarCode();
            }
          }else{
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid Case' });
          }
          this.spinner.hide();
        }, err => {
          this.spinner.hide();
        })
      
      }
    
    }
    
    if (this.caseCode && this.ofsChangeData.ofidtNo) {
      this.scanBarCode()
    }
  }

  scanBarCode(){
    const isCasesExceed = this.checkCasesCount();
    if (isCasesExceed) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Scanned Quantity should not be greater than approved quantity...' });
      return;
    }
    const req_Data = {
      action: this.data.destnEntityType === 'Distillery' ? 'add_casecodes_srd_dist' : 'add_casecodes_srd',
      data: { "tpNo": this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo, "caseCode": this.caseCode, "depotCode": this.icdcData[this.ofsIndex].productDetails.destnEntityId, "productDetails": this.icdcData[this.ofsIndex].productDetails }
    }
    if (this.ofsChangeData.productDetails && this.ofsChangeData.productDetails[0]['approvedCases']) {
      req_Data.data['type'] = 'cases';
    }
    else {
      req_Data.data['type'] = 'bottles';
    }
    if (this.caseCode && this.ofsChangeData.ofidtNo) {
      req_Data.data['ofidtNo'] = this.ofsChangeData.ofidtNo;
      delete req_Data.data['productDetails'][0].allocateQtyBtls;
      delete req_Data['data']['productDetails'][0].allocateQtyCases;
      delete req_Data['data']['productDetails'][0].closingBalBottles;
      delete req_Data['data']['productDetails'][0].closingBalCases;
      this.spinner.show();
      this.orderService.updateIDTs(req_Data).subscribe(res => {
        console.log(res);

        this.valid = res.status;
        this.caseCode = '';
        this.getOfsDetails();
        this.spinner.hide();
        this.isScanned = true;
      }, err => {
        this.spinner.hide();
      })
    }else{
      if (this.data.destnEntityType === 'Distillery') {
        req_Data.data['ofsRd'] = this.ofsScanCode;
      } else {
        req_Data.data['icdcCode'] = this.ofsScanCode;
      }
      this.spinner.show();
      this.shipmentsService.scanBarCode(req_Data).subscribe(res => {
        console.log(res);

        this.valid = res.status;
        this.caseCode = '';
        this.getOfsDetails();
        this.spinner.hide();
        this.isScanned = true;
      }, err => {
        this.spinner.hide();
      })
    }

  }

  changeOfs(code,i) {
    this.isDisable = false;
    if (this.data.destnEntityType === 'Distillery') {
      this.ofsScanCode = code.ofsRd;
    } else {
      this.ofsScanCode = code.icdcCode;
    }    
    this.ofsIndex = i;
    this.ofsChangeData = code;
  }
  dialogClose(): void {
    this.dialogRef.close();
  }
  checkCasesCount(): any {
    const isCasesExceed = this.icdcData.map((item: any) => {
      return item.productDetails.some(pd => pd.casesCount >= pd.approvedQty);
    });
    if (isCasesExceed && isCasesExceed.length && isCasesExceed[0] === true) {
      return true;
    }
    return false;
  }

  getCaseCount(item){
    if(item.breakages){
      if(item.breakages.bottleBreakages){
        let unitsPerCase = this.utilService.productCodeToDetails(item.productCode).unitsPerCase
        let bottleBreakagesLength = item.breakages.bottleBreakages.length; 
        if (item.breakages.bottleBreakages.length > 1) {
          const caseCodes: any = item.breakages.bottleBreakages.map(el => el.caseCode);
          const isDuplicate: any = caseCodes.filter((c, idx) => caseCodes.indexOf(c) != idx);
          console.log(isDuplicate);
          bottleBreakagesLength = bottleBreakagesLength - isDuplicate.length;
        }
        let totalScannedBottles = (bottleBreakagesLength * unitsPerCase) - item.breakages.bottles;
        item.totalScannedCases = item.casesCount + Math.floor(totalScannedBottles/unitsPerCase);
        item.totalScannedBottles = totalScannedBottles % unitsPerCase;
        console.log('tsc,tsb', item.totalScannedCases, item.totalScannedBottles);
      }
    }
    return item;
  }

}
