import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-asn-details-for-depot-receipt-dialog',
  templateUrl: './asn-details-for-depot-receipt-dialog.component.html',
  styleUrls: ['./asn-details-for-depot-receipt-dialog.component.scss']
})
export class AsnDetailsForDepotReceiptDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AsnDetailsForDepotReceiptDialogComponent>, @Inject(MAT_DIALOG_DATA)public data) { }

  ngOnInit(): void {
  }

  dialogClose(): void{
    this.dialogRef.close();
  }

}
