import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ShipmentsService } from 'src/app/core/services/shipments.service';

@Component({
  templateUrl: './supplier-invoice-info-dialog.component.html',
  styleUrls: ['./supplier-invoice-info-dialog.component.scss']
})
export class SupplierInvoiceInfoDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'no',
    'invoiceNumber',
    'productCode',
    'brandName',
    'quantity',
    'unitCost',
    'totalCost'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  constructor(public dialogRef: MatDialogRef<SupplierInvoiceInfoDialogComponent>, @Inject(MAT_DIALOG_DATA)public data, private shipmentsService: ShipmentsService) { }

  ngOnInit(): void {
    console.log(this.data,"26:::")
    this.dataSource = new MatTableDataSource(this.data);
    /* const req_Data = {
      "action": "get_invoice",
      "q": "{}" 
    }
    this.shipmentsService.getInvoice(req_Data).subscribe(res => {
          console.log(res, "32:::::")
    }) */
  }

  dialogClose(): void{
    this.dialogRef.close();
  }

}
