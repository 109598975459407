import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { MessageService } from 'primeng/api';
import { ConfirmPasswordValidator } from 'src/app/core/utils/confirm-password.validator';
import { UtilService } from 'src/app/core/utils/utility-helper';
@Component({
  selector: 'app-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.scss'],
})
export class ManageProfileComponent implements OnInit {
  manageProfileForm: FormGroup;
  user: any;
  changePasswordForm: FormGroup;
  emailIds:FormArray;
  mobileNumbers:FormArray;
  selectedRadio: any = '';
  selectedemailRadio:any = '';
  noEmailData:boolean;
  noMobileData:boolean;
  data:any=[];
  @ViewChild('passwordFrom') passwordFrom;
  constructor(private fb: FormBuilder, private authService: AuthService, private messageService: MessageService,private util:UtilService) {
    this.user = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
  }
  ngOnInit(): void {
    this.manageProfileForm = this.fb.group({
      fullName: this.data.fullName,
      zone:this.data?.zone,
      roleName:this.data?.roleName,
      entityType:this.data?.entityType,
      userName:this.data.userName,
      entityId:this.data?.entityId,
      emailIds: this.fb.array([this.updateEmailList()]),
      mobileNumbers:this.fb.array([this.updateMobileList()])
    });
    this.changePasswordForm = this.fb.group({
      oldPassword:['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    },{validator: ConfirmPasswordValidator.MatchPassword });
    this.emailIds = this.manageProfileForm.get('emailIds') as FormArray;
    this.mobileNumbers = this.manageProfileForm.get('mobileNumbers') as FormArray;
    this.getProfileData();
  }
  get f() {
    return this.manageProfileForm.controls;
  }
  addEmail(){
    const control = <FormArray>this.manageProfileForm.controls["emailIds"];
    control.push(this.updateEmailList());
    
  }
  updateEmailList() {
    return this.fb.group({
      emailId: [''],
      isVerified: [''],
      isPrimary: [''],
    });
  }
  removeEmail(i:number){
    const control = <FormArray>this.manageProfileForm.controls["emailIds"];
    control.removeAt(i);
  }
  isVerifyChange(event, i){
    const verifyEmail = (<FormArray>this.manageProfileForm.get("emailIds")).at(i);
   
  }
  addPhoneNumber(){
    const control = <FormArray>this.manageProfileForm.controls["mobileNumbers"];
    control.push(this.updateMobileList());
  }
  updateMobileList(){
    return this.fb.group({
      number: [''],
      isVerified: [''],
      isPrimary: [''],
    });
  }
  removeMobile(i:number){
    const control = <FormArray>this.manageProfileForm.controls["mobileNumbers"];
    control.removeAt(i);
  }
  isVerifiedMobile(event:any,i){
    if(event.checked == false){
      this.selectedRadio = '';
    }
  }
  isVerifiedEmail(event:any,i){
    if(event.checked == false){
      this.selectedemailRadio = '';
    }
  }


  editUser(formValue){
    
    const req_data: any = {};
    // req_data.name = formValue.name;
    const mobileNumbers = formValue.mobileNumbers;
    const emailIds= formValue.emailIds;
    formValue.mobileNumbers = [];
    formValue.emailIds = [];
    mobileNumbers.map((res, i) => {
      formValue.mobileNumbers[i] = {};
      formValue.mobileNumbers[i].number = res.number;
      if (res.isVerified) {
        formValue.mobileNumbers[i].isVerified = true;
      } else {
        formValue.mobileNumbers[i].isVerified = false;
      }
      if (this.selectedRadio === i) {
        formValue.mobileNumbers[i].isPrimary = true;
      } else {
        formValue.mobileNumbers[i].isPrimary = false;
      }
      if(formValue.mobileNumbers[i].number === '' && formValue.mobileNumbers[i].isVerified === false && formValue.mobileNumbers[i].isPrimary === false){
        this.noMobileData = true;
       }else{
        this.noMobileData = false;
       }
    });
    emailIds.map((res, i) => {
      formValue.emailIds[i] = {};
      formValue.emailIds[i].emailId = res.emailId;
      if (res.isVerified) {
        formValue.emailIds[i].isVerified = true;
      } else {
        formValue.emailIds[i].isVerified = false;
      }
      if (this.selectedemailRadio === i) {
        formValue.emailIds[i].isPrimary = true;
      } else {
        formValue.emailIds[i].isPrimary = false;
      }
      if(formValue.emailIds[i].emailId === '' && formValue.emailIds[i].isVerified === false && formValue.emailIds[i].isPrimary === false ){
        this.noEmailData=true;
      }else{
        this.noEmailData=false
      }
    
    });
    if(this.noEmailData ==false && this.noMobileData == false){
      const req_data= {
        "action":'update_profile',
        data:this.manageProfileForm.value
      }
      
      this.authService.updateProfile(req_data).subscribe((res:any)=>{
        
        if(res.status == true){
          this.messageService.add({ severity: 'success', detail: 'Profile Updated Successfully'});
        }
        this.getProfileData()
        
      })
    }  
    
  }
  getProfileData(){
    const req_Data = {
      "action": "get_profile_user"
    }
    this.authService.getProfileData(req_Data).subscribe((res:any)=>{
      
      this.data = res;
      if (this.data) {
        this.manageProfileForm.patchValue({
          userName : this.data.userName,
          roleName: this.data.roleName,
          entityId: this.data.entityId,
          entityType: this.data.entityType,
          fullName: this.data.fullName
        });
        const emailIds = this.manageProfileForm.get('emailIds') as FormArray;
        emailIds.clear();
        this.data.emailIds.forEach((b,i) => {
          if(b.isPrimary){
            this.selectedemailRadio = i;
          }
          emailIds.push(
            this.fb.group({
              emailId: b.emailId,
              isVerified: b.isVerified,
              isPrimary: b.isPrimary
            })
          );
        });
        const mobileNumbers = this.manageProfileForm.get('mobileNumbers') as FormArray;
        mobileNumbers.clear();
        this.data.mobileNumbers.forEach((b,i) => {
          if(b.isPrimary){
            this.selectedRadio = i;
          }
          mobileNumbers.push(
            this.fb.group({
              number: b.number,
              isVerified: b.isVerified,
              isPrimary: b.isPrimary
            })
          );
        });
      }
      
    })
  }

  changePassword() {
    const req_data= {
      "action": "update_password",
      data: {
        "oldpassword":  this.util.encryptPassword(this.changePasswordForm.value.oldPassword),
        "newpassword": this.util.encryptPassword(this.changePasswordForm.value.newPassword)
      }
    }
    const {confirmPassword, ...changePasswordParams} = this.changePasswordForm.value;
    this.authService.changePassword(req_data).subscribe(res => {
      if(res['status'] == true){
        this.passwordFrom.nativeElement.reset();
        this.messageService.add({ severity: 'success', detail: 'Password Updated Successfully'});
      }
    });
  }
}