import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CommonsService } from 'src/app/core/services/commons.service';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { takeUntil } from 'rxjs/operators';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { ImportService } from 'src/app/core/services/import.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-manage-import-indent',
  templateUrl: './manage-import-indent.component.html',
  styleUrls: ['./manage-import-indent.component.scss']
})
export class ManageImportIndentComponent implements OnInit {
  importIndentForm: FormGroup;
  features: any;
  featuresData: any;
  cvdChallaData:any=[];
  ifChallanData:any=[];
  ealChallanData:any=[];
  ttsChallanData:any=[];
  productsList:any=[];
  showPrint: any = false;
  printId: any = '';
  displayedColumns: string[] = [
    "select",
    'no',
    "transType",
    "initialAmount",
    "depositedAmount",
    "amountUsed",
    "remainingAmount"
  ];

  displayedColumnsProducts: string[] = [
    "select",
    'no',
    "productCode",
    "brandName",
    "size",
    "packType",
    "upc",
    "strength",
    "qtyInCase",
    // "qtyInBls",
    // "qtyInPls",
    "ofsImportPrice",
    "value"
  ];
  depotNames:any = [];
  entityType:any;
  today:any=new Date();
  refData:any;
  routeList: any=[];
  routes = [];
  cvdChallansList:any=[];
  importChallansList:any=[];
  ealChallansList:any=[];
  ttsChallansList:any=[];
  selectedCvdChallans:any=[];
  selectedimportFeeChallans:any=[];
  selectedEalChallans:any=[];
  selectedttsChallans:any=[];
  selectedTtsChallans:any=[];
  cvdSelection = new SelectionModel<any>(true, []);
  ifSelection = new SelectionModel<any>(true, []);
  EalSelection = new SelectionModel<any>(true, []);
  ttsSelection = new SelectionModel<any>(true, []);
  productSelection = new SelectionModel<any>(true, []);
  importFeeData: MatTableDataSource<any> = new MatTableDataSource([]);
  cvdData: MatTableDataSource<any> = new MatTableDataSource([]);
  ealData: MatTableDataSource<any> = new MatTableDataSource([]);
  ttsData: MatTableDataSource<any> = new MatTableDataSource([]);
  productsData: MatTableDataSource<any> = new MatTableDataSource([]);
  public filteredDepotNames: ReplaySubject<any> = new ReplaySubject<any>(1);
  public filteredRoutes: ReplaySubject<any> = new ReplaySubject<any>(1);
  public ifsDepotFilterCtrl: FormControl = new FormControl();
  public _onDestroy = new Subject<void>();
  public routeFilterCtrl: FormControl = new FormControl();
  @ViewChild('singleDepotSelect') singleDepotSelect: MatSelect;
  roleName:any;
  loader: any = false;
  disableApprove:any=false;
  licenceHeld:any;
  maxDate:any;
  distilleryUnit:any;
  challanDatas: any = [];
  isForeignProduct: any = false;

  constructor(private fb: FormBuilder,
    private dialogRef: MatDialogRef<ManageImportIndentComponent>,
    private shipmentsService: ShipmentsService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private commonsService: CommonsService,
    public utilService: UtilService,
    private importService:ImportService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data) { 
      this.entityType = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).entity_id;
      this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
      this.distilleryUnit = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).distillery_unit;
      const distilleryAddress = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).distillery_address
      // if(distilleryAddress && distilleryAddress[0].address){
      //   this.licenceHeld = distilleryAddress[0].address ? distilleryAddress[0].address : '';
      // }
      this.licenceHeld = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'))?.distilleryName
      // if(this.distilleryUnit === this.entityType){
      //   this.licenceHeld = this.utilService.formatString(this.distilleryUnit)
      // }else{
      //   this.licenceHeld = `${this.utilService.formatString(this.distilleryUnit)} S/L ${this.utilService.formatString(this.entityType)}` 
      // }
      
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 30);
      this.maxDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
    }

  ngOnInit(): void {
    this.importIndentForm = this.fb.group({
      onlineRefNo: ['', [Validators.required, ]],
      detailsOfLicenseHeld: [this.licenceHeld, [Validators.required, ]],
      consignmentStart: ['', [Validators.required, ]],
      depotName: ['', [Validators.required, ]],
      route: ['', [Validators.required, ]],
      validityDate: ['', [Validators.required, ]],
      cvdOpening: ['', [Validators.required, ]],
      importFeeOpening: ['', [Validators.required, ]],
      ealOpening: ['', [Validators.required, ]],
      ttsOpening: ['', [Validators.required, ]],
      // cvdChallanNUmbers:[''],
      // importChallanNUmbers:[''],
      // ealChallanNUmbers:[''],
      // ttsChallanNUmbers:[''],
      exciseDutyAmount: [0],
      cvdFeeAmount: [0],
      healFeeAmount: [0],
      importFeeAmount: [0],
      ttsFeeAmount: [0],
    });
    // this.getRoutes();
    if (this.roleName === 'NonLocal Brewery Manager') {
      this.importIndentForm.controls.ealOpening.setValidators(null);
    } else {
      this.importIndentForm.controls.ttsOpening.setValidators(null);
    }

    this.importIndentForm.controls.consignmentStart.setValue(this.entityType);
    if (this.data) {
      this.importIndentForm.patchValue({
        onlineRefNo: this.data.onlineRefNo,
        detailsOfLicenseHeld: this.data.detailsOfLicenseHeld,
        consignmentStart: this.data.supplierCode,
        depotName: this.data.depotCode,
        route: this.data.route,
        validityDate: this.data.validityDate,
        cvdOpening: this.data.cvdOpening,
        importFeeOpening: this.data.importFeeOpening,
        ealOpening: this.roleName === 'NonLocal Brewery Manager' ? 0 : this.data.ealOpening,
        ttsOpening: (this.roleName === 'NonLocal Brewery Manager' || this.roleName === 'Material Management') ? this.data.ttsOpening : 0,

      });
      let cvdFeeAmt = 0;
      let healFeeAmt = 0;
      let importFeeAmt = 0;
      let ttsFeeAmt = 0;
      this.productsData =this.data.productDetails;
      this.data.productDetails.forEach(element => {
        element['approvedQty'] = element.approvedQty ? element.approvedQty : (element.requestedQty ? element.requestedQty : element.requestedQty);
        element['value'] = (element.approvedQty ? element.approvedQty :0) * (((element.basicPricing[0] ? element.basicPricing[0].price : 0) - (element.pricing[0] ? element.pricing[0].importFee :0) + element.unitsPerCase) * 0.3)
        cvdFeeAmt += element['cvdFee'] ? element['cvdFee'] : 0;
        healFeeAmt += element['healFee'] ? element['healFee'] : 0;
        importFeeAmt += element['importFee'] ? element['importFee'] : 0;        
        ttsFeeAmt += element['ttsFee'] ? element['ttsFee'] : 0;
      });
      this.isForeignProduct = this.data.productDetails && this.data.productDetails.length && this.data.productDetails.some(pd => pd.category && pd.category === 'FOREIGN');

      this.data.productDetails.map((item: any) => {
        if (item.requestedQty) {
          item.approvedQty = item.requestedQty;
        }
      });
      this.importIndentForm.patchValue({
        cvdFeeAmount: this.isForeignProduct ? 0 :cvdFeeAmt,
        healFeeAmount: healFeeAmt,
        importFeeAmount: importFeeAmt,
        ttsFeeAmount: ttsFeeAmt,
      });
    }
    if(!this.data){
      // this.getChallans();
      this.getDepot();
      // this.getProducts();
    }
    this.routeFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterRoutes();
    });

  }

  
  filterRoutes() {
    if (!this.routes || (this.routes && this.routes.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.routeFilterCtrl.value;
    if (!search) {
      this.filteredRoutes.next(this.routes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredRoutes.next(
      this.routes.filter(name => name.toLowerCase().indexOf(search) > -1)
    );
  }
  getRefData(event:any){
    const onlineRefNumber = event.target.value;
    const req_Data = {
      action: 'common_apis',
      q: '{"data": {"action": "get_added_ip_ofs_data", "value": "' + onlineRefNumber + '"}}'

    }
    if(onlineRefNumber != ''){
      this.spinner.show();
      let cvdFeeAmount: any = 0;
      let importFeeAmount: any = 0;
      let healFeeAmount: any = 0;
      let ttsFeeAmount: any = 0;
      this.commonsService.getCommonEntites(req_Data).subscribe((res:any) => {
        if (res['get_added_ip_ofs_data'] && res['get_added_ip_ofs_data'].length > 0) {
          this.refData = res['get_added_ip_ofs_data'][0];
          this.productsData = this.refData && this.refData.productDetails;
          this.isForeignProduct = this.refData.productDetails && this.refData.productDetails.length && this.refData.productDetails.some(pd => pd.category && pd.category === 'FOREIGN');
          if (this.isForeignProduct) {
            this.importIndentForm.controls.cvdOpening.setValue(0);
            this.importIndentForm.controls.cvdOpening.setValidators(null);
            this.importIndentForm.controls.cvdOpening.updateValueAndValidity();

            this.importIndentForm.controls.cvdFeeAmount.setValue(0);
            this.importIndentForm.controls.cvdFeeAmount.setValidators(null);
            this.importIndentForm.controls.cvdFeeAmount.updateValueAndValidity();
          }
          this.refData.productDetails.forEach(el => {
            const exciseDutyAmount = el['Excise Duty'] ? el['Excise Duty'] : 0;
            const approvedQty = el.approvedQty ? el.approvedQty : 0;
            cvdFeeAmount += exciseDutyAmount * (approvedQty);
            el.bls = ((el.unitsPerCase * el.size * el.approvedQty) / 1000) || 0;
            el.importFee = 2 * el.bls;
            // el.healFee = ((approvedQty * el.unitsPerCase * 0.4366)).toFixed(2);
            el.healFee = ((approvedQty * el.unitsPerCase * 0.4273)).toFixed(2);
            importFeeAmount += el.importFee;
            healFeeAmount += parseFloat(el.healFee) || 0;
            if (this.roleName === 'NonLocal Brewery Manager') {
              // el.ttsFee = ((el.unitsPerCase * el.approvedQty * 0.0826)).toFixed(2);
              el.ttsFee = ((el.unitsPerCase * el.approvedQty * 0.0733)).toFixed(2);
              ttsFeeAmount += parseFloat(el.ttsFee) || 0;
            }
          });
        }
        if(this.refData){
          this.importIndentForm.patchValue({
            consignmentStart: this.refData.supplierCode,
            depotName: this.refData.depotCode,
            validityDate: this.maxDate,
            cvdFeeAmount: (cvdFeeAmount).toFixed(2),
            importFeeAmount: importFeeAmount,
            healFeeAmount: healFeeAmount,
          });
          if (this.roleName === 'NonLocal Brewery Manager') {
            this.importIndentForm.patchValue({
              ttsFeeAmount: ttsFeeAmount
            });
          }
          this.getRoutes(this.refData.depotCode);
        }
        this.spinner.hide();
        this.getImportChallanData();
        // this.getChallansData();
      })
    }

  }

getChallansData(){
  const req_Data = {
    action: 'common_apis',
    q: '{"data": {"action": "get_active_import_challan", "value": ""}}'

  }
  this.commonsService.getCommonEntites(req_Data).subscribe((res:any) => {
    console.log(res)
    res['get_active_import_challan'].forEach((data:any)=>{
      if(data.challanType === 'CVD'){
        this.cvdChallansList.push(data)
      }else if(data.challanType === 'EAL'){
        this.ealChallansList.push(data)
      }else if(data.challanType === 'Import Fee'){
        this.importChallansList.push(data)
      }else if(data.challanType === 'TTS'){
        this.ttsChallansList.push(data)
      }
    })
  })
}

  getImportChallanData(): any {
    const req_Data: any = {
      action: 'common_apis',
      q: '{"data": {"action": "get_import_challan_data", "value": ""}}'
    }
    this.spinner.show();
    this.commonsService.getCommonEntites(req_Data).subscribe(res => {
      const challans: any = [];
      const missingChallanType: any = [];
      if (res && res.get_import_challan_data && res.get_import_challan_data.length) {
        const ab = res.get_import_challan_data.some(el => el.challanType === 'EAL');
        //new Code
        if (this.roleName !== 'NonLocal Brewery Manager' && !res.get_import_challan_data.some(el => el.challanType === 'EAL')) {
          missingChallanType.push('EAL');
        }
        if (!res.get_import_challan_data.some(el => el.challanType === 'CVD')) {
          if (!this.isForeignProduct) {
            missingChallanType.push('CVD');
          }
        }
        if (!res.get_import_challan_data.some(el => el.challanType === 'Import Fee')) {
          missingChallanType.push('Import Fee');
        }
        if (this.roleName === 'NonLocal Brewery Manager') {
          if (!res.get_import_challan_data.some(el => el.challanType === 'TTS')) {
            missingChallanType.push('TTS');
          } 
        }
        // res.get_import_challan_data.filter(el => el.challanType !== 'EAL' || el.challanType !== 'CVD' || el.challanType !== 'Import Fee');
        // res.get_import_challan_data.forEach(el => {
        //   if (el.challanType !== 'EAL') {
        //     missingChallanType.push('EAL');
        //   }
        //   if (el.challanType !== 'CVD') {
        //     missingChallanType.push('CVD');
        //   }
        //   if (el.challanType !== 'Import Fee') {
        //     missingChallanType.push('Import Fee');
        //   } 
        // });
        if (missingChallanType && missingChallanType.length) {
          challans.push({challanType: missingChallanType});
          this.addImportChallanData(missingChallanType);
        } else {
          res.get_import_challan_data.forEach(challanData => {
            if (challanData && challanData.challanNumber) {
              if ((challanData.challanType !== 'CVD') || (challanData.challanType === 'CVD' && !this.isForeignProduct)) {
                const feeAmount = this.getFeeAmount(challanData.challanType);
                if (feeAmount > challanData.remainingAmount) {
                  challans.push({challanNumber: challanData.challanNumber,FeeAmount: (feeAmount - challanData.remainingAmount), challanType: challanData.challanType});
                }
              }
            }
          });
          if (challans.length) {
            this.checkImportChallans(challans);
          } else {
            this.setChallanDatas(res.get_import_challan_data);
          }
        }


        //Old Code
        // const challanData: any = res.get_import_challan_data.find(el => el.challanType === 'CVD');
        // if (challanData && challanData.challanNumber) {
        //   if (this.importIndentForm.value.cvdFeeAmount > challanData.remainingAmount) {
        //     this.checkImportChallans(challanData.challanNumber);
        //   } else {
        //     this.setChallanDatas(res.get_import_challan_data);
        //   }
        // } else {
        //   this.addImportChallanData(true);
        // }
      } else {
        if (this.roleName === 'NonLocal Brewery Manager') {
          this.addImportChallanData(['CVD', 'Import Fee', 'TTS']);
        } else {
          this.addImportChallanData(['EAL', 'CVD', 'Import Fee']);
        }
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }
  getFeeAmount(challanType): any {
    let feeAmount: any;
    switch (challanType) {
      case 'Import Fee':
        feeAmount = this.importIndentForm.value.importFeeAmount;
        break;
      case 'CVD':
        feeAmount = this.importIndentForm.value.cvdFeeAmount;
        break;
      case 'EAL':
        feeAmount = this.importIndentForm.value.healFeeAmount;
        break;
      case 'TTS':
        feeAmount = this.importIndentForm.value.ttsFeeAmount;
        break;
    }
    return feeAmount;
  }
  // old code
  // addImportChallanData(isAddChallan = false): any {
  //   const req_Data: any = {
  //     action: 'common_apis',
  //     q: '{"data": {"action": "add_import_challan_data", "value": ""}}'
  //   }
  //   this.spinner.show();
  //   this.commonsService.getCommonEntites(req_Data).subscribe(res => {
  //     console.log('res', res);
  //     if (isAddChallan && res.add_import_challan_data.addChallan) {
  //       this.addChallanData(res.add_import_challan_data);
  //     } else {
  //       if (res && res.add_import_challan_data && res.add_import_challan_data.length) {
  //         const challanData: any = res.add_import_challan_data.find(el => el.challanType === 'CVD');
  //         if (challanData && challanData.challanNumber) {
  //           if (this.importIndentForm.value.cvdFeeAmount > challanData.remainingAmount) {
  //             this.checkImportChallans(challanData.challanNumber);
  //           } else {
  //             this.setChallanDatas(res.add_import_challan_data);
  //           }
  //         }
  //       }
  //     }
  //     this.spinner.hide();
  //   }, err => {
  //     this.spinner.hide();
  //   });
  // }
  
  //new Code
  addImportChallanData(challanTypes: any = []): any {
    const req_Data: any = {
      action: 'common_apis',
      // old Code
      // q: '{"data": {"action": "add_import_challan_data", "value": ""}}'
      //new code
      q: JSON.stringify({
        data: {
          action: 'add_import_challan_data',
          value: {
            challanType: challanTypes
          }
        }
      })
    }
    this.spinner.show();
    this.commonsService.getCommonEntites(req_Data).subscribe(res => {
      const challans: any = [];
      if (res && res.add_import_challan_data && res.add_import_challan_data !== 'Data Not Exists') {
        if (res && res.add_import_challan_data && res.add_import_challan_data.length) {
          //new code
          const challanDatas: any = res.add_import_challan_data.filter(el => el.addChallan);
          if (challanDatas.length === res.add_import_challan_data.length) {
            this.addChallanData(challanDatas);
          } else {
            res.add_import_challan_data.forEach(challanData => {
              if (challanData && challanData.challanNumber) {
                const feeAmount = this.getFeeAmount(challanData.challanType);
                if (feeAmount > challanData.remainingAmount) {
                  challans.push({challanNumber: challanData.challanNumber,FeeAmount: (feeAmount - challanData.remainingAmount), challanType: challanData.challanType});
                }
              }
            });
            if (challans.length) {
              this.checkImportChallans(challans);
            } else {
              this.setChallanDatas(res.add_import_challan_data);
            }
          }
        }
      }

      // if (isAddChallan && res.add_import_challan_data.addChallan) {
      //   this.addChallanData(res.add_import_challan_data);
      // } else {
      //   if (res && res.add_import_challan_data && res.add_import_challan_data.length) {
      //     const challanData: any = res.add_import_challan_data.find(el => el.challanType === 'CVD');
      //     if (challanData && challanData.challanNumber) {
      //       if (this.importIndentForm.value.cvdFeeAmount > challanData.remainingAmount) {
      //         this.checkImportChallans(challanData.challanNumber);
      //       } else {
      //         this.setChallanDatas(res.add_import_challan_data);
      //       }
      //     }
      //   }
      // }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }
  
  // old code // challanNumber
  checkImportChallans(challans: any): any {
    const req_Data: any = {
      // old code
      // action: 'common_apis',
      // q: '{"data": {"action": "check_import_challans", "value": {"challanNumber":"'+ challanNumber +'", "cvdFeeAmount":'+ this.importIndentForm.value.cvdFeeAmount +'}}}'
      
      // new code
      action: 'common_apis',
      q: JSON.stringify({
        data: {
          action: 'check_import_challans',
          value: challans
        }
      })
    }
    this.spinner.show();
    this.commonsService.getCommonEntites(req_Data).subscribe(res => {
      //old code
      // if (res && res.check_import_challans && res.check_import_challans.addChallan) {
      //   this.addChallanData(res.check_import_challans);
      // }

      //new code
      if (res && res.check_import_challans && res.check_import_challans === 'Data Not Exists') {
        this.messageService.add({ severity: 'error', detail: 'Challans not found' });
      } else {
        if (res && res.check_import_challans && res.check_import_challans.length) {
          const challanDatas: any = res.check_import_challans.filter(el => el.addChallan);
          this.addChallanData(challanDatas);
        }
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }
  addChallanData(data: any): any {
    const req_Data: any = {
      //old code
      // action:'add_ip_challan',
      // data: data

      //new  code
      action:'add_ip_challan',
      data: {
        datas: data,
        multipleChallans: true,
        addChallans: true
      }
    }
    this.spinner.show();
    this.shipmentsService.postMethod(req_Data).subscribe((res: any) => {
      if (res && res.status && res.status === 'SUCCESS') {
        this.getImportChallanData();
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }
  setChallanDatas(challanDatas: any): any {
    this.challanDatas = challanDatas;
    challanDatas.forEach((challan: any) => {
      if (challan.challanType === 'CVD') {
        this.cvdChallaData.push(challan)
        this.cvdData = this.cvdChallaData;
        this.importIndentForm.controls.cvdOpening.setValue((challan.remainingAmount).toFixed(2));
      } else if (challan.challanType === 'Import Fee') {
        this.ifChallanData.push(challan)
        this.importFeeData = this.ifChallanData;
        this.importIndentForm.controls.importFeeOpening.setValue((challan.remainingAmount).toFixed(2));

      } else if (challan.challanType === 'EAL') {
        this.ealChallanData.push(challan)
        this.ealData = this.ealChallanData;
        this.importIndentForm.controls.ealOpening.setValue((challan.remainingAmount).toFixed(2));

      } else if (challan.challanType === 'TTS') {
        this.ttsChallanData.push(challan);
        this.ttsData = this.ttsChallanData;
        this.importIndentForm.controls.ttsOpening.setValue((challan.remainingAmount).toFixed(2));
      }
    });
  }
  getRoutes(depotCode:any){
    let paramObj = {
      action: 'common_apis',
      q: '{"data": {"action": "get_routes","value": "'+this.entityType+'"}}'
    }
    this.commonsService.getRoutes(paramObj).subscribe(result => {
      // this.routeList = result.get_routes;
      result.get_routes.forEach((route:any)=>{
        if(route.destinationLocation === depotCode){
         
          this.routeList.push(route)
          this.routes = this.routeList.map(route => route.routeVia);
          this.filteredRoutes.next(this.routes.slice());
        }
      })

    })
  }

getDepot(){
  let paramObj = '?action=common_apis&q={"data": ["get_dist_depot","get_route_src"]}';
  this.commonsService.getList(paramObj).subscribe(data =>  {
    this.depotNames = data['get_dist_depot']['depots'];
    this.filteredDepotNames.next(this.depotNames.slice());

  })
  this.ifsDepotFilterCtrl.valueChanges
  .pipe(takeUntil(this._onDestroy))
  .subscribe(() => {
    this.filterDepots();
  });
}

filterDepots() {
  if (!this.depotNames || (this.depotNames && this.depotNames.length == 0)) {
    return;
  }
  // get the search keyword
  let search = this.ifsDepotFilterCtrl.value;
  if (!search) {
    this.filteredDepotNames.next(this.depotNames.slice());
    return;
  } else {
    search = search.toLowerCase();
  }
  // filter the banks
  this.filteredDepotNames.next(
    this.depotNames.filter(name => name.toLowerCase().indexOf(search) > -1)
  );
}

getChallans(){
    this.spinner.show();
    const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).mod_feats;
    this.features = userModules['Shipment'].features;
    this.shipmentsService.getShipmentsFeatures().subscribe(res => {
      this.featuresData = res;
      if(this.featuresData['Add Challan']) {
        this.featuresData['Add Challan'].forEach(challan => {
          if(challan.challanType === 'CVD'){
            this.cvdChallaData.push(challan)
            this.cvdData = this.cvdChallaData;
            this.importIndentForm.controls.cvdOpening.setValue((challan.remainingAmount).toFixed(2));
          }else if(challan.challanType === 'Import Fee'){
            this.ifChallanData.push(challan)
            this.importFeeData = this.ifChallanData;
            this.importIndentForm.controls.importFeeOpening.setValue((challan.remainingAmount).toFixed(2));

          }else if(challan.challanType === 'EAL'){
            this.ealChallanData.push(challan)
            this.ealData = this.ealChallanData;
            this.importIndentForm.controls.ealOpening.setValue((challan.remainingAmount).toFixed(2));

          } else if(challan.challanType === 'TTS'){
            this.ttsChallanData.push(challan);
            this.ttsData = this.ttsChallanData;
            this.importIndentForm.controls.ttsOpening.setValue((challan.remainingAmount).toFixed(2));
          }
        });
      }
      this.spinner.hide();
    })

}

  getProducts(){
    let qData = {
      "data": { "value": this.entityType, "action": "get_supplier_brands" }
    };
    const req_Data = {
      "action": "common_apis",
      "q": JSON.stringify(qData)
    };
    this.commonsService.getCommonEntites(req_Data).subscribe((res) => {
    this.productsList =  res['get_supplier_brands'];
    this.productsData = this.productsList;
    })
  }
  isAllCvdSelected() {
    const cvdSelected = this.cvdSelection.selected.length;
    const numRows = this.cvdChallaData.length;
    return cvdSelected === numRows;
  }
   /** Selects all rows if they are not all selected; otherwise clear selection. */
   masterCvdToggle() {
    this.isAllCvdSelected() ?
      this.cvdSelection.clear() :
      this.cvdChallaData.forEach(row => this.cvdSelection.select(row));
  }

  isAllIfSelected() {
    const ifSelected = this.ifSelection.selected.length;
    const numRows = this.ifChallanData.length;
    return ifSelected === numRows;
  }
   /** Selects all rows if they are not all selected; otherwise clear selection. */
   masterIfToggle() {
    this.isAllIfSelected() ?
      this.ifSelection.clear() :
      this.ifChallanData.forEach(row => this.ifSelection.select(row));
  }

  isAllEalSelected() {
    const ealSelected = this.EalSelection.selected.length;
    const numRows = this.ealChallanData.length;
    return ealSelected === numRows;
  }
  isAllTTSSelected() {
    const ttsSelected = this.ttsSelection.selected.length;
    const numRows = this.ttsChallanData.length;
    return ttsSelected === numRows;
  }
  masterTTSToggle() {
    this.isAllTTSSelected() ?
      this.ttsSelection.clear() :
      this.ttsChallanData.forEach(row => this.ttsSelection.select(row));
  }
   /** Selects all rows if they are not all selected; otherwise clear selection. */
   masterEalToggle() {
    this.isAllEalSelected() ?
      this.EalSelection.clear() :
      this.ealChallanData.forEach(row => this.EalSelection.select(row));
  }


  isAllProductSelected() {
    const productSelected = this.productSelection.selected.length;
    const numRows = this.productsList.length;
    return productSelected === numRows;
  }
   /** Selects all rows if they are not all selected; otherwise clear selection. */
   masterProductToggle() {
    this.isAllProductSelected() ?
      this.productSelection.clear() :
      this.productsList.forEach(row => this.productSelection.select(row));
  }
  saveImport(){
    this.loader = true;
    let isQuantity;
    if(this.cvdSelection['_selected'] == undefined || this.cvdSelection['_selected'] == null){
      this.cvdSelection['_selected'] = [];
    }
    if(this.ifSelection['_selected'] == undefined || this.ifSelection['_selected'] == null){
      this.ifSelection['_selected'] = [];
    }
    if(this.EalSelection['_selected'] == undefined || this.EalSelection['_selected'] == null){
      this.EalSelection['_selected'] = [];
    }
    if(this.productSelection['_selected'] == undefined || this.productSelection['_selected'] == null){
      this.messageService.add({ severity: 'error', detail: 'Please Select Products' });
      this.loader = false;
      return
    }
    let data;
    data = this.importIndentForm.value;
    data.indentId = this.refData.indentId;
    // let listOfChallans:any=[];
    // if(!this.selectedCvdChallans.length|| !this.selectedimportFeeChallans.length || !this.selectedEalChallans.length){
    //   this.messageService.add({ severity: 'error', detail: 'Please select challans'});
    //   return;

    // }
    // listOfChallans = [...this.selectedCvdChallans,...this.selectedimportFeeChallans,...this.selectedEalChallans]
    // const allChallans = [].concat(...listOfChallans);
    // console.log(allChallans)
    // data.challanDetails = allChallans;
    // let cvdAmount:any =0;
    // let importAmount:any =0;
    // let ealAmount:any =0;
    // let ttsAmount:any=0;
    // data.challanDetails.forEach((item:any)=>{
    //   if(item.challanType === 'CVD'){
    //     cvdAmount += item.remainingAmount;
    //   }else if(item.challanType ==='Import Fee'){
    //     importAmount += item.remainingAmount;
    //   }else if(item.challanType ==='EAL'){
    //     ealAmount += item.remainingAmount;
    //   }else if(item.challanType ==='TTS'){
    //     ttsAmount += item.remainingAmount;
    //   }
    // })
    // console.log(cvdAmount , this.importIndentForm.value.cvdFeeAmount, cvdAmount < this.importIndentForm.value.cvdFeeAmount)
    // console.log(importAmount , this.importIndentForm.value.importFeeAmount,importAmount < this.importIndentForm.value.importFeeAmount)
    // if(cvdAmount < this.importIndentForm.value.cvdFeeAmount){
    //   this.messageService.add({ severity: 'error', detail: 'CVD challan amount should not be less than CVD amount'});
    //   return;
    // }else if(importAmount < this.importIndentForm.value.importFeeAmount){
    //   this.messageService.add({ severity: 'error', detail: 'Import Fee challan amount should not be less than Import fee'});
    //   return;
    // }else if(ealAmount < this.importIndentForm.value.healFeeAmount && this.roleName === 'NonLocal Supplier Manager'){
    //   this.messageService.add({ severity: 'error', detail: 'EAL challan amount should not be less than EAL amount'});
    //   return;
    // }else if(ttsAmount < this.importIndentForm.value.ttsFeeAmount && this.roleName === 'NonLocal Brewery Manager'){
    //   this.messageService.add({ severity: 'error', detail: 'TTS challan amount should not be less than TTS amount'});
    //   return;
    // }
    // data.challansData = [...this.cvdSelection['_selected'],...this.ifSelection['_selected'],...this.EalSelection['_selected']];
    data.challanDetails = this.challanDatas;
    const distilleryCode =JSON.parse(sessionStorage.getItem('lappCurrentUserModules'))?.distilleryCode ?
    JSON.parse(sessionStorage.getItem('lappCurrentUserModules'))?.distilleryCode  : '-'
    data.distilleryCode = distilleryCode
    if(this.productSelection['_selected']){
      this.productSelection['_selected'].forEach((item:any,i)=>{
        delete item.taxes
        delete item.status
        delete item.updatedDateTime
        delete item.effectiveDate
       
          item.casePrice = this.refData.ofsType === 'Non Local Supplier' ? 
          ((item.basicPricing[0] ? item.basicPricing[0].price : 0) - (((item?.size * item?.unitsPerCase)/1000)*2) - (item?.unitsPerCase * 0.3)) :
          ((item.basicPricing[0] ? item.basicPricing[0].price : 0) - (((item?.size * item?.unitsPerCase)/1000)*2))
        
        // item.casePrice = (((item.basicPricing[0] ? item.basicPricing[0].price : 0) - (item.pricing[0] ? item.pricing[0].importFee :0) + item.unitsPerCase) * 0.3);
        item.value = (item.requestedQty ? item.requestedQty :0) * (((item.basicPricing[0] ? item.basicPricing[0].price : 0) - (item.pricing[0] ? item.pricing[0].importFee :0) + item.unitsPerCase) * 0.3);
        if(!isQuantity &&(!item.requestedQty || (item.requestedQty == '' || item.requestedQty == undefined || item.requestedQty == null))){
          isQuantity = true;
        }
        // delete item.basicPricing
        // delete item.pricing
      })
    }
    if(isQuantity){
      this.messageService.add({ severity: 'error', detail: 'Please Enter quantity for selected Products' });
      return
    }
    data.productDetails = this.productSelection['_selected']
    const req_Data:any={
      action:'create_ip_ifs',
      data:data
    }
    console.log(req_Data)
    this.spinner.show();
    // delete req_Data.data.cvdChallanNUmbers;
    // delete req_Data.data.importChallanNUmbers;
    // delete req_Data.data.ealChallanNUmbers;
    // delete req_Data.data.ttsChallanNUmbers
    req_Data.data.productDetails.forEach((prod: any) => {
      if (prod.healFee) {
        delete prod.healFee;
      }
      if (prod.ttsFee) {
        delete prod.ttsFee
      }
    });
    this.shipmentsService.createICDC(req_Data).subscribe((res:any)=>{
      if(res.status ==='SUCCESS'){
        this.dialogRef.close('OFS Created Successfully');
      }else if(res.error){
        this.messageService.add({ severity: 'error', detail: res.error });
      }else{
        this.messageService.add({ severity: 'error', detail: 'OFS Creation failed' });
      }
      this.spinner.hide();
      this.loader = false;
    },(err)=>{
      this.messageService.add({ severity: 'error', detail: err.status });
      this.loader = false;
    })
    
  }
  updateIndent(status:any){
    const req_Data:any={
      action:status === 'isApprove' ? 'approve_ifs' : (this.data.ipType === 'Import Permit' ? 'rejected_ip_ifs' : 'reject_ifs'),
      data:this.data
    }
    this.loader = true;
    this.importService.updateIndent(req_Data).subscribe((res:any)=>{
      if(res.status ==='SUCCESS'){
        this.dialogRef.close('Indent Updated Successfully');
        this.messageService.add({severity: "success",summary: "Success",detail: "Indent Updated Successfully",
        });
        this.loader = true;
      }else{
        this.messageService.add({ severity: 'error', detail: 'Indent Updation failed' });
        this.loader = true;
      }
    },(err)=>{
      this.messageService.add({ severity: 'error', detail: 'Indent Updation failed' });
      this.loader = true;
    })
  }



  quantityCheck(i) {
    if (this.data.productDetails[i]["requestedQty"]) {
      return (
        this.data.productDetails[i]["requestedQty"] <
        this.data.productDetails[i]["approvedQty"]
      );
    }
  }

  changeQuantity(i, e,row) {
    if (this.data.productDetails[i].requestedQty) {
      this.data.productDetails[i].approvedQty = parseInt(e.target.value);
      this.data.productDetails[i].value = (row.approvedQty ? row.approvedQty :0) * (((row.basicPricing[0] ? row.basicPricing[0].price : 0) - (row.pricing[0] ? row.pricing[0].importFee :0) + row.unitsPerCase) * 0.3)

    }
  }

  checkApprovedQty(status) {
    if (
      this.data.productDetails.some((product) =>product.approvedQty <= product.requestedQty)) {
      this.updateIndent(status);
    } else {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Enter valid quantity",
      });
    }
  }

  doPrint(id): any {
    this.printId = id;
    this.showPrint = true;
    this.getPrint(id);
  }
  getPrint(id): any {
    let printContents;
    let popupWin;
    setTimeout(() => {
      if (document.getElementById(id)) {
        printContents = document.getElementById(id).innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.write(`${printContents}`);
        popupWin.document.close();
        this.showPrint = false;
      }
    }, 500);
  }


  selectCvd(e:any){
    this.selectedCvdChallans=[]
    this.selectedCvdChallans.push(e.value)
    console.log(this.selectedCvdChallans)
  }

  selectImport(e:any){
    this.selectedimportFeeChallans=[]
    this.selectedimportFeeChallans.push(e.value)
    console.log(this.selectedimportFeeChallans)
  }

  selectEal(e:any){
    this.selectedEalChallans=[]
    this.selectedEalChallans.push(e.value)
    console.log(this.selectedEalChallans)
  }

  selectTts(e:any){
    this.selectedTtsChallans=[]
    this.selectedTtsChallans.push(e.value)
    console.log(this.selectedTtsChallans)
  }

}
