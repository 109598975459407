import { ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { CommonsService } from 'src/app/core/services/commons.service';
import { ShipmentsService } from 'src/app/core/services/shipments.service';


@Component({
  selector: 'app-add-products-dialog',
  templateUrl: './add-products-dialog.component.html',
  styleUrls: ['./add-products-dialog.component.scss']
})
export class AddProductsDialogComponent implements OnInit {
  form: FormGroup;
  loader: any = false;
  public filteredProducts: ReplaySubject<any> = new ReplaySubject<any>(1);
  public productFitlerCtrl: FormControl = new FormControl();
  public filteredRetailers: ReplaySubject<any> = new ReplaySubject<any>(1);
  public retailerFitlerCtrl: FormControl = new FormControl();
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public _onDestroy = new Subject<void>();
  displayedColumns: string[] = ['no', 'productCode', 'bottles', 'cases', 'quantity', 'rationQuantity'];
  @ViewChild('productSelect') productSelect: MatSelect;
  @ViewChild('retailerSelect') retailerSelect: MatSelect;
  @ViewChild('dialogRefTemplate') dialogRefTemplate: TemplateRef<any>;
  products: any = [];
  productDataList: any = [];
  productList: any;
  brandList: any;
  dialogRef1: MatDialogRef<any, any>;
  sizeList: any = [];
  productsList:any=[];
  productListData:any=[];
  userInfo: any = {};
  productDetails: FormArray;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddProductsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private shipmentService: ShipmentsService,
    private commonService: CommonsService,
    private spinner: NgxSpinnerService,
    public util:UtilService,
    private messageService:MessageService,
    private myElement: ElementRef
  ) {
    this.userInfo = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
  }

  ngOnInit(): void {
    console.log('data==>', this.data);
    this.getProducts();
    this.form = this.fb.group({
      tpNo: [this.data.tpNo, Validators.required],
      depotCode: [this.data.depotCode, Validators.required],
      destnEntityId: [this.data.destnEntityId, Validators.required],
      icdcCode: [this.data.icdcCode, Validators.required],
      indentId: [this.data.indentId, Validators.required],
      indentType: [this.data.indentType, Validators.required],
      retailerCode: [this.data.retailerCode, Validators.required],
      productDetails: this.fb.array([this.updateProductDetails()]),
    });
    this.productDetails = this.form.get('productDetails') as FormArray;
    this.productFitlerCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProducts();
    });
  }
  updateProductDetails() {
    if (this.data.indentType === 'bottles_indent') {
      return this.fb.group({
        brandCode: ['', Validators.required],
        productCode: ['', Validators.required],
        unitsPerCase: ['', Validators.required],
        size: ['', Validators.required],
        casesCount: 0,
        approvedBottles: ['', Validators.required],
        onlineClosingBalBottles: ['', Validators.required],
        value: 0,
        liquorType: '',
        mrp: 0
      });
    } else {
      return this.fb.group({
        brandCode: ['', Validators.required],
        productCode: ['', Validators.required],
        unitsPerCase: ['', Validators.required],
        size: ['', Validators.required],
        casesCount: 0,
        approvedCases: ['', Validators.required],
        onlineClosingBalCases: ['', Validators.required],
        value: 0,
        liquorType: '',
        mrp: 0
      });
    }
  }
  get f() {
    return this.form.controls;
  }

  add() {
    const control = <FormArray>this.form.controls['productDetails'];
    control.push(this.updateProductDetails());
  }

  remove(i: number) {
    const control = <FormArray>this.form.controls['productDetails'];
    control.removeAt(i);
  }

  filterProducts() {
    if (!this.brandList || (this.brandList && this.brandList.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.productFitlerCtrl.value;
    if (!search) {
      this.filteredProducts.next(this.brandList);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredProducts.next(
      this.brandList.filter(product => JSON.stringify(product).toLowerCase().indexOf(search) > -1)
    );
  }
  getProducts() {
    this.spinner.show();
    let req_Data: any = {};
    req_Data = {
      "action": "common_apis",
      "q": '{"data": ["get_depot_stock", "get_depot_stock_bottle"]}'
    };
    this.commonService.getCommonEntites(req_Data).subscribe((res: any) => {
      // if (!res['get_depot_stock'] && !res['get_depot_stock_bottle']) {
      //   this.spinner.hide();
      //   return;
      // }
      if (this.data.indentType === 'bottles_indent') {
        this.productList = res['get_depot_stock_bottle'];
      } else {
        this.productList = res['get_depot_stock'];
      }
      this.brandList = this.productList.map(res => res.brandCode);
      this.products = this.productList.map(res => res.productCode);
      this.products = this.products.filter((res, i) => {
        return i == this.products.lastIndexOf(res);
      });
      this.brandList = this.brandList.filter((res, i) => {
        return i == this.brandList.lastIndexOf(res);
      });
      this.filteredProducts.next(this.brandList);
      this.brandList.forEach(res => {
        if (this.brandList.indexOf(res.brandName) !== -1 && this.sizeList.indexOf(res.size) == -1) {
          this.sizeList.push(res.size);
        }
      });
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }
  create() {
    const req_Data = {
      action: 'add_new_products',
      data: this.form.value
    }

    req_Data.data.productDetails.forEach((element, i) => {
      const product: any = this.productList.find(el => el.productCode === element.productCode);
      if (product) {
        element.requestedCases = element.approvedCases;
        element.casesCountNumber = 0;
        req_Data.data.productDetails[i] = {...product, ...element};
        delete req_Data.data.productDetails[i].closingBalBottles;
        delete req_Data.data.productDetails[i].closingBalCases;
        delete req_Data.data.productDetails[i].ctelClosingBalBottles;
        delete req_Data.data.productDetails[i].ctelClosingBalCases;
        delete req_Data.data.productDetails[i].ctelOnlineClosingBottles;
        delete req_Data.data.productDetails[i].ctelOnlineClosingCases;
        delete req_Data.data.productDetails[i].onlineClosingBalBottles;
        delete req_Data.data.productDetails[i].onlineClosingBalCases;
      }
    });
    this.loader = true;
    this.spinner.show();
    this.shipmentService.updateShipment(req_Data).subscribe((res: any) => {
      if(res.status && res.status === 'SUCCESS'){
        this.dialogRef.close(`Products Added Successfully`);
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Indent Creation Failed' });
        this.loader = false;
      }
      this.spinner.hide();
      this.loader = false;
    }, (err) => {
      this.loader = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
      this.spinner.hide();
    });
  }
  selectBrand(i, evt: any) {
    this.productListData[i] = [];
    const brandCode = evt.value;
    this.productList.forEach(el => {
      if (brandCode == el.brandCode ) {
        this.productListData[i].push(el.productCode);
      }
    });
  }
  selectProduct(i): any {
    const productDetails = this.form.get('productDetails') as FormArray;
    const productDetailsControls = productDetails.controls[i]['controls'];
    const productData: any = this.util.productCodeToDetails(productDetailsControls.productCode.value);
    productDetailsControls.unitsPerCase.setValue(productData.unitsPerCase);
    productDetailsControls.size.setValue(this.util.getSizeInMl(productData.size));
    const liquorType = this.util.getSegmentByType(productData.liquorType);
    productDetailsControls.liquorType.setValue(liquorType);
    const product: any = this.productList.find(el => el.productCode === productDetailsControls.productCode.value);
    if (product) {
      const mrp = product.mrp ? product.mrp : product.retailPricings ? product.retailPricings : 0;
      productDetailsControls.mrp.setValue(mrp);
      if (this.data.indentType === 'bottles_indent') {
        productDetailsControls.onlineClosingBalBottles.setValue(product.onlineClosingBalBottles);
      } else {
        productDetailsControls.onlineClosingBalCases.setValue(product.onlineClosingBalCases);
      }
    }      
  }
  isDisabled(value) {
    const productDetails = this.form.get('productDetails') as FormArray;
    let status = false;
    if (this.data.productDetails && this.data.productDetails.length) {
      status = this.data.productDetails.some((el: any) => el.productCode === value);
    }
    if (status === false) {
      status = productDetails.controls.some((el: any) => el.controls.productCode.value === value);
    }
    return status;
  }
  validateMin(i, controlName): any {
    const productDetails = this.form.get('productDetails') as FormArray;
    const stock = productDetails.controls[i]['controls'][controlName];
    if (stock.value == 0) {
      stock.setValue('');
    }
  }
  isDisableBtn() {
    const productDetails = this.form.get('productDetails') as FormArray;
    return productDetails.controls.some((el: any) => el.controls.approvedCases.value > el.controls.onlineClosingBalCases.value);
  }
  setValue(controlName, i): any {
    const productDetails = this.form.get('productDetails') as FormArray;
    const values = productDetails.controls[i]['value'];
    const prodValueCtrl = productDetails.controls[i]['controls']['value'];
    if (controlName === 'approvedCases') {
      const prodValue = ((values.approvedCases ? values.approvedCases : 0) * values.unitsPerCase) * values.mrp;
      prodValueCtrl.setValue(prodValue);
    } else if (controlName === 'approvedBottles') {
      const prodValue = (values.approvedBottles ? values.approvedBottles : 0) * values.mrp;
      prodValueCtrl.setValue(prodValue);
    }
  }
}

