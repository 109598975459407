import { ComponentInstanceService } from './../../utils/component-instance';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, matDialogAnimations } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { APBCL } from 'src/app/core/models/users/apbcl.model';
import { UserManualService } from 'src/app/core/services/user-manual.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { featureRules } from '../../utils/feature-rules';
import { CommonsService } from './../../services/commons.service';
import { FormControl } from '@angular/forms';
import { DynamicConfirmationDialogComponent } from '../dynamic-confirmation-dialog/dynamic-confirmation-dialog.component';
import { SharedServiceService } from '../../services/shared-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare const jsPDF: any;
declare const html2canvas: any;

@Component({
  selector: 'app-static-table',
  templateUrl: './static-table.component.html',
  styleUrls: ['./static-table.component.scss']
})
export class StaticTableComponent implements OnInit {

  @Input() writeAccess: boolean;
  @Input() actions: string[];
  @Input() featureName: string;
  @Output() refreshPage = new EventEmitter<boolean>();
  @Input() featuresData: any;
  @Input() moduleName: string;
  @Input() nodeType:string =  '';
  @Output() resetFilter = new EventEmitter<any>();
  @Input() showDateFilter: any;
  @Input() showStatusFilter: any;
  @Input() showDepotFilter: any;
  @Output() depotFilter = new EventEmitter<any>();
  @Input() dynamicFilter: any;
  @Output() dynamicFilterEve = new EventEmitter<any>();
  @Input() showRefresh: any = false;
  @Input() showBulkAction:any;
  @Input() activeTab:any;
  displayedColumns: Array<string>;
  columns: Array<any>;
  dataSource: MatTableDataSource<APBCL> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;
  tableLength: number;
  showCreate: boolean = false;
  showCreate2: boolean = false;
  createModal: any;
  createModal2: any;
  rules: any;
  userDetails: any;
  error: string;
  isSearch:boolean;
  selection = new SelectionModel<any>(true, []);
  fromDate: any;
  toDate: any;
  today: any = new Date();
  healStatus: any ='';
  statusList: any =[];
  selectedStatus: any = '';
  depots: any = [];
  selectedDepot: any = '';
  s3Url: any;
  filters: any = {
    fromDate: '',
    toDate: ''
  };
  suppliers: any = [];
  allSuppliers: any = [];
  selectedItems:any=[];


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;
  // public filterCtrl: FormControl = new FormControl();
  filterCtrls: any = {};

  customPrint: any = {};


  constructor(
    public util: UtilService,
    public featureRules: featureRules,
    private usersService: UserManualService,
    private dialog: MatDialog,
    private messageService: MessageService,
    private componentInstance: ComponentInstanceService,
    private commonsService: CommonsService,
    private SharedServiceService:SharedServiceService,
    private spinner:NgxSpinnerService
  ) { this.userDetails = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')) }

  ngOnInit(): void {
    this.limit = 10;
    this.offset = 0;
    this.s3Url = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).s3_url;
    this.rules = this.featureRules[`${this.moduleName.replace(/-|\s/g, '')}${this.featureName.replace(/-|'|\s/g, '')}`];
    if (!this.rules) {
      return;
    }
    if(this.userDetails.role === 'Excise Heals Inspector'){
      this.statusList =['Approved', 'Completed'];
    }else{
      this.statusList =['All','Approved','Completed','Dispatched','Received','Reviewed'];
    }
    this.columns = this.rules.columnsToDisplay[this.userDetails["role"]] ? this.rules.columnsToDisplay[this.userDetails["role"]] : this.rules.columnsToDisplay[Object.keys(this.rules.columnsToDisplay)[0]];
    this.createModal = this.rules.createRecord[this.userDetails["role"]];
    this.createModal2 = this.rules.createRecord2 ? this.rules.createRecord2[this.userDetails.role] : '';
    if (this.createModal2) {
      this.showCreate2 = true;
    }
    this.displayedColumns = this.columns.map(col => col.key);
    this.getTableDetails();
    if (this.showDepotFilter) {
      this.getDepots();
    }
    if (this.dynamicFilter && this.dynamicFilter.length) {
      this.dynamicFilter.forEach(el => {
        if (el.dynamic === true) {
          this.filterCtrls[el.key] = new FormControl();
        }
        if (el.dynamic === true && el.key === 'supplierCode') {
          const action: any = el.action ? el.action : '';
          this.getSuppliers(action);
        }
        const datas = {};
        datas[el.key] = ''; 
        this.filters = {...this.filters, ...datas};
      });
    }
  }

  ngOnChanges(): void {
    this.getTableDetails();
  }

  createRecord() {
    console.log(this.createModal);
    let data: any = this.createModal.tab ? {tabName: this.createModal.tab} : null;
    if ((this.moduleName === 'Products' || this.moduleName === 'Release Notes' || this.moduleName === 'Excise Licensee' || this.moduleName === 'Feature Permissions') && data === null) {
      data = {};
      data.data = this.featuresData;
      data.featureName = this.featureName;
      data.moduleName = this.moduleName;
    }
    
    this.util.openModalDialog(this.componentInstance.getComponent(this.createModal.component), data, this.createModal.width, this.createModal.height, false, this.refreshPage, this.getTableDetails.bind(this));
  }
  createRecord2() {
    console.log(this.createModal2);
    let data = this.createModal2.tab ? {tabName: this.createModal2.tab} : null;
    console.log(data);
    
    this.util.openModalDialog(this.componentInstance.getComponent(this.createModal2.component), data, this.createModal2.width, this.createModal2.height, false, this.refreshPage, this.getTableDetails.bind(this));
  }

  getModel(action, column){
    return column.modal.filter(modal => modal.actionLink === action)[0];
  }

  editRecord(data, modal, colName) {
    const modalDetails = modal ? modal : this.createModal ? this.createModal : '';
    
    if (colName) {
      data['colName'] = colName;
    }

    if (modalDetails.tab) {
      data['tabName'] = modalDetails.tab;
    }

    if((modalDetails.tab === 'documents' && data.status === 'InActive') || (modalDetails.tab === 'exeHeals' && data.status !== 'Approved')){
      return false;
    }
    if (modalDetails && this.fromDate==undefined) {
      this.util.openModalDialog(this.componentInstance.getComponent(modalDetails.component), data, modalDetails.width, modalDetails.height, false, this.refreshPage, this.getTableDetails.bind(this), modalDetails.actionLink);
    }
    if(modalDetails && this.fromDate!==undefined){
      this.util.openModalDialog(this.componentInstance.getComponent(modalDetails.component), data, modalDetails.width, modalDetails.height, false, this.getFilterByDate.bind(this), this.getTableDetails.bind(this), modalDetails.actionLink);
    }
  }

  isArray(item) {
   return Array.isArray(item);
  }
  getDistillerires(): any {
    const paramObj: any = '?action=common_apis&q={"data": ["get_dist_depot"]}';
    this.commonsService.getList(paramObj).subscribe(data => {
      // this.distilleryNames = data['get_dist_depot']['distilleries'];
      // this.allDistilleries = data['get_dist_depot']['all_distilleries'];
    });
  }

  getTableDetails() {
    if (this.showDateFilter && (!this.featuresData || this.featuresData.length === 0)) {
      this.dataSource = new MatTableDataSource([]);
    }
    if ((!this.isSearch) && this.featuresData && this.featuresData.length > 0) {
      this.dataSource = new MatTableDataSource(this.featuresData);
      this.tableLength = this.featuresData.length;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    if(this.nodeType ===  'Sublease' &&( this.featureName === 'Tanks' || this.featureName === 'Bottling Lines' )){
      this.showCreate = false;
    }else{
      this.showCreate = !!this.createModal;
    }
  }

  ngAfterViewInit() {
    this.util.ngAfterViewInit(this.dataSource, this.paginator, this.sort, this.input.nativeElement, this.getTableDetails.bind(this));
  }

  applyFilter(searchFlag: boolean) {
    this.isSearch = searchFlag;
    this.util.applyFilter(this.dataSource, this.search);
  }

  convertToDate(date) {
    return date ? moment(date).format('DD/MM/YY, h:mm a') : '-';
  }
  convertToDateOnly(date) {
    return moment(date).format('DD/MM/YY');
  }

  onPaginateChange(e) {
    this.limit = e.pageSize;
    this.offset = e.pageIndex * e.pageSize;
    this.getTableDetails();
  }

  // isAllSelected() {
  //   const numSelected = this.selection.selected.length;
  //   const numRows = this.dataSource.data.length;
  //   this.dataSource.paginator = this.paginator;
  //   return numSelected === numRows;
  // }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  // masterToggle() {
  //   this.isAllSelected() ?
  //     this.selection.clear() :
  //     this.dataSource.data.forEach(row => this.selection.select(row));
  // }

  isAllSelected() {
    const selectedAll = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return selectedAll === numRows;
  }
   /** Selects all rows if they are not all selected; otherwise clear selection. */
   masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }
  checkSelection(){
    if(this.selection){
      return this.selection['_selected'] === null; 
    }
  }
  updateBulkIndents(mode:any){
      this.checkSelectedData();
      if(this.selectedItems && this.selectedItems.length > 0){
        const data: any = {};
        data.content = `${mode} Indent(s)?`;
        const dialogRef = this.dialog.open(DynamicConfirmationDialogComponent, {
          width: '25%',
          data:data,
          autoFocus: false
        });
        dialogRef.afterClosed().subscribe((result) => {
          console.log(`Dialog result: ${result.message}`);
          if (result.message === 'Success') {
            if(this.activeTab === 'Distillery Indent'){
              const reqData:any ={
                action:mode === 'Approved' ? 'approve_ifs' : 'reject_ifs',
                data:this.selectedItems
              }
              reqData.data.forEach((data:any)=>{
                data.productDetails.map((prod:any)=>{
                  prod.approvedQty = Number(prod.requestedQty);
                  return prod;
                })
              })
              let req_Data: any = '';
              req_Data = {
                action: 'get_distillery_indent',
                q: JSON.stringify(this.util.getEmptyFromToDate())
              };
             this.spinner.show();
              this.SharedServiceService.updateIndents(reqData).subscribe((res:any)=>{
                console.log(res)
                if(res.status === 'SUCCESS'){
                  this.messageService.add({ severity: 'success', summary: 'Success', detail: `${mode} Successfully` });
                  this.SharedServiceService.getIndentManagementFeatures(req_Data).subscribe((res:any)=>{
                    console.log(res)
                    this.featuresData = res['Distillery Indent'];
                  })
                  this.getTableDetails();
                  this.refresh();
                  this.selection.clear();
                  this.dataSource = new MatTableDataSource(this.featuresData);
                  this.spinner.hide();
                }else{
                  this.messageService.add({ severity: 'error', summary: 'Error', detail: `Updation failed` });
                  this.spinner.hide();
                }
              },(err)=>{
                this.spinner.hide();
                this.messageService.add({ severity: 'error', summary: 'Error', detail: `Updation failed` });
              })
            }
          }
        });
      }
  }

  checkSelectedData(){
    this.selectedItems = this.selection['_selected'];
    if(this.selectedItems && this.selectedItems.length > 0){
      this.selectedItems = this.selectedItems.filter(item => item.status === 'Created');
      this.selectedItems.map((key:any) => {
        key.validityDate = this.util.getValidityDate()
      });
    }else if(this.selectedItems === null || this.selectedItems === undefined || this.selectedItems.length === 0){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: `Please select records` });
      return;
    }
  }

  copy(val: String): any {
    const selBox: any = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.messageService.add({ severity: 'success', summary: 'Copy Text', detail: `Copied` });
  }
  clearFilters(): any {
    this.dataSource.filter = '';
    this.search = '';
    this.isSearch = false;
    this.selection.clear();
  }
  getFilterByDate(): any {
    this.clearFilters();
    const datas: any = {fromDate: this.util.getDateOnly(this.fromDate), toDate: this.util.getDateOnly(this.toDate)};
    if(this.featureName === 'Distillery' || this.featureName === 'Depot'){
      datas['status'] = this.healStatus;
    }
    this.filters = {...this.filters, ...datas};
    this.resetFilter.emit(this.filters);
  }
  getFilterByStatus(): any {
    this.clearFilters();
    const datas: any = {};
    datas.status = this.selectedStatus;
    this.resetFilter.emit(datas);
  }
  getFilterByDepot(): any {
    this.clearFilters();
    const datas: any = {};
    datas.depotCode = this.selectedDepot;
    this.depotFilter.emit(datas);
  }
  getFilterByDynamicStatus(evt: any, key = ''): any {
    this.clearFilters();
    if (this.dynamicFilter && this.dynamicFilter.length) {
      this.dynamicFilter.forEach(element => {
        const datas: any = {};
        if (element.key === key) {
          datas[element.key] = evt.value;
        }
        this.filters = {...this.filters, ...datas};
      });
      // this.filters = {...this.filters, ...datas};
      this.dynamicFilterEve.emit(this.filters);
    }
    
  }
  getDepots(): any {
    let paramObj = '?action=common_apis&q={"data": ["get_dist_depot"]}';
    this.commonsService.getList(paramObj).subscribe(data => {
      this.depots = data['get_dist_depot']['depots'];
    });
  }
  refresh(): any {
    this.refreshPage.emit();
  }
  convertToCamelCase(str): any {
    if (str) {
      return str.replace(/\W+(.)/g, function(match, chr)
      {
        return chr.toUpperCase();
      });
    }
  }
  getSuppliers(action = ''): any {
    let paramObj = {
      action: 'common_apis',
      q: `{"data": ["${action}"]}`
    }
    this.commonsService.getCommonEntites(paramObj).subscribe((res) => {
      this.suppliers = res[action];
      this.allSuppliers = res[action];
    });
  }
  filterBySearchTerm(item): any {
    this.suppliers = this.allSuppliers.filter(el => el.toLowerCase().includes(this.filterCtrls[item.key].value && this.filterCtrls[item.key].value.toLowerCase()));
  }
  printRetChallan(data): any {
    this.customPrint = data;
    const thiss = this;
    thiss.spinner.show();
    setTimeout(() => {
      let printContents, popupWin;
      printContents = document.getElementById("print-section-ret-challan-payment").innerHTML;
      popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
      popupWin.document.write(`${printContents}`);
      popupWin.document.close();
      thiss.spinner.hide();
    }, 200);
  }
}
