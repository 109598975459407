import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CommonsService } from 'src/app/core/services/commons.service';
import { CsdService } from 'src/app/core/services/csd.service';

@Component({
  selector: 'app-manage-csd-challan',
  templateUrl: './manage-csd-challan.component.html',
  styleUrls: ['./manage-csd-challan.component.scss']
})
export class ManageCsdChallanComponent implements OnInit {

  csdChallanForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  description: string;
  challanNumber: string;
  transactionNumber:string;
  depositAmount: string;
  bankName: string;
  challanType: string;
  transactionDate: string;
  transactionDetails: any;
  imgName: any;
  selectedImage: string;
  sources = ['Source', 'Distillery', 'Location'];
  challanTypes = ['CSDs', 'Local', 'Breakage'];
  loader: any = false;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ManageCsdChallanComponent>,
    private spinner: NgxSpinnerService,
    private commonService: CommonsService,
    private messageService: MessageService,
    private csdService:CsdService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.saveDisabled = true;
    this.csdChallanForm = this.fb.group({
      transactionNumber: ['',Validators.required],
      // challanNumber: ['',Validators.required],
      depositAmount: ['',Validators.required],
      // bankName: ['',Validators.required],
      // challanType: ['',Validators.required],
      transactionDate: ['',Validators.required],
    });
    if (this.data && this.data._id) {
      this.csdChallanForm.patchValue({
        transactionNumber: this.data.cfmsTransId,
        // challanNumber: this.data.challanNumber,
        depositAmount: this.data.depositAmount,
        // bankName: this.data.bankName,
        // challanType: this.data.challanType,
        transactionDate: this.data.transactionDate,
      });
    }
  }

  get f() {
    return this.csdChallanForm.controls;
  }

  createChallan() {
    this.saveDisabled = true;
    let data :any = {...this.csdChallanForm.value}
    const req_data = {
      action: 'add_csd_challan',
      data: data
    };
    data.challanNumber = this.transactionDetails.challannumber,
    data.transactionDate = this.convertToDate(this.transactionDetails.bankdate),
    data.challanType = 'CSD',
    this.loader = true;
    this.csdService.createCsdIndent(req_data).subscribe((res: any) => {
      this.loader = false;
      if(res.status === 'SUCCESS' || res.status === 'Successfully inserted data'){
        this.dialogRef.close('CSD Challan added successfully');
      }else if(res.error){
        this.messageService.add({ severity: 'error', detail: res.error});
      }
      else{
        this.messageService.add({ severity: 'error', detail: 'CSD Challan adding failed'});
      }
    }, (err) => {
      this.loader = false;
      this.error = err.error.message;
      this.messageService.add({ severity: 'error', detail: this.error});
    });
  }



  getCsdTransactionDetails(event) {
    if(event.target.value === ''){
      return;
    }
    const  req_data = {
      action: 'cfm_validator',
      q: JSON.stringify({data: {challan_id: event.target.value }})
    };


    this.commonService.getCommonEntites(req_data).subscribe((res) => {
      this.transactionDetails = res['status'];
      if(this.transactionDetails && !this.transactionDetails['error']){
        this.csdChallanForm.patchValue({
          challanNumber: this.challanNumber,
          depositAmount: Number(this.transactionDetails.total_amt),
          transactionDate: this.transactionDetails.bankdate ? this.convertToDate(this.transactionDetails.bankdate) : '',
        });
      }else if(this.transactionDetails.error){
        this.messageService.add({ severity: 'error', detail: this.transactionDetails['error']});
        this.csdChallanForm.reset()
      }
      else{
        this.messageService.add({ severity: 'error', detail: 'Invalid Transation Number.Please enter a valid one' });
      }
    },(err)=>{
      this.error = err.error.message;
      this.messageService.add({ severity: 'error', detail: this.error});
    });
  }

  convertToDate(date){
    const dateString = new Date(date.slice(0, 4), date.slice(4, 6) - 1, date.slice(6, 8));
    return this.datePipe.transform(dateString, 'dd-MM-yyyy');

  }

}
