import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CommonsService } from 'src/app/core/services/commons.service';
import { PaymentsService } from 'src/app/core/services/payments.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-bars-update-dialog',
  templateUrl: './bars-update-dialog.component.html',
  styleUrls: ['./bars-update-dialog.component.scss']
})
export class BarsUpdateDialogComponent implements OnInit {
  barUpdateForm:FormGroup;
  barName:any;
  barCode:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data,private fb:FormBuilder,private util:UtilService,
  private commonService: CommonsService,
  private spinner: NgxSpinnerService,
  private messageService: MessageService,
  private paymentService:PaymentsService,
  private dialogRef: MatDialogRef<BarsUpdateDialogComponent>,) { 
    console.log(this.data)
    this.barName = this.util.formatString(this.data.name);
    this.barCode = this.util.formatStringByParamAndIndex(this.data.name, '-', 0);
  }

  ngOnInit(): void {

    this.barUpdateForm = this.fb.group({
      assignedDepot:[this.data.assignedDepot],
      code:[this.barCode],
      name:[this.barName,[Validators.required]],
      vendorType:[this.data.vendorType],
      licenseeName:[this.data.licenseeName],
      panNo:[this.data.panNo,[Validators.required]],
      aadharNo:[this.data.aadharNo],
      gslNo:[this.data.gslNo]
    })
  }
  updateBarDetails(){
    this.spinner.show();
    const data:any={
      barCode:this.barCode,
      barName:this.barUpdateForm.value.name,
      panNo:this.barUpdateForm.value.panNo
    }
    const reqData:any={
      action:'update_vendor_pan_barname',
      data:data
    }
    console.log(reqData)
    this.paymentService.managePayment(reqData).subscribe((res:any)=>{
      if(res.status === 'SUCCESS'){
        this.dialogRef.close('Details Updated Successfully');
      }else{
        this.messageService.add({ severity: 'error', detail: 'Updation Failed'});
      }
    })
    this.spinner.hide();
  }

}
