import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelRegistrationService } from 'src/app/core/services/label-registration.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicConfirmationDialogComponent } from 'src/app/core/components/dynamic-confirmation-dialog/dynamic-confirmation-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-manage-label-documents-verification-dialog',
  templateUrl: './manage-label-documents-verification-dialog.component.html',
  styleUrls: ['./manage-label-documents-verification-dialog.component.scss']
})
export class ManageLabelDocumentsVerificationDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'checkbox',
    'no',
    'productCode',
    'brandCode',
    'liquorType',
    'brandImage',
    'labelImage',
    'offeredBasicPrice',
    'brandbarCode',
    'size', 
    'packType',
    'price', 
    'status',
    'action'
  ];
  roleName: any;
  documentData:any;
  comments: string = '';
  effectiveDate: Date;
  expiryDate: Date;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  selectedProduct: any;
  s3Url: any = '';
  showPrint: any = false;
  selectedProducts: any = [];
  rowChanges: any = {};

  constructor(public util: UtilService,
     public dialogRef: MatDialogRef<ManageLabelDocumentsVerificationDialogComponent>,
     @Inject(MAT_DIALOG_DATA) public data,
     private lrService: LabelRegistrationService,
     public utilService: UtilService,
     private domSanitizer: DomSanitizer,
     private messageService: MessageService,
     private spinner: NgxSpinnerService,
     private dialog: MatDialog,
  ) {
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'))['role']
    this.s3Url = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).s3_url;
    if (this.roleName !== 'Material Management' && this.roleName !== 'AS Labels' && this.roleName !== 'Distillery Officer' && this.roleName !== 'Brewery Officer') {
      delete this.displayedColumns['checkbox'];
    }
  }


  ngOnInit() {
    console.log(this.data);
    this.rowChanges = {};
    this.data.productsDetails && this.data.productsDetails.forEach(el => {
      if(!el.temp) {
        el.temp = {brandImage: false, labelImage: false};
      }
      if (!el.product_images) {
        el.product_images = [];
        el.product_images[0] = {};
      }
      if (el.product_images && el.product_images.length && el.product_images[0].labelImage) {
        el.temp.labelImage = true;
      }
      if (el.product_images && el.product_images.length && el.product_images[0].brandImage) {
        el.temp.brandImage = true;
      }
      if (!el.brandbarCode) {
        el.brandbarCode = '';
      }
      if (!el.offeredBasicPrice) {
        el.offeredBasicPrice = '';
      }
    });
    this.dataSource = new MatTableDataSource(this.data.productsDetails);
    if(this.roleName === 'Distillery Manager' || this.roleName === 'Brewery Manager' || this.roleName === 'NonLocal Supplier Manager'  || this.roleName==='NonLocal Brewery Manager'){
      this.selectedProduct = this.data;
      this.documentData =  this.selectedProduct.label[0];
    }
  }

  getMinDate(){
    return moment(new Date()).format('YYYY-MM-DD');
  }

  verifyLabelDocuments() {
    let actionType;
    let payloadData;
    switch(this.roleName) {
      case 'Distillery Manager':
        actionType = 'label_request';
        break;
      case 'Brewery Manager':
        actionType = 'label_request';
        break;
      case 'NonLocal Supplier Manager':
        actionType = 'label_progress';
        break;
      case 'NonLocal Brewery Manager':
        actionType = 'label_progress';
        break;
      case 'Distillery Officer':
        actionType = 'label_progress';
        break;
      case 'Brewery Officer':
        actionType = 'label_progress';
        break;
      case 'Material Management':
        actionType = 'label_review';
        break;
      case 'AS Labels':
        actionType = 'label_approved';
        break;
    }

    if(this.roleName === 'AS Labels'){
      payloadData = {
        productCode: this.selectedProducts,
        comments: this.comments,
        effectiveDate: this.effectiveDate,
        expiryDate: this.expiryDate
      };
    }else{
      payloadData = {
        productCode: (this.roleName === 'Distillery Manager' || this.roleName === 'Brewery Manager') ? this.selectedProduct.productCode : this.selectedProducts
      };
      if(this.roleName === 'NonLocal Supplier Manager'  || this.roleName==='NonLocal Brewery Manager'){
        payloadData = {
          productCode:  [this.selectedProduct.productCode]
        };
      }
    }

    const docPayload = {
      action: actionType,
      data: payloadData
    };
    this.spinner.show();
    this.lrService.verifyLabelDocuments(docPayload).subscribe((res: any) => {
      if(res.status === 'SUCCESS'){
        this.dialogRef.close('label documents successfully Verified');
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not verified' });
      }
      this.spinner.hide();
    }, err => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
      this.spinner.hide();
    });
  }

  reject() {
    let payloadData;
    if(this.roleName === 'AS Labels'){
      payloadData = {
        productCode: this.selectedProducts,
        comments: this.comments,
        effectiveDate: this.effectiveDate,
        expiryDate: this.expiryDate
      };
    }else{
      payloadData = {
        productCode: this.selectedProducts,
        comments: this.comments,
      };
    }

    const docPayload = {
      action: 'label_reject',
      data: payloadData
    };
    this.spinner.show();
    this.lrService.verifyLabelDocuments(docPayload).subscribe((res: any) => {
      if(res.status === 'SUCCESS'){
        this.dialogRef.close('Rejected Successfully');
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not rejected' });
      }
      this.spinner.hide();
    }, err => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
      this.spinner.hide();
    });
  }

  selectProduct(rowData: any = ''){
    if (rowData === '') {
      this.selectedProduct = '';
      this.documentData =  [];
    } else {
      this.selectedProduct = rowData;
      this.documentData =  this.selectedProduct.label ? this.selectedProduct.label[0] : [];
    }
    
  }
  downloadPDF(pdfData): any {
    console.log(pdfData);
    const linkSource: any = this.domSanitizer.bypassSecurityTrustUrl(pdfData.source);
    if (linkSource.changingThisBreaksApplicationSecurity) {
      const downloadLink: any = document.createElement('a');
      downloadLink.href = linkSource.changingThisBreaksApplicationSecurity;
      downloadLink.download = pdfData.name;
      downloadLink.click();
    }
  }
  async update(row): Promise<any> {
    const payLoad: any = {};
    if (row.temp_product_images && row.temp_product_images.length && !row.product_images) {
      row.product_images = [];
    }
    await Object.keys(row).forEach(k => {
      if (k === 'brandbarCode' || k === 'offeredBasicPrice' || k === 'productCode') {
        if (k === 'brandbarCode') {
          if (this.rowChanges[row.productCode]) {
            payLoad[k] = row[k];
          }
        } else {
          payLoad[k] = row[k];
        }
      } else if (k === 'product_images') {
        payLoad.product_images = []; 
        if (row.temp_product_images && row.temp_product_images.length) {
          row.temp_product_images.forEach(pi => {
            if (pi.key) {
              payLoad.product_images.push(pi);
            }
          });
        }
      }
    });
    if (payLoad.product_images && payLoad.product_images.length === 0) {
      delete payLoad.product_images;
    }
    const reqData: any = {
      action: 'update_label_registraion',
      data: payLoad
    };
    this.spinner.show();
    this.lrService.updateLabelDocuments(reqData).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.dialogRef.close('Updated Successfully');
        // this.dataSource = new MatTableDataSource([]);
        // this.featuresData = [];
        // this.getFeatures();
        // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Updated successfully' });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not updated' });
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to upload label documents' });
    });
  }
  uploadPdfFile(event, col, row): any {
    console.log(event.target.files[0].type);
    
    if (!event.target.files || event.target.files.length === 0) {
      if (!row.product_images || row.product_images.length && !row.product_images[0][col]) {
        row.temp[col] = false;
      }
    }
    if (event.target.files[0] && event.target.files[0].type !== 'application/pdf' && event.target.files[0].type !== 'image/jpeg' && event.target.files[0].type !== 'image/png') {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please upload only PDF/JPEG/PNG File' });
      row.temp[col] = '';
      if (!row.product_images || row.product_images.length && !row.product_images[0][col]) {
        row.temp[col] = false;
      }
      return;
    }
    if (event.target.files && event.target.files[0] && event.target.files[0].size > 5242880) {
      row.temp[col] = '';
      if (!row.product_images || row.product_images.length && !row.product_images[0][col]) {
        row.temp[col] = false;
      }
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'File should not exceed more than 5MB' });
      return;
    }
    this.getBase64(event.target.files, col, row);
  }
  getBase64(file, col, row): any {
    if (file.length > 0) {
      const fileToLoad = file[0];
      const fileReader = new FileReader();
      fileReader.onload = (fileLoadedEvent) => {
        let src: any = fileLoadedEvent.target.result;
        if (src.match('data:application/pdf;')) {
          src = src.replace("data:application/pdf;", "");
        } else if (src.match('data:image/jpeg;')) {
          src = src.replace("data:image/jpeg;", "");
        } else if (src.match('data:image/png;')) {
          src = src.replace("data:image/png;", "");
        }
        const obj = { name: fileToLoad.name, source: src };
        if (!row.temp_product_images) {
          row.temp_product_images = [];
        }
        const index = row.temp_product_images.findIndex(e => e.key === col);
        if (index >= 0) {
          row.temp_product_images[index] = {key: col, value: obj};
        } else {
          row.temp_product_images.push({key: col, value: obj});
        }
        row.temp[col] = true;
      };
      fileReader.readAsDataURL(fileToLoad);
    }
  }
  print(id){
    setTimeout(() => {
      this.getPrint(id);
    }, 100);
  }
  getPrint(id){
    let printContents, popupWin;
    printContents = document.getElementById(id).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    if (printContents) {
      popupWin.document.write(`${printContents}`);
      popupWin.document.close();
    }
  }
  isDisable(): any {
    const el = this.selectedProduct;
    const isStatus = !el.offeredBasicPrice || !el.brandbarCode || !el.product_images || (el.product_images.length && (!el.product_images[0].labelImage || !el.product_images[0].brandImage));
    return isStatus;
  }
  isPDF(str): any {
    let status = false;
    if (str) {
      const isStatus = str.match('.pdf');
      if (isStatus !== null) {
        status = true;
      }
    }
    return status;
  }
  close(): any {
    this.dialogRef.close('');
  }
  dialogConfirm(mode, label): any {
    const data: any = {};
    data.content = `${label} ..?`;
    const dialogRef = this.dialog.open(DynamicConfirmationDialogComponent, {
      width: '25%',
      data:data,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result.message}`);
      if (result.message === 'Success') {
        if(mode=='reject'){
          this.reject(); 
        }else{
          this.verifyLabelDocuments()
        }
      }
    });
  }
  selectProducts(evt: MatCheckboxChange, row): any {
    if (evt.checked) {
      row.checked = true;
    } else {
      row.checked = false;
    }
    const filteredItem: any = this.dataSource.filteredData.filter(product => product.checked);
    const filteredProducts =  filteredItem.map(el => el.productCode);
    this.selectedProducts = filteredProducts;
    if (this.selectedProducts.length === 0) {
      this.selectProduct();
    } else {
      const productData = this.dataSource.filteredData.find(el => el.productCode === this.selectedProducts[0]);
      this.selectProduct(productData);
    }
  }
  onBrandBarCodeChange(evt, row): any {
    this.rowChanges[row.productCode] = true;
  }
}
