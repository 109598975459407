import { DistilleryInfoService } from 'src/app/core/services/distillery-info.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductionService } from 'src/app/core/services/production.service';
import PRODUCTION from '../../../../../app/core/constants/production.constants';
import { MessageService } from 'primeng/api';
import { UtilService } from 'src/app/core/utils/utility-helper';


@Component({
  selector: 'app-manage-batches-dialog',
  templateUrl: './manage-batches-dialog.component.html',
  styleUrls: ['./manage-batches-dialog.component.scss']
})
export class ManageBatchesDialogComponent implements OnInit {

  productionConstant = PRODUCTION;
  batchProductionForm: FormGroup;
  error: string;
  roleName: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  productData: any= [];
  public compositionAddFields: any[] = [];
  spiritData: string[];
  unitsData: string[];
  tankInfo: any;
  batches: any;
  loader: any = false;

  constructor(private messageService: MessageService,private fb: FormBuilder, private dialogRef: MatDialogRef<ManageBatchesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private productionService: ProductionService,
    private distilleryInfoService: DistilleryInfoService,public util:UtilService) {
      this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
     }

  ngOnInit(): void {
    this.spiritData = this.productionConstant[0]['spirit'];
    this.unitsData = this.productionConstant[0]['units'];

    this.productionService.getProductData().subscribe(data => {
      this.batches = data['Batches'];
      data['Products'].forEach( pData => {
        if(this.productData.length > 0 && JSON.stringify(this.productData).indexOf(pData.brandCode) == -1) {
          this.productData.push(pData);
        }else if(this.productData.length === 0){
          this.productData[0] = pData;
        }
      });

    })
   
    // this.getBrandCodes();
   if(!this.data && this.roleName !== 'Brewery Manager' &&  this.roleName !=='NonLocal Brewery Manager') {
    this.getTanks();
   }
    // console.log(this.data, "52:::")
    this.batchProductionForm = this.fb.group({
      batchId: ['BT-', [Validators.required, ]],
      brandCode: ['', [Validators.required, ]],
      brandName: ['', [Validators.required, ]],
      strength: ['', [Validators.required, Validators.max(999.999), , Validators.pattern(/^\d*(?:[.,]\d{1,3})?$/)]],
      tankName: ['', [Validators.required, ]],
      liquorType: [''],
      manufactureDate: ['', [Validators.required, ]],
      initialBlendBLs: ['', [Validators.required, ]],
      initialBlendPLs: ['', [Validators.required, ]],
      batchQuantity: ['', [Validators.required, ]]
    });
    if (this.data) {
      this.compositionAddFields = this.data.composition;
      this.batchProductionForm.patchValue({
        batchId : this.data.batchId,
        brandCode: this.data.brandCode,
        brandName: this.data.brandName,
        strength: Number(this.data.strength),
        tankName: this.data.tankName,
        liquorType: this.data.category,
        manufactureDate: this.data.manufactureDate,
        initialBlendBLs: Number(this.data.initialBlendBLs),
        initialBlendPLs: Number(this.data.initialBlendPLs),
        batchQuantity: this.data.batchQuantity
      });
      // this.batchProductionForm.controls['initialBlendPLs'].disable();
      // this.tankInfo = [this.data.tankName];
    }
    if(!this.tankInfo){
      this.batchProductionForm.get('tankName').setValidators(null);
      this.batchProductionForm.get('tankName').updateValueAndValidity();
    }
    this.batchProductionForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    })
    if (this.roleName === 'Brewery Manager' || this.roleName ==='NonLocal Brewery Manager') {
      this.batchProductionForm.removeControl('tankName');
    }
  }
  getTanks() {
    this.distilleryInfoService.getTanks().subscribe(res => {
      this.tankInfo = res['get_tanks'] && res['get_tanks'].map(res => res.tankName);
    })
  }
  todayDate = new Date();
  get f(){
    return this.batchProductionForm.controls;
  }

  dataFill(data) {
    let flag = false;
    Object.keys(data).forEach(val => {
      flag = !data[val];
    })
    return flag;
  }

  showAddRow() {
    if(this.compositionAddFields.length >=1) {
      return (!this.compositionAddFields[this.compositionAddFields.length - 1]['spirit'])
    }
    else {
      return false;
    }
  }

  addComposition() {

    this.compositionAddFields.push({ spirit: '', vatNo: '', strength: '', issuedBLs: '', unit: '' })
  }

  deleteComposition(i) {
    this.compositionAddFields.splice(i,1);
  }
  brandCodeChange(event) {
    this.productData.forEach(data => {
      if(data.brandCode === event.value)
      this.batchProductionForm.patchValue(
        {
          brandName: data.brandName,
          liquorType: data.liquorType
      })
     // this.batchProductionForm.patchValue({})
    })
  }

  brandNameChange(event) {
    this.productData.forEach(data => {
      if(data.brandName === event.value)
    //  this.batchProductionForm.patchValue({brandCode: data.brandCode});
      this.batchProductionForm.patchValue({
        brandCode: data.brandCode,
        liquorType: data.liquorType
    })
    })
  }

  calcuatePls() {
    if(this.batchProductionForm.get('initialBlendBLs').value && this.batchProductionForm.get('strength').value) {
      let bls = Number(this.batchProductionForm.get('initialBlendBLs').value);
      let strength = Number(this.batchProductionForm.get('strength').value);
      const val = Number(((bls*strength)/100).toFixed(3));
      // let calculatedVal = JSON.stringify(val).split('.');
      // val = Number(calculatedVal[0] + '.' + calculatedVal[1].charAt(0)+ calculatedVal[1].charAt(1))
      this.batchProductionForm.patchValue({initialBlendPLs: val})
    }
  }
  // getBlendTanks() {
  //   this.productionService.
  // }

  // getBrandCodes() {
  //   this.productionService.getProduct(0, 100).subscribe((res) => {
  //     this.products.push(...res.data);
  //   });
  // }

  // getTankName(){
  //   if (this.data && this.data._id) {
  //     this.disitilleryInfoService.getBlendTanks(0, 100, '').subscribe((res) => {
  //       this.blendTanks.push(...res.data);
  //     })
  //   } else {
  //     this.disitilleryInfoService.getBlendTanks(0, 100, '', 'BTS-NIU-01').subscribe((res) => {
  //       this.blendTanks.push(...res.data);
  //     })
  //   }

  // }
  batchDeletePrevention(event){
    if(event.target.value.length == 3 && (event.code == "Backspace" || event.code == "Delete")){
      return false;
    }
 
    return true;
   }
  selectProduct(e) {

  }

  createBatchProduction() {
    this.saveDisabled = true;
    this.batchProductionForm.value.strength = Number(parseFloat(this.batchProductionForm.value.strength).toFixed(1));
    this.batchProductionForm.value.initialBlendPLs = Number(parseFloat(this.batchProductionForm.value.initialBlendPLs).toFixed(3));

    const req_Data = {
      action : 'add_batch',
      data : this.batchProductionForm.value
    }
    req_Data.data['composition'] = this.compositionAddFields;
    if (this.batches.some((batch) => batch.batchId == req_Data.data.batchId && batch.brandCode == req_Data.data.brandCode)) {
      this.saveDisabled = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Batch Number already exists' });
    } else {
      this.loader = true;
      this.productionService.createBatch(req_Data).subscribe((res: any) => {
        this.loader = false;
        this.dialogRef.close('Batch Created Successfully');
      }, (err) => {
        this.loader = false;
        this.error = err.error.message;
      });
    }
  }

  updateBatchProduction() {
    this.saveDisabled = true;
    const obj = {
      action : 'update_batch',
      data: this.batchProductionForm.value
    };
    obj.data['composition'] = this.compositionAddFields;
    this.productionService.putBatch(obj).subscribe((res: any) => {
      this.saveDisabled = false;
      this.compositionAddFields = [];
      if (res && res.response === 'None') {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not updated' });
        return;
      }
      this.dialogRef.close('Batch Updated Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  dateChange() {
    this.todayDate = new Date();
  }
}
