import { UtilService } from 'src/app/core/utils/utility-helper';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from 'primeng/api';
import { unitsPerCases } from 'src/app/core/constants/units-per-case.constatnts';
import { IndentManagementService } from 'src/app/core/services/indent-management.service';
import { ShipmentsService } from 'src/app/core/services/shipments.service';

@Component({
  selector: 'app-icdc-scan-status-dialog',
  templateUrl: './icdc-scan-status-dialog.component.html',
  styleUrls: ['./icdc-scan-status-dialog.component.scss']
})
export class IcdcScanStatusDialogComponent implements OnInit {
  valid: any;
  ofsData: any [];
  caseCode: any;
  displayedColumns: string[] = [
    'no',
    'productCode',
    'brandName',
    'sizeCode',
    'shippedCases',
    'recievedCases',
    'recievedBottles',
    'damagedCases',
    'damagedBottles',
    'shortageCases',
    'shortageBottles' 
  ];
  user: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  productData: any = [];
  filteredProductDetails: any[];
  @Input() evcData: any;
  // @ViewChild('icdcCode') icdcCode: any;
  caseCodeType: any = "";
  caseTypes: any = ['Manual', 'Automatic'];
  unitsPerCases= unitsPerCases;
  caseCodeCount: any;
  showSubmit: boolean = true;
    constructor(public dialogRef: MatDialogRef<IcdcScanStatusDialogComponent>, private shipmentService: ShipmentsService, private indentManagementService: IndentManagementService , @Inject(MAT_DIALOG_DATA)public data, private messageService: MessageService,
    public util: UtilService) { }

  ngOnInit(): void {
    
    console.log(this.data,"33::::")
    this.productData = [];
    this.ofsData = [this.data];
    // this.ofsData = this.data['productDetails'];
    this.user = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
    console.log(this.user);
    /* this.data['consignment'].forEach(ofs => {
      if(ofs.productDetails) {
        if(this.productData.length >0) {
          ofs.productDetails.forEach( product => {

            this.productData.push(product);
          })
        }
        else {
          this.productData = ofs.productDetails;
        }
      }
    })
    const arr = this.productData;

    this.filteredProductDetails = Array.from(new Set(this.productData.map(a => a.productCode)))
    .map(productCode => {
      return this.productData.find(a => a.productCode === productCode)
    })

    this.filteredProductDetails.forEach(item => {
      let count = 0;
      this.productData.forEach( product => {
      
      
      if(item.productCode == product.productCode) {
        count = count + (product.casesCount? Number(product.casesCount): Number(product.caseCount))
      console.log(item.productCode, item.casesCount,product.productCode, product.casesCount);
      
      }
      
      })
      
      item.casesCount = count;
      }) */

    console.log(this.filteredProductDetails,this.data, this.productData , "24::::::::::")

    this.dataSource = new MatTableDataSource(this.filteredProductDetails);
  }

  caseCodeChange() {
    let data = {}
    if(this.caseCodeType == "Manual") {
      data = {"icdcCode":  this.data.icdcCode , "asnId": this.data.asnId , "type": "Manual", "caseCode": this.caseCode};
    }
    else {
      data = {"icdcCode":  this.data.icdcCode , "asnId": this.data.asnId , "type": "Automatic"};
    }
    const req_Data = {
      "action": "scan_icdc",
      data
    }

    this.indentManagementService.submitCaseCode(req_Data).subscribe(result => {
      this.showSubmit = false; 
     this.dialogRef.close('ICDC Updated Successfully');
      console.log(result,"96::")


    })
  }

  getRecievedCases(row) {
    let cases = 0;
 
    return !this.showSubmit ? row.casesCount : '' ;
  } 
  dialogClose(): void{
    this.dialogRef.close();
  }

}
