import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CancelledImportPermit } from 'src/app/core/models/import/cancel-import-permit.model';
import { ImportService } from 'src/app/core/services/import.service';

@Component({
  selector: 'app-cancel-import-permit-details-dialog',
  templateUrl: './cancel-import-permit-details-dialog.component.html',
  styleUrls: ['./cancel-import-permit-details-dialog.component.scss']
})
export class CancelImportPermitDetailsDialogComponent implements OnInit {

  displayedColumns: string[] = ['no', 'productCode', 'brandName', 'size', 'shipmentInQty'];
  dataSource: MatTableDataSource<CancelledImportPermit> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;

  constructor(public dialogRef: MatDialogRef<CancelImportPermitDetailsDialogComponent>, private importService: ImportService) { }

  ngOnInit(): void {
    this.getCancelImportPermit();
  }

  getCancelImportPermit() {
    this.importService.getCancelImportPermit(this.offset, this.limit, this.search).subscribe((res: any) => {
      console.log('res', res);
      this.dataSource = new MatTableDataSource(res.data);
    });
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

}
