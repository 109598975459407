import { Component, OnInit } from '@angular/core';
import { fromEvent, merge, Observable, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-no-internet',
  templateUrl: './no-internet.component.html',
  styleUrls: ['./no-internet.component.scss']
})
export class NoInternetComponent implements OnInit {

  online$: Observable<boolean>;

  constructor(
    private location: Location,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.isOnline();
  }
  isOnline(): any {
    this.spinner.show();
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );
    this.online$.subscribe(e => {
      this.spinner.hide();
      if (e === true) {
        this.location.back();
      }
    })
  }
  

}
