import { UtilService } from 'src/app/core/utils/utility-helper';
import { InterDepotService } from 'src/app/core/services/inter-depot.service';
import { Inject, AfterViewInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
declare const JsBarcode: any;
@Component({
  selector: 'app-idtreceipt-apn-details-dialog',
  templateUrl: './idtreceipt-apn-details-dialog.component.html',
  styleUrls: ['./idtreceipt-apn-details-dialog.component.scss']
})
export class IDTReceiptApnDetailsDialogComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = [
    'no',
    'productCode',
    'brandName',
    // 'batchId',
    'sizeCode',
    'shippedCases',
    'recievedCases',
    'recievedBottles',
    'damagedCases',
    'damagedBottles',
    'shortageCases',
    'shortageBottles'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  productData: any[];
  ofidtData: any;
  roleName: any;
  totalShipped: any = 0;
  receivedQtyFull: any = 0;
  receivedQtyPart: any = 0;
  breakageBtl: any = 0;
  address: any;
  depotName: any;
  totalBasicPricing: any = 0;
  stockAmount: any = 0;
  receivedQtyPrt: any = 0;
  selectedItem:any;

  constructor(
    public dialogRef: MatDialogRef<IDTReceiptApnDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private interDepotService: InterDepotService,
    public util: UtilService
  ) {
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    this.depotName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).entity_id;
    this.address = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).depot_address;
   }

  ngOnInit(): void {
    console.log('this.data.get_evc', this.data.get_evc);
    console.log(this.data, '33::::');
    this.productData = [];
    this.ofidtData = this.data.consignment;
    
  }


  updateStatus(status): any {
    const data = this.data;
    // tslint:disable-next-line:variable-name
    const req_Data: any = {
      action: 'ack_shipment',
      data
    };
    if (this.data.btpNo) {
      delete req_Data.data.tpNo;
      req_Data.data.btpNo = this.data.btpNo;
    } else if (this.data.bipNo) {
      delete req_Data.data.tpNo;
      req_Data.data.bipNo = this.data.bipNo;
    }

    console.log(req_Data, '51::::');
    this.interDepotService.acknowledgeSRA(req_Data).subscribe(result => {
      this.dialogRef.close('Acknowledged SRA Successfully');
    });
  }
   ngAfterViewInit(): any {
    if (this.data.chkNO) {
      JsBarcode('#barcode-chkNO', this.data.chkNO,
        {
          width: 1,
          height: 40
        }
      );
    }

    JsBarcode('#barcode-test', this.data.grnNO,
      {
        width: 1,
        height: 40
      }
    );
  }
  totavlValue(): any {
    this.totalShipped = 0;
    this.breakageBtl = 0;
    this.totalBasicPricing = 0;
    this.stockAmount = 0;
    this.receivedQtyFull = this.data.totalScannedCases;
    this.receivedQtyPart = this.data.totalScannedBottles;
    this.ofidtData.map((ofidt: any) => {
      ofidt.productDetails.map((item: any) => {
        this.totalShipped += parseInt(item.casesCount, 10);
        this.totalBasicPricing += parseInt(item.retailPricings, 10);
        this.stockAmount +=  item.casesCount * item.retailPricings;
        if (!this.data.totalScannedCases && item.scannedCases){
          this.receivedQtyFull += item.scannedCases ? item.scannedCases :0;
        }
        if (item.breakages){
          this.breakageBtl += parseInt(item.breakages.bottles, 10);

        }
      });
    });
  }
  dialogClose(): void {
    this.dialogRef.close();
  }

  Print(item,id): any {
    console.log(item)
    this.totavlValue();
    if(document.getElementById(id).innerHTML){
      this.selectedItem=item
      setTimeout(() => {
      let printContents;
      let popupWin;
      printContents = document.getElementById(id).innerHTML;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.write(`${printContents}`);
      popupWin.document.close();
    }, 500);
    }
 
  }
}
