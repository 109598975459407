import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { OutletSales } from 'src/app/core/models/bcl-outlets/outlet-sales.model';
import { BclOutletsService } from 'src/app/core/services/bcl-outlets.service';

@Component({
  selector: 'app-open-retailer-names-dialog',
  templateUrl: './open-retailer-names-dialog.component.html',
  styleUrls: ['./open-retailer-names-dialog.component.scss']
})
export class OpenRetailerNamesDialogComponent implements OnInit {
  displayedColumns: string[] = [
  'no',
  'iMLSale',
  'beerSale',
  'saleDate'
  ];
  dataSource: MatTableDataSource<OutletSales> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;

  constructor( public dialogRef: MatDialogRef<OpenRetailerNamesDialogComponent>, @Inject(MAT_DIALOG_DATA)public data,
               private bclOutletsService: BclOutletsService) { }

  ngOnInit(): void {
    this.getOFSDetails();
  }

  getOFSDetails() {
    this.bclOutletsService.getOutletSales(this.offset, this.limit, this.search).subscribe ((res: any) => {
      console.log('res', res);
      this.dataSource = new MatTableDataSource(res.data);
    });
  }

  dialogClose(): void{
    this.dialogRef.close();
  }

}
