import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManageProfileComponent } from './core/components/header/manage-profile/manage-profile.component';
import { AuthGuard } from './core/guards/auth.guard';
import { GitstatusComponent } from './features/pages/gitstatus/gitstatus.component';
import { LabelDetailsComponent } from './features/pages/label-details/label-details.component';
import { NoInternetComponent } from './features/pages/no-internet/no-internet.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  { path: 'label', component: LabelDetailsComponent},
  { path: 'get_git_status', component: GitstatusComponent},
  { path: 'no-internet', component: NoInternetComponent},
  { path: 'holograms', loadChildren: () => import('./features/holograms/holograms.module').then(m => m.HologramsModule), canActivate: [AuthGuard] },
  { path: 'track-trace', loadChildren: () => import('./features/track-trace/track-trace.module').then(m => m.TrackTraceModule), canActivate: [AuthGuard] },
  { path: 'production', loadChildren: () => import('./features/production/production.module').then(m => m.ProductionModule), canActivate: [AuthGuard] },
  { path: 'home', loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] },
  { path: 'login', loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule) },
  { path: 'supply-delivery', loadChildren: () => import('./features/supply-delivery/supply-delivery.module').then(m => m.SupplyDeliveryModule), canActivate: [AuthGuard] },
  { path: 'distillery-info', loadChildren: () => import('./features/distillery-info/distillery-info.module').then(m => m.DistilleryInfoModule), canActivate: [AuthGuard] },
  { path: 'indent-management', loadChildren: () => import('./features/indent-management/indent-management.module').then(m => m.IndentManagementModule), canActivate: [AuthGuard] },
  { path: 'stock', loadChildren: () => import('./features/stock/stock.module').then(m => m.StockModule), canActivate: [AuthGuard] },
  { path: 'shipments', loadChildren: () => import('./features/shipments/shipments.module').then(m => m.ShipmentsModule), canActivate: [AuthGuard] },
  { path: 'shipment', loadChildren: () => import('./features/shipments/shipments.module').then(m => m.ShipmentsModule), canActivate: [AuthGuard] },
  { path: 'dashboard', loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
  // { path: 'reports', loadChildren: () => import('./features/reports/reports.module').then(m => m.ReportsModule) },
  { path: 'reports', loadChildren: () => import('./features/reports-new/reports-new.module').then(m => m.ReportsNewModule), canActivate: [AuthGuard] },
  { path: 'inter-depot', loadChildren: () => import('./features/inter-depot/inter-depot.module').then(m => m.InterDepotModule), canActivate: [AuthGuard] },
  { path: 'inter-retailer-transfer', loadChildren: () => import('./features/inter-retailer/inter-retailer.module').then(m => m.InterRetailerModule), canActivate: [AuthGuard] },
  { path: 'inter-depot-transfer', loadChildren: () => import('./features/inter-depot/inter-depot.module').then(m => m.InterDepotModule), canActivate: [AuthGuard] },
  { path: 'supplier', loadChildren: () => import('./features/suppliers/suppliers.module').then(m => m.SuppliersModule), canActivate: [AuthGuard] },
  { path: 'physical-verification', loadChildren: () => import('./features/physical-verification/physical-verification.module').then(m => m.PhysicalVerificationModule), canActivate: [AuthGuard] },
  { path: 'depot-info', loadChildren: () => import('./features/depot-info/depot-info.module').then(m => m.DepotInfoModule), canActivate: [AuthGuard] },
  { path: 'products', loadChildren: () => import('./features/products/products.module').then(m => m.ProductsModule), canActivate: [AuthGuard] },
  { path: 'product-scanning', loadChildren: () => import('./features/product-scanning/product-scanning.module').then(m => m.ProductScanningModule), canActivate: [AuthGuard] },
  { path: 'stock-management', loadChildren: () => import('./features/stock-management/stock-management.module').then(m => m.StockManagementModule), canActivate: [AuthGuard] },
  { path: 'stock-in', loadChildren: () => import('./features/stock-in/stock-in.module').then(m => m.StockInModule), canActivate: [AuthGuard] },
  { path: 'sales', loadChildren: () => import('./features/sales/sales.module').then(m => m.SalesModule), canActivate: [AuthGuard] },
  { path: 'indent', loadChildren: () => import('./features/indent/indent.module').then(m => m.IndentModule), canActivate: [AuthGuard] },
  { path: 'breakages', loadChildren: () => import('./features/breakages/breakages.module').then(m => m.BreakagesModule), canActivate: [AuthGuard] },
  { path: 'packing', loadChildren: () => import('./features/packing/packing.module').then(m => m.PackingModule), canActivate: [AuthGuard] },
  { path: 'depot-shipments', loadChildren: () => import('./features/shipments/shipments.module').then(m => m.ShipmentsModule), canActivate: [AuthGuard] },
  { path: 'depot-breakages', loadChildren: () => import('./features/breakages/breakages.module').then(m => m.BreakagesModule), canActivate: [AuthGuard] },
  { path: 'retailers', loadChildren: () => import('./features/retailers/retailers.module').then(m => m.RetailersModule), canActivate: [AuthGuard] },
  { path: 'challan-status', loadChildren: () => import('./features/challan-status/challan-status.module').then(m => m.ChallanStatusModule), canActivate: [AuthGuard] },
  { path: 'depot-indents', loadChildren: () => import('./features/indent-management/indent-management.module').then(m => m.IndentManagementModule), canActivate: [AuthGuard] },
  { path: 'depot-packing', loadChildren: () => import('./features/packing/packing.module').then(m => m.PackingModule), canActivate: [AuthGuard] },
  { path: 'liquor-pricing', loadChildren: () => import('./features/liquor-pricing/liquor-pricing.module').then(m => m.LiquorPricingModule), canActivate: [AuthGuard] },
  { path: 'pricing', loadChildren: () => import('./features/liquor-pricing/liquor-pricing.module').then(m => m.LiquorPricingModule), canActivate: [AuthGuard] },
  { path: 'supplier-indents', loadChildren: () => import('./features/supplier-indents/supplier-indents.module').then(m => m.SupplierIndentsModule), canActivate: [AuthGuard] },
  { path: 'licenses', loadChildren: () => import('./features/licenses/licenses.module').then(m => m.LicensesModule), canActivate: [AuthGuard] },
  { path: 'retailer-info', loadChildren: () => import('./features/retailer-info/retailer-info.module').then(m => m.RetailerInfoModule), canActivate: [AuthGuard] },
  { path: 'licences', loadChildren: () => import('./features/licenses/licenses.module').then(m => m.LicensesModule), canActivate: [AuthGuard] },
  { path: 'breakage', loadChildren: () => import('./features/breakages/breakages.module').then(m => m.BreakagesModule), canActivate: [AuthGuard] },
  { path: 'brand-registration', loadChildren: () => import('./features/brand-registration/brand-registration.module').then(m => m.BrandRegistrationModule), canActivate: [AuthGuard] },
  { path: 'label-registration', loadChildren: () => import('./features/label-registration/label-registration.module').then(m => m.LabelRegistrationModule), canActivate: [AuthGuard] },
  {
    path: 'manage-profile',
    component: ManageProfileComponent,
    canActivate: [AuthGuard]
  },
  { path: 'excise-duty', loadChildren: () => import('./features/excise-duty/excise-duty.module').then(m => m.ExciseDutyModule), canActivate: [AuthGuard] },
  { path: 'lab', loadChildren: () => import('./features/lab/lab.module').then(m => m.LabModule), canActivate: [AuthGuard] },
  { path: 'spirit-register', loadChildren: () => import('./features/spirit-register/spirit-register.module').then(m => m.SpiritRegisterModule), canActivate: [AuthGuard] },
  { path: 'export', loadChildren: () => import('./features/export/export.module').then(m => m.ExportModule), canActivate: [AuthGuard] },
  { path: 'csd', loadChildren: () => import('./features/csd/csd.module').then(m => m.CsdModule), canActivate: [AuthGuard] },
  { path: 'alerts', loadChildren: () => import('./features/alerts/alerts.module').then(m => m.AlertsModule), canActivate: [AuthGuard] },
  { path: 'user-manual', loadChildren: () => import('./features/user-manual/user-manual.module').then(m => m.UserManualModule), canActivate: [AuthGuard] },
  { path: 'feedback', loadChildren: () => import('./features/feedback/feedback.module').then(m => m.FeedbackModule), canActivate: [AuthGuard] },
  { path: 'checklist', loadChildren: () => import('./features/checklist/checklist.module').then(m => m.ChecklistModule), canActivate: [AuthGuard] },
  { path: 'gate-register', loadChildren: () => import('./features/gate-register/gate-register.module').then(m => m.GateRegisterModule), canActivate: [AuthGuard] },
  { path: 'excise-licensee', loadChildren: () => import('./features/excise-licensee/excise-licensee.module').then(m => m.ExciseLicenseeModule), canActivate: [AuthGuard] },
  { path: 'beer', loadChildren: () => import('./features/beer/beer.module').then(m => m.BeerModule), canActivate: [AuthGuard] },
  { path: 'rationing', loadChildren: () => import('./features/rationing/rationing.module').then(m => m.RationingModule), canActivate: [AuthGuard]  },
  { path: 'sample-analysis', loadChildren: () => import('./features/sample-analysis/sample-analysis.module').then(m => m.SampleAnalysisModule), canActivate: [AuthGuard]  },
  { path: 'import', loadChildren: () => import('./features/import/import.module').then(m => m.ImportModule), canActivate: [AuthGuard]  },
  { path: 'tp-revalidation', loadChildren: () => import('./features/tp-revalidation/tp-revalidation.module').then(m => m.TpRevalidationModule), canActivate: [AuthGuard]  },
  { path: 'tp-revalidation-for-import', loadChildren: () => import('./features/tp-revalidation/tp-revalidation.module').then(m => m.TpRevalidationModule), canActivate: [AuthGuard]  },
  { path: 'bcl-outlet', loadChildren: () => import('./features/bcl-outlet/bcl-outlet.module').then(m => m.BclOutletModule), canActivate: [AuthGuard]  },
  { path: 'distillery', loadChildren: () => import('./features/distillery/distillery.module').then(m => m.DistilleryModule), canActivate: [AuthGuard]  },
  { path: 'users', loadChildren: () => import('./features/users/users.module').then(m => m.UsersModule), canActivate: [AuthGuard]  },
  { path: 'depot', loadChildren: () => import('./features/depot/depot.module').then(m => m.DepotModule), canActivate: [AuthGuard]  },
  { path: 'licensee-profiles', loadChildren: () => import('./features/licensee-profiles/licensee-profiles.module').then(m => m.LicenseeProfilesModule), canActivate: [AuthGuard]  },
  { path: 'licensee-sales', loadChildren: () => import('./features/licensee-sales/licensee-sales.module').then(m => m.LicenseeSalesModule), canActivate: [AuthGuard]  },
  { path: 'stock-return-drainout', loadChildren: () => import('./features/stock-return/stock-return.module').then(m => m.StockReturnModule), canActivate: [AuthGuard]  },
  { path: 'location', loadChildren: () => import('./features/locations/locations.module').then(m => m.LocationsModule), canActivate: [AuthGuard]  },
  { path: 'new-reports', loadChildren: () => import('./features/new-reports/new-reports.module').then(m => m.NewReportsModule), canActivate: [AuthGuard]  },
  { path: 'raw-material-procurement', loadChildren: () => import('./features/raw-material-procurement/raw-material-procurement.module').then(m => m.RawMaterialProcurementModule), canActivate: [AuthGuard]  },
  { path: 'abstract-reports', loadChildren: () => import('./features/abstract-reports/abstract-reports.module').then(m => m.AbstractReportsModule), canActivate: [AuthGuard]  },
  { path: 'price-list', loadChildren: () => import('./features/price-list/price-list.module').then(m => m.PriceListModule), canActivate: [AuthGuard]  },
  { path: 'sales-management', loadChildren: () => import('./features/sales-management/sales-management.module').then(m => m.SalesManagementModule), canActivate: [AuthGuard]  },
  { path: 'outlet-info', loadChildren: () => import('./features/outlet-info/outlet-info.module').then(m => m.OutletInfoModule), canActivate: [AuthGuard]  },
  { path: 'user-roles', loadChildren: () => import('./features/user-roles/user-roles.module').then(m => m.UserRolesModule), canActivate: [AuthGuard]  },
  { path: 'modules', loadChildren: () => import('./features/modules-manage/modules-manage.module').then(m => m.ModulesManageModule), canActivate: [AuthGuard]  },
  { path: 'features', loadChildren: () => import('./features/features-manage/features-manage.module').then(m => m.FeaturesManageModule), canActivate: [AuthGuard]  },
  { path: 'feature-permissions', loadChildren: () => import('./features/feature-permissions/feature-permissions.module').then(m => m.FeaturePermissionsModule), canActivate: [AuthGuard] },
  { path: 'user-accounts', loadChildren: () => import('./features/user-accounts/user-accounts.module').then(m => m.UserAccountsModule), canActivate: [AuthGuard] },
  { path: 'pages', loadChildren: () => import('./features/pages/pages.module').then(m => m.PagesModule) },
  { path: 'payment', loadChildren: () => import('./features/payment/payment.module').then(m => m.PaymentModule), canActivate: [AuthGuard] },
  {
    path: 'calculator',
    loadChildren: () => import('./features/calculator/calculator.module').then(m => m.CalculatorModule), canActivate: [AuthGuard]
  },
  { path: 'live-data', loadChildren: () => import('./features/live-data/live-data.module').then(m => m.LiveDataModule), canActivate: [AuthGuard] },
  { path: 'report-an-issue', loadChildren: () => import('./features/report-issues/report-issues.module').then(m => m.ReportIssuesModule), canActivate: [AuthGuard] },
  { path: 'pictographical-dashboard', loadChildren: () => import('./features/pictographical-dashboard/pictographical-dashboard.module').then(m => m.PictorialDashboardModule), canActivate: [AuthGuard] },
  {
    path: 'release-notes',
    loadChildren: () => import('./features/release-notes/release-notes.module').then(m => m.ReleaseNotesModule), canActivate: [AuthGuard]
  },
  {
    path: 'logs',
    loadChildren: () => import('./features/logs/logs.module').then(m => m.LogsModule), canActivate: [AuthGuard]
  },
  {
    path: 'licensee-shipments',
    loadChildren: () => import('./features/licensee-shipments/licensee-shipments.module').then(m => m.LicenseeShipmentsModule), canActivate: [AuthGuard]
  },
  {
    path: 'through-transport-permit',
    loadChildren: () => import('./features/through-transport-permit/through-transport-permit.module').then(m => m.ThroughTransportPermitModule), canActivate: [AuthGuard]
  },
  {
    path: 'recovery',
    loadChildren: () => import('./features/recovery/recovery/recovery.module').then(m => m.RecoveryModule), canActivate: [AuthGuard]
  },
  {
    path: 'central-issue',
    loadChildren: () => import('./features/central-issues/central-issue.module').then(m => m.CentralIssuesModule), canActivate: [AuthGuard]
  },
  {
    path: 'distillery-shipment',
    loadChildren: () => import('./features/distillery-shipmets/distillery-shipmets.module').then(m => m.DistilleryShipmetsModule), canActivate: [AuthGuard]
  },
  {
    path: 'bar-depot-reassign',
    loadChildren: () => import('./features/bar-depot-reassign/bar-depot-reassign.module').then(m => m.BarDepotReassignModule), canActivate: [AuthGuard]
  },

  { path: '**', redirectTo: 'pages/404-page-not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
