import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { GateRegisterService } from 'src/app/core/services/gate-register.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { CommonsService } from 'src/app/core/services/commons.service';
import { MessageService } from 'primeng/api';

@Component({
  templateUrl: './manage-inward-gate-register-dialog.component.html',
  styleUrls: ['./manage-inward-gate-register-dialog.component.scss']
})
export class ManageInwardGateRegisterDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'no',
    'indentNumber',
    'brandName',
    'retailerCode',
    'tpNo',
    'quantity',
    'quantityPart'
  ];
  inwardConsignmentTypes: string[] = [
    'OFIDT', 'Retailer Indent', 'Vendor Indent'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  inwardGRForm: FormGroup;
  userInfo: any;
  inwardData: any;
  inwardRegisterData:any;
  distConsignmentTypes: Array<string>;
  inwardRegisterForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<ManageInwardGateRegisterDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,
  private messageService: MessageService,
    private fb: FormBuilder, public util: UtilService, private CommonsService: CommonsService, private gateRegisterService: GateRegisterService) {
    this.userInfo = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'))
  }

  ngOnInit(): void {
    this.getCommonList('distConsignmentTypes');

    this.inwardGRForm = this.fb.group({
      tp: ['', Validators.required],
    });

    if(this.userInfo.entity_type === 'Depot'){
      this.inwardRegisterForm = this.fb.group({
        retailerIndent: [''],
        retailerCode: [''],
        entryDateTime: ['', Validators.required],
        validityDateTime: ['', Validators.required],
        indentNo: [''],
        tpNo: [''],
        vehicleNo: ['', Validators.required],
        consignmentType: ['', Validators.required]
      });
    }else{
      this.inwardRegisterForm = this.fb.group({
        indentNo: [''],
        tpNo: [''],
        vehicleNo: ['', Validators.required],
        consignmentType: ['', Validators.required]
      });
    }
   
    this.dataSource = new MatTableDataSource([]);
    console.log(this.data)
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

  onScan() {
    const qdata = {
      entityType: this.userInfo.entity_type,
      tpNo: this.inwardGRForm.get('tp').value,
    }
    this.gateRegisterService.getProducts(JSON.stringify(qdata), 'scan_tp').subscribe((res: any) => {
      this.inwardRegisterData = res['scan_tp'];
      if((res['scan_tp'].status=== "InTransit" && this.userInfo.entity_id === (res['scan_tp'].consignmentType === "OFIDT" ? res['scan_tp'].consignment[0].destnEntityId : res['scan_tp'].destnEntityId)) || (res['scan_tp'].status=== "Approved" && this.userInfo.entity_id === res['scan_tp'].sourceDepotCode)){
        this.inwardData = res['scan_tp'];
      }else if(this.inwardRegisterData.status=== "Reached"){
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'TP Already Processed.' });
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'TP not yet shipped' });
      }

    });
  }

  addInwardRegister() {
    const obj = {
      action: 'add_inward_register',
      data: {
        ...this.inwardData, validityDateTime: new Date(this.inwardData.validityDateTime),
        entityType: this.userInfo.entity_type
      }
    }
    this.gateRegisterService.addGateRegister(obj).subscribe(res => {
      this.dialogRef.close(res['status']);
    });
  }

  addRegister() {
    const obj = {
      action: 'add_inward_register',
      data: {
        ...this.inwardRegisterForm.value
      }
    }
    this.gateRegisterService.addInwardGateRegister(obj).subscribe(res => {
      this.dialogRef.close('Inward Registered Successfully');
    });
  }

  getCommonList(value) {
    const req_Data = {
      action: 'common_apis',
      q: `{"data": {"action": "get_static_codes", "value": ["${value}"]}}`
    }
    this.CommonsService.getCommonEntites(req_Data).subscribe(result => {
      this[value] = result[value] ? result[value] : [];
    })
  }

  convertToDate(date) {
    return date ? moment(date).format('DD/MM/YY, h:mm a') : '-';
  }
  convertToDateOnly(date) {
    return moment(date).format('DD/MM/YY');
  }

}
