import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-cartoon-barcode-dialog',
  templateUrl: './cartoon-barcode-dialog.component.html',
  styleUrls: ['./cartoon-barcode-dialog.component.scss']
})
export class CartoonBarcodeDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'spoolBarcode',
    'firstHealCode',
    'lastHealCode'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  cartonData: any = [];

  constructor(public dialogRef: MatDialogRef<CartoonBarcodeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    if(this.data.status !== "Issued"){
      this.cartonData = this.data.cartons[0].spoolBarcodes.filter(spoolBarCode => spoolBarCode.status !== "Issued");
      this.dataSource = new MatTableDataSource(this.cartonData);
    }
    
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

}
