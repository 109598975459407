import { Component, OnInit } from '@angular/core';
import { CommonsService } from 'src/app/core/services/commons.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-gitstatus',
  templateUrl: './gitstatus.component.html',
  styleUrls: ['./gitstatus.component.scss']
})
export class GitstatusComponent implements OnInit {

  datas: any = {};
  pyDatas: any = {};
  constructor(
    private commonsService: CommonsService,
    private util: UtilService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getDatas();
    this.getPyDatas();
  }
  getDatas(): any {
    this.datas = {}
    this.spinner.show();
    this.commonsService.getGitDetails().subscribe(res => {
      if (res && res.error) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: res.error });
      }
      if (res) {
        this.datas = res;
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
    });

  }
  getPyDatas(): any {
    this.datas = {}
    this.spinner.show();
    this.commonsService.getPyGitDetails().subscribe(res => {
      if (res['Commit ID']) {
        this.pyDatas = res;
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: `Can't able to reach/connect git` });
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
    });

  }
  copyMessage(val: any): any {
    this.util.copyMessage(val);
    this.messageService.add({ severity: 'success', summary: '', detail: 'Copied to clipboard' });
  }
  getShortHash(val): any {
    if (val) {
      return val.substring(0,8)
    }
    return '-';
  }

}
