import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Product } from 'src/app/core/models/production/product.model';
import { BreakagesService } from 'src/app/core/services/breakages.service';
import { ProductionService } from 'src/app/core/services/production.service';
import { ManageStorageDialogComponent } from '../../storage/manage-storage-dialog/manage-storage-dialog.component';
import { ShipmentsService } from '../../../../core/services/shipments.service';
import { TransportPermit } from '../../../../core/models/supply-delivery/transport-permit';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { MessageService } from 'primeng/api';
import { CommonsService } from 'src/app/core/services/commons.service';

@Component({
  selector: 'app-manage-transit-dialog',
  templateUrl: './manage-transit-dialog.component.html',
  styleUrls: ['./manage-transit-dialog.component.scss']
})
export class ManageTransitDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'no',
    'caseCode',
    'healCodes'
  ];
  transitForm: FormGroup;
  breakageForm: FormGroup;
  limit: number;
  offset: number;
  damagedCaseCodesLength: number;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  error: string;
  products: Product[];
  transportPermits: TransportPermit[];
  saveDisabled: boolean;
  damages: FormArray;
  isBeerProduct: any = false;
  isDisabled: any = false;
  transferPermitTotalBottles:number = 0;
  totalBottleCount:number = 0;
  breakagesData:boolean=true;
  isDisableBothButtons: any = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  loader: any = false;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageStorageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private breakageService: BreakagesService,
    private spinner: NgxSpinnerService,
    public util:UtilService,
    private productionService: ProductionService, private shipmentService: ShipmentsService,
    private messageService: MessageService, private commonService: CommonsService) { }

  ngOnInit(): void {
    this.limit = 10;
    this.offset = 0;
    if (this.data) {
      if (this.data.productCode) {
        const productType: any = this.util.getProductType(this.data.productCode);
        if (productType === 'Beer' || this.data.indent === 'Ctel') {
          this.isBeerProduct = true;
        }
      }
    }
    if(this.data && this.data.tabName){
      this.breakageForm = this.fb.group({
        tpNo: ['', Validators.required],
        damages: this.fb.array([this.createDamages()]),
      });
      this.damages = this.breakageForm.get('damages') as FormArray;
    }
    this.transitForm = this.fb.group({
      tpNo: ['', Validators.required],
      damages: this.fb.array([this.createDamages()]),
    });
    this.damages = this.transitForm.get('damages') as FormArray;
    this.transitForm && this.transitForm.valueChanges.subscribe(el => this.isDisable());
    this.getDamagedCaseCodesDetails();
  }

  createDamages() {
    return this.fb.group({
      caseCode : [''],
      healCodes : '',
      bottleCount : [0, Validators.pattern(/^[0-9]\d*$/)]
    });
  }

  onAddHeals(i) {
    (this.transitForm.controls['damages'] as FormArray).at(i).get('bottleCount').setValue((this.transitForm.controls['damages'] as FormArray).at(i).get('healCodes').value.length);
  }

  addDamages() {
    this.data && this.data.tabName ? (this.breakageForm.controls['damages'] as FormArray).push(this.createDamages()) : (this.transitForm.controls['damages'] as FormArray).push(this.createDamages());
    this.damages = this.data && this.data.tabName ? this.breakageForm.get('damages') as FormArray : this.transitForm.get('damages') as FormArray;
  }

  getOfsDetails(e) {
    if (!e) {
      return true;
    }
    this.isDisableBothButtons = false;
    const tpNo = e
    console.log(e)
    const req_Data = {
      action: 'common_apis',
      q: '{"data": {"action": "check_tp_breakages", "value": "' + tpNo + '"}}'

    }
    this.loader = true;
    this.commonService.getCommonEntites(req_Data).subscribe(result => {
      if(result['check_tp_breakages'] == true){
        this.breakagesData =true;
      } else if (result['check_tp_breakages'] && result['check_tp_breakages'].error) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: result['check_tp_breakages'].error});
        this.isDisableBothButtons = true;
      }
      else{
        this.breakagesData =false;
      }
      this.loader = false;
      console.log(this.breakagesData)
    })
  }

  createTransit() {
    this.spinner.show();
    let data = this.transitForm.value;

    data.damages = data.damages.map((damage,i) => {
      if(!damage.healCodes) {
        delete damage['healCodes'];
      }
      if(!damage.caseCode) {
        delete damage['caseCode'];
      }
      if(!damage.bottleCount) {
        delete damage['bottleCount'];
      }
      return damage;
    })
    data.damages = data.damages.filter(damage => {
      return Object.keys(damage).length>0
    })
    this.saveDisabled = true;
    const req_Data = {
      action : 'add_breakages',
      data 
    };

    this.breakageService.createBreakages(req_Data).subscribe((res: any) => {
      if(res.status){
        this.dialogRef.close('Transit Created Successfully');
      }else if(res.error){
        this.messageService.add({ severity: 'error', summary: 'Error', detail: res.error});
      }
      this.spinner.hide();
    }, (err) => {
      this.error = err.error.message;
    });
  }

  get f(){
    return this.data && this.data.tabName? this.breakageForm.controls : this.transitForm.controls;
  }

  handleHeals(evt,i) {
    (this.breakageForm.controls['damages'] as FormArray).at(i).get('bottleCount').setValue((this.breakageForm.controls['damages'] as FormArray).at(i).get('healCodes').value.length);
  console.log((this.breakageForm.controls['damages'] as FormArray).at(i).get('healCodes').value,'ccccc:::::', evt.value);
  }

  calculateAvailableBottlesforTP(e){
    const tpNo = e.target.value;    
    const req_Data = {
      action: 'common_apis',
    q: '{"data": {"action": "get_tp_details", "value": "' + tpNo + '"}}'
  }
  this.spinner.show();
  this.commonService.getCommonEntites(req_Data).subscribe(result=>{
    this.transferPermitTotalBottles = 0;    
    if(result["get_tp_details"]){
      result["get_tp_details"].consignment.forEach((item)=>{
        item.productDetails.forEach((product)=>{
          const productDetails = this.util.productCodeToDetails(product.productCode);
          this.transferPermitTotalBottles += productDetails.unitsPerCase * product.casesCount;
        });
      });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please check the TP Number'});
      this.transitForm.controls.tpNo.setValue('');
    }
    this.spinner.hide();
  });
  }
  noBreakages(){
    let data:any ={}
    data = this.breakageForm.value.tpNo;
    const req_Data =  {
      action:"no_breakages",
      data:{"tpNo":this.breakageForm.value.tpNo}
    }
  console.log(req_Data)
  if(this.breakageForm.value.tpNo !== ''){
    this.breakageService.createBreakages(req_Data).subscribe((res: any) => {
      if(res.status === 'SUCCESS' || res.status === 'Data Update Successfully'){
        this.dialogRef.close('Created Successfully');
      }else if(res.error){
        this.messageService.add({ severity: 'error', summary: 'Error', detail: res.error});
      }
      this.spinner.hide();
    }, (err) => {
      this.error = err.error.message;
    });
  }else{
    this.messageService.add({ severity: 'error', summary: 'Error', detail: "Please Enter TP number"});

  }

  }
  createBreakageRecord() {
    this.spinner.show();
    let data = this.breakageForm.value;

    data.damages = data.damages.map((damage,i) => {
      if(!damage.healCodes) {
        delete damage['healCodes'];
      }
      if(!damage.caseCode) {
        delete damage['caseCode'];
      }
      if(!damage.bottleCount) {
        delete damage['bottleCount'];
      }
      return damage;
    })
    data.damages = data.damages.filter(damage => {
      return Object.keys(damage).length>0
    })
    this.saveDisabled = true;
    const req_Data = {
      action : 'add_f2_breakages',
      data 
    };

    this.breakageService.createBreakages(req_Data).subscribe((res: any) => {
      console.log(res)
      if(res.status){
        this.dialogRef.close('Breakage Entry Created Successfully');
      }else if(res.error){
        this.messageService.add({ severity: 'error', summary: 'Error', detail: res.error});
      }
      this.spinner.hide();
    }, (err) => {
      this.error = err.error.message;
    });
  }

  getDamagedCaseCodesDetails() {
    if (this.data) {
      if (this.isBeerProduct) {
        this.data.damagedCaseCodes = [];
        this.data.damagedCaseCodes[0] = {};
        this.data.damagedCaseCodes[0].caseCode = this.data.productCode;
      }
      if (this.data.damagedCaseCodes?.length > 0) {
        this.dataSource = new MatTableDataSource(this.data.damagedCaseCodes);
        this.damagedCaseCodesLength = this.data.damagedCaseCodes.length;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }
  }

  onPaginateChange(e) {
    this.limit = e.pageSize;
    this.offset = e.pageIndex * e.pageSize;
    this.getDamagedCaseCodesDetails();
  }

  checkDamagedCasesDetails(){
    if(this.data && this.data.tabName){
      return this.breakageForm.value.damages.some(damageCase => damageCase.caseCode === '');
    }
    return this.transitForm.value.damages.some(damageCase => damageCase.caseCode === '');
  }

  removeUnit(i: number) {
    const control = this.data && this.data.tabName ? <FormArray>this.breakageForm.controls["damages"] : <FormArray>this.transitForm.controls["damages"];
    control.removeAt(i);
  }
  resetBottleCount(i): any {
    let damages: any;
    if (this.data && this.data.tabName) {
      damages = this.breakageForm.get('damages') as FormArray;
    } else {
      damages = this.transitForm.get('damages') as FormArray;
    }
    if (damages) {
      // tslint:disable-next-line:no-string-literal
      damages['controls'][i].controls['bottleCount'].setValue(0);
    }
  }
  isDisabledBottle(i): any {
    let damages: any;
    if (this.data && this.data.tabName) {
      damages = this.breakageForm.get('damages') as FormArray;
    } else {
      damages = this.transitForm.get('damages') as FormArray;
    }
    if (damages) {
      // tslint:disable-next-line:no-string-literal
      if (damages['controls'][i].controls.caseCode.value.length > 15) {
        return true;
      }
    }
    return false;
  }
  isDisable(): any {
    const damage: any = this.data && this.data.tabName ? <FormArray>this.breakageForm.controls["damages"]['controls'] : <FormArray>this.transitForm.controls["damages"]['controls'];
    // || (el.value.bottleCount === '' || el.value.bottleCount <= 0)
    const status = damage.some((el: any) => el.value.caseCode === '' && (el.value.healCodes === '' || el.value.healCodes && el.value.healCodes.length === 0));
    if (status) {
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
      this.totalBottleCount = damage.reduce((acc, cur)=> { return acc += cur.value.bottleCount}, 0);
      if(this.totalBottleCount > this.transferPermitTotalBottles){
      this.isDisabled = true;
      }
    }
  }

}
