import { MessageService } from 'primeng/api';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonsService } from 'src/app/core/services/commons.service';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
@Component({
  selector: 'app-ofs-product-branch-details-dialog',
  templateUrl: './ofs-product-branch-details-dialog.component.html',
  styleUrls: ['./ofs-product-branch-details-dialog.component.scss']
})
export class OfsProductBranchDetailsDialogComponent implements OnInit {

  consignmentDatas: any = [];
  totalDatas: any = [];
  userDetails: any = [];
  distilleryName: any = [];
  distilleryAddress: any = [];
  productBatchForm: FormGroup;
  productInformation: FormArray;
  batchIdListData = [];
  roleName: any;
  duplicateBatch: any = [];


  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<OfsProductBranchDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private shipmentsService: ShipmentsService,
    public utilService: UtilService,
    public commonsService: CommonsService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    console.log('data::::::', this.data);
    this.batchIdListData = this.data.batchIdListData;
    this.productBatchForm = this.fb.group({
      productInformation: this.fb.array([this.updateproductInformation()])
    });

    this.productInformation = this.productBatchForm.get('productInformation') as FormArray;
    this.productBatchForm.controls.productInformation.valueChanges.subscribe(el => this.isDuplicateBatch());

  }
  getBatchFilteredData(): any {
    return this.data.batchIdListData.filter(el => el.brandCode === this.data.productDetails.brandCode);
  }

  shipmentQuantityChange(ctrl){
    return this.batchIdListData.some(batch => (batch?.batchId === ctrl.value.batchId && ctrl.value.quantity > batch.closingBal));
  }

 

  updateproductInformation() {
    return this.fb.group({
      batchId: ['', Validators.required],
      quantity: ['', Validators.required]
    });
  }

  batchIdChange(i, evt) {
    let formValue = this.productBatchForm.get("productInformation").value;
    const batchDatas = this.batchIdListData.filter(batchData => formValue.some(formValues => formValues.batchId === batchData.batchId));
    const found = formValue.some((row, j) => {
      if (j !== formValue.length - 1) {
        if (row.productCode === evt.value) {
          return true;
        }
      }
    });
    if (batchDatas[0]) {
      if (found) {
        batchDatas[0].disabled = true;
      } else {
        batchDatas[0].disabled = false;
      }
    }
  }


  
  addUnit() {
    const control = <FormArray>this.productBatchForm.controls["productInformation"];
    control.push(this.updateproductInformation());
  }


  removeUnit(i: number) {
    const control = <FormArray>this.productBatchForm.controls["productInformation"];
    control.removeAt(i);

    //this.validateForm();  
  }

  addBatches(){
    let productDetailsData = this.productInformation.value.map(batch => {
      return {
        ...this.data.productDetails,
        batchId: 'BT-' + batch.batchId,
        batchApprovedQty: batch.quantity,
        shipmentQty: batch.quantity
      };
    });
    console.log(this.data)
    // let req_Data;
     const req_Data:any = {
        action: this.data.consignmentType === 'Export' ? 'add_batch_export' : 'add_batch_ofs',
        data:{
          tpNo: this.data.tpNo,
          ofsCode: this.data.ofsCode,
          productDetails: productDetailsData
        }
        }
        if (this.data.consignmentType === 'Export') {
          req_Data.data.exportIndentNumber = this.data.exportCode;
          delete req_Data.data.ofsCode;
        }

 
    if (this.roleName === 'Brewery Manager') {
      delete req_Data.data.tpNo;
      req_Data.data.btpNo = this.data.tpNo;
    } else if (this.roleName === 'NonLocal Brewery Manager') {
      delete req_Data.data.tpNo;
      req_Data.data.bipNo = this.data.tpNo;
    } else if (this.roleName === 'NonLocal Supplier Manager') {
      delete req_Data.data.tpNo;
      req_Data.data.ipNo = this.data.ipNo;
    }
    this.shipmentsService.putOfs(req_Data).subscribe(res => {
      this.dialogRef.close('Added batches to products successfully');
      console.log('add batch::::::', res);
    })
  }
  isInValid(blur = false): any {
    let batchesQty = 0;
    // tslint:disable-next-line:no-string-literal
    this.productBatchForm.controls.productInformation['controls'].forEach(el => batchesQty += el.controls.quantity.value);
    if (batchesQty < 1) {
      return true;
    }
    if(this.data.consignmentType === 'Export'){
      if (batchesQty > this.data.productDetails.quantityInCases) {
        if (blur) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please check the quantity, Should not greater than approved quantity' });
        }
        return true;
      }
    }else{
    if ((this.data.productDetails.remainingQuantity && (batchesQty > this.data.productDetails.remainingQuantity)) || (batchesQty > this.data.productDetails.approvedQty)) {
      if (blur) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please check the quantity, Should not greater than approved quantity' });
        }
      return true;
      }
    }
   
    return false;
    // tslint:disable-next-line:no-string-literal
    // this.productBatchForm.controls.productInformation['controls'].some(el => {
    //   if (el.controls.quantity.value < 1 || batchesQty > this.data.productDetails.approvedQty) {
    //       el.controls.quantity.setValue();
    //       this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please check the quantity, Should not greater than approved quantity' });
    //   }
    // });
    // if (qty > this.data.productDetails.approvedQty) {
    //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please check the quantity, Should not greater than approved quantity' });
    //   return false;
    // }
  }
  isValid(): any {
    // tslint:disable-next-line:no-string-literal
    this.productBatchForm.controls.productInformation['controls'].some(el => {
      if (el.controls.quantity < 0
        || el.controls.quantity > this.data.productDetails.approvedQty) {
          el.controls.quantity.setValue();
      }
    });
  }
  isDisableBatch(batchId): any {
    return this.productBatchForm.controls.productInformation['controls'].some(el => el.controls.batchId.value === batchId);
  }
  isDuplicateBatch(): any {
    this.duplicateBatch = false;
    const arr = this.productBatchForm.controls.productInformation['controls'].map(el => el.controls.batchId.value);
    this.duplicateBatch = arr.some((e, i, arr) => arr.indexOf(e) !== i);
    if (this.duplicateBatch) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Batch already exists' });
    }
    
  }
}
