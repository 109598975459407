import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DistilleryShipment } from 'src/app/core/models/shipments/distillery-shipment.model';
import { ShipmentsService } from 'src/app/core/services/shipments.service';

@Component({
  selector: 'app-manage-tp-details',
  templateUrl: './manage-tp-details.component.html',
  styleUrls: ['./manage-tp-details.component.scss']
})
export class ManageTpDetailsComponent implements OnInit {

  displayedColumns: string[] = ['ofsNumber', 'productCode', 'productName', 'orderedQuantity', 'allocatedQuantity'];
  dataSource: MatTableDataSource<DistilleryShipment> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;

  constructor(public dialogRef: MatDialogRef<ManageTpDetailsComponent>, private shipmentsService: ShipmentsService,@Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.getDistilleryShipment();
  }

  getDistilleryShipment() {
    // this.shipmentsService.getDistilleryShipment(this.offset, this.limit, this.search).subscribe((res: any) => {
    //   console.log('res', res);
    //   this.dataSource = new MatTableDataSource(res.data);
    // });

    let tableData=this.data.consignment.map((consignment:any) => {
      let obj={
        ofsNumber:consignment?.ofsCode,
        productCode:'',
        productName:'',
        orderedQuantity:0,
        allocatedQuantity:0
      }
      consignment?.productDetails.map((data:any) =>{
        obj.productCode=data.productCode ? data.productCode : '-';
        obj.productName=data.brandName ? data.brandName : '-';
        obj.orderedQuantity+=data.casesCount ? data.casesCount : 0
        obj.allocatedQuantity+=data.approvedQty ? data.approvedQty : 0
      })
      return obj
    })
    this.dataSource = new MatTableDataSource(tableData);
  }

  dialogClose(): void {
    this.dialogRef.close();
  }
}
