import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Ofs } from 'src/app/core/models/supply-delivery/ofs';
import { Observable } from 'rxjs';


const baseUrl = environment.baseUrl;
const stockReturnUrl = baseUrl+ 'stock_return_drainout'


const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Stock Return DrainOut'] && userModules.mod_feats['Stock Return DrainOut'].features ? userModules.mod_feats['Stock Return DrainOut'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class StockReturnService {

  constructor(private http: HttpClient) { }

  getStockReturnFeatures(payLoad: any = '') {
    const headerDict = {
      feature
    };
    return this.http.get(stockReturnUrl, { headers: headerDict, params: payLoad });
  }
  create(payLoad) {
    return this.http.post(stockReturnUrl, payLoad);
  }
  update(payLoad) {
    return this.http.put(stockReturnUrl, payLoad);
  }
  putOfs(ofsObj){
    return this.http.put<Ofs>(`${stockReturnUrl}`, ofsObj);
  }
  scanBarCode(caseObj): Observable<any> {
    return this.http.put(`${stockReturnUrl}`, caseObj);
  }
  saveShipmentQuantity(shipmentQtyObj):Observable<any> {
    return this.http.put(`${stockReturnUrl}`, shipmentQtyObj);
  }
}
