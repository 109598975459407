import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CommonsService } from 'src/app/core/services/commons.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { PaymentConfomationDialogComponent } from '../../excise-duty/payment-confomation-dialog/payment-confomation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
declare var Razorpay: any;

@Component({
  selector: 'app-label-details',
  templateUrl: './label-details.component.html',
  styleUrls: ['./label-details.component.scss']
})
export class LabelDetailsComponent implements OnInit {
  labelForm: FormGroup;
  saveDisabled: boolean;
  loader: any = false;
  queryParams: any;
 
  constructor(
    private fb: FormBuilder, 
    private commonService: CommonsService, private spinner: NgxSpinnerService,
    public util:UtilService,
    private messageService:MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.labelForm = this.fb.group({
      productCode: ['', Validators.required],
      supplierCode: ['', Validators.required],
      date: [''],
      regId: ['', Validators.required],
      applicationId: ['', Validators.required],
      amount: ['', Validators.required],
      remittanceName: ['Test Payment', Validators.required],
    });
    this.route.queryParams
      .subscribe((params: any) => {
        this.queryParams = JSON.parse(params.q);
        params = JSON.parse(params.q);
        this.labelForm.patchValue({
          productCode : params.PRODUCT_CODE,
          supplierCode: params.SUPPLIER_CODE,
          date: params.DATE ? params.DATE : '',
          regId: params.REG_ID,
          applicationId: params.APPLICATION_ID,
        });
      }
    );
  }
  paynow(): any {
    this.spinner.show();
    let bodyTag: any;
    if (document.getElementsByTagName('body')) {
      bodyTag = document.getElementsByTagName('body')[0];
      bodyTag.style.pointerEvents = 'none';
    } 
    const req_data = {
      action: 'add_label_renewal',
      data: this.labelForm.value
      
    };
    // if(this.paymentForm.value.purpose === 'Excise Duty'){
    //   delete req_data.data.gstAmount
    // }
    this.loader = true;
    this.commonService.paymentResponse(req_data).subscribe((res:any)=>{
      this.loader = false;
      if (res.status && res.status.Success === true) {
        this.payWithRazor(res.status);
        setTimeout(() => {
          this.spinner.hide();
        }, 2000);
      } else {
        if (document.getElementsByTagName('body')) {
          bodyTag = document.getElementsByTagName('body')[0];
          bodyTag.style.pointerEvents = '';
        }
        this.messageService.add({ severity: 'error',summary: 'ERROR', detail: res.status && res.status.error ? res.status.error : 'API error' });
        this.spinner.hide();
      }
    }, err => {
      if (document.getElementsByTagName('body')) {
        bodyTag = document.getElementsByTagName('body')[0];
        bodyTag.style.pointerEvents = '';
      }
      this.loader = false;
      this.spinner.hide();
    });
  }
  payWithRazor(data) {
    let rzpContainer: any;
    const thiss = this;
    const options: any = {
      key: this.util.decrypt(data.razorpay_id),
      amount: data.razorpay_data.data.amount,
      currency: data.razorpay_data.data.currency,
      name: data.razorpay_data.prefill.name ? data.razorpay_data.prefill.name : 'Test',
      description: data.razorpay_data.description,
      image: '',
      order_id: this.util.decrypt(data.order_id),
      theme: {
        color: '#142475'
      },
      handler: (response) => this.razorpaySuccessResponseHandler({ ...response}, thiss),
      modal: {
        "ondismiss": function(){
          thiss.clearPreventClick();
        }
      }
    };
    var rp = new Razorpay(options);
    rp.open();
    if (document.getElementsByClassName('razorpay-container')[0]) {
      rzpContainer = document.getElementsByClassName('razorpay-container')[0];
      rzpContainer.style.pointerEvents = 'all';
    }
    // rp.on('payment.failed', this.razorpayErrorResponseHandler(thiss));
    rp.on('payment.failed', (response) => {
      this.razorpayErrorResponseHandler(response, thiss);
    });
    // this.spinner.hide();
  }
  razorpaySuccessResponseHandler(response, thiss) {
    let bodyTag: any;
    if (document.getElementsByTagName('body')) {
      bodyTag = document.getElementsByTagName('body')[0];
      bodyTag.style.pointerEvents = 'none';
    }
    const rpData = {
      payment_id:response.razorpay_payment_id,
      order_id:response.razorpay_order_id,
      signature_id:response.razorpay_signature,
      data:this.labelForm.value
    }
    const req_data = {
      action: 'label_renewal_payment_verify',
      data: rpData
    };
    this.spinner.show();
    this.commonService.paymentResponse(req_data).subscribe((res:any)=>{
      thiss.clearPreventClick();
      if(res.status.status == true){
        const data = res.status.data;
        data.distilleryName - res.status.distilleryName;
        data.paymentData = {
          PRODUCT_CODE : thiss.queryParams.PRODUCT_CODE,
          SUPPLIER_CODE: thiss.queryParams.SUPPLIER_CODE,
          DATE: thiss.queryParams.DATE ? thiss.queryParams.DATE : '',
          REG_ID: thiss.queryParams.REG_ID,
          APPLICATION_ID: thiss.queryParams.APPLICATION_ID,
          FLAG: 'Y'
        }
        if (!data.paymentData.DATE) {
          delete data.paymentData.DATE;
        }
        this.ngZone.run(() => {
          // const dialogRef = this.util.openModalDialog(PaymentConfomationDialogComponent, data, '65%', '60%', false, false, null);
          const dialogRef = this.dialog.open(PaymentConfomationDialogComponent, {
            width: '65%',
            autoFocus: false,
            disableClose: true,
            data: data
          });
          dialogRef.afterClosed().subscribe((result) => {
            this.messageService.add({ severity: 'success',summary: 'Success', detail: 'Successfully Redirected' });
            thiss.clearPreventClick();
            this.router.navigate(['/login']);
          });
        });
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }
  razorpayErrorResponseHandler(response, thiss) {
    console.log(response);
    if (response && response.error && response.error.reason && response.error.reason === 'payment_failed') {
      if (document.getElementsByClassName('razorpay-container') && document.getElementsByClassName('razorpay-container')[0]) {
        const el: any = document.getElementsByClassName('razorpay-container')[0] as HTMLElement;
        el.style.display = "none";
      }
      const data: any = {
        orderId: response.error.metadata ? response.error.metadata.order_id : '',
        paymentId: response.error.metadata ? response.error.metadata.payment_id : '',
        paymentData: {
          PRODUCT_CODE : thiss.queryParams.PRODUCT_CODE,
          SUPPLIER_CODE: thiss.queryParams.SUPPLIER_CODE,
          DATE: thiss.queryParams.DATE ? thiss.queryParams.DATE : '',
          REG_ID: thiss.queryParams.REG_ID,
          APPLICATION_ID: thiss.queryParams.APPLICATION_ID,
          FLAG: 'N'
        }
      };
      if (!data.paymentData.DATE) {
        delete data.paymentData.DATE;
      }
      thiss.ngZone.run(() => {
        const dialogRef = thiss.dialog.open(PaymentConfomationDialogComponent, {
          width: '65%',
          autoFocus: false,
          disableClose: true,
          data: data
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.messageService.add({ severity: 'success',summary: 'Success', detail: 'Successfully Redirected' });
          thiss.clearPreventClick();
          thiss.router.navigate(['/login']);
        });
      });
    }
  }
  clearPreventClick(): any {
    let bodyTag: any;
    let rzpContainer: any;
    if (document.getElementsByClassName('razorpay-container')[0]) {
      rzpContainer = document.getElementsByClassName('razorpay-container')[0];
      rzpContainer.style.pointerEvents = '';
    }
    if (document.getElementsByTagName('body')) {
      bodyTag = document.getElementsByTagName('body')[0];
      bodyTag.style.pointerEvents = '';
    } 
  }
}
