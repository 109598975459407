import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { HologramsService } from 'src/app/core/services/holograms.service';

@Component({
  templateUrl: './issued-spools-dialog.component.html',
  styleUrls: ['./issued-spools-dialog.component.scss']
})
export class IssuedSpoolsDialogComponent implements OnInit {

  spoolsIssuedForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<IssuedSpoolsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private hologramsService: HologramsService) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
    if(this.data.status === 'Pending'){
      this.spoolsIssuedForm = this.fb.group({
        purpose: [''],
        quantity: [''],
        requestedOn: ['']
      });
    }
    else{
      this.spoolsIssuedForm = this.fb.group({
        healReqIndentId: [''],
        colour: [''],
        purpose: [''],
        quantity: [''],
        requestedOn: ['']
      });
    }
    // if(this.data === 'Issued Spools'){
    //   this.spoolsIssuedForm = this.fb.group({
    //     healReqIndentId: [''],
    //     colour: [''],
    //     purpose: [''],
    //     quantity: [''],
    //     requestedOn: ['']
    //   });
    // }

    if (this.data) {
      this.spoolsIssuedForm.patchValue({
        healReqIndentId: this.data.healReqIndentId,
        colour: this.data.colour,
        purpose: this.data.purpose,
        quantity : this.data.quantity,
        requestedOn: this.data.requestedOn ? new Date(this.data.requestedOn).toLocaleString() : ''
      });
    }
    this.spoolsIssuedForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
  }

  get f(){
    return this.spoolsIssuedForm.controls;
  }

  createHealDailyIndent() {
    // this.saveDisabled = true;
    // const req_data = {
    //   action: 'add_heal_daily_indent',
    //   data: {...this.spoolsIssuedForm.value}
    // };
    // this.hologramsService.createHealDailyIndent(req_data).subscribe((res: any) => {
    //   this.dialogRef.close('Heal Daily Indent Created Successfully');
    // }, (err) => {
    //   this.error = err.error.message;
    // });
  }

}
