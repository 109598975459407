import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  templateUrl: './supplier-shipment-shipment-info-dialog.component.html',
  styleUrls: ['./supplier-shipment-shipment-info-dialog.component.scss']
})
export class SupplierShipmentShipmentInfoDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'no',
    'tpNo',
    'vechicleNo',
    'transporter',
    'issuedDate',
    'validityDate'
  ];
  tableData:any[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  constructor(public dialogRef: MatDialogRef<SupplierShipmentShipmentInfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.tableData.push(this.data);
    this.dataSource = new MatTableDataSource(this.tableData);
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

  getTitle() {
    return this.data.tpNo ? 'TP' : 'IP';
  }
}
