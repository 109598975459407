import { CommonsService } from 'src/app/core/services/commons.service';
import { CsdService } from 'src/app/core/services/csd.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-manage-csd-feature-dialog',
  templateUrl: './manage-csd-feature-dialog.component.html',
  styleUrls: ['./manage-csd-feature-dialog.component.scss']
})
export class ManageCsdFeatureDialogComponent implements OnInit {

  // displayedColumns: string[] = [
  //   'productCode',
  //   'brandName',
  //   'size',
  //   'quantity'
  //   ];

  csdInformation: FormArray;
  csdForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  consignmentTypes = ['CSD', 'CSD Local', 'CSD Export'];
  csdList: any;


  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageCsdFeatureDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private csdService: CsdService, private commonsService: CommonsService,) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.csdForm = this.fb.group({
      consignmentType: [''],
      applicantName: [''],
      destination: [''],
      address: [''],
      licenseNo: [''],
      purpose: [''],
      route: [''],
      DutychallanDate: [''],
      dutyChallanNumber: [''],
      dutyChallanAmount: [''],
      csdInformation: this.fb.array([this.updateCSDInformation()])
    });
    if (this.data && this.data._id) {
      this.csdForm.patchValue({
        consignmentType : this.data.consignmentType,
        applicantName: this.data.applicantName,
        destination: this.data.destination._id,
        address: this.data.address,
        licenseNo: this.data.licenseNo,
        purpose : this.data.purpose,
        route: this.data.route,
        DutychallanDate: this.data.DutychallanDate._id,
        dutyChallanNumber: this.data.dutyChallanNumber,
        dutyChallanAmount: this.data.dutyChallanAmount,
      });
      const csdInformation = this.csdForm.get('csdInformation') as FormArray;
      csdInformation.clear();
      this.data.csdInformation.forEach(b => {
        csdInformation.push(
          this.fb.group({
            checkedValue: b.checkedValue,
            productCode: b.productCode,
            brandName: b.brandName,
            size: b.size,
            quantity: b.quantity
          })
        );
      });
    }
    this.csdInformation = this.csdForm.get('csdInformation') as FormArray;
    this.csdForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
    const paramObj = '?action=common_apis&q={"data": ["get_dist_depot"]}';
    this.commonsService.getList(paramObj).subscribe((data: any) => {
      this.csdList = data.get_dist_depot.csds.map(csd => csd.destination);
    });
  }

  updateCSDInformation(){
    return this.fb.group({
      checkedValue: '',
      productCode: ['', Validators.required],
      brandName: ['', Validators.required],
      size: ['', Validators.required],
      quantity: ['', Validators.required],
    });
  }

  addUnit() {
    const control = <FormArray>this.csdForm.controls["csdInformation"];
    control.push(this.updateCSDInformation());
  }


  removeUnit(i: number) {
    const control = <FormArray>this.csdForm.controls["csdInformation"];
    control.removeAt(i);
  }


  get f(){
    return this.csdForm.controls;
  }

  createCSD() {
    this.saveDisabled = true;
    const req_data ={
      action: 'add_csd_indent',
      data: {...this.csdForm.value}
    };
    this.csdService.createCSD(req_data).subscribe((res: any) => {
      this.dialogRef.close('CSD Created Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  updateCSD() {
    // this.saveDisabled = true;
    // const obj = [{
    //   _id: this.data._id,
    //   update: this.csdForm.value
    // }]
    // this.spiritService.putBatch(obj).subscribe((res: any) => {
    //   this.dialogRef.close('Batch Udated Successfully');
    // }, (err) => {
    //   this.error = err.error.message;
    // });
  }

  dialogClose(): void{
    this.dialogRef.close();
  }

}
