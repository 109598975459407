import { Inject } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { distilleryConstants } from 'src/app/core/constants/distillery-actions.constants';
import { FeaturePermissionService } from 'src/app/core/services/feature-permission.service';

@Component({
  selector: 'app-manage-retailer-feature-dialog',
  templateUrl: './manage-retailer-feature-dialog.component.html',
  styleUrls: ['./manage-retailer-feature-dialog.component.scss']
})
export class ManageRetailerFeatureDialogComponent implements OnInit {
  @Input() featuresData: any;

  displayedColumns: string[] = [
    'role',
    'actions',
    'delete'
    ];
  permissionData =[];
  dataSource = new MatTableDataSource(this.permissionData);

  retailerFeatureForm: FormGroup;
  error: string;
  saveDisabled: boolean;
  roles= ['License', 'Supervisor'];
  actions = distilleryConstants;
  // statuses: { code: string; type: string; }[];

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageRetailerFeatureDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private featurePermissionService: FeaturePermissionService) { }

  ngOnInit(): void {
    console.log('this.data', this.data),
    this.retailerFeatureForm = this.fb.group({
      role: ['', Validators.required],
      actions: ['', Validators.required],
    });

    if (this.data) {
      console.log('data', this.data);
      for(let role in this.data) {
        if(role !== 'featureName' && role !== 'moduleName') {
          this.permissionData.push({ role, actions: this.data[role]});
        }
      }
      this.dataSource = new MatTableDataSource(this.permissionData);
    }
    this.retailerFeatureForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
  }

  ngOnChanges(): void {
  }

  addPermissions(){
    // this.saveDisabled = true;
    this.permissionData.push(this.retailerFeatureForm.value)
  // this.dataSource=this.itemEntryForm.value
    this.dataSource = new MatTableDataSource(this.permissionData);
    this.retailerFeatureForm.reset();
  }


  get f(){
    return this.retailerFeatureForm.controls;
  }

  createPermission() {
    this.saveDisabled = true;
    // this.supplier.blendTankInfo.push(this.blendTanksForm.value);
    const btObj = this.retailerFeatureForm.value;
    delete btObj.status;
    const req_Data = {
      action : '',
      data : btObj
    }
    this.featurePermissionService.createFeaturePermissionFeatures(req_Data).subscribe((res: any) => {
      console.log(res);
      this.dialogRef.close('Successfully Created');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  updateRetailerPermission() {
    this.saveDisabled = true;
    const obj = [{
      _id: this.data._id,
      update: this.retailerFeatureForm.value

    }];
    this.featurePermissionService.editFeaturePermissionFeatures(obj).subscribe((res: any) => {
      console.log(res);
      this.dialogRef.close('Updated Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  removeAt(index: number) {
      const data = this.dataSource.data;
      data.splice(index, 1);
      this.dataSource.data = data;
  }
  // getTitle() {
  //   return this.data && this.data._id ? `Update Blend Tank for ${this.data.tankName}` : 'Create Blend Tank';
  // }


}
