import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-idtreceipt-ofidt-info-dialog',
  templateUrl: './idtreceipt-ofidt-info-dialog.component.html',
  styleUrls: ['./idtreceipt-ofidt-info-dialog.component.scss']
})
export class IDTReceiptOfidtInfoDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<IDTReceiptOfidtInfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    console.log(this.data)

  }

  dialogClose(): void {
    this.dialogRef.close();
  }

}
