import { HologramsService } from 'src/app/core/services/holograms.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';

@Component({
  templateUrl: './scan-spools-dialog.component.html',
  styleUrls: ['./scan-spools-dialog.component.scss']
})
export class ScanSpoolsDialogComponent implements OnInit {

  assignSpools: string[] = [];
  spoolsCreationForm: FormGroup;
  error = '';
  bottleInfo;
  submitDisabled: boolean;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ScanSpoolsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private messageService: MessageService, private hologramsService: HologramsService) { }

  ngOnInit(): void {
    this.submitDisabled = true;
    this.spoolsCreationForm = this.fb.group({
      assignSpools: ''
    });

    this.spoolsCreationForm.valueChanges.subscribe((change) => {
      if (!change.assignSpools.length) {
        this.submitDisabled = true;
        return;
      }
      const inValidValues = change.assignSpools.filter(item => {
        if (!(/^[ A-Z0-9]*$/).test(item) || item.length !== 35) {
          return item;
        }
        return;
      });
      this.submitDisabled = !!inValidValues.length;
    })
  }

  addQR() {
    this.assignSpools.push(this.spoolsCreationForm.get('healQR').value);
    this.spoolsCreationForm.patchValue({
      assignSpools: ''
    });
  }

  onspoolsCreation() {
    const obj = {
      action: 'issue_spools',
      data: {
        healReqIndentId: this.data.healReqIndentId,
        spools: this.spoolsCreationForm.get('assignSpools').value,
        purpose: this.data.purpose,
        entityId: this.data.entityId
      }
    };
    this.hologramsService.putHealInventory(obj).subscribe((res: any) => {
      console.log(res);
      if(res.error) {
      //  this.error = 'Invalid Spool';
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid Spool' });
      }
      else {
        this.error = '';
        this.dialogRef.close('Heal Inventory Created Successfully');
      }
    }, (err) => {
      this.error = err.error.message;
    });
  }

  disableHealSubmit() {
    return this.spoolsCreationForm.get('assignSpools').value.length !== this.data.unitsPerCase;
  }

  getTitle() {
    return `Add Heal QRs (Please add ${this.data.unitsPerCase} assignSpools)`;
  }

  onRemove(e) {
    if(e.target.value === undefined) {
      this.onAddSpools(null);
    }
  }

  onAddSpools(event) {
    this.submitDisabled = !(this.spoolsCreationForm.get('assignSpools').value.length <= this.data.quantity/20000);
    // this.Spools.push(event.value);
  }

  onAddHeals(event) {
    console.log('on add', event);
    if (event.value.match(/^\d{17}$/)) {
      console.log('inside validation');
      
    } else {
      this.spoolsCreationForm.get('assignSpools').value.pop();
      this.messageService.add({
        severity: 'warninig',
        summary: 'Please add valid Heals',
      });
    }
  }

}
