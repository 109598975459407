import { UtilService } from './../../../../core/utils/utility-helper';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CommonsService } from 'src/app/core/services/commons.service';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { MessageService } from 'primeng/api';

@Component({
  templateUrl: './manage-tp-request-dialog.component.html',
  styleUrls: ['./manage-tp-request-dialog.component.scss']
})
export class ManageTpRequestDialogComponent implements OnInit {

  tpRequestForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  title: any = 'Create';
  saveDisabled: boolean;
  public routeFilterCtrl: FormControl = new FormControl();
  consignmentTypes = ['OFS', 'CSD','Export', 'CSD Local', 'CSD Export', 'Export Non-Local', 'Export IST', 'Import Permit'];
  routes = [];
  public filteredRoutes: ReplaySubject<any> = new ReplaySubject<any>(1);
  public _onDestroy = new Subject<void>();
  @ViewChild('singleRouteSelect') singleRouteSelect: MatSelect;
  routeList: any;
  user: any;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageTpRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private shipmentsService: ShipmentsService, private commonsService: CommonsService, public utilService: UtilService,
    private messageService: MessageService) {
    this.user = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
  }

  ngOnInit(): void {
    if (this.user.entity_type === 'Depot') {
      this.consignmentTypes = ['ICDC', 'OFIDT'];
    }
    this.tpRequestForm = this.fb.group({
      consignmentType: ['',Validators.required],
      route: [''],
      vehicleNo: ['',Validators.required],
      transporterName: ['',Validators.required],
      lrNumber: [''],
      lrDate: [''],
      invoiceNo: ['',Validators.required],
      estimatedDeliveryTime: this.user.role === 'Data Processing Officer' ? ['',Validators.required] : [''],
      distance: this.user.role === 'Data Processing Officer' ? ['',Validators.compose([Validators.required,Validators.pattern("^[0-9]*$")])] : [''],
      driverName:['',Validators.required],
      driverNumber:['',Validators.required]
    });
    if (this.data) {
      this.title = 'Update';
      const tpDetails = this.data.transporterDetails[this.data.transporterDetails.length - 1] ? this.data.transporterDetails[this.data.transporterDetails.length - 1] : this.data.transporterDetails;
      this.tpRequestForm.patchValue({
        consignmentType: this.data.consignmentType,
        route: this.data.route,
        vehicleNo: tpDetails ? tpDetails.vehicleNo : '',
        transporterName: tpDetails ? tpDetails.transporterName : '',
        lrNumber: tpDetails ? tpDetails.lrNumber : '',
        lrDate: tpDetails ? new Date(tpDetails.lrDate).toISOString() : '',
        invoiceNo: tpDetails.invoiceNo ? tpDetails.invoiceNo : this.data.invoiceNo,
        estimatedDeliveryTime: this.data.estimatedDeliveryTime,
        distance:this.data.distance,
        driverName: tpDetails.driverName,
        driverNumber:tpDetails.driverNumber,
      });
      // if (this.data.status !== 'Created') {
      //   this.disableAllControls();
      // } else {
      //   this.disableControls();
      // }
      this.consignmentTypeChange();
    }
    // && this.data.status !== 'InTransit'
    if (this.data && this.data.status !== 'Created' && this.data.status !== 'InProgress') {
      this.tpRequestForm.disable();
    }
    this.tpRequestForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
    //let paramObj ='?action=common_apis&q={"data": ["get_routes"]}'
  /*   let paramObj = {
      action: 'common_apis',
      q: '{"data": ["get_routes"]}'
    }
    this.commonsService.getRoutes(paramObj).subscribe(result => {
      console.log(result, "54::::::::::")
      this.routeList = result.get_routes;
      this.routes = result.get_routes.map(route => route.routeName);
      this.filteredRoutes.next(this.routes.slice());
    }) */

    // listen for search field value changes
    this.routeFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRoutes();
      });
  }

  get f() {
    return this.tpRequestForm.controls;
  }

  filterRoutes() {
    if (!this.routes || (this.routes && this.routes.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.routeFilterCtrl.value;
    if (!search) {
      this.filteredRoutes.next(this.routes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredRoutes.next(
      this.routes.filter(name => name?.routeName?.toLowerCase().indexOf(search) > -1)
    );
  }

  estDate(date) {
    // console.log(date,"112::::::")
  }
  onChangeConsType(): any {
    this.tpRequestForm.controls.route.setValue('');
    this.tpRequestForm.controls.distance.setValue('');
    this.tpRequestForm.controls.estimatedDeliveryTime.setValue('');
    this.consignmentTypeChange();
  }
  consignmentTypeChange() {    
    let paramObj = {
      action: 'common_apis',
      q: '{"data": {"action": "get_routes","value": "'+this.tpRequestForm.value.consignmentType+'"}}'
    }
    this.commonsService.getRoutes(paramObj).subscribe(result => {
      this.routes = [];
      this.filteredRoutes.next([]);
      this.routeList = result.get_routes;
      this.routes = result.get_routes.map(route => route);
      this.filteredRoutes.next(this.routes.slice());
      if (this.routeList && this.routeList.length === 1) {
        this.tpRequestForm.patchValue({
          route: this.routes[0],
        });
        this.changeRoute(this.routes[0]);
      }
    })
  }

  createTPRequest() {
    this.saveDisabled = true;
    let data = { ...this.tpRequestForm.value };
    this.routeList.forEach(route => {

      if (route.routeName == data.route) {
        data['destnEntityId'] = route.destinationLocation;
        data['destnEntityName'] = route.destinationLocation.split('-')[1];
        data['distance'] = this.tpRequestForm.get('distance').value;
        data['estimatedTime'] = route.estimatedTime;
        data['routeVia'] = route.routeVia;
        data['sourceEntityName'] = route.sourceLocation.split('-')[1];
        console.log("data['destnEntityId'] :: ", data['destnEntityId'], "route.destnEntityId :: ", route.destnEntityId, "data['destnEntityName'] :: ", data['destnEntityName'], "route.destinationLocation :: ", route.destinationLocation)
      }
    })

    const req_data = {
      action: 'add_new_tp',
      data
    };
    console.log(req_data, '106:::');
    this.shipmentsService.createTPRequest(req_data).subscribe((res: any) => {
      if (res && res.status && (res.status === 'SUCCESS') || (res.status === 'Successfully added tp')) {
        this.dialogRef.close('TP Request Successfully');
      } else {
        this.messageService.add({ severity: 'error', detail: res && res.status && res.status.error ? res.status.error : 'Not Created' });
      }
    }, (err) => {
      this.error = err.error.message;
    });

  }

  selectDate(type: string, event: MatDatepickerInputEvent<Date>) {
    // console.log(event, type, "124");
    /*  this.tpRequestForm.patchValue({
       lrDate: event.value
     }); */

  }
  setInitialValue() {
    this.filteredRoutes
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleRouteSelect.compareWith = (a, b) => a && b && a === b;
      });

  }
  updateTPRequest() {


    this.saveDisabled = true;
    let data: any = {
      "route": this.tpRequestForm.get('route').value,
      "consignmentType": this.tpRequestForm.get('consignmentType').value,
      "tpNo": this.data.tpNo,
      distance : this.tpRequestForm.get('distance').value,
      // "vehicleNo": this.tpRequestForm.get('vehicleNo').value,
      "estimatedDeliveryTime": this.tpRequestForm.get('estimatedDeliveryTime').value,
      invoiceNo: this.tpRequestForm.get('invoiceNo').value,
      "transporterDetails": [{
        vehicleNo: this.tpRequestForm.get('vehicleNo').value,
        lrDate: this.tpRequestForm.get('lrDate').value,
        lrNumber: this.tpRequestForm.get('lrNumber').value,
        transporterName: this.tpRequestForm.get('transporterName').value,
      }

      ]

    };
    if (this.data && this.data.btpNo) {
      delete data.tpNo;
      data.btpNo = this.data.btpNo ? this.data.btpNo : ''
    } else if (this.data && this.data.bipNo) {
      delete data.tpNo;
      data.bipNo = this.data.bipNo ? this.data.bipNo : ''
    }

    this.routeList.forEach(route => {

      if (route.routeName == data.route) {
        data['destnEntityId'] = route.destnEntityId;
        data['destnEntityName'] = route.destinationLocation;
        data['distance'] = route.distance;
        data['estimatedTime'] = route.estimatedTime;
        data['routeVia'] = route.routeVia;
        console.log("data['destnEntityId'] :: ", data['destnEntityId'], "route.destnEntityId :: ", route.destnEntityId, "data['destnEntityName'] :: ", data['destnEntityName'], "route.destinationLocation :: ", route.destinationLocation)
      }
    })

    const req_data = {
      action: 'edit_tp',
      data
    };
    console.log(req_data, '106:::');
    this.shipmentsService.updateTPRequest(req_data).subscribe((res: any) => {
      if (res && res.status && res.status === 'SUCCESS') {
        this.dialogRef.close('TP Updated Successfully');
      } else {
        this.messageService.add({ severity: 'error', detail: res && res.status && res.status.error ? res.status.error : 'Not Created' });
      }
    }, (err) => {
      this.error = err.error.message;
    });

    // this.saveDisabled = true;
    // const obj = [{
    //   _id: this.data._id,
    //   update: this.tpRequestForm.value
    // }]
    // this.spiritService.putBatch(obj).subscribe((res: any) => {
    //   this.dialogRef.close('Batch Udated Successfully');
    // }, (err) => {
    //   this.error = err.error.message;
    // });
  }
  changeRoute(value): any {
    const routeData: any = this.routeList.filter(route => route.routeName === value.routeName && route.distance === value.distance);
    if (routeData && routeData.length) {
      this.tpRequestForm.patchValue({
        estimatedDeliveryTime: routeData[0].estimatedTime ? routeData[0].estimatedTime : '',
        distance: routeData[0].distance ? routeData[0].distance : ''
      });
    }
    this.disableControls();
  }
  disableControls(): any {
    this.tpRequestForm.controls.estimatedDeliveryTime.disable();
    // this.tpRequestForm.controls.distance.disable();
    // if (this.tpRequestForm.controls.estimatedDeliveryTime.value) {
    //   this.tpRequestForm.controls.estimatedDeliveryTime.disable();
    // }
    // if (this.tpRequestForm.controls.distance.value) {
    //   this.tpRequestForm.controls.distance.disable();
    // }
  }
  disableAllControls(): any {
    Object.keys(this.tpRequestForm.controls).forEach(key => {
      this.tpRequestForm.controls[key].disable();
    });
  }
}
