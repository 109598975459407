import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { ExciseLicenseeService } from 'src/app/core/services/excise-licensee.service';

@Component({
  selector: 'app-edit-registration-for-twof-dialog',
  templateUrl: './edit-registration-for-twof-dialog.component.html',
  styleUrls: ['./edit-registration-for-twof-dialog.component.scss']
})
export class EditRegistrationForTwofDialogComponent implements OnInit {

  registrationForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  offset: number;
  genderType = ['Male', 'Female', 'TG'];
  addressIds = ['Aadhaar', 'Driving License', 'Voter Id', 'Passport', 'PAN Card']
  twoFDetails: any;


  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef < EditRegistrationForTwofDialogComponent > ,
              @Inject(MAT_DIALOG_DATA) public data, private exciseLicenseeService: ExciseLicenseeService) {}

  ngOnInit(): void {
    this.twoFDetails = this.data && this.data.get_vendor_details ? this.data.get_vendor_details[0]:'';
    console.log('this.data', this.data);
    this.registrationForm = this.fb.group({
      revenueDistrict: ['', [Validators.required, ]],
      exciseDistrict: ['', [Validators.required, ]],
      depot: ['', [Validators.required, ]],
      exciseCircle: ['', [Validators.required, ]],
      locationZone: ['', [Validators.required, ]],
      licenseeCategory: ['', [Validators.required, ]],
      licenseeType: ['', [Validators.required, ]],
      licenseeCode: ['', [Validators.required, ]],
      licenseeAmount: ['', [Validators.required, ]],
      licenseDate: ['', [Validators.required, ]],
      expiryDate: ['', [Validators.required, ]],
      apgstNo: ['', [Validators.required, ]],
      apgstDate: ['', [Validators.required, ]],
      incomeTax: ['', [Validators.required, ]],
      purchaseTax: ['', [Validators.required, ]],
      tcsLimit: ['', [Validators.required, ]],
      tcsValidityDate: ['', [Validators.required, ]],
      retailerCode: ['', [Validators.required, ]],
      gazetteCode: ['', [Validators.required, ]],
      vbCode: ['', [Validators.required, ]],
      professionalTax: ['', [Validators.required, ]],


      licenseeName: ['', [Validators.required, ]],
      panNo: ['', [Validators.required, ]],
      aadharNo: ['', [Validators.required, ]],
      tinNo: ['', [Validators.required, ]],
      dob: ['', [Validators.required, ]],
      gender: ['', [Validators.required, ]],

      mobileNo: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      pinCode: ['', [Validators.required, ]],
      address: ['', [Validators.required, ]],
      shopName: ['', [Validators.required, ]],
      fathersName: ['', [Validators.required, ]],
      addressNo: ['', [Validators.required, ]],
      addressId: ['', [Validators.required, ]],


      nowkarnamaId: ['', [Validators.required, ]],
      name: ['', [Validators.required, ]],
      fatherName: ['', [Validators.required, ]],
      addressProofId: ['', [Validators.required, ]],
      addressProofNo: ['', [Validators.required, ]],
      village: ['', [Validators.required, ]],
      mandal: ['', [Validators.required, ]],
      district: ['', [Validators.required, ]],

      routeName: ['', [Validators.required, ]],
      source: ['', [Validators.required, ]],
      destination: ['', [Validators.required, ]],
      routeDescription: ['', [Validators.required, ]],
    });
    if (this.twoFDetails) {
      this.registrationForm.patchValue({
        revenueDistrict: this.twoFDetails.address.revenue_district,
        exciseDistrict: this.twoFDetails.exciseDistrict,
        depot: this.twoFDetails.assignedDepot,
        exciseCircle: this.twoFDetails.address.excise_circle,
        locationZone: this.twoFDetails.address.zone,
        licenseeCategory : this.twoFDetails.licenseeCategory,
        licenseeType: this.twoFDetails.locationType,
        licenseeCode: this.twoFDetails.licenseeCode,
        licenseeAmount : this.twoFDetails.licenseeAmount,
        licenseDate: this.twoFDetails.licenseDate,
        expiryDate: this.twoFDetails.expiryDate,
        apgstNo : this.twoFDetails.apgstNo,
        apgstDate : this.twoFDetails.apgstDate,
        incomeTax : this.twoFDetails.incomeTax,
        purchaseTax : this.twoFDetails.purchaseTax,
        tcsLimit : this.twoFDetails.tcsLimit,
        tcsValidityDate : this.twoFDetails.tcsValidityDate,
        retailerCode : this.twoFDetails.vendorId,
        gazetteCode : this.twoFDetails.gazetteCode,
        vbCode : this.twoFDetails.vbCode,

        professionalTax : this.twoFDetails.professionalTax,
        licenseeName: this.twoFDetails.licenseeName,
        panNo: this.twoFDetails.panNo,
        aadharNo : this.twoFDetails.aadharNo,
        tinNo: this.twoFDetails.tinNo,
        dob: this.twoFDetails.dob,
        gender : this.twoFDetails.gender,
        mobileNo : this.twoFDetails?.address?.phoneNumbers[0]?.number.replace(/\D/g, ''),
        pinCode : this.twoFDetails.address.pincode,
        address : this.twoFDetails.address.address,
        shopName : this.twoFDetails.name,
        fathersName : this.twoFDetails.fathersName,
        addressNo : this.twoFDetails.addressNo,
        addressId : this.twoFDetails.addressId,

        nowkarnamaId: this.twoFDetails.nowkarnamaId,
        name: this.twoFDetails.name,
        fatherName: this.twoFDetails.fatherName,
        addressProofId : this.twoFDetails.addressProofId,
        addressProofNo: this.twoFDetails.addressProofNo,
        village: this.twoFDetails.address.village,
        mandal : this.twoFDetails.address.mandal,
        district : this.twoFDetails.address.revenue_district,

        routeName : this.twoFDetails.routeName,
        source: this.twoFDetails.source,
        destination : this.twoFDetails.destination,
        routeDescription: this.twoFDetails.routeDescription,
      });
    }
    this.registrationForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
  }

  get f() {
    return this.registrationForm.controls;
  }

  onSave() {
    const obj ={
      action: 'edit_vendor_2f_details',
      data: { ...this.registrationForm.value }
    }
    this.exciseLicenseeService.updateLicensee(obj).subscribe(res => {
      console.log('res', res);
    })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  dialogClose(): void{
    this.dialogRef.close();
  }

}
