import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Distillery } from '../models/distillery-info/distillery.model';
import { GateRegister } from '../models/distillery-info/gate-register.model';
import { Observable } from 'rxjs';
import { BottlingLine } from '../models/distillery-info/bottling-line';
import { BlendTank } from '../models/distillery-info/blendTank.model';
import { License } from '../models/distillery-info/license.model';
import { User } from '../models/distillery-info/user.model';

const baseUrl = environment.baseUrl;
const distilleryInfoUrl = baseUrl + 'distillery_info';
const distilleryUrl = baseUrl + 'distilleryinfo/distilleries';
const licenseUrl = baseUrl + 'licence';
const userUrl = baseUrl + 'users';
const blendTankUrl = baseUrl + 'distilleryinfo/blendtanks';
const gateRegisterUrl = baseUrl + 'gateregisters';
const bottlingLineUrl = baseUrl + 'distilleryinfo/bottlinglines';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Distillery Info'] && userModules.mod_feats['Distillery Info'].features ? userModules.mod_feats['Distillery Info'].features.map(feat => feat.feature_name) : [];


@Injectable({
  providedIn: 'root',
})
export class DistilleryInfoService {
  currentUser: any;
  user: any;
  feature: any;
  bottlingLines: any;
  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(
      sessionStorage.getItem('lappCurrentUserDetails')
    );
  }

  getDistilleryInfo(all?: string): Observable < any > {
    const paramObj: any = {};
    all ? (paramObj.all = all) : (all = null);
    return this.http.get(distilleryUrl, {params: paramObj});
  }

  putDistilleryInfo(distilleryDetails) {
    return this.http.put(distilleryUrl, distilleryDetails);
  }

  createGateRegister(gateRegisterobj: any) {
      return this.http.post(gateRegisterUrl, gateRegisterobj);
  }
  getGateRegister(offset, limit, search?: string): Observable < any > {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? (paramObj.limit = limit) : (limit = null);
    search ? (paramObj.search = search) : (search = null);
    return this.http.get(gateRegisterUrl, {params: paramObj});
  }

  // putGateRegister(gateRegisterObj){
  //   return this.http.put<GateRegister>(`${gateRegisterUrl}`, gateRegisterObj)
  // }

  createBottlingLine(obj: any) {
    return this.http.post(distilleryInfoUrl, obj);
  }
  getBottlingLine(offset, limit, search?: string, status?: string): Observable < any > {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? (paramObj.limit = limit) : (limit = null);
    search ? (paramObj.search = search) : (search = null);
    status ? (paramObj.status = status) : (status = null);
    return this.http.get(bottlingLineUrl, {params: paramObj});
  }

  putBottlingLine(bottlingLineObj) {
    return this.http.put < any > (`${distilleryInfoUrl}`, bottlingLineObj);
  }
  getLicense(offset, limit, search?: string): Observable < any > {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? (paramObj.limit = limit) : (limit = null);
    search ? (paramObj.search = search) : (search = null);
    return this.http.get < License[] > (licenseUrl, {params: paramObj});
  }

  createLicense(licenseDetail: License) {
    return this.http.post(distilleryInfoUrl, licenseDetail);
  }

  createInsuranceDetails(reqPayload: any) {
    return this.http.post(distilleryInfoUrl, reqPayload);
  }

  putLicense(licenseDetails) {
    return this.http.put(distilleryInfoUrl, licenseDetails);
  }

  putInsuranceDetails(reqPayload: any) {
    return this.http.put(distilleryInfoUrl, reqPayload);
  }

  getUsers(offset, limit, search?: string): Observable < any > {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? (paramObj.limit = limit) : (limit = null);
    search ? (paramObj.search = search) : (search = null);
    return this.http.get < User[] > (userUrl, {params: paramObj});
  }

  putUser(userDetail) {
    return this.http.put(userUrl, userDetail);
  }

  createUser(userDetail: any) {
    return this.http.post(distilleryInfoUrl, userDetail);
  }

  getBlendTanks(offset, limit, search?: string, status?: string): Observable < any > {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? (paramObj.limit = limit) : (limit = null);
    search ? (paramObj.search = search) : (search = null);
    status ? (paramObj.status = status) : (status = null);
    return this.http.get < BlendTank[] > (blendTankUrl, {params: paramObj});
  }

  createBlendTank(obj: any) {
    return this.http.post(distilleryInfoUrl, obj);
  }

  putBlendTank(blendTankDetail) {
    return this.http.put(distilleryInfoUrl, blendTankDetail);
  }

  getDistilleryInfoFeature(){
    const headerDict = {
      feature
    }
    return this.http.get(distilleryInfoUrl, { headers: headerDict });
  }

  getTanks() {
    console.log(baseUrl, distilleryInfoUrl,  '135:::')
    const req = {
        "data": { "capacity": "2500", "tankType": "Blend Tank", "status": "EMPTY"},
        "action": "get_tanks"
    }
    return this.http.get(`${distilleryInfoUrl}?action=get_tanks&q={"tankType":"Blend Tank","status":"Empty"}`);
  }
  getFeatureData(feature) {
    const headerDict = {
      feature
    }
    return this.http.get(distilleryInfoUrl, { headers: headerDict });
  }
}
