import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CommonsService } from 'src/app/core/services/commons.service';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { takeUntil } from 'rxjs/operators';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { ImportService } from 'src/app/core/services/import.service';

@Component({
  selector: 'app-manage-sacramental-wine-dialog',
  templateUrl: './manage-sacramental-wine-dialog.component.html',
  styleUrls: ['./manage-sacramental-wine-dialog.component.scss']
})
export class ManageSacramentalWineDialogComponent implements OnInit {
  sacramaentalIndentForm: FormGroup;
  features: any;
  featuresData: any;
  cvdChallaData:any=[];
  ifChallanData:any=[];
  ealChallanData:any=[];
  productsList:any=[];
  showPrint: any = false;
  printId: any = '';
  locationsList:any=[];
  public locationFitlerCtrl: FormControl = new FormControl();
  public filteredLocations: ReplaySubject<any> = new ReplaySubject<any>(1);
  @Output() refreshPage = new EventEmitter<boolean>();
  challansData:any=[];

  displayedColumns: string[] = [
    // "select",
    'no',
    "transType",
    "initialAmount",
    "depositedAmount",
    "amountUsed",
    "remainingAmount"
  ];

  displayedColumnsProducts: string[] = [
    // "select",
    'no',
    "productCode",
    "brandName",
    "size",
    "packType",
    "upc",
    "strength",
    "qtyInCase",
    // "qtyInBls",
    // "qtyInPls",
    "ofsImportPrice",
    "value"
  ];
  depotNames:any = [];
  entityType:any;
  today:any=new Date()
  compositionAddFields: any = [];
  cvdSelection = new SelectionModel<any>(true, []);
  ifSelection = new SelectionModel<any>(true, []);
  EalSelection = new SelectionModel<any>(true, []);
  productSelection = new SelectionModel<any>(true, []);
  importFeeData: MatTableDataSource<any> = new MatTableDataSource([]);
  cvdData: MatTableDataSource<any> = new MatTableDataSource([]);
  ealData: MatTableDataSource<any> = new MatTableDataSource([]);
  productsData: MatTableDataSource<any> = new MatTableDataSource([]);
  public filteredDepotNames: ReplaySubject<any> = new ReplaySubject<any>(1);
  public ifsDepotFilterCtrl: FormControl = new FormControl();
  public _onDestroy = new Subject<void>();
  @ViewChild('singleDepotSelect') singleDepotSelect: MatSelect;
  roleName:any;
  totalValue:any;
  totalBottles:any;
  loader: any = false;
  selectedLocation:any;


  constructor(private fb: FormBuilder,
    private dialogRef: MatDialogRef<ManageSacramentalWineDialogComponent>,
    private shipmentsService: ShipmentsService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private commonsService: CommonsService,
    public util: UtilService,
    private myElement: ElementRef,
    private importService:ImportService,
    @Inject(MAT_DIALOG_DATA) public data) { 
      this.entityType = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).entity_id;
      this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    }

  ngOnInit(): void {
    this.sacramaentalIndentForm = this.fb.group({
      detailsOfLicenseHeld: ['', [Validators.required, ]],
      destnEntityId:['',Validators.required],
      sourceEntityId: ['', [Validators.required, ]],
      depotCode: ['', [Validators.required, ]],
      route: ['', [Validators.required, ]],
      validityDate: ['', [Validators.required, ]],
      importFeeOpening: ['', [Validators.required, ]],
    });
    console.log(this.data)
    if (this.data) {
      this.sacramaentalIndentForm.patchValue({
        destnEntityId: this.data.destnEntityId,
        detailsOfLicenseHeld: this.data.detailsOfLicenseHeld,
        sourceEntityId: this.data.supplierName,
        depotCode: this.data.depotCode,
        route: this.data.route,
        validityDate: this.data.validityDate,
        importFeeOpening: this.data.importFeeOpening
      });
      this.productsData =this.data.productDetails;
    }
    if(this.data){
      this.data.productDetails.map((item: any) => {
       if (item.bottlesQuantity) {
          item.approvedBottles = item.bottlesQuantity;
        }
      });
    }
    if(!this.data){
      this.getData();
      this.getDepot();
      this.getProducts();
    }

  }
  filterLocations() {
    if (!this.locationsList || (this.locationsList && this.locationsList.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.locationFitlerCtrl.value;
    if (!search) {
      this.filteredLocations.next(this.locationsList);
      return;
    } else {
      search = search.toLowerCase();
    }
    console.log(this.filteredLocations)
    // filter the banks
    this.filteredLocations.next(
      this.locationsList.filter(el => JSON.stringify(el.locationCode).toLowerCase().indexOf(search) > -1)
    );
  }
  addComposition() {

    this.compositionAddFields.push({
      brandName:'',
      size: '',
      strength: '',
      bottlesQuantity: '',
      quantityBls:'',
      quantityPls: '',
      rate: '',
      totalValue: '',
    });
  }
  deleteComposition(i) {
    this.compositionAddFields.splice(i, 1);
    this.getFormValues()
  }
  getFormValues(){
    this.totalValue =0;
    this.totalBottles=0;
    this.compositionAddFields.forEach((item:any)=>{
      this.totalValue += item.totalValue;
      this.totalBottles += item.bottlesQuantity;
    })
  }
getDepot(){
  let paramObj = '?action=common_apis&q={"data": ["get_dist_depot","get_route_src"]}';
  this.commonsService.getList(paramObj).subscribe(data =>  {
    this.depotNames = data['get_dist_depot']['depots'];
    this.filteredDepotNames.next(this.depotNames.slice());

  })
  this.ifsDepotFilterCtrl.valueChanges
  .pipe(takeUntil(this._onDestroy))
  .subscribe(() => {
    this.filterDepots();
  });
}

calculateBlsPls(field,i){
  const blsValue = (field.size * field.bottlesQuantity)/1000;
  this.compositionAddFields[i]['quantityBls'] = blsValue;
  const plsValue = (this.compositionAddFields[i]['quantityBls'] * (field.strength/100));
  this.compositionAddFields[i]['quantityPls'] = plsValue;


}
dataFill(data) {
  let flag = false;
  Object.keys(data).forEach(val => {
    if (!flag) {
      flag = !data[val] && val !== 'brandName' && val !== 'size' &&  val !== 'strength' && val !== 'bottlesQuantity' && val !== 'rate'
    }

  })
  return flag;
}

filterDepots() {
  if (!this.depotNames || (this.depotNames && this.depotNames.length == 0)) {
    return;
  }
  // get the search keyword
  let search = this.ifsDepotFilterCtrl.value;
  if (!search) {
    this.filteredDepotNames.next(this.depotNames.slice());
    return;
  } else {
    search = search.toLowerCase();
  }
  // filter the banks
  this.filteredDepotNames.next(
    this.depotNames.filter(name => name.toLowerCase().indexOf(search) > -1)
  );
}
checkLocationId(event:any){
  console.log(event)
  this.selectedLocation = event.value;
  let challansData:any=[]
  // this.ifChallanData.forEach((challan:any)=>{
  //   challan.matchLocationName = `${challan.locationID}-${challan.locationName}`;
  //   if(challan.challanType === 'ImportFee' && this.selectedLocation === challan.matchLocationName){
  //     challansData.push(challan)
  //     this.importFeeData = challansData;
  //     console.log(this.importFeeData)
  //     this.sacramaentalIndentForm.controls.importFeeOpening.setValue(challan.remainingAmount);
  //   }
  // })

  const req_Data: any = {
    action: 'common_apis',
    q: `{"data": {"action": "get_pastor_challan_data", "value": "${this.selectedLocation}"}}`
  }
  console.log(req_Data)
  this.spinner.show();
  this.commonsService.getCommonEntites(req_Data).subscribe((res:any) => {
   this.challansData = res['get_pastor_challan_data'];
   console.log(this.challansData.length,this.challansData[0].remainingAmount)
   if(this.challansData && this.challansData.length <= 1){
    this.sacramaentalIndentForm.controls.importFeeOpening.setValue(this.challansData[0].remainingAmount);
    this.importFeeData = this.challansData;
   }
   
    this.spinner.hide();
  })

}
selectFee(e:any){
  let challansData:any=[]
  challansData.push(e.value)
  this.importFeeData = challansData;
  console.log(this.importFeeData)
}
getData(){

    this.spinner.show();
    const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).mod_feats;
    this.features = userModules['Shipment'].features;
    this.importService.getIndents().subscribe(res => {
      this.featuresData = res;
      console.log(res)
      if(this.featuresData['Pastors Location']){
        this.locationsList = res['Pastors Location'];
        this.locationsList.map((data:any)=>{
          data.locationCode = `${data.locationID}-${data.locationName}`
        })
        this.filterLocations();
        this.locationFitlerCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterLocations();
        });
      }
      if(this.featuresData['Add Challans']) {
        console.log(this.featuresData['Add Challans'])
       this.ifChallanData = res['Add Challans']
      }
      this.spinner.hide();
    })

}

  getProducts(){
    let qData = {
      "data": { "value": this.entityType, "action": "get_supplier_brands" }
    };
    const req_Data = {
      "action": "common_apis",
      "q": JSON.stringify(qData)
    };
    this.commonsService.getCommonEntites(req_Data).subscribe((res) => {
    this.productsList =  res['get_supplier_brands'];
    this.productsData = this.productsList;
    })
  }
  isAllCvdSelected() {
    const cvdSelected = this.cvdSelection.selected.length;
    const numRows = this.cvdChallaData.length;
    return cvdSelected === numRows;
  }
   /** Selects all rows if they are not all selected; otherwise clear selection. */
   masterCvdToggle() {
    this.isAllCvdSelected() ?
      this.cvdSelection.clear() :
      this.cvdChallaData.forEach(row => this.cvdSelection.select(row));
  }

  isAllIfSelected() {
    const ifSelected = this.ifSelection.selected.length;
    const numRows = this.ifChallanData.length;
    return ifSelected === numRows;
  }
   /** Selects all rows if they are not all selected; otherwise clear selection. */
   masterIfToggle() {
    this.isAllIfSelected() ?
      this.ifSelection.clear() :
      this.ifChallanData.forEach(row => this.ifSelection.select(row));
  }

  isAllEalSelected() {
    const ealSelected = this.EalSelection.selected.length;
    const numRows = this.ealChallanData.length;
    return ealSelected === numRows;
  }
   /** Selects all rows if they are not all selected; otherwise clear selection. */
   masterEalToggle() {
    this.isAllEalSelected() ?
      this.EalSelection.clear() :
      this.ealChallanData.forEach(row => this.EalSelection.select(row));
  }


  isAllProductSelected() {
    const productSelected = this.productSelection.selected.length;
    const numRows = this.productsList.length;
    return productSelected === numRows;
  }
   /** Selects all rows if they are not all selected; otherwise clear selection. */
   masterProductToggle() {
    this.isAllProductSelected() ?
      this.productSelection.clear() :
      this.productsList.forEach(row => this.productSelection.select(row));
  }
  saveImport(){
  let data = {...this.sacramaentalIndentForm.value};
  data['productDetails'] = this.compositionAddFields;
  data['challanDetails'] = this.importFeeData;
  data['totalIndentValue'] = this.totalValue; 
  const req_Data:any={
    action:'add_sacrimental',
    data:data
  }
  console.log(this.sacramaentalIndentForm.value)
  if(this.totalValue > this.sacramaentalIndentForm.value.importFeeOpening){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Indent value should not be more than importfee'});
      return;
  }
  console.log(req_Data)
  this.loader=true;
  this.importService.createIndent(req_Data).subscribe((res:any)=>{
    console.log(res)
    if(res.status === 'SUCCESS'){
      this.loader=false;
      // this.refreshPage.emit(true);
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Indent created successfully' });
      this.dialogRef.close('');
    }else{
      this.loader=false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Indent adding failed' });
    }
  })
    
  }
  setTotal(field, i) {
    const totalValue = field.rate * field.bottlesQuantity;
    this.compositionAddFields[i]['totalValue'] = totalValue;
    this.getFormValues()  
  }
  
  doPrint(id): any {
    this.printId = id;
    this.showPrint = true;
    this.getPrint(id);
  }
  getPrint(id): any {
    let printContents;
    let popupWin;
    setTimeout(() => {
      if (document.getElementById(id)) {
        printContents = document.getElementById(id).innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.write(`${printContents}`);
        popupWin.document.close();
        this.showPrint = false;
      }
    }, 500);
  }
  changeQuantity(i, e) {
    if (this.data.productDetails[i].bottlesQuantity) {
      this.data.productDetails[i].approvedBottles = parseInt(e.target.value);
    }
  }
  checkApprovedQty() {
    if (this.data.productDetails.some((product) => (product.approvedBottles > product.bottlesQuantity) || product.approvedBottles < 1 || isNaN(product.approvedBottles))) {
          this.messageService.add({severity: "error",summary: "Error",detail: "Enter valid quantity"});
      } else {
        this.approveIndent();
      }
  }

  quantityCheck(i) {
   if (this.data.productDetails[i]["bottlesQuantity"]) {
      return (
        this.data.productDetails[i]["bottlesQuantity"] <
        this.data.productDetails[i].approvedBottles
      );
    }
  }
  
approveIndent(){
  // const req_Data:any={
  //   action:'update_sacrimental',
  //   "data": {
  //     "indentId": this.data.indentId,
  //     "status": "Approved"
  // }
  // }
  this.data['status'] = "Approved"
  const req_Data:any={
    action:'update_sacrimental',
    data:this.data
  }
  console.log(req_Data)
  this.loader=true;
  this.importService.updateIndent(req_Data).subscribe((res:any)=>{
    console.log(res)
    if(res.status === 'SUCCESS'){
      // this.refreshPage.emit(true);
      this.dialogRef.close('');
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Indent approved successfully' });

      this.loader=false;
    }else{
      this.loader=false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Indent approval failed' });
    }
  })
}
rejectIndent(){
  const req_Data:any={
    action:'update_sacrimental',
    "data": {
      "indentId": this.data.indentId,
      "status": "Rejected"
  }
  }
  console.log(req_Data)
  this.loader=true;
  this.importService.updateIndent(req_Data).subscribe((res:any)=>{
    console.log(res)
    if(res.status === 'SUCCESS'){
      this.dialogRef.close('Indent rejected successfully');
      this.loader=false;
    }else{
      this.loader=false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Indent rejection failed' });
    }
  })

}
  gotoTop() {
    let el = this.myElement.nativeElement.querySelector('nav');
    el.scrollIntoView({behavior: "smooth"})
    this.addComposition()
  }

}
