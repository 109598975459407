import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { InterDepotTransferLocal } from 'src/app/core/models/inter-depot/inter-depot-transfer-local.model';
import { InterDepotService } from 'src/app/core/services/inter-depot.service';

@Component({
  selector: 'app-inter-depot-transfer-local-dialog-details',
  templateUrl: './inter-depot-transfer-local-dialog-details.component.html',
  styleUrls: ['./inter-depot-transfer-local-dialog-details.component.scss']
})
export class InterDepotTransferLocalDialogDetailsComponent implements OnInit {
  displayedColumns: string[] = ['ofsNumber', 'brandCode', 'brandName', 'size', 'ofsQuantity'];
  dataSource: MatTableDataSource<InterDepotTransferLocal> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;

  constructor(public dialogRef: MatDialogRef<InterDepotTransferLocalDialogDetailsComponent>, private interDepotService: InterDepotService,
    @Inject(MAT_DIALOG_DATA) public data,) { }

  ngOnInit(): void {
    console.log(this.data)
    if(this.data && this.data.productDetails.length){
      this.data.productDetails.map((item:any)=>{
        item.ofidtNumber = this.data.indentNo;
      })
      this.dataSource = new MatTableDataSource(this.data.productDetails);

    }

  }



  dialogClose(): void {
    this.dialogRef.close();
  }

}
