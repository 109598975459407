import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const licenseeProfileUrl = baseUrl + 'licensee_profiles'

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Licensee Profiles'] && userModules.mod_feats['Licensee Profiles'].features ? userModules.mod_feats['Licensee Profiles'].features.map(feat => feat.feature_name) : [];


@Injectable({
  providedIn: 'root'
})
export class LicenseeProfilesService {

  constructor(private http: HttpClient) { }

  getShopsDetails(offset, limit, search){
    return this.http.get('assets/samples/shops.json');
  }

  getEP1Details(offset, limit, search){
    return this.http.get('assets/samples/ep1.json');
  }

  getLicenseeStatusDetails(offset, limit, search){
    return this.http.get('assets/samples/licensee-status.json');
  }

  getTemporaryRetailerDetails(offset, limit, search){
    return this.http.get('assets/samples/temporary-retailer.json');
  }

  getLicenseeProfileFeatures() {
    const headerDict = {
      feature
    };
    return this.http.get(licenseeProfileUrl, { headers: headerDict });
  }

}
