import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonsService } from 'src/app/core/services/commons.service';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { MessageService } from 'primeng/api';
import { DynamicConfirmationDialogComponent } from 'src/app/core/components/dynamic-confirmation-dialog/dynamic-confirmation-dialog.component';
import { AddProductsDialogComponent } from '../add-products-dialog/add-products-dialog.component';

@Component({
  selector: 'app-open-action-update-shipments-dialog',
  templateUrl: './open-action-update-shipments-dialog.component.html',
  styleUrls: ['./open-action-update-shipments-dialog.component.scss']
})
export class OpenActionUpdateShipmentsDialogComponent implements OnInit {

  icdcData = [];
  selectedIcdcDatas: any;
  isUpdated: boolean = false;

  constructor(public dialogRef: MatDialogRef<OpenActionUpdateShipmentsDialogComponent>, 
    @Inject(MAT_DIALOG_DATA)public data,
    public shipmentService: ShipmentsService,
    private spinner: NgxSpinnerService,
    public commonService: CommonsService,
    public util: UtilService,
    private messageService: MessageService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getIcdcDetails();
  }

  dialogClose(): void{
    this.dialogRef.close();
  }

  revertIndent(action){
    this.spinner.show();
    const actionName = action === 'update' ? "update_shipments" : "cancel_shipments"
    let consignmentData = []; //this.data.consignment;
    // this.selectedIcdcDatas.productDetails.forEach(item => {
    //   let changedProduct = this.data.consignment[0].productDetails.find(product => product.productCode === item.productCode);
    //   if(changedProduct){
    //     item.preApprovedCases = changedProduct.approvedCases
    //   }   
    // });
    let productDetailsData = [];
    this.data.consignment[0].productDetails.forEach(item => {
      let changedProduct = this.selectedIcdcDatas.productDetails.find(product => product.productCode === item.productCode);
      productDetailsData.push({
          ...item,
          preApprovedCases: item.approvedCases,
          approvedCases: changedProduct ? changedProduct.approvedCases : item.approvedCases,
          isDeleted:changedProduct ? false: true
        });
    });

    consignmentData.push({
      ...this.data.consignment[0],
      productDetails: productDetailsData
    });

    // const changedProducts = this.data.consignment[0].productDetails.map(product =>{
    //   let changedProduct = this.selectedIcdcDatas.productDetails.find(item => item.productCode === product.productCode && item.approvedCases !== product.approvedCases); 
    //   if(changedProduct){
    //     return {
    //       productCode: product.productCode,
    //       approvedCases: product.approvedCases
    //     };
    //   }      
    // });
    const revertShipmentPayload = {
      action: actionName,
      data:{
        tpNo: this.data.tpNo,
        consignment: consignmentData,
        //changedProductsDetails: changedProducts.filter(item => item !== undefined)
      }
    };

    this.shipmentService.revertRequest(revertShipmentPayload).subscribe((res: any) => {
      if (res.status === 'FAILURE') {
        this.messageService.add({ severity: 'error', detail: 'Not Updated' });
      } else {
        this.dialogRef.close('TP Updated Successfully');
        this.isUpdated = false;
      }
      this.spinner.hide();
    }, (err) => {
      //this.error = err.error.message;
    });
  }
  getSelectedIcdcDatasIndex(icdcCode): any {
    return this.data.consignment.findIndex(el => el.icdcCode === icdcCode);
  }

  getIcdcDetails() {
    let tpNo = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
    const req_Data = {
      action: 'common_apis',
      q: '{"data": {"action": "get_added_icdc", "value": "' + tpNo + '"}}'

    }
    this.commonService.getCommonEntites(req_Data).subscribe(result => {
      this.icdcData = result['get_added_icdc'];
      if (this.icdcData) {
        this.selectedIcdcDatas = this.icdcData[0];
        this.selectedIcdcDatas.productDetails.forEach(el => {
          el.approvedCasesInput = el.approvedCases ? el.approvedCases : 0;
          el.approvedBottlesInput = el.approvedBottles ? el.approvedBottles : 0;
          el.preApprovedCases = el.approvedCases;
          el.preApprovedBottles = el.approvedBottles;
        });
      }
    })
  }

  isDisableUpdate() {
    let isStatus = false;
    if (this.selectedIcdcDatas && this.selectedIcdcDatas.productDetails && this.selectedIcdcDatas.productDetails.length) {
      isStatus = this.selectedIcdcDatas.productDetails.every(el => this.getTotalCount(el) === el.approvedCases);
    }
    return isStatus;
  }

  deleteInProgress(comp){
    const totalScannedCases = this.getCaseCount(comp).totalScannedCases ? comp.totalScannedCases : comp.casesCount ? comp.casesCount : 0;
    const totalScannedBottles = comp.totalScannedBottles ? comp.totalScannedBottles :0;
    const productType = this.util.getProductType(comp.productCode);
    if((productType === 'Beer' && (this.data.status === 'Created' || this.data.status === 'InProgress')) || totalScannedCases == 0 && totalScannedBottles == 0){
      return true;
    }
    return false;
  }

  quantityCheck(productData) {
    const selectedProduct = productData;
    // const matchedProducts = this.data.consignment[0].productDetails.find(item => item.productCode === selectedProduct.productCode);
    if (selectedProduct && this.selectedIcdcDatas.indentType === 'cases_indent') {
      let unitsPerCase = this.util.productCodeToDetails(selectedProduct.productCode).unitsPerCase
      const scannedQty = selectedProduct.totalScannedCases ? selectedProduct.totalScannedCases : selectedProduct.casesCount ? selectedProduct.casesCount : 0;
      const totalScannedQty = scannedQty + (selectedProduct?.totalScannedBottles ? (selectedProduct?.totalScannedBottles / unitsPerCase) : 0);
      // return selectedProduct.approvedCases === null || selectedProduct.approvedCases === 0 || selectedProduct.approvedCases < totalScannedQty || matchedProducts.approvedCases < selectedProduct.approvedCases;
      // return selectedProduct.approvedCasesInput === null || selectedProduct.approvedCasesInput === 0 || selectedProduct.approvedCasesInput < totalScannedQty || selectedProduct.approvedCasesInput > selectedProduct.approvedCases;
      return selectedProduct.approvedCasesInput === null || selectedProduct.approvedCasesInput === 0 || selectedProduct.approvedCasesInput < totalScannedQty || selectedProduct.approvedCases < selectedProduct.approvedCasesInput;
    }
  }
  quantityCheckBottles(productData) {
    const selectedProduct = productData;
    // const matchedProducts = this.data.consignment[0].productDetails.find(item => item.productCode === selectedProduct.productCode);
    if (selectedProduct && this.selectedIcdcDatas.indentType === 'bottles_indent') {
      // let unitsPerCase = this.util.productCodeToDetails(selectedProduct.productCode).unitsPerCase
      // const scannedQty = selectedProduct.totalScannedCases ? selectedProduct.totalScannedCases : selectedProduct.casesCount ? selectedProduct.casesCount : 0;
      // const totalScannedQty = scannedQty + (selectedProduct?.totalScannedBottles ? (selectedProduct?.totalScannedBottles / unitsPerCase) : 0);
      // return selectedProduct.approvedBottles === null || selectedProduct.approvedBottles === 0 || selectedProduct.approvedBottles < selectedProduct.bottlesCount || matchedProducts.approvedBottles < selectedProduct.approvedBottles;
      // return selectedProduct.approvedBottlesInput === null || selectedProduct.approvedBottlesInput === 0 || selectedProduct.approvedBottlesInput < selectedProduct.bottlesCount || selectedProduct.approvedBottlesInput > selectedProduct.approvedBottles;
      return selectedProduct.approvedBottlesInput === null || selectedProduct.approvedBottlesInput === 0 || selectedProduct.approvedBottlesInput < selectedProduct.bottlesCount || selectedProduct.approvedBottles < selectedProduct.approvedBottlesInput;
    }
  }

  getCaseCount(item){
    if(item.breakages){
      if(item.breakages.bottleBreakages){
        this.util.getTotalScannedCasesBottles(item);
        // let unitsPerCase = this.util.productCodeToDetails(item.productCode).unitsPerCase
        // let totalScannedBottles = (item.breakages.bottleBreakages.length * unitsPerCase) - item.breakages.bottles;
        // item.totalScannedCases = item.casesCount + Math.floor(totalScannedBottles/unitsPerCase);
        // item.totalScannedBottles = totalScannedBottles % unitsPerCase;
      }
    }
    return item;
  }
  updateProduct(cons, product): any {
    product.isEdit = false;
    let consignmentData = [];
    product.isDeleted = false;
    delete product.isEdit;
    delete product.isEditScannedQty;
    if (cons.indentType === 'bottles_indent') {
      product.approvedCases = product.approvedCases ? product.approvedCases : 0;
    }
    if (product.approvedCasesInput) {
      product.approvedCases = product.approvedCasesInput;
    }
    if (product.approvedBottlesInput) {
      product.approvedBottles = product.approvedBottlesInput;
    }
    if (product.casesCountInput) {
      product.casesCount = product.casesCountInput;
      product.isEditScannedQty = false;
      delete product.casesCountInput;
    }
    consignmentData.push({
      ...cons,
      productDetails: [product],
    });
    const indentType = this.data.indentId.substring(0, 3);
    const updateShipmentPayload = {
      action: indentType && indentType === 'RET' ? 'update_shipments' : 'update_bar_shipments',
      data:{
        tpNo: this.data.tpNo,
        consignment: consignmentData,
      }
    };
    this.spinner.show();
    this.shipmentService.revertRequest(updateShipmentPayload).subscribe((res: any) => {
      if (res.status === 'FAILURE' || res.error) {
        this.messageService.add({ severity: 'error', detail: res.error ? res.error : 'Not Updated' });
      } else {
        this.messageService.add({ severity: 'success', detail: 'TP Updated Successfully' });
      }
      this.getIcdcDetails();
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
    });
  }
  deleteProduct(cons, products) {
    const payload = {
      action: 'delete_product_shipment',
      data:{
        tpNo: this.data.tpNo,
        destnEntityId: cons.destnEntityId, 
        icdcCode: cons.icdcCode,
        indentId: cons.indentId,
        productDetails: products
      }
    };
    payload.data.productDetails.isDeleted = true;
    this.spinner.show;
    this.shipmentService.revertRequest(payload).subscribe((res: any) => {
      if (res.status && res.status.createdOn) {
        this.messageService.add({ severity: 'success', detail: 'Deleted Successfully' });
        this.isUpdated = false;
      } else {
        this.messageService.add({ severity: 'error', detail: 'Not deleted' });
      }
      this.getIcdcDetails();
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
    });
  }

  checkForError(){
    if(this.selectedIcdcDatas){
      const outofRangeData = this.selectedIcdcDatas.productDetails.map((product,i) =>{
        return this.quantityCheck(product);
      });
  
      return outofRangeData.some(item => item === true);
    }
  }
  getTotalCount(product){
    if(product.breakages){
      let totalCases = product.totalScannedCases + (((product.totalScannedBottles ? product.totalScannedBottles : 0) + product.breakages.bottles) / product.unitsPerCase) + (product.breakages.cases ? product.breakages.cases : 0);
      return totalCases;
    }
    return product.casesCount;
  }
  close(): any {
    this.getIcdcDetails();
    this.dialogRef.close();
  }
  dialogConfirm(cons, products): any {
    const data: any = {};
    data.content = `Delete ..?`;
    const dialogRef = this.dialog.open(DynamicConfirmationDialogComponent, {
      width: '25%',
      data:data,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result.message}`);
      if (result.message === 'Success') {
        this.deleteProduct(cons, products); 
      }
    });
  }
  addProduct(icdcDatas): any {
    icdcDatas.tpNo = this.data.tpNo;
    const dialogRef = this.dialog.open(AddProductsDialogComponent, {
      width: '80%',
      data: icdcDatas,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getIcdcDetails();
    });
  }
  isRetailerIndent(): any {
    const indentType = this.data.indentId.substring(0, 3);
    return indentType === 'RET' ? true : false;
  }

}
