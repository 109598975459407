import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DistilleryInfoService } from 'src/app/core/services/distillery-info.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-manage-bottling-line-dialog',
  templateUrl: './manage-bottling-line-dialog.component.html',
  styleUrls: ['./manage-bottling-line-dialog.component.scss']
})
export class ManageBottlingLineDialogComponent implements OnInit {
  lineForm: FormGroup;
  error: string;
  saveDisabled: boolean;
  statuses = ['Idle', 'Maintenance', 'Damaged'];
  printerList = ['Desktop Printer', 'Network Printer']
  entityId: any;

  constructor(private messageService: MessageService,private fb: FormBuilder, private dialogRef: MatDialogRef<ManageBottlingLineDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private distilleryInfoService: DistilleryInfoService,
  ) { }


  ngOnInit(): void {
    this.entityId = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
    this.entityId = this.entityId['entity_id'].split('-')[0];
    console.log('this.data', this.data);
    this.lineForm = this.fb.group({
      lineName: [this.entityId + '-Line-', [Validators.required]],
      lineId: '',
      // scannerType: '',
      ipAddress: ['',[Validators.required]],
      status: '',
      port: ['', [Validators.required]],
      printerType: ['', [Validators.required]],
      printerFileName: ['', [Validators.required]],
      // barcodePrinterName: ['',[Validators.required]]
    });
    if (this.data) {
      this.lineForm.patchValue({
        lineName: this.data.lineName,
        lineId: this.data.lineId,
        // scannerType: this.data.scannerType,
        ipAddress: this.data.ipAddress,
        status: this.data.status,
        printerType: this.data.printerType,
        port: this.data.port,
        printerFileName: this.data.printerFileName,
        // barcodePrinterName: this.data.barcodePrinterName
      });
    //   this.lineForm.controls['lineName'].disable();
    // this.lineForm.controls['lineId'].disable();
    }
    this.lineForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    })
  }

  todayDate = new Date();

  get f() {
    return this.lineForm.controls;
  }

  createLine() {
    this.saveDisabled = true;
    const req_Data = {
      action: 'add_line',
      data: this.lineForm.value
    }
    const blObj = this.lineForm.value;
    delete blObj.status;
    this.distilleryInfoService.createBottlingLine(req_Data).subscribe((res: any) => {
      if(res.status?.includes('already exists')) {
        this.messageService.add({severity:'error', summary: 'Error', detail: res.status});
      } else {
        this.dialogRef.close('Bottling Line Created Successfully');
      }
    }, (err) => {
      this.error = err.error.message;
    });
  }

  updateLine() {
    this.saveDisabled = true;
    const obj = {
      action: 'edit_line',
      data: this.lineForm.value
    }
    console.log(this.lineForm.value, "78::::::::")
    this.distilleryInfoService.putBottlingLine(obj).subscribe((res: any) => {
      this.dialogRef.close('Bottling Line Updated Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  lineFormat(event) {
    const defaultLineName = this.entityId + '-Line-'
    if (event.code === "Backspace" || event.code === "Delete") {
      this.lineForm.patchValue({
        lineName: defaultLineName
      })
      event.preventDefault();
    }
    else if (event.code.indexOf("Num") == -1 && event.code.indexOf("Digit") == -1 && event.code !== "Tab") {
      event.preventDefault();
    }

  }

  // avoid spacing while adding 
  avoidSpace(event) {
    console.log(event, '62:::')
    if (event.code === "Space") {
      event.preventDefault();
    }
  }
  // getTitle() {
  //   return this.data && this.data._id ? `Update Line for ${this.data.lineName}` : 'Create Line';
  // }
  updateLineId(event): any {
    const lineName: any = event.target.value.split('-');
    if(Number(lineName[lineName.length - 1]) !== 0){
      this.lineForm.patchValue({
        lineId: lineName[lineName.length - 1]
      });
    }else{
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Enter valid line number'});
    }
    
  }

}
