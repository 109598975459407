import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FinishedGoods } from 'src/app/core/models/lab/finished-goods.model';
import { LabService } from 'src/app/core/services/lab.service';

@Component({
  selector: 'app-open-finished-goods-status-dialog',
  templateUrl: './open-finished-goods-status-dialog.component.html',
  styleUrls: ['./open-finished-goods-status-dialog.component.scss']
})
export class OpenFinishedGoodsStatusDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'no',
    'createdOn',
    'batchNo',
    'kindOfSample',
    'referenceNo',
    'letterOfAdviceId',
    'quantityInMl',
    'proof',
  ];
  dataSource: MatTableDataSource<FinishedGoods> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;

  constructor(public dialogRef: MatDialogRef<OpenFinishedGoodsStatusDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,
    private labService: LabService) { }

  ngOnInit(): void {
    this.getSamplesDetails();
  }

  getSamplesDetails() {
    this.labService.getFinishedGoodsDetails(this.offset, this.limit, this.search).subscribe((res: any) => {
      console.log('res', res);
      this.dataSource = new MatTableDataSource(res.data);
    });
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

}
