import { Inject, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { ShipmentsService } from '../../../../core/services/shipments.service';
import { FormControl, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { take, takeUntil } from 'rxjs/operators';
import { IndentManagementService } from 'src/app/core/services/indent-management.service';
import { CommonsService } from 'src/app/core/services/commons.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-manage-route-dpo-dialog',
  templateUrl: './manage-route-dpo-dialog.component.html',
  styleUrls: ['./manage-route-dpo-dialog.component.scss']
})
export class ManageRouteDpoDialogComponent implements OnInit {
  destinationList:any = [];
  routeForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  public ifsDistilleryFilterCtrl: FormControl = new FormControl();
  public ifsDepotFilterCtrl: FormControl = new FormControl();
  sources = ['Source', 'Distillery', 'Location'];
  distinations = ['All Depots', 'CSDs'];
  distilleryNames = [];
  depotNames = [];
  public _onDestroy = new Subject<void>();
   /** list of items filtered by search keyword */
   public filteredDistilleryNames: ReplaySubject<any> = new ReplaySubject<any>(1);
   public filteredDepotNames: ReplaySubject<any> = new ReplaySubject<any>(1);

   @ViewChild('singleDistillerySelect') singleDistillerySelect: MatSelect;
   @ViewChild('singleDepotSelect') singleDepotSelect: MatSelect;
  entityId: any;
  csdList: any;
  exportList:any;
  roleName: any;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageRouteDpoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private shipmentsService: ShipmentsService, private indentManagementService: IndentManagementService, private commonsService: CommonsService,
    private messageService: MessageService) {
      this.entityId = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).entity_id
      this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
     }

  ngOnInit(): void {

    /* const req_Data = {
      "action": "common_apis",
      "q": '{"data": ["get_dist_depot"]}'
    }; */
    this.destinationList = ["Retailer", "Vendor", "Depot"]
    let paramObj = '?action=common_apis&q={"data": ["get_dist_depot","get_route_src","get_added_export"]}';
    this.commonsService.getList(paramObj).subscribe(data =>  {
      this.csdList = data['get_dist_depot']['csds'].map(csd => csd.destination);
      this.exportList =data['get_dist_depot']['exports'].map(importer => importer.nameOfTheImporter);
      // this.distilleryNames = data['get_dist_depot']['distilleries'];
      this.depotNames = data['get_dist_depot']['depots'];
      console.log(this.depotNames,"60::;")
      this.filteredDepotNames.next(this.depotNames.slice());
      // this.filteredDistilleryNames.next(this.distilleryNames.slice());
      // if(this.entityId) {
      this.routeForm.patchValue({
        // tslint:disable-next-line:no-string-literal
        sourceLocation: (this.roleName === 'Brewery Manager' || this.roleName === 'NonLocal Brewery Manager') ? this.entityId : data['get_route_src']['sourceName']
      });
      // } 
      // console.log(this.distilleryNames,this.depotNames,"47:::");

    })



// listen for search field value changes
this.ifsDistilleryFilterCtrl.valueChanges
  .pipe(takeUntil(this._onDestroy))
  .subscribe(() => {
    this.filterDistilleries();
  });

  this.ifsDepotFilterCtrl.valueChanges
  .pipe(takeUntil(this._onDestroy))
  .subscribe(() => {
      //  ? :  this.filterDepots();
      if(this.routeForm.value.destinationType === "CSD Locations"){
        this.filterCsds()
      }
      else if(this.routeForm.value.destinationType === "Depot"){
        this.filterDepots()
      }
      else if(this.routeForm.value.destinationType === "Export"){
        this.filterImporters()
      }
  });

    console.log('this.data', this.data);
    this.routeForm = this.fb.group({
      destinationType: ['', Validators.required],
      sourceLocation: ['', Validators.required],
      routeName: ['', Validators.required],
      routeVia: ['', Validators.required],
      destinationLocation: ['', Validators.required],
      distance: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      estimatedTime: ['', [Validators.required, Validators.pattern("^[0-9]*$")]]
    });
    if (this.data && this.data._id) {
      this.routeForm.patchValue({
        source : this.data.source,
        routeName: this.data.routeName,
        routeVia: this.data.routeVia,
        destinationLocation: this.data.distinationLocation,
      });
    }
    this.routeForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    })
  }


  destinationChange() {
    if(this.routeForm.value.destinationType === 'CSD Locations') {
          this.filteredDepotNames.next(this.csdList.slice());
    }
    else if(this.routeForm.value.destinationType === 'Depot') {
      this.filteredDepotNames.next(this.depotNames.slice());
    }
    else if(this.routeForm.value.destinationType === 'Export') {
      this.filteredDepotNames.next(this.exportList.slice());
    }
  }

  setInitialValue() {
    this.filteredDistilleryNames
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleDistillerySelect.compareWith = (a, b) => a && b && a === b;
      });

      this.filteredDepotNames
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleDepotSelect.compareWith = (a, b) => a && b && a === b;
      });
  }

  filterDepots() {
    if (!this.depotNames || (this.depotNames && this.depotNames.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.ifsDepotFilterCtrl.value;
    if (!search) {
      this.filteredDepotNames.next(this.depotNames.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredDepotNames.next(
      this.depotNames.filter(name => name.toLowerCase().indexOf(search) > -1)
    );
  }

  filterCsds() {
    if (!this.csdList || (this.csdList && this.csdList.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.ifsDepotFilterCtrl.value;
    if (!search) {
      this.filteredDepotNames.next(this.csdList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredDepotNames.next(
      this.csdList.filter(name => name.toLowerCase().indexOf(search) > -1)
    );
  }

  filterDistilleries() {
    if (!this.distilleryNames || (this.distilleryNames && this.distilleryNames.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.ifsDistilleryFilterCtrl.value;
    if (!search) {
      this.filteredDistilleryNames.next(this.distilleryNames.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredDistilleryNames.next(
      this.distilleryNames.filter(name => name.toLowerCase().indexOf(search) > -1)
    );
  }

  filterImporters() {
    if (!this.exportList || (this.exportList && this.exportList.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.ifsDepotFilterCtrl.value;
    if (!search) {
      this.filteredDepotNames.next(this.exportList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredDepotNames.next(
      this.exportList.filter(name => name.toLowerCase().indexOf(search) > -1)
    );
  }

  get f(){
    return this.routeForm.controls;
  }

  createRoute() {
    this.saveDisabled = true;
    let data= {...this.routeForm.value}
    let estTime = new Date().getTime() + data.estimatedTime*60*60*1000;
    data.estimatedTime = new Date(estTime)
    const req_data ={
      action: 'add_route',
      data
    };
    
    this.shipmentsService.createRoute(req_data).subscribe((res: any) => {
      if (res && res.status && res.status === 'SUCCESS') {
        this.dialogRef.close('Route Created Successfully');
      } else {
        this.messageService.add({ severity: 'error', detail: res && res.status && res.status.error ? res.status.error : 'Route not created' });
      }
    }, (err) => {
      this.messageService.add({ severity: 'error', detail: 'Route not created' });
      this.error = err.error.message;
    });
  }

  updateRoute() {
    // this.saveDisabled = true;
    // const obj = [{
    //   _id: this.data._id,
    //   update: this.routeForm.value
    // }]
    // this.spiritService.putBatch(obj).subscribe((res: any) => {
    //   this.dialogRef.close('Batch Udated Successfully');
    // }, (err) => {
    //   this.error = err.error.message;
    // });
  }


}
