import { Component, Inject, OnInit,} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { DynamicConfirmationDialogComponent } from 'src/app/core/components/dynamic-confirmation-dialog/dynamic-confirmation-dialog.component';
import { CommonsService } from 'src/app/core/services/commons.service';

@Component({
  selector: 'app-report-issues-dialog',
  templateUrl: './report-issues-dialog.component.html',
  styleUrls: ['./report-issues-dialog.component.scss']
})
export class ReportIssuesDialogComponent implements OnInit {
  
  reportIssuesForm:FormGroup;
  arr:any=[]
  userModules:any
  modules: string[];
  featureName: string[];
  status: string[]=["Opened","Reopened","Closed","InProgress","Blocked"];
  assignee: string[]=["developer"];
  role:any;
  comments:string=''
  commentList=[]
  activeUsersName:string=''
  entityId:any=['Distillery','Depot','Breweries']
  entityType:any=[]
  entity:any={}

  constructor(private fb: FormBuilder,private commonsService:CommonsService,private dialogRef: MatDialogRef<ReportIssuesDialogComponent>,private spinner:NgxSpinnerService,private messageService:MessageService,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialog,) { }
  
  ngOnInit(): void {
    this.userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).mod_feats;
    this.role = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    this.activeUsersName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).activeUsersName;
    this.modules=Object.keys(this.userModules)
    this.reportIssuesForm = this.fb.group({
      mobileNumber: ['', [Validators.required]],
      module: ['' , [Validators.required]],
      featureName: ['' , [Validators.required]],
      image: [],
      description: ['', [Validators.required]],
      title : ['', [Validators.required]],
      status : ["", [Validators.required]],
      assignee : ["", [Validators.required]],
    });
    if(this.role==='Issues Administrator' || this.role==='Issues Support'){
      this.reportIssuesForm = this.fb.group({
        mobileNumber: ['', [Validators.required]],
        module: ['' , [Validators.required]],
        featureName: ['' , [Validators.required]],
        image: [],
        description: ['', [Validators.required]],
        title : ['', [Validators.required]],
        status : ["", [Validators.required]],
        assignee : ["", [Validators.required]],
        entityId : ["", [Validators.required]],
        entityType : ["", [Validators.required]],
      });
      this.getEntity();
    }
    if(this.data){
      this.commentList=this.data?.comments
      this.featureName= this.userModules[this.data?.moduleName]?.features
      this.arr=this.data.attachments
      this.status.shift();
      this.reportIssuesForm.patchValue({
        mobileNumber: this.data?.phoneNumber,
        module: this.data?.moduleName,
        featureName: this.data?.featureName,
        image: this.data.attachments ? this.data.attachments : null,
        description: this.data?.description,
        title: this.data?.title,
        status:this.data?.status,
      });
      if(this.role!=='Issues Administrator' && this.role!=='Issues Manager'){
        this.reportIssuesForm.patchValue({
          assignee:" ",
        })
      }
      if(this.role==='Issues Administrator' || this.role==='Issues Support'){
        this.reportIssuesForm.patchValue({
          entityId:this.data?.entityId,
          entityType:this.data?.entityType,
          assignee:this.data?.assignee ? this.data?.assignee : '',
        })
      }
    }else{
      this.reportIssuesForm.patchValue({
        status:"Opened",
        assignee:" ",
      })
    }
  }

  createIssues(){
    let data:any={
      phoneNumber:this.reportIssuesForm.value.mobileNumber,
      moduleName:this.reportIssuesForm.value.module,
      description:this.reportIssuesForm.value.description,
      featureName:this.reportIssuesForm.value.featureName,
      attachments:this.reportIssuesForm.value.image,
      title :this.reportIssuesForm.value.title,
      // comments :this.commentList,
      // status:this.reportIssuesForm.value.status,
    }
    if(this.role==='Issues Administrator' || this.role==='Issues Support'){
      data.entityType=this.reportIssuesForm.value?.entityId
      data.entityId=this.reportIssuesForm?.value.entityType.supplierCode ? this.reportIssuesForm.value?.entityType?.supplierCode : this.reportIssuesForm.value?.entityType?.depotCode
    }
    const payload = {
      action: 'add_issue',
      data: data
    };

    // console.log(payload,"request");
    this.spinner.show();
    this.commonsService.postreportAnIssues(payload).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res,"res")
      if (res.status === 'SUCCESS') {
        this.dialogRef.close('Issues Created Successfully');
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not uploaded' });
    }
    }, err => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not uploaded' });
      this.spinner.hide();
    })
  }

  updateIssues(){
    let data:any={
      phoneNumber:this.reportIssuesForm.value.mobileNumber,
      moduleName:this.reportIssuesForm.value.module,
      description:this.reportIssuesForm.value.description,
      featureName:this.reportIssuesForm.value.featureName,
      attachments:this.reportIssuesForm.value.image,
      title :this.reportIssuesForm.value.title,
      status:this.reportIssuesForm.value.status,
      issueId:this.data.issueId,
      comments :this.commentList,
    }
    if(this.role==='Issues Administrator' || this.role==='Issues Manager'){
      data.fullName=this.reportIssuesForm.value.assignee
    }
    if(this.role==='Issues Administrator' || this.role==='Issues Support'){
      data.entityType=this.reportIssuesForm.value.entityId
      data.entityId=this.reportIssuesForm.value.entityType.supplierCode ? this.reportIssuesForm.value.entityType.supplierCode : this.reportIssuesForm.value?.entityType?.depotCode
    }
    console.log(data)
    const payload = {
      action: 'update_issue',
      data: data
    };

    // console.log(payload,"request");
    this.spinner.show();
    this.commonsService.putreportAnIssues(payload).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res,"res")
      if (res.status === 'SUCCESS') {
        this.dialogRef.close('Issues Updated Successfully');
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not uploaded' });
    }
    }, err => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not uploaded' });
      this.spinner.hide();
    })
  }
  
  deleteIssue(){
    const dialogRef = this.dialog.open(DynamicConfirmationDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if(result.message === 'Success' && result?.message !== undefined){
        const revertPayload = {
          action: "delete_issue",
          data: {
            issueId: this.data.issueId,
          }
        };
        this.spinner.show();
        this.commonsService.putreportAnIssues(revertPayload).subscribe((res: any) => {
          console.log(res,"res")
          if (res.status === 'SUCCESS') {
            this.dialogRef.close('Issues Delete Successfully');
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Deleted' });
        }
          this.spinner.hide();
        }, (err) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Deleted' });
          this.spinner.hide();
        });
      }
    });
  }

  selectFeatureName(event):void{
    this.featureName= this.userModules[event.value].features
  }

  uploadFile(event, control): any {
    if(event.target.files.length>0){
      for(let i = 0; i < event.target.files.length; i++){
            const fileToLoad =event.target.files[i];
            if(this.arr.filter(e => e.fileName === fileToLoad.name).length === 0){
            const fileReader = new FileReader();
            fileReader.onload = (fileLoadedEvent) => {
              const obj = { fileName: fileToLoad.name, source: fileLoadedEvent.target.result };
              this.arr.push(obj);
            };
            fileReader.readAsDataURL(fileToLoad);
          }
        }
          this.reportIssuesForm.controls[control].setValue(this.arr);
    }else{
          
          this.reportIssuesForm.controls[control].setValue(this.arr);
    }

  }

  fileDelete(i){
    this.reportIssuesForm.value.image.splice(i, 1);
  }
  sendComments(commont){
    let comments ={
      author:this.activeUsersName,
      message:commont
    }
    console.log(comments)
    this.commentList.unshift(comments);
    this.comments=''
  }
  getEntityType(event){
    this.entityType=this.entity[event.value]
  }
  getEntity(){
    let data= ["get_dist_depot"]
    let datas={
      action : "common_apis",
      q : JSON.stringify({data})
    }
    this.spinner.show();
    this.commonsService.getreportEntityType(datas).subscribe((res: any) => {
      this.spinner.hide();
      console.log(res.get_dist_depot)
      this.entity['Depot']=res.get_dist_depot.depots
      this.entity['Distillery']=res.get_dist_depot.distilleries.filter(depot=>{
        return depot.locationType==='Distillery' || depot.location==="Supplier"
      })
      this.entity['Breweries']=res.get_dist_depot.distilleries.filter(brewery=>{
        return brewery.locationType==='Breweries'
      })
    },(err)=>{
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error' });
    });
  }
}
