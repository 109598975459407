import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { sample } from 'rxjs/operators';
import { FailedSamples } from 'src/app/core/models/lab/failed-samples.model';
import { LabService } from 'src/app/core/services/lab.service';
import { ManageSamplesDialogComponent } from '../../samples/manage-samples-dialog/manage-samples-dialog.component';

@Component({
  selector: 'app-failed-samples-dialog',
  templateUrl: './failed-samples-dialog.component.html',
  styleUrls: ['./failed-samples-dialog.component.scss']
})
export class FailedSamplesDialogComponent implements OnInit {

  // displayedColumns: string[] = [
  //   'descriptionOfSample',
  //   'dtpNo/lotNo',
  //   'sampleQty',
  //   'senderSeal',
  //   'percentageAlcohol'
  //   ];

  // dataSource: MatTableDataSource<FailedSamples>;
  // selection = new SelectionModel<FailedSamples>(true, []);

  labInformation: FormArray;
  failedSamplesForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  offset: number;
  search: string;
  saveDisabled: boolean;
  sampleTypes = ['ENA', 'RS', 'BLEND', 'HTS', 'MALT', 'GRAPE', 'CAB', 'HBS', 'MAIZE',
                 'JOWAR', 'BROKEN RICE', 'BAJRA', 'MOLASSES', 'CJS', 'Scotch Concentrate', 'Spirit'];
  sampleTypeData: any [];
  referenceData: any = [];
  sampleTypeDisplayData: any = [];


  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageSamplesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private labService: LabService) { }

  ngOnInit(): void {
    this.getSampleTypeData();
    console.log('this.data', this.data);
    this.failedSamplesForm = this.fb.group({
      date: ['', [Validators.required]],
      sampleType: ['', [Validators.required]],
      referenceNo: ['', [Validators.required]],
      sentThrough: ['', [Validators.required]],
      signatureOfHC: ['', [Validators.required]],
      labInformation: this.fb.array([this.updateLabInformation()])
    });
    if (this.data && this.data._id) {
      this.failedSamplesForm.patchValue({
        date: this.data.date,
        sampleType: this.data.sampleType,
        referenceNo: this.data.referenceNo,
        sentThrough: this.data.sentThrough,
        signatureOfHC: this.data.signatureOfHC,
      });
      const labInformation = this.failedSamplesForm.get('labInformation') as FormArray;
      labInformation.clear();
      this.data.labInformation.forEach(b => {
        labInformation.push(
          this.fb.group({
            checkedValue: b.checkedValue,
            descriptionOfSample: b.descriptionOfSample,
            dtpNoLotNo: b.dtpNoLotNo,
            sampleQuantity: b.sampleQuantity,
            sendersFacsimileSeal: b.sendersFacsimileSeal,
            percentageOfProofSpirit: b.percentageOfProofSpirit
          })
        );
      });
    }
    this.labInformation = this.failedSamplesForm.get('labInformation') as FormArray;
    this.failedSamplesForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
    // this.getFailedSamplesDetails();
  }

  getSampleTypeData() {
    const req_Data = {action: 'get_samples_by_status',
                      q: '{}'
                    }
    this.labService.getSampleTypes(req_Data).subscribe( res => {
      console.log(res);
      this.sampleTypeData = res['get_samples_by_status']; 
      let sTypes = this.sampleTypeData.map( type => type.sampleType);
      sTypes.forEach( type => {
        if(this.sampleTypeDisplayData.length == 0 || this.sampleTypeDisplayData.indexOf(type) == -1) {
          this.sampleTypeDisplayData.push(type)
        }
       

      })
    }) 
  }

  sampleTypeChange(event) {
    this.referenceData = [];
    this.sampleTypeData.forEach( sample => {
      if(event.value == sample['sampleType']) {
        this.referenceData.push(sample['referenceNo'])
      }
    })
  }

  updateLabInformation(){
    return this.fb.group({
      checkedValue: '',
      descriptionOfSample: ['', Validators.required],
      dtpNoLotNo: ['', Validators.required],
      sampleQuantity: ['', Validators.required],
      sendersFacsimileSeal: ['', Validators.required],
      percentageOfProofSpirit: ['', Validators.required]
    });
  }

  // getFailedSamplesDetails() {
  //   this.labService.getFailedSamplesDetails(this.offset, this.limit, this.search).subscribe ((res: any) => {
  //     console.log('res', res);
  //     this.dataSource = new MatTableDataSource(res.data);
  //   });
  // }


  addUnit() {
    const control =  this.failedSamplesForm.controls['labInformation'] as FormArray;
    control.push(this.updateLabInformation());
  }


  removeUnit(i: number) {
    const control =  this.failedSamplesForm.controls['labInformation'] as FormArray;
    control.removeAt(i);
  }

  get f(){
    return this.failedSamplesForm.controls;
  }

  createFieldSamples() {
    this.saveDisabled = true;
    console.log(this.failedSamplesForm.value,'121:::')
    this.labService.createFailedSamplesDetails(this.failedSamplesForm.value).subscribe((res: any) => {
      this.dialogRef.close('Samples Created Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  dialogClose(): void{
    this.dialogRef.close();
  }

}
