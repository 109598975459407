import { BlendIndent } from 'src/app/core/models/indent-management/blend-indent.model';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SupplyIndents } from '../models/supply-delivery/supply-indents';
import { RSIndent } from '../models/indent-management/rs-indent.model';
import { ENAIndent } from '../models/indent-management/ena-indent.model';
import { OtherSpiritIndent } from '../models/indent-management/other-spirit.model';

const baseUrl = environment.baseUrl;
const indentManagementUrl = baseUrl + 'indent_management';

const supplyIndentsUrl = baseUrl + 'indentmanagement/distillerysupplyindents';
const ofsUrl = baseUrl + 'supplymanagement/orderforsupply';
const transportPermitUrl = baseUrl + 'supplymanagement/transportpermits';
const ofsExtensionFormUrl = baseUrl + 'ofsExtension';
const supplyAddtionalQuotaFormUrl = baseUrl + 'supplyaddtionalquota'
const editRecomputeEligibilityUrl = baseUrl + 'editrecomputeeligibility'
const editApplyEligibilityRulesUrl = baseUrl + 'editapplyeligibilityrules'


const rsIndentUrl = baseUrl + 'rsindent';
const enaIndentUrl = baseUrl + 'enaindent';
const blendIndentUrl = baseUrl + 'blendindent';
const otherSpiritIndenttUrl = baseUrl + 'otherspirit';
const eventPermitUrl = baseUrl + 'event-permit';
// const rsIndentUrl = baseUrl + 'spiritindents';
// const enaIndentUrl = baseUrl + 'spiritindents';
// const blendIndentUrl = baseUrl + 'spiritindents';
// const otherSpiritIndenttUrl = baseUrl + 'spiritindents';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Indent Management'] && userModules.mod_feats['Indent Management'].features ? userModules.mod_feats['Indent Management'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class IndentManagementService {

  constructor(private http: HttpClient) { }

  createSupplyIndents(supplyIndentsObj) {
    return this.http.post(supplyIndentsUrl, supplyIndentsObj)
  }

  updateIndents(obj: any) {
    return this.http.put(indentManagementUrl, obj);
  }
  getSupplyIndents(offset, limit, search?: string): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    return this.http.get(supplyIndentsUrl, { params: paramObj });
  }

  submitCaseCode(caseObj) {
    return this.http.put(indentManagementUrl, caseObj)
  }

  getDistilleryNameList(paramObj): Observable<any> {
    return this.http.get(indentManagementUrl, { params: paramObj });
  }

  putSupplyIndents(supplyIndentObj, id) {
    return this.http.put<SupplyIndents>(`${supplyIndentsUrl}/${id}`, supplyIndentObj);
  }

  getRsIndentDetails(offset, limit, search) {
    return this.http.get('assets/samples/rs-indent-for-ena.json');
  }

  getEnaIndentDetails(offset, limit, search) {
    return this.http.get('assets/samples/ena-indent-for-blend.json');
  }

  getBlendIndentDetails(offset, limit, search) {
    return this.http.get('assets/samples/blend-indent-for-bottling.json');
  }

  getOtherSpiritsDetails(offset, limit, search) {
    return this.http.get('assets/samples/other-spirits-indent-for-blend.json');
  }

  createRSIndent(obj: any) {
    return this.http.post(indentManagementUrl, obj);
  }

  createENAIndent(obj: any) {
    return this.http.post(indentManagementUrl, obj);
  }

  createBlendIndent(obj: any) {
    return this.http.post(indentManagementUrl, obj);
  }

  createOtherspiritIndent(obj: any) {
    return this.http.post(indentManagementUrl, obj);
  }

  createRetailerIndent(obj: any) {
    return this.http.post(indentManagementUrl, obj);
  }

  getIndentingDetails(offset, limit, search) {
    return this.http.get('assets/samples/indenting.json');
  }

  getCreditRegisterDetails(offset, limit, search) {
    return this.http.get('assets/samples/credit-register.json');
  }

  getEventPermitDetails(offset, limit, search) {
    return this.http.get('assets/samples/event-permit.json');
  }

  createEventPermit(obj: any) {
    return this.http.post(eventPermitUrl, obj);
  }

  getInactiveRetailersDetails(offset, limit, search) {
    return this.http.get('assets/samples/inactive-retailers.json');
  }

  getCancelIcdcDetails(offset, limit, search) {
    return this.http.get('assets/samples/cancel-icdc.json');
  }

  getLicenseeIndentsDetails(offset, limit, search) {
    return this.http.get('assets/samples/licensee-indents.json');
  }

  getChallanReconsilDetails(offset, limit, search) {
    return this.http.get('assets/samples/challan-reconsil.json');
  }

  getAgedChallansDetails(offset, limit, search) {
    return this.http.get('assets/samples/aged-challans.json');
  }

  getRetailerChallanTransferDetails(offset, limit, search) {
    return this.http.get('assets/samples/retailer-challan-transfer.json');
  }

  getChallansDetails(offset, limit, search) {
    return this.http.get('assets/samples/challans-feature.json');
  }

  getDistilleryIndentDetails(offset, limit, search) {
    return this.http.get('assets/samples/distillery-indent.json');
  }

  createOFSExtensionForm(ofsExtensionFormObj) {
    return this.http.post(ofsExtensionFormUrl, ofsExtensionFormObj);
  }

  getCancelledICDCDetails(offset, limit, search) {
    return this.http.get('assets/samples/cancel-icdc.json');
  }

  getCompletedEligibilityDetails(offset, limit, search) {
    return this.http.get('assets/samples/completed-eligibility.json');
  }

  getStateEligibilityDetails(offset, limit, search) {
    return this.http.get('assets/samples/state-eligibility.json');
  }

  getRetailerIndentTypesDetails(offset, limit, search) {
    return this.http.get('assets/samples/retailer-indent-types.json');
  }


  createSupplyAdditionalQuotaForm(supplyAdditionalQuotaFormObj) {
    return this.http.post(supplyAddtionalQuotaFormUrl, supplyAdditionalQuotaFormObj);
  }

  createEditRecomputeEligibility(editRecomputeEligibilityObj) {
    return this.http.post(editRecomputeEligibilityUrl, editRecomputeEligibilityObj);
  }

  createEditApplyEligibilityRules(editApplyEligibilityRulesObj) {
    return this.http.post(editApplyEligibilityRulesUrl, editApplyEligibilityRulesObj);
  }

  getIndentManagementFeatures(paramObj: any = '') {
    const headerDict = {
      feature
    };
    return this.http.get(indentManagementUrl, { headers: headerDict, params: paramObj });
  }
  getIndentManagementFeaturesByParams(paramObj): any {
    const headerDict = {
      feature
    };
    return this.http.get(indentManagementUrl, { headers: headerDict, params: paramObj });
  }

  getIcdcInfo(action, q) {
    const paramObj: any = {};
    q ? paramObj.q = q : q = null;
    action ? paramObj.action = action : action = null;
    return this.http.get(indentManagementUrl, { params: paramObj });
  }

  createDistilleryIndent(obj: any) {
    return this.http.post(indentManagementUrl, obj);
  }

  editDistilleryIndent(obj: any) {
    return this.http.put(indentManagementUrl, obj);
  }
  approveRejectVendorIndentStatus(obj: any): any {
    return this.http.post(indentManagementUrl, obj);
  }

  postVendorIndentPaymentData(obj): any {
    return this.http.post(indentManagementUrl, obj);
  }

  revertRequest(ObjPayload) {
    return this.http.put(indentManagementUrl, ObjPayload)
  }

}
