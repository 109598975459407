import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const exportUrl = baseUrl + 'export';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Export'] && userModules.mod_feats['Export'].features ? userModules.mod_feats['Export'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(private http: HttpClient) { }

  getExportDetails(offset,limit,search) {
    return this.http.get('assets/samples/export.json');
  }
  createIndent(objData:any){
    return this.http.post(exportUrl,objData);
  }
  updateExport(objData:any){
    return this.http.put(exportUrl,objData);
  }
  getExportFeatures() {
    const headerDict = {
      feature
    };
    return this.http.get(exportUrl, { headers: headerDict });
  }
}
