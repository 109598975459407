import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { DistilleryInfoService } from 'src/app/core/services/distillery-info.service';

@Component({
  selector: 'app-insurance-details',
  templateUrl: './insurance-details.component.html',
  styleUrls: ['./insurance-details.component.scss']
})
export class InsuranceDetailsComponent implements OnInit {

  userInfo: any;
  supplierCode: any;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<InsuranceDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private distilleryInfoService: DistilleryInfoService,
    private messageService: MessageService
  ) { }

  get f() {
    return this.insuranceForm.controls;
  }

  insuranceForm: FormGroup;
  error: string;
  saveDisabled: boolean;
  todayDate = new Date();

  ngOnInit(): void {
    this.userInfo = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
    this.supplierCode = this.userInfo['entity_id'];
    console.log(this.data),
      this.insuranceForm = this.fb.group({
        supplierCode: [this.supplierCode, [Validators.required]],
        insuranceNumber: ['', [Validators.required]],
        insuranceDate: ['', [Validators.required]],
        expiryDate: ['', [Validators.required]],
      });
    if (this.data && this.data._id) {
      this.insuranceForm.patchValue({
        supplierCode: this.data.supplierCode,
        licenseNumber: this.data.licenseNumber,
        insuranceDate: this.data.insuranceDate,
        expiryDate: this.data.expiryDate,
      });
    }
    this.insuranceForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
  }

  create() {
    this.saveDisabled = true;
    const req_Data = {
      action: 'add_insurance_details',
      data: this.insuranceForm.value
    }
    this.distilleryInfoService.createInsuranceDetails(req_Data).subscribe((res: any) => {
      console.log(res);
      if (res && res.status === 'SUCCESS') {
        this.dialogRef.close('Created Successfully');
      } else {
        this.messageService.add({severity: 'error', summary: '', detail: 'Not Created.'});
      }
      
    }, (err) => {
      this.error = err.error.message;
    });
  }

  update() {
    this.saveDisabled = true;
    const req_Data = {
      action: 'update_insurance_details',
      data: this.insuranceForm.value
    }
    req_Data.data.id = this.data.id,
    this.distilleryInfoService.putInsuranceDetails(req_Data).subscribe((res: any) => {
      console.log(res);
      if (res && res.status === 'SUCCESS') {
        this.dialogRef.close('Updated Successfully');
      } else {
        this.messageService.add({severity: 'error', summary: '', detail: 'Not Updated.'});
      }
    }, (err) => {
      this.error = err.error.message;
    });
  }
}
