import { UtilService } from 'src/app/core/utils/utility-helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { Console } from 'console';
declare const JsBarcode: any;
@Component({
  selector: 'app-apn-details-for-supplier-dialog',
  templateUrl: './apn-details-for-supplier-dialog.component.html',
  styleUrls: ['./apn-details-for-supplier-dialog.component.scss']
})
export class ApnDetailsForSupplierDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'no',
    'productCode',
    'brandName',
    'batchId',
    'sizeCode',
    'shippedCases',
    'recievedCases',
    'recievedBottles',
    'damagedCases',
    'damagedBottles',
    'shortageCases',
    'shortageBottles'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  productData: any[];
  ofsData: any;
  roleName: any;
  totalShipped:any=0;
  receivedQtyFull:any=0;
  receivedQtyPart:any=0; 
  breakageBtl:any=0;
  breakageCases: any = 0;
  breakagesAmount: any = 0;
  address:any;
  depotName:any;
  totalRate:any=0;
  stockAmount:any=0;
  receivedQtyPrt:any=0;
  selectedOfs:any=[];
  currentDate: any;
  totalDatas: any = [];
  newScannedCasesBottles: any = [];
  selectedOfsIndex: any;

  constructor(public dialogRef: MatDialogRef<ApnDetailsForSupplierDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, private shipmentsService: ShipmentsService,
  private spinner: NgxSpinnerService,
  public util: UtilService) {
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    this.depotName =JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).entity_id;
    if (this.roleName === 'Distillery Manager') {
      this.address = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).distillery_address;
    } else {
      this.address = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).depot_address;
    }
   }

  ngOnInit(): void {
    this.currentDate = new Date();
    this.productData = [];
    this.ofsData = this.data['consignment'];
    this.getScannedBottle();
  }


  updateStatus(status) {
    this.spinner.show();
    let data = this.data;
    const req_Data: any = {
      action: 'ack_shipment',
      data: data
    }
    if (this.data.btpNo) {
      delete req_Data.data.tpNo;
      req_Data.data.btpNo = this.data.btpNo;
    } else if (this.data.bipNo) {
      delete req_Data.data.tpNo;
      req_Data.data.bipNo = this.data.bipNo;
    }

    this.shipmentsService.acknowledgeSRA(req_Data).subscribe(result => {
      this.dialogRef.close("Acknowledged SRA Successfully");
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    })
  }
   ngAfterViewInit(): any {
    if (this.data.chkNO) {
      JsBarcode('#barcode-chkNO', this.data.chkNO,
        {
          width: 1,
          height: 40
        }
      );
    }

    JsBarcode('#barcode-test', this.data.grnNO,
      {
        width: 1,
        height: 40
      }
    );
  }
  async getScannedBottle(): Promise<any> {
    let repackBottleCount = {};
    this.ofsData.forEach((ofs: any, j) => {
      if (ofs.ofidtNo) {
        ofs.ofsCode = ofs.ofidtNo; 
      }
      const repackProductDatas = ofs.productDetails.find(product => product.breakages && product.breakages.bottles && product.breakages.bottleBreakages && product.breakages.bottleBreakages.length === 0);
      if (repackProductDatas) {
        if (!repackBottleCount[repackProductDatas.productCode]) {
          repackBottleCount[repackProductDatas.productCode] = 0;
        }
        repackBottleCount[repackProductDatas.productCode] += repackProductDatas.breakages.bottles;
      }
    });
    this.ofsData.forEach((ofs: any, j) => {
      if (!this.newScannedCasesBottles[j]) {
        this.newScannedCasesBottles[j] = [];
      }
      ofs.productDetails.forEach((product: any, i) => {
        if (this.data.consignmentType === 'OFIDT') {
          product.shipmentQty = product.approvedCases;
        }
        if (!this.newScannedCasesBottles[j][i]) {
          this.newScannedCasesBottles[j][i] = {};
          this.newScannedCasesBottles[j][i].cases = 0;
          this.newScannedCasesBottles[j][i].bottles = 0;
          this.newScannedCasesBottles[j][i].breakageBottles = 0;
          this.newScannedCasesBottles[j][i].breakageCases = 0;
          this.newScannedCasesBottles[j][i].breakageCaseQty = 0;
        }
        product.unitsPerCase = this.util.productCodeToDetails(product.productCode).unitsPerCase;
        const productType = this.util.getProductType(product.productCode);
        let cases: any = product.casesCount ? product.casesCount : 0;
        let bottles: any = 0;
        const breakageCases: any = product.breakages && product.breakages.cases ? product.breakages.cases : 0;
        let breakageBottles: any = product.breakages && product.breakages.bottles ? product.breakages.bottles : 0;
        let bottleBreakagesLength = product.breakages && product.breakages.bottleBreakages ? product.breakages.bottleBreakages.length : 0;
        const shortageCases: any = product.shortages && product.shortages.cases ? product.shortages.cases : 0;
        const shortageBottles: any = product.shortages && product.shortages.bottles ? product.shortages.bottles : 0;
        if (productType === 'Beer') {
          bottles = ((product.shipmentQty * product.unitsPerCase) - (cases * product.unitsPerCase)) - ((breakageCases * product.unitsPerCase) + breakageBottles + (shortageCases * product.unitsPerCase) + shortageBottles);
          cases = cases + Math.floor(bottles/product.unitsPerCase);
          bottles = bottles % product.unitsPerCase;
        } else {
          if (bottleBreakagesLength) { 
            const caseCodes: any = product.breakages.bottleBreakages.map(el => el.caseCode);
            const isDuplicate: any = caseCodes.filter((c, idx) => caseCodes.indexOf(c) != idx);
            if (isDuplicate.length) {
              bottleBreakagesLength = bottleBreakagesLength - isDuplicate.length;
            }
            bottles = (bottleBreakagesLength * product.unitsPerCase) - (breakageBottles);
          }
          if (shortageBottles) {
            if (shortageBottles > 0 && shortageBottles <= bottles) {
              bottles = bottles - shortageBottles; 
            } else if (shortageBottles > 0 && shortageBottles > bottles) {
              // if (shortageBottles > 0 && shortageBottles <= bottles) {
              //  bottles = bottles - shortageBottles;
              // } else if (shortageBottles > 0 && shortageBottles > bottles) {
              //   // bottles = (product.unitsPerCase - shortageBottles) + bottles;
              //   bottles = (product.unitsPerCase - ((shortageBottles + bottles) % product.unitsPerCase));
              // }
              const sQty = product.shipmentQty * product.unitsPerCase;
              const rQty = cases * product.unitsPerCase;
              bottles = sQty - rQty - breakageBottles - shortageBottles;
            }
          }
        }
        if (product.breakages && product.breakages.bottles && product.breakages.bottleBreakages && product.breakages.bottleBreakages.length === 0) {
          bottles = 0;
          breakageBottles = 0;
        }
        if (repackBottleCount[product.productCode] && repackBottleCount[product.productCode] > 0 && product.breakages && product.breakages.bottles && product.breakages.bottleBreakages.length > 0) {
          bottles = bottles - repackBottleCount[product.productCode];
          breakageBottles = breakageBottles + repackBottleCount[product.productCode];
          repackBottleCount[product.productCode] = 0;
        }
        this.newScannedCasesBottles[j][i] = {cases: cases, bottles: bottles, breakageBottles: breakageBottles};
      });
    });
    this.getSubTotal();
  }

  dialogClose(): void {
    this.dialogRef.close();
  }
  PrintSra(item, j){
    this.selectedOfs=item;
    this.selectedOfsIndex = j;
    let printContents, popupWin;
    setTimeout(() => {
    printContents = document.getElementById("print-stock-receipt-acknowledgemnt").innerHTML;
    popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
    popupWin.document.write(`${printContents}`);
    popupWin.document.close();
  }, 500);
  }
  Print(item, j){
    this.selectedOfs=item;
    this.selectedOfsIndex = j;
    setTimeout(() => {
      let printContents, popupWin;
      printContents = document.getElementById("print-grn").innerHTML;
      popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
      popupWin.document.write(`${printContents}`);
      popupWin.document.close();
    }, 500);
}
  getBottlePrice(item): any {
    const basicPricing = item.basicPricing && item.basicPricing.length && item.basicPricing[0] ? (item.basicPricing ? item.basicPricing[0].price : 0) : (item.basicPricing ? item.basicPricing : 0);
    const exciseDuty = item.exciseDuty ? item.exciseDuty : 0;
    return (basicPricing + exciseDuty) / item.unitsPerCase;
  }
  getCasePrice(item): any {
    const basicPricing = item.basicPricing && item.basicPricing.length && item.basicPricing[0] ? (item.basicPricing ? item.basicPricing[0].price : 0) : (item.basicPricing ? item.basicPricing : 0);
    const exciseDuty = item.exciseDuty ? item.exciseDuty : 0;
    return basicPricing + exciseDuty;
  }
  getBreakagePrice(item, j, i) {
    const price = this.getBottlePrice(item);
    const breakageCases = item.breakages && item.breakages.cases ? item.breakages.cases : 0;
    const breakageBottles = this.newScannedCasesBottles[j][i].breakageBottles;
    const bottlesQty = (breakageCases * item.unitsPerCase) + breakageBottles;
    const breakagePrice = (bottlesQty * price).toFixed(2);
    return parseFloat(breakagePrice);
  }
  getShortagePrice(item) {
    if (!item.shortages) {
      return 0;
    }
    const price = this.getBottlePrice(item);
    const shortageCases = item.shortages && item.shortages.cases ? item.shortages.cases : 0;
    const shortageBottles = item.shortages && item.shortages.bottles ? item.shortages.bottles : 0;
    const bottlesQty = (shortageCases * item.unitsPerCase) + shortageBottles;
    const shortagePrice = (bottlesQty * price).toFixed(2);
    return parseFloat(shortagePrice);
  }
  getStockPrice(item, j, i) {
    const price = this.getBottlePrice(item);
    const receivedCases = this.newScannedCasesBottles[j][i].cases;
    const receivedBottles = this.newScannedCasesBottles[j][i].bottles;
    const bottlesQty = (receivedCases * item.unitsPerCase) + receivedBottles;
    const receivedPrice = (bottlesQty * price).toFixed(2);
    return parseFloat(receivedPrice);
  }
  getSubTotal(): any {
    this.totalDatas = {};
    this.ofsData.forEach((ofs: any, j) => {
      this.totalDatas[ofs.ofsCode] = {};
      this.totalDatas[ofs.ofsCode].shipmentQty = 0;
      this.totalDatas[ofs.ofsCode].totalScannedCases = 0;
      this.totalDatas[ofs.ofsCode].totalScannedBottles = 0;
      this.totalDatas[ofs.ofsCode].breakageCases = 0;
      this.totalDatas[ofs.ofsCode].breakageBottles = 0;
      this.totalDatas[ofs.ofsCode].totalRate = 0;
      this.totalDatas[ofs.ofsCode].stockPrice = 0;
      this.totalDatas[ofs.ofsCode].breakagePrice = 0;
      this.totalDatas[ofs.ofsCode].shortageCases = 0;
      this.totalDatas[ofs.ofsCode].shortageBottles = 0;
      this.totalDatas[ofs.ofsCode].shortagePrice = 0;
      ofs.productDetails.forEach((item: any, i) => {
        const basicPricing = item.basicPricing && item.basicPricing.length && item.basicPricing[0] ? (item.basicPricing ? item.basicPricing[0].price : 0) : (item.basicPricing ? item.basicPricing : 0);
        this.totalDatas[ofs.ofsCode].shipmentQty += this.data.consignmentType === 'OFIDT' ? 
        item.shipmentQty ? parseInt(item.shipmentQty, 10) : parseInt(item.approvedCases) : item.shipmentQty ? parseInt(item.shipmentQty, 10) : 0;
        // this.totalDatas[ofs.ofsCode].shipmentQty += item.approvedCases ? parseInt(item.approvedCases, 10) : 0;
        this.totalDatas[ofs.ofsCode].totalScannedCases += this.newScannedCasesBottles[j][i].cases ? this.newScannedCasesBottles[j][i].cases : 0;
        this.totalDatas[ofs.ofsCode].totalScannedBottles += this.newScannedCasesBottles[j][i].bottles ? this.newScannedCasesBottles[j][i].bottles : 0;
        this.totalDatas[ofs.ofsCode].breakageCases += item.breakages && item.breakages.cases ? item.breakages.cases : 0;
        this.totalDatas[ofs.ofsCode].breakageBottles += this.newScannedCasesBottles[j][i].breakageBottles ? this.newScannedCasesBottles[j][i].breakageBottles : 0;
        // const rate: any = item.basicPricing ? parseFloat(item.basicPricing) : 0 + parseFloat(item.exciseDuty);
        // const rate: any = basicPricing + parseFloat(item.exciseDuty);
        const rate = this.getCasePrice(item);
        this.totalDatas[ofs.ofsCode].totalRate += rate;
        this.totalDatas[ofs.ofsCode].stockPrice += this.getStockPrice(item, j, i);
        this.totalDatas[ofs.ofsCode].breakagePrice += this.getBreakagePrice(item, j, i);
        this.totalDatas[ofs.ofsCode].shortageCases += item.shortages && item.shortages.cases ? item.shortages.cases : 0;
        this.totalDatas[ofs.ofsCode].shortageBottles += item.shortages && item.shortages.bottles ? item.shortages.bottles : 0;
        this.totalDatas[ofs.ofsCode].shortagePrice += this.getShortagePrice(item);
      });
    });
  }
  changeOfsToOfIDT(obj: any = {}, value): any {
    if (this.data.consignmentType === 'OFIDT') {
      delete obj.ofsCode;
      obj.ofidtNo = value;
    }
  }

}
