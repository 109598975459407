import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-report-issues-view',
  templateUrl: './report-issues-view.component.html',
  styleUrls: ['./report-issues-view.component.scss']
})
export class ReportIssuesViewComponent implements OnInit {
  reportIssuesForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data,private fb: FormBuilder,public util: UtilService,private datePipe: DatePipe,
  private domSanitizer: DomSanitizer) { }
  images:any
  ngOnInit(): void {
    // console.log(this.data,"dat")
    const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
    this.images = userModules.s3_url

    // console.log(this.images,"images")
    // console.log(this.util.getDateOnly(this.data.createdAt))
    this.reportIssuesForm = this.fb.group({
      // description: [{value:this.data.description ,disabled: true}],
      created: [{value:this.getDateTime(this.data.createdAt) ,disabled: true} ],
      updated: [{value:this.getDateTime(this.data.updatedAt),disabled: true}],
      image: [],
      Model: [{value:this.data.moduleName,disabled: true}],
      featureName: [{value:this.data.featureName,disabled: true}],
      phoneNumber: [{value:this.data.phoneNumber,disabled: true}],
      status: [{value:this.data.status,disabled: true}],
    });
  }
  getUrl(uri: any):any{
    return encodeURI(uri)
  }
  getDateTime(date){
    return this.datePipe.transform(date, 'dd-MM-yyyy hh:mm:ss a')
  }
}

