import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
const baseUrl = environment.baseUrl;
const releaseNotesUrl = baseUrl + 'release-notes';
const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Indent Management'] && userModules.mod_feats['Indent Management'].features ? userModules.mod_feats['Indent Management'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class ReleaseNotesService {

  constructor(private http: HttpClient) { }

  getReleaseNotesFeatures(paramObj: any = '') {
    const headerDict = {
      feature
    };
    return this.http.get(releaseNotesUrl, { headers: headerDict, params: paramObj });
  }
  getEntityRoles(): any {
    return this.http.get('http://localhost:4200/assets/get_entities_role.json');
  }
  createMethod(paramObj: any): any {
    return this.http.post(releaseNotesUrl, paramObj);
  }
  putMethod(paramObj: any): any {
    return this.http.put(releaseNotesUrl, paramObj);
  }
}
