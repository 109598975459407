const MODULES = [
    {
      moduleId: 'holograms',
      moduleName: 'Holograms',
      description: 'heal management',
      order: 30,
      features: [
        {
          featureName: 'Heal inventory',
          featureId: 'H-HI-1',
          divisionType: 'Distillery',
        },
        {
          featureName: 'Heal purchase indents',
          featureId: 'H-HPI-1',
          divisionType: 'Distillery',
        },
      ],
    },
    {
      moduleId: 'production',
      moduleName: 'Production',
      description: 'Production management',
      order: 40,
      features: [
        {
          featureName: 'Products',
          featureId: 'P-Products-1',
          divisionType: 'Distillery',
        },
        {
          featureName: 'Production Line',
          featureId: 'P-PL-1',
          divisionType: 'Distillery',
        },
        {
          featureName: 'Batch Creation',
          featureId: 'P-BP-1',
          divisionType: 'Distillery',
        },
      ],
    },
    {
      moduleId: 'indent-management',
      moduleName: 'Indent management',
      description: 'Indent management',
      order: 50,
      features: [
        {
          featureName: 'Indent for supply',
          featureId: 'IM-IFS-1',
          divisionType: 'Distillery',
        }
      ],
    },
    {
      moduleId : 'shipments',
      moduleName: 'Shipments',
      description: 'Delivery',
      order:80,
      features:[
        {
          featureName: 'Order for supply',
          featureId: 'S-OFS-1',
          divisionType: 'Distillery'
        },
        {
          featureName: 'Purchase orders',
          featureId: 'S-PO-1',
          divisionType: 'Distillery'
        },
        {
          featureName: 'Transport Permit',
          featureId: 'S-TP-1',
          divisionType: 'Distillery'
        }
      ]
    },
    {
      moduleId: 'stock',
      moduleName: 'Stock',
      order:70,
      features: [
        {
          featureName: 'Stock',
          featureId: 'S-S-01',
          divisionType: 'Distillery'
        }
        ]
    },
    {
      moduleId : 'dashboard',
      moduleName : 'Dashboard',
      description : 'Dashboard',
      order: 10,
      features : []
    },
    {
      moduleId : 'distillery-info',
      moduleName : 'Distillery info',
      description : 'Distillery details',
      order:20,
      features : [
        {
        featureName: 'Distilleries',
        featureId: 'DI-D-1',
        divisionType: 'Distillery',
      },
      {
        featureName: 'Licenses',
        featureId: 'D-L-1',
        divisionType: 'Distillery',
      },
      {
        featureName: 'Users',
        featureId: 'D-U-1',
        divisionType: 'Distillery',
      },
      {
        featureName: 'Blend tanks',
        featureId: 'D-BT-1',
        divisionType: 'Distillery',
      },
      {
        featureName: 'Bottling lines',
        featureId: 'D-BL-1',
        divisionType: 'Distillery',
      },
      {
        featureName: 'Gate register',
        featureId: 'D-GR-1',
        divisionType: 'Distillery',
      }
    ]
    }
];
    
export default MODULES;