import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpiritRegisterService } from 'src/app/core/services/spirit-register.service';

@Component({
  selector: 'app-manage-ena-stock-dialog',
  templateUrl: './manage-ena-stock-dialog.component.html',
  styleUrls: ['./manage-ena-stock-dialog.component.scss']
})
export class ManageEnaStockDialogComponent implements OnInit {
  enaStockForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  units = ['OP', 'UP'];


  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageEnaStockDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private spiritService: SpiritRegisterService) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.enaStockForm = this.fb.group({
      date: ['', [Validators.required, ]],
      receiverNumber: ['', [Validators.required, ]],
      production: ['', [Validators.required, ]],
      strength: ['', [Validators.required, ]],
      unitProduction: ['', [Validators.required, ]],
      unit: ['', [Validators.required, ]],
      receipts: ['', [Validators.required, ]],
      source: ['', [Validators.required, ]],
    });
    if (this.data && this.data._id) {
      this.enaStockForm.patchValue({
        date: this.data.date,
        receiverNumber: this.data.receiverNumber,
        production: this.data.production,
        strength: this.data.strength,
        unitProduction: this.data.unitProduction,
        unit: this.data.unit,
        receipts: this.data.receipts,
        source: this.data.source,
      });
    }
    this.enaStockForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    })
  }

  get f(){
    return this.enaStockForm.controls;
  }

  createENAStock() {
    this.saveDisabled = true;
    const req_Data = {
      action : 'add_ena_stock',
      data : this.enaStockForm.value
    }
    this.spiritService.createENAStock(req_Data).subscribe((res: any) => {
      this.dialogRef.close('Batch Created Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  updateENAStock() {
    // this.saveDisabled = true;
    // const obj = [{
    //   _id: this.data._id,
    //   update: this.enaStockForm.value
    // }]
    // this.spiritService.putBatch(obj).subscribe((res: any) => {
    //   this.dialogRef.close('Batch Udated Successfully');
    // }, (err) => {
    //   this.error = err.error.message;
    // });
  }

}
