import { ComponentInstanceService } from './../../utils/component-instance';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, matDialogAnimations } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { APBCL } from 'src/app/core/models/users/apbcl.model';
import { UserManualService } from 'src/app/core/services/user-manual.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { featureRules } from '../../utils/feature-rules';
import { ManageRetailerReshuffingComponent } from 'src/app/features/user-accounts/manage-retailer-reshuffing/manage-retailer-reshuffing.component';
import { UserAccountsService } from '../../services/user-accounts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserinfoRetailersDialogComponent } from './userinfo-retailers-dialog/userinfo-retailers-dialog.component';
import { BarDepotReassignService } from '../../services/bar-depot-reassign.service';

@Component({
  selector: 'app-retailer-table',
  templateUrl: './retailer-table.component.html',
  styleUrls: ['./retailer-table.component.scss']
})
export class RetailerTableComponent implements OnInit {

    @Input() writeAccess: boolean;
    @Input() actions: string[];
    @Input() featureName: string;
    @Output() refreshPage = new EventEmitter<boolean>();
    @Input() featuresData: any;
    @Input() moduleName: string;
    @Input() nodeType:string =  '';
    @Output() resetFilter = new EventEmitter<any>();
    @Output() callBack = new EventEmitter<any>();
    @Input() showDateFilter: any;
    @Input() showStatusFilter: any;
    displayedColumns: Array<string>;
    columns: Array<any>;
    dataSource: MatTableDataSource<APBCL> = new MatTableDataSource([]);
    limit: number;
    offset: number;
    search: string;
    tableLength: number;
    showCreate: boolean = false;
    showCreate2: boolean = false;
    createModal: any;
    createModal2: any;
    rules: any;
    userDetails: any;
    error: string;
    isSearch:boolean;
    selection = new SelectionModel<any>(true, []);
    fromDate: any;
    toDate: any;
    today: any = new Date();
    healStatus: any ='';
    statusList: any =['Approved','Completed','Dispatched','Received','Reviewed'];
    selectedStatus: any = '';
  
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('input') input: ElementRef;
    
    serviceCall: any;
  
  
    constructor(
      public util: UtilService,
      public featureRules: featureRules,
      private usersService: UserManualService,
      private dialog: MatDialog,
      private userAccountsService: UserAccountsService,
      private barDepotReassignService: BarDepotReassignService,
      private messageService: MessageService,
      private componentInstance: ComponentInstanceService,
      private spinner: NgxSpinnerService
    ) { this.userDetails = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')) }
  
    ngOnInit(): void {
      this.limit = 10;
      this.offset = 0;
      this.rules = this.featureRules[`${this.moduleName.replace(/-|\s/g, '')}${this.featureName.replace(/-|\s/g, '')}`];
      if (!this.rules) {
        return;
      }
      this.columns = this.rules.columnsToDisplay[this.userDetails["role"]] ? this.rules.columnsToDisplay[this.userDetails["role"]] : this.rules.columnsToDisplay[Object.keys(this.rules.columnsToDisplay)[0]];
      this.createModal = this.rules.createRecord[this.userDetails["role"]];
      this.createModal2 = this.rules.createRecord2 ? this.rules.createRecord2[this.userDetails.role] : '';
      if (this.createModal2) {
        this.showCreate2 = true;
      }
      this.displayedColumns = this.columns.map(col => col.key);
      this.getTableDetails();
      if (this.moduleName === 'Bar Depot Reassign') {
        this.serviceCall = this.barDepotReassignService;
      } else {
        this.serviceCall = this.userAccountsService;
      }
    }
  
    ngOnChanges(): void {
      this.getTableDetails();
    }
  
    createRecord() {
      console.log(this.createModal);
      let data: any = this.createModal.tab ? {tabName: this.createModal.tab} : null;
      if (this.moduleName === 'Products' && data === null) {
        data = {};
        data.data = this.featuresData;
        data.moduleName = this.moduleName;
      }
      
      this.util.openModalDialog(this.componentInstance.getComponent(this.createModal.component), data, this.createModal.width, this.createModal.height, false, this.refreshPage, this.getTableDetails.bind(this));
    }
    createRecord2() {
      console.log(this.createModal2);
      let data = this.createModal2.tab ? {tabName: this.createModal2.tab} : null;
      console.log(data);
      
      this.util.openModalDialog(this.componentInstance.getComponent(this.createModal2.component), data, this.createModal2.width, this.createModal2.height, false, this.refreshPage, this.getTableDetails.bind(this));
    }
  
    // updateRetailer(changeStatus){
    //   let payload = {
    //     action: 'update_retailers',
    //     data:{
    //       status: changeStatus
    //     }
    //   }
    //   this.userAccountsService.createRetailer(payload).subscribe((res: any) => {
    //   }, (err) => {
    //     console.log(err)
    //   });    
    // }
  
    openReshuffling(dialogTitle, statusName, row: any = {}) {
      const datas: any = {
        title: dialogTitle,
        url: 'features',
        status: statusName,
        assignedDepot: row.assignedDepot,
        featureName: this.featureName,
        moduleName: this.moduleName
      };
      if (this.featureName === 'Retailers') {
        datas.retailerId = row.retailerId;
      } else if (this.featureName === 'Vendors' || this.featureName === 'Bars') {
        datas.vendorId = row.vendorId;
      }
      const dialogRef = this.dialog.open(ManageRetailerReshuffingComponent, {
        width: '50%',
        autoFocus: false,
        data: datas
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        if (result && result.message && result.message === 'SUCCESS') {
          // this.messageService.add({ severity: 'success', detail: 'Updated Successfully' });
          this.refreshPage.emit(true);
        }
      });
    }
  
    getModel(action, column){
      return column.modal.filter(modal => modal.actionLink === action)[0];
    }
  
    editRecord(data, modal, colName) {
      const modalDetails = modal ? modal : this.createModal ? this.createModal : '';
      
      if (colName) {
        data['colName'] = colName;
      }
  
      if (modalDetails.tab) {
        data['tabName'] = modalDetails.tab;
      }
  
      if((modalDetails.tab === 'documents' && data.status === 'InActive') || (modalDetails.tab === 'exeHeals' && data.status !== 'Approved')){
        return false;
      }
      if (modalDetails) {
        this.util.openModalDialog(this.componentInstance.getComponent(modalDetails.component), data, modalDetails.width, modalDetails.height, false, this.refreshPage, this.getTableDetails.bind(this), modalDetails.actionLink);
      }
    }
  
    isArray(item) {
     return Array.isArray(item);
    }
  
    getTableDetails() {
      if (this.showDateFilter && (!this.featuresData || this.featuresData.length === 0)) {
        this.dataSource = new MatTableDataSource([]);
      }
      if ((!this.isSearch  || this.showDateFilter) && this.featuresData && this.featuresData.length > 0) {
        this.dataSource = new MatTableDataSource(this.featuresData);
        this.tableLength = this.featuresData.length;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      if(this.nodeType ===  'Sublease' &&( this.featureName === 'Tanks' || this.featureName === 'Bottling Lines' )){
        this.showCreate = false;
      }else{
        this.showCreate = !!this.createModal;
      }
    }
  
    ngAfterViewInit() {
      this.util.ngAfterViewInit(this.dataSource, this.paginator, this.sort, this.input.nativeElement, this.getTableDetails.bind(this));
    }
  
    applyFilter(searchFlag: boolean) {
      this.isSearch = searchFlag;
      this.util.applyFilter(this.dataSource, this.search);
    }
  
    convertToDate(date) {
      return date ? moment(date).format('DD/MM/YY, h:mm a') : '-';
    }
    convertToDateOnly(date) {
      return moment(date).format('DD/MM/YY');
    }
  
    onPaginateChange(e) {
      this.limit = e.pageSize;
      this.offset = e.pageIndex * e.pageSize;
      this.getTableDetails();
    }
  
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      this.dataSource.paginator = this.paginator;
      return numSelected === numRows;
    }
  
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }
    clearFilters(): any {
      this.dataSource.filter = '';
      this.search = '';
    }
    getFilterByDate(): any {
      const datas: any = {fromDate: this.util.getDateOnly(this.fromDate), toDate: this.util.getDateOnly(this.toDate)};
      if(this.featureName === 'Distillery' || this.featureName === 'Depot'){
        datas['status'] = this.healStatus;
      }
      this.resetFilter.emit(datas);
    }
    getFilterByStatus(): any {
      const datas: any = {};
      datas.status = this.selectedStatus;
      this.resetFilter.emit(datas);
    }
    dialogConfirmation(status, row): any {
      const dialogRef = this.dialog.open(UserinfoRetailersDialogComponent, {
        width: '50%',
        autoFocus: false,
        data: {buttonLabel: status, title: `Are you sure you want to change the status to ${status} ?`}
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
        if (result && result.message && result.message === 'SUCCESS') {
          this.activeInactive(status, row);
        }
      });
    }
    activeInactive(status, row): any {
      this.spinner.show();
      const datas: any = {
        status: status
      };
      if (this.featureName === 'Retailers') {
        datas.retailerId = row.retailerId;
      } else if (this.featureName === 'Vendors') {
        datas.vendorId = row.vendorId;
      }
      const payload = {
        action: 'update_retailers',
        data: datas
      }
      
      this.serviceCall.update(payload).subscribe((res: any) => {
        if (res && res.status && res.status === 'Retailer Updated Successfully') {
          this.refreshPage.emit(true);
          this.messageService.add({ severity: 'success', detail: res.status });
        } else {
          this.messageService.add({ severity: 'error', detail: res && res.error ?  res.error : 'Not Updated' });
        }
        this.spinner.hide();
      }, err => {
        console.log(err);
        this.messageService.add({ severity: 'error', detail : 'Not Updated' });
        this.spinner.hide();
      });  
    }
  

}
