import { Component, OnInit, ViewChild, ElementRef, Inject, OnChanges, TemplateRef} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {  MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommonsService } from 'src/app/core/services/commons.service';
import { HologramsService } from 'src/app/core/services/holograms.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
declare const JsBarcode: any;
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-hologram-spools-details-dialog',
  templateUrl: './hologram-spools-details-dialog.component.html',
  styleUrls: ['./hologram-spools-details-dialog.component.scss']
})
export class HologramSpoolsDetailsDialogComponent implements OnInit, OnChanges {

  displayedColumns: string[] = [
    'no',
    'firstHealCode',
    'lastHealCode',
    'spoolBarcode',
    'color'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;
  healSpoolsLength: number;
  addCartonsSpooolsForm: FormGroup;
  cartonCode: string;
  cartonQrCodeData: any;
  cartonLength: number;

  printDatas: any = {
    cols: [],
    rows: [],
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;
  @ViewChild('confirmationDialog') confirmationDialog: TemplateRef<any>;
  private confirmDialogRef: MatDialogRef<TemplateRef<any>>;

  error: string;
  roleName: any;
  constructor(
    public util: UtilService,
    private fb: FormBuilder,
    private hologramsService: HologramsService,
    private commonsService: CommonsService,
    private dialogRef: MatDialogRef<HologramSpoolsDetailsDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
  }

  ngOnInit(): void {
    this.limit = 10;
    this.offset = 0;
    this.getSpoolsDetails();

    this.addCartonsSpooolsForm = this.fb.group({
      spoolBarcodes: ''
    });
    if (this.data && this.data.cartons && this.data.cartons.length) {
      this.createPrintDatas();
    }
    
  }

  createPrintDatas(): any {
    this.printDatas.cols = ['S.No', 'Carton Code','Spool Code'];
    this.printDatas.rows = [];
    this.data.cartons.forEach((carton, k) => {
      if (carton && carton.spoolBarcodes && carton.spoolBarcodes.length) {
        carton.spoolBarcodes.forEach((spool, i) => {
          const data = [i + 1, (carton.cartonBarcode || '-'), (spool.spoolBarcode || '-')];
          this.printDatas.rows.push(data);
        });
      }
    });
  }

  ngOnChanges(): void {
    this.getSpoolsDetails();
  }
  
  onAddSpools(e) {

  }

  openConfirmation() {
    if(this.cartonQrCodeData){
      this.confirmDialogRef = this.dialog.open(this.confirmationDialog,{
        width: '25%',
        // height:'45%',
        autoFocus: false
      });
    }else{
      if (this.dialogRef) {
        this.dialogRef.close();
      }
    }

}
closeConfirmDialog() {
  this.confirmDialogRef.close();
}
  addCartonsSpools(){
    const req_Data = {
      action: 'add_cartons_spools',
      data: {
        requestedEntity: this.data.entityType,
        supplierCode: this.data.entityId,
        healIndentId: this.data.healIndentId,
        requestedQuantity: this.data.requestedQuantity,
        purpose: this.data.purpose,
        cartonBarcode: this.cartonCode,
        spoolBarcodes: this.addCartonsSpooolsForm.value.spoolBarcodes
      }
    }
    console.log(req_Data)
    this.hologramsService.updateHealPurchaseIndents(req_Data).subscribe((res: any) => {
      const requiredCartonLength = (this.data.approvedQuantity/100000);
      if(requiredCartonLength === res.cartonsLength){
        this.dialogRef.close('Spools added Successfully');
        this.closeConfirmDialog();

      }else{
        this.resetAll();
      }
    }, (err) => {
      this.error = err.error.message;
    });
  }

  getSpoolCodeData(){
    if(this.data.colName !== 'cartons'){
      this.data.spools?.forEach(spool => {
        spool.spoolQrCodeData = JSON.stringify({
          purpose:  this.data.purpose,
          firstHealCode: spool.firstHealCode,
          lastHealCode: spool.lastHealCode,
          spoolBarcode: spool.spoolBarcode,
          entityId: this.data.entityId
        });
      })
      
    }
  }

  generateCaseCode(){
      const qData = {
        data: {
          action: 'get_carton_code',
          value: this.data.entityId
        }
      };
      // tslint:disable-next-line:variable-name
      const req_Data = {
        action: 'common_apis',
        q: JSON.stringify(qData)
      };
      this.commonsService.getCommonEntites(req_Data).subscribe(res => {
        if (res && res.get_carton_code) {
          this.cartonCode = res.get_carton_code;
          this.cartonQrCodeData = JSON.stringify({
            purpose: this.data.purpose,
            entityId:this.data.entityId,
            cartonCode: this.cartonCode
          });
        }
      });

  }

  resetAll(){
    this.addCartonsSpooolsForm.reset();
    this.cartonQrCodeData = null;
  }

  getSpoolsDetails() {
    if (this.data && this.data.spools?.length > 0) {
      this.getSpoolCodeData();
      this.dataSource = new MatTableDataSource(this.data.spools);
      this.healSpoolsLength = this.data.spools.length;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter() {
    this.util.applyFilter(this.dataSource, this.search);
  }

  onPaginateChange(e) {
    this.limit = e.pageSize;
    this.offset = e.pageIndex * e.pageSize;
    this.getSpoolsDetails();
  }
  exportExcel(): void {
    const wb = XLSX.utils.book_new();
    let ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [this.printDatas.cols]);
    XLSX.utils.sheet_add_json(ws, this.printDatas.rows, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `scanned-spools-${this.data.healIndentId}.xlsx`);
  }

}
