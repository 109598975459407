import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ExportService } from 'src/app/core/services/export.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { SelectionModel } from '@angular/cdk/collections';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-export-challan-details',
  templateUrl: './export-challan-details.component.html',
  styleUrls: ['./export-challan-details.component.scss']
})
export class ExportChallanDetailsComponent implements OnInit {
  displayedColumns: string[] = [
    "select",
    'no',
    "transType",
    // "bank",
    "date",
    "challanNo",
    "balance"
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;
  vendorIndentsLength: number;
  isSearch:boolean;
  featuresData: any;
  features:any;
  sumOfRemainingExport: number = 0;
  sumOfRemainingCommission: number = 0;
  indexOfCheckbox = [];
  indexOfCheckbox1 = [];
  checkBoxDisable= false;
  loader:boolean;
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  totalDatas: any = {};
  @Output() refreshPage = new EventEmitter<boolean>();
  constructor( @Inject(MAT_DIALOG_DATA) public data, public util: UtilService,
  public dialogRef: MatDialog,
  public matDialog:MatDialogRef<ExportChallanDetailsComponent>,
  private exportService: ExportService,private messageService: MessageService,) { }

  ngOnInit(): void {
    this.getFeatures();
    console.log(this.data);
    this.getTotal();
  this.featuresData = this.featuresData['Add Challan'].map((n,i)=>Object.assign(n,{'disabled': 'false','index': i}))
  this.dataSource = new MatTableDataSource(this.featuresData);
  }
  getTotal(){
    this.totalDatas = {};
    this.totalDatas.commissionFee = 0;
    this.totalDatas.exportFee = 0;
    if (this.data && this.data.productDetails.length) {
      this.data.productDetails.forEach((item:any)=>{
        this.totalDatas.commissionFee += item.commision ? parseFloat(item.commision) : 0;
        this.totalDatas.exportFee += item.exportFee ? parseFloat(item.exportFee) : 0;
      })
    }

  }

  clearFilters(): any {
    this.dataSource.filter = '';
    this.search = '';
  }

  ngOnChanges() {
    this.getChallanDetails();
  }
  getFeatures() {
    const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).mod_feats;
    this.features = userModules['Export'].features;
    this.exportService.getExportFeatures().subscribe(res => {
      this.featuresData = res;
      this.getChallanDetails();
    });
  }
  getChallanDetails() {
    if (this.featuresData) {
      this.dataSource = new MatTableDataSource(this.featuresData['Add Challan']);
      console.log(this.dataSource.filteredData)
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  ngAfterViewInit() {
    this.util.ngAfterViewInit(this.dataSource, this.paginator, this.sort, '', '');
  }

  applyFilter(searchFlag: boolean) {
    this.isSearch = searchFlag;
    this.util.applyFilter(this.dataSource, this.search);
  }

  onPaginateChange(e) {
    this.limit = e.pageSize;
    this.offset = e.pageIndex * e.pageSize;
  }
  
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
   /** Selects all rows if they are not all selected; otherwise clear selection. */
   masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  save(){
    let totalBalance = 0;
    let comissionFeeErr;
    let exportFeeErr;
    let selectedExportFee = 0;
    let selectedCFee = 0;
    const totalAmount =  this.totalDatas.commissionFee +  this.totalDatas.exportFee;
    delete this.data.actionLink;
    this.data.challanDetails = this.selection['_selected'];
  
  
    const noChallans = (this.data.challanDetails == undefined || this.data.challanDetails == null) ? 'Please Select Challans' : ''
    // const errMessage =  (totalBalance < totalAmount) ? 'Insufficient Balance' : '';
    console.log(this.data.challanDetails)
    if(this.data.challanDetails){
      this.data.challanDetails.forEach((item:any)=>{
        if(item.challanType === "Export Fee"){
          selectedExportFee += item.remainingAmount ? parseFloat(item.remainingAmount) :0;
          exportFeeErr = selectedExportFee < this.totalDatas.exportFee;
          this.data.exportFeeOpening = item.remainingAmount ? item.remainingAmount :0;
          this.data.exportFeeInitial = item.initialAmount ? item.initialAmount :0;
        }else if(item.challanType === "Commission Fee"){
          selectedCFee += item.remainingAmount ? parseFloat(item.remainingAmount) :0;
          comissionFeeErr = selectedCFee < this.totalDatas.commissionFee;
          this.data.commissionFeeOpening = item.remainingAmount ? item.remainingAmount :0;
          this.data.CommissionFeeInitial = item.initialAmount ? item.initialAmount :0;
        }
         
        // totalBalance += item.remainingAmount ? parseFloat(item.remainingAmount) : 0;
      })
     const isExportExist = this.data.challanDetails.some(el => el.challanType === 'Export Fee');
     const isComisionFeeExist = this.data.challanDetails.some(el => el.challanType === 'Commission Fee');
     if(!isExportExist){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "Please Select Export Fee Challan" });
      return;
     }else if(!isComisionFeeExist){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "Please Select Comission Fee Challan" });
      return;
     }
    }
    const reqData={
      action:'add_export',
      data:this.data
    }
    console.log(reqData);
    if(exportFeeErr){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "Insufficient Export Balance" });
    }
    else if(comissionFeeErr){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "Insufficient Challan Balance" });
    }
    else if(noChallans){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: noChallans });
    }
    else{
      this.loader = true;
       this.exportService.createIndent(reqData).subscribe((res:any)=>{
      console.log(res);
      if(res.status=='SUCCESS'){
        this.matDialog.close({
            message: res.status
          });
        
        // this.messageService.add({ severity: 'success', summary: 'success', detail: 'Indent Added Successfully' });
        this.getFeatures();
        this.loader = false;
      }
      
    })
    }
 
  }
  checkBoxStatus(row,index){
    let checkBoxChecked = document.getElementsByClassName('check-status')[index].getElementsByTagName('label')[0].childNodes[0].firstChild['attributes']['aria-checked'].value;
    if(row.challanType == 'Export Fee'){
      if(checkBoxChecked == 'false'){
        this.sumOfRemainingExport +=row.remainingAmount;
        this.indexOfCheckbox.push(row.index)
      }else{
        this.sumOfRemainingExport -=row.remainingAmount;
        let indexOfChecked = this.indexOfCheckbox.indexOf(row.index)
        this.indexOfCheckbox.splice(indexOfChecked,indexOfChecked+1)
      }
      if(this.sumOfRemainingExport > this.totalDatas.commissionFee){
        this.dataSource.filteredData.map((n,i)=>{
          !this.indexOfCheckbox.includes(i) && n.challanType == 'Export Fee' ? n.disabled = true : false;
        })
      }else{
        this.dataSource.filteredData.map((n)=>{
          if(n.challanType == 'Export Fee'){
            n.disabled = false;
          }
        })
      }
    }else if(row.challanType == 'Commission Fee'){
      if(checkBoxChecked == 'false'){
        this.sumOfRemainingCommission +=row.remainingAmount;
        this.indexOfCheckbox1.push(row.index)
      }else{
        this.sumOfRemainingCommission -=row.remainingAmount;
        let indexOfChecked = this.indexOfCheckbox1.indexOf(row.index)
        this.indexOfCheckbox1.splice(indexOfChecked,indexOfChecked+1)
      }
      if(this.sumOfRemainingCommission > this.totalDatas.commissionFee){
        this.dataSource.filteredData.map((n,i)=>{
        !this.indexOfCheckbox1.includes(i) && n.challanType == 'Commission Fee' ? n.disabled = true : false
        })
      }else{
        this.dataSource.filteredData.map((n)=>{
          if(n.challanType == 'Commission Fee'){
            n.disabled = false;
          }
        })
      }
    }
    

  }
}
