import { style } from '@angular/animations';
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

/**
 *  if columns are same for all roles then no need to add for every role.
 *  Just add common columns and any one role that applies.
 */

export class featureRules {

  public DashboardDistilleryStock = {
    columnsToDisplay: {
      "AS Labels": [
        { key: 'no', label: 'S.No.' },
        { key: 'brandCode', label: 'Brand Code' },
        { key: 'sizeInMl', label: 'Size(ml)' },
        { key: 'unitsPerCase', label: 'Units Per Case' },
        { key: 'brandName', label: 'Product Name' },
        { key: 'location', label: 'Location' },
        // { key: 'production', label: 'Production' },
        { key: 'dispatches', label: 'Sales' },
        { key: 'closingBal', label: 'Current Stock' }
      ]
    },
    createRecord: {}
  };

  public DashboardHourlyProductionReport = {
    columnsToDisplay: {
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'lineName', label: 'Line Name' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandName', label: 'Product Name' },
        { key: 'batchId', label: 'Batch ID' },
        { key: 'cases', label: 'Cases' },
      ]
    },
    createRecord: {}
  };

  public DashboardDepotSale = {
    columnsToDisplay: {
      "AS Labels": [
        { key: 'no', label: 'S.No.' },
        { key: 'depotName', label: 'Depot Name' },
        { key: 'icdc', label: 'ICDC' },
        { key: 'tp', label: 'TP' },
        { key: 'imlSale', label: 'IML Sale' },
        { key: 'beerSale', label: 'Beer Sale' },
        { key: 'sale', label: 'Sale(Rs)' }
      ]
    },
    createRecord: {}
  };
  

  public DashboardProductionDashboard = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'productionType', label: 'Production Type' },
        { key: 'lineName', label: 'Line' },
        { key: 'lineType', label: 'Line Type' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'batchId', label: 'Batch Number' },
        { key: 'packType', label: 'Pack Type' },
        { key: 'healStartCode', label: 'Heal Start Number' },
        { key: 'bottleCount', label: 'Bottle Count' },
        { key: 'caseCount', label: 'Case Count' },
        { key: 'totalCaseCount', label: 'Total Case Count' },
        { key: 'currentCase', label: 'Current Case' },
        { key: 'previousCase', label: 'Previous Case' }
      ]
    },
    createRecord: {}
  };

  public DashboardASN = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'asnDate', label: 'ASN Date', type: 'date' },
        { key: 'asnId', label: 'ASN ID' },
        { key: 'sourceEntityName', label: 'Supplier Name' },
        { key: 'transporterName', label: 'Transporter Name', path: 'transporterDetails' },
        { key: 'totalCases', label: 'Quantity(Cases/Bottles)' },
        { key: 'status', label: 'Status', icon: { brown: ['Delivered'], warning: ['Reviewed'], black: ['TPScanned'], yellow: ['Created'], success: ['Reached'], info: ['InTransit','InProgress'], purple: ['Revalidated']} }
      ]
    },
    createRecord: {}
  };

  public DashboardSTOCK = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'size', label: 'Size(ml)' },
        { key: 'category', label: 'Category' },
        { key: 'Ob', label: 'OB(Cases/Bottles)' },
        { key: 'stockIN', label: 'Stock In(Cases/Bottles)' },
        { key: 'dispatches', label: 'Sale(Cases/Bottles)' },
        { key: 'balance', label: 'Balance(Cases/Bottles)' },
      ]
    },
    createRecord: {}
  };

  public DashboardINDENT = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'requestedCases', label: 'Quantity In Cases', path: 'productDetails'},
        { key: 'totalBottles', label: 'quantity In Bottles', path: 'productDetails'},
        { key: 'status', label: 'Status', icon: { success: ['Approved'], warning: ['InProgress'], yellow: ['Requested'], danger: ['Rejected'], info: ['InTransit']} }
      ]
    },
    createRecord: {}
  };

  public DashboardICDC = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'icdcCode', label: 'ICDC Number' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'requestedCases', label: 'Quantity In Cases', path: 'productDetails'},
        { key: 'totalBottles', label: 'quantity In Bottles' ,path: 'productDetails'},
        { key: 'totalValue', label: 'ICDC Amount(Rs/PS)' }
      ]
    },
    createRecord: {}
  };

  public GateRegisterInwardRegister = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'purpose', label: 'Purpose', type: 'link', modal: { component: 'OpenDescriptionDialogComponent', width: '50%' } },
        // { key: 'description', label: 'Description' },
        { key: 'registerType', label: 'Type Of Register' },
        { key: 'vehicleNo', label: 'Vehicle Number', subkey: 'transporterDetails' },
        { key: 'inTime', label: 'Arrival Date And Time', type: 'dateTime' }
      ]
    },
    createRecord: {
      // "Depot Manager": {
      //   component: 'ManageInwardGateRegisterDialogComponent',
      //   width: '50%'
      // },
      // "Excise Superintendent Officer": {
      //   component: 'ManageInwardGateRegisterDialogComponent',
      //   width: '50%'
      // },
      // "Data Processing Officer": {
      //   component: 'ManageInwardGateRegisterDialogComponent',
      //   width: '50%'
      // },
      // "Store Officer": {
      //   component: 'ManageInwardGateRegisterDialogComponent',
      //   width: '50%'
      // },
      "Security Officer": {
        component: 'ManageInwardGateRegisterDialogComponent',
        width: '50%'
      },
      "Gate Security": {
        component: 'ManageInwardGateRegisterDialogComponent',
        width: '50%'
      },
    }
  };

  public GateRegisterOutwardRegister = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'purpose', label: 'Purpose', type: 'link', modal: { component: 'OpenOfsDialogComponent', width: '65%' }},
        { key: 'description', label: 'Description' },
        { key: 'registerType', label: 'Type Of Register' },
        { key: 'vehicleNo', label: 'Vehicle Number', subkey: 'transporterDetails' },
        { key: 'outTime', label: 'Departure Date And Time', type: 'dateTime' }
      ]
    },
    createRecord: {
      // "Depot Manager": {
      //   component: 'ManageOutwardGateRegisterDialogComponent',
      //   width: '50%'
      // },
      // "Excise Superintendent Officer": {
      //   component: 'ManageOutwardGateRegisterDialogComponent',
      //   width: '50%'
      // },
      // "Data Processing Officer": {
      //   component: 'ManageOutwardGateRegisterDialogComponent',
      //   width: '50%'
      // },
      // "Store Officer": {
      //   component: 'ManageOutwardGateRegisterDialogComponent',
      //   width: '50%'
      // },
      "Security Officer": {
        component: 'ManageOutwardGateRegisterDialogComponent',
        width: '50%'
      },
      "Gate Security": {
        component: 'ManageOutwardGateRegisterDialogComponent',
        width: '50%'
      }
    }
  };

  public ExciseLicenseeShops = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'supervisorName', label: 'Licensee Name' },
        { key: 'name', label: 'Retailer Name' },
        { key: 'locationType', label: 'Location Name' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'gslNo', label: 'Gazatte Code' },
        {
          key: 'view', label: 'View', headerClass: 'p-4', action: 'edit view', actions: [
            { icon: 'print', component: '' },
            { icon: 'card_membership', modal: { component: 'LicenseeAddressDetailsDialogComponent', width: '65%' } },
            { icon: 'credit_card', modal: { component: 'LicenseeBankDetailsDialogComponent', width: '65%' } },
            { icon: 'edit', modal: { component: 'EditRegistrationFormComponent', width: '75%', height: 'auto' } }
          ]
        }
      ],
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'supervisorName', label: 'Licensee Name' },
        { key: 'name', label: 'Retailer Name' },
        { key: 'locationType', label: 'Location Name' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'gslNo', label: 'Gazatte Code' },
        {
          key: 'view', label: 'View', headerClass: 'p-4', action: 'edit view', actions: [
            { icon: 'print', component: '' },
            { icon: 'card_membership', modal: { component: 'LicenseeAddressDetailsDialogComponent', width: '65%' } },
            { icon: 'credit_card', modal: { component: 'LicenseeBankDetailsDialogComponent', width: '65%' } },
            { icon: 'edit', modal: { component: 'EditRegistrationFormComponent', width: '75%', height: 'auto' } }
          ]
        }
      ]
    },
    createRecord: {
      "Data Processing Officer": {
        component: 'ExciseLicenseeDialogComponent',
        width: '80%'
      },
      "Excise Superintendent": {
        component: 'ExciseLicenseeDialogComponent',
        width: '80%'
      }, 
    }
  };

  public ExciseLicenseeBars = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'licenseName', label: 'Licensee Name' },
        { key: 'name', label: 'Retailer Name' },
        { key: 'locationType', label: 'Location Name' },
        { key: 'retailerIdNumber', label: 'Retailer Code' },
        { key: 'gslNo', label: 'Gazatte Code' },
        {
          key: 'view', label: 'View', headerClass: 'p-4', action: 'edit view', actions: [
            { icon: 'print', component: '' },
            { icon: 'card_membership', modal: { component: 'LicenseeAddressDetailsForBarsComponent', width: '65%' } },
            { icon: 'credit_card', modal: { component: 'LicenseeBankDetailsForBarsDialogComponent', width: '50%' } },
            { icon: 'edit', modal: { component: 'EditRegistrationForBarsComponent', width: '75%', height: 'auto' } }
          ]
        }
      ],
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'licenseName', label: 'Licensee Name' },
        { key: 'name', label: 'Retailer Name' },
        { key: 'locationType', label: 'Location Name' },
        { key: 'retailerIdNumber', label: 'Retailer Code' },
        { key: 'gslNo', label: 'Gazatte Code' },
        {
          key: 'view', label: 'View', headerClass: 'p-4', action: 'edit view', actions: [
            { icon: 'print', component: '' },
            { icon: 'card_membership', modal: { component: 'LicenseeAddressDetailsForBarsComponent', width: '65%' } },
            { icon: 'credit_card', modal: { component: 'LicenseeBankDetailsForBarsDialogComponent', width: '50%' } },
            { icon: 'edit', modal: { component: 'EditRegistrationForBarsComponent', width: '75%', height: 'auto' } }
          ]
        },
        { key:'action', label:'Status',type:'actionButton'}
      ]
    },
    createRecord: {
      "Data Processing Officer": {
        component: 'ExciseLicenseeDialogComponent',
        width: '80%'
      },
      "Excise Superintendent": {
        component: 'ExciseLicenseeDialogComponent',
        width: '80%'
      }, 
    }
  };

  public ExciseLicensee2T = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'licenseName', label: 'Licensee Name' },
        { key: 'name', label: 'Retailer Name' },
        { key: 'locationType', label: 'Location Name' },
        { key: 'retailerIdNumber', label: 'Retailer Code' },
        { key: 'gslNo', label: 'Gazatte Code' },
        {
          key: 'view', label: 'View', headerClass: 'p-4', action: 'edit view', actions: [
            { icon: 'print', component: '' },
            { icon: 'card_membership', modal: { component: 'ShowAddressDetailsDialogComponent', width: '65%' } },
            { icon: 'credit_card', modal: { component: 'ShowBankDetailsDialogComponent', width: '65%' } },
            { icon: 'edit', modal: { component: 'EditRegistrationFormDialogComponent', width: '75%', height: '95%' } }
          ]
        }
      ],
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'licenseName', label: 'Licensee Name' },
        { key: 'name', label: 'Retailer Name' },
        { key: 'locationType', label: 'Location Name' },
        { key: 'retailerIdNumber', label: 'Retailer Code' },
        { key: 'gslNo', label: 'Gazatte Code' },
        {
          key: 'view', label: 'View', headerClass: 'p-4', action: 'edit view', actions: [
            { icon: 'print', component: '' },
            { icon: 'card_membership', modal: { component: 'ShowAddressDetailsDialogComponent', width: '65%' } },
            { icon: 'credit_card', modal: { component: 'ShowBankDetailsDialogComponent', width: '65%' } },
            { icon: 'edit', modal: { component: 'EditRegistrationFormDialogComponent', width: '75%', height: '95%' } }
          ]
        },
        { key:'action', label:'Status',type:'actionButton'}
      ]
    },
    createRecord: {
      "Data Processing Officer": {
        component: 'ExciseLicenseeDialogComponent',
        width: '80%'
      },
      "Excise Superintendent": {
        component: 'ExciseLicenseeDialogComponent',
        width: '80%'
      },      
    }
  };

  public ExciseLicensee2F = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'licenseName', label: 'Licensee Name' },
        { key: 'name', label: 'Retailer Name' },
        { key: 'locationType', label: 'Location Name' },
        { key: 'retailerIdNumber', label: 'Retailer Code' },
        { key: 'gslNo', label: 'Gazatte Code' },
        {
          key: 'view', label: 'View', headerClass: 'p-4', action: 'edit view', actions: [
            { icon: 'print', component: '' },
            { icon: 'card_membership', modal: { component: 'LicenseeDetailsForTwofDialogComponent', width: '65%' } },
            { icon: 'credit_card', modal: { component: 'BankDetailsForTwofDialogComponent', width: '65%' } },
            { icon: 'edit', modal: { component: 'EditRegistrationForTwofDialogComponent', width: '75%', height: '95%' } }
          ]
        }
      ],
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'licenseName', label: 'Licensee Name' },
        { key: 'name', label: 'Retailer Name' },
        { key: 'locationType', label: 'Location Name' },
        { key: 'retailerIdNumber', label: 'Retailer Code' },
        { key: 'gslNo', label: 'Gazatte Code' },
        {
          key: 'view', label: 'View', headerClass: 'p-4', action: 'edit view', actions: [
            { icon: 'print', component: '' },
            { icon: 'card_membership', modal: { component: 'LicenseeDetailsForTwofDialogComponent', width: '65%' } },
            { icon: 'credit_card', modal: { component: 'BankDetailsForTwofDialogComponent', width: '65%' } },
            { icon: 'edit', modal: { component: 'EditRegistrationForTwofDialogComponent', width: '75%', height: '95%' } }
          ]
        },
        { key:'action', label:'Status',type:'actionButton'}
      ]
    },
    createRecord: {
      "Data Processing Officer": {
        component: 'ExciseLicenseeDialogComponent',
        width: '80%'
      },
      "Excise Superintendent": {
        component: 'ExciseLicenseeDialogComponent',
        width: '80%'
      }, 
    }
  };

  public ExciseLicenseeClubs = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'licenseName', label: 'Licensee Name' },
        { key: 'name', label: 'Retailer Name' },
        { key: 'locationType', label: 'Location Name' },
        { key: 'retailerIdNumber', label: 'Retailer Code' },
        { key: 'gslNo', label: 'Gazatte Code' },
        { key: 'view', label: 'View', modal: { component: '' }, icon: { type: 'pi pi-eye ml-2 text-success' } }
      ],
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'licenseName', label: 'Licensee Name' },
        { key: 'name', label: 'Retailer Name' },
        { key: 'locationType', label: 'Location Name' },
        { key: 'retailerIdNumber', label: 'Retailer Code' },
        { key: 'gslNo', label: 'Gazatte Code' },
        { key: 'view', label: 'View', modal: { component: '' }, icon: { type: 'pi pi-eye ml-2 text-success' } },
        { key:'action', label:'Status',type:'actionButton'}
      ]
    },
    createRecord: {
      "Data Processing Officer": {
        component: 'ExciseLicenseeDialogComponent',
        width: '80%'
      },
      "Excise Superintendent": {
        component: 'ExciseLicenseeDialogComponent',
        width: '80%'
      }, 
    }
  };

  public ExciseLicenseeTD1 = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'licenseName', label: 'Licensee Name' },
        { key: 'name', label: 'Retailer Name' },
        { key: 'locationType', label: 'Location Name' },
        { key: 'retailerIdNumber', label: 'Retailer Code' },
        { key: 'gslNo', label: 'Gazatte Code' },
        { key: 'view', label: 'View', modal: { component: '' }, icon: { type: 'pi pi-eye ml-2 text-success' } }
      ],
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'licenseName', label: 'Licensee Name' },
        { key: 'name', label: 'Retailer Name' },
        { key: 'locationType', label: 'Location Name' },
        { key: 'retailerIdNumber', label: 'Retailer Code' },
        { key: 'gslNo', label: 'Gazatte Code' },
        { key: 'view', label: 'View', modal: { component: '' }, icon: { type: 'pi pi-eye ml-2 text-success' } },
        { key:'action', label:'Status',type:'actionButton'}
      ]
    },
    createRecord: {
      "Data Processing Officer": {
        component: 'ExciseLicenseeDialogComponent',
        width: '80%'
      },
      "Excise Superintendent": {
        component: 'ExciseLicenseeDialogComponent',
        width: '80%'
      }, 
    }
  };

  public ExciseLicenseeTD2 = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'licenseName', label: 'Licensee Name' },
        { key: 'name', label: 'Retailer Name' },
        { key: 'locationType', label: 'Location Name' },
        { key: 'retailerIdNumber', label: 'Retailer Code' },
        { key: 'gslNo', label: 'Gazatte Code' },
        { key: 'view', label: 'View', modal: { component: '' }, icon: { type: 'pi pi-eye ml-2 text-success' } }
      ],
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'licenseName', label: 'Licensee Name' },
        { key: 'name', label: 'Retailer Name' },
        { key: 'locationType', label: 'Location Name' },
        { key: 'retailerIdNumber', label: 'Retailer Code' },
        { key: 'gslNo', label: 'Gazatte Code' },
        { key: 'view', label: 'View', modal: { component: '' }, icon: { type: 'pi pi-eye ml-2 text-success' } },
        { key:'action', label:'Status',type:'actionButton'}
      ]
    },
    createRecord: {
      "Data Processing Officer": {
        component: 'ExciseLicenseeDialogComponent',
        width: '80%'
      },
      "Excise Superintendent": {
        component: 'ExciseLicenseeDialogComponent',
        width: '80%'
      }, 
    }
  };

  public ExciseLicenseeEO = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'licenseeName', label: 'Licensee Name' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'locationName', label: 'Location Name' },
        { key: 'retailerIdNumber', label: 'Retailer Code' },
        { key: 'gazatteCode', label: 'Gazatte Code' },
        { key: 'view', label: 'View', modal: { component: '' }, icon: { type: 'pi pi-eye ml-2 text-success' } }
      ],
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'licenseeName', label: 'Licensee Name' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'locationName', label: 'Location Name' },
        { key: 'retailerIdNumber', label: 'Retailer Code' },
        { key: 'gazatteCode', label: 'Gazatte Code' },
        { key: 'view', label: 'View', modal: { component: '' }, icon: { type: 'pi pi-eye ml-2 text-success' } },
        { key:'action', label:'Status',type:'actionButton'}
      ]
    },
    createRecord: {
    //   "Data Processing Officer": {
    //     component: 'ExciseLicenseeDialogComponent',
    //     width: '80%'
    //   }
      // "Excise Superintendent": {
      //   component: 'ExciseLicenseeDialogComponent',
      //   width: '80%'
      // },    
    }
  };

  public ExciseLicenseeSuspendedRetailer = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'name', label: 'Retailer Name' },
        { key: 'suspendedBy', label: 'Suspended By' },
        { key: 'suspendedDate', label: 'Suspended Date', type: 'date' },
        { key: 'reason', label: 'Reason' },
        { key: 'description', label: 'Description' }
      ],
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'name', label: 'Retailer Name' },
        { key: 'suspendedBy', label: 'Suspended By' },
        { key: 'suspendedDate', label: 'Suspended Date', type: 'date' },
        { key: 'reason', label: 'Reason' },
        { key: 'description', label: 'Description' }
      ]
    },
    createRecord: {
      // "Data Processing Officer": {
      //   component: 'ExciseLicenseeDialogComponent',
      //   width: '80%'
      // }
      // "Excise Superintendent": {
      //   component: 'ExciseLicenseeDialogComponent',
      //   width: '80%'
      // }, 
    }
  };

  public ExciseLicenseeEP = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'licenseeName', label: 'Licensee Name' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'locationName', label: 'Location Name' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'gazatteCode', label: 'Gazatte Code' },
        { key: 'view', label: 'View', modal: { component: '' }, icon: { type: 'pi pi-eye ml-2 text-success' } }
      ],
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'licenseeName', label: 'Licensee Name' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'locationName', label: 'Location Name' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'gazatteCode', label: 'Gazatte Code' },
        { key: 'view', label: 'View', modal: { component: '' }, icon: { type: 'pi pi-eye ml-2 text-success' } }
      ]
    },
    createRecord: {
      "Data Processing Officer": {
        component: 'ExciseLicenseeDialogComponent',
        width: '80%'
      },
      "Excise Superintendent": {
        component: 'ExciseLicenseeDialogComponent',
        width: '80%'
      },    
    }
  };

  public ExciseLicenseeRetailersPartners = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'suspendedBy', label: 'Number Of Partners' },
        { key: 'suspendedDate', label: 'Retailer Type', type: 'date' },
        { key: 'reason', label: 'License Amount' },
      ]
    },
    createRecord: {}
  };

  public StockLocal = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandCode', label: 'Brand Code' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'sizeInMl', label: 'Size in ml' },
        { key: 'segment', label: 'Segment' },
        { key: 'unitsPerCase', label: 'Units Per Case' },
        { key: 'category', label: 'Category' },
        { key: 'OpeningStock', label: 'Opening Stock (Cases|Bottles)' },
        { key: 'inwardStock', label: 'Inward Stock (Cases|Bottles)' },
        { key: 'sale', label: 'Sale (Cases|Bottles)' },
        { key: 'transferredStockInCases', label: 'Transferred Stock (Cases|Bottles)' },
        { key: 'transferredIDTStockInCases', label: 'Transferred IDT Stock (Cases|Bottles)' },
        { key: 'outletSalesInBottles', label: 'Outlet Sales (Cases|Bottles)' },
        { key: 'outletReturnStockInCases', label: 'Outlet Return Stock (Cases|Bottles)' },
        { key: 'closingStock', label: 'Closing Stock (Cases|Bottles)', type: 'link', model: { component: 'ClosingStockDetailsDialogComponent', width: '50%' } },
        { key: 'damageQty', label: 'Damage Qty' },
        { key: 'shortageQty', label: 'Shortage Qty' },
        { key: 'sampleToCEInCases', label: 'Sample To CE (Cases|Bottles)' },
        { key: 'stockOnHoldQtyInCases', label: 'Stock OnHold Qty (Cases|Bottles)' },
        { key: 'drainOutStocks', label: 'Drain Out Stock (Cases|Bottles)' },
        { key: 'releasedStockInCases', label: 'Released Stock (Cases|Bottles)' },
        { key: 'stockReturnToSupplierInCases', label: 'Stock Return To Supplier (Cases|Bottles)' },
        { key: 'supplierDrainOut', label: 'Supplier Drainout (Cases|Bottles)' },
        { key: 'createdOn', label: 'Date', type: 'date' }
      ],
      "Store Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandCode', label: 'Brand Code' },
        { key: 'brandName', label: 'Brand Name' },
      ]
    },
    createRecord: {}
  };

  public StockNonLocal = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandCode', label: 'Brand Code' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'size', label: 'Size in ml' },
        { key: 'segment', label: 'Segment' },
        { key: 'unitsPerCase', label: 'Units Per Case' },
        { key: 'category', label: 'Category' },
        { key: 'OpeningStock', label: 'Opening Stock (Cases|Bottles)' },
        { key: 'inwardStock', label: 'Inward Stock (Cases|Bottles)' },
        { key: 'closingStock', label: 'Closing Stock (Cases|Bottles)' },
        { key: 'damageQty', label: 'Damage Qty' },
        { key: 'createdOn', label: 'Date', type: 'date' }
      ],
      "Store Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product Code', type: 'link', modal: { component: 'ClosingStockDetailsDialogComponent', width: '50%' } },
        { key: 'brandCode', label: 'Brand Code' },
        { key: 'brandName', label: 'Brand Name' },
      ]
    },
    createRecord: {}
  };

  public StockComputedPriceList = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandCode', label: 'Brand Number' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'size', label: 'Size Code' },
        { key: 'unitsPerCase', label: 'Units Per Case' },
        { key: 'slab', label: 'Slab ' },
        { key: 'basicPrice', label: 'Basic Price', type: 'number' },
        { key: 'exciseDuty', label: 'Excise Duty' },
        { key: 'landingCost', label: 'Landing Cost' },
        { key: 'apVat', label: 'AP Vat' },
        { key: 'assessmentFee', label: 'Assessment Fee' },
        { key: 'issuePrice', label: 'Issue Price', type: 'number' },
        { key: 'specialMargin', label: 'Special Margin' },
        { key: 'specialPrevFee', label: 'Special Fees' },
        { key: 'barAdditionalRetailExciseTax', label: 'Bar Additional Retail Excise Tax' },
        { key: 'mrp', label: 'MRP', type: 'number' },
        { key: 'applicableFrom', label: 'Effective From',type:'date' },
        { key: 'applicableTo', label: 'Effective To', type:'date' }
      ],
      "Store Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product Code', type: 'link', modal: { component: 'ClosingStockDetailsDialogComponent', width: '50%' } },
        { key: 'brandCode', label: 'Brand Code' },
        { key: 'brandName', label: 'Brand Name' },
      ]
    },
    createRecord: {}
  };

  public StockAddBrandBarcode = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandCode', label: 'Brand Number' },
        { key: 'brandName', label: 'Product Name' },
        { key: 'unitsPerCase', label: 'Units Per Case' },
        { key: 'sizeCode', label: 'Size Code' },
        { key: 'packType', label: 'Pack Type' },
        { key: 'brandBarcode', label: 'Brand Barcode', type: 'link', model: { component: 'AddBrandBarcodeDialogComponent', width: '50%' } },
      ]
    },
    createRecord: {
      "Depot Manager": {
        component: 'AddBrandBarcodeDialogComponent',
        width: '50%'
      },
      "Excise Superintendent Officer": {
        component: 'AddBrandBarcodeDialogComponent',
        width: '50%'
      },
      "Data Processing Officer": {
        component: 'AddBrandBarcodeDialogComponent',
        width: '50%'
      },
      "Store Officer": {
        component: 'AddBrandBarcodeDialogComponent',
        width: '50%'
      }
    }
  };

  public StockHoldOnStock = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandCode', label: 'Product Name' },
        { key: 'HosNo', label: 'Hos No' },
        { key: 'cases', label: 'Quantity(Cases)' },
        { key: 'bottles', label: 'Quantity(Bottles)' },
        { key: 'stockHoldDateTime', label: 'Hold On', type: 'dateTime' },
        { key: 'releaseDateTime', label: 'Un Hold On', type: 'dateTime' },
        { key: 'drainOutDateTime', label: 'Draint Out On', type: 'dateTime' },
        { key: 'updatedDateTime', label: 'Approved On', type: 'dateTime' },
        {
          key: 'status', label: 'Status', modal: { component: 'ManageHoldOnStockDialogComponent', width: '50%' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer text-success', style: 'font-size:12px;cursor:pointer;',
            yellow: ['Created'], info: ['InProgress'], success: ['Release'], danger: ['DrainOut'], warning: ['Approved']
          }
        },
      ],
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandCode', label: 'Product Name' },
        { key: 'HosNo', label: 'Hos No' },
        { key: 'cases', label: 'Quantity(Cases)' },
        { key: 'bottles', label: 'Quantity(Bottles)' },
        { key: 'stockHoldDateTime', label: 'Hold On', type: 'dateTime' },
        { key: 'releaseDateTime', label: 'Un Hold On', type: 'dateTime' },
        { key: 'drainOutDateTime', label: 'Draint Out On', type: 'dateTime' },
        { key: 'updatedDateTime', label: 'Approved On', type: 'dateTime' },
        {
          key: 'status', label: 'Status', modal: { component: 'ManageHoldOnStockDialogComponent', width: '50%' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer text-success', style: 'font-size:12px;cursor:pointer;',
            yellow: ['Created'], info: ['InProgress'], success: ['Release'], danger: ['DrainOut'], warning: ['Approved']
          }
        },
      ]
    },
    createRecord: {
      // "Depot Manager": {
      //   component: 'ManageHoldOnStockDialogComponent',
      //   width: '50%'
      // },
      "Excise Superintendent Officer": {
        component: 'ManageHoldOnStockDialogComponent',
        width: '50%'
      },
      "Data Processing Officer": {
        component: 'ManageHoldOnStockDialogComponent',
        width: '50%'
      },
      // "Store Officer": {
      //   component: 'ManageHoldOnStockDialogComponent',
      //   width: '50%'
      // }
    }
  };

  public StockOutletReturnStock = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'supplierCode', label: 'Outlet Name' },
        { key: 'supplierCodeOnly', label: 'Outlet Code' },
        { key: 'depotName', label: 'Depot Name' },
        { key: 'stockReceiptDateTime', label: 'Stock Return On', type: 'dateTime' },
        { key: 'unHoldOn', label: 'Stock Return No' },
        { key: 'draintoutOn', label: 'Location Name' },
        { key: 'receiptsCases', label: 'Quantity In Cases' },
        { key: 'receiptsBottles', label: 'Quantity In Bottles' },
        { key: 'view', label: 'Status', modal: { component: 'OutletReturnStockDialogComponent' }, icon: { type: 'pi pi-eye ml-2 text-success' } },
      ]
    },
    createRecord: {
      "Store Officer": {
        component: 'ManageOutletReturnStockComponent',
        width: '70%'
      }
    }
  };
  public StockVirtualStock = {
    columnsToDisplay: {
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandCode', label: 'Brand Code' },
        // { key: 'brandName', label: 'Brand Name' },
        { key: 'size', label: 'Size Code' },
        { key: 'unitsPerCase', label: 'Units Per Case' },
        { key: 'virtualCases', label: 'Stock in cases' },
        { key: 'virtualBottles', label: 'Stock in bottles' },
        { 
          key: 'status', label: 'Status', modal: { component: 'ManageVirtualStockDialogComponent', width: '70%' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer',
            style: 'font-size:12px;cursor:pointer;', success: ['Approved', 'Completed'], info: ['InProgress'], warning: ['Requested'], black: ['Reviewed'], yellow: ['Created']
          }
        },
      ]
    },
    createRecord: {
      "Data Processing Officer": {
        component: 'ManageVirtualStockDialogComponent',
        width: '70%'
      }
    }
  };

  public BeerBeerConsignment = {
    columnsToDisplay: {
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'asnId', label: 'ASN ID', type: 'link', modal: { component: 'ApnDetailsDialogComponent', width: '50%' } },
        { key: 'asnDate', label: 'ASN Date', type: 'date' },
        { key: 'breweriesName', label: 'Breweries Name' },
        {
          key: 'action', label: 'Action', actions: [
            { type: 'link', modal: { component: 'OpenTpDetailsDialogComponent', width: '50%' } },
            { type: 'link', modal: { component: 'OpenInvoiceDetailsDialogComponent', width: '75%' } },
            { type: 'link', modal: { component: 'OpenOfsDetailsDialogComponent', width: '50%' } },
          ]
        },
        { key: 'status', label: 'Status', icon: { success: ['Approved'], yellow: ['Created']} }
      ]
    },
    createRecord: {

    }
  };

  public DistilleryInfoLicences = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'licenseNo', label: 'Licence Number', path: "licencesInfo" },
        { key: 'licenceType', label: 'License Type', path: "licencesInfo" },
        { key: 'licenseProvider', label: 'Provider', path: "licencesInfo" },
        { key: 'purpose', label: 'Purpose', path: "licencesInfo" },
        { key: 'issuedDate', label: 'License Date', path: "licencesInfo", type: 'date' },
        { key: 'renewalDate', label: 'Renewal Date', path: "licencesInfo", type: 'date' },
        { key: 'status', label: 'Status', path: "licencesInfo" }
      ]
    },
    createRecord: {
      // "Distillery Officer": {
      //   component: 'ManageLicencesDialogComponent',
      //   width: '75%'
      // }
    }
  };

  public DistilleryInfoLicenceDetails = {
    columnsToDisplay: {
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'supplierCode', label: 'Supplier Code' },
        { key: 'licenceNumber', label: 'Licence Number' },
        { key: 'licenceUpdateDateTime', label: 'Licence Date', type: 'date' },
        { key: 'expiryDate', label: 'Expiry Date', type: 'date' },
      ]
    },
    createRecord: {
      "Distillery Manager": {
        component: 'ManageLicencesDialogComponent',
        width: '75%'
      }
    }
  };

  public DistilleryInfoSuppliers = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'supplierCode', label: 'Supplier Code' },
        { key: 'title', label: 'Title' },
        { key: 'companyName', label: 'Company Name' },
        { key: 'subleaseType', label: 'Sublease Type' },
        { key: 'licenseNo', label: 'License No' },
        { key: 'expiryDate', label: 'Expiry Date', type: 'date' },
      ]
    },
    createRecord: {}
  };

  public DistilleryInfoStaffUsers = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'userName', label: 'User Id' },
        { key: 'fullName', label: 'Full Name' },
        { key: 'number', label: 'Mobile Number', path: 'mobileNumbers' }, //mobileNumbers[0].number
        { key: 'emailId', label: 'Email', path: 'emailIds' }, //emailIds[0].mailId
        { key: 'roleName', label: 'Role Name' }
      ]
    },
    createRecord: {
     "Distillery Manager" :  {
      component: 'ManageStaffUsersDialogComponent',//  OpenSupplierDialogComponent
      width: '75%'
    }

    }
  };

  public DistilleryInfoTanks = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'tankName', label: 'Tank Name' },
        { key: 'tankType', label: 'Tank Type' },
        { key: 'capacity', label: 'Capacity' },
        { key: 'entityId', label: 'Used For Supplier' },
        { key: 'purpose', label: 'Content Type' },
        { key: 'status', label: 'Status',   icon: { style: '', success: ['Empty'], warning: ['InUse'], info: ['Maintenance'], danger: ['Damaged']} },              
        // {
        //   key: 'action', label: 'Action', action: 'edit', actions: [
        //     { icon: 'edit', modal: { component: 'ManageBlendTankDialogComponent', width: '50%' }, notEqRule: { key: 'status', value: 'InUse' }, },
        //   ]
        // }
      ]
    },
    createRecord: {
      // "Distillery Officer": {
      //   component: 'ManageBlendTankDialogComponent',
      //   width: '75%'
      // },
      "Distillery Manager": {
        component: 'ManageBlendTankDialogComponent',
        width: '75%'
      }
    }
  };

  public DistilleryInfoBottlingLines = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'lineName', label: 'Line Name' },
        { key: 'lineId', label: 'Line Id' },
        { key: 'port', label: 'Port' },
        { key: 'printerType', label: 'Printer Type' },
        { key: 'printerFileName', label: 'Printer File Name' },
        // { key: 'barcodePrinterName', label: 'Barcode Printer Name' },
        { key: 'status', label: 'Status', icon: { style: '', success: ['Idle'], warning: ['InUse'], info: ['Maintenance'], danger: ['Damaged']} },
        // {
        //   key: 'action', label: 'Action', action: 'edit', actions: [
        //     { icon: 'edit', modal: { component: 'ManageBottlingLineDialogComponent', width: '75%' }, notEqRule: { key: 'status', value: 'InUse' } },
        //   ]
        // }
      ]
    },
    createRecord: {
      // "Distillery Officer": {
      //   component: 'ManageBottlingLineDialogComponent',
      //   width: '75%'
      // },
      "Distillery Manager": {
        component: 'ManageBottlingLineDialogComponent',
        width: '50%'
      }
    }
  };

  public DistilleryInfoGateRegister = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'driverName', label: 'Driver Name' },
        { key: 'driverNo', label: 'Driver Number' },
        { key: 'vehicleNumber', label: 'Vehicle Number' },
        { key: 'purpose', label: 'Purpose' },
        { key: 'inTime', label: 'In Time' },
        { key: 'outTime', label: 'Out Time' }
      ]
    },
    createRecord: {
      "Distillery Officer": {
        component: 'ManageGateRegisterDialogComponent',
        width: '50%'
      }
    }
  };

  public DistilleryInfoInsuranceDetails = {
    columnsToDisplay: {
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'supplierCode', label: 'Supplier Code' },
        { key: 'insuranceNumber', label: 'Insurance Number' },
        { key: 'insuranceDate', label: 'Insurance Date', type: 'date' },
        { key: 'expiryDate', label: 'Expiry Date', type: 'date' },
      ]
    },
    createRecord: {
      "Distillery Manager": {
        component: 'InsuranceDetailsComponent',
        width: '50%'
      }
    }
  };

  //  public SuppliersSupplierDetails = {
  //   columnsToDisplay: {
  //       "Distillery Officer": [
  //           { key: 'no', label: 'S.No.' },
  //           { key: 'supplierCode', label: 'Supplier Code' },
  //           { key: 'title', label: 'Title' },
  //           { key: 'companyName', label: 'Company Name' },
  //           { key: 'subleaseType', label: 'Sublease Type' },
  //           { key: 'licenseNo', label: 'License No' },
  //           { key: 'expiryDate', label: 'Expiry Date' },
  //       ]
  //   },
  //   createRecord: {}
  //  };

  public UsersAPSBCL = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'userName', label: 'User ID' },
        { key: 'fullName', label: 'First Name' },
        { key: 'mailId', label: 'Email ID' },
        { key: 'mobileNumber', label: 'Mobile Number' },
        { key: 'locationType', label: 'Location Type' },
        { key: 'designation', label: 'Role' },
        { key: 'locationName', label: 'Location Name' }
      ]
    },
    createRecord: {
      // "Material Management": {
      //   component: 'OpenApbclDialogComponent',
      //   width: '75%'
      // }
    }
  };

  public UsersExcise = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'userName', label: 'User ID' },
        { key: 'fullName', label: 'Full Name' },
        { key: 'mailId', label: 'Email ID' },
        { key: 'mobileNumber', label: 'Mobile Number' },
        { key: 'entityId', label: 'Location Type' },
        { key: 'designation', label: 'Role' }
      ]
    },
    createRecord: {     
    }
  };

  public UsersSupplier = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'userName', label: 'User ID' },
        { key: 'fullName', label: 'Full Name' },
        { key: 'mailId', label: 'Email ID' },
        { key: 'mobileNumber', label: 'Mobile Number' },
        { key: 'entityId', label: 'Location Type' },
        { key: 'designation', label: 'Role' },
        { key: 'entityType', label: 'Location Name' }
      ]
    },
    createRecord: {
      "Material Management": {
        component: 'OpenSupplierDialogComponent',
        width: '75%'
      },
      // "Excise Lab": {
      //   component: 'OpenSupplierDialogComponent',
      //   width: '75%'
      // }
    }
  };

  public UsersRetailers = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'userName', label: 'User ID' },
        { key: 'firstName', label: 'First Name' },
        { key: 'mailId', label: 'Email ID' },
        { key: 'mobileNumber', label: 'Mobile Number' },
        { key: 'locationType', label: 'Location Type' },
        { key: 'designation', label: 'Role' },
        { key: 'locationName', label: 'Location Name' }
      ]
    },
    createRecord: {}
  };

  public UsersDepot = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'userName', label: 'User ID' },
        { key: 'fullName', label: 'Name' },
        { key: 'mailId', label: 'Email ID' },
        { key: 'mobileNumber', label: 'Mobile Number' },
        { key: 'designation', label: 'Role' },
        { key: 'entityId', label: 'Depot Name' }
      ]
    },
    createRecord: {
      "Material Management": {
        component: 'OpenDepotDialogComponent',
        width: '75%'
      }
    }
  };

  public UsersVendors = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'userName', label: 'User ID' },
        { key: 'fullName', label: 'Name' },
        { key: 'mailId', label: 'Email ID' },
        { key: 'mobileNumber', label: 'Mobile Number' },
        { key: 'designation', label: 'Role' },
        { key: 'entityId', label: 'Vendor Name' }
      ]
    },
    createRecord: {
      // "Material Management": {
      //   component: 'OpenVendorDialogComponent',
      //   width: '75%'
      // }
    }
  };

  public LocationLocations = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        // { key: 'select', label: '' },
        { key: 'locationCode', label: 'Location Code' },
        { key: 'supplierName', label: 'Name' },
        { key: 'licenceType', label: 'Location Type' },
        { key: 'address', label: 'Address', path: "officeAddresses" }, // distilleryAddress[0]['address']
        { key: 'town/village', label: 'City', path: "officeAddresses" }, // row.distilleryAddress[0]['town/village']
       // { key: 'revenue_district', label: 'District', path: "distilleryAddress" }, // row.distilleryAddress[0]['revenue_district']
        // {
        //   key: 'action', label: 'Action', action: 'edit', actions: [
        //     { icon: 'edit', modal: { component: 'ManageLocationDialogComponent', width: '75%' } },
        //   ]
        // }
      ],
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        // { key: 'select', label: '' },
        { key: 'locationCode', label: 'Location Code' },
        { key: 'supplierName', label: 'Name' },
        { key: 'licenceType', label: 'Location Type' },
        { key: 'address', label: 'Address', path: "officeAddresses" }, // distilleryAddress[0]['address']
        { key: 'town/village', label: 'City', path: "officeAddresses" }, // row.distilleryAddress[0]['town/village']
       // { key: 'revenue_district', label: 'District', path: "distilleryAddress" }, // row.distilleryAddress[0]['revenue_district']
        {
          key: 'action', label: 'Action', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'ManageLocationDialogComponent', width: '75%' } },
          ]
        }
      ]
    },
    createRecord: {
      "Material Management": {
        component: 'ManageLocationDialogComponent',
        width: '75%'
      }
    }
  }

  public LocationSuppliers = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'category', label:'Category'},
        { key: 'supplierCode', label: 'Supplier Code' },
        { key: 'supplierName', label: 'Name' },
        { key: 'distilleryUnit', label: 'Distillery' },
        { key: 'supplierType', label: 'Supplier Type' },
        { key: 'address', label: 'Address', path: "officeAddresses" },
        { key: 'town/village', label: 'City', path: "officeAddresses" },
        {
          key: 'action', label: 'Action', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'ManageLocationDialogComponent', width: '75%' } },
          ]
        }
      ]
    },
    createRecord: {
      "Material Management": {
        component: 'ManageLocationDialogComponent',
        width: '75%'
      }
    }
  }

  public LocationPrimaryDistilleries = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'primaryDistilleriesCode', label: 'Primary Distilleries Code' },
        { key: 'primaryDistilleriesName', label: 'Name' },
        { key: 'currentAddress', label: 'Address' },
        { key: 'town/village', label: 'City' },
      ]
    },
    createRecord: {}
  }

  public LocationVendors = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'vendorCode', label: 'Vendor Code' },
        { key: 'vendorName', label: 'Vendor Name' },
        { key: 'currentAddress', label: 'Address' },
        { key: 'phoneNumber', label: 'Phone Number' },
        { key: 'town/village', label: 'City' },
        // {
        //   key: 'action', label: 'Action', action: 'edit', actions: [
        //     { icon: 'edit', modal: { component: 'ManageVendorsDialogComponent', width: '75%' } },
        //   ]
        // }
      ]
    },
    createRecord: {
      "Material Management": {
        component: 'ManageVendorsDialogComponent',
        width: '75%'
      }
    }
  }

  public LocationDepots = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'depotCode', label: 'Depot Code' },
        { key: 'depotName', label: 'Depot Name' },
        { key: 'currentAddress', label: 'Address' },
        { key: 'town/village', label: 'City' },
        // {
        //   key: 'action', label: 'Action', action: 'edit', actions: [
        //     { icon: 'edit', modal: { component: 'ManageDepotsDialogComponent', width: '75%' } },
        //   ]
        // }
      ]
    },
    createRecord: {
      "Material Management": {
        component: 'ManageDepotsDialogComponent',
        width: '75%'
      }
    }
  }

  public LocationCSD = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'csdCode', label: 'CSD Code' },
        { key: 'csdName', label: 'CSD Name' },
        { key: 'phoneNumber', label: 'Phone Number' },
        { key: 'currentAddress', label: 'Address' },
        { key: 'town/village', label: 'City' },
        // {
        //   key: 'action', label: 'Action', action: 'edit', actions: [
        //     { icon: 'edit', modal: { component: 'ManageLocationCsdDialogComponent', width: '75%' } },
        //   ]
        // }
      ]
    },
    createRecord: {
      "Material Management": {
        component: 'ManageLocationCsdDialogComponent',
        width: '75%'
      }
    }
  }

  public LocationLabs = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'labName', label: 'Lab Name' },
        { key: 'phoneNumber', label: 'Phone Number' },
        { key: 'currentAddress', label: 'Address' },
        { key: 'town/village', label: 'City' },
        // {
        //   key: 'action', label: 'Action', action: 'edit', actions: [
        //     { icon: 'edit', modal: { component: 'ManageLabsDialogComponent', width: '75%' } },
        //   ]
        // }
      ]
    },
    createRecord: {
      "Material Management": {
        component: 'ManageLabsDialogComponent',
        width: '75%'
      }
    }
  }

  public DistilleryProducts = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'updatedDateTime', label: 'Date', type: 'dateTime' },
        { key: 'sizeCode', label: 'Size Code' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'brandCode', label: 'Brand Code' },
        { key: 'location', label: 'Location' },
        { key: 'receipts', label: 'Production' }
      ]
    },
    createRecord: {}
  };

  public DistilleryStock = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'brandCode', label: 'Brand Name' },
        { key: 'sizeCode', label: 'Size Code' },
        { key: 'supplierCode', label: 'Supplier Code' },
        { key: 'openingBal', label: 'Opening Stock' },
        { key: 'receipts', label: 'Production Quantity' },
        { key: 'dispatches', label: 'Total Sales' },
        { key: 'closingBal', label: 'Closing Stock' }
      ]
    },
    createRecord: {}
  };

  public DistilleryBeerStock = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'brandCodeOnly', label: 'Brand Code' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'sizeCode', label: 'Size Code' },
        { key: 'supplierCode', label: 'Brewery' },
        { key: 'openingBal', label: 'Opening Stock' },
        { key: 'receipts', label: 'Production Quantity' },
        { key: 'dispatches', label: 'Total Sales' },
        { key: 'closingBal', label: 'Closing Stock' }
      ]
    },
    createRecord: {}
  };

  public BeerBeerStock = {
    columnsToDisplay: {
      "Store Officer": [
        { key: 'productCode', label: 'Product Code', type: 'link', modal: { component: 'BeerProductCodeDialogComponent', width: '45%' } },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'brandCode', label: 'Brand Code' },
        { key: 'size', label: 'Size in ml' },
        { key: 'unitsPerCases', label: 'Units Per Cases' },
        { key: 'category', label: 'Category' },
        { key: 'openingBalCases', label: 'Opening Stock(cases)' },
        { key: 'openingBalBottles', label: 'Opening Stock in Prt Bottles' },
        { key: 'inwardStock', label: 'Inward Stock (Cases)' },
        { key: 'inwardStockBottles', label: 'Inward Stock in Prt Bottles' },
        { key: 'saleInCases', label: 'Sale in Cases' },
        { key: 'saleInBottles', label: 'Sale in Prt Bottles' },
        { key: 'transferedInCases', label: 'Transfered (Cases)' },
        { key: 'transferedInPrtBottles', label: 'Transfered in Prt Bottles' },
        { key: 'transferedIdtInPrtBottles', label: 'Transfered IDT in Prt Bottles' },
        { key: 'transferedInPrtCases', label: 'Transfered in Prt Cases' },
        { key: 'outsideSalesInBottles', label: 'Outside Sales in Bottles)' },
        { key: 'OutletReturnStockCases', label: 'Outlet Return Stock(Cases)' },
        { key: 'closingBalCases', label: 'Closing Stock(Cases)' },
        { key: 'closingBalBottles', label: 'Closing Stock(Bottles)' },
        { key: 'DamageQtyCases', label: 'Damage Qty(Cases)' },
        { key: 'DamageQtyBottles', label: 'Damage Qty(Bottles)' },
        { key: 'shortageCases', label: 'Shortage(Cases)' },
        { key: 'sampleToCe', label: 'Sample To CE(part)' },
        { key: 'StockOnHoldCases', label: 'Stock on Hold Qty(Cases)' },
        { key: 'drainOutStockCases', label: 'Drain Out Stock(Cases)' },
        { key: 'ReleasedStockCases', label: 'Released Stock(Cases)' },
        { key: 'StockReturnToSupplierCases', label: 'Stock Return to Supplier Stock(Cases)' },
        { key: 'supplierDrainOutCases', label: 'SupplierDrainOut(Cases)' },
        { key: 'updatedDate', label: 'Date', type: 'date' },
      ]
    },
    createRecord: {}
  };



  public DistilleryOFS = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'ofsCode', label: 'OFS Number' },
        { key: 'distillery', label: 'Distillery' },
        { key: 'consignmentType', label: 'Consignment Type' },
        { key: 'poNo', label: 'PO Number', path: 'purchaseOrderDetails' },
        { key: 'depotCode', label: 'Depot' },
        { key: 'updatedDateTime', label: 'Date', type: 'dateTime' },
        { key: 'status', label: 'Status' , icon: { success: ['Approved'], warning: ['In-Active']} },
      ]
    },
    createRecord: {}
  };

  public DistilleryRoute = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'date', label: 'Date', type: 'date' },
        { key: 'routeName', label: 'Name' },
        { key: 'routeVia', label: 'Route' },
        { key: 'sourceLocation', label: 'PO Number' },
        { key: 'destinationLocation', label: 'Destination Location' }
      ]
    },
    createRecord: {}
  };

  public DepotStock = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'depotCode', label: 'Location' },
        { key: 'openingStock', label: 'Opening Stock' },
        { key: 'inwardStock', label: 'Inward Stock' },
        { key: 'sales', label: 'Sales' },
        { key: 'outletReturnStock', label: 'Outlet Return Stock' },
        { key: 'closingBalBottles', label: 'ClosingStock' }
      ]
    },
    createRecord: {}
  };

  public DepotBreakageVerification = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'depot', label: 'Depot' },
        { key: 'breakageVerficationID', label: 'Breakage Verification ID' },
        { key: 'verficationDate', label: 'Verification Date', type: 'date' },
        { key: 'breakageCount', label: 'Breakage Count' }
      ]
    },
    createRecord: {}
  };

  public DepotChallan = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'depotname', label: 'Depot Name' },
        { key: 'depotCode', label: 'Depot Code' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'retailerLocId', label: 'Retailer Local Id' },
        { key: 'tXNDate', label: 'TXN Date', type: 'date' },
        { key: 'challanNumber', label: 'Challan Number' },
        { key: 'challanAmount', label: 'Challan Amount' },
        { key: 'sync', label: 'Sync' },
        { key: 'verfiedBy', label: 'Verfied By' }
      ]
    },
    createRecord: {}
  };

  public DepotHoldonStock = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'depotname', label: 'Depot Name' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'productName', label: 'Product Name' },
        { key: 'batchNo', label: 'Batch Number' },
        { key: 'qtyCasesBottles', label: 'Quantity(Cases/Bottles)' },
        { key: 'holdOn', label: 'Hold On' },
        { key: 'unholdOn', label: 'Unhold On' },
        { key: 'drainoutOn', label: 'Drainout On' },
        { key: 'approvedOn', label: 'Approved On' },
        {
          key: 'status', label: 'Status', modal: { component: '' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;',
            success: ['GREEN'], danger: ['RED']
          }
        }
      ]
    },
    createRecord: {}
  };

  public LicenseeProfilesShops = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'assignedDepot', label: 'Depot Name' },
        { key: 'gslNo', label: 'Gazette Code' },
        { key: 'supervisorName', label: 'Supervisor' },
        { key: 'issueDate', label: 'Issue Date', type: 'date' },
        { key: 'updatedDateTime', label: 'Validate Date', type: 'date' },
        { key: 'userId', label: 'User ID' },
        { key: 'mobileNo', label: 'Mobile No' },
        { key: 'emailId', label: 'Email Id' },
        { key: 'target', label: 'Target', modal: { component: 'OpenTargetDialogComponent' } },
        {
          key: 'view', label: 'View', headerClass: 'p-4', action: 'view', actions: [
            { icon: 'groups', modal: { component: 'OpenCustomerDetailsDialogComponent', width: '70%' } },
            { icon: 'picture_as_pdf', component: '' },
            { icon: 'account_balance', modal: { component: 'OpenBankDetailsDialogComponent', width: '70%' } }
          ]
        }
      ]
    },
    createRecord: {}
  };

  public LicenseeProfilesBars = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'assignedDepot', label: 'Depot Name' },
        { key: 'gslNo', label: 'Gazette Code' },
        { key: 'supervisorName', label: 'Supervisor' },
        { key: 'issueDate', label: 'Issue Date', type: 'date' },
        { key: 'updatedDateTime', label: 'Validate Date', type: 'date' },
        { key: 'vendorId', label: 'User ID' },
        { key: 'mobileNo', label: 'Mobile No' },
        { key: 'emailId', label: 'Email Id' },
        {
          key: 'view', label: 'View', headerClass: 'p-4', action: 'view', actions: [
            { icon: 'groups', modal: { component: 'OpenBarsCustomerDetailsDialogComponent', width: '70%' } },
            // { icon: 'picture_as_pdf', component: '' },
            { icon: 'account_balance', modal: { component: 'OpenBarsBankDetailsDialogComponent', width: '70%' } }
          ]
        }
      ]
    },
    createRecord: {}
  };

  public LicenseeProfilesTwoT = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'depotName', label: 'Depot Name' },
        { key: 'gazetterCode', label: 'Gazette Code' },
        { key: 'supervisor', label: 'Supervisor' },
        { key: 'issueDate', label: 'Issue Date', type: 'date' },
        { key: 'validateDate', label: 'Validate Date', type: 'date' },
        { key: 'userId', label: 'User ID' },
        { key: 'mobileNo', label: 'Mobile No' },
        { key: 'emailId', label: 'Email Id' },
        {
          key: 'view', label: 'View', headerClass: 'p-4', action: 'view', actions: [
            { icon: 'groups', modal: { component: 'OpenTwotCustomerDetailsDialogComponent', width: '50%' } },
            { icon: 'picture_as_pdf', component: '' },
            { icon: 'account_balance', modal: { component: 'OpenTwotBankDetailsDialogComponent', width: '50%' } }
          ]
        }
      ]
    },
    createRecord: {}
  };

  public LicenseeProfilesTwoF = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'depotName', label: 'Depot Name' },
        { key: 'gazetterCode', label: 'Gazette Code' },
        { key: 'supervisor', label: 'Supervisor' },
        { key: 'issueDate', label: 'Issue Date', type: 'date' },
        { key: 'validateDate', label: 'Validate Date', type: 'date' },
        { key: 'userId', label: 'User ID' },
        { key: 'mobileNo', label: 'Mobile No' },
        { key: 'emailId', label: 'Email Id' },
        {
          key: 'view', label: 'View', headerClass: 'p-4', action: 'view', actions: [
            { icon: 'groups', modal: { component: 'OpenTwotCustomerDetailsDialogComponent', width: '50%' } },
            { icon: 'picture_as_pdf', component: '' },
            { icon: 'account_balance', modal: { component: 'OpenTwotBankDetailsDialogComponent', width: '50%' } }
          ]
        }
      ]
    },
    createRecord: {}
  };

  public LicenseeProfilesClubs = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'depotName', label: 'Depot Name' },
        { key: 'gazetterCode', label: 'Gazette Code' },
        { key: 'supervisor', label: 'Supervisor' },
        { key: 'issueDate', label: 'Issue Date', type: 'date' },
        { key: 'validateDate', label: 'Validate Date', type: 'date' },
        { key: 'userId', label: 'User ID' },
        { key: 'mobileNo', label: 'Mobile No' },
        { key: 'emailId', label: 'Email Id' },
        {
          key: 'view', label: 'View', headerClass: 'p-4', action: 'view', actions: [
            { icon: 'groups', modal: { component: 'OpenClubsCustomerDetailsDialogComponent', width: '70%' } },
            { icon: 'picture_as_pdf', component: '' },
            { icon: 'account_balance', modal: { component: 'OpenClubsBankDetailsDialogComponent', width: '70%' } }
          ]
        }
      ]
    },
    createRecord: {}
  };

  public LicenseeProfilesTD1 = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'depotName', label: 'Depot Name' },
        { key: 'gazetterCode', label: 'Gazette Code' },
        { key: 'supervisor', label: 'Supervisor' },
        { key: 'issueDate', label: 'Issue Date', type: 'date' },
        { key: 'validateDate', label: 'Validate Date', type: 'date' },
        { key: 'userId', label: 'User ID' },
        { key: 'mobileNo', label: 'Mobile No' },
        { key: 'emailId', label: 'Email Id' },
        {
          key: 'view', label: 'View', headerClass: 'p-4', action: 'view', actions: [
            { icon: 'groups', modal: { component: 'OpenTd1CustomerDetailsDialogComponent', width: '50%' } },
            { icon: 'picture_as_pdf', component: '' },
            { icon: 'account_balance', modal: { component: 'OpenTd1BankDetailsDialogComponent', width: '50%' } }
          ]
        }
      ]
    },
    createRecord: {}
  };

  public LicenseeProfilesEP1 = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'permitNumber', label: 'Permit Number' },
        { key: 'issueDateTime', label: 'Issue Date & Time', type: 'dateTime' },
        { key: 'permitFees', label: 'Permit Fees' },
        { key: 'eventAddress', label: 'Event Address' },
        { key: 'authorizedName', label: 'Authorized Name' },
        {
          key: 'status', label: 'Status', modal: { component: '' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;',
            success: ['GREEN'], danger: ['RED']
          }
        },
        { key: 'print', label: 'Print/Pdf' },
        { key: 'details', label: 'Details' }
      ]
    },
    createRecord: {}
  };

  public LicenseeProfilesElite = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'depotName', label: 'Depot Name' },
        { key: 'gazetterCode', label: 'Gazette Code' },
        { key: 'supervisor', label: 'Supervisor' },
        { key: 'issueDate', label: 'Issue Date', type: 'date' },
        { key: 'validateDate', label: 'Validate Date', type: 'date' },
        { key: 'userId', label: 'User ID' },
        { key: 'mobileNo', label: 'Mobile No' },
        { key: 'emailId', label: 'Email Id' },
        { key: 'view', label: 'View' }
      ]
    },
    createRecord: {}
  };

  public LicenseeProfilesLicenseeStatus = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'retailerType', label: 'Retailer Type' },
        { key: 'licenseName', label: 'License Name' },
        {
          key: 'status', label: 'Status', modal: { component: '' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;',
            success: ['GREEN'], danger: ['RED']
          }
        }
      ]
    },
    createRecord: {}
  };

  public LicenseeProfilesSuspendedRetailers = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'depotName', label: 'Depot Name' },
        { key: 'gazetterCode', label: 'Gazette Code' },
        { key: 'supervisor', label: 'Supervisor' },
        { key: 'issueDate', label: 'Issue Date', type: 'date' },
        { key: 'validateDate', label: 'Validate Date', type: 'date' },
        { key: 'userId', label: 'User ID' },
        { key: 'mobileNo', label: 'Mobile No' },
        { key: 'emailId', label: 'Email Id' },
        { key: 'suspendedBy', label: 'Suspended By' },
        { key: 'reason', label: 'Reason' },
        { key: 'view', label: 'View' }
      ]
    },
    createRecord: {}
  };

  public LicenseeProfilesTemporaryRetailers = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'vendorName', label: 'Vendor Name' },
        { key: 'vendorUniqueCode', label: 'Vendor Unique Code' },
        { key: 'sourceDepotName', label: 'Source Depot Name' },
        { key: 'destinationDepotName', label: 'Destination Depot Name' },
        { key: 'fromDate', label: 'Form Date', type: 'date' },
        { key: 'toDate', label: 'To Date', type: 'date' },
        { key: 'reason', label: 'Reason' },
        { key: 'status', label: 'Status' },
        { key: 'action', label: 'Action' }
      ]
    },
    createRecord: {}
  };

  public LicenseeProfilesExpiredLicensees = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'depotName', label: 'Depot Name' },
        { key: 'gazetterCode', label: 'Gazette Code' },
        { key: 'supervisor', label: 'Supervisor' },
        { key: 'issueDate', label: 'Issue Date', type: 'date' },
        { key: 'validateDate', label: 'Validate Date', type: 'date' },
        { key: 'userId', label: 'User ID' },
        { key: 'mobileNo', label: 'Mobile No' },
        { key: 'emailId', label: 'Email Id' }
      ]
    },
    createRecord: {}
  };

  public LicenseeSalesShopSales = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'districtName', label: 'District Name' },
        { key: 'imlSale', label: 'IML Sale' },
        { key: 'imlStock', label: 'IML Stock' },
        { key: 'beerSale', label: 'Beer Sale' },
        { key: 'beerStock', label: 'Beer Stock' }
      ]
    },
    createRecord: {}
  };

  public LicenseeSalesBarSales = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'districtName', label: 'District Name' },
        { key: 'imlSale', label: 'IML Sale' },
        { key: 'imlStock', label: 'IML Stock' },
        { key: 'beerSale', label: 'Beer Sale' },
        { key: 'beerStock', label: 'Beer Stock' }
      ]
    },
    createRecord: {}
  };

  public LicenseeSalesClubSales = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'districtName', label: 'District Name' },
        { key: 'imlSale', label: 'IML Sale' },
        { key: 'imlStock', label: 'IML Stock' },
        { key: 'beerSale', label: 'Beer Sale' },
        { key: 'beerStock', label: 'Beer Stock' }
      ]
    },
    createRecord: {}
  };

  public LicenseeSalesTD1Sales = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'districtName', label: 'District Name' },
        { key: 'imlSale', label: 'IML Sale' },
        { key: 'imlStock', label: 'IML Stock' },
        { key: 'beerSale', label: 'Beer Sale' },
        { key: 'beerStock', label: 'Beer Stock' }
      ]
    },
    createRecord: {}
  };

  public LicenseeSalesTD2Sales = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'districtName', label: 'District Name' },
        { key: 'imlSale', label: 'IML Sale' },
        { key: 'imlStock', label: 'IML Stock' },
        { key: 'beerSale', label: 'Beer Sale' },
        { key: 'beerStock', label: 'Beer Stock' }
      ]
    },
    createRecord: {}
  };

  public RationingRationing = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'licenseeType', label: 'Licensee Type' },
        { key: 'size', label: 'SizeCode' },
        { key: 'packType', label: 'Pack Type' },
        { key: 'ration', label: 'Ration Quantity' },
        { key: 'applicableFrom', label: 'From', type: 'date'},
        { key: 'applicableTo', label: 'To', type: 'date'},
        { key: 'action', label: 'Action', type: 'link', value: 'Edit', modal: { component: 'AddRationingDialogComponent', width: '60%' }, 
        //  icon: { info:['Edit'] } 
      },
        // { key: 'history', label: 'History', modal: { component: 'ShowRationHistoryDialogComponent', width: '50%' }, icon: { type: 'pi pi-eye ml-2', style: 'color: green' } }
      ]
    },
    createRecord: {
      "Depot Manager": {
        component: 'AddRationingDialogComponent',
        width: '75%'
      },
      "Data Processing Officer":{
        component: 'AddRationingDialogComponent',
        width: '75%'
      },
      "Excise Superintendent Officer": {
        component: 'AddRationingDialogComponent',
        width: '75%'
      }

    }
  };

  public BCLOutletOutletProfiles = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'name', label: 'Oulet Names' },
        { key: 'imlSale', label: 'Oulet Code' },
        { key: 'imlStock', label: 'gazette Stock' },
        { key: 'supervisorName', label: 'Supervisor' },
        { key: 'operationStartDate', label: 'Issue Date', type: 'date' },
        { key: 'validateName', label: 'Validate Date' },
        { key: 'userID', label: 'User ID' },
        { key: 'mobileNumber', label: 'Mobile Number', subKey: "address" },
        { key: 'mailId', label: 'Email ID', subKey: "address" },
        { key: 'view', label: 'View' }
      ]
    },
    createRecord: {}
  };

  public BCLOutletOutletSales = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'name', label: 'Retailer Names', modal: { component: 'OpenRetailerNamesDialogComponent' } },
        { key: 'imlSale', label: 'IML Sale' },
        { key: 'imlStock', label: 'IML Stock' },
        { key: 'beerSale', label: 'Beer Sale' },
        { key: 'beerStock', label: 'Beer Stock' }
      ]
    },
    createRecord: {}
  };

  public BCLOutletOutletReturns = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'tpId', label: 'TP ID' },
        { key: 'outletName', label: 'Oulet Name' },
        { key: 'depotName', label: 'Depot Name' },
        { key: 'returnDate', label: 'Return Date', type: 'date' },
        { key: 'status', label: 'Status' }
      ]
    },
    createRecord: {}
  };

  public BCLOutletOutletStatus = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'name', label: 'Retailer Names' },
        { key: 'imlSale', label: 'IML Sale' },
        { key: 'imlStock', label: 'IML Stock' },
        { key: 'beerSale', label: 'Beer Sale' },
        { key: 'beerStock', label: 'Beer Stock' },
        { key: 'saleDate', label: 'Sale Date', type: 'date' }
      ]
    },
    createRecord: {}
  };

  public BCLOutletOutlets = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'name', label: 'Licensee Name' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'locationType', label: 'Location Name' },
        { key: 'retailerId', label: 'Retailer Code' },
        { key: 'gazetterCode', label: 'Gazette Code' },
        { key: 'view', label: 'View' }
      ]
    },
    createRecord: {
      "Excise Superintendent Officer": {
        component: 'AddRationingDialogComponent',
        width: '75%'
      }
    }
  };

  public BCLOutletIndent = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'supervisorName', label: 'Supervisor Name' },
        { key: 'outletName', label: 'Outlet Name' },
        { key: 'location', label: 'Location' },
        { key: 'outletCode', label: 'Outlet Code' },
        { key: 'gazetterCode', label: 'Gazette Code' },
        { key: 'view', label: 'View' },
        { key: 'indent', label: 'Indent' }
      ]
    },
    createRecord: {
      "Depot Manager": {
        component: 'OpenIndentsDialogComponent',
        width: '50%'
      },
      "Excise Superintendent Officer": {
        component: 'OpenIndentsDialogComponent',
        width: '50%'
      },
      "Data Processing Officer": {
        component: 'OpenIndentsDialogComponent',
        width: '50%'
      },
      "Store Officer": {
        component: 'OpenIndentsDialogComponent',
        width: '50%'
      }
    }
  };

  public BCLOutletStockReturn = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'tpId', label: 'TP Id' },
        { key: 'outletName', label: 'Outlet Name' },
        { key: 'outletCode', label: 'Outlet Code' },
        { key: 'depotName', label: 'Depot Name' },
        { key: 'returnDate', label: 'Return Date', type: 'date' },
        { key: 'status', label: 'Status' }
      ]
    },
    createRecord: {}
  };

  public BCLOutletConsolidatedDCs = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'tpId', label: 'Date' },
        { key: 'outletCode', label: 'Outlet Code' },
        { key: 'dcNumber', label: 'DC Number' },
        { key: 'tpBarcode', label: 'TP Barcode' },
        { key: 'sourceLocation', label: 'Source Location' },
        { key: 'destinationName', label: 'Destination Name' },
        { key: 'amount', label: 'Amount(Rs./Ps.)' }
      ]
    },
    createRecord: {}
  };

  public BCLOutletCancelDCs = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'dcNo', label: 'DC Number' },
        { key: 'registrationNo', label: 'Registration Number' },
        { key: 'outletName', label: 'Outlet Name' },
        { key: 'outletCode', label: 'Outlet Code' },
        { key: 'dateOfCancellation', label: 'Date Of Cancellation', type: 'date' },
        { key: 'status', label: 'Status' },
      ]
    },
    createRecord: {}
  };

  public BCLOutletInvoices = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'tpId', label: 'Date' },
        { key: 'outletCode', label: 'Outlet Code' },
        { key: 'outletName', label: 'Outlet Name' },
        { key: 'challanNo', label: 'Challan Number' },
        { key: 'icdcCode', label: 'ICDC Number' },
        { key: 'dcDetails', label: 'DC Details' },
      ]
    },
    createRecord: {}
  };

  public BCLOutletOutletPendingStock = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'tpId', label: 'Outlet Code' },
        { key: 'outletName', label: 'Retailer Name' }
      ]
    },
    createRecord: {}
  };

  public BCLOutletManualStockReturnReport = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'retunDate', label: 'Returned Date' },
        { key: 'outletName', label: 'Outlet Code' },
        { key: 'tpId', label: 'Outlet Name' },
        { key: 'returnedStock', label: 'Returned Stock (Cases/Bottles)' }
      ]
    },
    createRecord: {}
  };

  public HologramsDistillery = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'entityId', label: 'Supplier Name' },
        { key: 'distilleryUnit', label: 'Distillery Name' },
        { key: 'requestedQuantity', label: 'Quantity' },
        { key: 'requestedOn', label: 'Requested Date', type: 'date' },
        { key: 'healIndentId', label: 'Indent Number' },
        { key: 'challanId', label: 'Challan No' },
        { key: 'challanAmount', label: 'Amount' },
        { key: 'purpose', label: 'Purpose' },
        { key: 'colorLabel', label: 'Color', type: 'color' },
        { key: 'status', label: 'Status', modal: { component: 'DistilleryHologramStatusDetailsDialogComponent', width: '70%' }, icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;', success: ['Received', 'Completed'], info: ['InProgress'], brown: ['Dispatched'], warning: ['Approved', 'Requested'], black: ['Reviewed'] } }
      ],
      "Excise Heals Inspector": [
        { key: 'no', label: 'S.No.' },
        { key: 'entityId', label: 'Supplier Name' },
        { key: 'requestedQuantity', label: 'Quantity' },
        { key: 'requestedOn', label: 'Requested Date', type: 'date' },
        { key: 'healIndentId', label: 'Indent Number' },
        { key: 'challanId', label: 'Challan No' },
        { key: 'challanAmount', label: 'Amount' },
        { key: 'spools', label: 'Spools', value: 'Spools', type: 'link', modal: { component: 'HologramSpoolsDetailsDialogComponent', width: '70%', tab: 'exeHeals'} },
        { key: 'cartons', label: 'Carton Code', value: 'Carton', type: 'link', modal: { component: 'HologramSpoolsDetailsDialogComponent', width: '70%', tab: 'exeHeals' } },
        { key: 'purpose', label: 'Purpose' },
        { key: 'colorLabel', label: 'Color', type: 'color' },
        { key: 'status', label: 'Status', modal: { component: 'DistilleryHologramStatusDetailsDialogComponent', width: '70%' }, icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;', success: ['Received', 'Completed'], info: ['InProgress'], brown: ['Dispatched'], warning: ['Approved', 'Requested'], black: ['Reviewed'] } }
      ],
      "AS Labels": [
        { key: 'no', label: 'S.No.' },
        { key: 'entityId', label: 'Supplier Name' },
        { key: 'distilleryUnit', label: 'Distillery Name' },
        { key: 'requestedQuantity', label: 'Quantity' },
        { key: 'requestedOn', label: 'Requested Date', type: 'date' },
        { key: 'healIndentId', label: 'Indent Number' },
        { key: 'challanId', label: 'Challan No',style: 'color:red;', type: 'link', modal: { component: 'GetChallanDetailsComponent', width: '50%' } },
        { key: 'challanAmount', label: 'Amount' },
        { key: 'purpose', label: 'Purpose' },
        { key: 'colorLabel', label: 'Color', type: 'color' },
        { key: 'status', label: 'Status', modal: { component: 'DistilleryHologramStatusDetailsDialogComponent', width: '70%' }, icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;', success: ['Received', 'Completed'], info: ['InProgress'], brown: ['Dispatched'], warning: ['Approved', 'Requested'], black: ['Reviewed'] } }
      ],
    },
    createRecord: {}
  };

  public HologramsDepot = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'depot', label: 'Depot' },
        { key: 'requestedQuantity', label: 'Quantity'},
        { key: 'requestedOn', label: 'Requested Date', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'OpenDepotStatusDialogComponent', width: '60%'}, icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;text-align:center;', success: ['Received', 'Completed'], warning: ['Approved'], black: ['Reviewed'], brown: ['Dispatched'], yellow:['Requested'], info:['InProgress']} }
      ],
      "Excise Heals Inspector": [
        { key: 'no', label: 'S.No.' },
        { key: 'depot', label: 'Depot' },
        { key: 'requestedQuantity', label: 'Quantity'},
        { key: 'requestedOn', label: 'Requested Date', type: 'date' },
        { key: 'firstHealCode', label: 'Start Heal Code' },
        { key: 'lastHealCode', label: 'End Heal Code' },
        { key: 'spools', label: 'Spools', value: 'Spools', type: 'link', modal: { component: 'HologramSpoolsDetailsDialogComponent', width: '70%', tab: 'exeHeals' } },
        { key: 'cartons', label: 'Carton Code', value: 'Carton', type: 'link', modal: { component: 'HologramSpoolsDetailsDialogComponent', width: '70%', tab: 'exeHeals' } },
        { key: 'purpose', label: 'Purpose' },
        { key: 'colorLabel', label: 'Color', type: 'color' },
        { key: 'status', label: 'Status', modal: { component: 'DistilleryHologramStatusDetailsDialogComponent', width: '70%' }, icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;', success: ['Received','Completed'], info: ['InProgress'], brown: ['Dispatched'], warning: ['Approved', 'Requested'], black: ['Reviewed'] } }
      ]
    },
    createRecord: {}
  };

  public HologramsHEALDailyIndent = {
    
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'entityId', label: 'Supplier Name' },
        { key: 'quantity', label: 'Quantity' },
        { key: 'requestedOn', label: 'Requested On', type: 'date' },
        { key: 'purpose', label: 'Purpose' },
        { key: 'healReqIndentId', label: 'Inventory',style: 'color:red;', type: 'link', modal: { component: 'ScanSpoolsDialogComponent', width: '45%' } },
        {
          key: 'status', label: 'Status', modal: { component: 'IssuedSpoolsDialogComponent' },
          icon: {warning: ['Issued Spools'], info: ['Pending'], yellow: ['Requested'], success: ['Issued']
            // success: ['Issued Spools'], warning: ['Pending'], danger: ['Requested']
          }
        }
      ],
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'entityId', label: 'Supplier Name' },
        { key: 'quantity', label: 'Quantity' },
        { key: 'requestedOn', label: 'Requested On', type: 'date' },
        { key: 'purpose', label: 'Purpose' },
        { key: 'healReqIndentId', label: 'Inventory'},
        {
          key: 'status', label: 'Status', modal: { component: 'IssuedSpoolsDialogComponent' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer',
            info: ['Issued Spools'], warning: ['Pending'], danger: ['Requested'], success: ['Issued']
            // success: ['Issued Spools'], warning: ['Pending'], danger: ['Requested']
          }
        }
      ]
    },
    createRecord: {
      //"Distillery Officer": {
        //component: "ManageHealDailyIndentDialogComponent",
        //width: '65%'
      //},
      "Distillery Manager": {
        component: "ManageHealDailyIndentDialogComponent",
        width: '65%'
      }
    }
  };

  public HologramsHEALPurchaseIndent = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'entityId', label: 'Supplier Name' },
        { key: 'healIndentId', label: 'Indent Number' },
        { key: 'purpose', label: 'Purpose' },
        { key: 'finalQuantity', label: 'Quantity' },
        { key: 'challanId', label: 'Challan Number' },
        { key: 'challanAmount', label: 'Challan Amount' },
        { key: 'challanDate', label: 'Challan Date', type: 'date' },
        { key: 'requestedOn', label: 'Created On', type: 'date' },
        {
          key: 'status', label: 'Status', modal: { component: 'HealPurchaseIndentStatusDetailsDialogComponent', width: '45%' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer', 
            success: ['Approved'], yellow: ['Requested'], warning: ['Reviewed'], danger: ['Rejected'], black: ['Received'], brown: ['Dispatched']
          }
        }
      ],
      "Excise Superintendent Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'healIndentId', label: 'Indent Number' },
        { key: 'requestedQuantity', label: 'Quantity' },
        { key: 'purpose', label: 'Purpose' },
        { key: 'requestedOn', label: 'Created On', type: 'date' },
        {
          key: 'status', label: 'Status', modal: { component: 'HealPurchaseIndentStatusDetailsDialogComponent', width: '65%' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;',
            success: ['Approved'], yellow: ['Requested'], warning: ['Reviewed'], danger: ['Rejected'], black: ['Received'], brown: ['Dispatched']
          }
        }
      ]
    },
    createRecord: {
      //"Distillery Officer": {
        //component: "ManageHealPurchaseIndentDialogComponent",
        //width: '65%'
      //},
      "Distillery Manager": {
        component: "ManageHealPurchaseIndentDialogComponent",
        width: '60%'
      },
      "Excise Superintendent Officer": {
        component: "ManageHealPurchaseIndentDialogComponent",
        width: '65%'
      },
      "Data Processing Officer": {
        component: "ManageHealPurchaseIndentDialogComponent",
        width: '65%'
      },
    }
  };

  public HologramsHEALUsage = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'purpose', label: 'Purpose' },
        { key: 'openingStock', label: 'Opening Stock' },
        { key: 'receipts', label: 'Heal Recieved' },
        { key: 'issued', label: 'Heal Issued' },
        { key: 'used', label: 'Heal Used' },
        { key: 'damagedHealsCount', label: 'Damage' },
        { key: 'closingStock', label: 'Closing Stock' }
      ],
      "Excise Superintendent Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'openingStock', label: 'Opening Stock' },
        { key: 'receiptsQuantity', label: 'Receipts Quantity' },
        { key: 'totalQuantityUsed', label: 'Total Quantity Used' },
        { key: 'damage', label: 'Damage' },
        { key: 'closingStock', label: 'Closing Stock' }
      ]
    },
    createRecord: {}
  };

  public HologramsHEALInventory = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        {
          key: 'cartonBarcode', label: 'Carton Barcode', path: 'cartons', class: 'text-primary pointer',
          type: 'link', modal: { component: 'CartoonBarcodeDialogComponent', width: '65%' }
        },
        { key: 'entityId', label: 'Supplier Code' },
        { key: 'healsShippedOn', label: 'Shipped On', type: 'date' },
        { key: 'purpose', label: 'Purpose' },
        { key: 'healsReceivedOn', label: 'Received On', type: 'date' }
      ],
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'startCode', label: 'Heal Start Code' },
        { key: 'endCode', label: 'Heal End Code' },
        { key: 'spoolBarcode', label: 'Spool Barcode' },
        { key: 'purpose', label: 'Purpose' },
        { key: 'receivedDate', label: 'Recieved Date', type: 'date' },
       
        { key: 'unusedCount', label: 'Unused Count' }
      ]
    },
    createRecord: {}
  };


  public HologramsHEALSpools = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'spoolBarcode', label: 'Spool Barcode'},
        { key: 'firstHealCode', label: 'First Healcode'},
        { key: 'startHealCode', label: 'Start Healcode'},
        { key: 'lastHealCode', label: 'Last Healcode'},
        { key: 'numHealsUsed', label: 'Used Heals'},
        { key: 'remainingQuantity', label: 'Remaining Heals'},
      ]
    },
    createRecord: {}
  }

  public HologramsHEALFeed = {
    columnsToDisplay: {
      "Supplier Line Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'spoolBarcode', label: 'Spool Barcode' },
        { key: 'startCode', label: 'Heal Start Code' },
        { key: 'endCode', label: 'Heal End Code' },
        { key: 'purpose', label: 'Purpose' },
        { key: 'unusedCount', label: 'Quantity' },
        { key: 'action', label: 'Action' }

      ]
    },
    createRecord: {
    }
  };

  public HologramsHEALDeallocation = {
    columnsToDisplay: {
      "Supplier Line Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'spoolBarcode', label: 'Spool Barcode' },
        { key: 'assignedLineName', label: 'Line Name' },
        { key: 'receivedDate', label: 'Allocated Date' },
        { key: 'totalQuantity', label: 'Current Day Available Quantity' },
        { key: 'usedCount', label: 'Current Day Used' },
        { key: 'unusedCount', label: 'Current day Unused' },
        { key: 'action', label: 'Action' }
      ]
    },
    createRecord: {
    }
  };

  public HologramsHEALDamage = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'shCode', label: 'SH Code' },
        { key: 'spoolBarCode', label: 'Spool Barcode' }
      ]
    },
    createRecord: {
      "Distillery Manager": {
        component: 'ManageHealDamageDialogComponent',
        width: '50%'
      },
      "Excise Superintendent Officer": {
        component: 'ManageHealDamageDialogComponent',
        width: '50%'
      }
    }
  };

  public HologramsHEALVerification = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'spoolBarCode', label: 'Spool Barcode' },
        { key: 'action', label: 'Action' }
      ],
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'spoolBarCode', label: 'Spool Barcode' },
        { key: 'shCode', label: 'SH Code' }
      ]
    },
    createRecord: {}
  };

  public HologramsHealProcess = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'spoolBarCode', label: 'Indent Number' },
        { key: 'quantity', label: 'Quantity' },
        { key: 'purpose', label: 'Purpose' },
        { key: 'createdOn', label: 'Created On', type: 'date' },
        { key: 'status', label: 'status' }
      ]
    },
    createRecord: {}
  };

  public SupplierLocalSupplierDetails = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'supplierName', label: 'Supplier Name' },
        { key: 'mobileNo', label: 'Mobile Number'},
        { key: 'address', label: 'Address', path: 'distilleryAddress' },
        { key: 'town/village', label: 'City', path: 'distilleryAddress' },
        { key: 'cst', label: 'CST' },
        { key: 'tin', label: 'TIN' }
      ]
    },
    createRecord: {}
  };


  public SupplierNonlocalsupplierdetails = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'supplierName', label: 'Supplier Name' },
        { key: 'mobileNo', label: 'Mobile Number'},
        { key: 'address', label: 'Address', path: 'distilleryAddress' },
        { key: 'town/village', label: 'City', path: 'distilleryAddress' },
        { key: 'cst', label: 'CST' },
        { key: 'tin', label: 'TIN' }
      ]
    },
    createRecord: {}
  };

  public SupplierBreweriesDetails = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'supplierName', label: 'Supplier Name' },
        { key: 'mobileNo', label: 'Mobile Number'},
        { key: 'address', label: 'Address', path: 'distilleryAddress' },
        { key: 'town/village', label: 'City', path: 'distilleryAddress' },
        { key: 'cst', label: 'CST' },
        { key: 'tin', label: 'TIN' }
      ]
    },
    createRecord: {}
  };

  public ProductionProducts = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'liquorType', label: 'Segment' },
        { key: 'size', label: 'Size(ml)' },
        { key: 'packType', label: 'Pack Type' },
        { key: 'price', label: 'Base Price', path: 'basicPricing' },
        { key: 'EXCISE DUTY', label: 'Excise Duty',path:'taxes' },
        { key: 'Final Mrp', label: 'MRP',path:'taxes' }
      ],
      "Brewery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product Code', type: 'link', modal: { component: 'ProductionProductsDialogComponent', width: '70%' } },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'liquorType', label: 'Segment' },
        { key: 'size', label: 'Size(ml)' },
        { key: 'packType', label: 'Pack Type' },
        { key: 'price', label: 'Base Price', path: 'basicPricing' },
        { key: 'EXCISE DUTY', label: 'Excise Duty',path:'taxes' },
        { key: 'Final Mrp', label: 'MRP', type: 'number',path:'taxes' }
      ],
      "NonLocal Brewery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product Code', type: 'link', modal: { component: 'ProductionProductsDialogComponent', width: '70%' } },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'liquorType', label: 'Segment' },
        { key: 'size', label: 'Size(ml)' },
        { key: 'packType', label: 'Pack Type' },
        { key: 'price', label: 'Base Price', path: 'basicPricing' },
        { key: 'EXCISE DUTY', label: 'Excise Duty',path:'taxes' },
        { key: 'Final Mrp', label: 'MRP', type: 'number',path:'taxes' }
      ]
    },
    createRecord: {}
  };

  public ProductionBatches = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'batchId', label: 'Batch No' },
        { key: 'supplierCode', label: 'Supplier' },
        { key: 'brandCode', label: 'Brand Code' },
        { key: 'strength', label: 'Strength' },
        { key: 'tankName', label: 'Tank Name' },
        { key: 'manufactureDate', label: 'Blend Date Time', type: 'dateTime' },
        { key: 'initialBlendBLs', label: 'Initial Qty (in BLs)' },
        { key: 'initialBlendPLs', label: 'Initial Qty (in PLs)' },
        { key: 'remainingBlendBLsFixed', label: 'Remaining Qty (in BLs)' },
        { key: 'remainingBlendPLsFixed', label: 'Remaining Qty (in PLs)' },
        { key: 'batchQuantity', label: 'Batch Quantity' },
        { key: 'status', label: 'Status', icon: { success: ['Active'], black: ['InActive']} },
        // {
        //   key: 'action', label: 'Action', action: 'edit', actions: [
        //     { icon: 'edit',  modal: { component: 'ManageBatchesDialogComponent', width: '65%', }, notEqRule: { key: 'status', value: 'InUse', } },
        //   ] 
        // }
      ]
    },
    createRecord: {
      "Distillery Manager": {
        component: 'ManageBatchesDialogComponent',
        width: '80%'
      },
      "NonLocal Supplier Manager": {
        component: 'ManageBatchesDialogComponent',
        width: '50%'
      },
      "Brewery Manager": {
        component: 'ManageBatchesDialogComponent',
        width: '80%'
      },
      "NonLocal Brewery Manager": {
        component: 'ManageBatchesDialogComponent',
        width: '80%'
      },
    }
  };

  public ProductionProductionLine = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'entityId', label: 'Supplier Name' },
        { key: 'lineName', label: 'Line Name', type: 'link', modal: { component: 'ProductionLineDetailsDialogComponent', width: '65%' } },
        { key: 'createdAt', label: 'Created On', type: 'date' },
        {
          key: 'action', label: 'Action', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'ManageProductionLineDialogComponent', width: '50%' }, notEqRule: { key: 'status', value: 'InUse' } },
          ]
        },
         { key: 'status', label: 'Status', icon: { success: ['Active'], warning: ['InActive']} },
        {
          key: 'targetProduction', label: 'Action', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'TargetProductionDetailsDialogComponent', width: '50%' }, notEqRule: { key: 'status', value: 'InUse' } },
          ]
        },
      ],

      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'batchId', label: 'Batch Id' },
        { key: 'entityId', label: 'Supplier Name' },
        { key: 'lineName', label: 'Line Name', type: 'link', modal: { component: 'ProductionLineDetailsDialogComponent', width: '65%' } },
        { key: 'createdAt', label: 'Created On', type: 'dateTime' },
        { key: 'productionId', label: 'Production Id' },
        {
          key: 'targetCasesCount', label: 'Target Production', type:'edit', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'TargetProductionDetailsDialogComponent', width: '50%' }, notEqRule: { key: 'status', value: 'InUse' } },
          ]
        },
        {
          key: 'heelFeed', value: 'Allocated Spools', label: 'Heel Feed', type: 'link', modal: { component: 'SpoolDetailsDialogComponent', width: '50%' }, notEqRule: { key: 'status', value: 'Allocated Spools' }
        },
        /*  {
           key: 'action', label: 'Action', action: 'edit', actions: [
             { icon: 'edit', modal: { component: 'ManageProductionLineDialogComponent', width: '50%' }, notEqRule: { key: 'status', value: 'InUse' } },
           ]
         }, */
        { key: 'status', label: 'Status', icon:{ success: ['Active'], warning: ['InActive']} },
        // {
        //   key: 'action', label: 'Action', action: 'edit', actions: [
        //     { icon: 'edit', modal: { component: 'ManageProductionLineDialogComponent', width: '50%' }, notEqRule: { key: 'status', value: 'InUse' } },
        //   ]
        // },
      ],
      "Supplier Line Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'batchId', label: 'Batch Id' },
        { key: 'entityId', label: 'Supplier Name' },
        { key: 'lineName', label: 'Line Name', type: 'link', modal: { component: 'ProductionLineDetailsDialogComponent', width: '65%' } },
        { key: 'createdAt', label: 'Created On', type: 'dateTime' },
        { key: 'productionId', label: 'Production Id' },
        {
          key: 'targetCasesCount', label: 'Target Production', type:'edit', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'TargetProductionDetailsDialogComponent', width: '50%' }, notEqRule: { key: 'status', value: 'InUse' } },
          ]
        },
        {
          key: 'heelFeed', value: 'Allocated Spools', label: 'Heel Feed', type: 'link', modal: { component: 'SpoolDetailsDialogComponent', width: '50%' }, notEqRule: { key: 'status', value: 'Allocated Spools' }
        },
        { key: 'status', label: 'Status', icon:{ success: ['Active'], warning: ['InActive']} },
        {
          key: 'action', label: 'Action', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'ManageProductionLineDialogComponent', width: '50%' }, notEqRule: { key: 'status', value: 'InUse' } },
          ]
        },
      ]
    },
    createRecord: {
      // "Distillery Officer": {
      //   component: 'ManageProductionLineDialogComponent',
      //   width: '65%'
      // },
      "Distillery Manager": {
        component: 'ManageProductionLineDialogComponent',
        width: '65%'
      }
    }
  };

  public ProductionProductionStart = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'productionId', label: 'Production Id', type: 'link', modal: { component: 'BatchProductionInfoComponent', width: '65%' } },
        { key: 'lineName', label: 'Line' },
        { key: 'productionType', label: 'Production Type' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'batchId', label: 'Batch Number' },
        { key: 'packType', label: 'Pack Type' },
        { key: 'healStartCode', label: 'Heal Start Code' },
        { key: 'bottles', label: 'Bottles' },
        { key: 'cases', label: 'Cases' },
        { key: 'productionStatus', label: 'Action',  actions: [
          { icon: 'edit', modal: { component: 'ManageBatchProductionDialogComponent', width: '85%', height: 'auto' }},
        ]}
      ]
    },
    createRecord: {}
  };

  public ExciseDutyExciseDuties = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'transactionDate', label: 'Challan/Transaction Date',type:'dateTime' },
        { key: 'purpose', label: 'Transaction Type' },
        { key: 'transactionNo', label: 'Challan / TransactionNo.' },
        { key: 'initialAmount', label: 'Opening Balance', type:'decimal'},
        { key: 'amountDeposited', label: 'Credit', type:'decimal'},
        { key: 'amountUsed', label: 'Debit', type:'decimal'},
        { key: 'balanceAmount', label: 'Balance', type:'decimal' }
      ]
    },
    createRecord: {}
  };

  public ExciseDutyRemittance = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        // { key: 'dateTime', label: 'Date Time', type: 'date' },
        { key: 'transactionNo', label: 'Challan / Transaction No' },
        { key: 'createdDate', label: 'Created Date', type: 'date' },
        { key: 'amountDeposited', label: 'Deposit Amount' },
        { key: 'transactionDate', label: 'Transaction Date', type: 'date' },
        { key: 'purpose', label: 'Challan Type' },
      ]
    },
    createRecord: {
      // "Distillery Officer": {
      //   component: 'ManageRemitanceDialogComponent',
      //   width: '65%'
      // },
      "Distillery Manager": {
        component: 'ManageRemitanceDialogComponent',
        width: '65%'
      },
      "Brewery Manager": {
        component: 'ManageRemitanceDialogComponent',
        width: '65%'
      },
      // "NonLocal Brewery Manager": {
      //   component: 'ManageRemitanceDialogComponent',
      //   width: '65%'
      // },
    }
  };
  public ExciseDutyBeerTTSPaymentTransactions = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'departmentId', label: 'Dept Transaction Id' },
        { key: 'amountDeposited', label: 'Amount Paid For', type: 'staticValue', value: 'TTSFee'  },
        // { key: 'indentId', label: 'Indent Number' },
        { key: 'orderId', label: 'Unique Number' },
        { key: 'transactionNo', label: 'Transaction Ref ID' },
        { key: 'amount', label: 'Amount' },
        { key: 'bankTransactionDate', label: 'Challan Date', type: 'date' },
        { key: 'paymentStatus', label: 'Status' }
      ]
    },
    createRecord: {}
  };

  public ExciseDutyExciseDutyForExpiredGoods = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'departmentId', label: 'Dept Transaction Id' },
        { key: 'amountDeposited', label: 'Amount Paid For', type: 'staticValue', value: 'TTSFee'  },
        // { key: 'indentId', label: 'Indent Number' },
        { key: 'orderId', label: 'Unique Number' },
        { key: 'transactionNo', label: 'Transaction Ref ID' },
        { key: 'amount', label: 'Amount' },
        { key: 'bankTransactionDate', label: 'Challan Date', type: 'date' },
        { key: 'paymentStatus', label: 'Status' }
      ]
    },
    createRecord: {}
  };

  public RawMaterialProcurementRawMaterialProcurement = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent Id' },
        { key: 'invoiceNo', label: 'Invoice Number' },
        { key: 'sourceEntityId', label: 'Source' },
        { key: 'destinationEntityId', label: 'Destination' },
        { key: 'createdOn', label: 'Created On' },
        { key: 'validityDate', label: 'Validity Date' },
        { key: 'status', label: 'Status', modal: { component: 'ProcurementStatusDialogComponent', width: '60%' }, icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;', success: ['Received'], warning: ['Approved', 'Requested'], black: ['Reviewed'] } } 
      ]
    },
    createRecord: {
      "Distillery Manager": {
        component: 'ManageProcurementDialogComponent',
        width: '50%'
      },
      // "Excise Lab": {
      //   component: 'ManageProcurementDialogComponent',
      //   width: '50%'
      // }
    }
  };

  public IndentManagementUpdateIndents = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'destnEntityId', label: 'Retailer' },
        { key: 'sourceEntityId', label: 'Depot' },
        { key: 'createdOn', label: 'Created Date', type: 'date' },
        {
          key: 'status', label: 'Action', type: 'link',
          modal: { component: 'OpenActionUpdateIndentDialogComponent', width: '50%' }, icon: {
            type: 'pi pi-circle-on cursor-pointer',
            style: 'font-size:12px;cursor:pointer;', success: ['Approved'], warning: ['Active'], danger: ['In-Active']
          }
        },      ]
    },
    createRecord: {
    }
  };


  public IndentManagementUpdateBarIndents  = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'destnEntityId', label: 'Retailer' },
        { key: 'sourceEntityId', label: 'Depot' },
        { key: 'createdOn', label: 'Created Date', type: 'date' },
        {
          key: 'status', label: 'Action', type: 'link',
          modal: { component: 'OpenActionUpdateBarIndentComponent', width: '75%' }, icon: {
            type: 'pi pi-circle-on cursor-pointer',
            style: 'font-size:12px;cursor:pointer;', success: ['Approved'], warning: ['Active', 'Created'], danger: ['Cancelled', 'In-Active'], yellow: ['InTransit'], info: ['PENDING', 'InProgress'],
          }
        },
      ]
    },
    createRecord: {
    }
  };

  public IndentManagementDistilleryIndent = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'IFS Number' },
        { key: 'ofsCode', label: 'OFS Number' },
        { key: 'supplierCode', label: 'Supplier' },
        { key: 'depotCode', label: 'Depot' },
        { key: 'createdOn', label: 'Created Date', type: 'date' },
        { key: 'validityDate', label: 'Validity Date', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'OpenDistilleryIndentStatusDialogComponent', width: '75%' }, icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;', success: ['Approved'], warning: ['Requested', 'Created'], info: ['Reviewed'], danger: ['Rejected'], yellow: ['InTransit'], } }
      ],
      "Purchase Order Manager": [
        { key: 'checkbox', label: '', type: 'checkbox' },
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'IFS Number' },
        { key: 'ofsCode', label: 'OFS Number' },
        { key: 'supplierCode', label: 'Supplier' },
        { key: 'depotCode', label: 'Depot' },
        { key: 'createdOn', label: 'Created Date', type: 'date' },
        { key: 'validityDate', label: 'Validity Date', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'OpenDistilleryIndentStatusDialogComponent', width: '75%' }, icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;', success: ['Approved'], warning: ['Requested', 'Created'], info: ['Reviewed'], danger: ['Rejected'], yellow: ['InTransit'], } }
      ],
      "Distillery Manager": [
        { key: 'checkbox', label: '', type: 'checkbox' },
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'IFS Number' },
        { key: 'ofsCode', label: 'OFS Number' },
        { key: 'supplierCode', label: 'Supplier' },
        { key: 'depotCode', label: 'Depot' },
        { key: 'createdOn', label: 'Created Date', type: 'date' },
        { key: 'validityDate', label: 'Validity Date', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'OpenDistilleryIndentStatusDialogComponent', width: '75%' }, icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;', success: ['Approved'], warning: ['Requested', 'Created'], info: ['Reviewed'], danger: ['Rejected'], yellow: ['InTransit'], } }
      ]
    },
    createRecord: {
      "Purchase Order Manager": {
        component: 'ManageDistilleryIndentDialogComponent',
        width: '90%'
      },
      // "Distillery Manager": {
      //   component: 'ManageDistilleryIndentDialogComponent',
      //   width: '90%'
      // },
      // "NonLocal Supplier Manager": {
      //   component: 'ManageDistilleryIndentDialogComponent',
      //   width: '90%'
      // },
    }
  };

  public IndentManagementBreweryIndent = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'IFS Number' },
        { key: 'ofsCode', label: 'OFS Number' },
        { key: 'supplierCode', label: 'Supplier' },
        { key: 'depotCode', label: 'Depot' },
        { key: 'createdOn', label: 'Created Date', type: 'date' },
        { key: 'validityDate', label: 'Validity Date', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'OpenDistilleryIndentStatusDialogComponent', width: '75%' }, icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;', success: ['Approved'], warning: ['Requested', 'Created'], info: ['Reviewed'], danger: ['Rejected'], yellow: ['InTransit'], } }
      ],
      "Purchase Order Manager": [
        { key: 'checkbox', label: '', type: 'checkbox' },
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'IFS Number' },
        { key: 'ofsCode', label: 'OFS Number' },
        { key: 'supplierCode', label: 'Supplier' },
        { key: 'depotCode', label: 'Depot' },
        { key: 'createdOn', label: 'Created Date', type: 'date' },
        { key: 'validityDate', label: 'Validity Date', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'OpenDistilleryIndentStatusDialogComponent', width: '75%' }, icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;', success: ['Approved'], warning: ['Requested', 'Created'], info: ['Reviewed'], danger: ['Rejected'], yellow: ['InTransit'], } }
      ]
    },
    createRecord: {
      "Brewery Manager": {
        component: 'ManageDistilleryIndentDialogComponent',
        width: '90%'
      },
      "NonLocal Brewery Manager": {
        component: 'ManageDistilleryIndentDialogComponent',
        width: '90%'
      }
    }
  };

  public IndentManagementRetailerIndent = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'updatedDateTime', label: 'Raised On',type:'date' },
        // { key: 'requestedCases', label: 'Requested Quantity',path:'productDetails' },
        { key: 'status', label: 'Status',  icon: {info: ['InProgress'],  yellow: ['Requested', 'InTransit'], success: ['Approved'],danger:['Rejected']}}
      ]
    },
    createRecord: {
      // component: ManageRetailerIndentDialogComponent
      // width: '50%
    }
  };

  public IndentManagementICDCScan = {
    columnsToDisplay: {
      "Licensee": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'depotCode', label: 'Depot' },
        { key: 'createdOn', label: 'Approved On' },
        { key: 'status', label: 'Status', modal: { component: 'IcdcScanStatusDialogComponent', width: '75%' }, 
        icon: { success: ['Created'], yellow: ['InTransit', ], danger: ['Reached'], warning: ['Dispatched'] } }
      ]
    },
    createRecord: {}
  };

  public IndentManagementRetailerChallanTransfer = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'depotName', label: 'Depot Name' },
        { key: 'referenceChallanNo', label: 'Reference Challan Number' },
        { key: 'actualChallanNo', label: 'Actual Challan Number' },
        { key: 'oldRetailerCode', label: 'Old Retailer Code' },
        { key: 'newRetailerCode', label: 'New Retailer Code' },
        { key: 'newRetailerName', label: 'New Retailer Name' },
        { key: 'challanAmount', label: 'Challan Amount' },
        { key: 'challandate', label: 'Challan Date', type: 'date' },
        { key: 'action', label: 'Action' }
      ]
    },
    createRecord: {
      // component: ManageRetailerIndentDialogComponent
      // width: '50%
    }
  };

  public IndentManagementChallans = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'depotName', label: 'Depot Name' },
        { key: 'depotCode', label: 'Depot Code' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'tXNDate', label: 'TXN Date', type: 'date' },
        { key: 'challanNumber', label: 'Challan Number' },
        { key: 'challanAmount', label: 'Challan Amount' },
        { key: 'challandate', label: 'Challan Date', type: 'date' },
        { key: 'challanVerifiedDate', label: 'Challan Verified Date', type: 'date' },
        { key: 'sync', label: 'Sync' },
        { key: 'verifiedBy', label: 'Verified By' },
      ]
    },
    createRecord: {
      // component: ManageRetailerIndentDialogComponent
      // width: '50%
    }
  };

  public IndentManagementCancelledICDC = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'icdcNo', label: 'ICDC Number' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'registrationNo', label: 'Registration Number' },
        { key: 'retailer', label: 'Retailer' },
        { key: 'cancelDate', label: 'Date Of Cancellation', type: 'date' },
        { key: 'status', label: 'Status' }
      ]
    },
    createRecord: {
      // component: ManageIssueLogDialogComponent
      // width: '50%'
    }
  };

  public IndentManagementOFSExtension = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'ofsCode', label: 'OFS Number' },
        { key: 'createdOn', label: 'Validity From' },
        { key: 'validityDate', label: 'Validity Till' },
        { key: 'select', label: 'Select All', type: 'checkbox' }
      ]
    },
    createRecord: {
      // component: ManageIssueLogDialogComponent
      // width: '50%'
    }
  };

  public IndentManagementRSIndentforBlend = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentNo', label: 'Indent Number' },
        { key: 'indentDate', label: 'Indent Date', type: 'date' },
        {
          key: 'status', label: 'Status', modal: { component: 'StatusDetailsDialogComponent', width: '75%' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;',
            success: ['Approved'], warning: ['Requested'], info: ['Reviewed'], danger: ['Rejected']
          }
        }

      ]
    },
    createRecord: {
      "Distillery Officer": {
        component: 'ManageRsIndentDialogComponent',
        width: '75%'
      }
    }
  };

  public IndentManagementENAIndentforBlend = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentNo', label: 'Indent Number' },
        { key: 'indentDate', label: 'Indent Date', type: 'date' },
        { key: 'brand', label: 'Brand' },
        { key: 'category', label: 'Category' },
        {
          key: 'status', label: 'Status', modal: { component: 'StatusDetailsDialogComponent', width: '75%' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;',
            success: ['Approved'], warning: ['Requested'], info: ['Reviewed'], danger: ['Rejected']
          }
        }
      ]
    },
    createRecord: {
      "Distillery Officer": {
        component: 'ManageEnaIndentDialogComponent',
        width: '70%'
      }
    }
  };

  public IndentManagementBlendIndentforBottling = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentNo', label: 'Indent Number' },
        { key: 'indentDate', label: 'Indent Date' , type: 'date'},
        {
          key: 'status', label: 'Status', modal: { component: 'StatusDetailsDialogComponent', width: '75%' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;',
            success: ['Approved'], warning: ['Requested'], info: ['Reviewed'], danger: ['Rejected']
          }
        }
      ]
    },
    createRecord: {
      "Distillery Officer": {
        component: 'ManageBlendIndentDialogComponent',
        width: '80%'
      }
    }
  };

  public IndentManagementOtherSpiritsIndentForBlend = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentNo', label: 'Indent Number' },
        { key: 'indentDate', label: 'Indent Date', type: 'date' },
        { key: 'brand', label: 'Brand' },
        { key: 'category', label: 'Category' },
        { key: 'type', label: 'Type' },
        {
          key: 'status', label: 'Status', modal: { component: 'StatusDetailsDialogComponent', width: '75%' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;',
            success: ['Approved'], warning: ['Requested'], info: ['Reviewed'], danger: ['Rejected']
          }
        }
      ]
    },
    createRecord: {
      "Distillery Officer": {
        component: 'ManageOtherSpiritIndentDialogComponent',
        width: '75%'
      }
    }
  };

  public IndentManagementIFS = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'consignmentType', label: 'Consignment Type' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'destinationEntityId', label: 'Depot' },
        { key: 'createdOn', label: 'Created On', type: 'date' },
        { key: 'approvedOn', label: 'Approved On' },
        { key: 'status', label: 'Status', modal: { component: 'OpenDistilleryIndentStatusDialogComponent' }, icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px', success: ['Approved'], warning: ['Requested'], info: ['Reviewed'], danger: ['Rejected'] } }
      ],
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'consignmentType', label: 'Consignment Type' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'destinationEntityId', label: 'Depot' },
        { key: 'createdOn', label: 'Created On', type: 'date' },
        { key: 'approvedOn', label: 'Approved On' },
        { key: 'status', label: 'Status', modal: { component: 'OpenDistilleryIndentStatusDialogComponent' }, icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px', success: ['Approved'], warning: ['Requested'], info: ['Reviewed'], danger: ['Rejected'] } }
      ]
    },
    createRecord: {}
  };

  public IndentManagementLicenseeIndents = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'destnEntityId', label: 'Retailer Name' },
        { key: 'indentType', label: 'Indent Type' },
        { key: 'createdOn', label: 'Created Date', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'LicenseeStatusDialogComponent' }, icon: {  success: ['Approved'], yellow: ['Requested',''], info: ['Reviewed','InProgress'], danger: ['Rejected'] } }
      ]
    },
    createRecord: {
      // "Depot Manager": {
      //   component: 'ManageLicenseeIndentsDialogComponent',
      //   width: '75%'
      // },
      "Data Processing Officer": {
        component: 'ManageLicenseeIndentsDialogComponent',
        width: '85%'
      },
      // "Excise Superintendent Officer": {
      //   component: 'ManageLicenseeIndentsDialogComponent',
      //   width: '75%'
      // },
      // "Store Officer": {
      //   component: 'ManageLicenseeIndentsDialogComponent',
      //   width: '75%'
      // }
    }
  };
  public IndentManagementManualIndents = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'destnEntityId', label: 'Retailer Name' },
        { key: 'indentType', label: 'Indent Type' },
        { key: 'createdOn', label: 'Created Date', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'LicenseeStatusDialogComponent' }, icon: {  success: ['Approved'], yellow: ['Requested',''], info: ['Reviewed','InProgress'], danger: ['Rejected'] } }
      ]
    },
    createRecord: {
      // "Depot Manager": {
      //   component: 'ManageLicenseeIndentsDialogComponent',
      //   width: '75%'
      // },
      "Data Processing Officer": {
        component: 'ManageLicenseeIndentsDialogComponent',
        width: '85%'
      },
      // "Excise Superintendent Officer": {
      //   component: 'ManageLicenseeIndentsDialogComponent',
      //   width: '75%'
      // },
      // "Store Officer": {
      //   component: 'ManageLicenseeIndentsDialogComponent',
      //   width: '75%'
      // }
    }
  };

  public IndentManagementChallanReconcile = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'startDate', label: 'Start Date', type: 'date' },
        { key: 'endDate', label: 'End Date', type: 'date' },
        { key: 'action', label: 'action' },
      ]
    },
    createRecord: {
      "Depot Manager": {
        component: 'ManageLicenseeIndentsDialogComponent',
        width: '75%'
      },
      "Data Processing Officer": {
        component: 'ManageLicenseeIndentsDialogComponent',
        width: '75%'
      },
      "Excise Superintendent Officer": {
        component: 'ManageLicenseeIndentsDialogComponent',
        width: '75%'
      },
      "Store Officer": {
        component: 'ManageLicenseeIndentsDialogComponent',
        width: '75%'
      }
    }
  };
  public IndentManagementWholeIndents = {
    columnsToDisplay: {
      "Licensee": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'destnEntityId', label: 'Vendor Name' },
        { key: 'createdOn', label: 'Created On', type: 'date' },
        { key: 'cfmsId', label: 'CFMS ID'},
        { key: 'departmentId', label: 'Department Id'},
        { key: 'payment',label:'Payment'},
        { key: 'status', label: 'Status', modal: { component: 'VendorIndentStatusDialogComponent', width: '50%' }, icon: { type: 'pi pi-circle-on cursor-pointer',  success: ['Approved', 'SUCCESS'], warning: ['Requested'], info: ['Reviewed', 'InProgress'], danger: ['Rejected']} }
      ],
    },
    createRecord: {
    }
  };
  public IndentManagementIndents = {
    columnsToDisplay: {
      "Licensee": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'destnEntityId', label: 'Vendor Name' },
        { key: 'createdOn', label: 'Created On', type: 'date' },
        { key: 'cfmsId', label: 'CFMS ID'},
        { key: 'departmentId', label: 'Department Id'},
        { key: 'payment',label:'Payment'},
        { key: 'status', label: 'Status', modal: { component: 'VendorIndentStatusDialogComponent', width: '50%' }, icon: { type: 'pi pi-circle-on cursor-pointer',  success: ['Approved', 'SUCCESS'], warning: ['Requested'], info: ['Reviewed', 'InProgress'], danger: ['Rejected']} }
      ],
    },
    createRecord: {
    }
  };

  // public IndentManagementVendorIndents = {
  //   columnsToDisplay: {
  //     "Depot Manager": [
  //       { key: 'no', label: 'S.No.' },
  //       { key: 'indentId', label: 'Indent Number' },
  //       { key: 'destnEntityId', label: 'Vendor Name' },
  //       { key: 'createdOn', label: 'Created On', type: 'date' },
  //       { key: 'status', label: 'Status', modal: { component: 'LicenseeStatusDialogComponent' }, icon: { type: 'pi pi-circle-on cursor-pointer',  success: ['Approved'], warning: ['Requested'], info: ['Reviewed', 'InProgress'], danger: ['Rejected']} }
  //     ],
  //     "Licensee": [
  //       { key: 'no', label: 'S.No.' },
  //       { key: 'vendorId', label: 'Indent Number' },
  //       { key: 'destnEntityId', label: 'Vendor Name' },
  //       { key: 'createdOn', label: 'Created On', type: 'date' },
  //       { key: 'payment',label:'Payment'},
  //       { key: 'status', label: 'Status', modal: { component: 'LicenseeStatusDialogComponent' }, icon: { type: 'pi pi-circle-on cursor-pointer',  success: ['Approved'], warning: ['Requested'], info: ['Reviewed', 'InProgress'], danger: ['Rejected']} }
  //     ],
  //   },
  //   createRecord: {
  //     "Data Processing Officer": {
  //       component: 'ManageVendorIndentsDialogComponent',
  //       width: '75%'
  //     },
  //     "Licensee": {
  //       component: 'ManageVendorIndentsDialogComponent',
  //       width: '75%'
  //     }
  //   }
  // }; 

  public IndentManagementCreditRegister = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'date', label: 'Date & Time', type: 'date' },
        { key: 'retailer', label: 'Retailer' },
        { key: 'description', label: 'Description' },
        { key: 'challanNumber', label: 'ChallanNumber' },
        { key: 'openingBalance', label: 'Opening Balance(Rs./Ps.)' },
        { key: 'credit', label: 'Credit (Rs./Ps.)' },
        { key: 'debit', label: 'Debit(Rs./Ps.)' },
        { key: 'balance', label: 'Balance(Rs./Ps.)' },
      ]
    },
    createRecord: {
      "Depot Manager": {
        component: 'ManageIssueLogDialogComponent',
        width: '50%'
      }
      // "Data Processing Officer": {
      //   component: 'ManageLicenseeIndentsDialogComponent',
      //   width: '75%'
      // }

    }
  };

  public IndentManagementEventPermits = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'permitNumber', label: 'Permit Number' },
        { key: 'issueDate', label: 'IssueDate', type: 'date' },
        { key: 'permitFees', label: 'Permit Fees' },
        { key: 'eventDate', label: 'Event Date', type: 'date' },
        { key: 'eventAddress', label: 'Event Address' },
        { key: 'status', label: 'Status', type: 'link', modal: { component: 'OpenSamplesStatusDialogComponent', width: '85%' }, icon: { success: ['Active'], warning: ['In-Active']} }
      ]
    },
    createRecord: {
      "Depot Manager": {
        component: 'AddEventPermitDialogComponent',
        width: '75%'
      },
      // "Data Processing Officer": {
      //   component: 'AddEventPermitDialogComponent',
      //   width: '75%'
      // },
      "Excise Superintendent Officer": {
        component: 'AddEventPermitDialogComponent',
        width: '75%'
      }
    }
  };

  public IndentManagementInactiveRetailers = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'retailerRegisterNumber', label: 'Retailer Register Number' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'creditAmount', label: 'Credit Amount' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'gazetteCode', label: 'Gazette Code' },
        { key: 'type', label: 'Type' },
        { key: 'view', label: 'View' }
      ]
    },
    createRecord: {
      "Depot Manager": {
        component: 'AddEventPermitDialogComponent',
        width: '75%'
      },
      // "Data Processing Officer": {
      //   component: 'AddEventPermitDialogComponent',
      //   width: '75%'
      // },
      "Excise Superintendent Officer": {
        component: 'AddEventPermitDialogComponent',
        width: '75%'
      },
      "Store Officer": {
        component: 'AddEventPermitDialogComponent',
        width: '75%'
      }
    }
  };

  public IndentManagementCancelICDC = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'retailerRegisterNumber', label: 'Retailer Register Number' },
        { key: 'retailer', label: 'Retailer' },
        { key: 'Date', label: 'Date Of Cancellation', type: 'date' },
        { key: 'status', label: 'Status' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'indentNumber', label: 'Indent Number' },
        { key: 'icdcNumber', label: 'ICDC Number' }
      ]
    },
    createRecord: {
      // "Depot Manager": {
      //   component: 'AddEventPermitDialogComponent',
      //   width: '75%'
      // }
    }
  };

  public IndentManagementAgedChallans = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'selectAll', label: 'Select All' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'challanNumber', label: 'Challan Number' },
        { key: 'challanAmount', label: 'Challan Amount' },
        { key: 'blockedDate', label: 'Blocked Date', type: 'date' },
        { key: 'status', label: 'status' },
      ]
    },
    createRecord: {
      // "Depot Manager": {
      //   component: 'AddEventPermitDialogComponent',
      //   width: '75%'
      // }
      // "Data Processing Officer": {
      //   component: 'AddEventPermitDialogComponent',
      //   width: '75%'
      // }
    }
  };
  public IndentManagementOldIndents = {
    columnsToDisplay: {
      "Licensee": [
        { key: 'no', label: 'S.No.' },
        { key: 'tpNo', label: 'Transport Permit', type: 'link', modal: { component: 'IcdcCaseBarcodeDialogComponent', width: '50%' } },
        { key: 'consignmentType', label: 'Consignment Type', type: 'link', modal: { component: 'IcdcDetailDialogComponent', width: '65%' } },
        { key: 'destnEntityId', label: 'Retailer Code' },
        { key: 'vehicleNo', label: 'Vehicle No', path: 'transporterDetails' },
        { key: 'validityDateTime', label: 'Validity Date', type: 'date' },
        {
          key: 'status', label: 'Status', icon: { success: ['InProgress'], yellow: ['Created'], warning: ['InTransit'], brown: ['Reached'], info: ['Delayed'], },
          success: ['ReachedInTime'], danger: ['NotReachedInTime'], modal: { component: 'IcdcCaseBarcodeDialogComponent', width: '65%' }
        },
      ]
    },
    createRecord: {
      
    }
  };

  public IndentManagementCFMSPayment = {
    columnsToDisplay: {
      "Retailer Challan": [
        { key: 'no', label: 'S.No.' },
        { key: 'challanDate', label: 'Challan Date', type: 'date' },
        { key: 'amount', label: 'Amount' },
        { key: 'paynow', label: 'Pay Now', type: 'customBtn',
          modal: { component: 'RetailerChallanPayDialogComponent', width: '50%', eqRule: ['Pay Now'] },
          bgColor: { success: ['Paid'], yellow: ['Pay Now'], info: ['Challan Generated']},
          onlyLink: ['Pay Now']
        },
        { key: 'customPrint', label: 'Print', type: 'customPrint' },
        // {
        //   key: 'status', label: 'Status', icon: { success: ['Completed'], yellow: ['Created'], info: ['PENDING']},
        // },
        {
          key: 'action', label: 'Action', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'RetailerChallanComponent', width: '40%' }, notEqRule: { key: 'status', value: 'PENDING' } },
            { icon: 'visibility', modal: { component: 'RetailerChallanComponent', width: '85%' }, notEqRule: { key: 'status', value: 'Created' } },
          ]
        }
      ]
    },
    createRecord: {
      "Retailer Challan": {
        component: 'RetailerChallanComponent',
        width: '40%'
      }
    }
  };

  public LabSamples = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'entityId', label: 'Supplier Code' },
        { key: 'referenceNo', label: 'Reference Number' },
        { key: 'ceCRNo', label:'CE/CR Number'},
        { key: 'sampleType', label: 'Sample Type' },
        { key: 'createdOn', label: 'Date', type: 'dateTime' },
        { key: 'testStatus', label: 'Status', modal: { component: 'OpenSamplesStatusDialogComponent', width: '75%' }, icon: {warning: ['Reviewed'], info: ['Tested'], yellow: ['Requested']}
          // icon: { info: ['Tested']} 
        }
       
      ]
    },
    createRecord: {
      "Distillery Chemist": {
        component: 'ManageSamplesDialogComponent',
        width: '85%'
      }
    }
  };

  public LabFailedSamples = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'referenceNo', label: 'Reference Number' },
        { key: 'exciseLab', label: 'Excise Lab' },
        { key: 'date', label: 'Date', type: 'dateTime' },
        { key: 'sampleType', label: 'Sample Type' },
        { key: 'status', label: 'Status', type: 'link', modal: { component: 'FailedSamplesDialogComponent', width: '65%' } }
      ]
    },
    createRecord: {
      "Distillery Chemist": {
        component: 'FailedSamplesDialogComponent',
        width: '75%'
      }
    }
  };

  public LabFinishedGoods = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'createdOn', label: 'Date', type: 'dateTime' },
        { key: 'referenceNo', label: 'Reference Number' },
        { key: 'sampleType', label: 'Sample Type' },
        {
          key: 'status', label: 'Status', modal: { component: 'OpenFinishedGoodsStatusDialogComponent', width: '75%' },
          icon: {
            type: 'pi pi-circle-on', style: 'font-size:12px;cursor:pointer;',
            success: ['Passed'], warning: ['Testing'], info: ['Tested']
          }
        }
      ]
    },
    createRecord: {
      "Distillery Chemist": {
        component: 'ManageFinishedGoodsDialogComponent',
        width: '75%'
      }
    }
  };

  public LabLabReports = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'createdOn', label: 'Date', type: 'date' },
        { key: 'referenceNo', label: 'Reference Number' },
        { key: 'ceCRNo', label: 'CE/CR Number' },
        { key: 'sampleType', label: 'Kind Of Sample' },
        { key: 'testStatus', label: 'Status', type: 'link', modal: { component: 'OpenSamplesStatusDialogComponent', width: '75%'},
        icon: {
          type: 'pi pi-circle-on', style: 'font-size:12px;cursor:pointer;',
          success: ['Passed'], warning: ['Testing'], info: ['Tested']
        }}
      ]
    },
    createRecord: {
      // ManageDepotLabReportsDialogComponent
    }
  };

  public LabBatchDeactivation = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'date', label: 'CE Number' },
        { key: 'referenceNo', label: 'Brand Code' },
        { key: 'crNo', label: 'CSegment' },
        { key: 'kindOfSample', label: 'Brand Name' },
        { key: 'date', label: 'Size' },
        { key: 'referenceNo', label: 'Batch Number' },
        { key: 'crNo', label: 'Strength' },
        { key: 'kindOfSample', label: 'Status' },
        { key: 'action', label: 'Action', type: 'link', success: ['Active'], danger: ['InActive'] }
      ]
    },
    createRecord: {
      // ManageDepotLabReportsDialogComponent
    }
  };

  public SampleAnalysisSamplestoChemicalExaminer = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'createdOn', label: 'Date', type: 'date' },
        { key: 'referenceNo', label: 'Reference Number' },
        { key: 'exciseLab', label: 'Excise Lab' },
        { key: 'entityId', label: 'Supplier' },
        { key: 'healCode', label: 'Heal Code' },
        { key: 'batchNumber', label: 'Batch Number' },
        { key: 'cases', label: 'Cases' },
        { key: 'bottles', label: 'bottles' },
        { key: 'action', label: 'Action' },
        { key: 'testStatus', label: 'Status' }
      ]
    },
    createRecord: {
      // "Distillery Chemist" : {
      //   modal: 'ManageSamplesDialogComponent',
      //   width: '85'
      // },
      "Data Processing Officer" : {
        component: 'ManageSamplesDialogComponent',
        width: '85%'
      }
    }
  };

  public SampleAnalysisSediment = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'date', label: 'Date', type: 'date' },
        { key: 'referenceNo', label: 'Reference Number' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'supplier', label: 'Supplier' },
        { key: 'productName', label: 'ProductName' },
        { key: 'batchNumber', label: 'Batch Number' },
        { key: 'cases', label: 'Cases' },
        { key: 'healCode', label: 'Heal Code' },
        { key: 'bottles', label: 'bottles' },
        { key: 'exciseLab', label: 'Excise Lab' },
        { key: 'status', label: 'Status' }
      ]
    },
    createRecord: {
      // "Distillery Chemist" : {
      //   modal: 'ManageSamplesDialogComponent',
      //   width: '85'
      // },
      // "Data Processing Officer" : {
      //   modal: 'ManageSamplesDialogComponent',
      //   width: '85'
      // }
    }
  };

  public SampleAnalysisStockonHold = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'date', label: 'Date', type: 'date' },
        { key: 'referenceNo', label: 'Reference Number' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'supplier', label: 'Supplier' },
        { key: 'productName', label: 'ProductName' },
        { key: 'batchNumber', label: 'Batch Number' },
        { key: 'cases', label: 'Cases' },
        { key: 'healCode', label: 'Heal Code' },
        { key: 'bottles', label: 'bottles' },
        { key: 'exciseLab', label: 'Excise Lab' },
        { key: 'status', label: 'Status' }
      ]
    },
    createRecord: {
      // "Distillery Chemist" : {
      //   modal: 'ManageSamplesDialogComponent',
      //   width: '85'
      // },
      // "Data Processing Officer" : {
      //   modal: 'ManageSamplesDialogComponent',
      //   width: '85'
      // }
    }
  };

  public SampleAnalysisLabReports = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'date', label: 'Date', type: 'date' },
        { key: 'referenceNo', label: 'Reference Number' },
        { key: 'crNo', label: 'CE/CR Number' },
        { key: 'kindOfSample', label: 'Kind Of Sample' },
        { key: 'action', label: 'Action' },
        { key: 'testStatus',label:'Status'}
      ]
    },
    createRecord: {
      // ManageDepotLabReportsDialogComponent
    }
  };

  public SpiritRegisterProcurement = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent Id' },
        { key: 'invoiceNo', label: 'Invoice Number' },
        { key: 'sourceEntityId', label: 'Source' },
        { key: 'destinationEntityId', label: 'Destination' },
        { key: 'createdOn', label: 'Created On', type: 'date' },
        { key: 'validityDate', label: 'Validity Date', type: 'date' },
        {
          key: 'status', label: 'Status', type: 'link',
          modal: { component: 'ProcurementStatusDialogComponent', width: '80%' }, icon: { type: 'pi pi-circle-on cursor-pointer', yellow: ['Requested'], warning: ['Reviewed'], info: ['Received'], success: ['Approved']}
        },
      ]
    },
    createRecord: {
      "Distillery Manager": {
        component: 'ManageProcurementDialogComponent',
        width: '75%',
      }
    }
  };

  public SpiritRegisterReceipts = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'supplierName', label: 'Supplier Name' },
        { key: 'date', label: 'Date of Receipt', type: 'date' },
        { key: 'invoiceNo', label: 'Invoice Number' },
        { key: 'invoiceAmount', label: 'Invoice Amount' },
        { key: 'qtyInBls', label: 'Qty As Per TP(BLs)' },
        { key: 'receiptType', label: 'Receipt Type' },
      ]
    },
    createRecord: {}
  };

  public SpiritRegisterRS = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'date', label: 'Date', type: 'date' },
        { key: 'openingBalBLs', label: 'Opening (In BLs)' },
        { key: 'openingBalPLs', label: 'Opening (In PLs)' },
        { key: 'receiptsBalBLs', label: 'Receipts (In BLs)' },
        { key: 'receiptsBalPLs', label: 'Receipts (In PLs)' },
        { key: 'totalInBls', label: 'Total (In BLs)' },
        { key: 'totalInPls', label: 'Total (In PLs)' },
        { key: 'dispatchBalBLs', label: 'Issues (In BLs)' },
        { key: 'dispatchBalPLs', label: 'Issues (In PLs)' },
        { key: 'storageLossInBls', label: 'Storage Loss (In BLs)' },
        { key: 'storageLossInPls', label: 'Storage Loss (In PLs)' },
        { key: 'closingBalBLs', label: 'Closing (In BLs)' },
        { key: 'closingBalPLs', label: 'Closing (In PLs)' },
      ]
    },
    createRecord: {
      "Distillery Manager": {
        component: 'ManageRsStockDialogComponent',
        width: '75%',
      },
      // "Distillery Chemist": {
      //   component: 'ManageRsRedestillationDialogComponent',
      //   width: '75%',
      // }
    }
  };

  public SpiritRegisterENA = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'date', label: 'Date', type: 'date' },
        { key: 'openingInBls', label: 'Opening (In BLs)' },
        { key: 'openingInPls', label: 'Opening (In PLs)' },
        { key: 'productionInBls', label: 'Production (In BLs)' },
        { key: 'productionInPls', label: 'Production (In PLs)' },
        { key: 'totalInBls', label: 'Total (In BLs)' },
        { key: 'totalInPls', label: 'Total (In PLs)' },
        { key: 'issuesInBls', label: 'Issues (In BLs)' },
        { key: 'issuesInPls', label: 'Issues (In PLs)' },
        { key: 'storageLossInBls', label: 'Storage Loss (In BLs)' },
        { key: 'storageLossInPls', label: 'Storage Loss (In PLs)' },
        { key: 'closingInBls', label: 'Closing (In BLs)' },
        { key: 'closingInPls', label: 'Closing (In PLs)' },
      ]
    },
    createRecord: {
      "Distillery Manager": {
        component: 'ManageEnaStockDialogComponent',
        width: '75%',
      },
      // "Distillery Chemist": {
      //   component: 'ManageEnaBlendingDialogComponent',
      //   width: '75%',
      // }
    }
  };

  public SpiritRegisterBlend = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'date', label: 'Date', type: 'date' },
        { key: 'openingInBls', label: 'Opening (In BLs)' },
        { key: 'openingInPls', label: 'Opening (In PLs)' },
        { key: 'brand', label: 'Brand' },
        { key: 'category', label: 'Category' },
        { key: 'issuedInBls', label: 'Issued ENA (In BLs)' },
        { key: 'issuedInPls', label: 'Issued ENA (In PLs)' },
        { key: 'issuedMaltInBls', label: 'Issued Malt (In BLs)' },
        { key: 'issuedMaltInPls', label: 'Issued Malt (In PLs)' },
        { key: 'otherSpiritInBls', label: 'Other Spirit (In BLs)' },
        { key: 'otherSpiritInPls', label: 'Other Sprit (In PLs)' },
        { key: 'blendPreparedInBls', label: 'Blend Prepared (In BLs)' },
        { key: 'blendPreparedInPls', label: 'Blend Prepared (In PLs)' },
        { key: 'blendUsedInBls', label: 'Blend Used (In BLs)' },
        { key: 'blendUsedInPls', label: 'Blend Used (In PLs)' },
        { key: 'lossInBls', label: 'Loss (In BLs)' },
        { key: 'lossInPls', label: 'Loss (In PLs)' },
        { key: 'closingInBls', label: 'Closing (In BLs)' },
        { key: 'closingInPls', label: 'Closing (In PLs)' },
      ]
    },
    createRecord: {
      "Distillery Manager": {
        component: 'ManageBlendStockDialogComponent',
        width: '75%',
      },
      // "Distillery Chemist": {
      //   component: 'ManageBlendForBottlingDialogComponent',
      //   width: '75%',
      // }
    }
  };

  public ShipmentsDistilleryShipment = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'tpNo', label: 'Transport Permit', type: 'link', modal: { component: 'ManageTpDetailsComponent', width: '65%' } },
        { key: 'consignmentType', label: 'Consignment Type' },
        { key: 'sourceEntityName', label: 'From' },
        { key: 'destnEntityName', label: 'To' },
        { key: 'displayDate', label: 'Date', type: 'date' },
        { key: 'route', label: 'TP Route' },
        // { key: 'vtsReport', label: 'VTS Report' },
        { key: 'evcNo', label: 'EVC' },
        { key: 'grnNO', label: 'GRN' }
      ]
    },
    createRecord: {}
  };
  public ShipmentsBreweryShipment = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'tpNo', label: 'Transport Permit', type: 'link', modal: { component: 'ManageTpDetailsComponent', width: '65%' } },
        { key: 'consignmentType', label: 'Consignment Type' },
        { key: 'sourceEntityName', label: 'From' },
        { key: 'destnEntityName', label: 'To' },
        { key: 'displayDate', label: 'Date', type: 'date' },
        { key: 'route', label: 'TP Route' },
        // { key: 'vtsReport', label: 'VTS Report' },
        { key: 'evcNo', label: 'EVC' },
        { key: 'grnNO', label: 'GRN' }
      ]
    },
    createRecord: {}
  };

  public ShipmentsDepotShipment = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'sourceEntityName', label: 'Depot Name' },
        { key: 'icdcNumber', label: 'DC/ICDC Number', type: 'link', modal: { component: 'ShowIcdcDetailsDialogComponent', width: '65%' } },
        { key: 'tpNo', label: 'Transport Permit', type: 'link', modal: { component: 'ShowTpDetailsDialogComponent', width: '65%' } },
        { key: 'createdOn', label: 'Created On', type: 'date' }
      ]
    },
    createRecord: {}
  };

  public ShipmentsOFS = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'ofsCode', label: 'OFS Number', type: 'link', modal: { component: 'OpenOfsDialogComponent', width: '70%' }, copy: true },
        { key: 'createdOn', label: 'OFS Created Date', type: 'dateTime' },
        { key: 'validityDate', label: 'OFS Validity Date', type: 'dateTime' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'supplierCode', label: 'Supplier Code' },
        { key: 'depotCode', label: 'Depot' },
        { key: 'status', label: 'status', icon: { success: ['Approved'], black: ['Dispatched'], danger: ['Rejected'],warning: ['Requested'], info: ['Reviewed'], yellow: ['InTransit'] } }
      ],
      "Brewery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'ofsCode', label: 'OFS Number', type: 'link', modal: { component: 'OpenOfsDialogComponent', width: '70%' }, copy: true },
        { key: 'createdOn', label: 'OFS Created Date', type: 'dateTime' },
        { key: 'validityDate', label: 'OFS Validity Date', type: 'dateTime' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'supplierCode', label: 'Supplier Code' },
        { key: 'depotCode', label: 'Depot' },
        // { key: 'status', label: 'status', icon: { success: ['Approved'], black: ['Dispatched'], danger: ['Rejected'],warning: ['Requested'], info: ['Reviewed'], yellow: ['InTransit'] } }
        { key: 'status', label: 'Status', modal: { component: 'OfsPurchaseOrderDialogComponent' }, icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px', success: ['Approved'], warning: ['Requested'], info: ['Reviewed'], danger: ['Rejected'], yellow: ['InTransit']} }
      ],
      "NonLocal Brewery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'ofsCode', label: 'OFS Number', type: 'link', modal: { component: 'OpenOfsDialogComponent', width: '70%' }, copy: true },
        { key: 'createdOn', label: 'OFS Created Date', type: 'dateTime' },
        { key: 'validityDate', label: 'OFS Validity Date', type: 'dateTime' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'supplierCode', label: 'Supplier Code' },
        { key: 'depotCode', label: 'Depot' },
        // { key: 'status', label: 'status', icon: { success: ['Approved'], black: ['Dispatched'], danger: ['Rejected'],warning: ['Requested'], info: ['Reviewed'], yellow: ['InTransit'] } }
        { key: 'status', label: 'Status', modal: { component: 'OfsPurchaseOrderDialogComponent' }, icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px', success: ['Approved'], warning: ['Requested'], info: ['Reviewed'], danger: ['Rejected'], yellow: ['InTransit'] } },
        { key: 'importStatus' , label:'Import Status'}
      ],
      "NonLocal Supplier Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'ofsCode', label: 'OFS Number', type: 'link', modal: { component: 'OpenOfsDialogComponent', width: '70%' }, copy: true },
        { key: 'createdOn', label: 'OFS Created Date', type: 'dateTime' },
        { key: 'validityDate', label: 'OFS Validity Date', type: 'dateTime' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'supplierCode', label: 'Supplier Code' },
        { key: 'depotCode', label: 'Depot' },
        { key: 'status', label: 'Status', modal: { component: 'OfsPurchaseOrderDialogComponent' }, icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px', success: ['Approved'], warning: ['Requested'], info: ['Reviewed'], danger: ['Rejected'], yellow: ['InTransit'] } },
        { key: 'importStatus' , label:'Import Status'}
      ]
    },
    createRecord: {
      // "NonLocal Supplier Manager": {
      //   component: 'ManageImportIndentComponent',
      //   width: '75%'
      // }, 
      // "NonLocal Brewery Manager": {
      //   component: 'ManageImportIndentComponent',
      //   width: '75%'
      // }, 
    }
  };


  public ShipmentsImport = {

    columnsToDisplay: {
      "NonLocal Brewery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'ofsCode', label: 'OFS Number', type: 'link', modal: { component: 'OpenOfsDialogComponent', width: '70%' }, copy: true },
        { key: 'indentAddedDate', label: 'Import Created Date', type: 'dateTime' },
        { key: 'createdOn', label: 'OFS Created Date', type: 'dateTime' },
        { key: 'validityDate', label: 'OFS Validity Date', type: 'dateTime' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'supplierCode', label: 'Supplier Code' },
        { key: 'depotCode', label: 'Depot' },
        { key: 'status', label: 'Status', modal: { component: 'ManageImportIndentComponent' }, icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px', success: ['Approved'], warning: ['Requested'], info: ['Reviewed'], danger: ['Rejected'], yellow: ['InTransit'] } },
        { key: 'importStatus' , label:'Import Status'}
      ],
      "NonLocal Supplier Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'ofsCode', label: 'OFS Number', type: 'link', modal: { component: 'OpenOfsDialogComponent', width: '70%' }, copy: true },
        { key: 'indentAddedDate', label: 'Import Created Date', type: 'dateTime' },
        { key: 'createdOn', label: 'OFS Created Date', type: 'dateTime' },
        { key: 'validityDate', label: 'OFS Validity Date', type: 'dateTime' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'supplierCode', label: 'Supplier Code' },
        { key: 'depotCode', label: 'Depot' },
        { key: 'status', label: 'Status', modal: { component: 'ManageImportIndentComponent' }, icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px', success: ['Approved'], warning: ['Requested'], info: ['Reviewed'], danger: ['Rejected'], yellow: ['InTransit'] } },
        { key: 'importStatus' , label:'Import Status'}
      ]
    },
    createRecord: {
      "NonLocal Supplier Manager": {
        component: 'ManageImportIndentComponent',
        width: '75%'
      }, 
      "NonLocal Brewery Manager": {
        component: 'ManageImportIndentComponent',
        width: '75%'
      }, 
    }
  };

  public ShipmentsImportChallans = {
      columnsToDisplay: {
      "NonLocal Supplier Manager": [
        { key: 'no', label: 'S.No.' },
        // { key: 'challanType', label: 'Transaction Type' },
        // { key: 'ofsCode' , label:'OFS Number'},
        { key: 'createdOn', label:'Created Date',type:'date'},
        { key: 'depositedAmount', label: 'Credited Amount'},
        { key: 'challanDate', label:'Transaction Date',type:'date'},
        { key: 'challanType', label: 'Challan Type'},

      ],
      "NonLocal Brewery Manager": [
        { key: 'no', label: 'S.No.' },
        // { key: 'challanType', label: 'Transaction Type' },
        // { key: 'ofsCode' , label:'OFS Number'},
        { key: 'createdOn', label:'Created Date',type:'date'},
        { key: 'depositedAmount', label: 'Credited Amount'},
        { key: 'challanDate', label:'Transaction Date',type:'date'},
        { key: 'challanType', label: 'Challan Type'},
      ],
    },
    createRecord: { }
  };


  public ShipmentsRejectedIndents = {
    columnsToDisplay: {
      "NonLocal Supplier Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'ofsCode', label: 'OFS Number', type: 'link', modal: { component: 'OpenOfsDialogComponent', width: '70%' } },
        { key: 'indentAddedDate', label: 'Import Created Date', type: 'dateTime' },
        { key: 'createdOn', label: 'OFS Created Date', type: 'dateTime' },
        { key: 'validityDate', label: 'OFS Validity Date', type: 'dateTime' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'supplierCode', label: 'Supplier Code' },
        { key: 'depotCode', label: 'Depot' },
        { key: 'refundAmount', label:'Refund Amount'},
        { key: 'importStatus' , label:'Import Status'}
      ],
      "NonLocal Brewery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'ofsCode', label: 'OFS Number', type: 'link', modal: { component: 'OpenOfsDialogComponent', width: '70%' } },
        { key: 'indentAddedDate', label: 'Import Created Date', type: 'dateTime' },
        { key: 'createdOn', label: 'OFS Created Date', type: 'dateTime' },
        { key: 'validityDate', label: 'OFS Validity Date', type: 'dateTime' },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'supplierCode', label: 'Supplier Code' },
        { key: 'depotCode', label: 'Depot' },
        { key: 'refundAmount', label:'Refund Amount'},
        { key: 'importStatus' , label:'Import Status'}
      ],
    },
    createRecord: { }
  };


  public ShipmentsAddChallan = {
    columnsToDisplay: {
      "NonLocal Supplier Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'challanType', label: 'Transaction Type' },
        { key: 'initialAmount', label: 'Opening Balance',type:'decimal'},
        { key: 'depositedAmount', label: 'Credited Amount',type:'decimal'},
        { key: 'refundAmount', label: 'Refund Amount',type:'decimal'},
        { key: 'amountUsed', label: 'Debited Amount',type:'decimal'},
        { key: 'remainingAmount', label: 'Closing Balance',type:'decimal'},
      ],
    },
    createRecord: {
      "NonLocal Supplier Manager": {
        component: 'ManageChallanDialogComponent',
        width: '50%'
      },
      "NonLocal Brewery Manager": {
        component: 'ManageChallanDialogComponent',
        width: '50%'
      }, 
    }
  };

  public ShipmentsSupplierShipments = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'consignmentType', label: 'Consignment Type' },
        { key: 'asnId', label: 'ASN ID', type: 'link', modal: { component: 'AsnDetailsForSupplierShipmentDialogComponent', width: '50%' } },
        { key: 'dispatchDateTime', label: 'ASN Date', type: 'date' },
        { key: 'sourceEntityId', label: 'Supplier' },
        {
          key: 'action', label: 'Action', type: 'link',
          action: ['OFS','Invoice', 'Shipment', 'Gate Pass', 'Check List', 'EVC', 'SRA(GRN)', 'Receive'],
          modal: [
          { component: 'SupplierOfsInfoDialogComponent', width: '75%', actionLink: 'OFS' },
          { component: 'SupplierInvoiceInfoDialogComponent', width: '75%', actionLink: 'Invoice' },
          { component: 'SupplierShipmentShipmentInfoDialogComponent', width: '75%', actionLink: 'Shipment' },
          { component: 'SupplierGatepassInfoDialogComponent', width: '70%', actionLink: 'Gate Pass' },
          { component: 'SupplierChecklistInfoDialogComponent', width: '75%', actionLink: 'Check List' },
          { component: 'SupplierEvcInfoDialogComponent', width: '70%', actionLink: 'EVC' },
          { component: 'ApnDetailsForSupplierDialogComponent', width: '75%', actionLink: 'SRA(GRN)' },
          { component: 'SupplierChecklistInfoDialogComponent', width: '75%', actionLink: 'Receive' },
          ]
        },
        {
          key: 'status', label: 'status', modal: { component: 'SupplierShipmentShipmentInfoDialogComponent', width: '75%' },
          icon: {
            type: 'pi pi-circle-on',
            success: ['InProgress'], info: ['Delayed','Reviewed'], black: ['Delivered','TPReceived'], warning: ['InTransit'], purple: ['TPScanned'], danger: ['Rejected'], yellow: ['Created'], brown: ['Reached']
          }
        },
        {
          key: 'action1', label: 'Chemical Reports', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'CaseBarcodeDialogComponent', width: '45%' } },
          ]
        }
      ],
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'consignmentType', label: 'Consignment Type' },
        { key: 'asnId', label: 'ASN ID', type: 'link', modal: { component: 'AsnDetailsForSupplierShipmentDialogComponent', width: '50%' } },
        { key: 'dispatchDateTime', label: 'ASN Date', type: 'date' },
        { key: 'sourceEntityId', label: 'Supplier' },
        {
          key: 'action', label: 'Action', type: 'link',
          action: ['OFS','Invoice', 'Shipment', 'Gate Pass', 'Check List', 'EVC', 'SRA(GRN)', 'Receive'],
          modal: [
          { component: 'SupplierOfsInfoDialogComponent', width: '75%', actionLink: 'OFS' },
          { component: 'SupplierInvoiceInfoDialogComponent', width: '75%', actionLink: 'Invoice' },
          { component: 'SupplierShipmentShipmentInfoDialogComponent', width: '75%', actionLink: 'Shipment' },
          { component: 'SupplierGatepassInfoDialogComponent', width: '70%', actionLink: 'Gate Pass' },
          { component: 'SupplierChecklistInfoDialogComponent', width: '75%', actionLink: 'Check List' },
          { component: 'SupplierEvcInfoDialogComponent', width: '70%', actionLink: 'EVC' },
          { component: 'ApnDetailsForSupplierDialogComponent', width: '75%', actionLink: 'SRA(GRN)' },
          { component: 'SupplierChecklistInfoDialogComponent', width: '75%', actionLink: 'Receive' },
          ]
        },
        {
          key: 'status', label: 'status', modal: { component: 'CaseBarcodeDialogComponent', width: '75%' },
          icon: {
            type: 'pi pi-circle-on',
            success: ['InProgress'], info: ['Delayed','Reviewed'], black: ['Delivered','TPReceived'], warning: ['InTransit'], purple: ['TPScanned'], danger: ['Rejected'], yellow: ['Created'], brown: ['Reached']
          }
        },
        {
          key: 'action1', label: 'Chemical Reports', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'CaseBarcodeDialogComponent', width: '45%' } },
          ]
        }
      ]
    },
    createRecord: {
      /* "Depot Manager": {
        component: 'SupplierAdvancedInboundReportsDialogComponent',
        width: '50%'
      }, 
      "Excise Superintendent Officer": {
        component: 'SupplierAdvancedInboundReportsDialogComponent',
        width: '50%'
      },
      "Data Processing Officer": {
        component: 'SupplierAdvancedInboundReportsDialogComponent',
        width: '70%'
      }*/
    }
  };

  public ShipmentsICDC = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'icdcCode', label: 'ICDC Code', type: 'link', modal: { component: 'IcdcInfoDialogComponent', width: '80%' } },
        { key: 'indentId', label: 'Indent Number' },
        { key: 'retailerCode', label: 'Retailer' },
        { key: 'depotCode', label: 'Depot' },
        { key: 'updatedDateTime', label: 'Approved On', type:'date'},
        { key: 'status', label: 'Status', icon: { success: ['Approved'], info: ['InTransit'], purple: ['InProgress'], danger: ['Rejected'], warning: ['Dispatched'], yellow: ['Created']}, modal: { component: 'IcdcInfoDialogComponent', width: '80%' } }
      ]
    },
    createRecord: {
      // "Depot Manager": {
      //   component: 'ManageIcdcDialogComponent',
      //   width: '60%'
      // },
      // "Excise Superintendent Officer": {
      //   component: 'ManageIcdcDialogComponent',
      //   width: '60%'
      // },
      // "Data Processing Officer": {
      //   component: 'ManageIcdcDialogComponent',
      //   width: '60%'
      // }
    }
  };

  public ShipmentsLicenseeShipments = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'tpNo', label: 'Transport Permit', type: 'link', modal: { component: 'IcdcCaseBarcodeDialogComponent', width: '50%' } },
        { key: 'consignmentType', label: 'Consignment Type', type: 'link', modal: { component: 'IcdcDetailDialogComponent', width: '65%' } },
        { key: 'destnEntityId', label: 'Retailer Code' },
        { key: 'vehicleNo', label: 'Vehicle No', path: 'transporterDetails' },
        { key: 'validityDateTime', label: 'Validity Date', type: 'date' },
        {
          key: 'edit', label: 'Packing List', action: 'edit', actions: [
            {
              icon: 'edit', modal: { component: 'IcdcPackingListDialogComponent', width: '80%' }, notEqRule: { key: 'status', value: 'Rejected' }
            },
          ]
        },
        { key: 'printStatus', label: 'Print Status' },
        {
          key: 'status', label: 'Status', icon: { success: ['InProgress','TPReceived'], yellow: ['Created'], warning: ['InTransit'], brown: ['Reached','Revalidated'], info: ['Delayed'],danger:['Rejected'], },
          success: ['ReachedInTime'], danger: ['NotReachedInTime'], modal: { component: 'IcdcCaseBarcodeDialogComponent', width: '65%' }, notEqRule: { key: 'status', value: 'Rejected' }
        },
        {
          key: 'action', label: 'Action', action: 'edit', actions: [
            {
              icon: 'edit', modal: { component: 'ManageTpRequestDialogComponent', width: '80%' }, notEqRule: { key: 'status', value: 'Rejected' }
            },
          ]
        },
      ]
    },
    createRecord: {
      // "Depot Manager": {
      //   component: 'ManageTpRequestDialogComponent',
      //   width: '50%'
      // },
      // "Excise Superintendent Officer": {
      //   component: 'ManageTpRequestDialogComponent',
      //   width: '50%'
      // },
      // "Data Processing Officer": {
      //   component: 'ManageTpRequestDialogComponent',
      //   width: '60%'
      // },
    }
  };


  public ShipmentsManualShipments = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'tpNo', label: 'Transport Permit', type: 'link', modal: { component: 'IcdcCaseBarcodeDialogComponent', width: '50%' } },
        { key: 'consignmentType', label: 'Consignment Type', type: 'link', modal: { component: 'IcdcDetailDialogComponent', width: '65%' } },
        { key: 'destnEntityId', label: 'Retailer Code' },
        { key: 'vehicleNo', label: 'Vehicle No', path: 'transporterDetails' },
        { key: 'validityDateTime', label: 'Validity Date', type: 'date' },
        {
          key: 'edit', label: 'Packing List', action: 'edit', actions: [
            {
              icon: 'edit', modal: { component: 'IcdcPackingListDialogComponent', width: '80%' }, notEqRule: { key: 'status', value: 'Rejected' }
            },
          ]
        },
        { key: 'printStatus', label: 'Print Status' },
        {
          key: 'status', label: 'Status', icon: { success: ['InProgress','TPReceived'], yellow: ['Created'], warning: ['InTransit'], brown: ['Reached','Revalidated'], info: ['Delayed'],danger:['Rejected'], },
          success: ['ReachedInTime'], danger: ['NotReachedInTime'], modal: { component: 'IcdcCaseBarcodeDialogComponent', width: '65%' }, notEqRule: { key: 'status', value: 'Rejected' }
        },
        {
          key: 'action', label: 'Action', action: 'edit', actions: [
            {
              icon: 'edit', modal: { component: 'ManageTpRequestDialogComponent', width: '80%' }, notEqRule: { key: 'status', value: 'Rejected' }
            },
          ]
        },
      ]
    },
    createRecord: {
      // "Depot Manager": {
      //   component: 'ManageTpRequestDialogComponent',
      //   width: '50%'
      // },
      // "Excise Superintendent Officer": {
      //   component: 'ManageTpRequestDialogComponent',
      //   width: '50%'
      // },
      // "Data Processing Officer": {
      //   component: 'ManageTpRequestDialogComponent',
      //   width: '60%'
      // },
    }
  };

  public ShipmentsGeneratedList = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'destnEntityId', label: 'Retailer Code',path:'consignment' },
        // { key: 'shopName', label: 'Shop Name' },
        { key: 'retailerName', label: 'Retailer Name' },
        // { key: 'retailerPhoneNumber', label: 'Retailer Phone Number' },
        { key: 'tpNo', label: 'TP Number' },
        { key: 'icdcCode', label: 'ICDC Number' ,path:'consignment'},
        { key: 'indentId', label: 'Indent Value',path:'consignment' },
      ]
    },
    createRecord: {}
  };

  public ShipmentsStockReturnDrainOut = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent Number', path: 'consignment'},
        { key: 'icdcCode', label: 'Invoice Number', path: 'consignment'},
        { key: 'destnEntityId', label: 'Distillery/Supplier Name' },
        { key: 'consignmentType', label: 'Type' },
        { key: 'createdOn', label: 'Indented Date', type: 'date' },
        {
          key: 'edit', label: 'Packing List', action: 'edit', actions: [
            {
              icon: 'edit', modal: { component: 'StockReturnIcdcPackingListDialogComponent', width: '80%' }
            },
          ]
        },
        {
          key: 'status', label: 'Status', icon: { success: ['InProgress'], yellow: ['Created'], warning: ['InTransit'], brown: ['Reached'], info: ['Delayed'], },
          success: ['ReachedInTime'], danger: ['NotReachedInTime'], modal: { component: 'IcdcCaseBarcodeDialogComponent', width: '65%' }
        },
        { key: 'indentPrint', label: 'Indent Print' },
        { key: 'caseBarcodeList', label: 'Case Barcode/Healcode List' },
      ]
    },
    createRecord: {}
  };
  public ShipmentsStockReturntoDistillery = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent Number', path: 'consignment'},
        { key: 'consignmentType', label: 'Consignment Type' },
        { key: 'sourceEntityId', label: 'Depot' },
        { key: 'destnEntityId', label: 'Distillery/Supplier Name' },
        { key: 'createdOn', label: 'Date', type: 'date' },
        {
          key: 'edit', label: 'Packing List', action: 'edit', actions: [
            {
              icon: 'edit', modal: { component: 'StockReturnIcdcPackingListDialogComponent', width: '80%' }
            },
          ]
        },
        {
          key: 'status', label: 'Status', icon: { success: ['InProgress'], yellow: ['Created'], warning: ['InTransit'], brown: ['Reached'], info: ['Delayed'], },
          success: ['ReachedInTime'], danger: ['NotReachedInTime'], modal: { component: 'IcdcCaseBarcodeDialogComponent', width: '65%' }
        },
        { key: 'indentPrint', label: 'Indent Print' },
        // { key: 'caseBarcodeList', label: 'Case Barcode/Healcode List' },
      ]
    },
    createRecord: {}
  };
  public ShipmentsUpdateShipments = {
    columnsToDisplay: {
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'tpNo', label: 'TP Number'},
        { key: 'retailerId', label: 'Retailer Code' },
        { key: 'retailerName', label: 'Retailer Name' },
        //{ key: 'retCreatedOn', label: 'Indented Date', type: 'date' },
        { key: 'createdOn', label: 'TP Date', type: 'date' },
        { key: 'indentType', label: 'Indent Type', type: 'staticValue', value: 'online(Cases)' },
        {
          key: 'status', label: 'Action', type: 'link',
          modal: { component: 'OpenActionUpdateShipmentsDialogComponent', width: '80%' }, icon: {
            type: 'pi pi-circle-on cursor-pointer',
            style: 'font-size:12px;cursor:pointer;', success: ['InProgress'], warning: ['Created'], danger: ['In-Active']
          }
        },
      ]
    },
    createRecord: {}
  };
  public ShipmentsUpdateBarShipments = {
    columnsToDisplay: {
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'tpNo', label: 'TP Number'},
        { key: 'retailerId', label: 'Retailer Code' },
        { key: 'retailerName', label: 'Retailer Name' },
        //{ key: 'retCreatedOn', label: 'Indented Date', type: 'date' },
        { key: 'createdOn', label: 'TP Date', type: 'date' },
        { key: 'indentType', label: 'Indent Type', type: 'staticValue', value: 'online(Cases)' },
        {
          key: 'status', label: 'Action', type: 'link',
          modal: { component: 'OpenActionUpdateShipmentsDialogComponent', width: '80%' }, icon: {
            type: 'pi pi-circle-on cursor-pointer',
            style: 'font-size:12px;cursor:pointer;', success: ['InProgress'], warning: ['Created'], danger: ['In-Active']
          }
        },
      ]
    },
    createRecord: {}
  };

  public ShipmentsUpdateICDC = {
    columnsToDisplay: {
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'icdcCode', label: 'ICDC Number'},
        { key: 'retailerId', label: 'Retailer Code' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'createdOn', label: 'Icdc Date', type: 'date' },
        {
          key: 'status', label: 'Action', type: 'link',
          modal: { component: 'OpenActionUpdateIndentDialogComponent', width: '75%' }, icon: {
            type: 'pi pi-circle-on cursor-pointer',
            style: 'font-size:12px;cursor:pointer;', success: ['Approved'], yellow: ['Created'], warning: ['Active'], danger: ['In-Active']
          }
        },
      ]
    },
    createRecord: {}
  };

  public ShipmentsShipments = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'tpNo', label: 'Transport Permit', type: 'link', modal: { component: 'CaseBarcodeDialogComponent', width: '74%' } },
        { key: 'consignmentType', label: 'Consignment Type', type: 'link', modal: { component: 'OfsDetailsDialogComponent', width: '65%' } },
        { key: 'route', label: 'View Route' },
        { key: 'destnEntityId', label: 'Depot Destination' },
        { key: 'vehicleNo', label: 'Vehicle No', path: 'transporterDetails' },
        { key: 'validityDateTime', label: 'Validity Date', type: 'dateTime' },
        {
          key: 'packingList', label: 'Packing List', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'OfsDetailsDialogComponent', width: '75%' } },
          ]
        },
        // { key: 'evc', label: 'EVC' },
        // { key: 'grn', label: 'GRN' },
        { key: 'evcNo', label: 'EVC', type: 'link', modal: { component: 'SupplierEvcInfoDialogComponent', width: '75%' } },
        { key: 'grnNO', label: 'GRN', type: 'link', modal: { component: 'ApnDetailsForSupplierDialogComponent', width: '75%' } },
        { key: 'status', label: 'Status', modal: { component: 'CaseBarcodeDialogComponent',  width: '75%' }, icon: { type: 'pi pi-circle-on cursor-pointer',  success: ['ReachedInTime', 'InProgress','TPReceived'], yellow: ['Created'], brown: ['Reached'], warning: ['Reviewed','InTransit'], black: ['Delivered'], info: ['Delayed'], purple: ['TPScanned'], danger: ['NotReachedInTime'] } },
        {
          key: 'action', label: 'Action', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'ManageTpRequestDialogComponent', width: '75%' }, notEqRule: { key: 'status', value: 'InUse' } },
          ]
        }
      ],
      "NonLocal Supplier Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'ipNo', label: 'Import Permit', type: 'link', modal: { component: 'IpStatusDialogComponent', width: '80%' } },
        { key: 'consignmentType', label: 'Consignment Type', type: 'link', modal: { component: 'OfsDetailsDialogComponent', width: '65%' } },
        { key: 'route', label: 'View Route' },
        { key: 'destnEntityId', label: 'Depot Destination' },
        { key: 'vehicleNo', label: 'Vehicle No', path: 'transporterDetails' },
        { key: 'validityDate', label: 'Validity Date', type: 'date' },
        // {
        //   key: 'packingList', label: 'Packing List', action: 'edit', actions: [
        //     { icon: 'edit', modal: { component: 'OfsDetailsDialogComponent', width: '75%' } },
        //   ]
        // },
        { key: 'evc', label: 'EVC' },
        { key: 'grn', label: 'GRN' },
        { key: 'status', label: 'Status', modal: { component: 'IpStatusDialogComponent', width: '80%'}, icon: { success: ['ReachedInTime', 'InProgress', 'ReachedInTime'], yellow: ['Created'], brown: ['Reached'], warning: ['Reviewed','InTransit'], black: ['Delivered'], info: ['Delayed'], purple: ['TPScanned'], danger: ['NotReachedInTime','NotReachedInTime']} },
        {
          key: 'action', label: 'Action', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'ManageTpRequestDialogComponent', width: '75%' }, notEqRule: { key: 'status', value: 'InUse' } },
          ]
        }
      ],
      "Brewery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'btpNo', label: 'Transport Permit', type: 'link', modal: { component: 'CaseBarcodeDialogComponent', width: '74%' } },
        { key: 'consignmentType', label: 'Consignment Type', type: 'link', modal: { component: 'OfsDetailsDialogComponent', width: '65%' } },
        { key: 'route', label: 'View Route' },
        { key: 'destnEntityId', label: 'Depot Destination' },
        { key: 'vehicleNo', label: 'Vehicle No', path: 'transporterDetails' },
        { key: 'validityDateTime', label: 'Validity Date', type: 'dateTime' },
        {
          key: 'packingList', label: 'Packing List', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'OfsDetailsDialogComponent', width: '75%' } },
          ]
        },
        // { key: 'evc', label: 'EVC' },
        // { key: 'grn', label: 'GRN' },
        { key: 'evcNo', label: 'EVC', type: 'link', modal: { component: 'SupplierEvcInfoDialogComponent', width: '75%' } },
        { key: 'grnNO', label: 'GRN', type: 'link', modal: { component: 'ApnDetailsForSupplierDialogComponent', width: '75%' } },
        { key: 'status', label: 'Status', modal: { component: 'CaseBarcodeDialogComponent', width: '75%' }, icon: { type: 'pi pi-circle-on cursor-pointer',  success: ['ReachedInTime', 'InProgress'], yellow: ['Created'], brown: ['Reached'], warning: ['Reviewed','InTransit'], black: ['Delivered'], info: ['Delayed'], purple: ['TPScanned'], danger: ['NotReachedInTime'] } },
        {
          key: 'action', label: 'Action', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'ManageTpRequestDialogComponent', width: '75%' }, notEqRule: { key: 'status', value: 'InUse' } },
          ]
        }
      ],
      "NonLocal Brewery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'bipNo', label: 'Transport Permit', type: 'link', modal: { component: 'CaseBarcodeDialogComponent', width: '74%' } },
        { key: 'consignmentType', label: 'Consignment Type', type: 'link', modal: { component: 'OfsDetailsDialogComponent', width: '65%' } },
        { key: 'route', label: 'View Route' },
        { key: 'destnEntityId', label: 'Depot Destination' },
        { key: 'vehicleNo', label: 'Vehicle No', path: 'transporterDetails' },
        { key: 'validityDateTime', label: 'Validity Date', type: 'dateTime' },
        {
          key: 'packingList', label: 'Packing List', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'OfsDetailsDialogComponent', width: '75%' } },
          ]
        },
        // { key: 'evc', label: 'EVC' },
        // { key: 'grn', label: 'GRN' },
        { key: 'evcNo', label: 'EVC', type: 'link', modal: { component: 'SupplierEvcInfoDialogComponent', width: '75%' } },
        { key: 'grnNO', label: 'GRN', type: 'link', modal: { component: 'ApnDetailsForSupplierDialogComponent', width: '75%' } },
        { key: 'status', label: 'Status', modal: { component: 'CaseBarcodeDialogComponent', width: '75%' }, icon: { type: 'pi pi-circle-on cursor-pointer',  success: ['ReachedInTime', 'InProgress'], yellow: ['Created'], brown: ['Reached'], warning: ['Reviewed','InTransit'], black: ['Delivered'], info: ['Delayed'], purple: ['TPScanned'], danger: ['NotReachedInTime'] } },
        {
          key: 'action', label: 'Action', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'ManageTpRequestDialogComponent', width: '75%' }, notEqRule: { key: 'status', value: 'InUse' } },
          ]
        }
      ],
    },
    createRecord: {
      "Distillery Manager": {
        component: 'ManageTpRequestDialogComponent',
        width: '75%'
      },
      "NonLocal Supplier Manager": {
        component: 'ManageIpRequestDialogComponent',
        width: '75%'
      },
      "Brewery Manager": {
        component: 'ManageTpRequestDialogComponent',
        width: '75%'
      },
      "NonLocal Brewery Manager": {
        component: 'ManageTpRequestDialogComponent',
        width: '75%'
      }
    }
  };

  public ShipmentsTPVehicleAccident = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'tpNo', label: 'TP No' },
        { key: 'newVehicleNo', label: 'New Vehicle Number' },
        { key: 'oldVehicleNo', label: 'Old Vehicle No' },
        { key: 'depot', label: 'Depot' },
        { key: 'firNo', label: 'FIR No.' },
        { key: 'status', label: 'status' }
      ]
    },
    createRecord: {
      "Distillery Officer": {
        component: 'ManageTpVehicleAccidentDialogComponent',
        width: '65%'
      }
    }
  };

  public ShipmentsRepack = {
    //  Items Codes | Name | EQty (Indent) | SQty (Scanned) 
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'itemCode', label: 'Items Codes' },
        { key: 'name', label: 'Name' },
        { key: 'eQty', label: 'EQty (Indent)' },
        { key: 'sQty', label: 'SQty (Scanned)' },
      ]
    },
    createRecord: {
      "Security Officer": {
        component: 'ManageRepackingDialogComponent',
        width: '50%'
      },
      /* "Distillery Manager": {
        component: 'ManageRouteDialogComponent',
        width: '75%'
      } */
    }
  };

  public ShipmentsRoute = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'routeName', label: 'Name' },
        { key: 'routeVia', label: 'Route' },
        { key: 'sourceLocation', label: 'Source Location' },
        { key: 'destinationLocation', label: 'Destination Location' },
      ]
    },
    createRecord: {
      "Distillery Manager": {
        component: 'ManageRouteDialogComponent',
        width: '75%'
      },
      "Data Processing Officer": {
        component: 'ManageRouteDpoDialogComponent',
        width: '75%'
      },
      "NonLocal Supplier Manager": {
        component: 'ManageRouteDialogComponent',
        width: '75%'
      },
      "Brewery Manager": {
        component: 'ManageRouteDialogComponent',
        width: '75%'
      },
      "NonLocal Brewery Manager": {
        component: 'ManageRouteDialogComponent',
        width: '75%'
      }
    }
  };

  public ShipmentsTPRevalidation = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'tpNo', label: 'TP Barcode' },
        { key: 'vehicleNo', label: 'Vehicle Number',path: 'transporterDetails' },
        { key: 'distillery', label: 'Distillery' },
        { key: 'validityDateTime', label: 'Validity Date & Time', type: 'dateTime' },	
        { key: 'status', label: 'Status', icon: { black: ['Delivered'], warning: ['Reviewed','InProgress'], brown: ['TPScanned'], info: ['InTransit'], success: ['Reached','TPReceived'], yellow: ['Created','SOVerified']}}
      ]
    },
    createRecord: {
      // ManageTpVehicleAccidentDialogComponent
    }
  };

  public InterDepotIDTSupplier = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'consignmentType', label: 'Consignment' },
        { key: 'indentId', label: 'Indent No' },
        { key: 'supplierCode', label: 'Distillery/Supplier Name' },
        { key: 'sourceDepotCode', label: 'Source Depot' },
        { key: 'destinationDepotCode', label: 'Destination Depot' },
        { key: 'updatedDateTime', label: 'Date', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'InterDepotTrnasferSuplierDialogDetailsComponent', width: '75%' },
        icon: {
          type: 'pi pi-circle-on',
          success: ['Approved'], info: ['InProgress'], black: ['Delivered'], warning: ['InTransit'], danger: ['Rejected','Cancelled'], yellow: ['Created','Requested'], brown: ['Reached']
        }
      },
        {
          key: 'action', label: 'Action', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'ManageInterDepotTransferLocalComponent', width: '85%' }, notEqRule: { key: 'status', value: 'InUse' } },
          ]
        }
      ]
    },
    createRecord: {
    //   "Material Management": {
    //    component: 'ManageInterDepotTransferLocalComponent',
    //    width: '85%'
    //  },
     
   }
  };

  public InterDepotIDTLocal = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentNo', label: 'Indent No' },
        { key: 'supplierName', label: 'Distillery/Supplier Name' },
        { key: 'sourceDepot', label: 'Source Depot' },
        { key: 'destinationDepot', label: 'Destination Depot' },
        { key: 'date', label: 'Date', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'InterDepotTransferLocalDialogDetailsComponent', width: '70%' },
        icon: {
          type: 'pi pi-circle-on',
          success: ['Approved'], info: ['InProgress'], black: ['Delivered'], warning: ['InTransit'], danger: ['Rejected','Cancelled'], yellow: ['Created','Requested'], brown: ['Reached']
        }
      },
      ]
    },
    createRecord: {
      //  "Material Management": {
      //   component: 'InterDepotTransferLocalDialogDetailsComponent',
      //   width: '50%'
      // },
      
    }
  };

  public InterDepotDepotTransfer = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'consignmentType', label: 'Consignment' },
        { key: 'indentId', label: 'Indent No' },
        { key: 'sourceDepotCode', label: 'Source Depot' },
        { key: 'destinationDepotCode', label: 'Destination Depot' },
        { key: 'supplierCode', label: 'Supplier Name' },
        { key: 'evcNo', label: 'EVC' },
        { key: 'grnNo', label: 'GRN' },
        { key: 'createdOn', label: 'Date', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'OrdersDetailsDialogComponent' },
        icon: {
          type: 'pi pi-circle-on',
          success: ['Approved'], info: ['InProgress'], black: ['Delivered'], warning: ['InTransit'], danger: ['Rejected'], yellow: ['Created','Requested'], brown: ['Reached']
        }},
      ],
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'consignmentType', label: 'Consignment' },
        { key: 'indentId', label: 'Indent No' },
        { key: 'sourceDepotCode', label: 'Source Depot' },
        { key: 'destinationDepotCode', label: 'Destination Depot' },
        { key: 'supplierCode', label: 'Supplier Name' },
        { key: 'evcNo', label: 'EVC',path:'tp'},
        { key: 'grnNO', label: 'GRN',path:'tp'},
        { key: 'createdOn', label: 'Date', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'OrdersDetailsDialogComponent' },
        icon: {
          type: 'pi pi-circle-on',
          success: ['Approved'], info: ['InProgress'], black: ['Delivered'], warning: ['InTransit'], danger: ['Rejected'], yellow: ['Created','Requested'], brown: ['Reached']
        }},
      ],
      "Brewery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'consignmentType', label: 'Consignment' },
        { key: 'indentId', label: 'Indent No' },
        { key: 'sourceDepotCode', label: 'Source Depot' },
        { key: 'destinationDepotCode', label: 'Destination Depot' },
        { key: 'supplierCode', label: 'Supplier Name' },
        { key: 'evcNo', label: 'EVC',path:'tp'},
        { key: 'grnNO', label: 'GRN',path:'tp'},
        { key: 'createdOn', label: 'Date', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'OrdersDetailsDialogComponent' },
        icon: {
          type: 'pi pi-circle-on',
          success: ['Approved'], info: ['InProgress'], black: ['Delivered'], warning: ['InTransit'], danger: ['Rejected'], yellow: ['Created','Requested'], brown: ['Reached']
        }},
      ],
    },
    createRecord: {
      "Distillery Manager": {
        component: 'InderDepotTransferDistilleryComponent',
        width: '75%'
      },
      "Brewery Manager": {
        component: 'InderDepotTransferDistilleryComponent',
        width: '75%'
      },
      "NonLocal Supplier Manager": {
        component: 'InderDepotTransferDistilleryComponent',
        width: '75%'
      },
      "NonLocal Brewery Manager": {
        component: 'InderDepotTransferDistilleryComponent',
        width: '75%'
      },
      
    }
  };

  public InterDepotManualIDT = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'consignmentType', label: 'Consignment' },
        { key: 'indentId', label: 'Indent No' },
        { key: 'sourceDepotCode', label: 'Source Depot' },
        { key: 'destinationDepotCode', label: 'Destination Depot' },
        { key: 'supplierCode', label: 'Supplier Name' },
        { key: 'evcNo', label: 'EVC' },
        { key: 'grnNo', label: 'GRN' },
        { key: 'createdOn', label: 'Date', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'OrdersDetailsDialogComponent' },
        icon: {
          type: 'pi pi-circle-on',
          success: ['Approved'], info: ['InProgress'], black: ['Delivered'], warning: ['InTransit'], danger: ['Rejected'], yellow: ['Created','Requested'], brown: ['Reached']
        }},
      ],
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'consignmentType', label: 'Consignment' },
        { key: 'indentId', label: 'Indent No' },
        { key: 'sourceDepotCode', label: 'Source Depot' },
        { key: 'destinationDepotCode', label: 'Destination Depot' },
        { key: 'supplierCode', label: 'Supplier Name' },
        { key: 'evcNo', label: 'EVC',path:'tp'},
        { key: 'grnNO', label: 'GRN',path:'tp'},
        { key: 'createdOn', label: 'Date', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'OrdersDetailsDialogComponent' },
        icon: {
          type: 'pi pi-circle-on',
          success: ['Approved'], info: ['InProgress'], black: ['Delivered'], warning: ['InTransit'], danger: ['Rejected'], yellow: ['Created','Requested'], brown: ['Reached']
        }},
      ],
      "Brewery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'consignmentType', label: 'Consignment' },
        { key: 'indentId', label: 'Indent No' },
        { key: 'sourceDepotCode', label: 'Source Depot' },
        { key: 'destinationDepotCode', label: 'Destination Depot' },
        { key: 'supplierCode', label: 'Supplier Name' },
        { key: 'evcNo', label: 'EVC',path:'tp'},
        { key: 'grnNO', label: 'GRN',path:'tp'},
        { key: 'createdOn', label: 'Date', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'OrdersDetailsDialogComponent' },
        icon: {
          type: 'pi pi-circle-on',
          success: ['Approved'], info: ['InProgress'], black: ['Delivered'], warning: ['InTransit'], danger: ['Rejected'], yellow: ['Created','Requested'], brown: ['Reached']
        }},
      ],
    },
    createRecord: {
      "Distillery Manager": {
        component: 'ManageManualIdtComponent',
        width: '75%'
      },
      "Brewery Manager": {
        component: 'ManageManualIdtComponent',
        width: '75%'
      },
      "NonLocal Supplier Manager": {
        component: 'ManageManualIdtComponent',
        width: '75%'
      },
      "NonLocal Brewery Manager": {
        component: 'ManageManualIdtComponent',
        width: '75%'
      },
      
    }
  };

  public InterDepotOrders = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'createdOn', label: 'OFIDT Date', type: 'date' },
        { key: 'indentId', label: 'Indent No' },
        { key: 'ofidtNo', label: 'OFIDTNumber' },
        { key: 'supplierCode', label: 'Supplier' },
        { key: 'sourceDepotCode', label: 'Source Depot' },
        { key: 'destinationDepotCode', label: 'Destination Depot' },
        { key: 'status', label: 'Status', modal: { component: 'OrdersDetailsDialogComponent' }, icon: {  success: ['Approved'], info: ['InProgress'], black: ['Delivered'], warning: ['InTransit'], danger: ['Rejected'], yellow: ['Created','Requested'], brown: ['Reached']} },
      ],

    },
    createRecord: {}
  };

  public InterDepotInterDepotTransfer = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'createdOn', label: 'OFIDT Date', type: 'date' },
        // { key: 'ofidtNumber', label: 'OFIDTNumber' },
        { key: 'consignmentType', label: 'Consignment Type'},
        { key: 'tpNo', label: 'Transport Permit' },
        { key: 'sourceEntityId', label: 'Supplier Name' },
        { key: 'destnEntityId', label: 'Destination Depot',path:'consignment' },
        { key: 'dispatchDateTime', label: 'ICDC Generated Date', type: 'date' },
        // { key: 'createdOn', label: 'TP Issued Date & Time', type: 'dateTime' },
        { key: 'arrivedDateTime', label: 'TP Outward Date & Time', type: 'dateTime' },
        { key: 'cancelledIcdc', label: 'Cancelled ICDC' },
        { key: 'evcNo', label: 'EVC', type: 'link', modal: { component: 'IDTReceiptEvcInfoDialogComponent', width: '75%' } },
        { key: 'grnNO', label: 'GRN', type: 'link', modal: { component: 'IDTReceiptApnDetailsDialogComponent', width: '75%' } },
        {
          key: 'status', label: 'Status', modal: { component: 'OpenIdtStatusDialogComponent' , width: '65%'},
          icon: {
            type: 'pi pi-circle-on cursor-pointer', style: 'color: accent ;font-size:12px;cursor:pointer;',
            yellow: ['Created'], info: ['InProgress','InTransit'], success: ['Approved', 'Delivered'], danger: ['Cancelled']
          }
        },
      ],
      "Excise Superintendent Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'createdOn', label: 'OFIDT Date', type: 'date' },
        // { key: 'ofidtNumber', label: 'OFIDTNumber' },
        { key: 'consignmentType', label: 'Consignment Type',  type: 'link', modal: { component: 'OfidtDetailsComponent', width: '65%' }},
        { key: 'tpNo', label: 'Transport Permit' },
        { key: 'sourceDepotName', label: 'Supplier Name' },
        { key: 'destnEntityName', label: 'Destination Depot' },
        { key: 'dispatchDateTime', label: 'ICDC Generated Date', type: 'date' },
        // { key: 'createdOn', label: 'TP Issued Date & Time', type: 'dateTime' },
        { key: 'arrivedDateTime', label: 'TP Outward Date & Time', type: 'dateTime' },
        { key: 'cancelledIcdc', label: 'Cancelled ICDC' },
        { key: 'evcNo', label: 'EVC', type: 'link', modal: { component: 'IDTReceiptEvcInfoDialogComponent', width: '75%' } },
        { key: 'grnNO', label: 'GRN', type: 'link', modal: { component: 'IDTReceiptApnDetailsDialogComponent', width: '75%' } },
        {
          key: 'edit', label: 'Packing List', action: 'edit', actions: [
            {
              icon: 'edit', modal: { component: 'IcdcPackingListDialogComponent', width: '80%' }
            },
          ]
        },
        {
          key: 'status', label: 'Status', modal: { component: 'OpenIdtStatusDialogComponent', width: '65%' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer', style: 'color: accent ;font-size:12px;cursor:pointer;',
            yellow: ['Created'], info: ['InProgress','InTransit'], success: ['Approved', 'Delivered'], danger: ['Cancelled']
          }
        },
      ]
    },
    createRecord: {
      "Excise Superintendent Officer": {
        component: 'TpRequestDialogComponent',
        width: '65%'
      },
    }
  };

  public InterDepotInterDepotReceipt = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        // { key: 'asnId', label: 'ASN Id', type: 'link', modal: { component: 'AsnDetailsForDepotReceiptDialogComponent', width: '50%' } },
        // { key: 'asnDate', label: 'ASN Date', type: 'date' },
        { key: 'sourceEntityId', label: 'Source Depot' },
        {
          key: 'action', label: 'Action', type: 'link',
          action: ['OFIDT','Invoice', 'Shipment', 'Gate Pass', 'Check List', 'EVC', 'SRA(GRN)', 'Receive'],
          modal: [
          { component: 'IDTReceiptOfidtInfoDialogComponent', width: '75%', actionLink: 'OFIDT' },
          { component: 'IDTReceiptInvoiceInfoDialogComponent', width: '75%', actionLink: 'Invoice' },
          { component: 'IDTReceiptShipmentShipmentInfoDialogComponent', width: '75%', actionLink: 'Shipment' },
          { component: 'IDTReceiptGatepassInfoDialogComponent', width: '70%', actionLink: 'Gate Pass' },
          { component: 'IDTReceiptChecklistInfoDialogComponent', width: '75%', actionLink: 'Check List' },
          // { component: 'IDTReceiptEvcInfoDialogComponent', width: '70%', actionLink: 'EVC' },
          { component: 'SupplierEvcInfoDialogComponent', width: '70%', actionLink: 'EVC' },
          // { component: 'IDTReceiptApnDetailsDialogComponent', width: '75%', actionLink: 'SRA(GRN)' },
          { component: 'ApnDetailsForSupplierDialogComponent', width: '75%', actionLink: 'SRA(GRN)' },
          { component: 'IDTReceiptChecklistInfoDialogComponent', width: '75%', actionLink: 'Receive' },
          ]
        },
        {
          key: 'status', label: 'status', modal: { component: 'OpenIdtStatusDialogComponent', width: '75%' },
          icon: {
            type: 'pi pi-circle-on',
            success: ['InProgress','SOVerified', 'TPReceived'], info: ['Delayed'], black: ['Delivered'], warning: ['InTransit'], purple: ['TPScanned','Revalidated'], danger: ['Rejected'], yellow: ['Created'], brown: ['Reached']
          }
        }
        // {
        //   key: 'action', label: 'Action', type: 'link',
        //   action: ['OFS','Invoice', 'Shipment', 'Gate Pass', 'Check List', 'EVC', 'SRA(GRN)', 'Receive'],
        //   modal: [{ component: 'OpenChecklistDetailsDialogComponent', width: '50%' },
        //   { component: 'OpenOfidtDetailsDialogComponent', width: '50%' },
        //   { component: 'OpenTpForIdrDialogComponent', width: '50%' },
        //   { component: 'OpenLabDetailsDialogComponent', width: '50%' },
        //   { component: 'OpenIcdcDetailsDialogComponent', width: '50%' },
        //   { component: 'EvcDetailsDialogComponent', width: '50%' },
        //   { component: 'OpenStockRecentDetailsDialogComponent', width: '50%' },
        //   { component: 'OpenGatepassDetailsDialogComponent', width: '50%' },
        //   // { component: 'GrnDetailsDialogComponent', width: '50%' },
        //   // { component: 'OpenDepotReceiptDetailsDialogComponent', width: '50%' },
        //   ]
        // },
        // {
        //   key: 'status', label: 'Status', modal: { component: 'OpenIdtStatusDialogComponent' },
        //   icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;' }
        // },
      ]
    },
    createRecord: {}
  };

  public InterDepotOFIDTCancelled = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'ofidtDate', label: 'OFIDT Date' , type: 'date'},
        { key: 'ofidtNumber', label: 'OFIDT Number' },
        { key: 'sourceDepot', label: 'Source Depot' },
        { key: 'destinationDepot', label: 'Destination Depot' },
        { key: 'supplierName', label: 'Supplier' },
        { key: 'cancelOfidtDate', label: 'Date Of Cancellation', type: 'date' },
        { key: 'reason', label: 'Reason', type: 'link', modal: { component: 'OfidtReasonDialogComponent', width: '50%' } },
        {
          key: 'status', label: 'Status', modal: { component: 'OfidtStatusDialogComponent', width: '50%' },
          icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;' }
        }
      ]
    },
    createRecord: {}
  };

  public InterDepotICDCCancelled = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'ofidtDate', label: 'OFIDT Date', type: 'date' },
        { key: 'ofidtNumber', label: 'OFIDT Number' },
        { key: 'icdcDate', label: 'ICDC Date', type: 'date' },
        { key: 'icdcNo', label: 'ICDC No' },
        { key: 'sourceDepot', label: 'Source Depot' },
        { key: 'destinationDepot', label: 'Destination Depot' },
        { key: 'supplier', label: 'Supplier' },
        { key: 'dateOfCancellation', label: 'Date Of Cancellation', type: 'date' },
        { key: 'reason', label: 'Reason', type: 'link', modal: { component: 'IcdcReasonDialogComponent', width: '50%' } },
        {
          key: 'status', label: 'Status', modal: { component: 'IcdcStatusDialogComponent', width: '50%' },
          icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;' }
        }
      ]
    },
    createRecord: {}
  };
  public StockReturnStockReturntoDistillery = {
    columnsToDisplay: {
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'stockType', label: 'Consignment' },
        { key: 'indentId', label: 'Indent No' },
        { key: 'sourceEntityId', label: 'Depot' },
        { key: 'destnEntityId', label: 'Supplier Name' },
        { key: 'createdOn', label: 'Date', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'StockReturnToDistilleryComponent' },
        icon: {
          type: 'pi pi-circle-on',
          success: ['Approved'], info: ['InProgress'], black: ['Delivered'], warning: ['InTransit'], danger: ['Rejected'], yellow: ['Created','Requested'], brown: ['Reached']
        }},
      ],
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'stockType', label: 'Consignment' },
        { key: 'indentId', label: 'Indent No' },
        { key: 'sourceEntityId', label: 'Depot' },
        { key: 'destnEntityId', label: 'Supplier Name' },
        { key: 'createdOn', label: 'Date', type: 'date' },
        { key: 'tpNo', label: 'Action', type: 'link', value: 'Create TP', condition: {isExistKey: true},
          modal: { component: 'CreateTpForStockReturnComponent', width: '75%' } },
        { key: 'status', label: 'Status', modal: { component: 'StockReturnToDistilleryComponent' },
        icon: {
          type: 'pi pi-circle-on',
          success: ['Approved'], info: ['InProgress'], black: ['Delivered'], warning: ['InTransit'], danger: ['Rejected'], yellow: ['Created','Requested'], brown: ['Reached']
        }},
      ],
    },
    createRecord: {
      "Distillery Manager": {
        component: 'StockReturnToDistilleryComponent',
        width: '85%'
      }      
    }
  };

  public InterRetailerIRTSupplier = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'consignmentType', label: 'Consignment' },
        { key: 'indentId', label: 'Indent No' },
        { key: 'supplierCode', label: 'Distillery/Supplier Name' },
        { key: 'sourceRetailerCode', label: 'Source Retailer' },
        { key: 'destinationRetailerCode', label: 'Destination Retailer' },
        { key: 'updatedDateTime', label: 'Date', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'InterRetailerTrnasferSuplierDialogDetailsComponent', width: '50%' } },
        {
          key: 'action', label: 'Action', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'ManageInterRetailerSupplierDialogComponent', width: '50%' }, notEqRule: { key: 'status', value: 'InUse' } },
          ]
        }
      ]
    },
    createRecord: {
      "Material Management": {
       component: 'ManageInterRetailerTransferSupplierComponent',
       width: '85%'
     },
     
   }
  };

  public StockStocks = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product Code',
          // type: 'link', modal: { component: '', width: '75%' }
        },
        { key: 'brandName', label: 'Brand Name', type: 'link', modal: { component: 'ProductDetailsDialogComponent', width: '125%' } },
        { key: 'liquorType', label: 'Stock Type' },
        { key: 'sizeCode', label: 'Size in ml' },
        { key: 'batchId', label: 'Batch Id' },
        { key: 'openingBal', label: 'Opening Stock' },
        { key: 'receipts', label: 'Production' },
        { key: 'dispatches', label: 'Sales' },
        { key: 'closingBal', label: 'Closing Stock(Cases)' },
        // { key: 'breakages', label: 'Breakage(Bottles)' },
        // { key: 'shortage', label: 'Shortage(Bottles)' },
        { key: 'updatedDateTime', label: 'Last Updated Date', type: 'date' },
      ]
    },
    createRecord: {}
  };

  public StockIMLStock = {
    columnsToDisplay: {
      "Licensee": [
        { key: 'no', label: 'S.No.' },
        { key: 'updatedDateTime', label: 'Date', type: 'date' },
        { key: 'brandCode', label: 'Brand Code' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'size', label: 'Size in ml' },
        { key: 'openingBalance', label: 'Opening Balance(C/B)' },
        { key: 'receipts', label: 'Receipts(C/B)' },
        { key: 'sales', label: 'Sold(C/B)' },
        { key: 'breakages', label: 'Breakages(btls)' },
        { key: 'shortages', label: 'Shortages(btls)' },
        { key: 'return', label: 'Return(btls)' },
        { key: 'closingBalance', label: 'Closing Balance(C/B)' },
      ]
    },
    createRecord: {}
  };

  public StockBeerStock = {
    columnsToDisplay: {
      "Licensee": [
        { key: 'no', label: 'S.No.' },
        { key: 'date', label: 'Date', type: 'date' },
        { key: 'brandCode', label: 'Brand Code' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'size', label: 'Size in ml' },
        { key: 'openingBalance', label: 'Opening Balance(btls)' },
        { key: 'receipts', label: 'Receipts(btls)' },
        { key: 'sales', label: 'Sales(btls)' },
        { key: 'breakages', label: 'Breakages(btls)' },
        { key: 'shortages', label: 'Shortages(btls)' },
        { key: 'return', label: 'Return(btls)' },
        { key: 'closingBalance', label: 'Closing Balance(btls)' },
      ]
    },
    createRecord: {}
  };

  public StockSalePercentage = {
    columnsToDisplay: {
      "Licensee": [
        { key: 'no', label: 'S.No.' },
        { key: 'date', label: 'Date', type: 'date' },
        { key: 'imlBeerSalesTarget', label: 'Iml & Beer Sales Target' },
        { key: 'achievementOnImlBeer', label: 'Achievement On IML & Beer' },
        { key: 'percentageStillNeedToAchieve', label: 'Percentage Still Need To Achieve' },
        { key: 'percentageNeedToAchieve', label: 'Percentage Need to Achieve' }
      ]
    },
    createRecord: {}
  };

  public StockManagementForm3BreakagesEntry = {
    columnsToDisplay: {
      "Licensee": [
        { key: 'no', label: 'S.No.' },
        { key: 'incidentId', label: 'Indent No' },
        { key: 'permitId', label: 'TP Indent No' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'dateofExpiry', label: 'Date of Expiry', type: 'date' },
        {
          key: 'action', label: 'Action', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'Form3BreakagesEntryComponent', width: '50%' }, notEqRule: { key: 'status', value: 'InUse' } },
          ]
        }
      ]
    },
    createRecord: {
      "Licensee": {
        component: 'ManageBreakagesEntryDialogComponent',
        width: '75%'
      },
    }
  };

  public StockManagementForm4BreakagesEntry = {
    columnsToDisplay: {
      "Licensee": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentNo', label: 'Indent No' },
        { key: 'tpNo', label: 'TP Indent No' },
        { key: 'retailerName', label: 'Retailer Name' },
        { key: 'dateofExpiry', label: 'Date of Expiry' , type: 'date'},
        {
          key: 'action', label: 'Action', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'Form3BreakagesEntryComponent', width: '50%' }, notEqRule: { key: 'status', value: 'InUse' } },
          ]
        }
      ]
    },
    createRecord: {
      "Licensee": {
        component: 'ManageF4BreakagesEntryDialogComponent',
        width: '75%'
      },
    }
  };

  public PriceListPriceList = {
    columnsToDisplay: {
      "Licensee": [
        { key: 'no', label: 'S.No.' },
        { key: 'brandCode', label: 'Brand Number' },
        { key: 'size', label: 'Size Code' },
        { key: 'packType', label: 'Pack Type' },
        { key: 'brandName', label: 'Product Name' },
        { key: 'ISSUE_PRICE_ROUNDED', label: 'Issue Price', type: 'number',path:'taxes' },
        { key: 'SPECIAL MARGIN', label: 'Special Margin' },
        { key: 'MRP_ROUND', label: 'MRP', type: 'number' },
        { key: 'liquorType', label: 'Type' }

      ]
    },
    createRecord: {}
  };

  public SalesSalesScan = {
    columnsToDisplay: {
      "Supervisor": [
        { key: 'no', label: 'S.No.' },
        { key: 'brandNumber', label: 'Product Type' },
        { key: 'productName', label: 'Product Name' },
        { key: 'mrp', label: 'MRP', type: 'number' },
        { key: 'issuePrice', label: 'Size in ml', type: 'number' },
        { key: 'specialMargin', label: 'Scanned Date', type: 'date' }
      ]
    },
    createRecord: {}
  };

  public SalesManagementDaySalesEntryEdit = {
    columnsToDisplay: {
      "Licensee": [
        { key: 'no', label: 'S.No.' },
        { key: 'supplierCode', label: 'Supplier Code' },
        { key: 'supplierName', label: 'Supplier Name' },
        { key: 'brandCode', label: 'Brand Code' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'size', label: 'Size' },
        { key: 'productType', label: 'Product Type' },
        { key: 'mrp', label: 'MRP', type: 'number' },
        { key: 'dayAvailableBalance', label: 'Day Available Balance' },
        { key: 'soldQuantity', label: 'Sold Qty(Bottles)' },
        { key: 'currrentAvailableBalance', label: 'Current Available Balance' },
        { key: 'salesAmount', label: 'Sales Amount' }
      ]
    },
    createRecord: {}
  };

  public ImportImport = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'supplierName', label: 'Supplier Name' },
        { key: 'depot', label: 'Depot' },
        { key: 'indentNo', label: 'Indent Number' },
        { key: 'permitNumber', label: 'Permit Number' },
        { key: 'ofsNo', label: 'Ofs Number' },
        { key: 'purchaseOrderNo', label: 'Purchase OrderNumber' },
        { key: 'approve', label: 'Approve' },
        { key: 'importFee', label: 'Import Fee' },
        { key: 'ealFee', label: 'EAL Fee' },
        { key: 'date', label: 'Date', type: 'date' },
        { key: 'bulkPrintL1', label: 'Bulk Print L1' },
        { key: 'bulkPrintL2', label: 'Bulk Print L2' },
        { key: 'status', label: 'Status', icon: { type: 'pi pi-circle-on cursor-pointer', danger: ['Rejected'], success: ['Approved']}},
        {
          key: 'action', label: 'Action', action: 'edit', actions: [
            { icon: 'edit', modal: {}, notEqRule: { key: 'status', value: 'InUse' } },
          ]
        }
      ]
    },
    createRecord: {}
  };

  public ImportShipment = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'depot', label: 'Depot' },
        { key: 'indentNo', label: 'Indent Number' },
        { key: 'permitNumber', label: 'Permit Number' },
        { key: 'purchaseOrderNo', label: 'Purchase OrderNumber' },
        { key: 'transporter', label: 'Transporter' },
        { key: 'vehicleNumber', label: 'Vehicle Number' },
        { key: 'evc', label: 'EVC & GRN' },
        { key: 'status', label: 'Status', modal: { component: 'ShipmentDetailsDialogComponent', width: '50%', height: '75%' } },
        {
          key: 'action', label: 'Action', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'ManageShipmentDialogComponent', width: '50%' }, notEqRule: { key: 'status', value: 'InUse' } },
          ]
        }
      ]
    },
    createRecord: {}
  };

  public ImportExtensionofImportPermit = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'supplierName', label: 'Supplier Name' },
        { key: 'depot', label: 'Depot' },
        { key: 'indentNo', label: 'Indent Number' },
        { key: 'permitNumber', label: 'Permit Number' },
        { key: 'purchaseOrderNo', label: 'Purchase OrderNumber' },
        { key: 'importFee', label: 'Import Fee' },
        { key: 'cvd', label: 'CVD' },
        { key: 'challanDate', label: 'Challan Date', type: 'date' },
        { key: 'status', label: 'Status' }
      ]
    },
    createRecord: {}
  };

  public ImportCancelImportPermit = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'poNumber', label: 'PO Number' },
        { key: 'sourceName', label: 'Source Name' },
        { key: 'depotName', label: 'Depot Name' },
        { key: 'status', label: 'Status', modal: { component: 'CancelImportPermitDetailsDialogComponent' }, icon: { type: 'pi pi-circle-on cursor-pointer', style: 'color: green ;font-size: 12px', success: ['Received'], warning: ['Approved', 'Requested'], info: ['Reviewed'] } }
      ]
    },
    createRecord: {}
  };

  public ImportImportIndents = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentDate', label: 'Indent Date', type: 'date' },
        { key: 'indentNumber', label: 'Indent Number' },
        { key: 'poNo', label: 'Permit Number' },
        { key: 'ofsNumber', label: 'OFS Number' },
        { key: 'supplier', label: 'Supplier' },
        { key: 'vehicleNo', label: 'Vehicle No' },
        { key: 'route', label: 'Route' },
        { key: 'transporterName', label: 'Transporter Name' },
        { key: 'status', label: 'Status', modal: { component: 'ImportIndentsDetailsDialogComponent' }, icon: { success: ['Reached'], } }
      ]
    },
    createRecord: {}
  };

  public ImportPastorsLocation = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'locationID', label: 'Location Code'},
        { key: 'locationName', label: 'Name' },
        { key: 'Address1', label: 'Address 1' },
        { key: 'Address2', label: 'Address 2' },
        { key: 'city', label: 'City' },
        { key: 'locationType', label: 'Location Type' },
        { key: 'district', label: 'District' },
      ]
    },
    createRecord: {
      "Material Management": {
        component: 'ManagePastorsLocationComponent',
        width: '75%'
      },
    }
  };

  public ImportAddChallans = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'locationName', label: 'Location Name'},
        { key: 'challanType', label: 'Transaction Type' },
        { key: 'openingBalance', label: 'Opening Balance' },
        { key: 'depositedAmount', label: 'Credited Amount' },
        { key: 'amountUsed', label: 'Debited Amount' },
        { key: 'closingBalance', label: 'Closing Balance' },
      ]
    },
    createRecord: {
      "Material Management": {
        component: 'ManageChallanInformationComponent',
        width: '45%'
      },
    }
  };

  public PackingImportPacking = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'createdOn', label: 'Import Date', type: 'date' },
        { key: 'ipNo', label: 'Import Permit',  type: 'link', modal: { component: 'ImportPackingActionDialogComponent', width: '75%' } },
        { key: 'sourceEntityId', label: 'Supplier' },
        { key: 'importQty', label: 'Import Quantity' },
        { key: 'packedQty', label: 'Packed Quantity (Cases /  Bottles)' },
        { key: 'status', label: 'Status',icon: { type: 'pi pi-circle-on cursor-pointer', success: ['Received','Approved'], warning: ['Created', 'Requested'], info: ['Reviewed','TPReceived'],danger: ['Cancelled','Rejected'] } }
      ],
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'createdOn', label: 'Import Date', type: 'date' },
        { key: 'importPermit', label: 'Import Permit' },
        { key: 'supplierName', label: 'Supplier' },
        { key: 'importQty', label: 'Import Quantity' },
        { key: 'packedQty', label: 'Packed Quantity  (Cases /  Bottles)' },
        { key: 'action', label: 'Action' }
      ]
    },
    createRecord: {}
  };

  public PackingProducts = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'segment', label: 'Segment' },
        { key: 'size', label: 'size(ml)' },
        { key: 'packType', label: 'Pack Type' },
        { key: 'basePrice', label: 'Base Price', type: 'number' },
        { key: 'exciseDuty', label: 'Excise Duty' }

      ]
    },
    createRecord: {}
  };

  public ExportExport = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'consignmentType', label: 'Consignment' },
        { key: 'importIndentNumber', label: 'Import Indent No' },
        { key: 'exportIndentNumber', label: 'Export Indent No.' },
        { key: 'nameOfTheExporter', label: 'Source' },
        { key: 'destination', label: 'Destination' },
        { key: 'exportPermitNumber', label: 'Export Permit No.' },
        { key: 'createdOn', label: 'Date', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'ExportFeatureDetailsDialogComponent', width: '85%' }, icon: { type: 'pi pi-circle-on cursor-pointer', success: ['Received','Approved'], warning: ['Created', 'Requested'], info: ['Reviewed'],danger: ['Cancelled','Rejected'] } }
      ]
    },
    createRecord: {
      "Distillery Manager": {
        component: 'ExportFeatureDetailsDialogComponent',
        width: '85%'
      },
    }
  };

  public ExportAddChallan = {
    columnsToDisplay: {
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'challanType', label: 'Transaction Type' },
        { key: 'challanNumber', label: 'Challan Number' },
        { key: 'initialAmount', label: 'Opening Balance',type:'decimal'},
        { key: 'depositedAmount', label: 'Credited Amount',type:'decimal'},
        { key: 'exportrefundAmount', label: 'Refund Amount',type:'decimal'},
        { key: 'amountUsed', label: 'Debited Amount',type:'decimal'},
        { key: 'remainingAmount', label: 'Closing Balance',type:'decimal'},
        { key: 'transactionDate', label: 'Transaction Date' ,type:'date'},
      ]
    },
    createRecord: {
      "Distillery Manager": {
        component: 'ExportChallanDialogComponent',
        width: '40%'
      },
      "Brewery Manager": {
        component: 'ExportChallanDialogComponent',
        width: '40%'
      },
    }
  };

  public ExportExportChallans = {
    columnsToDisplay: {
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'challanType', label: 'Transaction Type' },
        { key: 'challanNumber', label: 'Challan Number' },
        { key: 'depositedAmount', label: 'Deposited Amount',type:'decimal'},
        { key: 'challanDate', label: 'Challan Date' ,type:'date'},
      ]
    },
    createRecord: {}
  };

  public CSDCSDIndents = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'consignmentType', label: 'Consignment' },
        { key: 'indentId', label: 'Indent No' },
        { key: 'depotCode', label: 'Destination' },
        { key: 'updatedDateTime', label: 'Date', type: 'date' },
        // { key: 'action', label: 'Action' },
        { key: 'status', label: 'Status', modal: { component: 'StatusCsdDialogComponent', width: '70%', height: '75%' }, icon: { type: 'pi pi-circle-on cursor-pointer',  success: ['Received','Approved'], warning: ['Created', 'Requested'] , info: ['Reviewed'],danger:['Rejected'] } }
      ]
    },
    createRecord: {
      // "Distillery Officer": {
      //   component: 'ManageCsdFeatureDialogComponent',
      //   width: '75%'
      // },
      "Distillery Manager": {
        component: 'ManageCsdDialogComponent',
        width: '75%'
      }
    }
  };

  public CSDCSDLocations = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'locationType', label: 'Location Type' },
        { key: 'csdName', label: 'Name' },
        { key: 'address', label: 'Address' },
        { key: 'destination', label: 'Destination' },
        { key: 'licenceNo', label: 'Licence No' }
      ]
    },
    createRecord: {
      // "Distillery Officer": {
      //   component: 'ManageCsdLocationsDialogComponent',
      //   width: '75%'
      // }
      "Distillery Manager": {
        component: 'ManageCsdLocationsDialogComponent',
        width: '75%'
      }
    }
  };

  public CSDAddChallan = {
    columnsToDisplay: {
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'transactionNo', label: 'Challan / Transaction No' },
        { key: 'createdDate', label: 'Created Date', type: 'date' },
        { key: 'amountDeposited', label: 'Deposit Amount' },
        { key: 'transactionDate', label: 'Transaction Date' },
        { key: 'purpose', label: 'Challan Type' },
      ]
    },
    createRecord: {
      "Distillery Manager": {
        component: 'ManageCsdChallanComponent',
        width: '45%'
      }
    }
  };

  public BreakagesDepot = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'size', label: 'Size (in ml)' },
        { key: 'breakageType', label: 'Location' },
        { key: 'incidentDate', label: 'Breakage Date', type: 'date' },
        { key: 'breakageBottles', label: 'Breakage(In Bottles)' }
      ]
    },
    createRecord: {}
  };
  
  public BreakagesDistillery = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'size', label: 'Size (in ml)' },
        { key: 'breakageType', label: 'Location' },
        { key: 'incidentDate', label: 'Breakage Date', type: 'date' },
        { key: 'breakageBottles', label: 'Breakage(In Bottles)' }
      ]
    },
    createRecord: {}
  };

  public BreakagesTransit = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'permitId', label: 'TP Number' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'damagedBottleCount',  label: 'Breakage(In Bottles)'}, // modal: { component: 'HealCodesDialogComponent', width: '50%', height: 'auto' } },
        { key: 'caseCodes', label: 'Case Barcode', type: 'link', value: 'Breakage Cases', modal: { component: 'ManageTransitDialogComponent', width: '75%' } },
        { key: 'incidentDate', label: 'Breakage Date', type: 'date' },
      ],
      // HealCodesDialogComponent
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'permitId', label: 'TP Number' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'caseCodes', label: 'Case Barcode', type: 'link', value: 'Breakage Cases', modal: { component: 'ManageTransitDialogComponent', width: '75%' } },
        { key: 'incidentDate', label: 'Breakage Date', type: 'date' },
      ]
    },
    createRecord: {
      "Store Officer": {
        component: 'ManageTransitDialogComponent',
        width: '75%'
      },
      // Excise Superintendent Officer
    }
  };

  public BreakagesStorage = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'permitId', label: 'TP Number' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'caseCodes', label: 'Case Barcode', type: 'link', value: 'Breakage Cases', modal: { component: 'ManageTransitDialogComponent', width: '75%', tab: 'breakageCaseCodes' } },
        { key: 'incidentDate', label: 'Breakage Date', type: 'date' },
        { key: 'damagedBottleCount', label: 'Breakage(In Bottles)' }
      ],
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'permitId', label: 'TP Number' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'caseCodes', label: 'Case Barcode', type: 'link', value: 'Breakage Cases', modal: { component: 'ManageTransitDialogComponent', width: '75%', tab: 'breakageCaseCodes' } },
        { key: 'incidentDate', label: 'Breakage Date', type: 'date' },
      ]
    },
    createRecord: {
      "Store Officer": {
        component: 'ManageTransitDialogComponent',
        tab: "Storage",
        width: '75%'
      }
    }
  };

  public BreakagesBreakageVerification = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'brandCode', label: 'Brand Code' },
        { key: 'healCode', label: 'Heal Code' },
        { key: 'size', label: 'Size (ml)' },
        { key: 'mrp', label: 'MRP', type: 'number' },
        { key: 'mfgDate', label: 'MFG Date', type: 'date' },
        { key: 'dateOfBreakage', label: 'Date Of Breakage', type: 'date' }
      ],
      "Data Processing Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'healCode', label: 'Heal Code' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'size', label: 'Size (ml)' },
        { key: 'mrp', label: 'MRP', type: 'number' },
        { key: 'mfgDate', label: 'MFG Date', type: 'date' },
        { key: 'dateOfBreakage', label: 'Date Of Breakage', type: 'date' }
      ]
    },
    createRecord: {
      // "Depot Manager": {
      //   component: 'ManageTransitDialogComponent',
      //   width: '75%'
      // },
      // "Data Processing Officer": {
      // component: 'ManageTransitDialogComponent',
      // width: '75%'
      //}
    }
  };

  public BreakagesBreakages = {
    columnsToDisplay: {
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'date', label: 'Date', type: 'date' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'brandNumber', label: 'Brand Number' },
        { key: 'sizeCode', label: 'size Code' },
        { key: 'batchNumber', label: 'Batch Number' },
        { key: 'action', label: 'Action' }
      ]
    },
    createRecord: {}
  };

  public BreakagesShortages = {
    columnsToDisplay: {
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'date', label: 'Date', type: 'date' },
        { key: 'caseBarcode', label: 'Case Barcode' },
        { key: 'healCode', label: 'Heal Code' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'productName', label: 'Brand Name' },
        { key: 'brandNumber', label: 'Batch Number' },
      ]
    },
    createRecord: {}
  };

  public ProductsProducts = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product', type: 'link', modal: { component: 'ProductStatusDialogComponent', width: '75%' } },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'liquorType', label: 'Liquor Type' },
        { key: 'sizeCode', label: 'Size Code' },
        { key: 'size', label: 'Size(ml)' },
        { key: 'unitsPerCase', label: 'Units Per Case' },
        {
          key: 'brandBarcode', label: 'Brand Barcode', action: 'add', actions: [
            { icon: 'add_circle_outline', class: 'text-primary cursor-pointer', modal: { component: 'AddBrandBarcodeComponent', width: '50%' } },
          ], type: 'link', modal: { component: 'ProductStatusDialogComponent', width: '75%' }
        }
      ],
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product', type: 'link', modal: { component: 'ProductStatusDialogComponent', width: '75%' } },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'liquorType', label: 'Liquor Type' },
        { key: 'sizeCode', label: 'Size Code' },
        { key: 'size', label: 'Size(ml)' },
        { key: 'unitsPerCase', label: 'Units Per Case' },
        {
          key: 'brandBarcode', label: 'Brand Barcode', action: 'add', actions: [
            { icon: 'add_circle_outline', class: 'text-primary cursor-pointer', modal: { component: 'AddBrandBarcodeComponent', width: '50%' } },
          ], type: 'link', modal: { component: 'ProductStatusDialogComponent', width: '75%' }
        },
        { key:'updatePrice',label:'Update Price',type: 'link', modal: {
          component: 'ViewPriceLabelRegistrationComponent',
          width: '50%'
        }},
        { key:'customStatus', customIcon:{success: ['Approved'], danger:['Rejected']},type:'customStatus', label:'Price Status'}
      ]
    },
    createRecord: {
      // "Managing Director": {
      //     component: 'AddBrandBarcodeComponent',
      //     width: '75%'
      // }
        "Material Management": {
          component: 'ManageLabelRegistrationDialogComponent',
          width: '75%'
        }
    }
  };

  public ProductsBrand = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        {
          key: 'brandCode', label: 'Brand Codes', type: 'link', modal: {
            component: 'BrandBarcodeDetailsDialogComponent',
            width: '75%'
          }
        },
        { key: 'brandName', label: 'Brand Name' },
        // { key: 'liquorCode', label: 'Liquor Code' },
        { key: 'liquorType', label: 'Liquor Type' }
        
        
      ]
    },
    createRecord: {
      // "Managing Director": {
      //     component: 'ManageBrandBarcodesDialogComponent',
      //     width: '75%'
      // }
    }
  };

  public AlertsAlerts = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'alertName', label: 'Alert Name' },
        { key: 'alertChannel', label: 'Alert Channel' },
        { key: 'subscriptedAlerts', label: 'Subscripted Alerts' }
      ]
    },
    createRecord: {}
  };

  public AlertsCircular = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'message', label: 'Message' },
        { key: 'createdDate', label: 'Created Date', type: 'date' },
        { key: 'createdBy', label: 'Created By' }
      ]
    },
    createRecord: {}
  };

  public AlertsSubscription = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'alertName', label: 'Alert Name' },
        { key: 'alertChannel', label: 'Alert Channel' },
        { key: 'subscriptedAlerts', label: 'Subscripted Alerts' }
      ]
    },
    createRecord: {}
  };

  public AlertsAlertofCorrection = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'retailerCode', label: 'Retailer Code' },
        { key: 'icdcNumber', label: 'ICDC Number' },
        { key: 'icdcDate', label: 'ICDC Date', type: 'date' },
        { key: 'negative', label: 'Negative' },
        { key: 'view', label: 'View' },
      ]
    },
    createRecord: {}
  };

  public ChecklistChecklist = {
    columnsToDisplay: {
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'alertName', label: 'Info' },
        { key: 'alertChannel', label: 'Message' },
        {
          key: 'action', label: 'Action', action: 'edit', actions: [
            { icon: 'edit', modal: { component: '', width: '' } },
          ]
        }
      ]
    },
    createRecord: {}
  };

  public BrandRegistrationBrandRegistration = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        // { key: 'brandCode', label: 'Brand Code' },
        { key: 'brandShortCode', label: 'Brand Code' },
        { key: 'brandName', label: 'Brand Name' },
        { key: 'size', label: 'Size(ml)' },
        { key: 'packType', label: 'Pack Type' },
        { key: 'liquorType', label: 'Liquor Type'},
        // { key: 'liquorCode', label: 'Segment' }
      ]
    },
    createRecord: {
      "Material Management": {
        component: 'ManageBrandRegistrationDialogComponent',
        width: '50%',
        // label: 'Create Brand Registration'
      }
    },
    // createRecord2: {
    //   "Material Management": {
    //     component: 'ManageSegmentRegistrationDialogComponent',
    //     width: '50%',
    //     label: 'Create Segment'
    //   }
    // }
  };

  public BrandRegistrationSegments = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'segment', label: 'Segment' },
        { key: 'importFee', label: 'Import Fee'},
        { key: 'exportFee', label: 'Export Fee'},
        { key: 'exciseDuty', label: 'Excise Duty'},
      ]
    },
    createRecord: {
      "Material Management": {
        component: 'ManageSegmentRegistrationDialogComponent',
        width: '50%',
      }
    },
  };

  public LabelRegistrationLabelRegistration = {
    columnsToDisplay: {
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product Code',path:'productsDetails' },
        { key: 'supplierCode', label: 'Supplier Code' },
        { key: 'brandCode', label: 'Brand Code',path:'productsDetails' },
        /*  { key: 'brandName', label: 'Brand Name' }, */
        { key: 'liquorType', label: 'Liquor Type',path:'productsDetails' },
        { key: 'size', label: 'Size', type: 'number',path:'productsDetails' },
        { key: 'packType', label: 'Pack Type',path:'productsDetails' },
        { key: 'price', label: 'Price', type: 'number' }, // row.basicPricing[0][price]
        {
          key: 'status', label: 'Status',path:'productsDetails', modal: { component: 'ManageLabelDocumentsVerificationDialogComponent', width: '75%', tab: 'documents' },
          icon: { type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;' }
        }
      ],
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'supplierCodeOnly', label: 'Supplier Code' },
        { key: 'supplierName', label: 'Supplier Name' },
        { key: 'entityType', label: 'Supplier Type' },
        {
          key: 'status', label: 'Status', modal: { component: 'ManageLabelDocumentsVerificationDialogComponent', width: '75%', tab: 'documents' },
          icon: {
            yellow: ['Created'], info: ['InProgress', 'Requested'], success: ['Approved', 'Release', 'Active'], danger: ['Cancelled'], warning: ['Reviewed', 'DrainOut'], black: ['InActive'],
            type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;'
          }
        },
      ],
      "AS Labels": [
        { key: 'no', label: 'S.No.' },
        { key: 'supplierCodeOnly', label: 'Supplier Code' },
        { key: 'supplierName', label: 'Supplier Name' },
        { key: 'entityType', label: 'Supplier Type' },
        {
          key: 'status', label: 'Status', modal: { component: 'ManageLabelDocumentsVerificationDialogComponent', width: '75%', tab: 'documents' },
          icon: {
            yellow: ['Created'], info: ['InProgress', 'Requested'], success: ['Approved', 'Release', 'Active'], danger: ['Cancelled'], warning: ['Reviewed', 'DrainOut'], black: ['InActive'],
            type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;'
          }
        },
      ]
    },
    createRecord: {
      // "Material Management": {
      //   component: 'ManageLabelRegistrationDialogComponent',
      //   width: '75%'
      // }
    }
  };

  public LabelRegisterLabelRegister = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'supplierCode', label: 'Supplier Code' },
        { key: 'brandCode', label: 'Brand Code' },
        { key: 'brandImage', label: 'Brand Image', type:'upload', value: 'product_images' },
        { key: 'labelImage', label: 'Label Image', type:'upload', value: 'product_images' },
        { key: 'offeredBasicPrice', label: 'Offered Basic Price', type:'input' },
        { key: 'brandbarCode', label: 'Brand Barcode', type:'input' },
        { key: 'liquorType', label: 'Liquor Type' },
        { key: 'size', label: 'Size', type: 'number' },
        { key: 'packType', label: 'Pack Type' },
        { key: 'price', label: 'Price', path: "basicPricing", type: 'number' }, // row.basicPricing[0][price]
        {
          key: 'status', label: 'Status', modal: { component: 'ManageLabelDocumentsVerificationDialogComponent', width: '75%', tab: 'documents' },
          icon: {
            yellow: ['Created'], info: ['InProgress', 'Requested'], success: ['Approved', 'Release', 'Active'], danger: ['Cancelled'], warning: ['Reviewed', 'DrainOut'], black: ['InActive'],
            type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;'}
        },
        { key: 'update', label: 'Action'},
      ],
      "Distillery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'supplierCodeOnly', label: 'Supplier Code' },
        { key: 'supplierName', label: 'Supplier Name' },
        { key: 'entityType', label: 'Supplier Type' },
        { key: 'action', label: 'Action', type: 'link', value: 'View', modal: { component: 'ManageLabelDocumentsVerificationDialogComponent', width: '75%', tab: 'documents' }}, 
      ],
      "Brewery Officer": [
        { key: 'no', label: 'S.No.' },
        { key: 'supplierCodeOnly', label: 'Supplier Code' },
        { key: 'supplierName', label: 'Supplier Name' },
        { key: 'entityType', label: 'Supplier Type' },
        { key: 'action', label: 'Action', type: 'link', value: 'View', modal: { component: 'ManageLabelDocumentsVerificationDialogComponent', width: '75%', tab: 'documents' }}, 
      ]
    },
    createRecord: {
      "Material Management": {
        component: 'ManageLabelRegistrationDialogComponent',
        width: '75%'
      }
    }
  };


  public FeedbackFeedback = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'location', label: 'Location' },
        { key: 'reportedBy', label: 'Reported By' },
        { key: 'mobileNo', label: 'Mobile No' },
        { key: 'issue', label: 'Issue' },
        { key: 'reportedDateTime', label: 'Reported Date Time', type: 'dateTime' },
        { key: 'status', label: 'Status' },
        { key: 'rating', label: 'Rating' }
      ]
    },
    createRecord: {
      "Managing Director": {
        component: 'ManageIssueLogDialogComponent',
        width: '75%'
      },
      "Material Management": {
        component: 'ManageIssueLogDialogComponent',
        width: '75%'
      }
    }
  };

  public TPRevalidationTPRevalidation = {
    columnsToDisplay: {
      "Managing Director": [
        { key: 'no', label: 'S.No.' },
        { key: 'tpNo', label: 'Tp Barcode' },
        { key: 'currentVehicleNo', label: 'Vehicle No' },
        { key: 'sourceEntityName', label: 'Distillery' },
        { key: 'validityDateTime', label: 'Validity Date & Time', type: 'dateTime' },
        { key: 'requestedDate', label: 'Requested Date', type: 'date' },
        {
          key: 'status', label: 'Status', modal: { component: 'TpDetailsDialogComponent', width: '75%' },
          icon: { info: ['Delayed'],type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;' }
        }
      ],
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'transportPermitNo', label: 'Tp Barcode' },
        { key: 'currentVehicleNo', label: 'Vehicle No' },
        { key: 'sourceEntityName', label: 'Distillery' },
        { key: 'validityDateTime', label: 'Validity Date & Time', type: 'dateTime' },
        { key: 'requestedDate', label: 'Requested Date', type: 'date' },
        { key: 'reason', label: 'Reason' },
        {
          key: 'status', label: 'Status', modal: { component: 'TpDetailsDialogComponent', width: '75%' },
          icon: { info: ['Delayed'],type: 'pi pi-circle-on cursor-pointer', style: 'font-size:12px;cursor:pointer;' }
        }
      ]

    },
    createRecord: {
    }
  };

  public UserRolesDepartment = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'no', label: 'S.No.' },
        { key: 'roleName', label: 'Role Name' },
        { key: 'description', label: 'Description' },
        { key: 'duties', label: 'Duties' },
        { key: 'responsibilities', label: 'Responsibilities' },
        { key: 'status', label: 'Status' },
        { key: 'action', label: 'Action' },
      ]
    },
    createRecord: {}
  };

  public UserRolesDistillery = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'no', label: 'S.No.' },
        { key: 'roleName', label: 'Role Name' },
        { key: 'description', label: 'Description' },
        { key: 'duties', label: 'Duties' },
        { key: 'responsibilities', label: 'Responsibilities' },
        { key: 'status', label: 'Status' },
        { key: 'action', label: 'Action' },
      ]
    },
    createRecord: {}
  };

  public UserRolesDepot = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'no', label: 'S.No.' },
        { key: 'roleName', label: 'Role Name' },
        { key: 'description', label: 'Description' },
        { key: 'duties', label: 'Duties' },
        { key: 'responsibilities', label: 'Responsibilities' },
        { key: 'status', label: 'Status' },
        { key: 'action', label: 'Action' },
      ]
    },
    createRecord: {}
  };

  public UserRolesRetailers = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'no', label: 'S.No.' },
        { key: 'roleName', label: 'Role Name' },
        { key: 'description', label: 'Description' },
        { key: 'duties', label: 'Duties' },
        { key: 'responsibilities', label: 'Responsibilities' },
        { key: 'status', label: 'Status' },
        { key: 'action', label: 'Action' },
      ]
    },
    createRecord: {}
  };

  public UserRolesVendors = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'no', label: 'S.No.' },
        { key: 'roleName', label: 'Role Name' },
        { key: 'description', label: 'Description' },
        { key: 'duties', label: 'Duties' },
        { key: 'responsibilities', label: 'Responsibilities' },
        { key: 'status', label: 'Status' },
        { key: 'action', label: 'Action' },
      ]
    },
    createRecord: {}
  };

  public UserRolesAdmin = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'no', label: 'S.No.' },
        { key: 'roleName', label: 'Role Name' },
        { key: 'description', label: 'Description' },
        { key: 'duties', label: 'Duties' },
        { key: 'responsibilities', label: 'Responsibilities' },
        { key: 'status', label: 'Status' },
        { key: 'action', label: 'Action' },
      ]
    },
    createRecord: {}
  };
  public UserRolesBreweries = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'no', label: 'S.No.' },
        { key: 'roleName', label: 'Role Name' },
        { key: 'description', label: 'Description' },
        { key: 'duties', label: 'Duties' },
        { key: 'responsibilities', label: 'Responsibilities' },
        { key: 'status', label: 'Status' },
        { key: 'action', label: 'Action' },
      ]
    },
    createRecord: {}
  };
  public Modules = {
    columnsToDisplay: {
      "Modules": [
        { key: 'no', label: 'S.No.' },
        { key: 'moduleName', label: 'Module Name' },
        { key: 'status', label: 'Status' },
        { key: 'action', label: 'Action' },
      ]
    },
    createRecord: {}
  };

  public FeaturePermissionsDepartment = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'no', label: 'S.No.' },
        { key: 'moduleName', label: 'Module Name' },
        { key: 'featureName', label: 'Feature Name' },
        { key: 'role1', label: 'Managing Director' },
        { key: 'role2', label: 'Material Management' },
        { key: 'role3', label: 'Deputy Commissioner' },
        { key: 'role4', label: 'Commissioner' },
        { key: 'action', label: 'Action' },
      ]
    },
    createRecord: {}
  };

  public FeaturePermissionsDistillery = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'no', label: 'S.No.' },
        { key: 'moduleName', label: 'Module Name' },
        { key: 'featureName', label: 'Feature Name' },
        { key: 'role1', label: 'role1' },
        { key: 'role2', label: 'role2' },
        { key: 'role3', label: 'role3' },
        { key: 'action', label: 'Action' },
      ]
    },
    createRecord: {
      "Technical Support": {
        component: 'ManageDistilleryFeatureDialogComponent',
        width: '50%'
      }
    }
  };

  public FeaturePermissionsDepot = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'no', label: 'S.No.' },
        { key: 'moduleName', label: 'Module Name' },
        { key: 'featureName', label: 'Feature Name' },
        { key: 'role1', label: 'Store Officer	' },
        { key: 'role2', label: 'Excise Superintendent Officer' },
        { key: 'role3', label: 'Depot Manager' },
        { key: 'role4', label: 'Data Processing Officer' },
        { key: 'action', label: 'Action' },
      ]
    },
    createRecord: {
      "Technical Support": {
        component: 'ManageDepotFeatureDialogComponent',
        width: '50%'
      }
    }
  };

  public FeaturePermissionsRetailers = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'no', label: 'S.No.' },
        { key: 'moduleName', label: 'Module Name' },
        { key: 'featureName', label: 'Feature Name' },
        { key: 'role1', label: 'Licensee' },
        { key: 'role2', label: 'Supervisor' },
        { key: 'action', label: 'Action' },
      ]
    },
    createRecord: {
      "Technical Support": {
        component: 'ManageRetailerFeatureDialogComponent',
        width: '50%'
      }
    }
  };

  public FeaturePermissionsVendors = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'no', label: 'S.No.' },
        { key: 'moduleName', label: 'Module Name' },
        { key: 'featureName', label: 'Feature Name' },
        { key: 'role1', label: 'Role 1' },
        { key: 'role2', label: 'Role 2' },
        { key: 'action', label: 'Action' },
      ]
    },
    createRecord: {
      "Technical Support": {
        component: 'ManageVendorsFeatureDialogComponent',
        width: '50%'
      }
    }
  };

  public FeaturePermissionsAdmin = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'no', label: 'S.No.' },
        { key: 'moduleName', label: 'Module Name' },
        { key: 'featureName', label: 'Feature Name' },
        { key: 'role1', label: 'Technical Support' },
        { key: 'action', label: 'Action' },
      ]
    },
    createRecord: {
      "Technical Support": {
        component: 'ManageAdminFeatureDialogComponent',
        width: '50%'
      }
    }
  };
  public FeaturePermissionsBreweries = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'no', label: 'S.No.' },
        { key: 'moduleName', label: 'Module Name' },
        { key: 'featureName', label: 'Feature Name' },
        { key: 'role1', label: 'Technical Support' },
        { key: 'action', label: 'Action' },
      ]
    },
    createRecord: {
      "Technical Support": {
        component: 'ManageAdminFeatureDialogComponent',
        width: '50%'
      }
    }
  };

  public FeaturePermissionsIssueTracker = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'no', label: 'S.No.' },
        { key: 'moduleName', label: 'Module Name' },
        { key: 'featureName', label: 'Feature Name' },
        { key: 'role1', label: 'Technical Support' },
        { key: 'action', label: 'Action' },
      ]
    },
    createRecord: {
      "Technical Support": {
        component: 'ManageFeaturePermissionDialogComponent',
        width: '50%',
        moduleName: 'Feature Permission',
        featureName: 'Issue Tracker'
      }
    }
  };

  public UserAccountsDepartment = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'UserId', label: 'User ID' },
        { key: 'firstName', label: 'First Name' },
        { key: 'emailId', label: 'E-Mail ID' },
        { key: 'mobileNumber', label: 'Mobile Number' },
        { key: 'locationType', label: 'Location Type' },
        { key: 'role', label: 'Role' },
        { key: 'locationName', label: 'Location Name' }
      ]
    },
    createRecord: {}
  };

  public UserAccountsDistillery = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'UserId', label: 'User ID' },
        { key: 'firstName', label: 'First Name' },
        { key: 'emailId', label: 'E-Mail ID' },
        { key: 'mobileNumber', label: 'Mobile Number' },
        { key: 'locationType', label: 'Location Type' },
        { key: 'role', label: 'Role' },
        { key: 'locationName', label: 'Location Name' }
      ]
    },
    createRecord: {}
  };

  public UserAccountsDepot = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'UserId', label: 'User ID' },
        { key: 'firstName', label: 'First Name' },
        { key: 'emailId', label: 'E-Mail ID' },
        { key: 'mobileNumber', label: 'Mobile Number' },
        { key: 'locationType', label: 'Location Type' },
        { key: 'role', label: 'Role' },
        { key: 'locationName', label: 'Location Name' }
      ]
    },
    createRecord: {}
  };

  public UserAccountsRetailers = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'retailerId', label: 'Retailer Id' },
        { key: 'assignedDepot', label: 'Assigned Depot' },
        { key: 'gslNo', label: 'Gsl No' },
        { key: 'locationType', label: 'Location Type' },
        { key: 'supervisorName', label: 'Supervisor Name' },
        { key: 'status', label: 'Status' },
        { key: 'button', label: 'Action', type: 'button' },
      ]
    },
    createRecord: {
      "Technical Support": {
        component: 'ManageRetailerRegistrationComponent',
        width: '80%'
      }
    }
  };

  public UserAccountsVendors = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'UserId', label: 'User ID' },
        { key: 'firstName', label: 'First Name' },
        { key: 'emailId', label: 'E-Mail ID' },
        { key: 'mobileNumber', label: 'Mobile Number' },
        { key: 'locationType', label: 'Location Type' },
        { key: 'role', label: 'Role' },
        { key: 'locationName', label: 'Location Name' },
        { key: 'status', label: 'Status' },
        { key: 'button', label: 'Action', type: 'button' },
      ]
    },
    createRecord: {}
  };

  public UserAccountsAdmin = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'UserId', label: 'User ID' },
        { key: 'firstName', label: 'First Name' },
        { key: 'emailId', label: 'E-Mail ID' },
        { key: 'mobileNumber', label: 'Mobile Number' },
        { key: 'locationType', label: 'Location Type' },
        { key: 'role', label: 'Role' },
        { key: 'locationName', label: 'Location Name' }
      ]
    },
    createRecord: {}
  };
  public UserAccountsBreweries = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'UserId', label: 'User ID' },
        { key: 'firstName', label: 'First Name' },
        { key: 'emailId', label: 'E-Mail ID' },
        { key: 'mobileNumber', label: 'Mobile Number' },
        { key: 'locationType', label: 'Location Type' },
        { key: 'role', label: 'Role' },
        { key: 'locationName', label: 'Location Name' }
      ]
    },
    createRecord: {}
  };

  public LiquorPricingStateLevy = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'name', label: 'Name' },
        { key: 'date', label: 'Date' },
      ]
    },
    createRecord: {}
  };

  public LiquorPricingComputedPrices = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'name', label: 'Name' },
        { key: 'date', label: 'Date' },
      ]
    },
    createRecord: {}
  };

  public LiquorPricingOfferedBasicFileUpload = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'name', label: 'Name' },
        { key: 'date', label: 'Date' },
      ]
    },
    createRecord: {}
  };

  public LiquorPricingRecompute = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'name', label: 'Name' },
        { key: 'date', label: 'Date' },
      ]
    },
    createRecord: {}
  };

  public LiquorPricingRecomputeBrandWise = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'name', label: 'Name' },
        { key: 'date', label: 'Date' },
      ]
    },
    createRecord: {}
  };

  public LiquorPricingBasicPriceRules = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'basicPriceLowerValue', label: 'Basic Price Lower Value' },
        { key: 'basicPriceUpperValue', label: 'Basic Price Upper Value' },
        { key: 'basicValue', label: 'Basic Value' },
        { key: 'basicPercent', label: 'Basic Percent' },
        { key: 'category', label: 'Category' },
        { key: 'priceName', label: 'Price Name' },
        { key: 'priceGroupId', label: 'Price Group Id' },
        {
          key: 'action', label: 'Edit Details', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'OpenBasicPricesDialogComponent', width: '50%' } },
          ]
        }
      ]
    },
    createRecord: {
      "Material Management": {
        component: 'OpenBasicPricesDialogComponent',
        width: '50%'
      }
    }
  };

  public LiquorPricingMRPProforma = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'name', label: 'Name' },
        { key: 'date', label: 'Date' },
      ]
    },
    createRecord: {}
  };

  public LiquorPricingOFSApproval = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'name', label: 'Name' },
        { key: 'date', label: 'Date' },
      ]
    },
    createRecord: {}
  };
  public ChallanStatusChallanStatus = {
    columnsToDisplay: {
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'bankTransactionDate', label: 'Bank Transaction Date',type:'date' },
        { key: 'bankName', label: 'Bank Name' },
        // { key: 'cardId', label: 'Card ID' },
        { key: 'departmentId', label: 'Card ID' },
        // { key: 'orderId', label: 'Card ID' },
        // { key: 'paymentId', label: 'Payment ID' },
        { key: 'paymentmethod', label: 'Payment Method' },
        { key: 'amount', label: 'Amount' },
        { key: 'paymentStatus', label: 'Status' ,icon: { type: 'pi pi-circle-on cursor-pointer', yellow: ['Requested'], warning: ['Reviewed'], info: ['Received'], success: ['captured']}},
        { key: 'purpose', label: 'Purpose' },
        { key: 'remittanceMobNum', label: 'Mobile Number' },
        { key: 'remittanceName', label: 'Name' },
        { key: 'supplierName', label: 'Supplier Name' },
        { key: 'updatedDateTime', label: 'Updated At' ,type:'date' },
        {
          key: 'action', label: 'View', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'PaymentConfomationDialogComponent', width: '75%' } },
          ]
        }
      ]
    },
    createRecord: {}
  };
  public PaymentCFMSSuccess = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'depotID', label: 'Department ID' },
        { key: 'indentId', label: 'Indent ID' },
        { key: 'vendorId', label: 'Vendor Name' },
        { key: 'transactionAmount', label: 'Paid Amount' },
        { key: 'status', label: 'Status' },
      ]
    },
    createRecord: {}
  };
  public PaymentCFMSFailure = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'depotID', label: 'Department ID' },
        { key: 'indentId', label: 'Indent ID' },
        { key: 'vendorId', label: 'Vendor Name' },
        { key: 'transactionAmount', label: 'Paid Amount' },
        { key: 'status', label: 'Status' },
      ]
    },
    createRecord: {}
  };
  public PaymentCFMSPending = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'depotID', label: 'Department ID' },
        { key: 'vendorId', label: 'Vendor Name' },
        { key: 'indentId', label: 'Indent ID' },
        { key: 'transactionAmount', label: 'Paid Amount' },
        {
          key: 'action', label: 'Status', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'CfmsPaymentVerifyDialogComponent', width: '75%' } },
          ]
        }
      ]
    },
    createRecord: {}
  };
  public PaymentUnusedCFMS = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'depotID', label: 'Department ID' },
        { key: 'vendorId', label: 'Vendor Name' },
        { key: 'indentId', label: 'Indent ID' },
        { key: 'totalIndentValue', label: 'Paid Amount',path:'taxes' },
        {
          key: 'action', label: 'Status', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'CfmsPaymentVerifyDialogComponent', width: '75%' } },
          ]
        }
      ]
    },
    createRecord: {}
  };
  public PaymentExciseDuty = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'departmentId', label: 'Department ID' },
        { key: 'supplierName', label: 'Supplier Name' },
        { key: 'amount', label: 'Paid Amount' },
        {
          key: 'action', label: 'Status', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'CfmsPaymentVerifyDialogComponent', width: '75%' } },
          ]
        }
      ]
    },
    createRecord: {}
  };
  public PaymentHealPurchaseIndent = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'departmentId', label: 'Department ID' },
        { key: 'supplierName', label: 'Vendor Name' },
        { key: 'amount', label: 'Paid Amount' },
        {
          key: 'action', label: 'Status', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'CfmsPaymentVerifyDialogComponent', width: '75%' } },
          ]
        }
      ]
    },
    createRecord: {}
  };

  public PaymentBarsUpdate = {
    columnsToDisplay: {
      "APBCL Finance": [
        { key: 'no', label: 'S.No.'},
        { key: 'assignedDepot', label:'Depot Name'},
        { key: 'name', label: 'Bar Name' },
        { key: 'vendorType', label:'Vendor Type'},
        { key: 'licenseeName', label:'Licensee Name'},
        { key: 'panNo', label: 'PAN Number' },
        { key: 'aadhar', label: 'Aadhar Number'},
        { key:'gslNo', label:'GSL Number'},
        {
          key: 'action', label: 'Status', action: 'edit', actions: [
            { icon: 'edit', modal: { component: 'BarsUpdateDialogComponent', width: '75%' } },
          ]
        }
      ]
    },
    createRecord: {}
  };

  public LiveDataCases = {
    columnsToDisplay: {
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'batchId', label: 'Batch Id' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'supplierCode', label: 'Supplier Code' },
        { key: 'caseCodeCount', label: 'Cases count',},
        { key: 'healCodesCount', label: 'Heal Count'},
        { key: 'packingDate', label: 'Date',type:'date' }
      ]
    },
    createRecord: {}
  };

  public StockReturnStockReturnDrainout = {
    columnsToDisplay: {
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent No' },
        // { key: 'ceCrNo', label: 'CE Number' },
        { key: 'stockType', label: 'Type' },
        { key: 'sourceEntityId', label: 'Distillery/Supplier' },
        { key: 'destnEntityId', label: 'Depot' },
        { key: 'createdOn', label: 'Date', type: 'date' },
        {
          key: 'status', label: 'Status', modal: { component: 'ManageStockReturnDrainoutDialogComponent', width: '75%' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer text-success', style: 'font-size:12px;cursor:pointer;',
            yellow: ['Created'], info: ['InProgress'], success: ['Approved', 'Release'], danger: ['Cancelled'], warning: ['DrainOut']
          }
        },
      ],
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent No' },
        // { key: 'ceCrNo', label: 'CE Number' },
        { key: 'stockType', label: 'Type' },
        { key: 'sourceEntityId', label: 'Distillery/Supplier' },
        { key: 'destnEntityId', label: 'Depot' },
        { key: 'createdOn', label: 'Date', type: 'date' },
        {
          key: 'status', label: 'Status', modal: { component: 'ManageStockReturnDrainoutDialogComponent', width: '75%' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer text-success', style: 'font-size:12px;cursor:pointer;',
            yellow: ['Created'], info: ['InProgress'], success: ['Approved', 'Release'], danger: ['Cancelled'], warning: ['DrainOut']
          }
        },
      ]
    },
    createRecord: {
      "Distillery Manager": {
        component: 'ManageStockReturnDrainoutDialogComponent',
        width: '75%'
      }
    }
  };

  public StockReturnCompletedStockReturn = {
    columnsToDisplay: {
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent No' },
        // { key: 'ceCrNo', label: 'CE Number' },
        { key: 'stockType', label: 'Type' },
        { key: 'sourceEntityId', label: 'Distillery/Supplier' },
        { key: 'destnEntityId', label: 'Depot' },
        { key: 'createdOn', label: 'Date', type: 'date' },
        {
          key: 'status', label: 'Status', modal: { component: 'ManageStockReturnDrainoutDialogComponent', width: '75%' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer text-success', style: 'font-size:12px;cursor:pointer;',
            yellow: ['Created'], info: ['InProgress'], success: ['Approved', 'Release'], danger: ['Cancelled'], warning: ['DrainOut']
          }
        },
      ],
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent No' },
        // { key: 'ceCrNo', label: 'CE Number' },
        { key: 'stockType', label: 'Type' },
        { key: 'sourceEntityId', label: 'Distillery/Supplier' },
        { key: 'destnEntityId', label: 'Depot' },
        { key: 'createdOn', label: 'Date', type: 'date' },
        {
          key: 'status', label: 'Status', modal: { component: 'ManageStockReturnDrainoutDialogComponent', width: '75%' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer text-success', style: 'font-size:12px;cursor:pointer;',
            yellow: ['Created'], info: ['InProgress'], success: ['Approved', 'Release'], danger: ['Cancelled'], warning: ['DrainOut']
          }
        },
      ]
    },
    createRecord: {}
  };

  public StockReturnCompletedStockDrainout = {
    columnsToDisplay: {
      "Distillery Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent No' },
        // { key: 'ceCrNo', label: 'CE Number' },
        { key: 'stockType', label: 'Type' },
        { key: 'sourceEntityId', label: 'Distillery/Supplier' },
        { key: 'destnEntityId', label: 'Depot' },
        { key: 'createdOn', label: 'Date', type: 'date' },
        {
          key: 'status', label: 'Status', modal: { component: 'ManageStockReturnDrainoutDialogComponent', width: '75%' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer text-success', style: 'font-size:12px;cursor:pointer;',
            yellow: ['Created'], info: ['InProgress'], success: ['Approved', 'Release'], danger: ['Cancelled'], warning: ['DrainOut']
          }
        },
      ],
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'indentId', label: 'Indent No' },
        // { key: 'ceCrNo', label: 'CE Number' },
        { key: 'stockType', label: 'Type' },
        { key: 'sourceEntityId', label: 'Distillery/Supplier' },
        { key: 'destnEntityId', label: 'Depot' },
        { key: 'createdOn', label: 'Date', type: 'date' },
        {
          key: 'status', label: 'Status', modal: { component: 'ManageStockReturnDrainoutDialogComponent', width: '75%' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer text-success', style: 'font-size:12px;cursor:pointer;',
            yellow: ['Created'], info: ['InProgress'], success: ['Approved', 'Release'], danger: ['Cancelled'], warning: ['DrainOut']
          }
        },
      ]
    },
    createRecord: {}
  };
  public ReportsIssuesReportAnIssue = {
    columnsToDisplay: {
      "Distillery Manager": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Description' },
        { key: 'createdAt', label: 'Created',type: 'dateTime' },
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
          ]
        }
      ],
      "Material Management": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Description' },
        { key: 'createdAt', label: 'Created',type: 'dateTime' },
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
          ]
        }
      ],
      "Depot Manager": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Description' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
          ]
        }
      ],
      "Distillery Officer": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Description' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
          ]
        }
      ],
      "Brewery Manager": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Description' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
          ]
        }
      ],
      "NonLocal Brewery Manager": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Description' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
          ]
        }
      ],
    },
    createRecord: {
      "Depot Manager": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Distillery Manager": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Distillery Officer": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Brewery Manager": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "NonLocal Brewery Manager": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      }
    }
  };
  public ReportsIssuesAllIssues = {
    columnsToDisplay: {
      "Brewery Manager": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Title' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        { key: 'status', label: 'Status', icon: { yellow: ['Opened'], black: ['Reopened'], danger: ['Blocked'],warning: ['Closed'], info: ['InProgress'] } },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
            { icon: 'edit', modal: { component: 'ReportIssuesDialogComponent', width: '75%' } },
          ]
        }
      ],
      "NonLocal Brewery Manager": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Title' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        { key: 'status', label: 'Status', icon: { yellow: ['Opened'], black: ['Reopened'], danger: ['Blocked'],warning: ['Closed'], info: ['InProgress'] } },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
            { icon: 'edit', modal: { component: 'ReportIssuesDialogComponent', width: '75%' } },
          ]
        }
      ],
    },
    createRecord: {
      "Brewery Manager": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Gate Security": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Brewery Officer": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "NonLocal Brewery Officer": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Data Processing Officer": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Store Officer": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Depot Manager": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Excise Superintendent Officer": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Security Officer": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Distllery Manager": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Supplier Line Manager": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Distillery Officer": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Distillery Chemist": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Production Manager": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "NonLocal Supplier Manager": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Supplier Manager": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Distillery Tester": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Supplier Officer": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Issues Administrator": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Distillery Manager": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "Issues Support": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
      "NonLocal Brewery Manager": {
        component: 'ReportIssuesDialogComponent',
        width: '75%'
      },
    }
  };
  public ReportsIssuesTodaysIssues = {
    columnsToDisplay: {
      "Issues Administrator": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Title' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        { key: 'status', label: 'Status', icon: { yellow: ['Opened'], black: ['Reopened'], danger: ['Blocked'],warning: ['Closed'], info: ['InProgress'] } },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
            { icon: 'edit', modal: { component: 'ReportIssuesDialogComponent', width: '75%' } },
          ]
        }
      ],
    },
    createRecord: {}
  }
  public ReportsIssuesBlockedIssues = {
    columnsToDisplay: {
      "Issues Administrator": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Title' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        { key: 'status', label: 'Status', icon: { yellow: ['Opened'], black: ['Reopened'], danger: ['Blocked'],warning: ['Closed'], info: ['InProgress'] } },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
            { icon: 'edit', modal: { component: 'ReportIssuesDialogComponent', width: '75%' } },
          ]
        }
      ],
    },
    createRecord: {}
  }
  public ReportsIssuesYesterdaysIssues = {
    columnsToDisplay: {
      "Issues Administrator": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Title' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        { key: 'status', label: 'Status', icon: { yellow: ['Opened'], black: ['Reopened'], danger: ['Blocked'],warning: ['Closed'], info: ['InProgress'] } },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
            { icon: 'edit', modal: { component: 'ReportIssuesDialogComponent', width: '75%' } },
          ]
        }
      ],
    },
    createRecord: {}
  }
  public ReportsIssuesLastWeeksIssues = {
    columnsToDisplay: {
      "Issues Administrator": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Title' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        { key: 'status', label: 'Status', icon: { yellow: ['Opened'], black: ['Reopened'], danger: ['Blocked'],warning: ['Closed'], info: ['InProgress'] } },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
            { icon: 'edit', modal: { component: 'ReportIssuesDialogComponent', width: '75%' } },
          ]
        }
      ],
    },
    createRecord: {}
  }
  public ReportsIssuesLast2WeeksIssues = {
    columnsToDisplay: {
      "Issues Administrator": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Title' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        { key: 'status', label: 'Status', icon: { yellow: ['Opened'], black: ['Reopened'], danger: ['Blocked'],warning: ['Closed'], info: ['InProgress'] } },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
            { icon: 'edit', modal: { component: 'ReportIssuesDialogComponent', width: '75%' } },
          ]
        }
      ],
    },
    createRecord: {}
  }
  public ReportsIssuesMonthsIssues = {
    columnsToDisplay: {
      "Issues Administrator": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Title' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        { key: 'status', label: 'Status', icon: { yellow: ['Opened'], black: ['Reopened'], danger: ['Blocked'],warning: ['Closed'], info: ['InProgress'] } },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
            { icon: 'edit', modal: { component: 'ReportIssuesDialogComponent', width: '75%' } },
          ]
        }
      ],
    },
    createRecord: {}
  }
  public ReportsIssuesIssuesbyAssignee = {
    columnsToDisplay: {
      "Issues Administrator": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Title' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        { key: 'status', label: 'Status', icon: { yellow: ['Opened'], black: ['Reopened'], danger: ['Blocked'],warning: ['Closed'], info: ['InProgress'] } },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
            { icon: 'edit', modal: { component: 'ReportIssuesDialogComponent', width: '75%' } },
          ]
        }
      ],
    },
    createRecord: {}
  }
  public ReportsIssuesDeletedIssues = {
    columnsToDisplay: {
      "Issues Administrator": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Title' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        { key: 'status', label: 'Status', icon: { yellow: ['Opened'], black: ['Reopened'], danger: ['Blocked'],warning: ['Closed'], info: ['InProgress'] } },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
            { icon: 'edit', modal: { component: 'ReportIssuesDialogComponent', width: '75%' } },
          ]
        }
      ],
    },
    createRecord: {}
  }
  public ReportsIssuesOpenIssues = {
    columnsToDisplay: {
      "Issues Administrator": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Title' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        { key: 'status', label: 'Status', icon: { yellow: ['Opened'], black: ['Reopened'], danger: ['Blocked'],warning: ['Closed'], info: ['InProgress'] } },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
            { icon: 'edit', modal: { component: 'ReportIssuesDialogComponent', width: '75%' } },
          ]
        }
      ],
    },
    createRecord: {}
  }
  public ReportsIssuesIssuesInProgress = {
    columnsToDisplay: {
      "Issues Administrator": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Title' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        { key: 'status', label: 'Status', icon: { yellow: ['Opened'], black: ['Reopened'], danger: ['Blocked'],warning: ['Closed'], info: ['InProgress'] } },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
            { icon: 'edit', modal: { component: 'ReportIssuesDialogComponent', width: '75%' } },
          ]
        }
      ],
    },
    createRecord: {}
  }
  public ReportsIssuesClosedIssues = {
    columnsToDisplay: {
      "Issues Administrator": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Title' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        { key: 'status', label: 'Status', icon: { yellow: ['Opened'], black: ['Reopened'], danger: ['Blocked'],warning: ['Closed'], info: ['InProgress'] } },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
            { icon: 'edit', modal: { component: 'ReportIssuesDialogComponent', width: '75%' } },
          ]
        }
      ],
    },
    createRecord: {}
  }
  public ReportsIssuesDistilleryIssues = {
    columnsToDisplay: {
      "Issues Administrator": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Title' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        { key: 'status', label: 'Status', icon: { yellow: ['Opened'], black: ['Reopened'], danger: ['Blocked'],warning: ['Closed'], info: ['InProgress'] } },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
            { icon: 'edit', modal: { component: 'ReportIssuesDialogComponent', width: '75%' } },
          ]
        }
      ],
    },
    createRecord: {}
  }
  public ReportsIssuesDepotIssues = {
    columnsToDisplay: {
      "Issues Administrator": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Title' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        { key: 'status', label: 'Status', icon: { yellow: ['Opened'], black: ['Reopened'], danger: ['Blocked'],warning: ['Closed'], info: ['InProgress'] } },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
            { icon: 'edit', modal: { component: 'ReportIssuesDialogComponent', width: '75%' } },
          ]
        }
      ],
    },
    createRecord: {}
  }
  public ReportsIssuesBreweryIssues = {
    columnsToDisplay: {
      "Issues Administrator": [
        { key: 'no', label: 'Id' },
        { key: 'sortDescription', label: 'Title' },
        { key: 'createdAt', label: 'Created' ,type: 'dateTime'},
        { key: 'updatedAt', label: 'Updated',type: 'dateTime' },
        { key: 'entityType', label: 'Entity Type' },
        { key: 'entityId', label: 'Entity Id ' },
        { key: 'status', label: 'Status', icon: { yellow: ['Opened'], black: ['Reopened'], danger: ['Blocked'],warning: ['Closed'], info: ['InProgress'] } },
        {
          key: 'action', label: 'Action', action: 'print', actions: [
            { icon: 'visibility', modal: { component: 'ReportIssuesViewComponent', width: '75%' } },
            { icon: 'edit', modal: { component: 'ReportIssuesDialogComponent', width: '75%' } },
          ]
        }
      ],
    },
    createRecord: {}
  }
  public ReleaseNotesWeb = {
    columnsToDisplay: {
      "Issues Administrator": [
        { key: 'no', label: 'S.No.' },
        // { key: 'entity_type', label: 'Entity Type' },
        // { key: 'roleName', label: 'Role' },
        { key: 'versionNo', label: 'Version Number' },
        { key: 'releaseType', label: 'Release Type' },
        { key: 'createdOn', label: 'Created Date', type: 'date' },
        {
          key: 'status', label: 'Action', type: 'link',
          modal: { component: 'ManageReleaseNotesDialogComponent', width: '50%' }, icon: {
            type: 'pi pi-circle-on cursor-pointer',
            style: 'font-size:12px;cursor:pointer;', success: ['Approved'], yellow: ['Created'], danger: ['Rejected']
          }
        },      ]
    },
    createRecord: {
      "Issues Administrator": {
        component: 'ManageReleaseNotesDialogComponent',
        width: '50%'
      },
    }
  };
  public ReleaseNotesMobile = {
    columnsToDisplay: {
      "Issues Administrator": [
        { key: 'no', label: 'S.No.' },
        // { key: 'entity_type', label: 'Entity Type' },
        // { key: 'roleName', label: 'Role' },
        { key: 'versionNo', label: 'Version Number' },
        { key: 'releaseType', label: 'Release Type' },
        { key: 'createdOn', label: 'Created Date', type: 'date' },
        {
          key: 'status', label: 'Action', type: 'link',
          modal: { component: 'ManageReleaseNotesDialogComponent', width: '50%' }, icon: {
            type: 'pi pi-circle-on cursor-pointer',
            style: 'font-size:12px;cursor:pointer;', success: ['Approved'], yellow: ['Created'], danger: ['Rejected']
          }
        },      ]
    },
    createRecord: {
      "Issues Administrator": {
        component: 'ManageReleaseNotesDialogComponent',
        width: '50%'
      },
    }
  };
  public LogsLogs = {
    columnsToDisplay: {
      "Technical Support": [
        { key: 'no', label: 'S.No.' },
        { key: 'createdOn', label: 'Date', type: 'date'},
        { key: 'Key', label: 'Download', type: 'download' },
      ]
    },
    createRecord: {
    }
  };
  public ThroughTransportPermitTTP = {
    columnsToDisplay: {
      "Through Transport Permit": [
        { key: 'no', label: 'S.No.' },
        { key: 'ttpNo', label: 'Permit No'},
        { key: 'transporterName', label: 'Transporter Name'},
        { key: 'importPermitNo', label: 'Import Permit No' },
        { key: 'exportPermitNo', label: 'Export Permit No' },
        { key: 'consignmentOrigin', label: 'Source' },
        { key: 'consignmentDestination', label: 'Destination' },
        { key: 'importerName', label: 'Importer Name' },
        { key: 'exporterName', label: 'Exporter Name' },
        { key: 'importPermitValidFrom', label: 'Import Valid From', type: 'date' },
        { key: 'importPermitValidTo', label: 'Import Valid To', type: 'date' },
        { key: 'exportPermitValidFrom', label: 'Export Valid From', type: 'date' },
        { key: 'exportPermitValidTo', label: 'Export Valid To', type: 'date' },
        { key: 'startingCheckpoint', label: 'Start Checkpost' },
        { key: 'endCheckpoint', label: 'End Checkpost' },
        // { key: 'escot', label: 'Escot' },
        // { key: 'download', label: 'Download' },
        { key: 'createdOn', label: 'Created On', type: 'date' },
        {
          key: 'status', label: 'Status', modal: { component: 'TtpDetailsDialogComponent', width: '80%' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer text-success', style: 'font-size:12px;cursor:pointer;',
            yellow: ['Requested'], warning: ['Reviewed'], success: ['Approved'], danger: ['Rejected'], brown:['Resend'], black: ['Revalidated']
          }
        },
      ],
      "Additional Commissioner": [
        { key: 'no', label: 'S.No.' },
        { key: 'entityId', label: 'Supplier' },
        { key: 'ttpNo', label: 'Permit No'},
        { key: 'transporterName', label: 'Transporter Name'},
        { key: 'importPermitNo', label: 'Import Permit No' },
        { key: 'exportPermitNo', label: 'Export Permit No' },
        { key: 'consignmentOrigin', label: 'Source' },
        { key: 'consignmentDestination', label: 'Destination' },
        { key: 'importerName', label: 'Importer Name' },
        { key: 'exporterName', label: 'Exporter Name' },
        { key: 'importPermitValidFrom', label: 'Import Valid From', type: 'date' },
        { key: 'importPermitValidTo', label: 'Import Valid To', type: 'date' },
        { key: 'exportPermitValidFrom', label: 'Export Valid From', type: 'date' },
        { key: 'exportPermitValidTo', label: 'Export Valid To', type: 'date' },
        { key: 'startingCheckpoint', label: 'Start Checkpost' },
        { key: 'endCheckpoint', label: 'End Checkpost' },
        // { key: 'escot', label: 'Escot' },
        // { key: 'download', label: 'Download' },
        { key: 'createdOn', label: 'Created On', type: 'date' },
        {
          key: 'status', label: 'Status', modal: { component: 'TtpDetailsDialogComponent', width: '80%' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer text-success', style: 'font-size:12px;cursor:pointer;',
            yellow: ['Requested'], warning: ['Reviewed'], success: ['Approved'], danger: ['Rejected'], brown:['Resend'], black: ['Revalidated']
          }
        },
      ],
      "Senior Assistant": [
        { key: 'no', label: 'S.No.' },
        { key: 'entityId', label: 'Supplier' },
        { key: 'ttpNo', label: 'Permit No'},
        { key: 'transporterName', label: 'Transporter Name'},
        { key: 'importPermitNo', label: 'Import Permit No' },
        { key: 'exportPermitNo', label: 'Export Permit No' },
        { key: 'consignmentOrigin', label: 'Source' },
        { key: 'consignmentDestination', label: 'Destination' },
        { key: 'importerName', label: 'Importer Name' },
        { key: 'exporterName', label: 'Exporter Name' },
        { key: 'importPermitValidFrom', label: 'Import Valid From', type: 'date' },
        { key: 'importPermitValidTo', label: 'Import Valid To', type: 'date' },
        { key: 'exportPermitValidFrom', label: 'Export Valid From', type: 'date' },
        { key: 'exportPermitValidTo', label: 'Export Valid To', type: 'date' },
        { key: 'startingCheckpoint', label: 'Start Checkpost' },
        { key: 'endCheckpoint', label: 'End Checkpost' },
        // { key: 'escot', label: 'Escot' },
        // { key: 'download', label: 'Download' },
        { key: 'createdOn', label: 'Created On', type: 'date' },
        {
          key: 'status', label: 'Status', modal: { component: 'ManageTtpDialogComponent', width: '80%' },
          icon: {
            type: 'pi pi-circle-on cursor-pointer text-success', style: 'font-size:12px;cursor:pointer;',
            yellow: ['Requested'], warning: ['Reviewed'], success: ['Approved'], danger: ['Rejected'], brown:['Resend'], black: ['Revalidated']
          }
        },
      ]
    },
    createRecord: {
      "Through Transport Permit": {
        component: 'ManageTtpDialogComponent',
        width: '80%'
      },
    }
  };
  public ThroughTransportPermitRoute = {
    columnsToDisplay: {
      "Senior Assistant": [
        { key: 'name', label: 'Name'},
        { key: 'description', label: 'Route'},
        { key: 'startPoint', label: 'Source Location' },
        { key: 'endPoint', label: 'Destination Location' },
        { key: 'createdOn', label: 'Created On', type: 'date' },
        {
          key: 'action', label: 'Action', action: 'edit', actions: [
            {
              icon: 'edit', modal: { component: 'ManageTtpRouteDialogComponent', width: '50%' }
            },
          ]
        },
      ]
    },
    createRecord: {
      "Through Transport Permit": {
        component: 'ManageTtpRouteDialogComponent',
        width: '50%'
      },
    }
  };
  public ThroughTransportPermitTTPRevalidation = {
    columnsToDisplay: {
      "Senior Assistant": [
        { key: 'permitNo', label: 'Permit No'},
        // { key: 'transporterName', label: 'Transporter Name'},
        // { key: 'importPermitNo', label: 'Import Permit No' },
        // { key: 'exportPermitNo', label: 'Export Permit No' },
        // { key: 'sourceEntityId', label: 'Source' },
        // { key: 'destinationEntityId', label: 'Destination' },
        // { key: 'importerName', label: 'Importer Name' },
        // { key: 'exporterName', label: 'Exporter Name' },
        { key: 'validFrom', label: 'Valid From', type: 'date' },
        { key: 'validTo', label: 'Valid To', type: 'date' },
        { key: 'status', label: 'Status', modal: { component: 'ManageTtpRevalidationDialogComponent', width: '50%' }, icon: { success: ['Approved'], black: ['Revalidated']} },
      ]
    },
    createRecord: {
      "Through Transport Permit": {
        component: 'ManageTtpRevalidationDialogComponent',
        width: '50%'
      },
    }
  };
  public ThroughTransportPermitTTPLocation = {
    columnsToDisplay: {
      "Senior Assistant": [
        { key: 'no', label: 'S.No.' },
        { key: 'locationCode', label: 'Location Code'},
        { key: 'locationName', label: 'Name'},
        { key: 'address1', label: 'Address 1' },
        { key: 'address2', label: 'Address 2' },
        { key: 'city', label: 'City' },
        {
          key: 'edit', label: 'Edit', action: 'edit', actions: [
            {
              icon: 'edit', modal: { component: 'ManageTtpLocationDialogComponent', width: '50%' }
            },
          ]
        },
      ]
    },
    createRecord: {
      "Senior Assistant": {
        component: 'ManageTtpLocationDialogComponent',
        width: '50%'
      },
    }
  };
  public ThroughTransportPermitTTPUsers = {
    columnsToDisplay: {
      "Senior Assistant": [
        { key: 'no', label: 'S.No.' },
        { key: 'userName', label: 'User ID'},
        { key: 'fullName', label: 'Full Name'},
        { key: 'emailId', label: 'Email', path: 'emailIds' },
        { key: 'number', label: 'Mobile Number', path: 'mobileNumbers' },
        { key: 'locationCode', label: 'Location Code' },
        { key: 'status', label: 'Status' },
        {
          key: 'edit', label: 'Edit', action: 'edit', actions: [
            {
              icon: 'edit', modal: { component: 'ManageTtpUsersDialogComponent', width: '50%' }
            },
          ]
        },
      ]
    },
    createRecord: {
      "Senior Assistant": {
        component: 'ManageTtpUsersDialogComponent',
        width: '50%'
      },
    }
  };
  public LicenseeShipmentsLicenseeShipments = {
    columnsToDisplay: {
      "Depot Manager": [
        { key: 'no', label: 'S.No.' },
        { key: 'tpNo', label: 'Transport Permit', type: 'link', modal: { component: 'IcdcCaseBarcodeDialogComponent', width: '50%' } },
        { key: 'consignmentType', label: 'Consignment Type', type: 'link', modal: { component: 'IcdcDetailDialogComponent', width: '65%' } },
        { key: 'destnEntityId', label: 'Retailer Code' },
        { key: 'vehicleNo', label: 'Vehicle No', path: 'transporterDetails' },
        { key: 'validityDateTime', label: 'Validity Date', type: 'date' },
        // {
        //   key: 'edit', label: 'Packing List', action: 'edit', actions: [
        //     {
        //       icon: 'edit', modal: { component: 'IcdcPackingListDialogComponent', width: '80%' }
        //     },
        //   ]
        // },
        { key: 'printStatus', label: 'Print Status' },
        {
          key: 'status', label: 'Status', icon: { success: ['InProgress','TPReceived'], yellow: ['Created'], warning: ['InTransit'], brown: ['Reached'], info: ['Delayed'],danger:['Rejected'], },
          success: ['ReachedInTime'], danger: ['NotReachedInTime'], modal: { component: 'IcdcCaseBarcodeDialogComponent', width: '65%' }
        },
        // {
        //   key: 'action', label: 'Action', action: 'edit', actions: [
        //     {
        //       icon: 'edit', modal: { component: 'ManageTpRequestDialogComponent', width: '80%' }, notEqRule: { key: 'status', value: 'InUse' }
        //     },
        //   ]
        // },
      ]
    },
    createRecord: {
    }
  };
  public BarDepotReassignBars = {
    columnsToDisplay: {
      "Material Management": [
        { key: 'no', label: 'S.No.' },
        { key: 'vendorId', label: 'Vendor Id' },
        { key: 'assignedDepot', label: 'Assigned Depot' },
        { key: 'status', label: 'Status' },
        { key: 'button', label: 'Action', type: 'button' },
      ]
    },
    createRecord: {
    }
  };

}
