import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Shops } from 'src/app/core/models/licensee-profiles/shops.model';
import { LicenseeProfilesService } from 'src/app/core/services/licensee-profiles.service';

@Component({
  selector: 'app-open-bank-details-dialog',
  templateUrl: './open-bank-details-dialog.component.html',
  styleUrls: ['./open-bank-details-dialog.component.scss']
})
export class OpenBankDetailsDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'no',
    'bankName',
    'accountHolderName',
    'accountNumber',
    'ifscCode',
    'branch',
    'town'
  ];
  dataSource: MatTableDataSource<Shops> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;

  constructor(public dialogRef: MatDialogRef<OpenBankDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,
    private licenseeProfilesService: LicenseeProfilesService) { }

  ngOnInit(): void {
    this.getStockDetails();
  }

  getStockDetails() {
    this.licenseeProfilesService.getShopsDetails(this.offset, this.limit, this.search).subscribe((res: any) => {
      console.log('res', res);
      this.dataSource = new MatTableDataSource(res.data);
    });
  }

}
