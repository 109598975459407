import { ProductionService } from 'src/app/core/services/production.service';
import { Component, OnInit, Inject } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ProductionLine } from 'src/app/core/models/production/production-line.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonsService } from 'src/app/core/services/commons.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-target-production-details-dialog',
  templateUrl: './target-production-details-dialog.component.html',
  styleUrls: ['./target-production-details-dialog.component.scss']
})

 export class TargetProductionDetailsDialogComponent implements OnInit{

  @Input() featuresData: any;

  targetProductionForm: FormGroup;
  isEditable: boolean = false;
  error: string;
  targetCases: ProductionLine;
  saveDisabled: boolean;
  batchQuantity: any;
  roleName: any = '';

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<TargetProductionDetailsDialogComponent>, private productionService: ProductionService,public util: UtilService, 
              private commonsService: CommonsService, private messageService: MessageService, @Inject(MAT_DIALOG_DATA)public data) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    this.data.producedCasesCount = this.data.producedCasesCount ? this.data.producedCasesCount : 0;

    this.targetProductionForm = this.fb.group({
      targetProduction: '',
      productionId: this.data.productionId
    });
    if (this.data) {
      this.targetProductionForm.patchValue({
        targetProduction: this.data.targetCasesCount,
        productionId: this.data.productionId
      });
    }

    const req_Data = {
      "action": "common_apis",
      "q":'{"data": {"action": "get_batch_qty", "value": "' + this.data.batchId + '"}}'
    }

    this.commonsService.getBatchQuantity(req_Data).subscribe(res => {
        this.batchQuantity = res['get_batch_qty'];
    })
  }

  // isTarget() { 
  //  return Number(this.targetProductionForm.get('targetProduction').value) > Number(this.batchQuantity) - Number(this.data.producedCasesCount);
  // }

  targetChange() {
      console.log(Number(this.targetProductionForm.get('targetProduction').value), Number(this.batchQuantity), Number(this.data.producedCasesCount) )
  }

  putTargetProduction(){
    this.saveDisabled = true;
    const obj = {
      action: 'target_prod',
      data: {
        ...this.targetProductionForm.value,
        targetProduction: parseInt(this.targetProductionForm.value.targetProduction)
      }
    };
    this.productionService.putProduction(obj).subscribe((res: any) => {
      this.dialogRef.close('Production Line Updated Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

}

