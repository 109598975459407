import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

const baseUrl = environment.baseUrl;
const locationUrl = baseUrl + 'location';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Location'] && userModules.mod_feats['Location'].features ? userModules.mod_feats['Location'].features.map(feat => feat.feature_name) : [];


@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor(private http: HttpClient) { }

  getLocationDetails(offset, limit, search) {
    return this.http.get('assets/samples/location.json');
  }


  getLocationFeatures() {
    const headerDict = {
      feature
    }
    return this.http.get(locationUrl, { headers: headerDict });
  }

  putLocation(obj) {
    return this.http.put(locationUrl, obj);
  }

  postLocation(obj){
    return this.http.post(locationUrl, obj);
  }

  postDepot(obj) {
    return this.http.post(locationUrl, obj);
  }

  putDepot(obj) {
    return this.http.put(locationUrl, obj);
  }

  postLab(obj) {
    return this.http.post(locationUrl, obj);
  }

  putLab(obj) {
    return this.http.put(locationUrl, obj);
  }

  postVendor(obj) {
    return this.http.post(locationUrl, obj);
  }

  putVendor(obj) {
    return this.http.put(locationUrl, obj);
  }
}
