import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Get_Indents, Indents } from 'src/app/core/models/bcl-indents/indents.model';
import { BclIndentsService } from 'src/app/core/services/bcl-indents.service';

@Component({
  selector: 'app-open-indents-dialog',
  templateUrl: './open-indents-dialog.component.html',
  styleUrls: ['./open-indents-dialog.component.scss']
})
export class OpenIndentsDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'exciseCircle',
    'noOfOutlets'
  ];

  dataSource: MatTableDataSource<Indents> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;

  constructor(public dialogRef: MatDialogRef<OpenIndentsDialogComponent>,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA)public data,
              private bclIndentsService: BclIndentsService ){ }

  ngOnInit(): void {
    this.getOutletsDetails();
  }

  getOutletsDetails() {
    this.bclIndentsService.getIndentsDetails(this.offset, this.limit, this.search).subscribe ((res: Get_Indents) => {
      console.log('res', res);
      this.dataSource = new MatTableDataSource(res.data);
    });
  }

  dialogClose(): void{
    this.dialogRef.close();
  }

}
