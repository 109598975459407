import { ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { StockService } from 'src/app/core/services/stock.service';
import { CommonsService } from 'src/app/core/services/commons.service';


@Component({
  selector: 'app-manage-virtual-stock-dialog',
  templateUrl: './manage-virtual-stock-dialog.component.html',
  styleUrls: ['./manage-virtual-stock-dialog.component.scss']
})
export class ManageVirtualStockDialogComponent implements OnInit {
  form: FormGroup;
  loader: any = false;
  public filteredProducts: ReplaySubject<any> = new ReplaySubject<any>(1);
  public productFitlerCtrl: FormControl = new FormControl();
  public filteredRetailers: ReplaySubject<any> = new ReplaySubject<any>(1);
  public retailerFitlerCtrl: FormControl = new FormControl();
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public _onDestroy = new Subject<void>();
  displayedColumns: string[] = ['no', 'productCode', 'bottles', 'cases', 'quantity', 'rationQuantity'];
  @ViewChild('productSelect') productSelect: MatSelect;
  @ViewChild('retailerSelect') retailerSelect: MatSelect;
  @ViewChild('dialogRefTemplate') dialogRefTemplate: TemplateRef<any>;
  products: any = [];
  productDataList: any = [];
  productList: any;
  brandList: any;
  dialogRef1: MatDialogRef<any, any>;
  sizeList: any = [];
  productsList:any=[];
  productListData:any=[];
  userInfo: any = {};
  productDetails: FormArray;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ManageVirtualStockDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private stockService: StockService,
    private commonService: CommonsService,
    private spinner: NgxSpinnerService,
    public util:UtilService,
    private messageService:MessageService,
    private myElement: ElementRef
  ) {
    this.userInfo = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
  }

  ngOnInit(): void {
    console.log('data==>', this.data);
    this.getProducts();
    this.form = this.fb.group({
      retailerCode: ['',Validators.required],
      productDetails: this.fb.array([this.updateProductDetails()]),
    });
    this.productDetails = this.form.get('productDetails') as FormArray;
    this.productFitlerCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProducts();
    });
  }
  updateProductDetails(){
    return this.fb.group({
      brandCode: ['', Validators.required],
      productCode: ['', Validators.required],
      unitsPerCase: ['', Validators.required],
      size: ['', Validators.required],
      virtualCases: ['', Validators.required],
      virtualBottles: ['', Validators.required],
      tempCases: '',
      tempBottles: '',
    });
  }
  get f() {
    return this.form.controls;
  }

  add() {
    const control = <FormArray>this.form.controls['productDetails'];
    control.push(this.updateProductDetails());
  }

  remove(i: number) {
    const control = <FormArray>this.form.controls['productDetails'];
    control.removeAt(i);
  }

  filterProducts() {
    if (!this.brandList || (this.brandList && this.brandList.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.productFitlerCtrl.value;
    if (!search) {
      this.filteredProducts.next(this.brandList);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredProducts.next(
      this.brandList.filter(product => JSON.stringify(product).toLowerCase().indexOf(search) > -1)
    );
  }
  getProducts() {
    this.spinner.show();
    let req_Data: any = {};
    req_Data = {
      "action": "common_apis",
      "q": '{"data": ["get_ctel_products"]}'
    };
    this.commonService.getCommonEntites(req_Data).subscribe((res: any) => {
      if (!res['get_ctel_products']) {
        this.spinner.hide();
        return;
      }
      this.productList = res['get_ctel_products'];
      this.brandList = this.productList.map(res => res.brandCode);
      this.products = this.productList.map(res => res.productCode);
      this.products = this.products.filter((res, i) => {
        return i == this.products.lastIndexOf(res);
      });
      this.brandList = this.brandList.filter((res, i) => {
        return i == this.brandList.lastIndexOf(res);
      });
      this.filteredProducts.next(this.brandList);
      this.brandList.forEach(res => {
        if (this.brandList.indexOf(res.brandName) !== -1 && this.sizeList.indexOf(res.size) == -1) {
          this.sizeList.push(res.size);
        }
      });
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }
  async create() {
    const req_Data = {
      action: 'create_virtual_stock',
      data: this.form.value
    }
    req_Data.data.productDetails.forEach(el => {
      el.virtualCases = el.tempCases;
      el.virtualBottles = el.tempBottles;
      delete el.tempCases;
      delete el.tempBottles;
    });
    this.loader = true;
    this.spinner.show();
    await this.stockService.postMethod(req_Data).subscribe((res: any) => {
      if(res.status && res.status === 'SUCCESS'){
        this.dialogRef.close(`Indent Created Successfully`);
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Indent Creation Failed' });
        this.loader = false;
      }
      this.spinner.hide();
      this.loader = false;
    }, (err) => {
      this.loader = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
      this.spinner.hide();
    });
  }

  updateIndent() {
    // this.saveDisabled = true;
    // this.purchaseOrdersService.putPurchaseOrder(this.licenseeIndentsForm.value).subscribe((res: any) => {
    //   this.dialogRef.close('Supply Updated Successfully');
    // }, (err) => {
    //   this.error = err.message;
    // });
  }
  selectBrand(i, evt: any) {
    this.productListData[i] = [];
    const brandCode = evt.value;
    this.productList.forEach(el => {
      if (el.productCode && brandCode == el.brandCode ) {
        this.productListData[i].push(el.productCode);
      }
    });
  }
  selectProduct(i): any {
    const productDetails = this.form.get('productDetails') as FormArray;
    const productDetailsControls = productDetails.controls[i]['controls'];
    const productData: any = this.util.productCodeToDetails(productDetailsControls.productCode.value);
    productDetailsControls.unitsPerCase.setValue(productData.unitsPerCase);
    productDetailsControls.size.setValue(this.util.getSizeInMl(productData.size));
  }
  isDisabled(value) {
    const productDetails = this.form.get('productDetails') as FormArray;
    return productDetails.controls.some((el: any) => el.controls.productCode.value === value);
  }
  validateMin(i, controlName): any {
    const productDetails = this.form.get('productDetails') as FormArray;
    const stock = productDetails.controls[i]['controls'][controlName];
    if (stock.value == 0) {
      stock.setValue('');
    }
  }
  convertBottlesToCases(i): any {
    const productDetails = this.form.get('productDetails') as FormArray;
    const productDetailsControls = productDetails.controls[i]['controls'];
    const upc = productDetailsControls.unitsPerCase.value;
    const cases: any = productDetailsControls.virtualCases.value ? productDetailsControls.virtualCases.value : 0;
    const bottles: any = productDetailsControls.virtualBottles.value ? productDetailsControls.virtualBottles.value : 0;
    let newCases = cases;
    let newBottles = bottles;
    if (bottles >= upc) { 
      newBottles = bottles % upc;
      newCases = cases + Math.floor(bottles/upc);
    }
    productDetailsControls.tempCases.setValue(newCases);
    productDetailsControls.tempBottles.setValue(newBottles);
  }
}
