import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { Shipments } from 'src/app/core/models/shipments/shipments.model';
import { ShipmentsService } from 'src/app/core/services/shipments.service';

@Component({
  selector: 'app-ip-status-dialog',
  templateUrl: './ip-status-dialog.component.html',
  styleUrls: ['./ip-status-dialog.component.scss']
})
export class IpStatusDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'importPermit'
  ];
  validityForm: FormGroup;
  dataSource: MatTableDataSource<Shipments> = new MatTableDataSource([]);
  todayDate: Date;
  roleName: any;
  showPrint: any = false;
  printId: any;

  constructor(private fb: FormBuilder,private messageService: MessageService,
    private spinner: NgxSpinnerService, public dialogRef: MatDialogRef<IpStatusDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, private shipmentService: ShipmentsService) { 
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role
  }


  ngOnInit(): void {
    this.todayDate = new Date();
    this.validityForm = this.fb.group({
      validityDate: ['', [Validators.required]],
      hrs: ['', [Validators.required]],
      mins: ['', [Validators.required]]
    })
    console.log(this.data, "26");
  }

  selectDate(event) {
    let cDate = new Date();
    let sDate = new Date(event.value);
    let time = Number(sDate.getTime() - cDate.getTime());

    let hours = time > 0 ? Math.floor(time / 1000 / 60 / 60) : 0;
    let minutes = time > 0 ? Math.floor((time / 1000 / 60 / 60 - hours) * 60) : 0;
    console.log(time, "41:::")
    this.validityForm.patchValue({
      hrs: hours,
      mins: minutes
    })
  }

  ipComplete() {
    this.spinner.show();
    delete this.data['colName'];
    const req_Data = {
      action: 'ip_complete',
      data: this.data
    }
    this.shipmentService.postTpComplete(req_Data).subscribe(result => {
      if(result.status === 'SUCCESS'){
        this.dialogRef.close('IP Completed Successfully');
      }else{
        this.dialogRef.close();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: result.status });
      }
      this.spinner.hide();
    })
  }

  calcuateTime(event) {
    if (event.target.valueAsNumber > 2 && event.target.placeholder == "Validity Minutes") {
      event.preventDefault();
    }
    let hours = this.validityForm.get('hrs').value ? (this.validityForm.get('hrs').value) * 1000 * 60 * 60 : 0;
    let minutes = this.validityForm.get('mins').value ? (this.validityForm.get('mins').value) * 1000 * 60 : 0;

    let validateTime = new Date().getTime() + hours + minutes;

    this.validityForm.patchValue({
      validityDate: new Date(validateTime)
    })

  }

  validateTime() {
    const req_Data = {
      action: 'generate_tp',
      data: { "validityDateTime": this.validityForm.get('validityDate').value, "ipNo": this.data.ipNo }
    }

    this.shipmentService.validityDateTime(req_Data).subscribe(result => {
      this.dialogRef.close('Date Validated Successfully');
    })
  }

  tpComplete() {

    delete this.data['colName'];
    const req_Data = {
      action: 'ip_complete',
      data: this.data
    }
    this.shipmentService.postTpComplete(req_Data).subscribe(result => {
      this.dialogRef.close('IP Completed Successfully');
    })
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

  doPrint(id, data:any = []): any {
    console.log(id,data)
    this.printId = id;
    this.showPrint = true;
    this.getPrint(id);
  }

  getPrint(id): any {
    let printContents;
    let popupWin;
    setTimeout(() => {
      if (document.getElementById(id)) {
        printContents = document.getElementById(id).innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.write(`${printContents}`);
        popupWin.document.close();
        this.showPrint = false;
        // setTimeout(() => {
        //   this.showPrint = false;
        // }, 500);
      }
    }, 500);
  }

}
