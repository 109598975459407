import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StockService } from 'src/app/core/services/stock.service';

@Component({
  selector: 'app-manage-outlet-return-stock-dialog',
  templateUrl: './manage-outlet-return-stock-dialog.component.html',
  styleUrls: ['./manage-outlet-return-stock-dialog.component.scss'],
})
export class ManageOutletReturnStockDialogComponent implements OnInit {
  outletReturnStockForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  // products: Product[] = [];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ManageOutletReturnStockDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private stockService: StockService
  ) {}

  ngOnInit(): void {
    // this.getBrandCodes();
    console.log('this.data', this.data);
    this.outletReturnStockForm = this.fb.group({
      retailer: ['', [Validators.required]],
      brand: ['', [Validators.required]],
      batch: ['', [Validators.required]],
      products: ['', [Validators.required]],
      cases: ['', [Validators.required]],
      bottles: ['', [Validators.required]],
      retailerType: ['', [Validators.required]],
      date: '',
    });
    if (this.data && this.data._id) {
      this.outletReturnStockForm.patchValue({
        retailer: this.data.retailer,
        brand: this.data.brand,
        batch: this.data.batch,
        products: this.data.products,
        cases: this.data.cases,
        bottles: this.data.bottles,
        retailerType: this.data.retailerType,
        date: this.data.date,
      });
    }
    this.outletReturnStockForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    })
  }

  get f() {
    return this.outletReturnStockForm.controls;
  }

  // getBrandCodes() {
  //   this.productionService.getProduct(1, 100).subscribe((res) => {
  //     this.products.push.data);
  //   });
  // }

  // selectProduct(e) {

  // }

  createOutletReturnStock() {
    this.saveDisabled = true;
    const req_Data = {
      action : '',
      data : this.outletReturnStockForm.value
    }
    this.stockService
      .createOutletReturnStock(req_Data)
      .subscribe(
        (res) => {
          this.dialogRef.close();
        },
        (err) => {
          this.error = err.error.message;
        }
      );
  }

  updateOutletReturnStock() {
    this.saveDisabled = true;
    const obj = [
      {
        _id: this.data._id,
        update: this.outletReturnStockForm.value,
      },
    ];
    this.stockService.putOutletReturnStock(obj).subscribe(
      (res) => {
        this.dialogRef.close();
      },
      (err) => {
        this.error = err.error.message;
      }
    );
  }

  // getTitle() {
  //   return this.data && this.data._id
  //     ? `Update Outlet Return Stock for ${this.data.outletStockStockName}`
  //     : 'Create Outlet Return Stock';
  // }
}
