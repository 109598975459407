import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  templateUrl: './supplier-ofs-info-dialog.component.html',
  styleUrls: ['./supplier-ofs-info-dialog.component.scss']
})
export class SupplierOfsInfoDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'no',
    'indentId',
    'tpNo',
    'brandNumber',
    'brandName',
    'quantity',
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  constructor(public dialogRef: MatDialogRef<SupplierOfsInfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data.ofsDetails);
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

}
