import { CommonsService } from 'src/app/core/services/commons.service';
import { Inject, TemplateRef, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Samples } from 'src/app/core/models/lab/samples.model';
import { LabService } from 'src/app/core/services/lab.service';
import { MessageService } from 'primeng/api';
import { OtpVerificationDialogComponent } from 'src/app/core/components/otp-verification-dialog/otp-verification-dialog.component';
import { FormGroup, NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { labAnalysis } from '../../../../core/constants/lab.analysis.constants'


@Component({
  selector: 'app-open-samples-status-dialog',
  templateUrl: './open-samples-status-dialog.component.html',
  styleUrls: ['./open-samples-status-dialog.component.scss']
})
export class OpenSamplesStatusDialogComponent implements OnInit {
  displayedColumns: string[];
  displayedColumnsSerialNo: any;
  sampleStatusList = ['PASS', 'FAIL', 'Custom Remarks'];
  sampleStatusItems= ['PASS', 'FAIL'];
  dataSource: MatTableDataSource<Samples> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;
  roleName: any;
  showPrint: any = false;
  printId: any = '';
  editLabDatas: any = [];
  disabled: any = false;
  showEditCecrNo: any = false;
  newCecrNo: any = '';
  loader: any = false;
  cecrNoDialogRef: any;
  editAnalysisDialogRef: any;
  enaAnalysisDialogRef: any;
  analysisDataHeaders:any=[];
  analysisTableData:any=[];
  @ViewChild('editCecrNo', { static: true }) editCecrNo: TemplateRef<any>;
  @ViewChild('editAnalysis', { static: true }) editAnalysis: TemplateRef<any>;
  @ViewChild('enaAnalysis', { static: true }) enaAnalysis: TemplateRef<any>;
  @ViewChild('analysisForm') analysisForm!: NgForm;
  isFormValid:boolean=true;
  requirementasPer:any;
  liquorTypes:any=[];
  selectedType:any;
  filteredData:any=[];
  selectedTypes:any=[];
  features: any;
  featuresData: any;
  isDisable:boolean=false;
  Whiskydata: any[] = [];
  Brndydata: any[] = [];
  isWhiskyData: boolean = true;
  isBrandyData: boolean = true;
  isVodkaData:boolean = true;
  isRumData:boolean = true;
  ismaltGrainWhisky:boolean=true;
  isBmGrainWhisky:boolean=true;
  isblendedGBrandy:boolean=true;
  isWhiteRum:boolean=true;
  isPmixVodka:boolean=true;

  isGin:boolean=true;
  isPmixGin:boolean=true;
  isPmixRum:boolean=true;
  isgrapeBrandy:boolean=true;

  isSubmit:boolean=false;
  fieldError:any=[];
  isValidate: any = true;
  refNumber:any;
  requirementValue:any;
  dataResult:any;
  minSerials:any;
  maxSerials:any
  constructor(public dialogRef: MatDialogRef<OpenSamplesStatusDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, private messageService: MessageService,private commponService:CommonsService,private dialog: MatDialog,
    private labService: LabService, private spinner: NgxSpinnerService) {
      this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'))['role']

      if(this.data.testStatus == 'NotTested') {
        this.data.labInformation[0].sampleStatus = '';
      }
     }

  ngOnInit(): void {
    console.log(this.data, "33::::")
    if(this.data.sampleType === 'BLEND'){
      this.displayedColumns = [
        'no', 'customSerialNumber', 'batchId', 'descriptionOfSample', 'percentageOfProofSpirit', 'approvedPercentageOfProofSpirit', 'sendersFacsimileSeal' , 'sampleStatus', 'action'
      ];
    }else{
      this.displayedColumns = [
        'no', 'customSerialNumber', 'descriptionOfSample', 'dtpNoLotNo', 'percentageOfProofSpirit', 'approvedPercentageOfProofSpirit', 'sendersFacsimileSeal' , 'sampleStatus', 'action'
      ];
    }
    this.displayedColumnsSerialNo = ['no', 'newSerialNo', 'descriptionOfSample'];
    this.newCecrNo = this.data.ceCRNo ? this.data.ceCRNo : '';
    this.data.labInformation.forEach(el => el.newSerialNo = el.customSerialNumber ? el.customSerialNumber : '');
    //this.getSamplesDetails();
   /*  let columns = Object.keys(this.data.labInformation[0]);
    columns.splice(columns.indexOf('checkedValue'),1)
    console.log(columns, this.data.labInformation[0] ,"38::::")
    columns.unshift('no');
    if(columns.indexOf('sampleStatus') == -1) {
      columns.push('sampleStatus')
    }
    this.displayedColumns = columns; */
    const labRecords = [];
    // this.data.labInformation.forEach((labInfo, index) => {
    //   let labInfoIndex = this.data.labInformation.filter(item =>  item.batchId === labInfo.batchId && item.brandName === labInfo.brandName);
    //   let batchIndex = labRecords.findIndex(item =>  item.batchId === labInfo.batchId && item.brandName === labInfo.brandName);
    //   if(batchIndex === -1){
    //     labRecords.push(labInfoIndex[labInfoIndex.length - 1]);
    //   }
    // });
    // this.dataSource = new MatTableDataSource(labRecords); 
    this.dataSource = new MatTableDataSource(this.data.labInformation);   
    this.characteristicData();
    this.processData();
  }

  characteristicData(){
    if(this.data.sampleType ==='BLEND'){
    this.liquorTypes = [...new Set(this.data.labInformation.map(item => this.capitalize(item.liquorType)))];
    }
    this.analysisDataHeaders =[]

    this.filteredData.forEach((brand:any)=>{
      this.analysisDataHeaders.push({"brandName":brand.brandName,"batchId":brand.batchId,"value":'',"serialNo":brand.serialNo,"sampleStatus":''})
    })
    if(this.data && this.data.labInformation && this.data.labInformation.length && (this.data.sampleType === 'ENA' || this.data.sampleType ==='BLEND')){
   
      let updatedData;
      if(this.data.sampleType === 'BLEND'){
        const serialNumbers = this.filteredData.map(item => item.serialNo);
        this.minSerials = Math.min(...serialNumbers);
        this.maxSerials = Math.max(...serialNumbers);
        this.dataResult = `All the above sample noted in Serial No From (${this.minSerials}) To (${this.maxSerials}) confirm to the specifications laid down for ${this.selectedType} in ${this.requirementValue} and Fit for human consumption.`


        if(this.selectedType === 'Whisky' && this.data.whiskyData && this.data.whiskyData.length){
          updatedData  = this.data.whiskyData;
          this.dataResult = this.data.whiskyResult;
        }else if(this.selectedType === 'Brandy' && this.data.brandyData && this.data.brandyData.length){
          updatedData  = this.data.brandyData;
          this.dataResult = this.data.brandyResult;
        }else if(this.selectedType === 'Vodka' && this.data.vodkaData && this.data.vodkaData.length){
          updatedData  = this.data.vodkaData;
          this.dataResult = this.data.vodkaResult;
        }else if(this.selectedType === 'Rum' && this.data.rumData && this.data.rumData.length){
          updatedData  = this.data.rumData;
          this.dataResult = this.data.rumResult;
        }else if(this.selectedType === 'Malt/grain whisky' && this.data.maltGrainWhisky && this.data.maltGrainWhisky.length){
          updatedData  = this.data.maltGrainWhisky;
          this.dataResult = this.data.maltGrainWhiskyResult;
        }else if(this.selectedType === 'Blended malt/grain whisky' && this.data.blendMaltGrainWhisky && this.data.blendMaltGrainWhisky.length){
          updatedData  = this.data.blendMaltGrainWhisky;
          this.dataResult = this.data.blendMaltGrainWhiskyResult;
        }else if(this.selectedType === 'Blended grape brandy' && this.data.blendGrapeBrandy && this.data.blendGrapeBrandy.length){
          updatedData  = this.data.blendGrapeBrandy;
          this.dataResult = this.data.blendGrapeBrandyResult;
        }else if(this.selectedType === 'White rum' && this.data.whiteRum && this.data.whiteRum.length){
          updatedData  = this.data.whiteRum;
          this.dataResult = this.data.whiteRumResult;
        }else if(this.selectedType === 'Premix vodka' && this.data.premixVodka && this.data.premixVodka.length){
          updatedData  = this.data.premixVodka;
          this.dataResult = this.data.premixVodkaResult;
        }else if(this.selectedType === 'Gin' && this.data.ginData && this.data.ginData.length){
          updatedData  = this.data.ginData;
          this.dataResult = this.data.ginDataResult;
        }else if(this.selectedType === 'Premix rum' && this.data.premixRum && this.data.premixRum.length){
          updatedData  = this.data.premixRum;
          this.dataResult = this.data.premixRumResult;
        }else if(this.selectedType === 'Grape brandy' && this.data.grapeBrandy && this.data.grapeBrandy.length){
          updatedData  = this.data.grapeBrandy;
          this.dataResult = this.data.grapeBrandyResult;
        }else if(this.selectedType === 'Premix gin' && this.data.premixGin && this.data.premixGin.length){
          updatedData  = this.data.premixGin;
          this.dataResult = this.data.premixResult;
        }else{
          updatedData = labAnalysis.getBlendLiquortypeData(this.selectedType);
        }
      }
      if(this.data.sampleType === 'ENA'){
        this.data.labInformation.forEach((ena:any)=>{
          this.analysisDataHeaders.push({"descriptionOfSample":ena.descriptionOfSample,"dtpNoLotNo":ena.dtpNoLotNo,"value":'',"serialNo":ena.serialNo,"sampleStatus":''})
        })
        const serialNumbers = this.data.labInformation.map(item => item.serialNo);
        this.minSerials = Math.min(...serialNumbers);
        this.maxSerials = Math.max(...serialNumbers);
        this.dataResult = `All the above samples noted in Serial No From (${this.minSerials})  To (${this.maxSerials})  confirm to the specifications laid down for Neutral Spirit in IS 6613 (2002).The samples were found to be free from noxious ingredients injurious to health and hence fit for the manufacture of IMFL.`
       

        if(this.data.enaData && this.data.enaData.length){
          updatedData = this.data.enaData;
          this.dataResult = this.data.enaDataResult;
        }else{
          updatedData = labAnalysis.getEnaLiquortypeData();
        }
      }
      this.processData();
      if(this.data.testStatus !== 'Tested'){
        this.analysisTableData = this.normalizeData(updatedData);
      }
     

      this.checkFormValidity();

      

    }
  }
  capitalize(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }
  processData(): void {
    const hasWhyData = this.data.labInformation.some(item => item.liquorType === 'Whisky' || item.liquorType === 'WHISKY');
    const hasBrData = this.data.labInformation.some(item => item.liquorType === 'Brandy' || item.liquorType === 'BRANDY');
    const hasVodka =  this.data.labInformation.some(item => item.liquorType === 'Vodka' || item.liquorType === 'VODKA');
    const hasRum =  this.data.labInformation.some(item => item.liquorType === 'Rum' || item.liquorType === 'RUM');
    const hasMgWhisky =  this.data.labInformation.some(item => item.liquorType === 'Malt/Grain Whisky');
    const hasBmgWhisky =  this.data.labInformation.some(item => item.liquorType === 'Blended Malt/Grain Whisky');
    const hasBlededGBrandy = this.data.labInformation.some(item => item.liquorType === 'Blended Grape Brandy');
    const hasWhiteRum = this.data.labInformation.some(item => item.liquorType === 'White Rum');
    const hasPmixVodka = this.data.labInformation.some(item => item.liquorType === 'Premix Vodka');
    const hasGin = this.data.labInformation.some(item => item.liquorType === 'Gin');
    const hasPmixGin = this.data.labInformation.some(item => item.liquorType === 'Premix Gin');
    const hasPmixRum = this.data.labInformation.some(item => item.liquorType === 'Premix Rum');
    const hasGrapeBrandy = this.data.labInformation.some(item => item.liquorType === 'Grape Brandy');


    if(this.data.sampleType === 'ENA'){
      this.data.enaData && this.data.enaData.length ?  this.isDisable = true : this.isDisable = false;
    }else if(this.data.sampleType === 'BLEND'){
      if (hasWhyData) {
        this.data.whiskyData && this.data.whiskyData.length > 0 ? this.isWhiskyData = true : this.isWhiskyData = false;
      } if (hasBrData) {
        this.data.brandyData && this.data.brandyData.length > 0 ? this.isBrandyData = true : this.isBrandyData = false;
     
      } if (hasVodka) {
        this.data.vodkaData && this.data.vodkaData.length > 0 ? this.isVodkaData = true : this.isVodkaData = false;
     
      } if(hasRum){
        this.data.rumData && this.data.rumData.length > 0 ? this.isRumData = true : this.isRumData = false;
      } if(hasMgWhisky){
        this.data.maltGrainWhisky && this.data.maltGrainWhisky.length ? this.ismaltGrainWhisky = true : this.ismaltGrainWhisky = false;
      } if(hasBmgWhisky){
        this.data.blendMaltGrainWhisky && this.data.blendMaltGrainWhisky.length ? this.isBmGrainWhisky = true : this.isBmGrainWhisky = false;
      }if(hasBlededGBrandy){
        this.data.blendGrapeBrandy && this.data.blendGrapeBrandy.length ? this.isblendedGBrandy = true : this.isblendedGBrandy = false;
      } if(hasWhiteRum){
        this.data.whiteRum && this.data.whiteRum.length ? this.isWhiteRum = true : this.isWhiteRum = false;
      } if(hasPmixVodka){
        this.data.premixVodka && this.data.premixVodka.length ? this.isPmixVodka = true : this.isPmixVodka = false;
      } if(hasGin){
        this.data.ginData && this.data.ginData.length ? this.isGin = true : this.isGin = false;
      } if(hasPmixGin){
        this.data.premixGin && this.data.premixGin.length ? this.isPmixGin = true : this.isPmixGin = false;
      } if(hasPmixRum){
        this.data.premixRum && this.data.premixRum.length ? this.isPmixRum = true : this.isPmixRum = false;
      } if(hasGrapeBrandy){
        this.data.grapeBrandy && this.data.grapeBrandy.length ? this.isgrapeBrandy = true : this.isgrapeBrandy = false;
      }
    }
  }
  normalizeData(brandyData) {
  
    if(this.data.sampleType === 'BLEND'){
   
      if(brandyData && brandyData.length){
        return brandyData.map((item) => {
          while (item.samples.length < this.analysisDataHeaders.length) {
            item.samples.push(
              {brand: this.analysisDataHeaders[item.samples.length].brandName,
                batchId: this.analysisDataHeaders[item.samples.length].batchId,
                value: '',
                serialNo:this.analysisDataHeaders[item.samples.length].serialNo,
                sampleStatus:this.analysisDataHeaders[item.samples.length].sampleStatus
              }
            );
          }
          if (item.samples.length > this.analysisDataHeaders.length) {
            item.samples = item.samples.slice(0, this.analysisDataHeaders.length);
          }
         
          item.samples = item.samples.map((sampleValue, index) => ({
            brand: this.analysisDataHeaders[index].brandName,
            batchId: this.analysisDataHeaders[index].batchId,
            value:sampleValue.value,
            serialNo:this.analysisDataHeaders[index].serialNo,
            sampleStatus:this.analysisDataHeaders[index].sampleStatus ? this.analysisDataHeaders[index].sampleStatus :sampleValue.sampleStatus,
            
          }));
          item.samples.forEach((sample) => {
            const header = this.analysisDataHeaders.find((headerItem) => headerItem.serialNo === sample.serialNo);
            if (header) {
              header.sampleStatus = sample.sampleStatus;
            }
          });
          return item;
        });
      }
   
    }

      if(this.data.sampleType === 'ENA'){
        if(brandyData && brandyData.length){
          return brandyData.map((item) => {
            while (item.samples.length < this.analysisDataHeaders.length) {
              item.samples.push(
                {descriptionOfSample: this.analysisDataHeaders[item.samples.length].descriptionOfSample,
                  dtpNoLotNo: this.analysisDataHeaders[item.samples.length].dtpNoLotNo,
                  value: '',
                  serialNo:this.analysisDataHeaders[item.samples.length].serialNo,
                  sampleStatus:this.analysisDataHeaders[item.samples.length].sampleStatus,
                }
              ); // Initialize with empty strings
              // item.samples.push(0); // Alternatively, initialize with 0
            }
            if (item.samples.length > this.analysisDataHeaders.length) {
              item.samples = item.samples.slice(0, this.analysisDataHeaders.length);
            }
           
            item.samples = item.samples.map((sampleValue, index) => ({
              descriptionOfSample: this.analysisDataHeaders[index].descriptionOfSample,
              dtpNoLotNo: this.analysisDataHeaders[index].dtpNoLotNo,
              value:sampleValue.value,
              serialNo:this.analysisDataHeaders[index].serialNo,
              sampleStatus:this.analysisDataHeaders[index].sampleStatus  ? this.analysisDataHeaders[index].sampleStatus :sampleValue.sampleStatus,
            }));
            item.samples.forEach((sample) => {
              const header = this.analysisDataHeaders.find((headerItem) => headerItem.serialNo === sample.serialNo);
              if (header) {
                header.sampleStatus = sample.sampleStatus;
              }
            });
            return item;
          });
        }
      }

  
  }
  selectLiquor(event){
    this.selectedType = event.value;
    this.requirementCheck(this.selectedType)
    if(this.data && this.data.labInformation && this.data.labInformation.length){
      this.filteredData  = this.data.labInformation.filter(
        product => product.liquorType.toLowerCase() === this.selectedType.toLowerCase()
      );
    }
    this.characteristicData();
   
  }
  selectSampleStatus(event,rowIndex){
    // if (this.analysisTableData[rowIndex]) {
    //   this.analysisTableData[rowIndex].sampleStatus = event.value;
    // }
  }
  changeRemarks(event,rowIndex){
    // console.log(event)
    // if (this.analysisTableData[rowIndex]) {
    //   this.analysisTableData[rowIndex].customRemarks = event;
    // }
  }

  isSubmitDisabled() {
   
    this.analysisDataHeaders.forEach((item:any)=>{
      if(item.sampleStatus === ""){
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Please select sample status'});
        return
      }
    })
    console.log(this.analysisDataHeaders)
   
  }
  requirementCheck(type){

    switch (type) {
      case 'Whisky':
      case 'Malt/grain whisky':
      case 'Blended malt/grain whisky':
        this.requirementValue = 'IS 4449 (2005)';
        break;
      case 'Vodka':
      case 'Premix vodka':
        this.requirementValue = 'IS 5286 (2005)';
        break;
      case 'Brandy':
      case 'Blended grape brandy':
      case 'Grape brandy':
         this.requirementValue = 'IS 4450 (2005)';
         break;
      case 'Rum':
      case 'White rum':
      case 'Premix rum':
         this.requirementValue = 'IS 3811 (2005)';
         break;
      case 'Gin':
      case 'Premix gin':
         this.requirementValue = 'N/A';
         break;
    }

  }
  getSamplesDetails() {
    this.labService.getSamplesDetails(this.offset, this.limit, this.search).subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(res.data);
    });
  }

  async approveLab(): Promise<any> {
    // const req_otp ={
    //   action: "send_otp",
    //   data:{
    //    ...this.data
    //   }
    // }
    
    // this.commponService.sendVerifyOtp(req_otp).subscribe((res:any)=>{
      
      
    // })
    // const dialogRef = this.dialog.open(OtpVerificationDialogComponent, {
    //   width: '25%',
    //   height:'45%',
    //   autoFocus: false
    // });

    // dialogRef.afterClosed().subscribe((result) => {
    //   if(result.message == 'SUCCESS'){
        this.data.labInformation.forEach(lab => {
          if (lab.customRemarks) {
            lab.sampleStatus = lab.customRemarks;
            lab.customRemarks = '';
          }
        });
        const req_Data = {
          action : 'approve_sample',
          data: this.data
        }
       /*  const req_Data = {
          action : 'approve_sample',
          data: {data: this.samplesForm, testStatus}
        } */
        this.data.testStatus = 'Tested';

        this.labService.updatelabStatus(req_Data).subscribe((res: any) => {
          if (res.status === 'SUCCESS') {
            this.dialogRef.close('Samples Status Updated Successfully');
          } else {
            this.messageService.add({severity: 'error', summary: 'Error', detail: 'Samples Status can\'t be updated Successfully'});
            this.data.testStatus = 'NotTested';
          }
        }, err => {
          this.data.testStatus = 'NotTested';
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Samples Status can\'t be updated Successfully'});
        });
    //   }
    // });


  }

  dialogClose(): void {
    this.dialogRef.close();
    this.dataSource = new MatTableDataSource();
  }
  reviewLab(){
    const req_Data = {
      action : 'review_sample',
      data: this.data
    }
   /*  const req_Data = {
      action : 'approve_sample',
      data: {data: this.samplesForm, testStatus}
    } */
    this.data.testStatus = 'Reviewed';
    
    this.labService.updatelabSampleStatus(req_Data).subscribe(res => {
      if(res['response'] != "None") {
        this.dialogRef.close('Samples Status Updated Successfully');
      }
    }, err => {
      this.data.testStatus = 'NotTested';
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Samples Status can\'t be updated Successfully'});
    }) 

  }

  rejectLab(){
    const req_Data = {
      action : 'review_sample',
      data: this.data
    }
   /*  const req_Data = {
      action : 'approve_sample',
      data: {data: this.samplesForm, testStatus}
    } */
    this.data.testStatus = 'Rejected';
    
    this.labService.updatelabSampleStatus(req_Data).subscribe(res => {
      if(res['response'] != "None") {
        this.dialogRef.close('Samples Status Updated Successfully');
      }
    }, err => {
      this.data.testStatus = 'NotTested';
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Samples Status can\'t be updated Successfully'});
    }) 

  }
  doPrint(id): any {
    this.printId = id;
    this.showPrint = true;
    this.getPrint(id);
  }
  getPrint(id): any {
    let printContents;
    let popupWin;
    setTimeout(() => {
      if (document.getElementById(id)) {
        printContents = document.getElementById(id).innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.write(`${printContents}`);
        popupWin.document.close();
        this.showPrint = false;
      }
    }, 500);
  }
  updateLabInfo(i): any {
    // const formDatas: any = this.data;
    const formDatas: any = {};
    formDatas.ceCRNo = this.data.ceCRNo;
    formDatas.entityId = this.data.entityId;
    formDatas.entityType = this.data.entityType;
    formDatas.labInformation = [];
    formDatas.labInformation.push(this.data.labInformation[i]);
    formDatas.labInformation[0].descriptionOfSample = this.editLabDatas[i].descriptionOfSample;
    formDatas.labInformation[0].approvedPercentageOfProofSpirit = this.editLabDatas[i].approvedPercentageOfProofSpirit;
    const reqData = {
      action : 'update_sample',
      data : formDatas
    };
    this.labService.updateSamplesDetails(reqData).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Samples Lab Information Updated Successfully'});  
      } else {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Samples Status can\'t be updated Successfully'});
      }
      this.disabled = false;
      this.editLabDatas = [];
      }, (err) => {
        this.disabled = false;
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Samples Status can\'t be updated Successfully'});
      }
    );
  }
  // updateCecrNo(): any {
  //   const formDatas: any = {
  //     oldceCRNo: this.data.ceCRNo,
  //     ceCRNo: this.newCecrNo,
  //   };
  //   const reqData = {
  //     action : 'update_lab_cecrno',
  //     data : formDatas
  //   };
  //   console.log('reqData', reqData);
  //   this.labService.updateSamplesDetails(reqData).subscribe((res: any) => {
  //     if (res.status === 'SUCCESS') {
  //       this.messageService.add({severity: 'success', summary: 'Success', detail: 'Samples Lab Information Updated Successfully'});  
  //     } else {
  //       this.newCecrNo = this.data.ceCRNo; 
  //       this.messageService.add({severity: 'error', summary: 'Error', detail: 'Samples Status can\'t be updated Successfully'});
  //     }
  //     this.showEditCecrNo = false;
  //     }, (err) => {
  //       this.newCecrNo = this.data.ceCRNo;
  //       this.messageService.add({severity:'error', summary: 'Error', detail: 'Samples Status can\'t be updated Successfully'});
  //     }
  //   );
  // }
  updateSerialNoAndName(): any {
    this.loader = true;
    const labInfo = [];
    this.dataSource.filteredData.forEach((el: any) => {
      labInfo.push(
        {
          customSerialNumber: el.newSerialNo,
          serialNo: el.serialNo ? el.serialNo : ''
        }
      )
    });
    const formDatas: any = {
      oldceCRNo: this.data.ceCRNo,
      ceCRNo: this.newCecrNo,
      entityId: this.data.entityId,
      labInformation: labInfo
    };
    const reqData = {
      action : 'update_lab_custom_name_num',
      data : formDatas
    };
    console.log('reqData', reqData);
    this.labService.updateSamplesDetails(reqData).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.closeCecrnoDialog();
        this.dialogRef.close('Samples Lab Information Updated Successfully');
      } else {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Samples Status can\'t be updated Successfully'});
      }
      this.loader = false;
      }, (err) => {
        this.disabled = false;
        this.loader = false;
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Samples Status can\'t be updated Successfully'});
      }
    );
  }
  editLabInfo(i, data): any {
    this.editLabDatas = [];
    this.editLabDatas[i] = {};
    this.editLabDatas[i].descriptionOfSample = data.descriptionOfSample;
    this.editLabDatas[i].approvedPercentageOfProofSpirit = data.approvedPercentageOfProofSpirit;
    this.editLabDatas[i].dtpNoLotNo = data.dtpNoLotNo;
    this.disabled = true;
  }
  enableApproveBtn(): any {
    let enableBtn: any = true;
    enableBtn = this.dataSource.filteredData.every((row: any) => (row.sampleStatus && row.sampleStatus !== 'Custom Remarks') ||
      (row.sampleStatus === 'Custom Remarks') && row.customRemarks);
    return enableBtn;
  }
  enableApprove(): any {
    this.disabled = false;
  }

  editCecrNoDialog(){
    this.cecrNoDialogRef = this.dialog.open(this.editCecrNo, {
      width:'80%',
      disableClose: true
    });
  }
  closeCecrnoDialog(): any {
    this.cecrNoDialogRef.close();
  }

  editAnalysisDialog(){
    this.editAnalysisDialogRef = this.dialog.open(this.editAnalysis, {
      width:'80%',
      disableClose: true
    });
  }

  closeAnalysisDialog(): any {
    this.editAnalysisDialogRef.close();
    // this.analysisForm.reset(); 
 
  }
  enaAnalysisDialog(){
    this.enaAnalysisDialogRef = this.dialog.open(this.enaAnalysis, {
      width:'80%',
      disableClose: true
    });
 
  }
  
  closeenaAnalysisDialog(): any {
    this.enaAnalysisDialogRef.close();
    // this.analysisForm.reset(); 
 
  }
  isDisableSerialNoName(): any {
    return this.dataSource.filteredData.some((el: any) => !el.newSerialNo);
  }
  trackByIndex(index: number, item: any): any {
    return index;
  }

  onInputChange(event: any, rowIndex: number, colIndex: number) {
    if (this.analysisTableData[rowIndex]?.samples && this.analysisTableData[rowIndex].samples[colIndex]) {
      this.analysisTableData[rowIndex].samples[colIndex].value = event;
    }
 
  }
 
  validateValue(value: any, item: any, index: number) {
 
    // const parsedValue = parseFloat(value);
    // const requirement = parseFloat(item.requirement);

    // const isValid = !isNaN(parsedValue);

    // const exceedsRequirement = isValid && parsedValue > requirement;
  
  
    // if(this.data.sampleType === 'BLEND'){
    //   if (!isValid || exceedsRequirement) {
    //     item.samples[index].error = exceedsRequirement
    //       ? 'Value exceeds the allowed limit'
    //       : 'Please enter a valid value';
         
    //   } else {
    //     item.samples[index].error = null; // No error
    //   }
    // }else if(this.data.sampleType === 'ENA'){
    //   if (exceedsRequirement) {
    //     item.samples[index].error = exceedsRequirement
    //       ? 'Value exceeds the allowed limit'
    //       : 'Please enter a valid value';
         
    //   } else {
    //     item.samples[index].error = null; // No error
    //   }
    // }

    this.checkFormValidity();
  }
  

  checkFormValidity() {
    if(this.selectedType && this.data.sampleType === 'BLEND'){
      this.isValidate = this.analysisTableData.every(item =>
        // item.samples.every(sample => sample.value && !sample.error)
        item.samples.every(sample => sample.value)
// 
      );
    }else if( this.data.sampleType === 'ENA'){
      this.isValidate = this.analysisTableData.every(item =>
        item.samples.every(sample => sample.value)
      );
    }

  }

  validateValueEna(data,i){
    this.isValidate = false;
    this.fieldError = [];
    if(!this.fieldError[i]){
      this.fieldError[i] = {};
    }
    const value = parseFloat(data.value)
    const requirement =parseFloat(data.requirement)
    if(value > requirement){
      this.fieldError[i].error = true;
      this.isValidate = true;
      console.log(this.isValidate)
    }else{
      this.isValidate = false;
    }
  }

  
  saveAnalysis(formData){
    // this.loader = true;
      const hasEmptyStatus = this.analysisDataHeaders.some((item) => item.sampleStatus === "");
      if (hasEmptyStatus) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select sample status' });
        return false;
      }
      this.analysisTableData = this.normalizeData(this.analysisTableData);
      const data:any={
      }
      if(this.selectedType === 'Whisky'){
        data.whiskyData = this.analysisTableData;
        data.whiskyResult = this.dataResult;
      }else if(this.selectedType === 'Brandy'){
        data.brandyData = this.analysisTableData;
        data.brandyResult = this.dataResult;
      }else if(this.selectedType === 'Vodka'){
        data.vodkaData = this.analysisTableData;
        data.vodkaResult = this.dataResult;
      }else if(this.selectedType === 'Rum'){
        data.rumData = this.analysisTableData;
        data.rumResult = this.dataResult;
      }else if(this.selectedType === 'Malt/grain whisky'){
        data.maltGrainWhisky = this.analysisTableData;
        data.maltGrainWhiskyResult = this.dataResult;
      }else if(this.selectedType === 'Blended malt/grain whisky'){
        data.blendMaltGrainWhisky = this.analysisTableData;
        data.blendMaltGrainWhiskyResult = this.dataResult;
      }else if(this.selectedType === 'Blended grape brandy'){
        data.blendGrapeBrandy = this.analysisTableData;
        data.blendGrapeBrandyResult = this.dataResult;
      }else if(this.selectedType === 'White rum'){
        data.whiteRum = this.analysisTableData;
        data.whiteRumResult = this.dataResult;
      }else if(this.selectedType === 'Premix vodka'){
        data.premixVodka = this.analysisTableData;
        data.premixVodkaResult = this.dataResult;
      }else if(this.selectedType === 'Premix gin'){
        data.premixGin = this.analysisTableData;
        data.premixResult = this.dataResult;
      }else if(this.selectedType === 'Grape brandy'){
        data.grapeBrandy = this.analysisTableData;
        data.grapeBrandyResult = this.dataResult;
      }else if(this.selectedType === 'Premix rum'){
        data.premixRum = this.analysisTableData;
        data.premixRumResult = this.dataResult;
      }else if(this.selectedType === 'Gin'){
        data.ginData = this.analysisTableData;
        data.ginDataResult = this.dataResult;
      }
      if(this.data.sampleType === 'ENA'){
        data.enaData = this.analysisTableData;
        data.enaDataResult = this.dataResult;
      }
      data.ceCRNo = this.data.ceCRNo;
      data.entityId = this.data.entityId;
      this.refNumber =  this.data.referenceNo
      const closeDialog = this.data.sampleType === 'ENA' ?  this.enaAnalysisDialogRef.close() : this.editAnalysisDialogRef.close();
      const reqData:any ={
        action:'update_sample_lab',
        data:data
      }
    
      console.log(reqData)
      this.labService.createSamplesDetails(reqData).subscribe((res:any)=>{
        if(res.status==='SUCCESS'){
          this.messageService.add({severity: 'success', summary: 'Success', detail: 'Updated successfully'});
          this.getFeatures();
          closeDialog
          this.selectedType = '';
        
        }else{
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Updation failed'});
        }
        this.loader = false;
      }, (err) => {
        this.disabled = false;
        this.loader = false;
        this.messageService.add({severity:'error', summary: 'Error', detail: 'Updation failed'});
    })
 
  }


  blendPrintCheck(data: any): { hasData: boolean; hasNoData: boolean } {
    const hasData = (data.brandyData && data.brandyResult) || (data.whiskyData && data.whiskyResult) || 
    (data.vodkaData && data.vodkaResult) || (data.rumData && data.rumResult) || 
    (data.maltGrainWhisky && data.maltGrainWhiskyResult) || 
    (data.blendMaltGrainWhisky && data.blendMaltGrainWhiskyResult) ||
    (data.blendGrapeBrandy && data.blendGrapeBrandyResult) || 
    (data.whiteRum && data.whiteRumResult) || (data.premixVodka && data.premixVodkaResult) ||
    (data.ginData && data.ginResult) || (data.premixGin && data.premixGinResult) || 
    (data.premixRum && data.premixRumResult) || (data.grapeBrandy && data.grapeBrandyResult);

    const hasNoData = !hasData;
    return { hasData, hasNoData };
  }

  enaPrintCheck(data: any): { hasData: boolean; hasNoData: boolean } {
    const hasData = (data.enaData);

    const hasNoData = !hasData;
    return { hasData, hasNoData };
  }
  getFeatures() {
    this.spinner.show();
    const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).mod_feats;
    this.features = userModules['Lab'].features;
    this.labService.getLabFeatures().subscribe((res:any) => {
      console.log('alerts res', res);
      this.featuresData = res['Samples'];
      this.featuresData.forEach((item:any)=>{
        if(item.referenceNo === this.refNumber){
          console.log(item);
          this.data = item;
          this.data.status = 'Reviewed'
          this.characteristicData();
        }
      })
      this.spinner.hide();
    });
   
  
  }

}

