import { InterDepotService } from 'src/app/core/services/inter-depot.service';
import { CommonsService } from 'src/app/core/services/commons.service';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'app-open-idt-status-dialog',
  templateUrl: './open-idt-status-dialog.component.html',
  styleUrls: ['./open-idt-status-dialog.component.scss']
})
export class OpenIdtStatusDialogComponent implements OnInit {

  icdcData: any = [];
  validityForm: FormGroup;
  roleName: any;
  minDate: any;
  showValidityDate: any = false;
  userDetails: any = [];
  distilleryAddress: any = [];
  selectedOfsForPrint: any;
  totalDatas: any = [];
  distilleryName: any = [];

  constructor(
    public dialogRef: MatDialogRef<OpenIdtStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA)public data,
    public utilService: UtilService,
    private fb: FormBuilder,private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private interDepotService: InterDepotService,
    private commonsService: CommonsService,
    private shipmentsService: ShipmentsService,
    public util:UtilService
  ) { }

  ngOnInit(): void {
    this.userDetails = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
    if (this.userDetails.distillery_unit && this.userDetails.distillery_address) {
      this.distilleryName = this.utilService.formatString(this.userDetails.distillery_unit);
      this.distilleryAddress = this.userDetails.distillery_address[0];
    }
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    this.getOfsDetails();
    this.validityForm = this.fb.group({
      validityDate: ['', [Validators.required]],
      hrs: ['', [Validators.required]],
      mins: ['', [Validators.required]]
    });
    console.log(this.data)
  }
  getOfsDetails(): any {
    const tpNo = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
    // tslint:disable-next-line:variable-name
    const req_Data = {
      action: 'common_apis',
      q: '{"data": {"action": "get_added_ofidt", "value": "' + tpNo + '"}}'
    };
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {
      this.icdcData = result.get_added_ofidt;
      console.log(this.icdcData)
      if (this.icdcData) {
        this.isShowValidityDate();
      }
    });
  }

  dialogClose(): void{
    this.dialogRef.close();
  }
  selectDate(event): any {
    const sDate = new Date(event.value);
    const cDate = new Date();
    const time = Number(sDate.getTime() - cDate.getTime());
    const hours = Math.floor(time / 1000 / 60 / 60);
    const minutes = Math.floor((time / 1000 / 60 / 60 - hours) * 60);
    this.validityForm.patchValue({
      hrs: hours,
      mins: minutes
    });
  }
  calcuateTime(event): any {
    if (event.target.valueAsNumber > 2 && event.target.placeholder === 'Validity Minutes') {
      event.preventDefault();
    }
    const hours = this.validityForm.get('hrs').value ? (this.validityForm.get('hrs').value) * 1000 * 60 * 60 : 0;
    const minutes = this.validityForm.get('mins').value ? (this.validityForm.get('mins').value) * 1000 * 60 : 0;
    const validateTime = new Date().getTime() + hours + minutes;
    this.validityForm.patchValue({
      validityDate: new Date(validateTime)
    });
  }

  validateTime(): any {
    // tslint:disable-next-line:variable-name
    const req_Data = {
      action: 'generate_tp',
      data: { validityDateTime: this.validityForm.get('validityDate').value, tpNo: this.data.tpNo }
    };

    this.shipmentsService.validityDateTime(req_Data).subscribe(result => {
      this.dialogRef.close('Date Validated Successfully');
    });
  }

  tpComplete(): any {
    this.spinner.show();
    delete this.data.colName;
    this.data.validityDateTime = this.validityForm.get('validityDate').value;
    // tslint:disable-next-line:variable-name
    const req_Data = {
      action: 'tp_complete',
      data: this.data
    };
    this.interDepotService.postTpComplete(req_Data).subscribe(result => {
      if(result.status === 'SUCCESS'){
        this.dialogRef.close('TP Completed Successfully');
      }else{
        this.dialogRef.close();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: result.status });
      }
      this.spinner.hide();
    });
  }
  isShowValidityDate(): any {
    this.showValidityDate = false;
    let matchCount: any = 0;
    let productCount: any = 0;
    this.icdcData.map((item: any) => {
      productCount += item.productDetails.length;
      matchCount += item.productDetails.filter(pd => pd.approvedQty === pd.casesCount).length;
      if (productCount === matchCount) {
        this.showValidityDate = true;
      }
    });
  }

  getPrint(){
    // if (this.statusData && this.statusData.tpNo) {
    //   JsBarcode('#barcode', this.statusData.tpNo);
    // }
    let printContents, popupWin;
    printContents = document.getElementById('print-ofidt').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.write(`${printContents}`);
    popupWin.document.close();
  }
  convertToDate(date) {
    return date ? moment(date).format('DD/MM/YY, h:mm a') : '-';
  }
  print(ofsItem){
    console.log(ofsItem)
    this.selectedOfsForPrint = ofsItem.consignment[0];
    this.totalDatas = [];
    this.totalDatas.approvedQty = 0;
    this.totalDatas.shipmentQty = 0;
    this.totalDatas.bulkLitres = 0;
    this.totalDatas.proofLitres = 0;
    this.totalDatas.exciseDuty = 0;
    this.totalDatas.retailPricings = 0;
    this.totalDatas.value = 0;
    ofsItem.consignment[0].productDetails.map(row => {
      this.totalDatas.approvedQty += row.approvedCases ? row.approvedCases :0;
      this.totalDatas.bulkLitres += row.bulkLitres ? row.bulkLitres :0;
      // this.totalDatas.bulkLitres += (row.shipmentQty*row.size*row.unitsPerCase)/1000;
      this.totalDatas.proofLitres += row.proofLitres ? row.proofLitres :0;
      this.totalDatas.retailPricings += row.retailPricings ? row.retailPricings :0;
      this.totalDatas.exciseDuty += row.exciseDuty ? row.exciseDuty :0;
       this.totalDatas.shipmentQty += row.shipmentQty ? row.shipmentQty :0;
      if (row.value) {
        this.totalDatas.value += row.value ? row.value :0;
      }
    });

    setTimeout(() => {
      this.getPrint();
    }, 100);
  }

}
