import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const baseUrl = environment.baseUrl;
const retailerUrl = baseUrl + 'retailers';

@Injectable({
  providedIn: 'root'
})
export class RetailersService {

  currentUser: any;

  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(
      sessionStorage.getItem('lappCurrentUserDetails')
    );
  }
  getRetailer(offset, limit, type?: string, search?: string):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    type ? paramObj.type = type : type = null;
    search ? paramObj.search = search : search = null;

    return this.http.get(retailerUrl, {params: paramObj});
  }

}
