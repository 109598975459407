import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonsService } from 'src/app/core/services/commons.service';
import { SpiritRegisterService } from 'src/app/core/services/spirit-register.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-manage-rs-stock-dialog',
  templateUrl: './manage-rs-stock-dialog.component.html',
  styleUrls: ['./manage-rs-stock-dialog.component.scss']
})
export class ManageRsStockDialogComponent implements OnInit {
  rsStockForm: FormGroup;
  error: string;
  page: number;
  storageTanks: Array<string>;
  limit: number;
  saveDisabled: boolean;
  units = ['OP', 'UP'];


  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageRsStockDialogComponent>,public util: UtilService,
    @Inject(MAT_DIALOG_DATA) public data, private commonsService: CommonsService, private spiritService: SpiritRegisterService) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.getStorageTanks();
    this.rsStockForm = this.fb.group({
      date: ['', [Validators.required,]],
      receipts: [''],
      strength: ['', [Validators.required, Validators.max(1000)]],
      unit: [''],
      source: [''],
      storageTankNo: [''],
    });
    if (this.data && this.data._id) {
      this.rsStockForm.patchValue({
        date: this.data.date,
        receipts: this.data.receipts,
        strength: this.data.strength,
        unit: this.data.unit,
        source: this.data.source,
        storageTankNo: this.data.storageTankNo,
      });
    }
    this.rsStockForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    })
  }

  get f() {
    return this.rsStockForm.controls;
  }

  createRSStock() {
    this.saveDisabled = true;
    const req_Data = {
      action: 'add_rs_stock',
      data: this.rsStockForm.value
    }
    this.spiritService.createRS(req_Data).subscribe((res: any) => {
      this.dialogRef.close('Batch Created Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  getStorageTanks() {
    const req_Data = {
      action: 'common_apis',
      q: '{"data": {"action": "get_tanks_by_type", "value": "Storage Tank"}}'
    }
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {
      this.storageTanks = result['get_tanks_by_type'];
      console.log('storageTanks', this.storageTanks);
    })
  }

  updateRSStock() {
    // this.saveDisabled = true;
    // const obj = [{
    //   _id: this.data._id,
    //   update: this.rsStockForm.value
    // }]
    // this.spiritService.putBatch(obj).subscribe((res: any) => {
    //   this.dialogRef.close('Batch Udated Successfully');
    // }, (err) => {
    //   this.error = err.error.message;
    // });
  }

}
