import { UtilService } from 'src/app/core/utils/utility-helper';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Shipments } from 'src/app/core/models/shipments/shipments.model';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-icdc-case-barcode-dialog',
  templateUrl: './icdc-case-barcode-dialog.component.html',
  styleUrls: ['./icdc-case-barcode-dialog.component.scss']
})
export class IcdcCaseBarcodeDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'transportPermit'
  ];
  validityForm: FormGroup;
  dataSource: MatTableDataSource<Shipments> = new MatTableDataSource([]);
  roleName: any;
  minDate: any;
  checkBreakage:boolean = false;

  constructor(private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<IcdcCaseBarcodeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, 
    private shipmentService: ShipmentsService, public utilService: UtilService) {
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
   }


  ngOnInit(): void {
    this.validityForm = this.fb.group({
      validityDate: ['', [Validators.required]],
      hrs: ['', [Validators.required]],
      mins: ['', [Validators.required]]
    })
    console.log(this.data, "26");
    if (this.data.noBreakages=== false || this.data.noBreakages=== true) {
      this.checkBreakage = true;
      } else {
      this.checkBreakage = false;
      }
    this.checkScannedQty(this.data);
  }


  selectDate(event) {

    let sDate = new Date(event.value);
    let cDate = new Date();
    let time = Number(sDate.getTime() - cDate.getTime());
    let hours = Math.floor(time / 1000 / 60 / 60);
    let minutes = Math.floor((time / 1000 / 60 / 60 - hours) * 60);
    this.validityForm.patchValue({
      hrs: hours,
      mins: minutes
    })
    this.checkScannedQty(this.data, true);
  }

  calcuateTime(event) {
    if (event.target.valueAsNumber > 2 && event.target.placeholder == "Validity Minutes") {
      event.preventDefault();
    }
    let hours = this.validityForm.get('hrs').value ? (this.validityForm.get('hrs').value) * 1000 * 60 * 60 : 0;
    let minutes = this.validityForm.get('mins').value ? (this.validityForm.get('mins').value) * 1000 * 60 : 0;

    let validateTime = new Date().getTime() + hours + minutes;

    this.validityForm.patchValue({
      validityDate: new Date(validateTime)
    })
    this.checkScannedQty(this.data, true);

  }

  validateTime() {
    const req_Data = {
      action: 'generate_tp',
      data: { "validityDateTime": this.validityForm.get('validityDate').value, "tpNo": this.data.tpNo }
    }

    this.shipmentService.validityDateTime(req_Data).subscribe(result => {
      this.dialogRef.close('Date Validated Successfully');
    })
  }

  checkScannedQty(data: any, showErrorMessage = false){
    data.consignment.forEach(item => {
      item.productDetails.forEach(product => {
        this.utilService.getTotalScannedCasesBottles(product);
      });
    })
    const noMatchData = data.consignment.map(item => {
      let status = false;
      item.productDetails.forEach((product:any)=>{
        if (this.data.destnEntityType === 'Distillery') {
          status = this.checkTotalQty(product);
        } else {
          if(product.approvedCases){
            status = item.productDetails.some(product => (product.approvedCases ? parseInt(product.approvedCases) : 0) !== parseInt(this.getTotalCount(product)));
          }else{
            status = item.productDetails.some(product => ((product.approvedBottles ? product.approvedBottles : 0) !== (product.bottlesCount ? product.bottlesCount : 0)));
          }
        }
      });
      return status;
      // return item.productDetails.some(product => (product.approvedCases ? parseInt(product.approvedCases) : 0) !== parseInt(this.getTotalCount(product)) || ((product.approvedBottles ? product.approvedBottles : 0) !== (product.bottlesCount ? product.bottlesCount : 0)));
    })
    const isDisable = noMatchData.some(scannedCount => scannedCount === true);
    if (showErrorMessage && isDisable) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please check the scanned cases / scanned bottles' });
    }
    return isDisable;
  }

  getTotalCount(product){
    console.log(product,'fffff11111dd:::::');

    if(product.breakages){
      let totalCases = product.totalScannedCases + (((product.totalScannedBottles ? product.totalScannedBottles : 0) + product.breakages.bottles) / product.unitsPerCase) + (product.breakages.cases ? product.breakages.cases : 0);
      console.log(product.approvedCases,'fffff:::::', totalCases);
      return totalCases;
    }
    console.log(product.approvedCases,'fffff11111:::::', product.casesCount);

    return product.casesCount;
  }

  tpComplete() {
    this.spinner.show();
    delete this.data['colName'];
    if(this.data.consignmentType ==="OFIDT"){
      delete this.data['icdc'];
    }
    this.data["validityDateTime"] = this.validityForm.get('validityDate').value
    const req_Data = {
      action: this.data.consignmentType ==="OFIDT" ? 'idt_tp_complete': this.data.consignmentType ==="STOCKRETURN" ? 'tp_complete_srd' : 'tp_complete',
      data: this.data
    }
    if (this.data.destnEntityType === 'Distillery') {
      req_Data.action = 'tp_complete_srd_to_dist';
    }


    this.shipmentService.postTpComplete(req_Data).subscribe(result => {
      if(result.status === 'SUCCESS'){
        this.dialogRef.close('TP Completed Successfully');
      }else{
        this.dialogRef.close();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: result.status });
      }
      this.spinner.hide();
    })
  }

  dialogClose(): void {
    this.dialogRef.close();
  }
  checkTotalQty(product): any {
    const shipmentQty = ((product.approvedCases ? product.approvedCases : 0) * product.unitsPerCase) + (product.approvedBottles ? product.approvedBottles : 0);
    const receivedQty = ((product.totalScannedCases ? product.totalScannedCases : 0) * product.unitsPerCase) + (product.totalScannedBottles ? product.totalScannedBottles : 0);
    const breakageQty = ((product.breakageCases ? product.breakageCases : 0) * product.unitsPerCase) + (product.breakageBottles ? product.breakageBottles : 0);
    const shortageQty = ((product.shortageCases ? product.shortageCases : 0) * product.unitsPerCase) + (product.shortageBottles ? product.shortageBottles : 0);
    const totalReceivedQtyInclBreakgesShortages = receivedQty + breakageQty + shortageQty;
    return shipmentQty !== totalReceivedQtyInclBreakgesShortages;
  }

}
