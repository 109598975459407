import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

const baseUrl = environment.baseUrl;
const recoveryUrl = baseUrl + 'recovery';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Recovery'] && userModules.mod_feats['Recovery'].features ? userModules.mod_feats['Recovery'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class RecoveryService {
  constructor(private http:HttpClient) { }

  getRecoveryFeatures() {
    const headerDict = {
      feature
    };
    return this.http.get(recoveryUrl, { headers: headerDict });
  }

  createRecovery(data){
    return this.http.post(recoveryUrl, data);
  }

  updateRecovery(data){
    return this.http.put(recoveryUrl, data);
  }
}