import { CommonsService } from 'src/app/core/services/commons.service';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OtpVerificationDialogComponent } from 'src/app/core/components/otp-verification-dialog/otp-verification-dialog.component';
import { IndentManagementService } from 'src/app/core/services/indent-management.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-open-distillery-indent-status-dialog',
  templateUrl: './open-distillery-indent-status-dialog.component.html',
  styleUrls: ['./open-distillery-indent-status-dialog.component.scss']
})
export class OpenDistilleryIndentStatusDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'no',
    'productCode',
    'brandName',
    'size',
    'casesCount',
    'approvedQuantity',
    'rate',
    'value'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  addQuantityForm: FormGroup;
  limit: number;
  offset: number;
  search: string;
  error: any;
  roleName: any;
  vailidityDate: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;
  showPrint: any = false;
  showImportPrint:any=false;
  printId: any = '';
  disableApprove:any;

  constructor(
    public util: UtilService, private fb: FormBuilder, public dialogRef: MatDialogRef<OpenDistilleryIndentStatusDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, private indentManagementService: IndentManagementService,private dialog: MatDialog,private commponService:CommonsService) {
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
  }

  ngOnInit(): void {

    this.vailidityDate = this.getValidityDate();
    this.vailidityDate.setDate(0);
    this.vailidityDate = this.vailidityDate.toDateString();
    this.data.productDetails.forEach(element => {
      element['approvedQty'] = element.approvedQty ? element.approvedQty : (element.requestedQty ? element.requestedQty : element.bottlesQuantity);
      element['value'] =  (element.casePrice ? element.casePrice : element.rate ? element.rate : 0) * (element.approvedQty ? element.approvedQty : 0);
    });

    this.dataSource = new MatTableDataSource(this.data.productDetails);

    this.addQuantityForm = this.fb.group({
      poNo: [this.data.purchaseOrderDetails ? this.data.purchaseOrderDetails[0].poNo : '']

    });
    console.log(this.data)
  }


  getValidityDate() {
    // let currentDate = new Date();
    // let currentMonth = currentDate.getMonth() < 10 ? currentDate.getMonth() + 2 : currentDate.getMonth() - 10;
    // let currentYear = currentDate.getFullYear();
    // return new Date(new Date(currentMonth + '/' + 1 + '/' + currentYear).getTime() - (6 * 60 * 60 * 1000));
    const currentDate = new Date();
    const currentMonthLastdate =  new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const currentMonthLastDateTime = new Date(currentMonthLastdate);
    currentMonthLastDateTime.setHours(23, 59, 59);
    return currentMonthLastDateTime;
  }
  ngAfterViewInit() {
    this.util.ngAfterViewInit(this.dataSource, this.paginator, this.sort, null, null);
  }

  applyFilter() {
    this.util.applyFilter(this.dataSource, this.search);
  }
  updateStatus(status) {
    if(status == 'Approved'){
      // const req_otp ={
      //   action: "send_otp",
      //   data:{
      //    ...this.data
      //   }
      // }
      
      // this.commponService.sendVerifyOtp(req_otp).subscribe((res:any)=>{
        
        
      // })
      // const dialogRef = this.dialog.open(OtpVerificationDialogComponent, {
      //   width: '25%',
      //   height:'45%',
      //   autoFocus: false
      // });
  
      // dialogRef.afterClosed().subscribe((result) => {
      //   if(result.message == 'SUCCESS'){
          const req_Data = {
            action: status === 'Approved' ? 'approve_ifs' : 'reject_ifs',
            data: [this.data]
          }
          req_Data.data[0].productDetails = req_Data.data[0].productDetails.map(res => {
            res.approvedQty = Number(res.approvedQty);
            return res;
          })
          req_Data.data[0].status = status;
          this.data.validityDate = this.getValidityDate();
          console.log(this.data, req_Data, "77::::::::::")
          this.indentManagementService.updateIndents(req_Data).subscribe((res: any) => {
            this.data.status = status;
            this.dialogRef.close('Indent updated Successfully');
          }, (err) => {
            this.error = err.error.message;
          });
      //   }
      // });
    }else{
      const req_Data = {
        action: status === 'Approved' ? 'approve_ifs' : 'reject_ifs',
        data: this.data
      }
      req_Data.data.productDetails = req_Data.data.productDetails.map(res => {
        res.approvedQty = Number(res.approvedQty);
        return res;
      })
      req_Data.data.status = status;
      this.data.validityDate = this.getValidityDate();
      console.log(this.data, req_Data, "77::::::::::")
      this.indentManagementService.updateIndents(req_Data).subscribe((res: any) => {
        this.data.status = status;
        this.dialogRef.close('Indent updated Successfully');
      }, (err) => {
        this.error = err.error.message;
      });
    }

 
  }

  setPrice(row) {
    row.value = (row.casePrice ? row.casePrice : row.rate)  * row.approvedQty;
    this.disableApprove = (row.requestedQty ? row.requestedQty : row.bottlesQuantity) < row.approvedQty
    // if(row.requestedQty < row.approvedQty){
    //   this
    // }
  }
  onPaginateChange(e) {
    this.limit = e.pageSize;
    this.offset = e.pageIndex * e.pageSize;
  }
  dialogClose(): void {
    this.dialogRef.close();
  }
  doPrint(id): any {
    this.printId = id;
    this.showPrint = true;
    this.getPrint(id);
  }
  getPrint(id): any {
    let printContents;
    let popupWin;
    setTimeout(() => {
      if (document.getElementById(id)) {
        printContents = document.getElementById(id).innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.write(`${printContents}`);
        popupWin.document.close();
        this.showPrint = false;
      }
    }, 500);
  }

  doImportPrint(id): any {
    this.printId = id;
    this.showImportPrint = true;
    this.getImportPrint(id);
  }
  getImportPrint(id): any {
    let printContents;
    let popupWin;
    setTimeout(() => {
      if (document.getElementById(id)) {
        printContents = document.getElementById(id).innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.write(`${printContents}`);
        popupWin.document.close();
        this.showPrint = false;
        this.showImportPrint = false;
      }
    }, 500);
  }
  isButtonDisable(): any {
    return this.dataSource.filteredData.some(el => (el.approvedQty < 1) || (el.approvedQty > (el.requestedQty ? el.requestedQty : el.bottlesQuantity)));
  }


}
