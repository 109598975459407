import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { ReplaySubject } from 'rxjs';
import { CommonsService } from 'src/app/core/services/commons.service';
import { UserManualService } from 'src/app/core/services/user-manual.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { OpenSupplierDialogComponent } from '../supplier/open-supplier-dialog/open-supplier-dialog.component';

@Component({
  selector: 'app-open-vendor-dialog',
  templateUrl: './open-vendor-dialog.component.html',
  styleUrls: ['./open-vendor-dialog.component.scss']
})
export class OpenVendorDialogComponent implements OnInit {
  vendorForm: FormGroup;
  roles: any;
  vendors = [];
  filteredRoles: any;
  isPassword: boolean = true;
  titles = ['Mr.', 'Mrs.', 'Ms.'];
  isConfirmPassword: boolean = true;
  public vendorFilterCtrl: FormControl = new FormControl();
  filteredVendors: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);


  constructor(private fb: FormBuilder, public util: UtilService, private dialogRef: MatDialogRef<OpenSupplierDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,private messageService: MessageService, private CommonsService: CommonsService,
    private userManualService: UserManualService) {

  }

  ngOnInit(): void {
    this.getVendors();
    this.getVendorRoles();

    this.vendorForm = this.fb.group({
      title: ['', [Validators.required,]],
      fullName: ['', [Validators.required,]],
      emailId: [''],
      userName: ['', [Validators.required,]],
      password: ['', [Validators.required,]],
      confirmPassword: ['', [Validators.required,]],
      vendorCode: ['', [Validators.required,]],
      role: ['', [Validators.required,]],
      mobileNo: [''],
      vendorFilter: ''
    }, {
      validators: this.confirmPasswordmatch('password', 'confirmPassword')
    });
    this.util.matSelectFilter(this, "vendorFilterCtrl", "filteredVendors", "vendors");
  }


  getVendors() {
    let paramObj = {
      action: 'common_apis',
      q: '{"data": ["get_all_vendors"]}'
    }
    this.CommonsService.getCommonEntites(paramObj).subscribe((res) => {
      this.vendors = res['get_all_vendors'].map((vendor) => vendor.vendorId);
      this.filteredVendors.next(this.vendors);
    });
  }

  getVendorRoles() {
    let paramObj = {
      action: 'common_apis',
      q: '{"data": ["get_vendor_roles"]}'
    }
    this.CommonsService.getCommonEntites(paramObj).subscribe((res) => {
      this.roles = res['get_vendor_roles'];
      this.filteredRoles = JSON.parse(JSON.stringify(this.roles));      
    });
  }

  confirmPasswordmatch(controlName: string, matchingControlName: string) {
    return (fromGroup: FormGroup) => {
      const control = fromGroup.controls[controlName];
      const matchingControl = fromGroup.controls[matchingControlName];
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmPasswordmatch: true });
      }
      else {
        matchingControl.setErrors(null);
      }
    }
  }

  createVendor() {
    this.vendorForm.patchValue({
      createdOn: new Date().toISOString()
    });
    const plObj = this.vendorForm.value;
    const req_Data = {
      action: 'add_vendor_user',
      data: {
        ...plObj,
        number: plObj.mobileNo
      }
    }
    req_Data.data['activeUsers'] = this.util.encryptPassword(req_Data.data.password);
    delete req_Data.data.confirmPassword;
    delete req_Data.data.password;
    delete req_Data.data.mobileNo;
    delete req_Data.data.vendorFilter;

    this.userManualService.createVendor(req_Data).subscribe((res: any) => {
      if (res.status && res.status != 'User already exists') {         
        this.dialogRef.close('User Created Sucessfully');
      } else {
        this.messageService.add({ severity: 'error', detail: 'User already exists' });
      }
    }, (err) => {
      this.messageService.add({ severity: 'error', detail: err.error.message });
    });
  }

  showPassword() {
    this.isPassword = !this.isPassword;
  }

  showConfirmPassword() {
    this.isConfirmPassword = !this.isConfirmPassword;
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

}