import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Icdc } from '../models/inter-depot/icdc.model';
import { InterDepotReceipt } from '../models/inter-depot/inter-depot-receipt.model';
import { InterDepotTransferSupplier } from '../models/inter-depot/inter-depot-transfer-supplier.model';
import { InterDepotTransfer } from '../models/inter-depot/inter-depot-transfer.model';
import { Ofidt } from '../models/inter-depot/ofidt.model';
import { Order } from '../models/inter-depot/orders.model';
import { Ofidts } from '../models/supply-delivery/ofidt';
const baseUrl = environment.baseUrl;
const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'))?JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).mod_feats: {};
let interDepotUrl = userModules['Inter Depot'] ? baseUrl + 'inter_depot' : baseUrl + 'inter_depot_transfer';
let interDepot = baseUrl + 'inter_depot';
// It will change the base urls Inter depot to inter depot transfer as per the module lable
const ordersUrl = baseUrl + 'interdepot/orders';
const idtUrl = baseUrl + 'inter_depot';
const interDepotTransferUrl = baseUrl + 'depottransfers';
const interDepotReceiptUrl = baseUrl + 'interdepot/interdepotreceipt';
const ofidtUrl = baseUrl + 'interdepot/ofidt';
const icdcUrl = baseUrl + 'interdepot/icdc';
const interDepotTransferSupplierUrl = baseUrl + 'interdepot/interdepottransfersupplier'


@Injectable({
  providedIn: 'root'
})
export class InterDepotService {
  constructor(private http: HttpClient) {
  }
  getInterDepotUrl(): any {
    const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'))?JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).mod_feats: {};
    return userModules['Inter Depot'] ? baseUrl + 'inter_depot' : baseUrl + 'inter_depot_transfer';
  }

  createOrder(ordersobj: any) {
    return this.http.post(ordersUrl, ordersobj)
  }
  getOrder(offset, limit): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);

    limit ? paramObj.limit = limit : limit = null;
    return this.http.get('assets/samples/orders.json');
  }

  putOrder(ordersobj) {
    return this.http.put<Order>(`${ordersUrl}`, ordersobj);
  }

  updateIDTs(idtobj) {
    return this.http.put<Order>(`${idtUrl}`, idtobj);
  }

  createInterDepotTransfer(interDepotTransferobj: any) {
    return this.http.post(interDepotTransferUrl, interDepotTransferobj);
  }

  createIDT(interDepotTransferobj: any) {
    return this.http.post(interDepotUrl, interDepotTransferobj);
  }

  createIdtTP(interDepotTransferobj: any) {
    return this.http.post(interDepot, interDepotTransferobj);
  }
  
  updateIDT(interDepotTransferobj: any) {
    return this.http.put(this.getInterDepotUrl(), interDepotTransferobj);
  }

  getInterDepotTransfer(offset, limit, incoming?: boolean): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    incoming ? paramObj.incoming = incoming : incoming = null;
    limit ? paramObj.limit = limit : limit = null;
    return this.http.get('assets/samples/inter-depot-transfer.json');
  }

  putInterDepotTransfer(interDepotTransferobj) {
    return this.http.put<InterDepotTransfer>(`${interDepotTransferUrl}`, interDepotTransferobj)
  }

  createInterDepotReceipt(interDepotReceiptobj: InterDepotReceipt) {
    return this.http.post(interDepotReceiptUrl, interDepotReceiptobj);
  }
  getInterDepotReceipt(offset, limit): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);

    limit ? paramObj.limit = limit : limit = null;
    return this.http.get('assets/samples/inter-depot-receipt.json');
  }

  putInterDepotReceipt(interDepotReceiptobj) {
    return this.http.put<InterDepotReceipt>(`${interDepotReceiptUrl}`, interDepotReceiptobj);
  }

  createOfidt(ofidtobj: Ofidt) {
    return this.http.post(ofidtUrl, ofidtobj)
  }

  getOfidt(offset, limit): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);

    limit ? paramObj.limit = limit : limit = null;
    return this.http.get('assets/samples/ofidt-cancelled.json');
  }

  putOfidt(ofidtobj) {
    return this.http.put<Ofidt>(`${ofidtUrl}`, ofidtobj);
  }

  createIcdc(icdcobj: Icdc) {
    return this.http.post(icdcUrl, icdcobj);
  }
  getIcdc(offset, limit): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);

    limit ? paramObj.limit = limit : limit = null;
    return this.http.get(icdcUrl, { params: paramObj });
  }

  putIcdc(icdcobj) {
    return this.http.put<Icdc>(`${icdcUrl}`, icdcobj);
  }

  getIcdcCancelled(offset, limit, search): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);

    limit ? paramObj.limit = limit : limit = null;
    return this.http.get('assets/samples/icdc-cancelled.json');
  }

  getDepotTransferDetails(offset, limit, search) {
    return this.http.get(interDepotTransferUrl);
  }

  getInterDepotTransferSupplier(offset, limit, search): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);

    limit ? paramObj.limit = limit : limit = null;
    return this.http.get('assets/samples/inter-depot-transfer-supplier.json');
  }
  putInterDepotTransferSupplier(interDepotTransferSupplierObj) {
    return this.http.put<InterDepotTransferSupplier>(`${interDepotTransferSupplierUrl}`, interDepotTransferSupplierObj);
  }
  getInterDepotTransferLocal(offset, limit, search): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);

    limit ? paramObj.limit = limit : limit = null;
    return this.http.get('assets/samples/inter-depot-transfer-local.json');
  }

  getInterDepotFeatures(paramObj: any = ''): any {
    return this.http.get(this.getInterDepotUrl(), { params: paramObj });
  }
  postTpComplete(tpObj): Observable<any> {
    return this.http.put(`${this.getInterDepotUrl()}`, tpObj);
  }
  generateActions(payload): any {
    return this.http.put(this.getInterDepotUrl(), payload);
  }
  approveEvc(evcObj): Observable<any> {
    return this.http.put(this.getInterDepotUrl(), evcObj);
  }
  acknowledgeSRA(sraObj): Observable<any> {
    return this.http.put(this.getInterDepotUrl(), sraObj);
  }
  putOfs(ofsObj): any {
    return this.http.put<Ofidts>(`${this.getInterDepotUrl()}`, ofsObj);
  }
  saveShipmentQuantity(shipmentQtyObj): Observable<any> {
    return this.http.put(`${this.getInterDepotUrl()}`, shipmentQtyObj);
  }
  scanBarCode(caseObj): Observable<any> {
    return this.http.put(`${this.getInterDepotUrl()}`, caseObj);
  }
}
