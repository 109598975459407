import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
// import { Icdc } from '../models/inter-retailer/icdc.model';
// import { InterRetailerReceipt } from '../models/inter-retailer/inter-retailer-receipt.model';
// import { InterRetailerTransferSupplier } from '../models/inter-retailer/inter-retailer-transfer-supplier.model';
// import { InterRetailerTransfer } from '../models/inter-retailer/inter-retailer-transfer.model';
// import { Ofidt } from '../models/inter-retailer/ofidt.model';
// import { Order } from '../models/inter-retailer/orders.model';

const baseUrl = environment.baseUrl;
const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'))?JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).mod_feats: {};
const interRetailerUrl = userModules['Inter Retailer'] ? baseUrl + 'inter_retailer' : baseUrl + 'inter_retailer_transfer'; //inter_retailer_transfer
// It will change the base urls Inter retailer to inter retailer transfer as per the module lable
const ordersUrl = baseUrl + 'interretailer/orders';
const interRetailerTransferUrl = baseUrl + 'retailertransfers';
const interRetailerReceiptUrl = baseUrl + 'interretailer/interretailerreceipt';
const ofidtUrl = baseUrl + 'interretailer/ofidt';
const icdcUrl = baseUrl + 'interretailer/icdc';
const interRetailerTransferSupplierUrl = baseUrl + 'interretailer/interretailertransfersupplier';


@Injectable({
  providedIn: 'root'
})
export class InterRetailerService {
  constructor(private http: HttpClient) {
  }

  createOrder(ordersobj: any) {
    return this.http.post(ordersUrl, ordersobj)
  }
  getOrder(offset, limit): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);

    limit ? paramObj.limit = limit : limit = null;
    return this.http.get('assets/samples/orders.json');
  }

  // putOrder(ordersobj) {
  //   // return this.http.put<Order>(`${ordersUrl}`, ordersobj);
  // }

  // createInterRetailerTransfer(interRetailerTransferobj: any) {
  //   // return this.http.post(interRetailerTransferUrl, interRetailerTransferobj);
  // }

  createIRT(interRetailerTransferobj: any): any {
    return this.http.post(interRetailerUrl, interRetailerTransferobj);
  }

  getInterRetailerTransfer(offset, limit, incoming?: boolean): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    incoming ? paramObj.incoming = incoming : incoming = null;
    limit ? paramObj.limit = limit : limit = null;
    return this.http.get('assets/samples/inter-retailer-transfer.json');
  }

  // putInterRetailerTransfer(interRetailerTransferobj) {
  //   return this.http.put<InterRetailerTransfer>(`${interRetailerTransferUrl}`, interRetailerTransferobj)
  // }

  // createInterRetailerReceipt(interRetailerReceiptobj: InterRetailerReceipt) {
  //   return this.http.post(interRetailerReceiptUrl, interRetailerReceiptobj);
  // }
  // getInterRetailerReceipt(offset, limit): Observable<any> {
  //   const paramObj: any = {};
  //   offset !== undefined ? (paramObj.offset = offset) : (offset = null);

  //   limit ? paramObj.limit = limit : limit = null;
  //   return this.http.get('assets/samples/inter-retailer-receipt.json');
  // }

  // putInterRetailerReceipt(interRetailerReceiptobj) {
  //   // return this.http.put<InterRetailerReceipt>(`${interRetailerReceiptUrl}`, interRetailerReceiptobj);
  // }

  // createOfidt(ofidtobj: Ofidt) {
  //   return this.http.post(ofidtUrl, ofidtobj)
  // }

  getOfidt(offset, limit): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);

    limit ? paramObj.limit = limit : limit = null;
    return this.http.get('assets/samples/ofidt-cancelled.json');
  }

  // putOfidt(ofidtobj) {
  //   return this.http.put<Ofidt>(`${ofidtUrl}`, ofidtobj);
  // }

  // createIcdc(icdcobj: Icdc) {
  //   return this.http.post(icdcUrl, icdcobj);
  // }
  getIcdc(offset, limit): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);

    limit ? paramObj.limit = limit : limit = null;
    return this.http.get(icdcUrl, { params: paramObj });
  }

  // putIcdc(icdcobj) {
  //   return this.http.put<Icdc>(`${icdcUrl}`, icdcobj);
  // }

  // getIcdcCancelled(offset, limit, search): Observable<any> {
  //   const paramObj: any = {};
  //   offset !== undefined ? (paramObj.offset = offset) : (offset = null);

  //   limit ? paramObj.limit = limit : limit = null;
  //   return this.http.get('assets/samples/icdc-cancelled.json');
  // }

  // getRetailerTransferDetails(offset, limit, search) {
  //   return this.http.get(interRetailerTransferUrl);
  // }

  getInterRetailerTransferSupplier(offset, limit, search): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);

    limit ? paramObj.limit = limit : limit = null;
    return this.http.get('assets/samples/inter-retailer-transfer-supplier.json');
  }
  // putInterRetailerTransferSupplier(interRetailerTransferSupplierObj) {
  //   return this.http.put<InterRetailerTransferSupplier>(`${interRetailerTransferSupplierUrl}`, interRetailerTransferSupplierObj);
  // }
  getInterRetailerTransferLocal(offset, limit, search): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);

    limit ? paramObj.limit = limit : limit = null;
    return this.http.get('assets/samples/inter-retailer-transfer-local.json');
  }

  getInterRetailerFeatures(): any {
    return this.http.get(interRetailerUrl);
  }
}
