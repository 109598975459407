
import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CommonsService } from 'src/app/core/services/commons.service';
import { IndentManagementService } from 'src/app/core/services/indent-management.service';
import { InterRetailerService } from 'src/app/core/services/inter-retailer.service';
import { ShipmentsService } from 'src/app/core/services/shipments.service';

@Component({
  selector: 'app-manage-inter-retailer-transfer-supplier',
  templateUrl: './manage-inter-retailer-transfer-supplier.component.html',
  styleUrls: ['./manage-inter-retailer-transfer-supplier.component.scss']
})
export class ManageInterRetailerTransferSupplierComponent implements OnInit, AfterViewInit, OnDestroy {


  ifsForm: FormGroup;
  productInformation: FormArray;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  public depotFilterCtrl: FormControl = new FormControl();
  public sourceRetailerFilterCtrl: FormControl = new FormControl();
  public destinationRetailerFilterCtrl: FormControl = new FormControl();
  offset: number;
  search: string;
  allDepots = [];
  sourceRetailerNames = [];
  destinationRetailerNames = [];
  productCodeList = [];
  brandNameList = [];
  batchIdList = [];
  sizeList = [];
  allRetailers = [];
  filteredRetailers = [];
  isBrandNameDisable = false;
  isSizeDisable = false;
  isProductCodeDisable = false;
  

  // tslint:disable-next-line:variable-name
  public _onDestroy = new Subject<void>();
  public filteredDepotNames: ReplaySubject<any> = new ReplaySubject<any>(1);
  public filteredSourceRetailerNames: ReplaySubject<any> = new ReplaySubject<any>(1);
  public filteredDestinationRetailerNames: ReplaySubject<any> = new ReplaySubject<any>(1);
  batchIds: any;
  productInfo: any;
  filteredData: any[];
  productDetails: any;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ManageInterRetailerTransferSupplierComponent>,
    private interRetailerService: InterRetailerService,
    @Inject(MAT_DIALOG_DATA) public data,
    // private indentManagementService: IndentManagementService,
    private commonsService: CommonsService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    const paramObj = '?action=common_apis&q={"data": ["get_dist_depot","get_prod_info","get_batches", "get_retailer_depot"]}';
    this.spinner.show();
    this.commonsService.getList(paramObj).subscribe((data: any) => {
      this.allDepots  = data.get_dist_depot.depots;
      this.productInfo = data.get_prod_info;
      this.filteredDepotNames.next(this.allDepots.slice());
      this.spinner.hide();

    });
    this.getAllRetailers();


    this.depotFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.doFilter(this.allDepots, this.depotFilterCtrl, this.filteredDepotNames);
    });

    this.sourceRetailerFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.doFilter(this.filteredRetailers, this.sourceRetailerFilterCtrl, this.filteredSourceRetailerNames);
    });
    this.destinationRetailerFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.doFilter(this.filteredRetailers, this.destinationRetailerFilterCtrl, this.filteredDestinationRetailerNames);
    });

    this.ifsForm = this.fb.group({

      depotCode: ['', [Validators.required, ]],
      sourceRetailerName: ['', [Validators.required, ]],
      destinationRetailerName: ['', [Validators.required, ]],
      indentId: [''],
      productInformation: this.fb.array([this.updateproductInformation()])
    });
    if (this.data) {
      this.ifsForm.patchValue({
        depotCode: this.data.depotCode,
        sourceRetailerName: this.data.sourceRetailerName,
        destinationRetailerName: this.data.destinationRetailerName,
        indentId: this.data.indentId,
        ofsValidityDate: this.data.ofsValidityDate,
        date: this.data.date,
      });
      const productInformation = this.ifsForm.get('productInformation') as FormArray;
      productInformation.clear();
      this.data.productInformation.forEach(b => {
        productInformation.push(
          this.fb.group({
            checkedValue: b.checkedValue,
            productCode: b.productCode,
            brandName: b.brandName,
            requestedQty: Number(b.requestedQty),
            casePrice: b.basicPricing ? Number(b.basicPricing[0].price) : '',
            value: Number(b.requestedQty) * Number(b.basicPricing[0].price)
          })
        );
      });
    }
    this.productInformation = this.ifsForm.get('productInformation') as FormArray;
    this.saveDisabled = true;
    // this.getSamplesDetails();
  }


  ngAfterViewInit(): any {
    // this.setInitialValue();

  }
  depotChange(event): any {
    this.ifsForm.controls.sourceRetailerName.patchValue('');
    this.ifsForm.controls.destinationRetailerName.patchValue('');
    this.filteredRetailers = this.allRetailers.filter((ret, i) => i < 5);
    this.filteredSourceRetailerNames.next(
      // this.sourceRetailerNames.filter(ret => ret.depotId === event.value)
      this.filteredRetailers
    );
  }
  searchDropDown(event): any {
    // console.log(event);
  }

  sourceRetailerChange(event, filer = null): any {
    this.ifsForm.controls.destinationRetailerName.patchValue('');
    this.filteredDestinationRetailerNames.next(
      this.filteredRetailers.filter(ret => ret.toLowerCase() !== event.value.toLowerCase())
    );
  }
  sourceChange(event, filter): any {
    const code = event.value;
    this.filteredData = [];

    // if(!filter) {
    this.filteredData = this.productInfo.filter(info => {
      return info.depotCode === code;
    });
    // }

    const filteredBatchData = this.batchIds.filter(info => {
      return info.depotCode === code;
    });

    /*  if(filter) {
        this.filteredData =  this.filteredData.filter(info => {
          return info[filter] == code;
        })
      }
       */
    // console.log(this.filteredData, '158:::::');
    this.productCodeList = Array.from(new Set(this.filteredData.map(data => data.productCode)));
    this.brandNameList = Array.from(new Set(this.filteredData.map(data => data.brandName)));
    // console.log(this.brandNameList, '161::::');
    this.brandNameList = this.brandNameList.filter((list) => {
      // console.log(this.brandNameList.indexOf(list), this.brandNameList.lastIndexOf(list), '160::::::::::');
      if (this.brandNameList.indexOf(list) !== this.brandNameList.lastIndexOf(list)) {
        this.brandNameList.splice(this.brandNameList.indexOf(list), 1);
      }
      return this.brandNameList.indexOf(list) === this.brandNameList.lastIndexOf(list);
    });

    this.sizeList = Array.from(new Set(this.filteredData.map(data => data.size)));
    // this.sizeList = this.sizeList.filter((list) => {
    //   return this.sizeList.indexOf(list) == this.sizeList.lastIndexOf(list);
    // })
   // this.batchIdList = Array.from(new Set(filteredBatchData.map(data => data.batchId)));
  }

  setInitialValue(): any {
    // this.filteredDepotNames
    //   .pipe(take(1), takeUntil(this._onDestroy))
    //   .subscribe(() => {
    //     // setting the compareWith property to a comparison function
    //     // triggers initializing the selection according to the initial value of
    //     // the form control (i.e. _initializeSelection())
    //     // this needs to be done after the filteredBanks are loaded initially
    //     // and after the mat-option elements are available
    //     this.singleDistillerySelect.compareWith = (a, b) => a && b && a === b;
    //   });

    // this.filteredRetailerNames
    //   .pipe(take(1), takeUntil(this._onDestroy))
    //   .subscribe(() => {
    //     // setting the compareWith property to a comparison function
    //     // triggers initializing the selection according to the initial value of
    //     // the form control (i.e. _initializeSelection())
    //     // this needs to be done after the filteredBanks are loaded initially
    //     // and after the mat-option elements are available
    //     this.singleRetailerSelect.compareWith = (a, b) => a && b && a === b;
    //   });
  }

  doFilter(retailerNames, retailerFilterCtrl, filteredRetailerNames): any {
    if (!retailerNames || (retailerNames && retailerNames.length === 0)) {
      return;
    }
    let search = retailerFilterCtrl.value;
    if (!search) {
      filteredRetailerNames.next(retailerNames.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    filteredRetailerNames.next(
      retailerNames.filter(ret => ret.toLowerCase().indexOf(search) > -1)
    );
  }

  productChange(event, i): any {
    // const productInformation = this.ifsForm.get('productInformation') as FormArray;
    const productInformation = (this.ifsForm.get('productInformation') as FormArray).at(i);
    const [bindData] = this.productInfo.filter(data => {
      return data.productCode === event.value;
    });
    this.batchIdList[i] = [];
    this.productInfo.forEach(prod => {
      if (prod.productCode === event.value) {
        this.batchIdList.push(prod.batchId);
      }
    });
    // this.data.productInformation.forEach(b => {
    // console.log(event.value, i, bindData, productInformation, '242::::::');
    this.isBrandNameDisable = true;
    this.isSizeDisable = true;

    productInformation.patchValue({
      productCode: bindData.productCode,
      brandName: bindData.brandName,
      size: bindData.size,
      // batchId: '',
      requestedQty: '',
      casePrice: bindData.basicPricing ? bindData.basicPricing[0].price : ''

    });

    if (productInformation.get('casePrice').value && productInformation.get('requestedQty').value) {
      productInformation.patchValue({
        value: productInformation.get('casePrice').value * productInformation.get('requestedQty').value
      });
    }

    this.validateForm();

  }
  updateproductInformation(): any {
    return this.fb.group({
      productCode: ['', Validators.required],
      size: ['', Validators.required],
      brandName: ['', Validators.required],
      requestedQty: ['', Validators.required],
      casePrice: ['', Validators.required],
      value: ['', Validators.required],
      closingBalCases: [''],
      stockReceiptDateTime: ['']
    });
  }

  quantityChange(event, i): any {
    const productInformation = (this.ifsForm.get('productInformation') as FormArray).at(i);
    if (productInformation.get('casePrice').value) {
      productInformation.patchValue({
        value: Number(productInformation.get('casePrice').value) * Number(event.target.value)
      });
    }
    this.validateForm();
  }

  productDataChange(event, i): any {
    // console.log(event, this.filteredData, '258::::');

    const productInformation = (this.ifsForm.get('productInformation') as FormArray).at(i);

    if (event.source.ngControl.name === 'brandName' || event.source.ngControl.name === 'size') {
      const [bindData] = this.filteredData.filter(data => {
        return data[event.source.ngControl.name] === event.value;
      });

      if (event.source.ngControl.name === 'size') {
        productInformation.patchValue({
          productCode: bindData.productCode,
          size: bindData.size,
          batchId: '',
          requestedQty: '',
          casePrice: bindData.basicPricing ? bindData.basicPricing[0].price : '',
          value: ''
        });
      } else {
        productInformation.patchValue({
          productCode: bindData.productCode,
          brandName: bindData.brandName,
          size: bindData.size,
          batchId: '',
          requestedQty: '',
          casePrice: bindData.basicPricing ? bindData.basicPricing[0].price : '',
          value: ''

        });
      }


      if (productInformation.get('casePrice').value && productInformation.get('requestedQty').value) {
        productInformation.patchValue({
          value: productInformation.get('casePrice').value * productInformation.get('requestedQty').value
        });
      }

      this.isProductCodeDisable = true;
    }
    this.validateForm();
  }

  validateForm(): any {
    const control = this.ifsForm.controls.productInformation as FormArray;
    this.saveDisabled = !!control.controls.filter(item => !!Object.keys(item.value).filter(obj =>
      obj !== 'batchId' && !item.value[obj]
    ).length).length;
    // console.log(this.saveDisabled);
    // console.log(this.ifsForm);
    

  }
  addUnit(): any {
    const control = this.ifsForm.controls.productInformation as FormArray;
    control.push(this.updateproductInformation());
    this.isBrandNameDisable = false;
    this.isProductCodeDisable = false;
    this.isSizeDisable = false;
    this.saveDisabled = true;
  }


  removeUnit(i: number): any {
    const control = this.ifsForm.controls.productInformation as FormArray;
    control.removeAt(i);
    this.validateForm();
    this.isBrandNameDisable = false;
    this.isProductCodeDisable = false;
    this.isSizeDisable = false;
  }



  get f(): any {
    return this.ifsForm.controls;
  }

  submit(form): any {
    console.log(form);
    this.saveDisabled = true;
    // tslint:disable-next-line:variable-name
    const req_data = {
      action: 'add_supplier_irt',
      data: { ...this.ifsForm.value }
    };
    this.interRetailerService.createIRT(req_data).subscribe((res: any) => {
      this.dialogRef.close('Created Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  editDistilleryIndent(): any {

  }

  dialogClose(): void {
    this.dialogRef.close();
  }
  ngOnDestroy(): any {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  getAllRetailers(): any {
    const qData = {
      data: 'get_all_retailers'
    };
    // tslint:disable-next-line:variable-name
    const req_Data = {
      action: 'common_apis',
      q: JSON.stringify(qData)
    };
    this.commonsService.getCommonEntites(req_Data).subscribe((res) => {
      this.allRetailers = res.get_all_retailers.map(ret => ret.retailerId);
      this.filteredRetailers = this.allRetailers;
      this.filteredSourceRetailerNames.next(this.allRetailers.slice());
    });
  }

}
