import { MessageService } from 'primeng/api';
import { CommonsService } from 'src/app/core/services/commons.service';
import { liquorTypes } from "src/app/core/constants/liquor-type.constants";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  BottleSizes,
  unitsPerCase,
} from "src/app/core/constants/bottle-sizes.constants";
import { packTypes } from "src/app/core/constants/pack-type.constants";
import { ProductsService } from "src/app/core/services/products.service";
import { UtilService } from "src/app/core/utils/utility-helper";

@Component({
  selector: "app-add-brand-packig-dialog",
  templateUrl: "./add-brand-packig-dialog.component.html",
  styleUrls: ["./add-brand-packig-dialog.component.scss"],
})
export class AddBrandPackigDialogComponent implements OnInit {
  packTypeList: string[];
  sizeList: string[];
  unitsPerCase: string[];
  packTypeConstant = {};
  sizeListConstant = {};
  unitsPerCaseConstant = {};
  brandData: any;
  saveDisabled: boolean;
  editData: any;
  duplicateError = "Pack Type and Size Code shouldn't have duplicate ";
  brandPackingForm: FormGroup;
  selectedPackSize: any;
  selectedSizeConstant: any;
  selectedSizeUnit: any;
  formattedPrice: any;
  segmentDatas: any = [];
  packTypeListRootDatas: any = [];
  sizeListRootDatas: any = [];
  loader: any = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddBrandPackigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private productsService: ProductsService,
    public util: UtilService,
    private commonsService: CommonsService,
    private messageService: MessageService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.packTypeConstant = packTypes;
    this.sizeListConstant = BottleSizes;
    this.unitsPerCaseConstant = unitsPerCase;

    this.packTypeList = Object.keys(packTypes);
    this.packTypeListRootDatas = Object.keys(packTypes);
    this.sizeList = Object.keys(BottleSizes);
    this.sizeListRootDatas = Object.keys(BottleSizes);

    this.unitsPerCase = Object.keys(unitsPerCase);
    this.brandPackingForm = this.fb.group({
      liquorType: ["", Validators.required],
      packType: ["", Validators.required],
      size: ["", Validators.required],
      strength: ["", [Validators.required, Validators.max(999.999)]],
      basicPricing: ["", [Validators.required, Validators.max(1000000)]],
      importFee: ["", Validators.required],
      exportFee: ["", Validators.required],
      exciseDuty: ["", Validators.required],
      customDuty: ["", [Validators.required, Validators.max(1000000)]],
      // retailPricings: ["", Validators.required],
      //
    });
    if (this.data && this.data.length > 0) {
      this.brandPackingForm.patchValue({
        size: this.sizeList && this.sizeList.length === 1 ? this.sizeList[0] : '',
        strength: this.data[0].strength,
      });
    } else {
      var checkData: Boolean = this.data && this.data.pricing && this.data.pricing.length > 0;
      this.brandPackingForm.patchValue({
        liquorType: this.data.liquorType,
        packType: this.data.packType,
        size: this.sizeList.find(
          (size) => this.sizeListConstant[size] == this.data.size && this.unitsPerCaseConstant[size] == this.data.unitsPerCase
        ),
        strength: this.data.strength,
        basicPricing:this.data &&this.data.basicPricing && this.data.basicPricing.length > 0 ? this.data.basicPricing[0].price: "",
        importFee: checkData ? this.data.pricing[0].importFee : "",
        exportFee: checkData ? this.data.pricing[0].exportFee : "",
        exciseDuty: checkData ? this.data.pricing[0].exciseDuty : "",
        customDuty: checkData ? this.data.pricing[0].customDuty : "",
        // retailPricings:
        //   this.data &&
        //   this.data.retailPricings &&
        //   this.data.retailPricings.length > 0
        //     ? this.data.retailPricings[0].mrp
        //     : "",
      });
      // this.brandPackingForm.patchValue({basicPricing: this.util.formatCurrency(this.data &&this.data.basicPricing && this.data.basicPricing.length > 0 ? this.data.basicPricing[0].price: "")})
      // this.getCurrency(this.data &&this.data.basicPricing && this.data.basicPricing.length > 0 ? this.data.basicPricing[0].price: "", '');
      // this.getCurrency(checkData ? this.data.pricing[0].importFee : "", 'importFee');
      // this.getCurrency(checkData ? this.data.pricing[0].exportFee : "", 'exportFee');
      // this.getCurrency(checkData ? this.data.pricing[0].exciseDuty : "", 'exciseDuty');
      // this.getCurrency(checkData ? this.data.pricing[0].customDuty : "", 'customDuty');
      // this.getCurrency(this.data &&this.data.retailPricings &&this.data.retailPricings.length > 0? this.data.retailPricings[0].mrp: "", 'retailPricings');
    }

    this.brandPackingForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
    this.getSegments();

    /*  const req_Data = {
      "action": "get_liquor_types",
      "q": '{brandCode}'
    } */
  }
  selectedSize(e) {
    this.selectedPackSize = e;
    this.selectedSizeConstant = this.sizeListConstant[this.selectedPackSize];
    this.selectedSizeUnit = this.unitsPerCaseConstant[this.selectedPackSize];
  }
  checkForDuplicate() {
    const selectedSize = this.brandPackingForm.get("size").value;
    const selectedPackType = this.brandPackingForm.get("packType").value;
    let isDupildate = false;
    if (this.data && this.data.length > 0) {
      isDupildate = this.data.some(
        (item) =>
          item.packType === selectedPackType &&
          item.size === this.selectedSizeConstant &&
          item.unitsPerCase === this.selectedSizeUnit
      );
    }
    return isDupildate;
  }

  addBrandPacking() {
    const req_Data = {
      action: "add_brand_packing",
      data: this.brandPackingForm.value,
    };

    req_Data.data.brandCode = this.data[0].brandCode;
    req_Data.data.brandName = this.data[0].brandName;
    req_Data.data["liquorCode"] = this.data[0].liquorCode;
    req_Data.data["composition"] = this.data[0].composition;
    req_Data.data["strength"] = parseFloat(this.brandPackingForm.controls.strength.value);
    req_Data.data["basicPricing"] = parseFloat(this.brandPackingForm.controls.basicPricing.value);
    // req_Data.data["retailPricings"] = parseFloat(this.brandPackingForm.controls.retailPricings.value);
    req_Data.data["importFee"] = parseFloat(this.brandPackingForm.controls.importFee.value);
    req_Data.data["exportFee"] = parseFloat(this.brandPackingForm.controls.exportFee.value);
    req_Data.data["exciseDuty"] = parseFloat(this.brandPackingForm.controls.exciseDuty.value);
    req_Data.data["customDuty"] = parseFloat(this.brandPackingForm.controls.customDuty.value);
    // req_Data.data["basicPricing"] = parseFloat((this.brandPackingForm.controls.basicPricing.value.substring(0).replace(/,/g, '')));
    // req_Data.data["retailPricings"] = parseFloat((this.brandPackingForm.controls.retailPricings.value.substring(1).replace(/,/g, '')));
    // req_Data.data["importFee"] = parseFloat((this.brandPackingForm.controls.importFee.value.substring(1).replace(/,/g, '')));
    // req_Data.data["exportFee"] = parseFloat((this.brandPackingForm.controls.exportFee.value.substring(1).replace(/,/g, '')));
    // req_Data.data["exciseDuty"] = parseFloat((this.brandPackingForm.controls.exciseDuty.value.substring(1).replace(/,/g, '')));
    // req_Data.data["customDuty"] = parseFloat((this.brandPackingForm.controls.customDuty.value.substring(1).replace(/,/g, '')));
    this.saveDisabled = true;
    this.loader = true;
    this.productsService.addBrandPacking(req_Data).subscribe((res) => {
      if (res.status && res.status === 'SUCCESS') {
        this.dialogRef.close("Brand packing added successfully");
        this.loader = false;
      } else {
        this.loader = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Brand packing has not been added' });
      }
    });
  }

  editBrandPacking() {
    const req_Data = {
      action: "edit_brand_packing",
      data: this.brandPackingForm.value,
    };

    req_Data.data.brandCode = this.data.brandCode;
    req_Data.data.brandName = this.data.brandName;
    req_Data.data["basicPricing"] = parseFloat(this.brandPackingForm.controls.basicPricing.value);
    // req_Data.data["retailPricings"] = parseFloat(this.brandPackingForm.controls.retailPricings.value);
    req_Data.data["importFee"] = parseFloat(this.brandPackingForm.controls.importFee.value);
    req_Data.data["exportFee"] = parseFloat(this.brandPackingForm.controls.exportFee.value);
    req_Data.data["exciseDuty"] = parseFloat(this.brandPackingForm.controls.exciseDuty.value);
    req_Data.data["customDuty"] = parseFloat(this.brandPackingForm.controls.customDuty.value);
    // req_Data.data["basicPricing"] = parseFloat((this.brandPackingForm.controls.basicPricing.value.substring(1).replace(/,/g, '')));
    // req_Data.data["retailPricings"] = parseFloat((this.brandPackingForm.controls.retailPricings.value.substring(1).replace(/,/g, '')));
    // req_Data.data["importFee"] = parseFloat((this.brandPackingForm.controls.importFee.value.substring(1).replace(/,/g, '')));
    // req_Data.data["exportFee"] = parseFloat((this.brandPackingForm.controls.exportFee.value.substring(1).replace(/,/g, '')));
    // req_Data.data["exciseDuty"] = parseFloat((this.brandPackingForm.controls.exciseDuty.value.substring(1).replace(/,/g, '')));
    // req_Data.data["customDuty"] = parseFloat((this.brandPackingForm.controls.customDuty.value.substring(1).replace(/,/g, '')));
    this.saveDisabled = true;
    this.loader = true;
    this.productsService.editBrandPacking(req_Data).subscribe((res) => {
      this.loader = false;
      this.dialogRef.close("Brand packing added successfully");
    });
  }
  close() {
    this.dialogRef.close();
  }


  getCurrency(value:any, type:any) {
    if (type === "basicPricing"){
      if(!this.brandPackingForm.controls.basicPricing.invalid){
        this.brandPackingForm.patchValue({
          [type]: this.util.formatCurrency(value)
        })
      }
    }else{
      this.brandPackingForm.patchValue({
        [type]: this.util.formatCurrency(value)
      })
    }
  }
  getSegments(): any {
    const qData = {
      data: 'get_all_segments'
    };
    // tslint:disable-next-line:variable-name
    const req_Data = {
      action: 'common_apis',
      q: JSON.stringify(qData)
    };
    this.commonsService.getCommonEntites(req_Data).subscribe(res => {
      if (res && res.get_all_segments) {
        this.segmentDatas = res.get_all_segments;
        if (this.data && this.data.length) {
          this.filterLiquorType();
          if (this.brandPackingForm.controls.liquorType.value) {
            this.setFee(this.brandPackingForm.controls.liquorType.value);
          }
        }
      }
    });
  }
  changeLiquorType(value): any {
    this.brandPackingForm.patchValue({
      packType: '',
      size: '',
    });
    if (value === 'BEER') {
      this.filterPackSizeForBeer();
    } else {
      this.packTypeList = this.packTypeListRootDatas;
      this.sizeList = this.sizeListRootDatas;
    }
    this.setFee(value);
  }
  setFee(value): any {
    const segmentData = this.segmentDatas.filter(res => res.segment === value);
    if (segmentData.length) {
      this.brandPackingForm.patchValue({
        importFee: segmentData[0].importFee,
        exportFee: segmentData[0].exportFee,
        exciseDuty: segmentData[0].exciseDuty,
      });
    }
  }
  filterLiquorType(): any {
    this.segmentDatas = this.segmentDatas.filter(el => {
      if (this.data && this.data.length && this.data[0].brandType === 'BEER' && el.segment === 'BEER') {
        return el.segment;
      } else if (this.data && this.data.length && this.data[0].brandType !== 'BEER' && el.segment !== 'BEER') {
        return el.segment;
      }
    });
    this.brandPackingForm.patchValue({
      liquorType: this.segmentDatas.length === 1 ? this.segmentDatas[0].segment : '',
    });
    if (this.brandPackingForm.controls.liquorType.value) {
      this.setFee(this.brandPackingForm.controls.liquorType.value);
    }
    if (this.data[0].brandType === 'BEER') {
      this.filterPackSizeForBeer();
    }
  }
  async filterPackSizeForBeer(): Promise<any> {
    this.packTypeList = await this.packTypeList.filter(el => el === 'G' || el === 'C');
    this.sizeList = await this.sizeList.filter(el => {
      const size: any = el && el.split('-');
      if (size.length && (size[0] === 'BS' || size[0] === 'UP' || size[0] === 'AP')) {
        return el;
      }
    });
    this.brandPackingForm.patchValue({
      packType: this.packTypeList.length === 1 ? this.packTypeList[0] : '',
      size: this.sizeList && this.sizeList.length === 1 ? this.sizeList[0] : '',
    });
  }

}

