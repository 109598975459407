import { NgxSpinnerService } from 'ngx-spinner';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { arrayMax } from 'highcharts';
import * as moment from 'moment';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
declare const JsBarcode: any;
@Component({
  templateUrl: './supplier-gatepass-info-dialog.component.html',
  styleUrls: ['./supplier-gatepass-info-dialog.component.scss']
})
export class SupplierGatepassInfoDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'no',
    'importPermitNumber',
    'supplier',
    'transporter',
    'vechicleNumber',
    'received',
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  selectedOfsForPrint: any;
  roleName: any;
  depotName:any;
  address:any;

  constructor(public dialogRef: MatDialogRef<SupplierGatepassInfoDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data, private shipmentService: ShipmentsService,
    public utilService: UtilService,
    private spinner: NgxSpinnerService) { 
      this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
      this.depotName =JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).entity_id;
      this.address = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).depot_address;

    }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource([this.data]);
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

  generateGatePass(){
    this.spinner.show();
    let payload;
    if(this.data.consignmentType === 'Import Permit'){
      payload = {
        "action": "generate_gatepass",
        data: {
          ipNo:this.data.ipNo
        }
      }
    }else{
      payload = {
        "action": "generate_gatepass",
        data: {
          tpNo:this.data.tpNo
        }
      }
    }
    if (this.data.btpNo) {
      delete payload.data.tpNo;
      payload.data.btpNo = this.data.btpNo;
    } else if (this.data.bipNo) {
      delete payload.data.tpNo;
      payload.data.bipNo = this.data.bipNo;
    }
    this.shipmentService.generateActions(payload).subscribe(result => {
      this.dialogRef.close('Generated successfully');
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    })
  }
  
  convertToDate(date) {
    return date ? moment(date).format('DD/MM/YY, h:mm a') : '-';
  }
  convertToDateOnly(date) {
    return moment(date).format('DD/MM/YY');
  }
  ngAfterViewInit(): any {
    if (this.data.chkNO) {
      JsBarcode('#barcode-gatepass', this.data.slNo,
        {
          width: 1,
          height: 40
        }
      );
    }
  }
  print(){
    setTimeout(() => {
      this.getPrint();
    }, 100);
  }
  getPrint(){
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.write(`${printContents}`);
    popupWin.document.close();
  }
}
