import { EventEmitter, Inject, Input, OnChanges, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TpRevalidationService } from 'src/app/core/services/tp-revalidation.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-tp-details-dialog',
  templateUrl: './tp-details-dialog.component.html',
  styleUrls: ['./tp-details-dialog.component.scss']
})
export class TpDetailsDialogComponent implements OnInit, OnChanges {

  @Input() featuresData: any;
  @Output() refreshPage = new EventEmitter<boolean>();
  todayDate: Date;
  tpRevalidationForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  offset: number;
  search: string;
  tpRevalidation: any;
  saveDisabled: boolean;
  validityDate: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  displayedColumns: string[] = ['ofs', 'productCode', 'productName', 'orderQty', 'allocatedQty', 'allocatedBottles'];
  productData:any=[];
  roleName:any;
  reason:any;

  constructor(private fb: FormBuilder,
    private tpRevalidationService: TpRevalidationService,
    private dialogRef: MatDialogRef<TpDetailsDialogComponent>,
    public util:UtilService,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data) { 
      this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    }

  ngOnInit(): void {
    this.todayDate = new Date();
    console.log(this.data, this.data.transporterDetails.vehicleNo);
    this.tpRevalidationForm = this.fb.group({
      transportPermitNo: '',
      vehicleNo: '',
      validPeriod: '',
      transporterName: '',
    });
    if (this.data) {
      this.tpRevalidationForm.patchValue({
        transportPermitNo: this.data.tpNo ?  this.data.tpNo :  this.data.ipNo ?  this.data.ipNo :
        this.data.btpNo ?  this.data.btpNo : this.data.bipNo ? this.data.bipNo : '-',
        vehicleNo: this.data.transporterDetails[this.data.transporterDetails.length - 1].vehicleNo,
        validPeriod: this.util.convertToIST(this.data.validityDateTime),
        transporterName: this.data.transporterDetails[this.data.transporterDetails.length - 1].transporterName,
      })
      this.data.consignment.forEach((consData:any)=>{
        consData.productDetails.map((prod:any)=>{
          prod.indentCode = consData.ofidtNo ? consData.ofidtNo : consData.ofsCode ? consData.ofsCode :
          consData.exportIndentNumber ? consData.exportIndentNumber : '-'
          this.productData.push(prod)
        })
      })
      this.dataSource = new MatTableDataSource(this.productData);
    }
  }

  ngOnChanges(): void {
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

  revalidatePermit(){
    const permitPayload = {
      action: 'tp_revalidate',
      data: {
        ...this.data,
        validityDateTimeNew: this.validityDate
      }
    };
    if(this.data.consignmentType === 'Export' || this.data.consignmentType === 'Import Permit'){
      permitPayload.data.reason = this.reason
    }
    console.log(permitPayload)

    this.tpRevalidationService.revalidatePermit(permitPayload).subscribe((res:any) => {
      if(res.status === 'SUCCESS'){
        this.dialogRef.close('Permit Re-Validated Successfully');
        this.refreshPage.emit();
      }else{
        this.messageService.add({ severity: 'error', detail: 'Permit Re-Validation failed' });
      }
      
    });
  }

}
