import { NgxSpinnerService } from 'ngx-spinner';
import { Component, Inject, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { unitsPerCases } from 'src/app/core/constants/units-per-case.constatnts';
import { MessageService } from 'primeng/api';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
declare const JsBarcode: any;

@Component({
  templateUrl: './supplier-checklist-info-dialog.component.html',
  styleUrls: ['./supplier-checklist-info-dialog.component.scss']
})
export class SupplierChecklistInfoDialogComponent implements OnInit, AfterViewInit {
  valid: any;
  ofsData: any [];
  caseCode: any;
  totalDatas: any = {};
  totalDatashipped:any=0;
  receivedQtyFull:any=0;
  receivedQtyPart:any=0; 
  breakageBtl:any=0;
  displayedColumns: string[] = [
    'no',
    'productCode',
    'brandName',
    'batchId',
    'sizeCode',
    'shippedCases',
    'recievedCases',
    'recievedBottles',
    'damagedCases',
    'damagedBottles',
    'shortageCases',
    'shortageBottles' 
  ];
  user: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  productData: any = [];
  address:any;
  filteredProductDetails: any[];
  @Input() evcData: any;
  roleName: any;
  // @ViewChild('icdcCode') icdcCode: any;
  caseCodeType: any = "";
  caseTypes: any = ['Manual', 'Automatic'];
  unitsPerCases= unitsPerCases;
  caseCodeCount: any;
  showSubmit: boolean = true;
  depotName:any;
  userDetails: any = [];
  distilleryName: any = [];
  distilleryAddress: any = [];
  selectedOfs: any;
  checklistDatas: any = {};

    constructor(public dialogRef: MatDialogRef<SupplierChecklistInfoDialogComponent>,
       private shipmentService: ShipmentsService , @Inject(MAT_DIALOG_DATA)public data,
        private messageService: MessageService,
        public utilService: UtilService,
        private spinner: NgxSpinnerService) {
          this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    this.depotName =JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).entity_id;
    this.address = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).depot_address;

         }

  ngOnInit(): void {
    this.resetCheckListPrintForms();
    console.log(this.data,"33::::")
    this.productData = [];
    this.ofsData = this.data['consignment'];
    // this.ofsData = this.data['productDetails'];
    this.user = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
    console.log(this.data);
    this.totalValue();
    if (this.userDetails.distillery_unit && this.userDetails.distillery_address) {
      this.distilleryName = this.utilService.formatString(this.userDetails.distillery_unit);
      this.distilleryAddress = this.userDetails.distillery_address[0];
    }
    /* this.data['consignment'].forEach(ofs => {
      if(ofs.productDetails) {
        if(this.productData.length >0) {
          ofs.productDetails.forEach( product => {

            this.productData.push(product);
          })
        }
        else {
          this.productData = ofs.productDetails;
        }
      }
    })
    const arr = this.productData;

    this.filteredProductDetails = Array.from(new Set(this.productData.map(a => a.productCode)))
    .map(productCode => {
      return this.productData.find(a => a.productCode === productCode)
    })

    this.filteredProductDetails.forEach(item => {
      let count = 0;
      this.productData.forEach( product => {
      
      
      if(item.productCode == product.productCode) {
        count = count + (product.casesCount? Number(product.casesCount): Number(product.caseCount))
      console.log(item.productCode, item.casesCount,product.productCode, product.casesCount);
      
      }
      
      })
      
      item.casesCount = count;
      }) */

    console.log(this.ofsData)

    this.dataSource = new MatTableDataSource(this.filteredProductDetails);
  }
  totalValue(){
    // this.totalDatas.approvedQty = 0;
    // this.totalDatas.shipmentQty = 0;
    // this.totalDatas.balance = 0;
    this.totalDatas = [];
    this.ofsData.forEach((ofs: any) => {
      this.totalDatas[ofs.ofsCode] = {};
      this.totalDatas[ofs.ofsCode].approvedQty = 0;
      this.totalDatas[ofs.ofsCode].shipmentQty = 0;
      this.totalDatas[ofs.ofsCode].balance = 0;
      ofs.productDetails.forEach((item: any) => {
        this.totalDatas[ofs.ofsCode].approvedQty += parseInt(item.approvedQty, 10);
        if(this.data.consignmentType == "Import Permit"){
          this.totalDatas[ofs.ofsCode].shipmentQty += parseInt(item.approvedQty);
          this.totalDatas[ofs.ofsCode].balance += parseInt(item.approvedQty, 10) - parseInt(item.approvedQty, 10);
        }else{
          this.totalDatas[ofs.ofsCode].shipmentQty += parseInt(item.shipmentQty);
          this.totalDatas[ofs.ofsCode].balance += parseInt(item.approvedQty, 10) - parseInt(item.shipmentQty, 10);

        }
      });
    });
    this.ofsData.forEach((ofsItem: any) => {
      const valueArr = ofsItem.productDetails.map(item => item.productCode);
      const dupProductIndexes = this.utilService.getDuplicates(valueArr);
      if (dupProductIndexes.size > 0) {
        let damageBottles: any = 0;
        ofsItem.productDetails.forEach(el => {
          if ((el.approvedQty === el.casesCount) && (el.breakages && el.breakages.bottles)) {
            damageBottles += el.breakages.bottles;
          }
        });
        dupProductIndexes.forEach((obj) => {
          Object.keys(obj).forEach(key => {
            if (ofsItem.productDetails[obj[key]].approvedQty === ofsItem.productDetails[obj[key]].casesCount &&
              ofsItem.productDetails[obj[key]].breakages && ofsItem.productDetails[obj[key]].breakages.bottles) {
              ofsItem.productDetails[obj[key]].breakages.bottles = 0;
              ofsItem.productDetails[obj[key]].scannedBottles = 0;
            }
            if (ofsItem.productDetails[obj[key]].approvedQty !== ofsItem.productDetails[obj[key]].casesCount &&
              ofsItem.productDetails[obj[key]].breakages && ofsItem.productDetails[obj[key]].breakages.bottles) {
              ofsItem.productDetails[obj[key]].scannedBottles = ofsItem.productDetails[obj[key]].scannedBottles - damageBottles;
              ofsItem.productDetails[obj[key]].breakages.bottles = ofsItem.productDetails[obj[key]].breakages.bottles + damageBottles;
            }
          });
        });
      }
    });
  }
  ngAfterViewInit(): any {
    if (this.data.chkNO) {
      JsBarcode('#barcode-chkNO', this.data.chkNO,
        {
          width: 1,
          height: 40
        }
      );
    }
    JsBarcode('#barcode-bar-icdc', 'ICDC086160522001379',
      {
        width: 1,
        height: 40
      }
    );
    JsBarcode('#barcode-test', this.data.chkNO,
      {
        width: 1,
        height: 40
      }
    );
  }

  updateAction(){
    this.spinner.show();
    let payload;
    if(this.data.consignmentType === 'Import Permit'){
       payload = {
        "action": this.data.actionLink === 'Check List'? "generate_checklist" : "receive_tp",
        data: {
          ipNo: this.data.ipNo
        }
      }
    }else{
      payload = {
        "action": this.data.actionLink === 'Check List'? "generate_checklist" : "receive_tp",
        data: {
          tpNo: this.data.consignmentType === 'Import Permit' ? this.data.ipNo : this.data.tpNo
        }
      }
    }
    if (this.data.btpNo) {
      delete payload.data.tpNo;
      payload.data.btpNo = this.data.btpNo;
    } else if (this.data.bipNo) {
      delete payload.data.tpNo;
      payload.data.bipNo = this.data.bipNo;
    }
    this.shipmentService.generateActions(payload).subscribe(result => {
     this.dialogRef.close('Action Generated successfully');
     this.spinner.hide();
    }, err => {
      this.spinner.hide();
    })
  }

  getRecievedCases(row) {
    let cases = 0;
 
    return !this.showSubmit ? row.casesCount : '' ;
  } 
  dialogClose(): void{
    this.dialogRef.close();
  }
  print(item){
    this.selectedOfs = item;
    // this.checklistDatas = item;
    const datas: any = [];
    this.resetCheckListPrintForms();
    this.checklistDatas.ofsCode2 = item.ofsCode;
    item.productDetails.forEach(element => {
      this.checklistDatas.shipmentQty2 += element.shipmentQty;
      element.shipmentQty2 = element.shipmentQty;
      element.approvedQty2 = element.approvedQty;
      const isExist = datas.find(el => el.productCode === element.productCode);
      if (isExist) {
        isExist.shipmentQty2 += element.shipmentQty ? element.shipmentQty : 0;
        return;
      }
      this.checklistDatas.approvedQty2 += element.approvedQty;
      datas.push(element);
    });;
    this.checklistDatas.productDetails2 = datas;
    console.log('this.checklistDatas', this.checklistDatas);
    console.log('this.selectedOfs', this.selectedOfs);
    setTimeout(() => {
      let printContents, popupWin;
      printContents = document.getElementById('print-checklist').innerHTML;
      // printContents = document.getElementById("print-checklist").innerHTML;
      popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
      popupWin.document.write(`${printContents}`);
      popupWin.document.close();
    }, 500);
    
  }
  resetCheckListPrintForms(): any {
    this.checklistDatas = {
      ofsCode2: '',
      approvedQty2: 0,
      shipmentQty2: 0,
      productDetails2: [],
    }
  }
}
