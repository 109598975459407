import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, Subject } from 'rxjs';
import { CommonsService } from 'src/app/core/services/commons.service';
import { DistilleryInfoService } from 'src/app/core/services/distillery-info.service';
import { UserManualService } from 'src/app/core/services/user-manual.service';
import { ConfirmPasswordValidator } from 'src/app/core/utils/confirm-password.validator';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-manage-staff-users-dialog',
  templateUrl: './manage-staff-users-dialog.component.html',
  styleUrls: ['./manage-staff-users-dialog.component.scss']
})
export class ManageStaffUsersDialogComponent implements OnInit {

  InformationForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  offset: number;
  titles = ['Mr.', 'Mrs.', 'Ms.'];
  locationTypes = ['Supplier', 'Distillery', 'Breweries', 'Non Local Breweries', 'Company'];
  isPassword: boolean = true;
  isConfirmPassword: boolean = true;
  roles: any;
  userDetails: any;
  filteredRoles: any;
  suppliers = [];
  filteredSuppliers: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  protected _onDestroy = new Subject<void>();
  public supplierFilterCtrl: FormControl = new FormControl();
  getSuppliersResp: any;
  enableLineName = false;
  lineNames: any;
  constructor(private messageService: MessageService, private fb: FormBuilder, public util: UtilService, private dialogRef: MatDialogRef<ManageStaffUsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private distilleryInfoService: DistilleryInfoService, private userManualService: UserManualService, private CommonsService: CommonsService) {
    this.filteredSuppliers.next([]);
    this.userDetails = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));

  }

  ngOnInit(): void {
    this.getSuppliers();
    // console.log('this.data', this.data);
    this.lineNames = this.distilleryInfoService.bottlingLines;    

    this.InformationForm = this.fb.group({
      title: ['', [Validators.required,]],
      fullName: ['', [Validators.required,]],
      emailId: ['', [Validators.required,]],
      userName: ['', [Validators.required,]],
      password: ['', [Validators.required,]],
      confirmPassword: ['', [Validators.required,]],
      locationType: ['', [Validators.required,]],
      location: ['', [Validators.required,]],
      role: ['', [Validators.required,]],
      line: [''],
      mobileNo: [''],
      supplierFilter: ''
    }, {
      validators: this.confirmPasswordmatch('password', 'confirmPassword')
    });

    if (this.data) {
      this.InformationForm.patchValue({
        title: this.data.title,
        fullName: this.data.fullName,
        emailId: this.data.emailId,
        userName: this.data.userName,
        password: this.data.password,
        confirmPassword: this.data.confirmPassword,
        locationType: this.data.locationType,
        location: this.data.supplier.supplierCode,
        role: this.data.role,
        mobileNo: this.data.mobileNo
      });
    }
    this.InformationForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });

    this.util.matSelectFilter(this, "supplierFilterCtrl", "filteredSuppliers", "suppliers");

  }

  confirmPasswordmatch(controlName: string, matchingControlName: string) {
    // password matching
    return (fromGroup: FormGroup) => {
      const control = fromGroup.controls[controlName];
      const matchingControl = fromGroup.controls[matchingControlName];
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmPasswordmatch: true });
      }
      else {
        matchingControl.setErrors(null);
      }
    }
  }

  getSuppliers() {
    let paramObj = {
      action: 'common_apis',
      q: '{"data": ["get_suppliers_roles"]}'
    }

    this.CommonsService.getCommonEntites(paramObj).subscribe((res) => {
      this.getSuppliersResp = res;
      this.roles = res['get_suppliers_roles']['ROLES'];
      this.filteredRoles = JSON.parse(JSON.stringify(this.roles));

      this.suppliers = res['get_suppliers_roles']['SUPPLIERS'].map((supplier) => supplier.supplierCode);
      if(this.userDetails.role === "Distillery Manager"){
        this.filteredSuppliers.next([this.userDetails.entity_id]);
        this.InformationForm.get('location').setValue(this.userDetails.entity_id);
        this.onLocationChange(this.userDetails.entity_id);
      }else{
        this.filteredSuppliers.next(this.suppliers);
      }
    });
  }



  createSupplier() {
    this.InformationForm.patchValue({
      createdOn: new Date().toISOString()
    });
    this.saveDisabled = true;
    const plObj = this.InformationForm.value;
    const req_Data = {
      action: 'add_supplier_user',
      data: {
        ...plObj,
        number: plObj.mobileNo
      }
    }
    req_Data.data['activeUsers'] = this.util.encryptPassword(req_Data.data.password);
    delete req_Data.data.confirmPassword;
    delete req_Data.data.password;
    delete req_Data.data.mobileNo;

    if(this.enableLineName) {
      req_Data.data.lineName = this.InformationForm.controls['line'].value
    }

    console.log(req_Data);
    

    this.distilleryInfoService.createUser(req_Data).subscribe((res: any) => {
      if(res.status != 'SUCCESS') {
        this.messageService.add({severity:'error', summary: 'Error', detail: res.status});
      }

      else {
        this.dialogRef.close('Supplier Created Successfully');
      }
    // }, (err) => {
    //   this.error = err.error.message;
    //   this.messageService.add({severity:'error', summary: 'Error', detail: 'Supplier can\'t be created Successfully'});
    });
  }

  showPassword() {
    this.isPassword = !this.isPassword;
  }

  onLocationChange(value) {
    const [role] = this.getSuppliersResp['get_suppliers_roles']['SUPPLIERS'].filter(item => item.supplierCode === value);
    this.InformationForm.get('locationType').setValue(role.locationType);
    this.filteredRoles = this.roles;

    if (role && role.locationType === 'Supplier') {
     this.filteredRoles = [{roleName: 'Distillery Manager'} , {roleName:'Production Manager'}, {roleName:'Supplier Line Manager'}]
    }
  }
  showConfirmPassword() {
    this.isConfirmPassword = !this.isConfirmPassword;
  }
  dialogClose(): void {
    this.dialogRef.close();
  }

  roleChange(event) {
    if(event == 'Supplier Line Manager') {
      this.enableLineName = true;
    } else {
      this.enableLineName = false;
    }
    
  }

}
