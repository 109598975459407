import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { HologramsService } from 'src/app/core/services/holograms.service';

@Component({
  selector: 'app-heal-purchase-indent-status-details-dialog',
  templateUrl: './heal-purchase-indent-status-details-dialog.component.html',
  styleUrls: ['./heal-purchase-indent-status-details-dialog.component.scss']
})
export class HealPurchaseIndentStatusDetailsDialogComponent implements OnInit {
  error: any;
  roleName: string;
  addCartonsSpooolsForm: FormGroup;
  cartons:FormArray;
  spoolsQty: number = 0;
  indentNo: any;

  constructor(private dialogRef: MatDialogRef<HealPurchaseIndentStatusDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)public data, private hologramsService: HologramsService,
    private fb: FormBuilder,
    private messageService: MessageService) {
      this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    }

  ngOnInit(): void {
  // this.dataSource = new MatTableDataSource(this.data);
    console.log(this.data);
    // this.addCartonsSpooolsForm = this.fb.group({
    //   cartons: this.fb.array([this.createBarCode()]),
    // });
    // this.cartons = this.addCartonsSpooolsForm.get('cartons') as FormArray;

  }

  createBarCode() {
    return this.fb.group({
      cartonBarcode: '',
      spools:'',
      qty: 0,
      spoolsLength: 0
    });
  }

  // addBarCode() {
  //   (this.addCartonsSpooolsForm.controls['cartons'] as FormArray).push(this.createBarCode())
  // }

  updateStatus(status) {
    const req_Data: any = {
      action : 'update_heal_purchase_indent',
      data : {status, healIndentId: this.data.healIndentId, entityId: this.data.entityId}
    }
    if(this.data.status === 'Dispatched'){
      req_Data.data.indentNo =  this.indentNo;
      req_Data.data.cartons = this.data.cartons
      req_Data.data.spools = this.data.spools
    }
    if(this.data.entityType === "Depot"){
      req_Data.data['purpose'] =  this.data.purpose;
     // req_Data['cartons'] = this.addCartonsSpooolsForm.value.cartons
    }
    this.hologramsService.updateHealPurchaseIndents(req_Data).subscribe((res: any) => {
      if(res.error){
        this.messageService.add({severity: 'error', summary: '', detail: res.error});
      }else{
        this.dialogRef.close('Heal Purchase Indent updated Successfully');
      }
    }, (err) => {
      this.error = err.error.message;
    });
  }

  // onValidateCartons(e, i) {
  //   console.log('e', e)
  //   const qdata={
  //     cartonBarcode:e.target.value,
  //     healIndentId: this.data.healIndentId
  //   }
  //   this.hologramsService.getHpiDetails(JSON.stringify(qdata),'validate_carton').subscribe((res: any) => {
  //     console.log('res', res);
  //     (<FormArray>this.addCartonsSpooolsForm.get('cartons')).at(i).patchValue({cartonBarcode: e.target.value,
  //       spools: res.spoolBarcodes, spoolsLength: res.spoolBarcodes.length, qty: res.spoolBarcodes.length * 20000});
  //     // this.spoolsQty += res.spoolsBarcodes.length * 20000;
  //   })
  // }

  // checkQty() {
  //   if(this.cartons.value.length > 0) {
  //     this.spoolsQty = 0;
  //     for(let carton of this.cartons.value) {
  //       this.spoolsQty += carton.qty;
  //     }
  //     return !(this.spoolsQty == this.data.approvedQuantity);
  //   } else {
  //     return true;
  //   }
  // }
}
