import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
// import { spiritTypes } from 'src/app/core/constants/liquor-type.constants';
import { BrandRegistrationService } from 'src/app/core/services/brand-registration.service';
import { CommonsService } from 'src/app/core/services/commons.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-manage-segment-registration-dialog',
  templateUrl: './manage-segment-registration-dialog.component.html',
  styleUrls: ['./manage-segment-registration-dialog.component.scss']
})
export class ManageSegmentRegistrationDialogComponent implements OnInit {
  addForm: FormGroup;
  segmentDatas: any = [];
  loader: any = false;

  // liquorTypeList: any = [];
  // segmentList: any = [];
  // liquorData: any = {};
  // brandsData: any
  // spiritTypeList: any = Object.keys(spiritTypes).map(res => spiritTypes[res]);
  // selectedSpiritTypes: any;
  // brandCodeDuplicate = false;
  // brandNameDuplicate = false;
  // strength: number;
  // brandTypes: any = ['BEER', 'IML'];

  constructor(
    private fb: FormBuilder, private messageService: MessageService,
    private commonsService: CommonsService,
    public util: UtilService,
    private spinner: NgxSpinnerService,
    private brService: BrandRegistrationService,
    private dialogRef: MatDialogRef<ManageSegmentRegistrationDialogComponent>) { }

  ngOnInit(): void {
    // this.spinner.show();
    this.addForm = this.fb.group({
      segment: ['', Validators.required],
      importFee: ['', Validators.required],
      exportFee: ['', Validators.required],
      exciseDuty: ['', Validators.required],
    });
    this.getSegments();

    // const req_Data = {
    //   "action": "common_apis",
    //   "q": '{"data": ["get_liquor_types"]}'
    // }
    // this.commonsService.getCommonEntites(req_Data).subscribe(res => {
    //   this.liquorData = res["get_liquor_types"];
    //   this.liquorTypeList = Object.keys(res["get_liquor_types"]);
    //   this.segmentList = Object.keys(res["get_liquor_types"]).map(val => res["get_liquor_types"][val]);
    // })

    // this.brService.getBrandRegistrationFeatures().subscribe(res => {
    //   this.spinner.hide();
    //   this.brandsData = res['Brand Registration'];
    //   this.brandsData.forEach(brand => {
    //     brand.brandNumber = brand.brandCode.split('-')[0];
    //   })
    //   console.log('lllll:::::', this.brandsData);

    // })

  }

  // checkDuplicate(fieldName: string) {
  //   if (fieldName == 'code') {
  //     this.brandCodeDuplicate = this.brandsData.some(brand => brand.brandNumber === this.addForm.get('brandCode').value);
  //   } else {
  //     this.brandNameDuplicate = this.brandsData.some(brand => brand.brandNumber === this.addForm.get('brandName').value);
  //   }
  // }

  create(): any {
    // tslint:disable-next-line:variable-name
    const req_Data = {
      action: 'add_segment',
      data: this.addForm.value
    };
    this.loader=true;
    this.brService.creteBrand(req_Data).subscribe((res: any) => {
      if (res.status !== 'SUCCESS') {
        this.messageService.add({ severity: 'error', summary: 'Error Message', detail: res['status'] });
        this.loader=false;
      }
      else {
        this.dialogRef.close('Segment Created Successfully');
        this.loader=false;
      }
    });
  }
  getSegments(): any {
    const qData = {
      data: 'get_all_segments'
    };
    // tslint:disable-next-line:variable-name
    const req_Data = {
      action: 'common_apis',
      q: JSON.stringify(qData)
    };
    this.commonsService.getCommonEntites(req_Data).subscribe(res => {
      if (res && res.get_all_segments) {
        this.segmentDatas = res.get_all_segments;
      }
    });
  }
  isSegmentExists(): any {
    const isExists = this.segmentDatas.find((res: any) => res.segment.toLowerCase() === this.addForm.controls.segment.value.toLowerCase());
    console.log('isExists', isExists);
    if (isExists) {
      this.messageService.add({ severity: 'error', detail: 'Segment already exists...'});
      this.addForm.controls.segment.setValue('');
    }
  }
}
