import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TD1 } from 'src/app/core/models/licensee-profiles/td1.model';
import { LicenseeProfilesService } from 'src/app/core/services/licensee-profiles.service';
import { OpenClubsBankDetailsDialogComponent } from '../../clubs/open-clubs-bank-details-dialog/open-clubs-bank-details-dialog.component';

@Component({
  selector: 'app-open-td1-bank-details-dialog',
  templateUrl: './open-td1-bank-details-dialog.component.html',
  styleUrls: ['./open-td1-bank-details-dialog.component.scss']
})
export class OpenTd1BankDetailsDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'no',
    'bankName',
    'accountHolderName',
    'accountNumber',
    'ifscCode',
    'branch',
    'town'
  ];
  dataSource: MatTableDataSource<TD1> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;

  constructor(public dialogRef: MatDialogRef<OpenClubsBankDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,
    private licenseeProfilesService: LicenseeProfilesService) { }

  ngOnInit(): void {
    this.getStockDetails();
  }

  getStockDetails() {
    this.licenseeProfilesService.getShopsDetails(this.offset, this.limit, this.search).subscribe((res: any) => {
      console.log('res', res);
      this.dataSource = new MatTableDataSource(res.data);
    });
  }

}

