import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Product } from '../models/production/product.model';
import { ProductionLine } from '../models/production/production-line.model';
import { Batch } from '../models/production/batch.model';
import { CaseReprint } from '../models/production/case-reprint.model';
const baseUrl = environment.baseUrl;
const productionUrl = baseUrl + 'production';
const productsUrl = baseUrl + 'production/products';
const productionlineUrl = baseUrl + 'productionlines';
const batchUrl = baseUrl + 'production';
const productionStartUrl = baseUrl + 'production';
const caseReprintUrl = baseUrl + 'stock/stockinventory/cases';
const brandsUrl = baseUrl + 'production/products/brands';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Production'] && userModules.mod_feats['Production'].features ? userModules.mod_feats['Production'].features.map(feat => feat.feature_name) : [];


@Injectable({
  providedIn: 'root'
})
export class ProductionService {
  public products = new BehaviorSubject([]);
  public productData = this.products.asObservable();
  productionLineData:any;
  constructor(private http: HttpClient) { }

  createProduct(productobj: any){
    return this.http.post(productsUrl, productobj);
  }

  getStartProduction(id):Observable<any> {
    const paramObj: any = '?action=get_prod_details&q={"productionId":"'+id+'"}'
    return this.http.get(productionUrl+paramObj);
  }
  getProduct(offset, limit, search?: string):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = '';
    return this.http.get(productsUrl, {params: paramObj});
  }

  getBrands(offset,limit,search?: string): Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = '';
    return this.http.get(brandsUrl, {params: paramObj});
  }


  getproductionLines(offset, limit, search?: string): Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);

    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    return this.http.get(productionlineUrl, {params: paramObj});
  }

  setProductData(data) {
    this.products.next(data)
  }

  getProductData() {
    return this.productData;
  }
  putproductionLines(productionLinesObj){
    return this.http.put<ProductionLine>(`${productionlineUrl}`, productionLinesObj);
  }

  createBatchProduction(batchObj: any){
    if(batchObj.action == 'initiate_prod') {
      return this.http.post(batchUrl, batchObj)
    }
    else {
      return this.http.put(batchUrl, batchObj)

    }
  }

  createBatch(batchObj: any){
      return this.http.post(batchUrl, batchObj)
  }
  
  getBatches(offset, limit, search?: string): Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    return this.http.get(batchUrl, {params: paramObj});
  }

  putBatch(batchObj){
    return this.http.put<any>(`${productionUrl}`, batchObj);
  }

  createCaseReprint(batchObj: CaseReprint){
    return this.http.post(caseReprintUrl, batchObj);
  }

  getCaseReprints(search?: string): Observable<any>{
    const paramObj: any = {};
    search ? paramObj.search = search : search = null;
    return this.http.get(caseReprintUrl, {params: paramObj});
  }

  putCaseReprint(batchObj){
    return this.http.put<CaseReprint>(`${caseReprintUrl}`, batchObj);
  }

  getProductionLineDetails(offset, limit, search) {
    return this.http.get(productionlineUrl);
  }

  getProductsDetails(offset, limit, search) {
    return this.http.get('assets/samples/products.json');
  }

  getProductionStartDetails(offset, limit, search) {
    return this.http.get(productionStartUrl);
  }

  getProductionFeatures() {
    const headerDict = {
      feature
    };
    return this.http.get(productionUrl, { headers: headerDict });
  }

  getProductsByDepot(productsObj: any) {
    const headerDict = {
      feature
    };
    return this.http.get(productionUrl, { params: productsObj, headers: headerDict });
  }

  getRePrintCase() {
    const headerDict = {
      feature,
      action:'reprint_case'
    };
    return this.http.get(productionUrl, { headers: headerDict });
  }

  putProduction(productionLinesobj: any){
    return this.http.put<any>(productionUrl, productionLinesobj);
  }

  createproduction(productionLinesobj: any){
    return this.http.post(productionUrl, productionLinesobj);
  }

  scanProduction(scanObj) {
    return this.http.post(productionUrl, scanObj);
  }
}
