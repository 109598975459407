import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Local } from 'src/app/core/models/stock/local.model';
import { Get_Local } from 'src/app/core/models/stock/local.model';
import { StockService } from 'src/app/core/services/stock.service';

@Component({
  selector: 'app-case-bar-code-details-dialog',
  templateUrl: './case-bar-code-details-dialog.component.html',
  styleUrls: ['./case-bar-code-details-dialog.component.scss']
})
export class CaseBarCodeDetailsDialogComponent implements OnInit {

  displayedColumns: string[] = ['no', 'healCode', 'brandName', 'caseBarCode'];
  dataSource: MatTableDataSource<Local> = new MatTableDataSource([]);

  constructor(public dialogRef: MatDialogRef<CaseBarCodeDetailsDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private stockService: StockService) { 
      this.dataSource = new MatTableDataSource(this.data);
    }

  ngOnInit(): void {
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

}
