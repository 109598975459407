import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonsService } from 'src/app/core/services/commons.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-production-products-dialog',
  templateUrl: './production-products-dialog.component.html',
  styleUrls: ['./production-products-dialog.component.scss']
})
export class ProductionProductsDialogComponent implements OnInit {

  todayDate: Date;
  roleName: any;
  datas: any = [];

  constructor(
    public dialogRef: MatDialogRef<ProductionProductsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private spinner: NgxSpinnerService,
    private commonsService: CommonsService,
    private util: UtilService
  ) { 
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role
  }


  ngOnInit(): void {
    this.todayDate = new Date();
    this.getDatas();
  }

  //Added depot level sales by product
  getDatas(): any {
    this.spinner.show();
    const queryObject = {
      data:{
        action: "get_stocks_by_product", 
        value: this.data.productCode
      }
    };
    const req_Data: any = {
      action: 'common_apis',
      q: JSON.stringify(queryObject)
    };
    this.datas = [];
    this.commonsService.getCommonEntites(req_Data).subscribe((res: any) => {
      if (res && res.get_stocks_by_product) {
        this.datas = res.get_stocks_by_product;
        this.getFormattedDatas();
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }
  async getFormattedDatas(): Promise<any> {
    if (this.datas && this.datas.depotDetails && this.datas.depotDetails.length) {
      this.datas.depotDetails.forEach(item => {
        item.depotName = this.util.formatString(item.depotCode);
        item.l3msCases = 0;
        item.l3msBottles = 0;
        item.pendingOfsCases = 0;
        item.waitingTrucksCases = 0;
        if (!item.closingBalanceCases) {
          item.closingBalanceCases = 0;  
        }
        if (!item.closingBalanceBottles) {
          item.closingBalanceBottles = 0;  
        }
        item.closingBalanceCases += item.cases_closing ? item.cases_closing : 0;
        item.closingBalanceBottles += item.bottles_closing ? item.bottles_closing : 0;
          item.waitingTrucks && item.waitingTrucks.length && item.waitingTrucks.forEach(el => {
            if (el.productDetails && el.productDetails.length) {
              el.productDetails.forEach(prod => {
                if (this.data.productCode !== prod.productCode) {
                  return;
                }
                item.waitingTrucksCases += prod.casesCount ? prod.casesCount : 0;
              });
            }
          });
          item.pendingOfs && item.pendingOfs.length && item.pendingOfs.forEach(el => {            
            if (!el.productDetails || this.data.productCode !== el.productDetails.productCode) {
              return;
            }
            item.pendingOfsCases += el.productDetails.approvedQty ? el.productDetails.approvedQty : 0;
          });
      });
    }
    if (this.datas && this.datas.l3ms && this.datas.l3ms.length) {
      await this.datas.l3ms.forEach(item => {
        const existData = this.datas.depotDetails.find(el => el.depotCode === item.depotCode);
        if (existData) {
          existData.l3msCases += item.dispatchCases ? item.dispatchCases : 0;
          existData.l3msBottles += item.dispatchBottles ? item.dispatchBottles : 0;
          return;
        }
      });
    }
    console.log('c', this.datas);
  }

  dialogClose(): void {
    this.dialogRef.close();
  }
  round(data): any {
    return Math.ceil(data);
  }
  print(): any {
    setTimeout(() => {
      this.getPrint();
    }, 100);
  }
  getPrint(){
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.write(`${printContents}`);
    popupWin.document.close();
  }

}
