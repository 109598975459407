import { FormGroup } from '@angular/forms';
import { InterDepotTransferSupplier } from './../../../../core/models/inter-depot/inter-depot-transfer-supplier.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InterDepotService } from 'src/app/core/services/inter-depot.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
declare const JsBarcode: any;

@Component({
  selector: 'app-inter-depot-trnasfer-suplier-dialog-details',
  templateUrl: './inter-depot-trnasfer-suplier-dialog-details.component.html',
  styleUrls: ['./inter-depot-trnasfer-suplier-dialog-details.component.scss']
})
export class InterDepotTrnasferSuplierDialogDetailsComponent implements OnInit {
  displayedColumns: string[] = ['brandCode', 'brandName', 'size', 'indentQuantity','approvedCases'];
  dataSource: MatTableDataSource<InterDepotTransferSupplier> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;
  addQuantityForm:FormGroup;
  roleName:any;
  distilleryAddress: any = '';
  userDetails: any = [];
  distilleryName: any = [];
  totalDatas:any=[];
  validityDate:any;

  constructor(public dialogRef: MatDialogRef<InterDepotTrnasferSuplierDialogDetailsComponent>, private interDepotService: InterDepotService,@Inject(MAT_DIALOG_DATA) public data,
  public utilService: UtilService) {
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    this.userDetails = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
      
      if (this.userDetails.distillery_unit && this.userDetails.distillery_address) {
        this.distilleryName = this.utilService.formatString(this.userDetails.distillery_unit);
        this.distilleryAddress = this.userDetails.distillery_address[0];
      } else if (this.data.distilleryAddress) {
        this.distilleryAddress = this.data.distilleryAddress[0];
      }
   }

  ngOnInit(): void {
    this.getInterDepotTransferSupplierDetails();

    this.data.productDetails.forEach(element => {
      element['approvedCases'] = element.approvedCases ? element.approvedCases : element.requestedCases;
    });
    console.log(this.data)
    this.getValidityDate();
    
  }

  getInterDepotTransferSupplierDetails() {
      this.dataSource = new MatTableDataSource(this.data.productDetails);
  }
  isDisabled(): any {
    const disableBtn = this.data.productDetails.some(item => (item.requestedCases < item.approvedCases) || item.approvedCases < 1);
    return disableBtn;
  }
  updateStatus(status) {
    if(status == 'Approved'){
      this.data.status = "Approved"
      delete this.data['colName'];
      const req_Data = {
        action:'update_supplier_idt',
        data: this.data
      }
      req_Data.data.productDetails = req_Data.data.productDetails.map(res => {
        res.approvedCases = Number(res.approvedCases);
        return res;
      })
      
      this.interDepotService.updateIDT(req_Data).subscribe((res:any)=>{
        if(res.status == 'SUCCESS'){
          this.dialogRef.close('Indent updated Successfully');
        }
      })

    }
    else{
      this.data.status = "Rejected";
      delete this.data['colName'];
      const req_Data = {
        action:'update_supplier_idt',
        data: this.data
      }
      req_Data.data.productDetails = req_Data.data.productDetails.map(res => {
        res.approvedCases = Number(res.approvedCases);
        return res;
      })
     
      
      this.interDepotService.updateIDT(req_Data).subscribe((res:any)=>{
        if(res.status == 'SUCCESS'){
          this.dialogRef.close('Indent updated Successfully');
        }
       
      })
    }
  }
  ngAfterViewInit(): any {
    if(this.data && this.data?.indentId){
      JsBarcode('#ofidtBarCode', this.data.indentId,
      {
        width: 1,
        height: 40
      })
    }
  }
  print(id,data){
    console.log(data)
    // this.selectedOfsForPrint = ofsItem;
    this.totalDatas = [];
    this.totalDatas.totalCases = 0;
    this.totalDatas.totalBtls = 0;
    data.productDetails.map(row => {
      this.totalDatas.totalCases += row.approvedCases;
      this.totalDatas.totalBtls += row.approvedCases;

    });

    setTimeout(() => {
      this.getPrint();
    }, 100);
  }
  getPrint(){
    let printContents, popupWin;
    printContents = document.getElementById('order-details1').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.write(`${printContents}`);
    popupWin.document.close();
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

  getValidityDate(){
    const currentDate = new Date();
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const daysLeft = lastDayOfMonth.getDate() - currentDate.getDate();
    const approvedDate = this.data.updatedDateTime ? this.data.updatedDateTime : this.data.approvedOn;
    var date = new Date(approvedDate);
    const extendedDate = date.setDate(date.getDate() + 14);
    const validityDate = new Date(extendedDate)
    this.validityDate = daysLeft < 15 ? lastDayOfMonth : validityDate
    console.log(lastDayOfMonth,validityDate,this.validityDate)
  }

}
