import { NgxSpinnerService } from 'ngx-spinner';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from 'primeng/api';
import { Shipments } from 'src/app/core/models/shipments/shipments.model';
import { CommonsService } from 'src/app/core/services/commons.service';
import { InterDepotService } from 'src/app/core/services/inter-depot.service';
import { ShipmentsService } from 'src/app/core/services/shipments.service';

@Component({
  selector: 'app-icdc-packing-list-dialog',
  templateUrl: './icdc-packing-list-dialog.component.html',
  styleUrls: ['./icdc-packing-list-dialog.component.scss']
})
export class IcdcPackingListDialogComponent implements OnInit {
ofsCode = '';
ofsScanCode = '';
@Input() statusData: any;
displayedColumns: string[] = ['ofsNo', 'productCode', 'productName', 'orderQty', 'allocateQty', 'action'];
dataSource: MatTableDataSource<Shipments> = new MatTableDataSource([]);
limit: number;
offset: number;
search: string;
icdcData: any = [];
caseCode: any;
valid: any;
caseCount: any;
isEdit: boolean = false;
isQtyValid: boolean;
cttDetails: any;
ofsChangeData: any;
isDisable: boolean = true;
isScanned: boolean;
ofsIndex: any;
caseDetails: any;

constructor(
  public dialogRef: MatDialogRef<IcdcPackingListDialogComponent>,
  private messageService: MessageService,
  private orderService: InterDepotService,
  private commonsService: CommonsService,
  private shipmentsService: ShipmentsService,
  @Inject(MAT_DIALOG_DATA) public data,
  public utilService: UtilService,
  private spinner: NgxSpinnerService
) { }
ofsAddFields = [];
ngOnInit(): void {
  console.log(this.data, this.statusData, "28::::");
  if (this.statusData) {
    this.data = this.statusData;
  }
  this.getOfsDetails();
  // this.getCttFetails();
  this.ofsAddFields = [
    { ofsCode: '', productCode: '', productName: '', casesCount: '', approvedQty: '' }
  ]
  console.log(this.data)
}

getCttFetails() {
  let data = JSON.stringify({ "data": ["get_ctt"] });

  const req_Data = {
    action: 'common_apis',
    q: data

  }
  this.commonsService.getCommonEntites(req_Data).subscribe(result => {
    this.cttDetails = result['get_ctt'];
  })
}

getOfsDetails() {
  let tpNo = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
  let action;
  if(this.data.consignmentType === 'OFIDT'){
    action = 'get_added_ofidt'
  }else if(this.data.consignmentType === 'ICDC'){
    action = 'get_added_icdc'
  }
  const req_Data = {
    action: 'common_apis',
    q: '{"data": {"action":"' + action + '", "value": "' + tpNo + '"}}'

  }
  this.commonsService.getCommonEntites(req_Data).subscribe(result => {
    console.log(result)
    if(this.data.consignmentType === 'OFIDT'){
      this.icdcData = result['get_added_ofidt'];
    }else if(this.data.consignmentType === 'ICDC'){
      this.icdcData = result['get_added_icdc'];
    }
    if (this.icdcData) {
      this.icdcData.forEach(ofs => {
        ofs.productDetails.forEach(product => {
          this.utilService.getTotalScannedCasesBottles(product);
        });
       });
    }
  })
}

shipmentQuantityChange(item) {
  console.log(item.approvedQty, item.shipmentQty, item.approvedQty < item.shipmentQty, "50:::::")
  return item.approvedQty < item.shipmentQty || item.shipmentQty < item.casesCount;
}

addOfs() {
  let data = {};
  this.data.icdc.forEach(ofs => {
    if (this.ofsCode == ofs.icdcCode) {
      data = ofs;
    }
    data['tpNo'] = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
    console.log(data, this.data, this.ofsCode, "42::::::")
  });
  const req_Data = {
    action: 'add_icdc',
    data
  }
  this.shipmentsService.putOfs(req_Data).subscribe(res => {
    console.log(res, "47:::")
    this.getOfsDetails();
  })
}

shipmentEdit(item) {
  item.isEdit = true;
}

shipmentSave(item, ofs) {
  if (item.approvedQty < item.shipmentQty || item.shipmentQty < item.casesCount) {
    return;
  }
  console.log(item, "82:::::::::")
  const req_Data = {
    action: 'update_shipment_qty',
    data: { "tpNo": this.data.tpNo, "icdc": ofs.icdcCode, "productCode": item.productCode, "shipmentQty": item.shipmentQty }
  }
  this.shipmentsService.saveShipmentQuantity(req_Data).subscribe(result => {

  })
  item.isEdit = false;
}

dataFill(data) {
  let flag = false;
  Object.keys(data).forEach(val => {
    flag = !data[val];
  })
  return flag;
}

deleteOfs(i) {
  this.ofsAddFields.splice(i, 1);
}

caseCodeChange() {
  if(this.caseCode.length === 17){
    const caseReq_Data = {
      action: 'common_apis',
      q: '{"data": {"action": "get_case_by_heal", "value": "' + this.caseCode + '"}}'
    }

    this.commonsService.getCommonEntites(caseReq_Data).subscribe(result => {
      this.caseDetails = result['get_case_by_heal'];
      if(this.caseDetails){
        const isTpAvailable = this.caseDetails.tpNo;
        const matchesCaseDetails = this.ofsChangeData.productDetails.some(product => {
          if (this.caseDetails.productCode.length === 11) {
            this.caseDetails.productCode = this.utilService.convertProductCodeLength11TO12(this.caseDetails.productCode);
          }
          return this.caseDetails.productCode === product.productCode;
        }); 
        const scannedQtyAllowed = this.ofsChangeData.productDetails.some(product => this.caseDetails.productCode === product.productCode && (!product.casesCount || product.approvedCases > product.casesCount)); 
        const caseError = (isTpAvailable || this.caseDetails.location !== "Depot")? 'Case already scanned' : !matchesCaseDetails ? 'Wrong Product Case Scanned' : 'Shipment Quantity Reached';
        if(isTpAvailable || !matchesCaseDetails || !scannedQtyAllowed){
          this.messageService.add({ severity: 'error', summary: 'Error', detail: caseError });
        }else if(matchesCaseDetails && scannedQtyAllowed){
          this.scanBarCode();
        }
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid Case' });
      }
     
    })
  }else{
    if (this.caseCode && this.ofsScanCode) {

      const caseReq_Data = {
        action: 'common_apis',
        q: '{"data": {"action": "get_case_details", "value": "' + this.caseCode + '"}}'
      }
  
      this.commonsService.getCommonEntites(caseReq_Data).subscribe(result => {
        this.caseDetails = result['get_case_details'];
        if(this.caseDetails){
          const now = new Date();;
          const isTpAvailable = this.caseDetails.tpNo;
          const tpDate = this.utilService.getDateOnly(this.caseDetails.icdcTpDateTime);
          const todayDate = this.utilService.getDateOnly(now);
          let isDateMatch = tpDate == todayDate
          console.log(tpDate,todayDate,isDateMatch)
          const matchesCaseDetails = this.ofsChangeData.productDetails.some(product => {
            if (this.caseDetails.productCode.length === 11) {
              this.caseDetails.productCode = this.utilService.convertProductCodeLength11TO12(this.caseDetails.productCode);
            }
            return this.caseDetails.productCode === product.productCode;
          }); 
          const scannedQtyAllowed = this.ofsChangeData.productDetails.some(product => this.caseDetails.productCode === product.productCode && (!product.casesCount || product.approvedCases > product.casesCount)); 
          const isHealNotMatched = this.caseDetails.healCodes.length !== this.caseDetails.unitsPerCase;
          const caseError = ((isDateMatch && isTpAvailable) || this.caseDetails.location !== "Depot")? 'Case already scanned' : !matchesCaseDetails ? 'Wrong Product Case Scanned' : isHealNotMatched ? 'This case has some damaged heals' : 'Shipment Quantity Reached';
          if((isDateMatch && isTpAvailable) || !matchesCaseDetails || !scannedQtyAllowed || isHealNotMatched){
            this.messageService.add({ severity: 'error', summary: 'Error', detail: caseError });
          }else if(matchesCaseDetails && scannedQtyAllowed){
            this.scanBarCode();
          }
          this.caseCode = '';
        }else{
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid Case' });
        }
      })
     
    }
  
  }
  
  if (this.caseCode && this.ofsChangeData.ofidtNo) {
    this.scanBarCode()
  }
}

scanBarCode(){
  const isCasesExceed = this.checkCasesCount();
  if (isCasesExceed) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Scanned Quantity should not be greater than approved quantity...' });
    return;
  }
  const req_Data = {
    action: 'add_casecodes',
    data: { "tpNo": this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo, "caseCode": this.caseCode, "depotCode": this.icdcData[this.ofsIndex].productDetails.destnEntityId, "productDetails": this.icdcData[this.ofsIndex].productDetails }
  }
  if (this.ofsChangeData.productDetails && this.ofsChangeData.productDetails[0]['approvedCases']) {
    req_Data.data['type'] = 'cases';
  }
  else {
    req_Data.data['type'] = 'bottles';
  }
  if (this.caseCode && this.ofsChangeData.ofidtNo) {
    req_Data.data['ofidtNo'] = this.ofsChangeData.ofidtNo;
    delete req_Data.data['productDetails'][0].allocateQtyBtls;
    delete req_Data['data']['productDetails'][0].allocateQtyCases;
    delete req_Data['data']['productDetails'][0].closingBalBottles;
    delete req_Data['data']['productDetails'][0].closingBalCases;
    this.orderService.updateIDTs(req_Data).subscribe(res => {
      console.log(res);

      this.valid = res.status;
      this.caseCode = '';
      this.getOfsDetails();
    })
  }else{
    req_Data.data['icdcCode'] = this.ofsScanCode;
    this.shipmentsService.scanBarCode(req_Data).subscribe(res => {
      console.log(res);

      this.valid = res.status;
      this.caseCode = '';
      this.getOfsDetails();
    })
  }

}

changeOfs(code,i) {
  this.isDisable = false;
  this.ofsScanCode = code.icdcCode;
  this.ofsIndex = i;
  this.ofsChangeData = code;
}
dialogClose(reload = false): void {
  if (reload === true) {
    this.dialogRef.close('Updated Successfully');
  } else {
    this.dialogRef.close();
  }
}
checkCasesCount(): any {
  const isCasesExceed = this.icdcData.map((item: any) => {
    return item.productDetails.some(pd => pd.casesCount >= pd.approvedQty);
  });
  if (isCasesExceed && isCasesExceed.length && isCasesExceed[0] === true) {
    return true;
  }
  return false;
}

// getCaseCount(item){
//   // if(item.breakages){
//     // if(item.breakages.bottleBreakages){
//       // this.utilService.getTotalScannedCasesBottles(item);
//       // let unitsPerCase = this.utilService.productCodeToDetails(item.productCode).unitsPerCase
//       // let totalScannedBottles = (item.breakages.bottleBreakages.length * unitsPerCase) - item.breakages.bottles;
//       // item.totalScannedCases = item.casesCount + Math.floor(totalScannedBottles/unitsPerCase);
//       // item.totalScannedBottles = totalScannedBottles % unitsPerCase;
//     // }
//   // }
//   this.utilService.getTotalScannedCasesBottles(item);
//   console.log('item', item);
//   return item;
// }
updateScannedBeerCases(comp, item): any {
  if (!comp.casesCountInput || comp.casesCountInput < 1 || comp.casesCountInput > comp.approvedCases) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Scanned Cases should not greater than approved Cases' });
    return;
  }
  const reqData: any = {
    action: (this.data.consignment[0] && this.data.consignment[0].indentType && this.data.consignment[0].indentType === 'bottles_indent') ? 'add_healcodes_beer' :'add_casecodes_beer',
    data: {
      tpNo: this.data.tpNo,
      icdcCode: item.icdcCode,
      productCode: comp.productCode,
      consignmentType: this.data.consignmentType,
      ofidtNo: item.ofidtNo
    }
  };
  if (this.data.consignment[0].indentType === 'cases_indent') {
    reqData.data.casesCount = comp.casesCountInput;
  } else if (this.data.consignment[0].indentType === 'bottles_indent') {
    reqData.data.bottlesCount = comp.casesCountInput;
  }
  this.spinner.show();
  this.shipmentsService.saveShipmentQuantity(reqData).subscribe(res => {
    if (res && res.status === 'SUCCESS') {
      this.getOfsDetails();
      // this.dialogClose(true);
    } else {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Not Updated' });
    }
    this.spinner.hide();
  }, err => {
    this.spinner.hide();
  });
}
closeDialog(){
  this.dialogRef.close('');
}
isValidCasesCount(comp, errorMessage = false): any {
  if (comp.casesCount && this.caseCode && this.caseCode.length) {
    return true;
  }
  if(comp.approvedBottles){
    if (!comp.casesCountInput || comp.casesCountInput < 1 || (comp.casesCountInput > comp.approvedBottles)) {
      if (errorMessage) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Scanned Bottles should not 0 and should not greater than approved Bottles' });
      }
      return true;
    }
    const availableBottles = comp.approvedBottles - comp.bottlesCount;
    if(comp.casesCountInput > availableBottles){
      if(errorMessage){
        this.messageService.add({ severity: 'error', summary: 'Error', detail: `Available Bottles Count is ${availableBottles}` });
      }
      return true;
    }
  } else if (comp.approvedCases) {
    if (!comp.casesCountInput || comp.casesCountInput < 1 || (comp.casesCountInput > comp.approvedCases)) {
      if (errorMessage) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Scanned Cases should not 0 and should not greater than approved Cases' });
      }
      return true;
    }
    let availableCases = comp.approvedCases - comp.casesCount;
    if (comp.breakageBottles || comp.breakageCases || comp.shortageCases || comp.shortageBottles) {
      const breakageQty = (comp.breakageCases * comp.unitsPerCase) + comp.breakageBottles;
      const shortageeQty = (comp.shortageCases * comp.unitsPerCase) + comp.shortageBottles;
      const totalQty = breakageQty + shortageeQty;
      availableCases = availableCases - Math.ceil(totalQty / comp.unitsPerCase);
    }
    if (comp.casesCountInput > availableCases) {
      if (errorMessage) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: `Available Case Count is ${availableCases}` });
      }
      return true;
    }
  }
  // else if (!comp.casesCountInput || comp.casesCountInput < 1 || (comp.casesCountInput > comp.approvedCases)) {
  //   if (errorMessage) {
  //     this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Scanned Cases should not 0 and should not greater than approved Cases' });
  //   }
  //   return true;
  // }
  return false;
}

}
