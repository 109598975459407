import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DistilleryInfoService } from 'src/app/core/services/distillery-info.service';

@Component({
  selector: 'app-manage-gate-register-dialog',
  templateUrl: './manage-gate-register-dialog.component.html',
  styleUrls: ['./manage-gate-register-dialog.component.scss']
})
export class ManageGateRegisterDialogComponent implements OnInit , OnChanges {
  @Input() featuresData: any;
  gateRegisterForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  user: any;
  saveDisabled: boolean;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageGateRegisterDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private distilleryInfoService: DistilleryInfoService) { }

              // this.user = JSON.parse(sessionStorage.getItem('currentUser')).result;
  ngOnInit(): void {
    console.log('this.data', this.data);
    this.gateRegisterForm = this.fb.group({
      driverName:['', [Validators.required]],
      driverNumber:['', [Validators.required]],
      vehicleNumber: ['', [Validators.required]],
      inTime: '',
      outTime: '',
      purpose: ['', [Validators.required]],
      // entityType: 'Distillery',
      // entityId: this.user.entityId
    });
    if (this.data && this.data._id) {
      this.gateRegisterForm.patchValue({
        driverName: this.data.driverName,
        driverNumber: this.data.driverNumber,
        vehicleNumber: this.data.vehicleNumber,
        inTime: this.data.inTime,
        outTime: this.data.outTime,
        purpose: this.data.purpose,
        // entityType: this.data.entityType,
        // entityId: this.data.entityId
      });
    }
    this.gateRegisterForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    })
  }

  ngOnChanges(): void {
  }

  get f(){
    return this.gateRegisterForm.controls;
  }

  createGateRegister() {
    this.saveDisabled = true;
    this.gateRegisterForm.patchValue({manufactureDate: new Date().toISOString() });
    this.distilleryInfoService.createGateRegister(this.gateRegisterForm.value).subscribe((res: any) => {
      this.dialogRef.close('Gate Register Created Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  // getTitle() {
  //   return this.data && this.data._id ? `Update Gate Register for ${this.data.gateRegisterName}` : 'Create Gate Register';
  // }


}
