export const BottleSizes = {
    "FK": 50000,
    "KK": 30000,
    "EK": 20000,
    "MD": 4500,
    "MR": 4500,
    "MM": 4500,
    "XG": 2000,
    "TM": 1750,
    "BM": 1500,
    "LK": 1500,
    "RB": 1500,
    "LL": 1000,
    "LQ": 1000,
    "LR": 1000,
    "LG": 1000,
    "LM": 1000,
    "QQ": 750,
    "QM": 750,
    "QK": 750,
    "QR": 750,
    "QG": 750,
    "TG": 750,
    "JD": 700,
    "JQ": 700,
    "JM": 700,
    "JG": 700,
    "JR": 700,
    "JK": 700,
    "JH": 700,
    "BS": 650,
    "BA": 650,
    "AP": 500,
    "HE": 500,
    "HM": 500,
    "BW": 500,
    "HQ": 500,
    "BX": 500,
    "PP": 375,
    "PM": 375,
    "WQ": 375,
    "SA": 360,
    "SP": 360,
    "BI": 355,
    "DP": 350,
    "UP": 330,
    "RP": 325,
    "BV": 300,
    "IT": 300,
    "GP": 275,
    "ZP": 250,
    "BY": 207,
    "CP": 200,
    "CN": 200,
    "NN": 180,
    "NP": 180,
    "VB": 100,
    "DD": 90,
    "OO": 60,
    "AF": 50,
    "AC": 50,
    "AQ": 50,
    "AA": 50,
    "AB": 50,
    "AJ": 50,
    "AD": 50,
    "BL": 1
}
export const unitsPerCase = {
    "AF": 192,
    "OO": 150,
    "AC": 120,
    "DD": 96,
    "AD": 96,
    "AJ": 80,
    "AB": 60,
    "VB": 60,
    "NN": 48,
    "CN": 48,
    "AA": 40,
    "IT": 30,
    "CP": 24,
    "ZP": 24,
    "GP": 24,
    "UP": 24,
    "DP": 24,
    "BI": 24,
    "PP": 24,
    "AP": 24,
    "NP": 24,
    "BY": 24,
    "RP": 24,
    "SP": 24,
    "SA": 20,
    "BA": 20,
    "BV": 20,
    "BX": 20,
    "HE": 18,
    "JH": 15,
    "BS": 12,
    "JQ": 12,
    "QQ": 12,
    "LQ": 12,
    "AQ": 12,
    "WQ": 12,
    "HQ": 12,
    "LL": 9,
    "BW": 8,
    "JM": 6,
    "QM": 6,
    "BM": 6,
    "PM": 6,
    "HM": 6,
    "LM": 6,
    "TM": 6,
    "MM": 6,
    "JG": 4,
    "XG": 4,
    "QG": 4,
    "LG": 4,
    "TG": 4,
    "JR": 3,
    "QR": 3,
    "LR": 3,
    "RB": 3,
    "MR": 3,
    "JD": 2,
    "MD": 2,
    "QK": 1,
    "KK": 1,
    "FK": 1,
    "BL": 1,
    "JK": 1,
    "LK": 1,
    "EK": 1
}