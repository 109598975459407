import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-heal-codes-dialog',
  templateUrl: './heal-codes-dialog.component.html',
  styleUrls: ['./heal-codes-dialog.component.scss']
})
export class HealCodesDialogComponent implements OnInit {
  healCodes: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {

    this.healCodes = this.data.healCodes.map(res => res.healCode);
  }

  onAddHeals(e) {
    
  }

}
