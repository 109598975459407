import { ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonsService } from 'src/app/core/services/commons.service';
import { MessageService } from 'primeng/api';
import { ThroughTransportPermitService } from './../../../core/services/through-transport-permit.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-manage-ttp-revalidation-dialog',
  templateUrl: './manage-ttp-revalidation-dialog.component.html',
  styleUrls: ['./manage-ttp-revalidation-dialog.component.scss']
})
export class ManageTtpRevalidationDialogComponent implements OnInit {
  
  @ViewChild('ttpHtmlForm') ttpHtmlForm: any;
  ttpForm: FormGroup;
  userInfo: any;
  loader: any = false;
  roleName: any = '';

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ManageTtpRevalidationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private ttpService: ThroughTransportPermitService,
    private messageService:MessageService,
    private spinner: NgxSpinnerService,
  ) {
    this.userInfo = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
  }

  ngOnInit(): void {
    this.ttpForm = this.fb.group({
      permitNo: ['', Validators.required],
      validFrom: ['', Validators.required],
      validTo: ['', Validators.required],
      comments: ['', Validators.required],
    });
    if (this.data) {
      this.ttpForm.patchValue({
        permitNo: this.data.permitNo,
        validFrom: this.data.validFrom,
        validTo: this.data.validTo,
        comments: this.data.comments
      });
    }
  }
  create() {
    console.log('createTTP', this.ttpForm);
    if (this.ttpForm.status === 'VALID') {
      const req_Data = {
        action: 'create_ttp_revalidation',
        data: this.ttpForm.value
      }
      console.log(req_Data)
      this.loader = true;
      this.spinner.show();
      this.ttpService.postMethod(req_Data).subscribe((res: any) => {
        if (res && res.status === 'SUCCESS') {
          this.dialogRef.close('TTP Revalidation Successfully');
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: res.error ? res.error : 'TTP Revaliated Failed' });
        }
        this.loader = false;
        this.spinner.hide();
      }, (err) => {
        this.loader = false;
        this.spinner.hide();
      });
    }
  }
  update(): any {
    console.log('reviewTTPRevalidation', this.ttpForm);
    if (this.ttpForm.status === 'VALID') {
      const req_Data = {
        action: 'update_ttp_revalidation',
        data: this.ttpForm.value
      }
      // req_Data.data.status = 'Approved';
      this.loader = true;
      this.spinner.show();
      this.ttpService.putMethod(req_Data).subscribe((res: any) => {
        if (res && res.status === 'SUCCESS') {
          this.dialogRef.close('TTP Revalidation Updated Successfully');
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'TTP Revalidation Updation Failed' });
          this.dialogRef.close('TTP Revalidation Failed');
        }
        this.loader = false;
        this.spinner.hide();
      }, (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server Error' });
        this.dialogRef.close();
        this.loader = false;
        this.spinner.hide();
      });
    }
  }

  resetForm(): any {
    this.ttpHtmlForm.nativeElement.reset();    
  }

}
