import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CommonsService } from 'src/app/core/services/commons.service';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { MessageService } from 'primeng/api';
import { IndentManagementService } from 'src/app/core/services/indent-management.service';
import { InterDepotService } from 'src/app/core/services/inter-depot.service';

@Component({
  selector: 'app-manage-tp-requests-dialog',
  templateUrl: './manage-tp-requests-dialog.component.html',
  styleUrls: ['./manage-tp-requests-dialog.component.scss']
})
export class ManageTpRequestsDialogComponent implements OnInit {
  tpRequestForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  title: any = 'Create';
  saveDisabled: boolean;
  public routeFilterCtrl: FormControl = new FormControl();
  consignmentTypes = ['ICDC','STOCKRETURN'];
  routes = [];
  public filteredRoutes: ReplaySubject<any> = new ReplaySubject<any>(1);
  public _onDestroy = new Subject<void>();
  @ViewChild('singleRouteSelect') singleRouteSelect: MatSelect;
  routeList: any;
  user: any;
  loader: any = false;


  constructor(private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,private commonsService: CommonsService, public utilService: UtilService,
    private dialogRef: MatDialogRef<ManageTpRequestsDialogComponent>,private messageService:MessageService,private indentManagementService: IndentManagementService,
    private interDepotService:InterDepotService) {
    this.user = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
  }

  ngOnInit(): void {
    this.tpRequestForm = this.fb.group({
      consignmentType: ['',Validators.required],
      vehicleNo: ['',Validators.required],
      transporterName: ['',Validators.required],
      distance: ['',Validators.compose([Validators.required,Validators.pattern("^[0-9]*$")])],
      driverName:['',Validators.required],
      driverNumber:['',Validators.required]
    });
    console.log(this.data)
    if(this.data.consignmentType === 'OFIDT'){
      this.consignmentTypes = ['OFIDT'];

    }
  }
  get f() {
    return this.tpRequestForm.controls;
  }
  consignmentTypeChange() {
    let paramObj = {
      action: 'common_apis',
      q: '{"data": {"action": "get_routes","value": "'+this.tpRequestForm.value.consignmentType+'"}}'
    }
    this.commonsService.getRoutes(paramObj).subscribe(result => {
      this.routeList = result.get_routes;
      this.routes = result.get_routes.map(route => route.routeName);
      this.filteredRoutes.next(this.routes.slice());
    })
  }

  createTPRequest() {
    this.saveDisabled = true;
    let data = { ...this.tpRequestForm.value };
    if(this.data.consignmentType === 'OFIDT'){
      data.ofidtNo = this.data.ofidtNo;
      data.destinationDepotCode = this.data.destinationDepotCode;
    }else{
      data.icdcCode = this.data.icdcCode;
    }
    if (this.data.indent) {
      data.indent = this.data.indent;
    }
    const req_data:any = {
      action: 'create_tp',
      data
    };
    console.log(req_data,);
    this.loader = true;
   const apiUrl = this.data.consignmentType === 'OFIDT' ? this.interDepotService.createIdtTP(req_data) : this.indentManagementService.createRetailerIndent(req_data)
   apiUrl.subscribe((res: any) => {
      if(res.status === 'SUCCESS'){
        this.loader = false;
        this.dialogRef.close({
          message: 'Success',
        });
      }else if(res.error){
        this.loader = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: `${res.error}` });
      }
    
    }, (err) => {
      this.error = err.error.message;
    });

  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
