import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { CommonsService } from 'src/app/core/services/commons.service';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { SupplyDeliveryService } from 'src/app/core/services/supply-delivery.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { SourceBottleCaseDetailsDialogComponent } from './source-bottle-case-details-dialog/source-bottle-case-details-dialog.component';
import { SourceCaseDetailsDialogComponent } from './source-case-details-dialog/source-case-details-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-manage-repacking-dialog',
  templateUrl: './manage-repacking-dialog.component.html',
  styleUrls: ['./manage-repacking-dialog.component.scss']
})
export class ManageRepackingDialogComponent implements OnInit {
  rePackingForm: FormGroup;
  productDetails: { supplierCode: any; liquorType: any; brandCode: any; packType: any; size: any; };

  constructor(private fb: FormBuilder,
   private supplyDeliveryService: SupplyDeliveryService, private commonsService: CommonsService, private utilService: UtilService,
    private shipmentService: ShipmentsService, private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private util: UtilService
  ) { }
    targetData:any ;
    healCodeData:any ;
    healCodeDataList:any = [];
    healCodeList:any = [];
    movedHealDataList:any = [];
    caseCodeDataList:any = [];
    copyCaseCodeDataList:any = [];
    movedCaseFataList: any = [];
    moveBottleCount: any = 0;
    bottlesToMove: any;
    isUndo: boolean = false;
  ngOnInit(): void {

    this.rePackingForm = this.fb.group({
      scanHealCode: ['', [Validators.required]],
      scanBottleCaseCode: ['', [Validators.required]],
      scanTargetCaseCode: ['', [Validators.required]]
   
    });

    
  }


  repack() {
  console.log('this.movedHealDataList', this.movedHealDataList);
  console.log('this.targetData', this.targetData);
  const targetCaseCode = this.rePackingForm.value.scanTargetCaseCode;
  const imlCaseCode = this.movedHealDataList.map(item => {return {"healCode": item.healCode,"caseCode": item.caseCode}});
  const tpNoExistsInMovedHeal = this.movedHealDataList.every(el => el.tpNo);
  const tpNoExistsInTarget = this.targetData && this.targetData.tpNo ? true : false;  
  console.log(tpNoExistsInMovedHeal, tpNoExistsInTarget);
  if (tpNoExistsInMovedHeal && tpNoExistsInTarget && (this.targetData.tpNo.slice(0,3)) === 'TP0') {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Case already scanned for Licensee Shipments' });
    return;
  }
   const req_Data = {
      action: (tpNoExistsInMovedHeal || tpNoExistsInTarget) ? 'repack_bottle_case' : 'repack_depot_case',
      data: {
        targetCaseCode: targetCaseCode,
        imlCaseCode: imlCaseCode,
        productCode: this.targetData.productCode,
        tpNo: this.targetData.tpNo,
        ofsCode: this.targetData.ofsCode,
        batchId: this.targetData.batchId
      }
    }
   // delete req_Data.data.scanHealCode;
   if (!this.targetData.tpNo) {
    delete req_Data.data.tpNo;
   }
   console.log(req_Data);
   this.spinner.show();
    this.shipmentService.repackBottle(req_Data).subscribe(res => {
      if (res && res.status !== "SUCCESS") {
        this.spinner.hide();
        return;
      }
    //  this.dialogRef.close("Bottles Repacked Successfully");
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Repacked Successfully' });
   
    this.movedHealDataList.forEach(item => {
      const healCodeIndex = this.healCodeDataList.findIndex(movedItem =>  movedItem.healCode === item.healCode);
      if( healCodeIndex != -1) {
        this.healCodeDataList.splice(healCodeIndex,1);
      }
    })
    this.rePackingForm.patchValue({
      scanHealCode: ''
    })
    // this.changeTargetCaseCode();
    this.movedHealDataList = [];
    this.targetData = [];
    this.rePackingForm.controls.scanTargetCaseCode.setValue('');
    this.spinner.hide();
    }, err => {
      this.spinner.hide();
    })
  }

  repackBeer() {
    const req_Data = {
      action: 'repack_bottle_case',
      data: {
        targetCaseCode: this.rePackingForm.value.scanTargetCaseCode,
        beerCaseCode: this.caseCodeDataList,
        totalBottlesCount: this.targetData.unitsPerCase
      }
    }
   
    this.spinner.show();
   // delete req_Data.data.scanHealCode;
    this.shipmentService.repackBottle(req_Data).subscribe(res => {
    //  this.dialogRef.close("Bottles Repacked Successfully");
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Repacked Successfully' });
   
 
    this.rePackingForm.patchValue({
      scanBottleCaseCode: ''
    })
    this.changeTargetCaseCode();
    this.caseCodeDataList = [];
    this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }



  changeSourceCaseCode() {
    {
      //  let tpNo = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
      if(this.rePackingForm.get('scanBottleCaseCode').value) {
        const payload = {
          data:{
            action: "get_beer_case_details", 
            value: {
              sourceCaseCode: this.rePackingForm.get('scanBottleCaseCode').value,
              targetProductCode: this.targetData.productCode,
              targetTpno: this.targetData.tpNo
            }
          }
        };
        const req_Data = {
          action: 'common_apis',
          q: JSON.stringify(payload)
    
        }
    
        this.commonsService.getCommonEntites(req_Data).subscribe(result => {
         /*  this.targetData = result['get_case_details'];
          this.productDetails = this.utilService.productCodeToDetails(this.targetData.productCode); */
          if(!result['get_case_details'].error){
          this.caseCodeDataList.push(result['get_case_details']);
          this.caseCodeDataList.forEach(item => item['movedCount'] = 0);
          this.copyCaseCodeDataList = JSON.parse(JSON.stringify(this.caseCodeDataList))
          console.log(this.caseCodeDataList,"83::", result)
        }else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result['get_case_details'].error });
          }
         })
      }
      }
  }

  changeTargetCaseCode() {
  //  let tpNo = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
  if(this.rePackingForm.get('scanTargetCaseCode').value) {

    const req_Data = {
      action: 'common_apis',
      q: '{"data": {"action": "get_case_details", "value": "' + this.rePackingForm.get('scanTargetCaseCode').value + '"}}'

    };
    this.spinner.show();
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {
      if(result && result['get_case_details'] && result['get_case_details'].bottleCount < result['get_case_details'].unitsPerCase ||  result['get_case_details'] && result['get_case_details'].healCodes.length < result['get_case_details'].unitsPerCase) {
        this.targetData = result['get_case_details'];
        this.productDetails = this.utilService.productCodeToDetails(this.targetData.productCode)
        this.movedHealDataList = [];
      }
      else if(this.targetData== null){
      
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "Cannot Repack this Case Code" });
      }
      this.spinner.hide();
     }, err => {
      this.spinner.hide();
     });
  }
  }

  moveHeal(heal) {
    if(this.targetData.unitsPerCase - this.targetData.healCodes?.length > this.movedHealDataList.length) {
      this.movedHealDataList.push(heal);
    }
    else {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Target Case limit Reached' });
    }
  }

  moveCase(data) {
   data.movedCount++;
   this.targetData.bottleCount++;
  }

  removeSourceCase(data,i) {
    this.targetData.bottleCount-=data.movedCount;
    this.caseCodeDataList.splice(i,1);

  }

  undoHeal(heal) {
    let undoItem = this.movedHealDataList.findIndex((element,i) => {
     return element.healCode === heal.healCode
    });
    this.movedHealDataList.splice(undoItem,1);
  }

  undoCase(data) {
    data.movedCount--;
    this.targetData.bottleCount--;
   }

  isMoved(heal) {
    return this.movedHealDataList.findIndex((element,i) => { return element.healCode === heal.healCode}) != -1;
  }

  showSourceCaseDetails(data){
    this.utilService.openModalDialog(SourceCaseDetailsDialogComponent, data, '50%', '50%', false, null, null);
  }  

  showBottleCaseDetails(data) {
    this.utilService.openModalDialog(SourceBottleCaseDetailsDialogComponent, data, '50%', '50%', false, null, null);
  }

  changeHealCode() {
    //  let tpNo = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
    console.log('::::::1600:::', this.bottlesToMove);
    if(this.rePackingForm.get('scanHealCode').value) {
      const payload = {
        data:{
          action: "get_heal_details", 
          value: {
            sourceHealCode: this.rePackingForm.get('scanHealCode').value,
            targetCaseCode:this.targetData.caseCode,
            targetProductCode:this.targetData.productCode,
            targetTpno:this.targetData.tpNo
          }
        }
      };
      
        payload['data']['value']['breakageType'] = !this.targetData.tpNo ? 'Storage' : 'Transit'
     
      const req_Data = {
        action: 'common_apis',
        q: JSON.stringify(payload)
  
      }
      this.spinner.show();
      this.commonsService.getCommonEntites(req_Data).subscribe(result => {
        if (result && result['get_heal_details']) {
          this.healCodeData = result['get_heal_details'];
          if (this.healCodeData.productCode) {
            this.healCodeData.packType = this.util.productCodeToDetails(this.healCodeData.productCode).packType;
          }
          this.healCodeData.healCode = this.rePackingForm.get('scanHealCode').value;
          if (this.healCodeData.healCodes && this.healCodeList.indexOf(this.healCodeData.healCodes) == -1) {
            this.healCodeList.push(this.healCodeData.healCodes);
            this.healCodeDataList.push(result['get_heal_details']);
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: this.healCodeData.error });
          }
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: this.healCodeData.error });
        }        
        this.spinner.hide();
       }, err => {
        this.spinner.hide();
       });
    }
  }
  onAddHeals(e) {

  }
}
