import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonsService } from 'src/app/core/services/commons.service';

@Component({
  selector: 'app-cfms-payment-verify-dialog',
  templateUrl: './cfms-payment-verify-dialog.component.html',
  styleUrls: ['./cfms-payment-verify-dialog.component.scss']
})
export class CfmsPaymentVerifyDialogComponent implements OnInit {

  paymentForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  exciseDutyPurpose:any;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CfmsPaymentVerifyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private commonService: CommonsService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.setForm();
    if (this.data.depotID) {
      this.getPaymentDetails();
    }
    console.log(this.data)
  }
  setForm(): any {
   
      this.paymentForm = this.fb.group({
        cfms_id : [this.data.cfms_id, [Validators.required, ]],
        oth_dept_id : [this.data.oth_dept_id, [Validators.required, ]],
        bankamount: [this.data.bankamount, [Validators.required, ]],
        bankrefnum: [this.data.bankrefnum, [Validators.required, ]],
      });
    
    if(this.data.departmentId && this.data.orderId){
      this.exciseDutyPurpose= true;
      this.paymentForm = this.fb.group({
        // cfms_id : [this.data.cfms_id, [Validators.required, ]],
        oth_dept_id : [this.data.departmentId, [Validators.required, ]],
        bankamount: [this.data.amount, [Validators.required, ]],
        bankrefnum: [this.data.paymentGateway !='PAYTM' ? this.data.acquirer_data?.bank_transaction_id : this.data.bank_txn_id, [Validators.required, ]],
      });
    }

  }
  getPaymentDetails(): any {
    // tslint:disable-next-line:variable-name
    const  req_data = {
        action: 'cfm_validator',
        q: JSON.stringify({data: {dept_txn_id: this.data.depotID }})
      };
    console.log(req_data)

    this.spinner.show();
    this.commonService.paymentDetails(req_data).subscribe((res: any) => {
      console.log(res)
      if (res && res.status && res.status.error) {
        this.messageService.add({ severity: 'error', detail: res.status.error});
        this.dialogRef.close();
      } else if (res.status && res.status.cfms_id) {
        this.data = res.status;
        this.setForm();
      }
      this.spinner.hide();
    }, err => {
      this.messageService.add({ severity: 'error', detail: 'Error'});
      this.spinner.hide();
    });
  }
  verify(): any {
    // tslint:disable-next-line:variable-name
    let req_Data;
      
    //  if(this.data.paymentGateway === 'PAYTM'){
    //   let data:any ={}
    //   data.payment_id = this.data.paymentId,
    //   data.purpose = this.data.purpose
    //   req_Data = {
    //     action: 'paytm_cfm_validator_update',
    //     data:data
    //   };
    // }else{
      req_Data = {
        action : 'cfm_validator_update',
        data : this.data
      }
    // }
    console.log(req_Data)


    this.spinner.show();
    this.commonService.verifyPaymentDetails(req_Data).subscribe(res => {
      if (res.status === 'SUCCESS') {
        this.dialogRef.close('');
      } else {
        this.messageService.add({ severity: 'error', detail: 'Not Verified'});
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      this.error = err.error.message;
      this.messageService.add({ severity: 'error', detail: err.error.message});
    });
  }

}
