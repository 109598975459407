import { CommonsService } from './../../../../core/services/commons.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Export } from 'src/app/core/models/export/export.model';
import { ExportService } from 'src/app/core/services/export.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { Product } from 'src/app/core/models/stock/hold-on-stock.model';
import { MatSelect } from '@angular/material/select';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { ExportChallanDetailsComponent } from '../export-challan-details/export-challan-details.component';

@Component({
  selector: 'app-export-feature-details-dialog',
  templateUrl: './export-feature-details-dialog.component.html',
  styleUrls: ['./export-feature-details-dialog.component.scss']
})
export class ExportFeatureDetailsDialogComponent implements OnInit {
  exportForm:FormGroup;
  productDetails: FormArray;
  compositionAddFields: any = [];
  public productFitlerCtrl: FormControl = new FormControl();
  public _onDestroy = new Subject<void>();
  public filterCtrl: FormControl = new FormControl();
  productCodes:any=[];
  showPrint: any = false;
  printId: any = '';
  loader:boolean;
  displayedColumns: string[] = ['no',
    'brandCode',
    'brandName',
    'size',
    'packType',
    'quantity',
    'apBasic',
    'concernedInvoiceBasic',
    'apbclValue',
    'value',
    'bls',
    'exportFee',
    'cst',
    'tcs',
    'other',
    'invoice',
    'commision'
  ];
  dataSource: MatTableDataSource<Export> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;
  nameOfTheExporter:any;
  productData:any=[];
  userRole:any;
  public filteredProducts: ReplaySubject<any> = new ReplaySubject<any>(1);
  quantity:any;
  minDate:any;
  @ViewChild('productSelect') productSelect: MatSelect;
  disableInput:Boolean;
  statesList:any=[
    // 'Andhra Pradesh',
    'Andaman and Nicobar Islands',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Delhi',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu and Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Malaysia',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Puducherry-UT',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Singapore',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'USA',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal'
  ]
  validPeriod: any = '';

  constructor(public dialogRef: MatDialogRef<ExportFeatureDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,public dialog:MatDialog,
    private exportService: ExportService,private fb: FormBuilder,private commonService:CommonsService, public util:UtilService,) { 
      this.nameOfTheExporter = this.data ? this.data.nameOfTheExporter : JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).entity_id; 
      this.userRole = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
      this.minDate = new Date();
    }

  ngOnInit(): void {
    this.getExportDetails();
    this.exportForm = this.fb.group({
      nameOfTheImporter: ['',[Validators.required]],
      nameOfTheExporter: ['',[Validators.required]],
      address: ['',[Validators.required]],
      importIndentNumber: ['',[Validators.required]],
      importPermitFromDate:['',[Validators.required]],
      importPermitToDate:['',[Validators.required]],
      purpose:['',[Validators.required]],
      destination:['',[Validators.required]],
      route:['',[Validators.required]],
      state:['',[Validators.required]],
      productDetails: this.fb.array([this.updateExportInformation()], [Validators.required])
    });
    this.productDetails = this.exportForm.get('productDetails') as FormArray;
    this.getProducts();
    this.productFitlerCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterProducts();
    });
 
  console.log(this.data)
  if(this.userRole === 'Material Management'){
    this.disableInput = true;
  }

if (this.data) {
  const productDetails = this.exportForm.get('productDetails') as FormArray;
  productDetails.clear();
  this.exportForm.patchValue({
    nameOfTheImporter: this.data.nameOfTheImporter,
    nameOfTheExporter: this.data.nameOfTheExporter,
    address: this.data.address,
    importIndentNumber:  this.data.importIndentNumber,
    importPermitFromDate: this.data.importPermitFromDate,
    importPermitToDate: this.data.importPermitToDate,
    purpose: this.data.purpose,
    destination: this.data.destination,
    route: this.data.route,
    state:this.data.state
  });
  this.data.productDetails.forEach(b => {
    productDetails.push(
      this.fb.group({
        productCode: b.productCode,
        brandName: b.brandName,
        brandCode:b.brandCode,
        size: b.size,
        packType:b.packType,
        apBasic: b.apBasic,
        concernedInvoiceBasic:b.concernedInvoiceBasic,
        approvedQty:b.approvedQty,
        apbclValue:b.apbclValue,
        value:b.value,
        bls:b.bls.toFixed(2),
        exportFee: b.exportFee.toFixed(2),
        cst: b.cst,
        tcs: b.tcs,
        other:b.other,
        invoiceValue:b.invoiceValue,
        commision:b.commision,
        strength:b.strength
      })
    );
  });
  this.dataSource = new MatTableDataSource(this.data.productDetails);
  this.validPeriod = this.data.validPeriod ? this.data.validPeriod : '';
}
  }
  getProducts() {

    const req_Data = {
      "action": "common_apis",
      "q": '{"data": ["get_prod_info_by_entity_id"]}'
    }
    this.commonService.getCommonEntites(req_Data).subscribe((res:any) => {
      this.productData = res['get_prod_info_by_entity_id'];
      console.log(this.productData)
      this.productData.forEach((code:any)=>{
        this.productCodes.push(code.productCode)
      })
      this.filteredProducts.next(this.productCodes);  
    });
    
  }
  filterProducts() {
    if (!this.productCodes || (this.productCodes && this.productCodes.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.productFitlerCtrl.value;
    if (!search) {
      this.filteredProducts.next(this.productCodes);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredProducts.next(
      this.productCodes.filter(product => JSON.stringify(product).toLowerCase().indexOf(search) > -1)
    );
  }
  updateExportInformation(){
    return this.fb.group({
      // checkedValue: '',
      productCode: ['',Validators.required],
      brandName:[''],
      brandCode:[''],
      size:[''],
      packType:[''],
      apBasic:[''],
      concernedInvoiceBasic:['',Validators.required],
      approvedQty:['',Validators.required],
      apbclValue:[''],
      value:[''],
      bls:[''],
      exportFee:[''],
      cst:[''],
      tcs:[''],
      other:[''],
      invoiceValue:[''],
      commision:[''],
      strength:['']
    });
  }
  removeUnit(i: number) {
    const control = <FormArray>this.exportForm.controls["productDetails"];
    control.removeAt(i);
  }
  addUnit() {
    const control = <FormArray>this.exportForm.controls["productDetails"];
    control.push(this.updateExportInformation());
  }
  getExportDetails() {
    // this.exportService.getExportDetails(this.offset, this.limit, this.search).subscribe((res: any) => {
    //   console.log('res', res);
    //   this.dataSource = new MatTableDataSource(res.data);
    // });
  }

  dialogClose(): void {
    this.dialogRef.close();
  }
  isDisabled(value): any {
    return this.exportForm.controls["productDetails"].value.some(item => item.productCode === value) 
    }

  QtyInCase(i){
    const productDetails = (<FormArray>this.exportForm.get("productDetails")).at(i);
    const productData = this.selectedProductData(this.productDetails.controls[i]['controls']['productCode'].value)
    const productCodeToData = this.util.productCodeToDetails(productData[0].productCode);
    const sizeInMl = this.util.getSizeInMl(productCodeToData.size)
    // const bulkLiters = productCodeToData.unitsPerCase * sizeInMl;
    const qty = parseInt(this.productDetails.controls[i]['controls']['approvedQty'].value);
    let bulkLiters;
    // if(sizeInMl === 90 || sizeInMl === 180){
    //    bulkLiters = qty * 8.64;
    // }else{
    //   bulkLiters = qty * 9;
    // }
    bulkLiters =(productCodeToData.unitsPerCase * qty * sizeInMl)/1000

    // const exportValue = (productData[0].basicPricing[0].price ? parseFloat(productData[0].basicPricing[0].price) :0) * qty;
    const apBasicValue = this.productDetails.controls[i]['controls']['apBasic'].value;
    const invoiceBasicValue = this.productDetails.controls[i]['controls']['concernedInvoiceBasic'].value;
    const exportValue = Math.max(apBasicValue,invoiceBasicValue) * qty;
    this.productDetails.controls[i]['controls']['value'].setValue(exportValue);
    const exportFee = 2 * bulkLiters;
    const cst = (exportValue + exportFee) * 0.02;
    const tcs = this.productDetails.controls[i]['controls']['tcs'].value;
    const other = this.productDetails.controls[i]['controls']['other'].value;
    const invoiceValue = (exportValue + exportFee + cst + tcs + other);
    const commision = (invoiceValue) * 0.01
    productDetails.patchValue({
      // concernedInvoiceBasic :apBasicValue,
      // approvedQty:this.productDetails.controls[i]['controls']['approvedQty'].value,
      apbclValue:exportValue,
      value:exportValue,
      bls:bulkLiters,
      exportFee: exportFee,
      cst: cst.toFixed(2),
      tcs: tcs,
      other:other,
      invoiceValue:invoiceValue.toFixed(2),
      commision:commision.toFixed(2),
    });
    if(this.productDetails.controls[i]['controls']['approvedQty'].value == 0){
      this.disableInput = true;
    }else{
      this.disableInput = false;
    }
  }
  selectedProductData(value){
   return this.productData.filter(data => {
      if(data.productCode == value){
        return data;
      }
    })
     
  }
  selectProduct(value, i) {
    const productDetails = (<FormArray>this.exportForm.get("productDetails")).at(i);
    this.productData.filter(data => {
       if(data.productCode == value.value.productCode){      
        productDetails.patchValue({
          productCode:data.productCode,
          size: data.size,
          brandName:data.brandName,
          packType:data.packType,
          apBasic: data.basicPricing[0].price ? data.basicPricing[0].price :0,
          approvedQty:1,
          brandCode:data.brandCode,
          tcs:0,
          other:0,
          strength:data.strength
          // apBasic:((data.basicPricing[0].price ? data.basicPricing[0].price :0) * (data.taxes[0] ? data.taxes[0]['VAT'] : 0)),
          // concernedInvoiceBasic: this.productDetails.controls[i].controls['concernedInvoiceBasic'].setValue(),
          // apbclValue:((data.basicPricing[0].price ? data.basicPricing[0].price :0) * (data.taxes[0] ? data.taxes[0]['VAT'] : 0)),
          // value:((data.basicPricing[0].price ? data.basicPricing[0].price :0) * (data.taxes[0] ? data.taxes[0]['VAT'] : 0)),
          // bls:((data.basicPricing[0].price ? data.basicPricing[0].price :0) * (data.taxes[0] ? data.taxes[0]['VAT'] : 0)),
          // exportFee: 2 * bulkLiters,
          // cst:((data.basicPricing[0].price ? data.basicPricing[0].price :0) * (data.taxes[0] ? data.taxes[0]['VAT'] : 0)),
          // tcs:((data.basicPricing[0].price ? data.basicPricing[0].price :0) * (data.taxes[0] ? data.taxes[0]['VAT'] : 0)),
          // other:((data.basicPricing[0].price ? data.basicPricing[0].price :0) * (data.taxes[0] ? data.taxes[0]['VAT'] : 0)),
          // invoiceValue:((data.basicPricing[0].price ? data.basicPricing[0].price :0) * (data.taxes[0] ? data.taxes[0]['VAT'] : 0)),
          // commision:((data.basicPricing[0].price ? data.basicPricing[0].price :0) * (data.taxes[0] ? data.taxes[0]['VAT'] : 0)),
        })
        this.QtyInCase(i)
       }
    })
  }
  approveIndent(status){
    delete this.data.colName;
    status === "Approved" ?  this.data.status = "Approved" :  this.data.status = "Cancelled";
    const reqData={
      action:'approve_export',
      data:this.data
    }
    reqData.data.validPeriod = this.validPeriod;
    console.log(reqData);
    this.exportService.updateExport(reqData).subscribe((res:any)=>{
      console.log(res);
      if(res.status=='SUCCESS'){
          this.dialogRef.close('Indent Updated Successfully');
      }
      this.validPeriod = '';
      
    })
  }
  save(){
    // const reqData={
    //   action:'add_export',
    //   data:this.exportForm.value
    // }
    // console.log(reqData);
    // this.exportService.createIndent(reqData).subscribe((res:any)=>{
    //   console.log(res);
    //   if(res.status=='SUCCESS'){
    //       this.dialogRef.close('Indent Added Successfully');
    //   }
      
    // })
    console.log(this.exportForm.value)
    this.loader = true;
    const dialogRef :any = this.dialog.open(ExportChallanDetailsComponent,{
      width: '50%',
      height: '60%',
      autoFocus: false,
      data :this.exportForm.value
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log("Success",result)
      if(result.message === "SUCCESS"){
        this.dialogRef.close('Indent Added Successfully');
      }
    });
    this.loader = false;
    
  }

  doPrint(id): any {
    this.printId = id;
    this.showPrint = true;
    this.getPrint(id);
  }
  getPrint(id): any {
    let printContents;
    let popupWin;
    setTimeout(() => {
      if (document.getElementById(id)) {
        printContents = document.getElementById(id).innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.write(`${printContents}`);
        popupWin.document.close();
        this.showPrint = false;
      }
    }, 500);
  }
}
