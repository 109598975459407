import { CommonsService } from './../../../../core/services/commons.service';
import { UserManualService } from './../../../../core/services/user-manual.service';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, Subject } from 'rxjs';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-open-supplier-dialog',
  templateUrl: './open-supplier-dialog.component.html',
  styleUrls: ['./open-supplier-dialog.component.scss'],
  providers: [MessageService]
})
export class OpenSupplierDialogComponent implements OnInit {

  InformationForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  offset: number;
  titles = ['Mr.', 'Mrs.', 'Ms.'];
  locationTypes = ['Supplier', 'Distillery', 'Non-Local Supplier', 'Breweries', 'Non-Local Breweries', 'Company'];
  isPassword: boolean = true;
  isConfirmPassword: boolean = true;
  roles: any;
  filteredRoles: any;
  suppliers = [];
  filteredSuppliers: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  protected _onDestroy = new Subject<void>();
  public supplierFilterCtrl: FormControl = new FormControl();
  getSuppliersResp: any;

  constructor(private fb: FormBuilder, public util: UtilService, private dialogRef: MatDialogRef<OpenSupplierDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,private messageService: MessageService, private userManualService: UserManualService, private CommonsService: CommonsService) {
    this.filteredSuppliers.next([]);

  }

  ngOnInit(): void {
    this.getSuppliers();
    console.log('this.data', this.data);

    this.InformationForm = this.fb.group({
      title: ['', [Validators.required,]],
      fullName: ['', [Validators.required,]],
      emailId: [''],
      userName: ['', [Validators.required,]],
      password: ['', [Validators.required,]],
      confirmPassword: ['', [Validators.required,]],
      locationType: ['', [Validators.required,]],
      location: ['', [Validators.required,]],
      role: ['', [Validators.required,]],
      mobileNo: [''],
      supplierFilter: ''
    }, {
      validators: this.confirmPasswordmatch('password', 'confirmPassword')
    });

    if (this.data) {
      this.InformationForm.patchValue({
        title: this.data.title,
        fullName: this.data.fullName,
        emailId: this.data.emailId,
        userName: this.data.userName,
        password: this.data.password,
        confirmPassword: this.data.confirmPassword,
        locationType: this.data.locationType,
        location: this.data.supplier.supplierCode,
        role: this.data.role,
        mobileNo: this.data.mobileNo
      });
    }
    this.InformationForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });

    this.util.matSelectFilter(this, "supplierFilterCtrl", "filteredSuppliers", "suppliers");

  }

  confirmPasswordmatch(controlName: string, matchingControlName: string) {
    // password matching
    return (fromGroup: FormGroup) => {
      const control = fromGroup.controls[controlName];
      const matchingControl = fromGroup.controls[matchingControlName];
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmPasswordmatch: true });
      }
      else {
        matchingControl.setErrors(null);
      }
    }
  }

  getSuppliers() {
    let paramObj = {
      action: 'common_apis',
      q: '{"data": ["get_suppliers_roles"]}'
    }

    this.CommonsService.getCommonEntites(paramObj).subscribe((res) => {
      this.getSuppliersResp = res;
      this.roles = res['get_suppliers_roles']['ROLES'];
      this.filteredRoles = JSON.parse(JSON.stringify(this.roles));

      this.suppliers = res['get_suppliers_roles']['SUPPLIERS'].map((supplier) => supplier.supplierCode);
      this.filteredSuppliers.next(this.suppliers);
    });
  }



  createSupplier() {
    this.InformationForm.patchValue({
      createdOn: new Date().toISOString()
    });
    this.saveDisabled = true;
    const plObj = this.InformationForm.value;
    const req_Data = {
      action: 'add_supplier_user',
      data: {
        ...plObj,
        number: plObj.mobileNo
      }
    }
    req_Data.data['activeUsers'] = this.util.encryptPassword(req_Data.data.password);
    delete req_Data.data.confirmPassword;
    delete req_Data.data.password;
    delete req_Data.data.mobileNo;

    this.userManualService.createSupplier(req_Data).subscribe((res: any) => {
      //popup status message
      if (res.status && res.status != 'User already exists') {
         
        this.dialogRef.close('User Created Sucessfully');
      } else {
        this.messageService.add({ severity: 'error', detail: 'User already exists' });
      }
    }, (err) => {
      this.error = err.error.message;
    });
  }

  showPassword() {
    this.isPassword = !this.isPassword;
  }

  onLocationChange(value) {
    const [role] = this.getSuppliersResp['get_suppliers_roles']['SUPPLIERS'].filter(item => item.supplierCode === value);
    this.InformationForm.get('locationType').setValue(role.locationType);
    this.filteredRoles = this.roles;

    if (role && role.locationType) {
      // console.log(role, '::::::::::137::::', this.roles);
      if (role.locationType === 'Distillery') {
        this.filteredRoles = this.roles.filter(roleItem => roleItem.roleName === 'Distillery Officer' || roleItem.roleName === 'Distillery Manager');
      } else  if (role.locationType === 'Supplier') {
        this.filteredRoles = this.roles.filter(roleItem => roleItem.roleName === 'Distillery Manager');
      }else  if (role.locationType === 'Non-Local Supplier') {
        this.filteredRoles = this.roles.filter(roleItem => roleItem.roleName === 'NonLocal Supplier Manager');
      } else {
        this.filteredRoles = this.roles.filter(roleItem => {
          if (roleItem.locationTypes.some(location => location === role.locationType)) {
            return role;
          }
        });
      }

    }
  }
  showConfirmPassword() {
    this.isConfirmPassword = !this.isConfirmPassword;
  }
  dialogClose(): void {
    this.dialogRef.close();
  }

}
