import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-idtreceipt-shipment-shipment-info-dialog',
  templateUrl: './idtreceipt-shipment-shipment-info-dialog.component.html',
  styleUrls: ['./idtreceipt-shipment-shipment-info-dialog.component.scss']
})
export class IDTReceiptShipmentShipmentInfoDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'no',
    'tpNo',
    'vechicleNo',
    'transporter',
    'issuedDate',
    'validityDate'
  ];
  tableData: any[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  constructor(public dialogRef: MatDialogRef<IDTReceiptShipmentShipmentInfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,
  public util:UtilService) { }

  ngOnInit(): void {
    this.tableData.push(this.data);
    this.dataSource = new MatTableDataSource(this.tableData);
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

  getTitle(): any {
    return this.data.tpNo ? 'TP' : 'IP';
  }
}
