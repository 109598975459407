import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  versionNo:any='';
  constructor() { }
  ngOnInit(): void {
    const currentUserModules = sessionStorage.getItem('lappCurrentUserModules');
    const item = JSON.parse(currentUserModules); 
    if (item && item.version_history && item.version_history) {
      this.versionNo=item.version_history.versionNo;
    }
  }
}