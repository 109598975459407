import { CommonsService } from 'src/app/core/services/commons.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OtpVerificationDialogComponent } from 'src/app/core/components/otp-verification-dialog/otp-verification-dialog.component';
import { InterDepotService } from 'src/app/core/services/inter-depot.service';

@Component({
  selector: 'app-manage-inter-depot-supplier-dialog',
  templateUrl: './manage-inter-depot-supplier-dialog.component.html',
  styleUrls: ['./manage-inter-depot-supplier-dialog.component.scss']
})
export class ManageInterDepotSupplierDialogComponent implements OnInit {

  interDepotTransferSupplierForm: FormGroup;
  error: string;
  saveDisabled: boolean;
  productDetails: FormArray;
  todayDate = new Date ();

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageInterDepotSupplierDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private interDepotService: InterDepotService,private dialog: MatDialog,private commponService:CommonsService) { }

  ngOnInit(): void {
    this.interDepotTransferSupplierForm = this.fb.group({
      sourceDepotName: ['', [Validators.required]],
      destinationDepotName: ['', [Validators.required]],
      capacity: ['', [Validators.required]],
      indentNumber: ['', [Validators.required]],
      installedDate: ['', [Validators.required]],
      productDetails: this.fb.array([this.editProductInfo()]),
    });

    if (this.data && this.data._id) {
      this.interDepotTransferSupplierForm.patchValue({
        sourceDepotName : this.data.sourceDepotName,
        destinationDepotName: this.data.destinationDepotName,
        capacity: this.data.capacity,
        indentNumber: this.data.indentNumber,
        installedDate: this.data.installedDate
      });
      const productDetails = this.interDepotTransferSupplierForm.get('productDetails') as FormArray;
      productDetails.clear();
      this.data.productDetails.forEach(b => {
        productDetails.push(
          this.fb.group({
            brandCode: b.brandCode,
            brandName: b.brandName,
            size: b.size,
            quantityInCases: b.quantityInCases,
            indentQuantityInCases: b.indentQuantityInCases
          })
        )
      });
    }
    this.productDetails = this.interDepotTransferSupplierForm.get('productDetails') as FormArray


    this.interDepotTransferSupplierForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
  }
  editProductInfo(){
    return this.fb.group({
      brandCode: ['', Validators.required],
      brandName: ['', Validators.required],
      size: ['', Validators.required],
      quantityInCases: ['', Validators.required],
      indentQuantityInCases: ['', Validators.required]
    });
  }

  get f(){
    return this.interDepotTransferSupplierForm.controls;
  }


  updateShipmentImportPermit() {

    // const req_otp ={
    //   action: "send_otp",
    //   data:{
    //    ...this.data
    //   }
    // }
    
    // this.commponService.sendVerifyOtp(req_otp).subscribe((res:any)=>{
      
      
    // })
    // const dialogRef = this.dialog.open(OtpVerificationDialogComponent, {
    //   width: '25%',
    //   height:'45%',
    //   autoFocus: false
    // });

    // dialogRef.afterClosed().subscribe((result) => {
    //   if(result.message == 'SUCCESS'){
        this.saveDisabled = true;
    const obj = [{
      _id: this.data._id,
      update: this.interDepotTransferSupplierForm.value

    }];
    this.interDepotService.putInterDepotTransferSupplier(obj).subscribe((res: any) => {
      console.log(res);
      this.dialogRef.close('Import Permit Updated Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
    //   }
    // });
  
  }
}
