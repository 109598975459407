import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CommonsService } from 'src/app/core/services/commons.service';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-manage-ip-request-dialog',
  templateUrl: './manage-ip-request-dialog.component.html',
  styleUrls: ['./manage-ip-request-dialog.component.scss']
})
export class ManageIpRequestDialogComponent implements OnInit {

  tpRequestForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  title: any = 'Create';
  saveDisabled: boolean;
  public routeFilterCtrl: FormControl = new FormControl();
  consignmentTypes = ['OFS', 'CSD', 'CSD Local', 'CSD Export', 'Export Non-Local', 'Export IST', 'Import Permit'];
  routes = [];
  public filteredRoutes: ReplaySubject<any> = new ReplaySubject<any>(1);
  public _onDestroy = new Subject<void>();
  @ViewChild('singleRouteSelect') singleRouteSelect: MatSelect;
  routeList: any;
  user: any;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageIpRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private shipmentsService: ShipmentsService, private commonsService: CommonsService
    ,public utilService: UtilService,) {
    this.user = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
  }

  ngOnInit(): void {
    console.log('this.data', this.data, this.user);
    if (this.user.entity_type === 'Depot') {
      this.consignmentTypes = ['ICDC', 'OFIDT'];
    }
    this.tpRequestForm = this.fb.group({
      consignmentType: [''],
      route: [''],
      vehicleNo: [''],
      transporterName: [''],
      lrNumber: [''],
      lrDate: [''],
      invoiceNo: [''],
      estimatedDeliveryTime: [''],
      driverName:['',Validators.required],
      driverNumber:['',Validators.required]
    });
    if (this.data) {
      this.title = 'Update';
      const tpDetails = this.data.transporterDetails[this.data.transporterDetails.length - 1] ? this.data.transporterDetails[this.data.transporterDetails.length - 1] : this.data.transporterDetails;
      this.tpRequestForm.patchValue({
        consignmentType: this.data.consignmentType,
        route: this.data.route,
        vehicleNo: tpDetails ? tpDetails.vehicleNo : '',
        transporterName: tpDetails ? tpDetails.transporterName : '',
        lrNumber: tpDetails ? tpDetails.lrNumber : '',
        lrDate: tpDetails ? tpDetails.lrDate : '',
        invoiceNo: this.data.transporterDetails.invoiceNo,
        estimatedDeliveryTime: this.data.transporterDetails.estimatedDeliveryTime,
        driverName: this.data.transporterDetails.driverName,
        driverNumber: this.data.transporterDetails.driverNumber
      });
    }
    this.tpRequestForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
    //let paramObj ='?action=common_apis&q={"data": ["get_routes"]}'
  /*   let paramObj = {
      action: 'common_apis',
      q: '{"data": ["get_routes"]}'
    }
    this.commonsService.getCommonEntites(paramObj).subscribe(result => {
      console.log(result, "54::::::::::")
      this.routeList = result.get_routes;
      this.routes = result.get_routes.map(route => route.routeName);
      this.filteredRoutes.next(this.routes.slice());
    }) */

    // listen for search field value changes
    this.routeFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRoutes();
      });
  }

  get f() {
    return this.tpRequestForm.controls;
  }

  filterRoutes() {
    if (!this.routes || (this.routes && this.routes.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.routeFilterCtrl.value;
    if (!search) {
      this.filteredRoutes.next(this.routes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredRoutes.next(
      this.routes.filter(name => name.toLowerCase().indexOf(search) > -1)
    );
  }

  estDate(date) {
    console.log(date,"112::::::")
  }

  consignmentTypeChange() {
    let paramObj = {
      action: 'common_apis',
      q: '{"data": {"action": "get_routes","value": "'+this.tpRequestForm.value.consignmentType+'"}}'
    }
    this.commonsService.getCommonEntites(paramObj).subscribe(result => {
      console.log(result, "54::::::::::")
      this.routeList = result.get_routes;
      this.routes = result.get_routes.map(route => route.routeName);
      this.filteredRoutes.next(this.routes.slice());
    })
  }

  createTPRequest() {
    this.saveDisabled = true;
    let data = { ...this.tpRequestForm.value };
    this.routeList.forEach(route => {

      if (route.routeName == data.route) {
        data['destnEntityId'] = route.destinationLocation;
        data['destnEntityName'] = route.destinationLocation.split('-')[1];
        data['distance'] = route.distance;
        data['estimatedTime'] = route.estimatedTime;
        data['sourceEntityName'] = route.sourceLocation.split('-')[1];
        data['routeVia'] = route.routeVia;
        console.log("data['destnEntityId'] :: ", data['destnEntityId'], "route.destnEntityId :: ", route.destnEntityId, "data['destnEntityName'] :: ", data['destnEntityName'], "route.destinationLocation :: ", route.destinationLocation)
      }
    })

    const req_data = {
      action: 'add_new_tp',
      data
    };
    console.log(req_data, '106:::');
    this.shipmentsService.createTPRequest(req_data).subscribe((res: any) => {
      if(res.status === 'SUCCESS'){
        this.dialogRef.close('IP Request Successfully');
      }
    }, (err) => {
      this.error = err.error.message;
    });

  }

  selectDate(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log(event, type, "124");
    /*  this.tpRequestForm.patchValue({
       lrDate: event.value
     }); */

  }
  setInitialValue() {
    this.filteredRoutes
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleRouteSelect.compareWith = (a, b) => a && b && a === b;
      });

  }
  updateTPRequest() {


    this.saveDisabled = true;
    let data = {
      "route": this.tpRequestForm.get('route').value,
      "consignmentType": this.tpRequestForm.get('consignmentType').value,
      "tpNo": this.data.tpNo,
      "vehicleNo": this.tpRequestForm.get('vehicleNo').value,
      "estimatedDeliveryTime": this.tpRequestForm.get('estimatedDeliveryTime').value,
      "transporterDetails": [{

        invoiceNo: this.tpRequestForm.get('invoiceNo').value,
        lrDate: this.tpRequestForm.get('lrDate').value,
        lrNumber: this.tpRequestForm.get('lrNumber').value,
        transporterName: this.tpRequestForm.get('transporterName').value,
      }

      ]

    };

    this.routeList.forEach(route => {

      if (route.routeName == data.route) {
        data['destnEntityId'] = route.destnEntityId;
        data['destnEntityName'] = route.destinationLocation;
        data['distance'] = route.distance;
        data['estimatedTime'] = route.estimatedTime;
        data['routeVia'] = route.routeVia;
        console.log("data['destnEntityId'] :: ", data['destnEntityId'], "route.destnEntityId :: ", route.destnEntityId, "data['destnEntityName'] :: ", data['destnEntityName'], "route.destinationLocation :: ", route.destinationLocation)
      }
    })

    const req_data = {
      action: 'edit_tp',
      data
    };
    console.log(req_data, '106:::');
    this.shipmentsService.updateTPRequest(req_data).subscribe((res: any) => {
      if(res.status === 'SUCCESS'){
        this.dialogRef.close('TP Updated Successfully');
      }
    }, (err) => {
      this.error = err.error.message;
    });

    // this.saveDisabled = true;
    // const obj = [{
    //   _id: this.data._id,
    //   update: this.tpRequestForm.value
    // }]
    // this.spiritService.putBatch(obj).subscribe((res: any) => {
    //   this.dialogRef.close('Batch Udated Successfully');
    // }, (err) => {
    //   this.error = err.error.message;
    // });
  }

}
