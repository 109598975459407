import { MessageService } from 'primeng/api';
import { environment } from './../environments/environment';
import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, merge, Observable, of } from 'rxjs';
import { filter, map, mapTo } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'lapp-gov';
  hideHeader: boolean;
  showFooter: boolean;
  env: any = environment;
  loading$: Observable<boolean> = of(false);
  online$: Observable<boolean>;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private messageService: MessageService,
  ) {
    if(this.env.production === true){
      (window as any).console.log = () => {};
    }
    this.router.events.subscribe((res) => {
      const url = this.router.url.split('?')[0];
      if (url === '/login' || url === '/' || url === '/home' || url === '/login/forgot-password' || url.indexOf('print') >= 0 || url === '/pages/404-page-not-found' || url === '/get_git_status' || url === '/no-internet' || url === '/label') {
        this.hideHeader = false;
        this.showFooter = false;
      } else {
        this.hideHeader = true;
      }
      if(url==='/home'){
        this.showFooter = true;
      }
    });
    translate.addLangs(['en', 'te']);
    if (sessionStorage.getItem('locale')) {
      const browserLang = sessionStorage.getItem('locale');
      translate.use(browserLang.match(/en|te/) ? browserLang : 'en');
    } else {
      sessionStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }
  }

  ngOnInit(): any {
    this.hideHeader = false;
    this.getRouterSpinner();
    this.isOnline();
  }
  @HostListener('document:contextmenu', ['$event'])
  onDocumentRightClick(e: any): any {
    if (this.env.production) {
      this.messageService.add({severity: 'error', summary: '', detail: 'Due to security reason, Right Click is not allowed.'});
      return false;
     }
  }
  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): any {
    if (this.env.production) {
      if (event.getModifierState) {
        const shift = event.getModifierState('Shift');
        const ctrl = event.getModifierState('Control');
        if ((shift && ctrl) || event.keyCode === 123) {
          this.messageService.add({severity: 'error', summary: '', detail: 'Due to security reason, Inspect is not allowed.'});
          event.preventDefault();
        }
      }

    }
  }
  getRouterSpinner(): any {
    this.loading$ = this.router.events.pipe(
      filter(
        (e) =>
          e instanceof NavigationStart ||
          e instanceof NavigationEnd ||
          e instanceof NavigationCancel ||
          e instanceof NavigationError
      ),
      map((e) => e instanceof NavigationStart)
    );
  }
  isOnline(): any {
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );
    this.online$.subscribe(e => {
      if (e === false) {
        this.router.navigate(['/no-internet']);
      }
    });
  }
}
