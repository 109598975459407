import { ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonsService } from 'src/app/core/services/commons.service';
import { MessageService } from 'primeng/api';
import { ThroughTransportPermitService } from './../../../core/services/through-transport-permit.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-manage-ttp-users-dialog',
  templateUrl: './manage-ttp-users-dialog.component.html',
  styleUrls: ['./manage-ttp-users-dialog.component.scss']
})
export class ManageTtpUsersDialogComponent implements OnInit {
  
  @ViewChild('ttpHtmlForm') ttpHtmlForm: any;
  ttpForm: FormGroup;
  userInfo: any;
  loader: any = false;
  locations: any = [];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ManageTtpUsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private ttpService: ThroughTransportPermitService,
    private messageService:MessageService,
    private spinner: NgxSpinnerService,
    public util: UtilService
  ) {
    this.userInfo = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
  }
  ngOnInit(): void {
    this.ttpForm = this.fb.group({
      userName: ['', Validators.required],
      fullName: ['', Validators.required],
      password: ['', Validators.required],
      emailId: ['', Validators.required],
      number: ['', Validators.required],
      locationCode: ['', Validators.required],
    });
    
    this.getLocation();
  }
  getFormData(): any {
    this.ttpForm.patchValue({
      userName: this.data.userName,
      fullName: this.data.fullName,
      password: '',
      emailId: this.data.emailIds.length && this.data.emailIds[0].emailId,
      mobileNumber: this.data.mobileNumbers.length && this.data.mobileNumbers[0].number,
      locationCode: this.data.locationCode,
    });
    this.ttpForm.controls.password.setValidators(null);
    this.ttpForm.controls.password.updateValueAndValidity();
    this.ttpForm.disable();
  }
  getLocation(): any {
    const req_Data = {
      action: 'get_ttp_location',
    }
    this.loader = true;
    this.locations = [];
    this.spinner.show();
    this.ttpService.getMethod(req_Data).subscribe((res: any) => {
      if (res && res.get_ttp_location) {
        this.locations = res.get_ttp_location;
        if (this.data && this.data.userName) {
          this.getFormData();
        }
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'TTP Location Fetch Failed' });
      }
      this.loader = false;
      this.spinner.hide();
    }, (err) => {
      this.loader = false;
      this.spinner.hide();
    });
  }
  getLocationName(locationCode): any {
    const loc: any = this.locations.find(el => el.locationCode === locationCode);
    return loc ? loc?.locationName : '';
  }
  create() {
    if (this.ttpForm.status === 'VALID') {
      const req_Data = {
        action: 'add_ttp_users',
        data: this.ttpForm.value
      }
      req_Data.data.password = this.util.encryptPassword(this.ttpForm.value.password);
      req_Data.data.entityId = req_Data.data.userName;
      this.loader = true;
      this.spinner.show();
      this.ttpService.postMethod(req_Data).subscribe((res: any) => {
        if (res && res.status === 'SUCCESS') {
          this.dialogRef.close('TTP User Created Successfully');
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'TTP User Creation Failed' });
        }
        this.loader = false;
        this.spinner.hide();
      }, (err) => {
        this.loader = false;
        this.spinner.hide();
      });
    }
  }
  update(): any {
    if (this.ttpForm.status === 'VALID') {
      const req_Data = {
        action: 'update_ttp_users',
        data: this.ttpForm.value
      }
      delete req_Data.data.password;
      this.loader = true;
      this.spinner.show();
      this.ttpService.putMethod(req_Data).subscribe((res: any) => {
        if (res && res.status === 'SUCCESS') {
          this.dialogRef.close('TTP Users Updated Successfully');
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'TTP Users Update Failed' });
        }
        this.loader = false;
        this.spinner.hide();
      }, (err) => {
        this.loader = false;
        this.spinner.hide();
      });
    }
  }

  resetForm(): any {
    this.ttpHtmlForm.nativeElement.reset();    
  }

}
