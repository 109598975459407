import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PurchaseOrder } from '../models/shipments/purchase-order.model';
import { Ofs } from '../models/supply-delivery/ofs';
import { StockIn } from '../models/shipments/stock-in.model';
import { StockReturn } from '../models/shipments/stock-return.model';
import { TPRevalidation } from '../models/shipments/tp-revalidation.model';
import { TransportPermit } from '../models/supply-delivery/transport-permit';
import { Issue } from '../models/shipments/issue.model';
import { Route } from '@angular/compiler/src/core';
import { TPVehicleAccident } from '../models/shipments/tp-vehicle-accident.model';
import { Shipments } from '../models/shipments/shipments.model';

const baseUrl = environment.baseUrl;
const shipmentUrl = baseUrl + 'shipment';
const updateShipmentUrl = baseUrl + 'update_shipment';
const manualShipmentUrl = baseUrl + 'manual_shipment';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Shipment'] && userModules.mod_feats['Shipment'].features ? userModules.mod_feats['Shipment'].features.map(feat => feat.feature_name) : [];

const ofsUrl = baseUrl + 'shipments/orderforsupply';
const transportPermitUrl = baseUrl + 'shipments/transportpermits';
const poUrl = baseUrl + 'shipments/purchaseorders';
const stockInUrl = baseUrl + 'shipments/transportpermits';
const stockReturnUrl = baseUrl + 'stockreturns';
const tPRevalidationUrl = baseUrl + 'delayedtransportpermits';
const retailerIndentsUrl = baseUrl + 'shipments/retailerindents';
const issuesUrl = baseUrl + 'shipments/issues';
const icdcUrl = baseUrl + 'shipments/indentcumdeliverychallans';
const routeUrl = baseUrl + 'routes';
const shipmentsUrl = baseUrl + 'transportpermits';
const tpVehicleUrl = baseUrl + 'vehicleaccidents';
const tpRequestUrl = baseUrl + 'tprequest';
const transferShipmentUrl= baseUrl + 'transfershipment'


@Injectable({
  providedIn: 'root'
})
export class ShipmentsService {

  constructor(private http: HttpClient) { }

  createOfs(ofsObj){
    return this.http.post(ofsUrl, ofsObj)
  }

  repackBottle(repackObj): Observable<any> {
    return this.http.put(shipmentUrl, repackObj)
  }

  approveEvc(evcObj): Observable<any> {
    return this.http.put(shipmentUrl, evcObj)
  }

  acknowledgeSRA(sraObj): Observable<any> {
    return this.http.put(shipmentUrl, sraObj)
  }

  getInvoice(params) {
    return this.http.get(shipmentUrl, {params});
  }

  submitCaseCode(caseObj) {
    return this.http.put(shipmentUrl, caseObj)
  }

  generateActions(payload) {
    return this.http.put(shipmentUrl, payload)
  }

  getOfs(q, action){
    const paramObj: any = {};
    q ? (paramObj.q = q) : (q = null);
    action ? (paramObj.action = action) : (action = null);
    return this.http.get(shipmentUrl, {params: paramObj});
  }

  getTP(q, action){
    const paramObj: any = {};
    q ? (paramObj.q = q) : (q = null);
    action ? (paramObj.action = action) : (action = null);
    return this.http.get(shipmentUrl, {params: paramObj});
  }

  putOfs(ofsObj){
    return this.http.put<Ofs>(`${shipmentUrl}`, ofsObj);
  }

  scanBarCode(caseObj): Observable<any> {
    return this.http.put(`${shipmentUrl}`, caseObj);
  }

  postTpComplete(tpObj): Observable<any> {
    return this.http.put(`${shipmentUrl}`, tpObj);
  }
  addOfidt(ofidtPayload): any {
    return this.http.put(`${shipmentUrl}`, ofidtPayload);
  }

  validityDateTime(valObj):Observable<any> {
    return this.http.put(`${shipmentUrl}`, valObj);
  }
  createPurchaseOrder(poObj: any){
    return this.http.post(poUrl, poObj);
  }

  getPurchaseOrder(offset, limit, search?: string):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? paramObj.offset = offset : offset = null;
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    return this.http.get(poUrl, {params: paramObj});
  }

  putPurchaseOrder(poObj){
    return this.http.put<PurchaseOrder>(`${poUrl}`, poObj);
  }

  saveShipmentQuantity(shipmentQtyObj):Observable<any> {
    return this.http.put(`${shipmentUrl}`, shipmentQtyObj);

  }

  createStockIn(stockInObj: StockIn){
    return this.http.post(stockInUrl, stockInObj);
  }

  getStockIn(offset, limit, incoming?: boolean, search?: string):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    incoming ? paramObj.incoming = incoming : incoming = null;
    return this.http.get(stockInUrl, {params: paramObj});
  }

  putStockIn(stockInObj){
    return this.http.put<StockIn>(`${stockInUrl}`, stockInObj);
  }

  createStockReturn(stockReturnObj: StockReturn){
    return this.http.post(stockReturnUrl, stockReturnObj);
  }

  getStockReturn(offset, limit, search?: string):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    return this.http.get(stockReturnUrl, {params: paramObj});
  }

  putStockReturn(stockReturnObj){
    return this.http.put<StockReturn>(`${stockReturnUrl}`, stockReturnObj)
  }

  createTPRevalidation(tPRevalidationObj: TPRevalidation){
    return this.http.post(tPRevalidationUrl, tPRevalidationObj);
  }

  getTPRevalidation(offset, limit, search?: string):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    return this.http.get(tPRevalidationUrl, {params: paramObj});
  }

  putTPRevalidation(tPRevalidationObj){
    return this.http.put<TPRevalidation>(`${tPRevalidationUrl}`, tPRevalidationObj);
  }

  getRetailerIndents(offset, limit, search?: string, incoming?: boolean):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? paramObj.offset = offset : offset = null;
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    incoming ? paramObj.incoming = incoming : incoming = null;
    return this.http.get(retailerIndentsUrl);
  }

  // createRetailerIndent(riObj){
  //   return this.http.post(shipmentUrl, riObj);
  // }

  createTransportPermit(transportPermitObj){
    return this.http.post(transportPermitUrl, transportPermitObj);
  }

  getTransportPermit(offset, limit, incoming?: boolean, search?: string):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? paramObj.offset = offset : offset = null;
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    incoming ? paramObj.incoming = incoming : incoming = null;
    return this.http.get(transportPermitUrl, {params: paramObj});
  }

  putTransportPermit(transportPermitObj){
    return this.http.put<TransportPermit>(`${transportPermitUrl}`, transportPermitObj)
  }

  createIssue(issueObj){
    return this.http.post(issuesUrl, issueObj);
  }

  getIssue(offset, limit, search?: string):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? paramObj.offset = offset : offset = null;
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    return this.http.get(issuesUrl, {params: paramObj});
  }

  putIssue(issueObj){
    return this.http.put<Issue>(`${issuesUrl}`, issueObj);
  }

  createICDC(icdcObj){
    return this.http.post(shipmentUrl, icdcObj);
  }

  postMethod(req){
    return this.http.post(shipmentUrl, req);
  }

  getICDC(params):Observable<any>{
    return this.http.get(shipmentUrl, {params});
  }

  putICDC(icdcObj){
    return this.http.put(`${shipmentUrl}`, icdcObj);
  }

  getTpVehicleAccidentDetails(offset, limit, search) {
    return this.http.get(tpVehicleUrl);
  }

  getShipmentsDetails(offset, limit, search) {
    return this.http.get('assets/samples/shipments.json');
  }

  getRouteDetails(offset, limit, search) {
    return this.http.get(routeUrl);
  }

  getTPRevalidationDetails(offset, limit, search){
    return this.http.get('assets/samples/tp-revalidation.json');
  }

  getOfsDetails(offset, limit, search){
    return this.http.get('assets/samples/shipments.json');
  }

  createRoute(obj: any){
    return this.http.post(shipmentUrl, obj);
  }

  createTPVehicle(obj: any){
    return this.http.put(shipmentUrl, obj);
  }

  createTPRequest(obj: any){
    return this.http.post(shipmentUrl, obj);
  }

  updateTPRequest(obj: any){
    return this.http.put(shipmentUrl, obj);
  }

  getSupplierOfsDetails(offset, limit, search) {
    return this.http.get('assets/samples/supplier-ofs.json');
  }

  getSupplierShipmentsDetails(offset, limit, search) {
    return this.http.get('assets/samples/supplier-shipments.json');
  }

  getRetailerShipmentsDetails(offset, limit, search) {
    return this.http.get('assets/samples/retailer-shipments.json');
  }

  getGeneratedListDetails(offset, limit, search) {
    return this.http.get('assets/samples/generated-list.json');
  }

  getStockReturnDetails(offset, limit, search) {
    return this.http.get('assets/samples/stock-return.json');
  }

  getUpdateIndentsDetails(offset, limit, search) {
    return this.http.get('assets/samples/update-indents.json');
  }

  getDistilleryShipment(offset, limit, search?: string):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    return this.http.get('assets/samples/distillery-shipment.json', {params: paramObj});
  }

  getDepotShipment(offset, limit, search?: string):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    return this.http.get('assets/samples/depot-shipment.json', {params: paramObj});
  }

  transferShipment(obj){
    return this.http.post(transferShipmentUrl, obj);
  }

  getShipmentsFeatures(paramObj: any = '') {
    const headerDict = {
      feature
    };
    // return this.http.get(shipmentUrl, { headers: headerDict });
    return this.http.get(shipmentUrl, { headers: headerDict, params: paramObj });
  }

  revertRequest(ObjPayload) {
    return this.http.put(shipmentUrl, ObjPayload)
  }
  updateShipment(ObjPayload): any {
    return this.http.put(shipmentUrl, ObjPayload);
  }
  f2Breakage(ObjPayload): any {
    return this.http.put(shipmentUrl, ObjPayload);
  }
}
