import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ProductsService } from 'src/app/core/services/products.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CommonsService } from 'src/app/core/services/commons.service';

@Component({
  selector: 'app-view-price-label-registration',
  templateUrl: './view-price-label-registration.component.html',
  styleUrls: ['./view-price-label-registration.component.scss']
})
export class ViewPriceLabelRegistrationComponent implements OnInit {

  priceDatas: any = [];
  displayedColumns: string[] = [
    'no',
    'price',
    'amount'
  ];
  pricingData:any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,private productsService: ProductsService,
    private dialogRef: MatDialogRef<ViewPriceLabelRegistrationComponent>,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private commonsService: CommonsService,

  ) { }

  ngOnInit(): void {
    if(this.data.updatePrice){
      this.getPriceData();
      
    }else{
      Object.keys(this.data.datas).forEach(key => {
        this.priceDatas.push({keys: key, values: this.data.datas[key]});
      });
      this.dataSource = new MatTableDataSource(this.priceDatas);
      this.dataSource.paginator = this.paginator;
    }


  }
  getPriceData(){
    this.priceDatas = [];
    const params: any = {
      supplier_code: this.data.supplierCode,
      brandCode: this.data.brandCode,
      liquorType: this.data.liquorType,
      size: this.data.sizeCode,
      strength: this.data.strength,
      price: this.data.basicPricing[0]['price'],
    };
    if(this.data.productType === 'Foriegn' || this.data.productType === 'FORIEGN'){
      params.customDuty = this.data.pricing[0]['customDuty']
    }
    
    const qData = {
      data: {
        action: this.data.liquorType === 'BEER' ? 'beer_pricing' : (this.data.productType === 'Foriegn' || this.data.productType === 'FORIEGN') ?  'fl_pricing' : this.data.liquorType === 'WINE' ? 'get_pricing_wine' : 'get_pricing',
        value: [params]
      }
    };

    const req_Data = {
      action: 'common_apis',
      q: JSON.stringify(qData)
    };
    console.log(req_Data)
    this.commonsService.getCommonEntites(req_Data).subscribe((res:any) => {
      if (res.get_pricing) {
        Object.keys(res.get_pricing).forEach(key => {
          this.priceDatas.push({keys: key, values: res.get_pricing[key]});
        });
        this.dataSource = new MatTableDataSource(this.priceDatas);
        this.dataSource.paginator = this.paginator;
      }
      else if(res && res.beer_pricing){
        Object.keys(res.beer_pricing).forEach(key => {
          this.priceDatas.push({keys: key, values: res.beer_pricing[key]});
        });
        this.dataSource = new MatTableDataSource(this.priceDatas);
        this.dataSource.paginator = this.paginator;
      }else if(res && res.fl_pricing){
        Object.keys(res.fl_pricing).forEach(key => {
          this.priceDatas.push({keys: key, values: res.fl_pricing[key]});
        });
        this.dataSource = new MatTableDataSource(this.priceDatas);
        this.dataSource.paginator = this.paginator;
      }else if(res && res.get_pricing_wine){
        Object.keys(res.get_pricing_wine).forEach(key => {
          this.priceDatas.push({keys: key, values: res.get_pricing_wine[key]});
        });
        this.dataSource = new MatTableDataSource(this.priceDatas);
        this.dataSource.paginator = this.paginator;
      }
    });


  }
  verifyMrp(status){
    const req_Data:any={
      action:'pricing_verify',
      data:{"productCode":this.data.productCode,"verifyStatus":status}
    }
    this.spinner.show();
    this.productsService.putAPi(req_Data).subscribe((res:any)=>{
      if(res && res.status === 'SUCCESS'){
        this.dialogRef.close('Updated Successfully');
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Updation Failed' });
      }
      this.spinner.hide();
      
    },(err)=>{
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Updation Failed' });
      this.spinner.hide();
    })
  }
}

