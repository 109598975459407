import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-source-case-details-dialog',
  templateUrl: './source-case-details-dialog.component.html',
  styleUrls: ['./source-case-details-dialog.component.scss']
})
export class SourceCaseDetailsDialogComponent implements OnInit {
  healCodeData: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialogRef: MatDialogRef<SourceCaseDetailsDialogComponent>) { }

  ngOnInit(): void {
    console.log(this.data,"14::::")
    this.healCodeData = this.data;
  }

}
