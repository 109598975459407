import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CommonsService } from 'src/app/core/services/commons.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { IndentManagementService } from 'src/app/core/services/indent-management.service';
import { DynamicConfirmationDialogComponent } from 'src/app/core/components/dynamic-confirmation-dialog/dynamic-confirmation-dialog.component';

@Component({
  selector: 'app-retailer-challan',
  templateUrl: './retailer-challan.component.html',
  styleUrls: ['./retailer-challan.component.scss']
})
export class RetailerChallanComponent implements OnInit {

  retChallanForm: FormGroup;
  @ViewChild('form') form;
  minDate: any;
  maxDate: any;

  constructor(
    private fb: FormBuilder,
    public util: UtilService,
    private commonService: CommonsService,
    private indentManagementService: IndentManagementService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<RetailerChallanComponent>
  ) { }
  ngOnInit(): void {
    this.minDate = this.util.getDateOnly(new Date('2023-12-12'));
    this.maxDate = this.util.getDateOnly(new Date('2023-12-17'));
    // const maxDate = this.util.getPastDateByDayCount(new Date(), 1);
    // this.maxDate = this.util.getDateOnly(maxDate);
    this.retChallanForm = this.fb.group({
      challanDate: ['', [Validators.required, Validators.min(this.minDate), Validators.max(this.maxDate)]],
      amount: ['', [Validators.required]],
    });
    if (this.data && this.data.status === 'Created') {
      this.retChallanForm.patchValue({
        challanDate: this.data.challanDate,
        amount: this.data.amount
      });
      this.retChallanForm.controls.challanDate.disable()
    }
  }
  dialogConfirm(): any {
    const data: any = {};
    data.content = `continue with the amount ₹${this.retChallanForm.controls.amount.value} ..?`;
    const dialogRef = this.dialog.open(DynamicConfirmationDialogComponent, {
      width: '25%',
      data:data,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.message === 'Success') {
        if (this.data && this.data.status === 'Created') {
          this.update();
        } else {
          this.submit();
        }
      }
    });
  }
  submit(): any {
    const req_data = {
      action: 'create_cfms_payment_challan',
      data: this.retChallanForm.value
    };
    req_data.data.challanDate = this.util.getDateOnly(req_data.data.challanDate);
    this.spinner.show();
    this.indentManagementService.createRetailerIndent(req_data).subscribe((res: any) => {
      if (res && res.error) {
        this.messageService.add({ severity: 'error', detail: res.error});
      } else if (res.status && res.status === 'SUCCESS') {
        this.dialogRef.close('Created Successfully');
      } else {
        this.messageService.add({ severity: 'error', detail: 'Error'});
        this.reset();
      }
      this.spinner.hide();
    }, err => {
      this.messageService.add({ severity: 'error', detail: 'Error'});
      this.reset();
      this.spinner.hide();
    });
  }
  update(): any {
    const req_data = {
      action: 'update_cfms_amount',
      data: {
        indentId: this.data.indentId,
        amount: this.retChallanForm.controls.amount.value
      }
    };
    this.spinner.show();
    this.indentManagementService.updateIndents(req_data).subscribe((res: any) => {
      if (res && res.status && res.status.error) {
        this.messageService.add({ severity: 'error', detail: res.status.error});
      } else if (res.status && res.status === 'SUCCESS') {
        this.dialogRef.close('Updated Successfully');
      } else {
        this.messageService.add({ severity: 'error', detail: 'Error'});
        this.reset();
      }
      this.spinner.hide();
    }, err => {
      this.messageService.add({ severity: 'error', detail: 'Error'});
      this.reset();
      this.spinner.hide();
    });
  }
  reset(): any {
    this.form.nativeElement.reset();
  }
  print() {
    let printContents, popupWin;
    printContents = document.getElementById("print-section-ret-cfms-challan-payment").innerHTML;
    popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
    popupWin.document.write(`${printContents}`);
    popupWin.document.close();
  }
  dialogClose(): void{
    this.dialogRef.close();
  }

}
