import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const featurePermissionUrl = baseUrl + 'feature_permissions';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Feature Permissions'] && userModules.mod_feats['Feature Permissions'].features ? userModules.mod_feats['Feature Permissions'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class FeaturePermissionService {

  constructor(private http: HttpClient) { }

  getFeaturePermissionFeatures() {
    const headerDict = {
      feature
    };
    return this.http.get(featurePermissionUrl, { headers: headerDict });
  }
  createFeaturePermissionFeatures(obj) {
    return this.http.post(featurePermissionUrl, obj);
  }
  editFeaturePermissionFeatures(obj) {
    return this.http.put(featurePermissionUrl, obj);
  }

  getActions(actionData) {
    console.log('actionData', actionData);
    return this.http.get(featurePermissionUrl, { headers: actionData });
  }
}
