import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { ReplaySubject, Subject } from 'rxjs';
import { CommonsService } from 'src/app/core/services/commons.service';
import { UserManualService } from 'src/app/core/services/user-manual.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { OpenSupplierDialogComponent } from '../../supplier/open-supplier-dialog/open-supplier-dialog.component';

@Component({
  selector: 'app-open-depot-dialog',
  templateUrl: './open-depot-dialog.component.html',
  styleUrls: ['./open-depot-dialog.component.scss']
})
export class OpenDepotDialogComponent implements OnInit {
  depotForm: FormGroup;
  roles: any;
  depots = [];
  filteredRoles: any;
  isPassword: boolean = true;
  titles = ['Mr.', 'Mrs.', 'Ms.'];
  isConfirmPassword: boolean = true;
  loader: any = false;
  public depotFilterCtrl: FormControl = new FormControl();
  filteredDepots: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);


  constructor(private fb: FormBuilder, public util: UtilService, private dialogRef: MatDialogRef<OpenSupplierDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,private messageService: MessageService, private CommonsService: CommonsService,
    private userManualService: UserManualService) {

  }

  ngOnInit(): void {
    this.getDepots();
    this.getDepotRoles();

    this.depotForm = this.fb.group({
      title: ['', [Validators.required,]],
      fullName: ['', [Validators.required,]],
      emailId: [''],
      userName: ['', [Validators.required,]],
      password: ['', [Validators.required,]],
      confirmPassword: ['', [Validators.required,]],
      depotCode: ['', [Validators.required,]],
      role: ['', [Validators.required,]],
      mobileNo: [''],
      depotFilter: ''
    }, {
      validators: this.confirmPasswordmatch('password', 'confirmPassword')
    });
    this.util.matSelectFilter(this, "depotFilterCtrl", "filteredDepots", "depots");
  }


  getDepots() {
    let paramObj = {
      action: 'common_apis',
      q: '{"data": ["get_depots"]}'
    }
    this.CommonsService.getCommonEntites(paramObj).subscribe((res) => {
      this.depots = res['get_depots'].map((depot) => depot.depotCode);
      this.filteredDepots.next(this.depots);
    });
  }

  getDepotRoles() {
    let paramObj = {
      action: 'common_apis',
      q: '{"data": ["get_depot_roles"]}'
    }
    this.CommonsService.getCommonEntites(paramObj).subscribe((res) => {
      this.roles = res['get_depot_roles'];
      this.filteredRoles = JSON.parse(JSON.stringify(this.roles));      
    });
  }

  confirmPasswordmatch(controlName: string, matchingControlName: string) {
    return (fromGroup: FormGroup) => {
      const control = fromGroup.controls[controlName];
      const matchingControl = fromGroup.controls[matchingControlName];
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmPasswordmatch: true });
      }
      else {
        matchingControl.setErrors(null);
      }
    }
  }

  createDepot() {
    this.depotForm.patchValue({
      createdOn: new Date().toISOString()
    });
    const plObj = this.depotForm.value;
    const req_Data = {
      action: 'add_depot_user',
      data: {
        ...plObj,
        number: plObj.mobileNo
      }
    }
    req_Data.data['activeUsers'] = this.util.encryptPassword(req_Data.data.password);
    delete req_Data.data.confirmPassword;
    delete req_Data.data.password;
    delete req_Data.data.mobileNo;
    delete req_Data.data.depotFilter;
    this.loader = true;
    this.userManualService.createDepot(req_Data).subscribe((res: any) => {
      if (res.status && res.status != 'User already exists') {         
        this.dialogRef.close('User Created Sucessfully');
        this.loader = false;
      } else {
        this.messageService.add({ severity: 'error', detail: 'User already exists' });
        this.loader = false;
      }
    }, (err) => {
      this.messageService.add({ severity: 'error', detail: err.error.message });
      this.loader = false;
    });
  }

  showPassword() {
    this.isPassword = !this.isPassword;
  }

  showConfirmPassword() {
    this.isConfirmPassword = !this.isConfirmPassword;
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

}