import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { ReleaseNotesService } from './../../../core/services/release-notes.service';
import { CommonsService } from 'src/app/core/services/commons.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
// declare const UIkit: any;

@Component({
  selector: 'app-manage-release-notes-dialog',
  templateUrl: './manage-release-notes-dialog.component.html',
  styleUrls: ['./manage-release-notes-dialog.component.scss']
})
export class ManageReleaseNotesDialogComponent implements OnInit {

  @ViewChild('cont') cont;
  releaseNotesForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  loader: any = false;
  entityTypesDatas: any = [];
  roles: any = [];
  entityTypes: FormArray;
  selectedRoles: any = [];
  contents: any = [];
  step: any = -1;
  releaseTypes: any = ['Major', 'Minor', 'Patch'];
  previousVersion: any = '';
  previousMobVersion: any = '';
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    // toolbarHiddenButtons: [
    //   ['bold', 'italic'],
    //   ['fontSize']
    // ]
  };
  isDisabled: any = false;
  roleName: any = '';

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ManageReleaseNotesDialogComponent>,
    private releaseNotesService: ReleaseNotesService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private commonsService: CommonsService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    console.log('abcdef', this.data);
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    this.setConfig();
    if (!this.data.status && this.data.data.length === 0) {
      this.previousVersion = '0.0.0';
    }
    else if (!this.data.status && this.data.data.length > 0) {
      this.previousVersion = this.data.data[this.data.data.length - 1].versionNo;
      // if (this.data.featureName === 'Web') {
        // this.previousVersion = this.data.data[this.data.data.length - 1].versionNo;
      // } else if (this.data.featureName === 'Mobile') {
        // this.previousVersion = this.data.data[this.data.data.length - 1].versionNo;
      // }
    }
    this.getEntityRoles();
    this.releaseNotesForm = this.fb.group({
      browserType: [this.data.featureName, Validators.required],
      releaseType: ['', Validators.required],
      versionNo: ['', Validators.required],
      selectedEntityTypes: [''],
      entityTypes: this.fb.array([]),
    });
    this.entityTypes = this.releaseNotesForm.get('entityTypes') as FormArray;
  }

  get f() {
    return this.releaseNotesForm.controls;
  }

  editEntities(): any {
    if (this.data && this.data.status) {
      this.releaseNotesForm.patchValue({
        browserType: this.data.browserType,
        versionNo: this.data.versionNo,
        releaseType: this.data.releaseType,
        selectedEntityTypes: '',
        // entityTypes: this.data.entityTypes,
      });
      // const entityTypes = this.releaseNotesForm.get('entityTypes') as FormArray;
      this.entityTypes.clear();
      this.data.entityTypes.forEach((e, i) => {
        this.editEntityType(e, i);
        // this.entityTypes.push(
        //   this.fb.group({
        //     entityType: e.entityType,
        //     roles: [e.roles],
        //     content: e.content,
        //   })
        // );
      });
      // this.isDisableEntity();
    }
    this.spinner.hide();
  }

  createEntityType(entityType: any = ''): any {
    if (entityType !== '') {
      const control = <FormArray>this.releaseNotesForm.controls['entityTypes'];
      control.push(this.setEntityType(entityType));
      this.getRoles(entityType);
      this.isDisableEntity();
      this.step++;
    }
  }
  async editEntityType(e, i): Promise<any> {
    // const control = <FormArray>this.releaseNotesForm.controls['entityTypes'];
    // control.push(this.updateEntityType(entityType));
    console.log('e', e);
    if (this.data.browserType === 'Web') {
      await this.entityTypes.push(
        this.fb.group({
          entityType: e.entityType,
          roles: [e.roles],
          content: e.content,
        })
      );
    } else if (this.data.browserType === 'Mobile') {
      await this.entityTypes.push(
        this.fb.group({
          entityType: e.entityType,
          roles: [e.roles],
          title: e.title,
          content: e.content,
        })
      );
    }
    this.getRoles(e.entityType, i);
    this.isDisableEntity();
    this.step++;
    console.log(this.releaseNotesForm);
  }

  setEntityType(entityType: any = ''): any {
    if (this.data.featureName === 'Web' || this.data.browserType === 'Web') {
      return this.fb.group({
        entityType: [entityType, Validators.required],
        roles: [[], Validators.required],
        content: ['', Validators.required],
      }); 
    } else if (this.data.featureName === 'Mobile' || this.data.browserType === 'Mobile') {
      return this.fb.group({
        entityType: [entityType, Validators.required],
        roles: [[], Validators.required],
        title: ['', Validators.required],
        content: ['', Validators.required],
      }); 
    } 
  }

  getEntityRoles(): any {
    this.spinner.show();
    const reqData = {
      action: 'common_apis',
      q: '{"data": {"action":"get_all_roles_by_entity_type", "value": "' + '' + '"}}'
    }
    this.commonsService.getCommonEntites(reqData).subscribe((res: any) => {
      if (res && res.get_all_roles_by_entity_type) {
        this.entityTypesDatas = res.get_all_roles_by_entity_type;
        this.editEntities();
        // console.log(this.entityTypesDatas);
      }
    }, (err) => {
      this.messageService.add({ severity: 'error', detail: 'Something went wrong' });
      this.error = err.error.message;
    });
  }
  // selectAll(i) {
  //   console.log(this.selectedRoles);
  //   const isExist = this.selectedRoles[i].findIndex(el => el === 'All');
  //   if (isExist !== -1) {
  //     this.selectedRoles[i] = this.roles[i];
  //     this.selectedRoles[i] = ['All', ...this.selectedRoles]
  //   } else {
  //     this.selectedRoles[i] = [];
  //   }
  // }
  // uncheckIsAll(i): any {
  //   console.log(this.selectedRoles[i]);
  //   if (this.selectedRoles && this.selectedRoles[i].length) {
  //     this.selectedRoles[i] = this.selectedRoles[i].filter(el => el !== 'All');
  //   }
  // }

  async getRoles(value, i = ''): Promise<any> {
    const entityTypes = this.releaseNotesForm.get('entityTypes') as FormArray;
    const len = i === '' ? entityTypes.length - 1 : i;
    if (!this.roles[len]) {
      this.roles[len] = [];
    }
    let roles: any = await this.entityTypesDatas && this.entityTypesDatas.filter(el => el.entityType === value);
    console.log('aaroles', roles);
    if (roles && roles.length) {
      roles = [...new Set(roles[0].roleName)];
      this.roles[len] = roles;
    }
    console.log('this.roles', this.roles);
  }
  isDisableEntity(): any {
    const entityTypes = this.releaseNotesForm.get('entityTypes') as FormArray;
    // console.log('entityTypes', entityTypes);
    this.entityTypesDatas.forEach(element => {
      if (entityTypes.value.findIndex(el => el.entityType === element.entityType) !== -1) {
        element.disabled = true;
      }
    });
  }
  removeDisabled(name): any {
    this.entityTypesDatas.forEach(element => {
      if (element.entityType === name) {
        element.disabled = false;
      }
    });
  }
  setConfig(): any {}
  async create(): Promise<any> {
    this.loader = true;
    this.spinner.show();
    const errorsMsgs$: any = this.setContent();
    let errorMsgs: any = 0;
    await errorsMsgs$.then(e => {
      errorMsgs = e.length;
      console.log('errorMsgs', errorMsgs);
    });
    console.log('errorMsgs----', errorMsgs);
    if (errorMsgs > 0) {
      return;
    }
    const req_data = {
      action: 'add_release_notes',
      data: this.releaseNotesForm.value      
    };
    this.releaseNotesService.createMethod(req_data).subscribe((res: any) => {
      this.loader = false;
      this.spinner.hide();
      if (res && res.status === 'SUCCESS') {
        this.dialogRef.close('Created Successfully');
      } else {
        this.messageService.add({ severity: 'error', detail: 'Not created' });
      }
    }, (err) => {
      this.loader = false;
      this.spinner.hide();
      this.messageService.add({ severity: 'error', detail: 'Something went wrong' });
      this.error = err.error.message;
    });
  }
  update() {
    const req_data = {
      action: 'update_release_notes',
      data: this.releaseNotesForm.value      
    };
    this.loader = true;
    this.spinner.show();
    this.releaseNotesService.putMethod(req_data).subscribe((res: any) => {
      this.loader = false;
      this.spinner.hide();
      if (res && res.status === 'SUCCESS') {
        this.dialogRef.close('Updated Successfully');
      } else {
        this.messageService.add({ severity: 'error', detail: 'Not Updated' });
      }
    }, (err) => {
      this.loader = false;
      this.spinner.hide();
      this.messageService.add({ severity: 'error', detail: 'Something went wrong' });
      this.error = err.error.message;
    });
  }
  remove(i, entityName): any {
    const control = <FormArray>this.releaseNotesForm.controls['entityTypes'];
    this.removeDisabled(entityName);
    this.step--;
    control.removeAt(i);
    this.releaseNotesForm.controls.selectedEntityTypes.setValue('');
  }
  setStep(index: number) {
    this.step = index;
  }
  async setContent(): Promise<any> {
    let errors: any = false;
    const msgs: any = [];
    const control: any = <FormArray>this.releaseNotesForm.controls['entityTypes'];
    await control.controls.forEach((element, i) => {
      console.log('element', element);
      if (document.getElementsByClassName('uk-htmleditor-preview')[i]) {
        const contents = document.getElementsByClassName('uk-htmleditor-preview')[i];
        if (!contents.innerHTML || contents.innerHTML == '<div></div>') {
          element.controls.content.setValue('');
          msgs.push(`${element.controls.entityType.value}`);
          errors = true;
        } else {
          element.controls.content.setValue(contents.innerHTML);
        }
      }
    });
    if (errors) {
      this.messageService.add({ severity: 'error', detail: `Please add content for ${msgs}` });
    }
    return msgs;
  }
  selectVersion(releaseType): any {
    console.log(releaseType);
    const versions = this.previousVersion.split(".");
    console.log(versions);
    const major = parseInt(versions[0], 10);
    const minor = parseInt(versions[1], 10);
    let patch = parseInt(versions[2], 10);
    // patch = patch === 0 ? patch + 1 : 0; 
    if (releaseType === 'Major') {
      // this.releaseNotesForm.controls.versionNo.setValue(`${parseInt(versions[0], 10) + 1}.${versions[1]}.${versions[2] === '0' ? parseInt(versions[2] + 1, 10) : versions[2]}`);
      this.releaseNotesForm.controls.versionNo.setValue(`${major + 1}.${0}.${1}`);
    } else if (releaseType === 'Minor') {
      this.releaseNotesForm.controls.versionNo.setValue(`${major === 0 ? 1 : major}.${minor + 1}.${1}`);
    } else if (releaseType === 'Patch') {
      this.releaseNotesForm.controls.versionNo.setValue(`${major === 0 ? 1 : major}.${minor}.${patch + 1}`);
    }
  }
  // isContentNotExists(): any {
  //   let errors = false;
  //   const control: any = <FormArray>this.releaseNotesForm.controls['entityTypes'];
  //   control.controls.forEach((element, i) => {
  //     console.log('element', element);
  //     if (document.getElementsByClassName('uk-htmleditor-preview')[i]) {
  //       const contents = document.getElementsByClassName('uk-htmleditor-preview')[i];
  //       if (!contents.innerHTML || contents.innerHTML == '<div></div>') {
  //         element.controls.content.setValue('');
  //         // msgs.push(`${element.controls.entityType.value}`);
  //         errors = true;
  //       } else {
  //         element.controls.content.setValue(contents.innerHTML);
  //       }
  //     }
  //   });
  //   return errors;
  // }
  test(): any {
    console.log(this.releaseNotesForm);
  }

}

