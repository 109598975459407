import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-asn-details-for-supplier-shipment-dialog',
  templateUrl: './asn-details-for-supplier-shipment-dialog.component.html',
  styleUrls: ['./asn-details-for-supplier-shipment-dialog.component.scss']
})
export class AsnDetailsForSupplierShipmentDialogComponent implements OnInit {
  productDetails:any;
  totalShipmentQty:any;
  TotalshipmentQtyInBottles:any;
  
  constructor(public dialogRef: MatDialogRef<AsnDetailsForSupplierShipmentDialogComponent>, @Inject(MAT_DIALOG_DATA)public data) { }

  ngOnInit(): void {
    this.productDetails = this.data.consignment[0].productDetails[0];

    this.totalShipmentQty = 0;
    this.TotalshipmentQtyInBottles = 0;
    this.data.consignment.forEach( element => {       
      element.productDetails.forEach( element=> {
        if(this.productDetails.productCode === element.productCode ){
          this.totalShipmentQty = this.totalShipmentQty + element.shipmentQty
          this.TotalshipmentQtyInBottles = this.TotalshipmentQtyInBottles + (element.shipmentQty * element.unitsPerCase)
        }      
      });            
    });
    
   // this.productDetails.shipmentQtyInBottles = this.productDetails.shipmentQty * this.productDetails.unitsPerCase;
  }

  dialogClose(): void{
    this.dialogRef.close();
  }

}
