import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-import-indents-details-dialog',
  templateUrl: './import-indents-details-dialog.component.html',
  styleUrls: ['./import-indents-details-dialog.component.scss']
})
export class ImportIndentsDetailsDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ImportIndentsDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA)public data) { }

  ngOnInit(): void {
  }

  dialogClose(): void{
    this.dialogRef.close();
  }


}
