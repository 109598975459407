import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CommonsService } from 'src/app/core/services/commons.service';
import { IndentManagementService } from 'src/app/core/services/indent-management.service';
import { InterDepotService } from 'src/app/core/services/inter-depot.service';
import { ShipmentsService } from 'src/app/core/services/shipments.service';

@Component({
  selector: 'app-manage-inter-depot-transfer-local',
  templateUrl: './manage-inter-depot-transfer-local.component.html',
  styleUrls: ['./manage-inter-depot-transfer-local.component.scss']
})
export class ManageInterDepotTransferLocalComponent implements OnInit, OnDestroy, AfterViewInit {


  ifsForm: FormGroup;
  productInformation: FormArray;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  public ifsDistilleryFilterCtrl: FormControl = new FormControl();
  public ifsDepotFilterCtrl: FormControl = new FormControl();
  offset: number;
  search: string;
  distilleryNames = [];
  depotNames = [];
  productCodeList = [];
  brandNameList = [];
  batchIdList = [];
  sizeList = [];
  isBrandNameDisable = false;
  isSizeDisable = false;
  isProductCodeDisable = false;
  countCheck:boolean;


  public _onDestroy = new Subject<void>();
  /** list of items filtered by search keyword */
  public filteredDistilleryNames: ReplaySubject<any> = new ReplaySubject<any>(1);
  public filteredDepotNames: ReplaySubject<any> = new ReplaySubject<any>(1);

  @ViewChild('singleDistillerySelect') singleDistillerySelect: MatSelect;
  @ViewChild('singleDepotSelect') singleDepotSelect: MatSelect;
  batchIds: any;
  productInfo: any;
  filteredData: any[];
  productDetails: any;
  roleName:any;
  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageInterDepotTransferLocalComponent>, private interDepotService: InterDepotService,
    @Inject(MAT_DIALOG_DATA) public data,
    private indentManagementService: IndentManagementService,
    private commonsService: CommonsService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    let paramObj = '?action=common_apis&q={"data": ["get_dist_depot","get_prod_info","get_batches"]}';
    this.spinner.show();
    this.commonsService.getList(paramObj).subscribe(data => {
      this.distilleryNames = data['get_dist_depot']['distilleries'];
      this.depotNames = data['get_dist_depot']['depots'];
      this.batchIds = data['get_batches'];
      this.productInfo = data['get_prod_info'];
      this.filteredDepotNames.next(this.depotNames.slice());
      this.filteredDistilleryNames.next(this.distilleryNames.slice());
      this.spinner.hide();

    })
   



    // listen for search field value changes
    this.ifsDistilleryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterDistilleries();
      });

    this.ifsDepotFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterDepots();
      });

    this.ifsForm = this.fb.group({
      
      supplierCode: ['', [Validators.required,]],
      sourceDepotName: ['', [Validators.required,]],
      destinationDepotName: ['', [Validators.required,]],
      indentId: ['', [Validators.required,]],
      productInformation: this.fb.array([this.updateproductInformation()])
    });
    console.log(this.data)
    if (this.data) {
      this.ifsForm.patchValue({
        supplierCode: this.data.supplierCode,
        sourceDepotName: this.data.sourceDepotCode,
        destinationDepotName: this.data.destinationDepotCode,
        indentId: this.data.indentId,
        // ofsValidityDate: this.data.ofsValidityDate,
        // date: this.data.createdOn,
      });
      const productInformation = this.ifsForm.get('productInformation') as FormArray;
      // this.sourceDepotChange('','')
      productInformation.clear();
      this.data.productDetails.forEach(b => {
        productInformation.push(
          this.fb.group({
            size:b.size,
            // batchId:b.batchId,
            // closingBalCases:b.closingBalCases,
            // stockReceiptDateTime:b.stockReceiptDateTime,
            // value:b.value,
            // checkedValue: b.checkedValue,
            productCode: b.productCode,
            brandName: b.brandName,
            requestedCases: Number(b.requestedCases),
            // casePrice: b.casePrice,
            // value: Number(b.requestedQty) * Number(b.basicPricing[0]['price'])
          })
        );
      });
    }
    this.productInformation = this.ifsForm.get('productInformation') as FormArray;
    this.saveDisabled = true;
    // this.getSamplesDetails();
  }


  ngAfterViewInit() {
    this.setInitialValue();

  }
  searchDropDown(event) {
    console.log(event);
  }

  sourceDepotChange(event, filer) {
    const req_Prod = { 
      supplierCode : this.ifsForm.value.supplierCode,
      sourceDepotName : this.ifsForm.value.sourceDepotName
    }

    let qData = {
      "data": { "value": req_Prod, "action": "get_idt_stock" }
    };
    const req_Data = {
      "action": "common_apis",
      "q": JSON.stringify(qData)
    };

    this.commonsService.getCommonEntites(req_Data).subscribe((res) => {
      
        this.productDetails = res["get_idt_stock"];
        this.productDetails?.forEach(productItem => {
          this.productInfo.forEach(product => {
            if(product.productCode === productItem.productCode) {
              Object.assign(product, productItem);
            }
          })
        })
       
      /*   let list = this.productDetails.map(data => data.batchId);
        console.log(res,this.productDetails, "160:::::")
        list.forEach(item => {
          if(this.batchIdList.indexOf(item) == -1) {
            this.batchIdList.push(item);
          }
        }) */

     })
  }

  sourceChange(event, filter) {
    let code = event.value;
    this.filteredData = [];

    //if(!filter) {
    this.filteredData = this.productInfo.filter(info => {
      return info['supplierCode'] == code;
    })
    // }


    // let filteredBatchData = this.batchIds.filter(info => {
    //   console.log(info);
      
    //   return info.supplierCode == code;
    // })

    /*  if(filter) {
        this.filteredData =  this.filteredData.filter(info => {
          return info[filter] == code;
        })
      }
       */
    this.productCodeList = Array.from(new Set(this.filteredData.map(data => data.productCode)));
    this.brandNameList = Array.from(new Set(this.filteredData.map(data => data.brandName)));
    this.brandNameList = this.brandNameList.filter((list) => {
      if (this.brandNameList.indexOf(list) !== this.brandNameList.lastIndexOf(list)) {
        this.brandNameList.splice(this.brandNameList.indexOf(list), 1);
      }
      return this.brandNameList.indexOf(list) == this.brandNameList.lastIndexOf(list)
    })

    this.sizeList = Array.from(new Set(this.filteredData.map(data => data.size)));
    // this.sizeList = this.sizeList.filter((list) => {
    //   return this.sizeList.indexOf(list) == this.sizeList.lastIndexOf(list);
    // })
  // this.batchIdList = Array.from(new Set(filteredBatchData.map(data => data.batchId)));
  }

  setInitialValue() {
    this.filteredDistilleryNames
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleDistillerySelect.compareWith = (a, b) => a && b && a === b;
      });

    this.filteredDepotNames
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleDepotSelect.compareWith = (a, b) => a && b && a === b;
      });
  }

  filterDepots() {
    if (!this.depotNames || (this.depotNames && this.depotNames.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.ifsDepotFilterCtrl.value;
    if (!search) {
      this.filteredDepotNames.next(this.depotNames.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredDepotNames.next(
      this.depotNames.filter(name => name.toLowerCase().indexOf(search) > -1)
    );
  }

  filterDistilleries() {
    if (!this.distilleryNames || (this.distilleryNames && this.distilleryNames.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.ifsDistilleryFilterCtrl.value;
    if (!search) {
      this.filteredDistilleryNames.next(this.distilleryNames.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredDistilleryNames.next(
      this.distilleryNames.filter(name => name.toLowerCase().indexOf(search) > -1)
    );
  }


  batchIdChange(i) {
    const productInformation = (<FormArray>this.ifsForm.get("productInformation")).at(i);

   
    this.productDetails.forEach(prod => {
      if(prod.productCode == this.ifsForm.value.productInformation[i]["productCode"]) {
        productInformation.patchValue({
          stockReceiptDateTime: prod.stockReceiptDateTime,
           closingBalCases: prod.closingBalCases
        })
      }
    })
  }

  productChange(event, i) {
    // const productInformation = this.ifsForm.get('productInformation') as FormArray;
    const productInformation = (<FormArray>this.ifsForm.get("productInformation")).at(i);
    let [bindData] = this.filteredData.filter(data => {
      return data.productCode == event.value;
    })
    this.batchIdList[i] = [];
    this.productDetails.forEach(prod => {
      if(prod.productCode === event.value) {
        this.batchIdList[i].push(prod.batchId);
      }
    })
    // this.data.productInformation.forEach(b => {
    this.isBrandNameDisable = true;
    this.isSizeDisable = true;

    productInformation.patchValue({
      productCode: bindData.productCode,
      brandName: bindData.brandName,
      size: bindData.size,
       batchId: "",
       requestedCases: "",
      casePrice: bindData.basicPricing ? bindData.basicPricing[0].price : ''

    })

    if (productInformation.get('casePrice').value && productInformation.get('requestedCases').value) {
      productInformation.patchValue({
        value: productInformation.get('casePrice').value * productInformation.get('requestedCases').value
      })
    }

    this.validateForm();

  }
  updateproductInformation() {
    return this.fb.group({
      productCode: ['', Validators.required],
      size: ['', Validators.required],
      batchId: [''],
      brandName: ['', Validators.required],
      requestedCases: ['', Validators.required],
      // casePrice: ['', Validators.required],
      // value: ['', Validators.required],
      // closingBalCases: [''],
      // stockReceiptDateTime: ['']
    });
  }

  quantityChange(event, i) {
    const productInformation = (<FormArray>this.ifsForm.get("productInformation")).at(i);
    if(event.target.value > productInformation.value.closingBalCases){
      this.countCheck = true;
    }else{
      this.countCheck = false;
    }
    if (productInformation.get('casePrice').value) {
      productInformation.patchValue({
        value: Number(productInformation.get('casePrice').value) * Number(event.target.value)
      })
    }
    this.validateForm();
  }

  productDataChange(event, i) {
    const productInformation = (<FormArray>this.ifsForm.get("productInformation")).at(i);
    if (event.source.ngControl.name === 'brandName' || event.source.ngControl.name === 'size') {
      let [bindData] = this.filteredData.filter(data => {
        return data[event.source.ngControl.name] == event.value;
      });

      if (event.source.ngControl.name === 'size') {
        productInformation.patchValue({
          productCode: bindData.productCode,
          size: bindData.size,
          // batchId: '',
          requestedCases: '',
          // casePrice: bindData.basicPricing ? bindData.basicPricing[0].price : '',
          // value: ''
        })
      } else {
        productInformation.patchValue({
          productCode: bindData.productCode,
          brandName: bindData.brandName,
          size: bindData.size,
          // batchId: '',
          requestedCases: '',
          // casePrice: bindData.basicPricing ? bindData.basicPricing[0].price : '',
          // value: ''

        })
      }


      if (productInformation.get('casePrice').value && productInformation.get('requestedCases').value) {
        productInformation.patchValue({
          value: productInformation.get('casePrice').value * productInformation.get('requestedCases').value
        })
      }

      this.isProductCodeDisable = true;
    }
    this.validateForm();
  }

  validateForm() {
    const control = <FormArray>this.ifsForm.controls["productInformation"];
    this.saveDisabled = !!control.controls.filter(item => !!Object.keys(item.value).filter(obj =>
      obj !== "batchId" && !item.value[obj]
    ).length).length;

  }

  // isAllSelected() {
  //   const numSelected = this.selection.selected.length;
  //   const numRows = this.dataSource.data.length;
  //   return numSelected === numRows;
  // }

  // removeRow() {
  //   this.selection.selected.forEach(item => {
  //     let index: number = this.data.findIndex(d => d === item);
  //     console.log(this.data.findIndex(d => d === item));
  //     this.data.splice(index,1)
  //   });
  // }

  // /** Selects all rows if they are not all selected; otherwise clear selection. */
  // masterToggle() {
  //   this.isAllSelected() ?
  //       this.selection.clear() :
  //       this.dataSource.data.forEach(row => this.selection.select(row));
  // }

  // getSamplesDetails() {
  //   this.labService.getSamplesDetails(this.offset, this.limit, this.search).subscribe ((res: any) => {
  //     console.log('res', res);
  // this.dataSource = new MatTableDataSource(res.data);
  //   });
  // }

  addUnit() {
    const control = <FormArray>this.ifsForm.controls["productInformation"];
    control.push(this.updateproductInformation());
    this.isBrandNameDisable = false;
    this.isProductCodeDisable = false;
    this.isSizeDisable = false;
    this.saveDisabled = true;
  }


  removeUnit(i: number) {
    const control = <FormArray>this.ifsForm.controls["productInformation"];
    control.removeAt(i);
    this.validateForm();
    this.isBrandNameDisable = false;
    this.isProductCodeDisable = false;
    this.isSizeDisable = false;
  }



  get f() {
    return this.ifsForm.controls;
  }

  createDistilleryIndent() {
    this.saveDisabled = true;
    let req_data = {
      action: 'add_supplier_idt',
      data: { ...this.ifsForm.value }
    };
    req_data.data['productDetails'] =  req_data.data['productInformation'];
    req_data.data['productDetails'].forEach(res => {
          this.productInfo.forEach(resp => {
            if(res.brandName == resp.brandName) {
                res['brandCode'] = resp.brandCode;
            }
          })
    })
    delete req_data.data['productInformation']
    delete req_data.data['indentId'];

    this.interDepotService.createIDT(req_data).subscribe((res: any) => {
      this.dialogRef.close('Created Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  editDistilleryIndent() {
    // this.saveDisabled = true;
    // let req_data = {
    //   action: 'edit_supplier_idt',
    //   data: { ...this.ifsForm.value,ofidtNo:this.data.indentId }
    // };
    // req_data.data['productDetails'] =  req_data.data['productInformation'];
    // req_data.data['productDetails'].forEach(res => {
    //       this.productInfo.forEach(resp => {
    //         if(res.brandName == resp.brandName) {
    //             res['brandCode'] = resp.brandCode;
    //         }
    //       })
    // })
    // delete req_data.data['productInformation']
    // delete req_data.data['indentId'];

    // console.log(req_data, "239::::::")
    // console.log(JSON.stringify(req_data));
    // // this.interDepotService.updateIDT(req_data).subscribe((res: any) => {
    // //   this.dialogRef.close('Updated Successfully');
    // // }, (err) => {
    // //   this.error = err.error.message;
    // // });
  }

  dialogClose(): void {
    this.dialogRef.close();
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
