import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from 'primeng/api';
import { ProductsService } from 'src/app/core/services/products.service';
import { AddBrandPackigDialogComponent } from './add-brand-packig-dialog/add-brand-packig-dialog.component';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { BottleSizes } from 'src/app/core/constants/bottle-sizes.constants';
import { CommonsService } from 'src/app/core/services/commons.service';
import { bottleDetails } from 'src/app/core/constants/bottle-details.constant';

@Component({
  selector: 'app-brand-barcode-details-dialog',
  templateUrl: './brand-barcode-details-dialog.component.html',
  styleUrls: ['./brand-barcode-details-dialog.component.scss']
})
export class BrandBarcodeDetailsDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'segment',
    'brandType',
    'packType',
    'packSize',
    'productSize',
    'unitsPerCase',
    'strength',
    'basicPrice',
    'importFee',
    'exportFee',
    'exciseDuty',
    'customDuty',
    'action'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;
  bottleSizes = BottleSizes;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  brandCodesLength: any;
  brandCodeDetails: any;
  roleName: any;

  constructor(
    public util: UtilService, public dialogRef: MatDialogRef<BrandBarcodeDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, private commonsService: CommonsService,
    private productsService: ProductsService,
    private dialog: MatDialog,
    private messageService: MessageService) {

      this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role,
      dialogRef.disableClose = true;

    }

  ngOnInit(): void {
    this.data.brandCodeOnly = this.data.brandCode;
    this.data.brandCodeName = this.data.brandCode + '-' + this.data.brandName;
    this.getBrandCodeDetails()
  }

  ngAfterViewInit() {
    this.util.ngAfterViewInit(this.dataSource, this.paginator, this.sort, null, null);
  }

  applyFilter() {
    this.util.applyFilter(this.dataSource, this.search);
  }
  addBrandPacking() {
    this.data.brandCode = this.data.brandCodeName;
    const dialogRef = this.dialog.open(AddBrandPackigDialogComponent, {
      width: '50%',
      // height: '50%',
      autoFocus: false,
      data: (this.brandCodeDetails && this.brandCodeDetails.length) ? this.brandCodeDetails : [this.data],
      disableClose: true ,
    },);    
    dialogRef.afterClosed().subscribe(result => {
      this.data.brandCode = this.data.brandCodeOnly;
      // console.log(`Dialog result: ${result}`);
      if (result !== true && result !== undefined) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: result });
        this.getBrandCodeDetails();
      }
    });
  }

  editBrandPacking(data) {
    const dialogRef = this.dialog.open(AddBrandPackigDialogComponent, {
      width: '50%',
      // height: '50%',
      autoFocus: false,
      data
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
      if (result !== true && result !== undefined) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: result });
        this.getBrandCodeDetails();
      }
    });
  }

  getBrandCodeDetails() {
  /*   const obj = {
      brandCode: this.data.brandCode
    } */

    const obj = {
      "action": "common_apis",
        "q": '{"data": {"action": "get_brand_packing_list", "value":"' +this.data.brandCode+'"}}'
      // brandCode: this.data.brandCode
    }

    this.commonsService.getCommonEntites(obj).subscribe(res => {
   /*    console.log(res,"104")
    })
    this.productsService.getbrandCodesDetails(JSON.stringify(obj), 'get_brand_products').subscribe((res: any) => { */
      // let data = res.filter(res => !(!res))
      //  this.dataSource = new MatTableDataSource(data);
      this.brandCodeDetails = res['get_brand_packing_list'];
      let dataSource = res['get_brand_packing_list'].filter(item => !(!item.packType))
      if (!res.response || res.response != 'None') {
       /*  Object.keys(this.bottleSizes).forEach(resp => {
            res['get_brand_packing_list'].forEach(prod => {
                if(prod['size'] == this.bottleSizes[resp]) {
                    prod['size'] = resp;
                }
            })
        }) */
        this.dataSource = new MatTableDataSource(dataSource);
        this.brandCodesLength = this.data.length;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }

    });
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

  onPaginateChange(e) {
    this.limit = e.pageSize;
    this.offset = e.pageIndex * e.pageSize;
  }

  getPackSize(size, units) {
    let bottleCode = '';
    bottleDetails.forEach(b => {
      if (b.volumeInML == size && b.unitsPerCase == units) {
        bottleCode = b.bottleCode;
      }
    })
    return bottleCode;
  }
}
