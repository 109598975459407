import { UtilService } from 'src/app/core/utils/utility-helper';
import { InterDepotService } from './../../../../../core/services/inter-depot.service';
import { CommonsService } from 'src/app/core/services/commons.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { OtpVerificationDialogComponent } from 'src/app/core/components/otp-verification-dialog/otp-verification-dialog.component';

@Component({
  selector: 'app-idtreceipt-evc-info-dialog',
  templateUrl: './idtreceipt-evc-info-dialog.component.html',
  styleUrls: ['./idtreceipt-evc-info-dialog.component.scss']
})
export class IDTReceiptEvcInfoDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'no',
    'productCode',
    'brandName',
    // 'batchId',
    'sizeCode',
    'shippedCases',
    'recievedCases',
    'recievedBottles',
    'damagedCases',
    'damagedBottles',
    'shortageCases',
    'shortageBottles'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  productData: any[];
  ofidtData: any;
  roleName: any;
  totalShipped: any = 0;
  receivedQtyFull: any = 0;
  receivedQtyPart: any = 0;
  breakageBtl: any = 0;
  depotName: any;
  address: any;
  selectedEvc:any;

  constructor(
    public dialogRef: MatDialogRef<IDTReceiptEvcInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA)public data,
    private interDepotService: InterDepotService,
    private dialog: MatDialog, private commonService: CommonsService,
    public util: UtilService
  ) {
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    this.depotName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).entity_id;
    this.address = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).depot_address;
   }

  ngOnInit(): void {
    console.log('this.data.get_evc', this.data.get_evc);
    console.log(this.data, '33::::');
    this.productData = [];
    this.ofidtData = this.data.consignment;
    this.totalValue();
    console.log(this.data)
  }

  updateAction(): any {
    const payload: any = {
      action: 'add_checkList',
      data: {
        tpNo: this.data.tpNo
      }
    };
    if (this.data.btpNo) {
      delete payload.data.tpNo;
      payload.data.btpNo = this.data.btpNo;
    } else if (this.data.bipNo) {
      delete payload.data.tpNo;
      payload.data.bipNo = this.data.bipNo;
    }
    this.interDepotService.generateActions(payload).subscribe(result => {
     this.dialogRef.close('Verified successfully');
    });
  }

  updateStatus(status): any  {
    if (status === 'Approved'){
      // tslint:disable-next-line:variable-name
      // const req_otp = {
      //   action: 'send_otp',
      //   data: {
      //    ...this.data
      //   }
      // };

      // this.commonService.sendVerifyOtp(req_otp).subscribe((res: any) => {


      // });
      // const dialogRef = this.dialog.open(OtpVerificationDialogComponent, {
      //   width: '25%',
      //   height: '45%',
      //   autoFocus: false
      // });

      // dialogRef.afterClosed().subscribe((result) => {
      //   if (result.message === 'SUCCESS'){
          // tslint:disable-next-line:variable-name
          const req_Data: any = {
            action: 'approve_evc',
            data: {
                status
            }
          };

          if (this.data.consignmentType === 'Import Permit'){
            req_Data.data.ipNo = this.data.ipNo;
          }else{
            req_Data.data.tpNo = this.data.tpNo;
          }

          this.interDepotService.approveEvc(req_Data).subscribe(res => {
            this.dialogRef.close('EVC Updated Successfully');
          });
      //   }
      // });
    }else{
      // tslint:disable-next-line:variable-name
      const req_Data: any = {
        action: 'approve_evc',
        data: {
            status
        }
      };

      if (this.data.consignmentType === 'Import Permit'){
        req_Data.data.ipNo = this.data.ipNo;
      }else{
        req_Data.data.tpNo = this.data.tpNo;
      }

      this.interDepotService.approveEvc(req_Data).subscribe(result => {
        this.dialogRef.close('EVC Updated Successfully');
      });
    }


  }
  totalValue(): any {
    this.totalShipped = 0;
    this.receivedQtyFull = 0;
    this.receivedQtyPart = 0;
    this.breakageBtl = 0;
    this.ofidtData.map((ofidt: any) => {
      ofidt.productDetails.map((item: any) => {
        this.totalShipped += parseInt(item.casesCount, 10);
        // this.receivedQtyFull += parseInt(item.scannedCases, 10);
        this.receivedQtyFull +=  item.breakages?.cases ? (item.casesCount - item.breakages.cases) : item.casesCount 
        this.receivedQtyPart += parseInt(item.scannedBottles, 10);
        if (item.breakages){
          this.breakageBtl += parseInt(item.breakages.bottles, 10);

        }
      });
    });
  }
  dialogClose(): void{
    this.dialogRef.close();
  }
  Print(data,id): any {
  
    if(document.getElementById(id)){
      this.selectedEvc = data;
      setTimeout(() => {
        let printContents;
        let popupWin;
        console.log(this.selectedEvc)
        printContents = document.getElementById(id).innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.write(`${printContents}`);
        popupWin.document.close();
      }, 500);
    }

}

}
