import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CommonsService } from 'src/app/core/services/commons.service';
import { IndentManagementService } from 'src/app/core/services/indent-management.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-manage-distillery-indent-dialog',
  templateUrl: './manage-distillery-indent-dialog.component.html',
  styleUrls: ['./manage-distillery-indent-dialog.component.scss']
})
export class ManageDistilleryIndentDialogComponent implements OnInit, OnDestroy, AfterViewInit {


  ifsForm: FormGroup;
  productInformation: FormArray;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  public ifsDistilleryFilterCtrl: FormControl = new FormControl();
  public ifsDepotFilterCtrl: FormControl = new FormControl();
  public ifProductFilterCtrl: FormControl = new FormControl();
  offset: number;
  search: string;
  distilleryNames = [];
  depotNames = [];
  productCodeList = [];
  productDisableList = [];
  brandNameList = [];
  sizeList = [];
  isBrandNameDisable = false;
  isSizeDisable = false;
  isProductCodeDisable = false;


  public _onDestroy = new Subject<void>();
  /** list of items filtered by search keyword */
  public filteredDistilleryNames: ReplaySubject<any> = new ReplaySubject<any>(1);
  public filteredDepotNames: ReplaySubject<any> = new ReplaySubject<any>(1);
  public filteredProductCodes: ReplaySubject<any> = new ReplaySubject<any>(1);
  @ViewChild('singleDistillerySelect') singleDistillerySelect: MatSelect;
  @ViewChild('singleDepotSelect') singleDepotSelect: MatSelect;
  @ViewChild('singleProductSelect') singleProductSelect: MatSelect;
  batchIds: any;
  productInfo: any[] = [];
  filteredData: any[];
  allDistilleries: any = [];
  selectedDistillery: any = [];
  supplierType:any;

  roleName: any = '';
  entityId: any = '';
  productRemainingQtys: any = {};
  remainingQtyDisabled: any = false;

  isDistilleryIndent = false;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageDistilleryIndentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private indentManagementService: IndentManagementService,
    private commonsService: CommonsService,
    private spinner: NgxSpinnerService,
    public util: UtilService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    let paramObj = '?action=common_apis&q={"data": ["get_dist_depot"]}';

    if (this.roleName === 'Distillery Manager' || this.roleName === 'Brewery Manager' || this.roleName === 'NonLocal Supplier Manager' || this.roleName === 'NonLocal Brewery Manager') {
      this.isDistilleryIndent = true;
      this.entityId = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).entity_id;
    }

    this.spinner.show();
    // get_batches
    this.commonsService.getList(paramObj).subscribe(data => {
      this.distilleryNames = data['get_dist_depot']['distilleries'];
      this.allDistilleries = data['get_dist_depot']['all_distilleries'];
      this.depotNames = data['get_dist_depot']['depots'];
      // this.productInfo = data['get_prod_info'];
      this.filteredDepotNames.next(this.depotNames.slice());
      this.filteredDistilleryNames.next(this.distilleryNames.slice());
      this.filteredProductCodes.next(this.productCodeList.slice());
      if (this.isDistilleryIndent) {
        this.sourceChange(this.entityId, '');
      }
      this.spinner.hide();

    })

    


    /* this.indentManagementService.getDistilleryNameList(paramObj).subscribe(data =>  {
      this.distilleryNames = data['distilleries'];
      this.depotNames = data['depots'];
      this.filteredDepotNames.next(this.depotNames.slice());
      this.filteredDistilleryNames.next(this.distilleryNames.slice());

    }) */



    // listen for search field value changes
    this.ifsDistilleryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterDistilleries();
      });

    this.ifsDepotFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterDepots();
      });

    this.ifProductFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProducts();
      });

    this.ifsForm = this.fb.group({
      sourceDistilleryName: ['', [Validators.required,]],
      depotName: ['', [Validators.required,]],
      indentId: [''],
      productInformation: this.fb.array([this.updateproductInformation()])
    });
    if (this.isDistilleryIndent) {
      this.ifsForm.patchValue({
        sourceDistilleryName: this.entityId
      });
      this.addControlRemainingQty();
    }
    if (this.data) {
      this.ifsForm.patchValue({
        sourceDistilleryName: this.data.sourceDistilleryName,
        depotName: this.data.depotName,
        indentId: this.data.indentId,
        ofsValidityDate: this.data.ofsValidityDate,
        date: this.data.date,
      });
      const productInformation = this.ifsForm.get('productInformation') as FormArray;
      productInformation.clear();
      this.data.productInformation.forEach(b => {
        productInformation.push(
          this.fb.group({
            checkedValue: b.checkedValue,
            productCode: b.productCode,
            brandName: b.brandName,
            brandCode: b.brandCode,
            requestedQty: Number(b.requestedQty),
            casePrice: b.basicPricing ? Number(b.basicPricing[0]['price']) : '',
            value: Number(b.requestedQty) * Number(b.basicPricing[0]['price']),
            unitsPerCase:b.unitsPerCase
          })
        );
      });
    }
    this.productInformation = this.ifsForm.get('productInformation') as FormArray;
    // this.saveDisabled = true;
    // this.getSamplesDetails();
  }


  ngAfterViewInit() {
    this.setInitialValue();

  }
  searchDropDown(event) {
    console.log(event);
  }

  sourceChange(eventValue, filter) {
    this.selectedDistillery = [];    
    this.selectedDistillery = this.allDistilleries.filter(el => el.supplierCode === eventValue);
    console.log('this.selectedDistillery', this.selectedDistillery);
    this.allDistilleries.forEach((item:any)=>{
      if(item.supplierCode === eventValue){
        this.supplierType = item.supplierType;
      }
    })
    console.log(this.supplierType)
    let code = eventValue;
    this.filteredData = [];
      let qData = {
        "data": { "value": this.ifsForm.value.sourceDistilleryName, "action": "get_products_by_id" }
      };
      const req_Data = {
        "action": "common_apis",
        "q": JSON.stringify(qData)
      };
      
      this.commonsService.getCommonEntites(req_Data).subscribe(data => {
        data["get_products_by_id"].forEach((val:any) => {
          this.productInfo.push(val);
        }
          // val.productInfo.forEach(product=> {
          //   this.productInfo.push(product);
          // })
        );
           //if(!filter) {
        this.filteredData = this.productInfo &&this.productInfo.filter(info => info['supplierCode'] == code);
        // }   
        this.productCodeList = Array.from(new Set(this.filteredData.map(data => data.productCode)));
        this.brandNameList = Array.from(new Set(this.filteredData.map(data => data.brandName)));
        this.brandNameList = this.brandNameList.filter((list) => {
          if (this.brandNameList.indexOf(list) !== this.brandNameList.lastIndexOf(list)) {
            this.brandNameList.splice(this.brandNameList.indexOf(list), 1);
          }
          return this.brandNameList.indexOf(list) == this.brandNameList.lastIndexOf(list)
        })
      
        this.sizeList = Array.from(new Set(this.filteredData.map(data => data.size)));
       
         this.filterProducts();
          this.spinner.hide();
      })
  }

  validateForm() {
    const control = <FormArray>this.ifsForm.controls["productInformation"];
    // this.saveDisabled = !!control.controls.filter(item => !!Object.keys(item.value).filter(obj =>
    //  !item.value[obj]
    // ).length).length;

  }

  setInitialValue() {
    this.filteredDistilleryNames
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleDistillerySelect.compareWith = (a, b) => a && b && a === b;
      });

    this.filteredDepotNames
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleDepotSelect.compareWith = (a, b) => a && b && a === b;
      });


      this.filteredProductCodes
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleProductSelect.compareWith = (a, b) => a && b && a === b;
      });
  }

  filterDepots() {
    if (!this.depotNames || (this.depotNames && this.depotNames.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.ifsDepotFilterCtrl.value;
    if (!search) {
      this.filteredDepotNames.next(this.depotNames.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredDepotNames.next(
      this.depotNames.filter(name => name.toLowerCase().indexOf(search) > -1)
    );
  }

  filterDistilleries() {
    if (!this.distilleryNames || (this.distilleryNames && this.distilleryNames.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.ifsDistilleryFilterCtrl.value;
    if (!search) {
      this.filteredDistilleryNames.next(this.distilleryNames.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredDistilleryNames.next(
      this.distilleryNames.filter(name => name.toLowerCase().indexOf(search) > -1)
    );
  }

  filterProducts() {
    if (!this.productCodeList || (this.productCodeList && this.productCodeList.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.ifProductFilterCtrl.value;
    if (!search) {
      this.filteredProductCodes.next(this.productCodeList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredProductCodes.next(
      this.productCodeList.filter(name => name.toLowerCase().indexOf(search) > -1)
    );
  }

  productChange(event, i) {
    // const productInformation = this.ifsForm.get('productInformation') as FormArray;
    const productInformation = (<FormArray>this.ifsForm.get("productInformation")).at(i);
    let [bindData] = this.filteredData.filter(data => {
      return data.productCode == event.value;
    }) 
    // this.data.productInformation.forEach(b => {
    this.isBrandNameDisable = true;
    this.isSizeDisable = true;

    productInformation.patchValue({
      productCode: bindData.productCode,
      brandName: bindData.brandName,
      brandCode: bindData.brandCode,
      size: bindData.size,
      requestedQty: '',
      casePrice: bindData.basicPricing ? parseFloat(this.getCasePrice(bindData)) : '',
      value: '',
      unitsPerCase:bindData.unitsPerCase

    })

    if (productInformation.get('casePrice').value && productInformation.get('requestedQty').value) {
      productInformation.patchValue({
        value: productInformation.get('casePrice').value * productInformation.get('requestedQty').value
      })
    }

    this.validateForm();

    if (this.isDistilleryIndent) {
      this.getNetEligibilty(bindData, productInformation);
    }
  }

  getEligibility(i): any {
    const productInformation = (<FormArray>this.ifsForm.get("productInformation")).at(i);
    const productData: any = this.filteredData.find(data => data.productCode === productInformation.get('productCode').value);
    const payload: any = {
      depotName: this.ifsForm.value.depotName,
      brandType: productData.brandType,
      productCode: productData.productCode
    }
    const reqData: any = {
      action: 'calculate_eligibility',
      q: JSON.stringify(payload)
    };
    this.spinner.show();
    this.indentManagementService.getIndentManagementFeaturesByParams(reqData).subscribe((res: any) => {
      if (res && res.calculate_eligibility && res.calculate_eligibility.length) {
        this.getRemainingQty(res, productInformation, productData);
      } else {
        this.messageService.add({ severity: 'error', detail: res.status });
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  getRemainingQty(res, productInformation, product): any {
    const resData = res.calculate_eligibility[0];
    console.log(resData)
    productInformation.patchValue({
      depotEligibility: resData.depotEligibility ? resData.depotEligibility : 0, 
      netEligibilty: resData.netEligibilty ? resData.netEligibilty : 0, 
      remainingQty:  resData.remainingQty ? resData.remainingQty : 0, 
      usedQty: resData.usedQty ? resData.usedQty : 0
    });

    
  }
 

  getNetEligibilty(product, productInformation): any {
    const payload: any = {
      depotName: this.ifsForm.value.depotName,
      brandType: product.brandType,
      productCode: product.productCode
    }
    const reqData: any = {
      action: 'calculate_eligibility',
      q: JSON.stringify(payload)
    };
    this.spinner.show();
    this.indentManagementService.getIndentManagementFeaturesByParams(reqData).subscribe((res: any) => {
      if (res && res.calculate_eligibility && res.calculate_eligibility.length) {
        this.getRemainingQty(res, productInformation, product);
      } else {
        productInformation.patchValue({
          remainingQty: ''
        });
        this.messageService.add({ severity: 'error', detail: "No eligible quantity for this product" });
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  getCasePrice(prodData){
    // const importFee = prodData.size * (prodData.unitsPerCase / 1000) * prodData.pricing[0].importFee;
    // return prodData.basicPricing[0].price - importFee - (prodData.unitsPerCase * 0.3); 
    // return this.supplierType === 'Non-Local' ? 
    // (((prodData.basicPricing[0] ? prodData.basicPricing[0].price : 0) - (((prodData?.size * prodData?.unitsPerCase)/1000)*2) - (prodData?.unitsPerCase * 0.4366))).toFixed(2) 
    // : (prodData.basicPricing[0].price + prodData.taxes[0]['EXCISE DUTY']).toFixed(2);
    const productType: any = this.util.getProductType(prodData.productCode);
    return this.supplierType === 'Non-Local' ? 
    (((prodData.basicPricing[0] ? prodData.basicPricing[0].price : 0) - (((prodData?.size * prodData?.unitsPerCase)/1000)*2) - (productType === 'Beer' ? 0 : prodData?.unitsPerCase * 0.4273))).toFixed(2) 
    : (prodData.basicPricing[0].price + prodData.taxes[0]['EXCISE DUTY']).toFixed(2);
    // return prodData.basicPricing[0].price + prodData.taxes[0]['EXCISE DUTY'];
  }

  productCodeDisable(p,i) {
    let isDisable = false;
    
   
    
    this.ifsForm.value.productInformation.forEach(val => {
      if(val.productCode === p) {
        
        isDisable = true;
      }
    })
    return isDisable;
  }

  updateproductInformation() {
    return this.fb.group({
      productCode: ['', Validators.required],
      size: ['', Validators.required],
      brandName: ['', Validators.required],
      brandCode: ['', Validators.required],
      requestedQty: ['', Validators.required],
      casePrice: ['', Validators.required],
      value: ['', Validators.required],
      unitsPerCase:['', Validators.required]
    });
  }

  quantityChange(event, i) {
    const productInformation = (<FormArray>this.ifsForm.get("productInformation")).at(i);
    if (productInformation.get('casePrice').value) {
      productInformation.patchValue({
        value: Number(productInformation.get('casePrice').value) * Number(event.target.value)
      })
    }

    if (this.isDistilleryIndent) {
      this.validateRemainingQty();
    }
    if (event.target.value < 1) {
      this.remainingQtyDisabled = true;
    }

    // this.validateForm();
  }

  validateRemainingQty(): any {
    const control = <FormArray>this.ifsForm.controls["productInformation"];
    this.remainingQtyDisabled = control.value.some((ctrl: any) => ctrl.requestedQty > ctrl.remainingQty); 
  }

  productDataChange(event, i) {

    const productInformation = (<FormArray>this.ifsForm.get("productInformation")).at(i);

    if (event.source.ngControl.name === 'brandName' || event.source.ngControl.name === 'size') {
      let [bindData] = this.filteredData.filter(data => {
        return data[event.source.ngControl.name] == event.value;
      });

      if (event.source.ngControl.name === 'size') {
        productInformation.patchValue({
          productCode: bindData.productCode,
          size: bindData.size,
          requestedQty: '',
          casePrice: bindData.basicPricing ? bindData.basicPricing[0].price : '',
          value: '',
          unitsPerCase:bindData.unitsPerCase
        })
      } else {
        productInformation.patchValue({
          productCode: bindData.productCode,
          brandName: bindData.brandName,
          brandCode: bindData.brandCode,
          size: bindData.size,
          requestedQty: '',
          casePrice: bindData.basicPricing ? bindData.basicPricing[0].price : '',
          value: '',
          unitsPerCase:bindData.unitsPerCase

        })
      }


      if (productInformation.get('casePrice').value && productInformation.get('requestedQty').value) {
        productInformation.patchValue({
          value: productInformation.get('casePrice').value * productInformation.get('requestedQty').value
        })
      }

      this.isProductCodeDisable = true;
    }
    this.validateForm();
  }


  // isAllSelected() {
  //   const numSelected = this.selection.selected.length;
  //   const numRows = this.dataSource.data.length;
  //   return numSelected === numRows;
  // }

  // removeRow() {
  //   this.selection.selected.forEach(item => {
  //     let index: number = this.data.findIndex(d => d === item);
  //     this.data.splice(index,1)
  //   });
  // }

  // /** Selects all rows if they are not all selected; otherwise clear selection. */
  // masterToggle() {
  //   this.isAllSelected() ?
  //       this.selection.clear() :
  //       this.dataSource.data.forEach(row => this.selection.select(row));
  // }

  // getSamplesDetails() {
  //   this.labService.getSamplesDetails(this.offset, this.limit, this.search).subscribe ((res: any) => {
  // this.dataSource = new MatTableDataSource(res.data);
  //   });
  // }

  addControlRemainingQty(): any {
    const control: any = <FormArray>this.ifsForm.controls["productInformation"];
    const prodcutCtrls: any = control.controls[control.controls.length - 1];
    prodcutCtrls.addControl("depotEligibility", new FormControl(0));
    prodcutCtrls.addControl("netEligibilty", new FormControl(0));
    prodcutCtrls.addControl("remainingQty", new FormControl(0));
    prodcutCtrls.addControl("usedQty", new FormControl(0));
  }

  addUnit() {
    const control: any = <FormArray>this.ifsForm.controls["productInformation"];
    control.push(this.updateproductInformation());
    this.addControlRemainingQty();
    this.isBrandNameDisable = false;
    this.isProductCodeDisable = false;
    this.isSizeDisable = false;
    // this.saveDisabled = true;
  }


  removeUnit(i: number) {
    const control = <FormArray>this.ifsForm.controls["productInformation"];
    control.removeAt(i);

    this.validateForm();
    this.isBrandNameDisable = false;
    this.isProductCodeDisable = false;
    this.isSizeDisable = false;   
  }



  get f() {
    return this.ifsForm.controls;
  }
  changeDepot(value){
    const productInformation = this.ifsForm.get('productInformation') as FormArray;
    productInformation.clear();
  }

  createDistilleryIndent() {
    this.saveDisabled = true;
    let req_data = {
      action: 'create_ifs',
      data: { ...this.ifsForm.value }
    };
    req_data.data.entityType = this.selectedDistillery.length ? this.selectedDistillery[0].locationType : '';
    req_data.data['productDetails'] =  req_data.data['productInformation'];
    req_data.data['productDetails'].forEach(res => {
          this.productInfo.forEach(resp => {
            if(res.brandCode === resp.brandCode && res.productCode === resp.productCode) {
              console.log('this.productInfo', this.productInfo);
              const productData = this.util.productCodeToDetails(resp.productCode);
                res['brandCode'] = resp.brandCode;
                res['basicPricing'] = resp.basicPricing;
                res['pricing'] = resp.pricing;
                res['packType'] = resp.packType;
                res['strength'] =  resp.strength;
                res['unitsPerCase'] = resp.unitsPerCase;
                res['Excise Duty'] = resp.taxes && resp.taxes.length ? resp.taxes[0]['EXCISE DUTY'] : 0;
                res.category = resp.category;
            }
          })
    })
    delete req_data.data['productInformation']
    delete req_data.data['indentId'];
    this.spinner.show();
    this.indentManagementService.createDistilleryIndent(req_data).subscribe((res: any) => {
      this.spinner.hide();
      this.dialogRef.close('Created Successfully');
    }, (err) => {
      this.spinner.hide();
      this.error = err.error.message;
    });
  }

  editDistilleryIndent() {

  }

  dialogClose(): void {
    this.dialogRef.close();
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
