import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Remitance } from '../models/excise-duty/remitance.model';

const baseUrl = environment.baseUrl;
const exciseDutyUrl = baseUrl + 'excise_duty';
const exciseDutiesUrl = baseUrl + 'exciseduties';
const remitanceUrl = baseUrl + 'remitances';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Excise Duty'] && userModules.mod_feats['Excise Duty'].features ? userModules.mod_feats['Excise Duty'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class ExciseDutyService {

  constructor(private http: HttpClient) { }

  getExciseDuties() {
    return this.http.get(exciseDutiesUrl);
  }

  getRemitance() {
    return this.http.get(remitanceUrl);
  }

  createRemitance(obj: any){
    return this.http.post(exciseDutyUrl, obj);
  }

  getExciseDutyFeatures(paramObj: any = '') {
    const headerDict = {
      feature
    };
    return this.http.get(exciseDutyUrl, { headers: headerDict, params: paramObj });
  }

  getTransactionDetails(q, action) {
    const params: any = {};
    q ? (params.q = q) : (q = null);
    action ? (params.action = action) : (action = null);
    return this.http.get(exciseDutyUrl, {params});
  }

}
