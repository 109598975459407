import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  templateUrl: './supplier-advanced-inbound-reports-dialog.component.html',
  styleUrls: ['./supplier-advanced-inbound-reports-dialog.component.scss']
})
export class SupplierAdvancedInboundReportsDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'no',
    'asnId',
    'truckNumber',
    'transporter',
    'createdDate',
    'newStatus',
    'processedStatus',
    'completedStatus',
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  constructor(public dialogRef: MatDialogRef<SupplierAdvancedInboundReportsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data.ofsDetails);
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

}
