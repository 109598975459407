import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DepotShipment } from 'src/app/core/models/shipments/depot-shipment.model';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-show-icdc-details-dialog',
  templateUrl: './show-icdc-details-dialog.component.html',
  styleUrls: ['./show-icdc-details-dialog.component.scss']
})
export class ShowIcdcDetailsDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'productName',
    'brandNumber',
    'size',
    'packType',
    'quantityCases',
    'quantityBottles',
    'productPrice',
    'totalPrice'
  ];
  dataSource: MatTableDataSource<DepotShipment> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;

  constructor(public dialogRef: MatDialogRef<ShowIcdcDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,
    private shipmentsService: ShipmentsService,private util:UtilService) { }

  ngOnInit(): void {
    this.getIcdcDetails();
  }

  getIcdcDetails() {
    if(this.data){
      // productCodeToDetails
      let dataTable=this.data.consignment && this.data.consignment.map((consignment:any)=>{
        let obj={
          productName:'',
          brandNumber:'',
          sizeMl:0,
          packType:'',
          quantityCases:0,
          quantityBottles:0,
          productPrice:0,
          totalPrice:0,
        }
        this.data.indentId =this.data.indentId ? this.data.indentId : consignment.indentId
        consignment?.productDetails && consignment?.productDetails.map((product:any)=>{
         this.util.getTotalScannedCasesBottles(product)
          const price=product.retailPricings ? product.retailPricings : product.mrp
          obj.productName=obj.productName ? obj.productName :product.brandName;
          obj.brandNumber=obj.brandNumber ? obj.brandNumber :product.brandCode;
          obj.sizeMl+=product.size ? product.size : 0 ;
          obj.packType=obj.packType ? obj.packType : product.packType;
          obj.quantityBottles+=product.totalScannedBottles ? product.totalScannedBottles : 0;
          obj.quantityCases+=product.totalScannedCases ? product.totalScannedCases : 0
          obj.productPrice+=price
          obj.totalPrice+=price * ((product.unitsPerCase * product.totalScannedCases)+(product.totalScannedBottles))
        })
        return obj;
      })
      this.dataSource = new MatTableDataSource(dataTable);

    }else{
      this.shipmentsService.getDepotShipment(this.offset, this.limit, this.search).subscribe((res: any) => {
        console.log('res', res);
        this.dataSource = new MatTableDataSource(res.data);
      });
    }

  }

  dialogClose(): void {
    this.dialogRef.close();
  }

}
