import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { CommonsService } from 'src/app/core/services/commons.service';
import { LocationsService } from 'src/app/core/services/locations.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { ManageLocationDialogComponent } from '../location/manage-location-dialog/manage-location-dialog.component';

@Component({
  selector: 'app-manage-depots-dialog',
  templateUrl: './manage-depots-dialog.component.html',
  styleUrls: ['./manage-depots-dialog.component.scss']
})
export class ManageDepotsDialogComponent implements OnInit {
  depotForm: FormGroup;
  zonesList: any = [];

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageLocationDialogComponent>,
    private messageService: MessageService, public util: UtilService, @Inject(MAT_DIALOG_DATA) public data,
    private CommonsService: CommonsService, private locationsService: LocationsService) { }

  ngOnInit(): void {
    this.getZones()
    this.depotForm = this.fb.group({
      depotCode: ['', [Validators.required, Validators.min(10)]],
      depotName: ['', Validators.required],
      address: ['', Validators.required],
      pincode: ['', Validators.required],
      town: ['', Validators.required],
      state: ['Andhra Pradesh', Validators.required],
      zone: ['', Validators.required],
      phoneNumber: ['', Validators.compose([Validators.required, Validators.pattern(this.util.phoneNumberValidation())])],
      coordinates: ['0 , 0'],
    });
    // if (this.data) {
    //   this.depotForm.patchValue({
    //     depotCode: this.data.depotCode.split('-')[0],
    //     depotName: this.data.depotName,
    //     address: this.data.address ? this.data.address.address : '',
    //     pincode: this.data.address ? this.data.address.pincode : '',
    //     town: this.data.address ? this.data.address['town/village'] : '',
    //     state: this.data.address ? this.data.address.state : '',
    //     zone: this.data.address ? this.data.address.zone : '',
    //     phoneNumber: this.data.address && this.data.address.phoneNumbers ? this.data.address.phoneNumbers[0].number : '',
    //     coordinates: this.data.address ? this.data.address.coordinates : ''
    //   });      
    // }
  }

  getZones() {
    const req_Data = {
      action: 'common_apis',
      q: `{"data": {"action": "get_static_codes", "value": ["zones"]}}`
    }
    this.CommonsService.getCommonEntites(req_Data).subscribe(result => {
      this.zonesList = result.zones;
    })
  }

  saveDepot() {
    console.log(this.depotForm.value);
    if (this.data) {
      const obj = {
        action: 'update_depots',
        data: this.depotForm.value
      };
      this.locationsService.putDepot(obj).subscribe((res: any) => {
        if (res.status && res.status !="SUCCESS") {
          this.dialogRef.disableClose = true;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: res.status});
        }
        else {
          this.dialogRef.close('Updated Successfully');
        }
      }, (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: err.error.message});
      });
    }
    else {
      const obj = {
        action: 'add_depots',
        data: this.depotForm.value
      };

      this.locationsService.postDepot(obj).subscribe((res: any) => {
        if (res.status && res.status !="SUCCESS") {
          this.dialogRef.disableClose = true;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: res.status});
        }
        else {
          this.dialogRef.close('Added Successfully');
        }
      }, (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: err.error.message});
      });
    }

  }

  dialogClose(): void {
    this.dialogRef.close();
  }

}