import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Login } from '../models/login.model';

const baseUrl = environment.baseUrl;
const loginUrl = baseUrl + 'login';
const logoutUrl = baseUrl + 'auth/logout';
const changePasswordUrl = baseUrl + 'profile';
const validateUrl = baseUrl+ 'validate-vehicle';
const updateProfile = baseUrl+ 'profile';
const getProfileUser = baseUrl + 'get_profile_user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {  cachedRequests: Array<HttpRequest<any>> = [];

  constructor(private http: HttpClient) { }

  public getToken(): string {
    if (sessionStorage.getItem('lappCurrentUserToken') !== null) {
      const token = JSON.parse(sessionStorage.getItem('lappCurrentUserToken'));
      return token;
    }
  }

  public isAuthenticated(): boolean {
    // get the token
    const token = this.getToken();
    // return a boolean reflecting
    // whether or not the token is expired
    return this.tokenNotExpired(token);
  }

  tokenNotExpired(token) {
    return true;
  }

  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }

  public retryFailedRequests(): void {
    // retry the requests. this method can
    // be called after the token is refreshed
  }

  onLogin(loginDetails: Login) {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'activeUsersName': loginDetails.userId,
      'activeUsers': loginDetails.activeUsers
    }

    // const requestOptions = {
    //   headers: new Headers(headerDict),
    // };
    return this.http.get(`${loginUrl}`, {headers: headerDict});
  }

  onLogout() {
    return this.http.post(logoutUrl, {}).pipe(map(data => {
      sessionStorage.removeItem('lappCurrentUserDetails');
      sessionStorage.removeItem('lappCurrentUserModules');
    }));
  }

  validateVehicle(params) {
    return this.http.get(validateUrl, {params})
  }

  changePassword(obj) {
    return this.http.post(changePasswordUrl, obj);
  }

  getProfileData(params){
    return this.http.get(getProfileUser, {params});
  }

  updateProfile(obj){
    return this.http.post(updateProfile, obj);
  }
}