import { Component, Input, OnInit } from '@angular/core';
import { UtilService } from 'src/app/core/utils/utility-helper';
declare const JsBarcode: any;

@Component({
  selector: 'app-print-import',
  templateUrl: './print-import.component.html',
  styleUrls: ['./print-import.component.scss']
})
export class PrintImportComponent implements OnInit {
  @Input() data: any;
  @Input() selectedImportIndent: any;
  @Input() dataId: any;
  @Input() ofsType: any;
  totalQty:any;
  totalBls:any;
  totalapBasic:any;
  totalapbclValue:any;
  totalinvoiceValue:any;
  totalexportFee:any;
  totalcst:any;
  totaltcs:any;
  totalcommision:any;
  totalValue:any;
  totalDatas:any={};
  today=new Date();
  formData:any=[];
  dayRegisterData:any=[];
  formsData:any=[];
  challanData:any=[];
  prevChallanData:any=[];
  validityDate:any;
  userDetails:any;
  isMs:boolean=false;
  isMain:boolean=false;
  isForeignProduct: any = false;
  isRtd:any = false;

  constructor(public util:UtilService) { 
    this.userDetails = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
  }

  ngOnInit(): void {  
    this.setTotal();
    if (this.selectedImportIndent) {
      var date = new Date(this.selectedImportIndent.indentApprovedDate);
      this.validityDate = date.setDate(date.getDate() + 30);
      if (this.selectedImportIndent.importNumber) {
        JsBarcode('#barcode-import', this.selectedImportIndent.importNumber,
          {
            width: 1,
            height: 40,
            fontSize: 16
          }
        );
      }
      this.selectedImportIndentTotal();

        const disilleryname = this.userDetails.role === 'NonLocal Supplier Manager' || this.userDetails.role === 'NonLocal Brewery Manager' ? this.userDetails?.distillery_unit :  (this.selectedImportIndent?.distilleryUnit ? this.selectedImportIndent?.distilleryUnit : this.selectedImportIndent?.supplierCode);
        if(this.selectedImportIndent.supplierCode === disilleryname){
            this.isMain = true;
        }else{
          this.isMain = false;
        }
      
      if(this.selectedImportIndent?.supplierCode){
       const isMs= (this.userDetails.role === 'Material Management' || this.userDetails.role === 'Purchase Order Manager') ? 
       (this.util.formatString(this.selectedImportIndent?.distilleryUnit)) : 
       this.util.formatString(this.selectedImportIndent?.supplierCode)
       if(isMs){
        if(isMs.substring(0, 3) === 'M/s' || isMs.substring(0, 3) === 'M/S' || isMs.substring(0, 4) === ' M/s' || isMs.substring(0, 4) === ' M/S'){
          this.isMs = true;
         }else{
          this.isMs = false;
         }
       }
     
      }
    } else {
      this.totavlValue();
    }
  }

  ngAfterViewInit(): any {    
  }

  totavlValue() {
    // if (this.data && this.data.length) {
    //   this.data.forEach(element => {
    //     if(element && element.productDetails && element.productDetails.length){
    //       element.productDetails.forEach((item: any) => {
           
    //         if (element.ofsType === 'Non Local Brewery') {
    //           item.healFee = 0;
    //         }
    //         this.totalQty += item.approvedQty ? parseInt(item.approvedQty) : 0;
    //         this.totalBls += item.bls ? parseFloat(item.bls) : 0;
    //         this.totalapBasic += item.apBasic ? parseFloat(item.apBasic) : 0;
    //         this.totalapbclValue += item.apbclValue ? parseFloat(item.apbclValue) : 0;
    //         this.totalinvoiceValue += item.invoiceValue ? parseFloat(item.invoiceValue) :0;
    //         this.totalexportFee += item.exportFee ? parseFloat(item.exportFee) : 0;
    //         this.totalcst += item.cst ? parseFloat(item.cst) :0;
    //         this.totaltcs += item.tcs ? parseFloat(item.tcs) :0;
    //         this.totalcommision += item.commision ? parseFloat(item.commision) :0;
    //         this.totalValue += item.value ? parseFloat(item.value) : 0;
    //         this.totalDatas.qtyTtl += item.approvedQty ? item.approvedQty :0;
    //         this.totalDatas.ttlPls += item.pls ? item.pls :0;
    //         this.totalDatas.ttlBls += item.bls ? item.bls :0;
    //         this.totalDatas.ttlCvdFee += item.cvdFee ? item.cvdFee :0;
    //         this.totalDatas.ttlImportFee += item.importFee ? item.importFee :0;
    //         this.totalDatas.ttlHealFee += item.healFee ? item.healFee :0;
    //         this.totalDatas.ttsFee += item.ttsFee ? item.ttsFee :0;
    //       });
    //     }
    //   });      
    // }
    const disilleryname = this.userDetails.role === 'NonLocal Supplier Manager' || this.userDetails.role === 'NonLocal Brewery Manager' ? this.userDetails?.distillery_unit :  (this.data[0]?.distilleryUnit ? this.data[0]?.distilleryUnit : this.data[0]?.supplierCode);
    if(this.data[0].supplierCode === disilleryname){
      this.isMain = true;
  }else{
    this.isMain = false;
  }
  
  if(this.data[0]?.supplierCode){
   const isMs= this.util.formatString(this.data[0]?.supplierCode);
   if(isMs.substring(0, 3) === 'M/s' || isMs.substring(0, 3) === 'M/S' || isMs.substring(0, 4) === ' M/s' || isMs.substring(0, 4) === ' M/S'){
    this.isMs = true;
   }else{
    this.isMs = false;;
   }
  }
    
    this.challanData= [];
    const datas: any = [];    
    if (this.data && this.data.length) {
      this.data.forEach(element => {
        let challanData:any ={};
        let commissionData;
        let exportData;
        let ealData;
        let TTSData

        element.approvedQty = 0;
        element.cvdFee = 0;
        element.importFee = 0;
        element.healFee = 0;
        element.ttsFee = 0;
        element.prodTtsFee=0;
        console.log(element.challanDetails)
        if(element.challanDetails && element.challanDetails.length){
          element.challanDetails.forEach((challan:any)=>{
            challan.challanCategory = challan.oldChallan ? 'Previous Challan' : '-'
            if(challan.challanType ==='CVD'){
              commissionData = `${challan.actualDepositedAmount} / ${challan.challanNumber ? challan.challanNumber : '-'}`
              element.commisionValue = challan.amountUsed;
              element.commisionChallanNo = challan.challanNumber;
              element.comissionChallanType= element.challanType;
              element.comissionInitialAmount = element.initialAmount;
              element.oldCvdCarryForwardAmount = element.oldCarryForwardAmount;
              element.comissionUtilize = challan.amountUsed;
              element.comissionBal = element.remainingAmount;
              this.totalDatas.commisionFeeUtilized += challan.remainingAmount;
              // this.totalDatas.comisionAdv= challan.actualDepositedAmount;
            }
            else if(challan.challanType ==='Import Fee' || challan.challanType ==='ImportFee'){
              exportData = `${challan.actualDepositedAmount} / ${challan.challanNumber ? challan.challanNumber : '-'}`
              element.exportValue = challan.amountUsed;
              element.exportChallanNo = challan.challanNumber;
              element.cexportChallanType= element.challanType;
              element.exportInitialAmount = element.initialAmount;
              element.oldImCarryForwardAmount = element.oldCarryForwardAmount;
              element.exportUtilize = challan.amountUsed;
              element.exportBal = element.remainingAmount;
              this.totalDatas.exportFeeUtilized += challan.remainingAmount;
              // this.totalDatas.expAdv = challan.actualDepositedAmount;
            }
            else if(challan.challanType ==='EAL'){
              ealData = `${challan.actualDepositedAmount} / ${challan.challanNumber ? challan.challanNumber : '-'}`
              element.EalValue = challan.amountUsed;
              element.ealChallanNo = challan.challanNumber;
              element.ealChallanType= element.challanType;
              element.ealInitialAmount = element.initialAmount;
              element.oldEalCarryForwardAmount = element.oldCarryForwardAmount;
              element.ealUtilize = challan.amountUsed;
              element.ealBal = element.remainingAmount;
              this.totalDatas.ealUtilized += challan.remainingAmount;
              // this.totalDatas.ealAdv = challan.actualDepositedAmount;
            }
            else if(challan.challanType === 'TTS'){
              TTSData = `${challan.actualDepositedAmount} / ${challan.challanNumber ? challan.challanNumber : '-'}`
              element.TTSValue = challan.entityType === 'Breweries' ? challan.amountUsed : 0;
              element.ttsChallanNo = challan.challanNumber;
              element.ttsChallanType= element.challanType;
              element.ttsInitialAmount = challan.entityType === 'Breweries' ? element.initialAmount :0;
              element.oldTtsCarryForwardAmount = element.oldCarryForwardAmount;
              element.ttsUtilize = challan.entityType === 'Breweries' ? challan.amountUsed :0;
              element.ttsBal = challan.entityType === 'Breweries' ? element.remainingAmount :0;
              this.totalDatas.ttsUtilized += challan.entityType === 'Breweries' ? challan.remainingAmount :0;
              // this.totalDatas.ttsAdv = challan.entityType === 'Breweries' ? challan.actualDepositedAmount :0;
            }
            challanData = {commissionData,exportData,ealData,TTSData};
            let challansDetails:any=[];
            const isExist =  this.challanData.find(el=> el.challanNumber === challan.challanNumber && el.challanType === challan.challanType);
            if(!isExist){
              this.challanData.push(challan);
            }
            if(challan.previousChallans && challan.previousChallans.length){
              challan.previousChallans.map((prevChallan)=>{
                prevChallan.challanCategory = 'Previous Challan'
                const isPrevExist =  this.prevChallanData.find(el=> el.challanNumber === prevChallan.challanNumber);
                if(!isPrevExist && prevChallan.type){
                  this.prevChallanData.push(prevChallan)
                }
              })
            
            }
            let finalChallansData:any=[];
            let initialChallans:any=[]
            finalChallansData = [...this.challanData,...this.prevChallanData]
            initialChallans  = finalChallansData.filter(
              (obj, index, self) =>
                index === self.findIndex((o) => o.challanNumber === obj.challanNumber)
            );
            this.challanData= [...initialChallans]
            console.log(this.challanData,this.prevChallanData,initialChallans)

          })
            // this.totalDatas.advances = this.totalDatas.comisionAdv + this.totalDatas.expAdv + this.totalDatas.ealAdv + this.totalDatas.ttsAdv;
            this.totalDatas.utilizedAmount =this.totalDatas.commisionFeeUtilized+this.totalDatas.exportFeeUtilized+this.totalDatas.ealUtilized;
        }
        this.isForeignProduct = element.productDetails && element.productDetails.length && element.productDetails.some(pd => pd.category && pd.category === 'FOREIGN');
        element.productDetails.forEach((item:any) => {
          if (this.ofsType === 'Non Local Brewery') {
            item.healFee = 0;
          }
          if(element.consignmentType === 'SACR' && item.totalValue){
            item.importFee = item.totalValue
          }
          element.prevCvdChallan = 0;
          element.prevEalChallan =0;
          element.prevImChallan =0;
          this.prevChallanData.forEach((prevElement)=>{
            if(prevElement.challanType === 'CVD' && prevElement.type && !prevElement.oldChallan){
              element.prevCvdChallan = prevElement.remainingAmount ? prevElement.remainingAmount :0;
            }
            if(prevElement.challanType === 'EAL'  && prevElement.type&& !prevElement.oldChallan){
              element.prevEalChallan = prevElement.remainingAmount ? prevElement.remainingAmount :0;
            }
            if(prevElement.challanType === 'Import Fee' || prevElement.challanType === 'ImportFee' && prevElement.type&& !prevElement.oldChallan){
              element.prevImChallan = prevElement.remainingAmount ? prevElement.remainingAmount :0;
            }
            if(prevElement.challanType === 'TTS Fee' && prevElement.type&& !prevElement.oldChallan){
              element.prevTtsChallan =prevElement.remainingAmount ? prevElement.remainingAmount :0;
            }
          })
          element.prodTtsFee += item.ttsFee ? item.ttsFee : 0;
          item.cvdFee = this.isForeignProduct ? 0 :item.cvdFee;
          item.prodCommisionValue = item.commision ? parseFloat(item.commision) :0;
          item.prodExportValue = item.exportFee ? parseFloat(item.exportFee) :0;
          this.totalDatas.totalQty += element.consignmentType != 'SACR' ? (item.approvedQty ? parseInt(item.approvedQty) : 0) : (item.approvedBottles ? item.approvedBottles :0);
          this.totalDatas.prodExportValue += item.prodExportValue;
          this.totalDatas.prodCommisionValue += item.prodCommisionValue;
          this.totalDatas.ttlCvdFee += this.isForeignProduct ? 0 : (item.cvdFee ? item.cvdFee :0);
          this.totalDatas.ttlImportFee += item.importFee ? item.importFee :0;
          this.totalDatas.ttlHealFee += item.healFee ? item.healFee :0;
          this.totalDatas.ttsFee += element.ofsType === 'Non Local Breweries' ? (item.ttsFee ? item.ttsFee :0) : 0;
          this.totalDatas.ttsFeeTotal += item.ttsFee ? item.ttsFee: 0;
          datas.push({...element, ...item, ...challanData});          
        });
        this.totalDatas.commisionValue += element.commisionValue ? element.commisionValue :0;
        this.totalDatas.exportValue += element.exportValue ? element.exportValue :0;
        this.totalDatas.ealValue += element.EalValue ? element.EalValue :0
        this.totalDatas.ttsValue += element.TTSValue ? element.TTSValue :0
        this.totalDatas.comissionInitialAmount += element.comissionInitialAmount ? element.comissionInitialAmount :0;
        this.totalDatas.exportInitialAmount += element.exportInitialAmount ? element.exportInitialAmount :0;
        this.totalDatas.ealInitialAmount += element.ealInitialAmount ? element.ealInitialAmount :0;
        this.totalDatas.ttsInitialAmount += element.ttsInitialAmount ? element.ttsInitialAmount :0;
        this.totalDatas.oldCvdCarryForwardAmount +=  element.oldCvdCarryForwardAmount ? element.oldCvdCarryForwardAmount :0;
        this.totalDatas.oldImCarryForwardAmount += element.oldImCarryForwardAmount ? element.oldImCarryForwardAmount :0;
        this.totalDatas.oldEalCarryForwardAmount += element.oldEalCarryForwardAmount ? element.oldEalCarryForwardAmount :0;
        this.totalDatas.oldTtsCarryForwardAmount +=element.oldTtsCarryForwardAmount ? element.oldTtsCarryForwardAmount :0;
        this.totalDatas.comissionUtilize += element.comissionUtilize ? element.comissionUtilize :0;
        this.totalDatas.exportUtilize += element.exportUtilize ? element.exportUtilize :0;
        this.totalDatas.comissionBal += element.comissionBal ? element.comissionBal :0;
        this.totalDatas.exportBal += element.exportBal ? element.exportBal :0;

        this.totalDatas.prevCvdChallan = element.prevCvdChallan ? element.prevCvdChallan :0;
        this.totalDatas.prevEalChallan = element.prevEalChallan ? element.prevEalChallan :0;
        this.totalDatas.prevImChallan =  element.prevImChallan ? element.prevImChallan :0;
        this.totalDatas.prevTtsChallan = element.prevTtsChallan ? element.prevTtsChallan :0;
      });
    }


    this.dayRegisterData = [];
    if (datas && datas.length) {
      const dayRegisterData: any = [];
      datas.forEach(element => {
        const isExist = dayRegisterData.find(el =>el.onlineRefNo === element.onlineRefNo);
        if (isExist){            
          isExist.approvedQty += element.approvedQty;
          isExist.cvdFee += this.isForeignProduct ? 0 : element.cvdFee;
          isExist.importFee += element.importFee;
          isExist.healFee += element.healFee;
          isExist.ttsFee += element.ttsFee;
          return;
        }
        dayRegisterData.push(element);
      });
      this.dayRegisterData = dayRegisterData;
    }
    if(this.challanData && this.challanData.length){
      this.challanData.map((cd:any)=>{
        if(!cd.oldChallan){
          this.totalDatas.advances += cd.actualDepositedAmount ? cd.actualDepositedAmount :0;
        }
        if(cd.challanType === 'CVD' && !cd.oldChallan){
          this.totalDatas.comisionAdv += cd.actualDepositedAmount ? cd.actualDepositedAmount :0;
        }
        if(cd.challanType === 'EAL' && !cd.oldChallan){
          this.totalDatas.ealAdv += cd.actualDepositedAmount ? cd.actualDepositedAmount :0;
        }
        if(cd.challanType === 'TTS' && !cd.oldChallan){
          this.totalDatas.ttsAdv += cd.actualDepositedAmount ? cd.actualDepositedAmount :0;
        }
        if(cd.challanType ==='Import Fee' || cd.challanType ==='ImportFee' && !cd.oldChallan){
          this.totalDatas.expAdv += cd.actualDepositedAmount ? cd.actualDepositedAmount :0;
        }

      })
    }
    this.formData = datas;
  }
  selectedImportIndentTotal(): any {
    const element = this.selectedImportIndent;
    element.prodTtsFee = 0;    
    this.isForeignProduct = element.productDetails && element.productDetails.length && element.productDetails.some(pd => pd.category && pd.category === 'FOREIGN');
    // this.isRtd = element.productDetails && element.productDetails.length && element.productDetails.some(pd => pd.productCode && this.util.productCodeToDetails(pd.productCode).liquorType === 'M');
    element.productDetails.forEach((item:any)=>{
      if (this.ofsType === 'Non Local Brewery') {
        item.healFee = 0;
      }
      if(element.consignmentType === 'SACR' && item.totalValue){
        item.importFee = item.totalValue
      }
      if(item.productCode && this.util.productCodeToDetails(item.productCode).liquorType === 'M'){
        item.isRtd = true;
      }else{
        item.isRtd = false;
      }
      
      element.prodTtsFee += item.ttsFee ? item.ttsFee : 0;
      item.prodCommisionValue = item.commision ? parseFloat(item.commision) :0;
      item.prodExportValue = item.exportFee ? parseFloat(item.exportFee) :0;
      this.totalDatas.totalQty += element.consignmentType != 'SACR' ? (item.approvedQty ? parseInt(item.approvedQty) : 0) : (item.approvedBottles ? item.approvedBottles :0);
      this.totalDatas.prodExportValue += item.prodExportValue;
      this.totalDatas.prodCommisionValue += item.prodCommisionValue;
      this.totalDatas.ttlCvdFee += this.isForeignProduct ? 0 : (item.cvdFee ? item.cvdFee :0);
      this.totalDatas.ttlImportFee += item.importFee ? item.importFee :0;
      this.totalDatas.ttlHealFee += item.healFee ? item.healFee :0;
      this.totalDatas.ttsFee += item.ttsFee ? item.ttsFee :0;
      
      this.totalDatas.commisionValue += element.commisionValue ? element.commisionValue :0;
      this.totalDatas.exportValue += element.exportValue ? element.exportValue :0;
      this.totalDatas.ealValue += element.EalValue ? element.EalValue :0
      this.totalDatas.ttsValue += element.TTSValue ? element.TTSValue :0
      this.totalDatas.comissionInitialAmount += element.comissionInitialAmount ? element.comissionInitialAmount :0;
      this.totalDatas.exportInitialAmount += element.exportInitialAmount ? element.exportInitialAmount :0;
      this.totalDatas.ealInitialAmount += element.ealInitialAmount ? element.ealInitialAmount :0
      this.totalDatas.ttsInitialAmount += element.ttsInitialAmount ? element.ttsInitialAmount :0
      this.totalDatas.comissionUtilize += element.comissionUtilize ? element.comissionUtilize :0;
      this.totalDatas.exportUtilize += element.exportUtilize ? element.exportUtilize :0;
      this.totalDatas.comissionBal += element.comissionBal ? element.comissionBal :0;
      this.totalDatas.exportBal += element.exportBal ? element.exportBal :0;

      this.totalDatas.qtyTtl += item.approvedQty ? item.approvedQty :0;
      this.totalDatas.ttlBls += item.bls ? item.bls :0;
      this.totalDatas.ttlPls += (item.pls && item.isRtd === false) ? item.pls :0;
    });
  }
  setTotal(): any {
    this.totalQty = 0;
    this.totalBls =0;
    this.totalapBasic=0;
    this.totalapbclValue= 0;
    this.totalinvoiceValue= 0;
    this.totalexportFee= 0;
    this.totalcst= 0;
    this.totaltcs= 0;
    this.totalcommision= 0;
    this.totalValue = 0;
    this.totalDatas.totalQty = 0;
    this.totalDatas.commisionValue =0;
    this.totalDatas.exportValue =0;
    this.totalDatas.ealValue =0;
    this.totalDatas.ttsValue =0;
    this.totalDatas.comissionInitialAmount =0;
    this.totalDatas.exportInitialAmount =0;
    this.totalDatas.ealInitialAmount=0;
    this.totalDatas.ttsInitialAmount=0
    this.totalDatas.oldCvdCarryForwardAmount = 0;
    this.totalDatas.oldImCarryForwardAmount = 0;
    this.totalDatas.oldEalCarryForwardAmount = 0;
    this.totalDatas.oldTtsCarryForwardAmount = 0;

    this.totalDatas.comissionUtilize =0;
    this.totalDatas.exportUtilize =0;
    this.totalDatas.comissionBal =0;
    this.totalDatas.exportBal =0;
    this.totalDatas.prodCommisionValue =0;
    this.totalDatas.prodExportValue = 0;
    this.totalDatas.advances=0;
    this.totalDatas.utilizedAmount =0
    this.totalDatas.commisionFeeUtilized=0;
    this.totalDatas.exportFeeUtilized= 0;
    this.totalDatas.ealUtilized =0;
    this.totalDatas.ttsUtilized =0;
    this.totalDatas.comisionAdv =0
    this.totalDatas.expAdv =0;
    this.totalDatas.ealAdv=0;
    this.totalDatas.ttsAdv=0;
    this.totalDatas.totalQty =0;
    this.totalDatas.qtyTtl =0;
    this.totalDatas.ttlPls =0;
    this.totalDatas.ttlBls =0;
    this.totalDatas.ttlCvdFee=0;
    this.totalDatas.ttlImportFee=0;
    this.totalDatas.ttlHealFee=0;
    this.totalDatas.ttsFee = 0;
    this.totalDatas.ttsFeeTotal = 0;

    this.totalDatas.prevCvdChallan = 0;
    this.totalDatas.prevImChallan = 0;
    this.totalDatas.prevEalChallan = 0;
    this.totalDatas.prevTtsChallan = 0
  }
  getDefaultHours(date): any {
    if (date.match(/Z/)) {
      date = date.substring(0,10);
    }
    // console.log('date', date);
    const newDate = new Date(date);
    newDate.setHours(0,0,0);
    // console.log('newDate', newDate);
    return newDate;
  }
}
