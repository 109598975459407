import { InterDepotService } from './../../../../../core/services/inter-depot.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { arrayMax } from 'highcharts';
import * as moment from 'moment';
declare const JsBarcode: any;
@Component({
  selector: 'app-idtreceipt-gatepass-info-dialog',
  templateUrl: './idtreceipt-gatepass-info-dialog.component.html',
  styleUrls: ['./idtreceipt-gatepass-info-dialog.component.scss']
})
export class IDTReceiptGatepassInfoDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'no',
    'importPermitNumber',
    'supplier',
    'transporter',
    'vechicleNumber',
    'received',
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  selectedOfsForPrint: any;
  roleName: any;
  depotName:any;
  address:any;

  constructor(
    public dialogRef: MatDialogRef<IDTReceiptGatepassInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private interDepotService: InterDepotService
  ) {
      this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
      this.depotName =JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).entity_id;
      this.address = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).depot_address;

    }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource([this.data]);
    console.log(this.data)
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

  generateGatePass(): any {
    const payload: any = {
      action: 'generate_gatepass',
      data: {
        tpNo: this.data.tpNo
      }
    };
    if (this.data.btpNo) {
      delete payload.data.tpNo;
      payload.data.btpNo = this.data.btpNo;
    } else if (this.data.bipNo) {
      delete payload.data.tpNo;
      payload.data.bipNo = this.data.bipNo;
    }
    this.interDepotService.generateActions(payload).subscribe(result => {
     this.dialogRef.close('Generated successfully');
    });
  }

  convertToDate(date) {
    return date ? moment(date).format('DD/MM/YY, h:mm a') : '-';
  }
  convertToDateOnly(date) {
    return moment(date).format('DD/MM/YY');
  }
  ngAfterViewInit(): any {
    if (this.data.chkNO) {
      JsBarcode('#barcode-gatepass', this.data.slNo,
        {
          width: 1,
          height: 40
        }
      );
    }
  }
  print(){
    setTimeout(() => {
      this.getPrint();
    }, 100);
  }
  getPrint(){
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.write(`${printContents}`);
    popupWin.document.close();
  }
}
