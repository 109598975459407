import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const roleUrl = baseUrl + 'user_roles';
const moduleUrl = baseUrl + 'modules';
const featureUrl = baseUrl + 'features';

const featurePermissionUrl = baseUrl + 'feature_permissions'

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['User Roles'] && userModules.mod_feats['User Roles'].features ? userModules.mod_feats['User Roles'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class RoleModuleFeaturesService {

  constructor(private http: HttpClient) { }

  getMethod(url, params): any {
    // const headerDict = {
    //   feature
    // };
    return this.http.get(baseUrl + url, { params });
  }

  createMethod(url, params): any {
    return this.http.post(baseUrl + url, params);
  }
  updateMethod(url, params): any {
    return this.http.put(baseUrl + url, params);
  }
  updateFeaturePermission(params): any {
    return this.http.put(featurePermissionUrl, params);
  }
  getUserRoles(): any {
    const headerDict = {
      feature
    };
    return this.http.get(roleUrl, { headers: headerDict });
  }
  getModules(): any {
    return this.http.get<any>(moduleUrl);
  }
  getFeatures(): any {
    return this.http.get<any>(featureUrl);
  }
}
