import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonsService } from 'src/app/core/services/commons.service';

@Component({
  selector: 'app-manage-challan-dialog',
  templateUrl: './manage-challan-dialog.component.html',
  styleUrls: ['./manage-challan-dialog.component.scss']
})
export class ManageChallanDialogComponent implements OnInit {
  challanType:any=['CVD','Import Fee','EAL'];
  challanDetailsForm:FormGroup;
  roleName: any = '';
  isImportFee: any = false;
  isCarryForward:boolean=false;
  disableImportFeeCtrls: any = false;

  constructor(private fb: FormBuilder, private shipmentService: ShipmentsService,
    private dialogRef: MatDialogRef<ManageChallanDialogComponent>,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private commonsService: CommonsService
  ) { }

  ngOnInit(): void {
    this.challanDetailsForm = this.fb.group({
      challanType: ['', Validators.required],
      depositedAmount: ['', Validators.required],
      challanDate: ['', Validators.required],
      challanNumber: ['', Validators.required],
      bankName: ['', Validators.required],
      challanPlace: ['', Validators.required],
      cfmsId: ['']
      // carryForward:['',Validators.required]

    });
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    if (this.roleName === 'NonLocal Brewery Manager') {
      this.challanType = ['CVD','Import Fee'];
    }
  }

  getImportPaymentDetails(): any {
    this.disableImportFeeCtrls = false;
    if (!this.challanDetailsForm.value.cfmsId) {
      return;
    }
    // tslint:disable-next-line:variable-name
    const req_data = {
      action: 'cfm_validator',
      q: JSON.stringify({data:{ cfms_txn_id: this.challanDetailsForm.value.cfmsId}})
    };
    this.spinner.show();
    this.commonsService.paymentDetails(req_data).subscribe((res: any) => {
      if (res && res.status && res.status.error) {
        this.messageService.add({ severity: 'error', detail: res.status.error});
        this.resetChallanNumber();
      } else if (res.status.total_amt) {
        this.updateImportFee(res.status);
        this.disableImportFeeCtrls = true;
      }
      this.spinner.hide();
    }, err => {
      this.messageService.add({ severity: 'error', detail: 'Error'});
      this.reset();
      this.spinner.hide();
    });
  }

  createChallan(){
    console.log(this.challanDetailsForm.value);
    const reqData:any ={
      action:'add_ip_challan',
      data:this.challanDetailsForm.value
    }
    // delete reqData.data.carryForward;
    // if(this.isCarryForward === true){
    //   reqData.data.carryForward = true;
    // }
    if (this.isImportFee) {
      delete reqData.data.cfmsId; 
    }
    console.log(reqData)
    this.spinner.show();

    this.shipmentService.createICDC(reqData).subscribe((res:any)=>{
      console.log(res)
      if(res.status === 'SUCCESS'){
        this.dialogRef.close('Challan Added Successfully');
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Challan not added' });
      }
      this.disableImportFeeCtrls = false;
    })
    this.spinner.hide();

  }
  getPaymentDetails(): any {
    // if (this.roleName !== 'NonLocal Brewery Manager') {
    //   return;  
    // }
    if (!this.challanDetailsForm.value.challanNumber) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Please enter challan number' });
      return;
    }
    let qData = {
      "data": { "value": this.challanDetailsForm.value.challanNumber, "action": "get_razorpay_payment_details" }
    };
    const req_Data = {
      "action": "common_apis",
      "q": JSON.stringify(qData)
    };
    this.spinner.show();
    // this.challanDetailsForm.controls.challanPlace.setValidators(null);
    this.commonsService.getCommonEntites(req_Data).subscribe((res:any)=>{
      if(res && res.get_razorpay_payment_details && res.get_razorpay_payment_details.amount) {
        this.challanDetailsForm.patchValue({
          challanType: (res.get_razorpay_payment_details.purpose === 'Excise Duty' || res.get_razorpay_payment_details.purpose === 'CVD' ) ? 'CVD' : ( res.get_razorpay_payment_details.purpose === 'Beer TTS Payment Transactions' ? 'TTS' : res.get_razorpay_payment_details.purpose === 'Heal Fee' ? 'EAL' : ''),
          depositedAmount: res.get_razorpay_payment_details.amount,
          challanDate: new Date(res.get_razorpay_payment_details.bankTransactionDate),
          bankName: res.get_razorpay_payment_details.bankName,
          // challanPlace: '',
        });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: res?.get_razorpay_payment_details?.status ? res.get_razorpay_payment_details.status : 'Cant able fetch the payment details' });
      }
    })
    this.spinner.hide();
  }
  onKeypressEvent(event : any){
    return event.charCode >= 48
  }
  resetImportFee(): any {
    this.challanDetailsForm.patchValue({
      depositedAmount: '',
      challanDate: '',
      bankName: '',
      challanPlace: '',
    });
  }
  resetChallanNumber(): any {
    this.challanDetailsForm.patchValue({
      challanNumber: '',
      cfmsId: ''
    });
  }
  reset(): any {
    this.challanDetailsForm.patchValue({
      challanType: '',
      depositedAmount: '',
      challanDate: '',
      bankName: '',
      challanPlace: '',
    });
  }
  changeImportFee(evt): any {
    this.challanDetailsForm.reset();
    if (evt.checked === true) {
      this.isImportFee = true;
      this.challanDetailsForm.controls.challanType.setValue('Import Fee');
    } else {
      this.isImportFee = false;
    }
  }
  updateImportFee(data: any): any {
    this.challanDetailsForm.patchValue({
      depositedAmount: Number(parseFloat(data.total_amt).toFixed(2)) || 0,
      challanDate: data.bankdate ? this.getDateByString(data.bankdate) : '',
      bankName: '',
      challanPlace: '',
      challanNumber: data.challannumber ? data.challannumber : ''
    });
  }
  isCarryChallan(e:any){
    const isCarry = e.value;
    isCarry === 'no' ? this.isCarryForward = true : this.isCarryForward = false;
    console.log(this.isCarryForward)
  }
  getDateByString(text: any): any {
    const year = text.substring(0, 4);
    const month = text.substring(4,6);
    const date = text.substring(6,8);
    return new Date(`${year}-${month}-${date}`);
    
  }

}
