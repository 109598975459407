import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


const baseUrl = environment.baseUrl;
const brandRegistrationUrl = baseUrl + 'brand_registration';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Brand Registration'] && userModules.mod_feats['Brand Registration'].features ? userModules.mod_feats['Brand Registration'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class BrandRegistrationService {

  constructor(private http: HttpClient) { }



  creteBrand(req_Data) {
    return this.http.post(brandRegistrationUrl, req_Data);
  }
  getBrandRegistrationFeatures() {
    const headerDict = {
      feature
    }
    return this.http.get(brandRegistrationUrl, { headers: headerDict });
  }
}
