export const bottleDetails = [
    { bottleCode: 'AF', volumeInML: 50, unitsPerCase: 192 },
    { bottleCode: 'AC', volumeInML: 50, unitsPerCase: 120 },
    { bottleCode: 'OO', volumeInML: 60, unitsPerCase: 150 },
    { bottleCode: 'DD', volumeInML: 90, unitsPerCase: 96 },
    { bottleCode: 'NN', volumeInML: 180, unitsPerCase: 48 },
    { bottleCode: 'CP', volumeInML: 200, unitsPerCase: 24 },
    { bottleCode: 'CN', volumeInML: 200, unitsPerCase: 48 },
    { bottleCode: 'ZP', volumeInML: 250, unitsPerCase: 24 },
    { bottleCode: 'GP', volumeInML: 275, unitsPerCase: 24 },
    { bottleCode: 'UP', volumeInML: 330, unitsPerCase: 24 },
    { bottleCode: 'DP', volumeInML: 350, unitsPerCase: 24 },
    { bottleCode: 'BI', volumeInML: 355, unitsPerCase: 24 },
    { bottleCode: 'SA', volumeInML: 360, unitsPerCase: 20 },
    { bottleCode: 'PP', volumeInML: 375, unitsPerCase: 24 },
    { bottleCode: 'AP', volumeInML: 500, unitsPerCase: 24 },
    { bottleCode: 'BS', volumeInML: 650, unitsPerCase: 12 },
    { bottleCode: 'BA', volumeInML: 650, unitsPerCase: 20 },
    { bottleCode: 'JQ', volumeInML: 700, unitsPerCase: 12 },
    { bottleCode: 'JM', volumeInML: 700, unitsPerCase: 6 },
    { bottleCode: 'JG', volumeInML: 700, unitsPerCase: 4 },
    { bottleCode: 'JD', volumeInML: 700, unitsPerCase: 2 },
    { bottleCode: 'JR', volumeInML: 700, unitsPerCase: 3 },
    { bottleCode: 'QK', volumeInML: 750, unitsPerCase: 1 },
    { bottleCode: 'QQ', volumeInML: 750, unitsPerCase: 12 },
    { bottleCode: 'QM', volumeInML: 750, unitsPerCase: 6 },
    { bottleCode: 'LL', volumeInML: 1000, unitsPerCase: 9 },
    { bottleCode: 'LQ', volumeInML: 1000, unitsPerCase: 12 },
    { bottleCode: 'BM', volumeInML: 1500, unitsPerCase: 6 },
    { bottleCode: 'XG', volumeInML: 2000, unitsPerCase: 4 },
    { bottleCode: 'KK', volumeInML: 30000, unitsPerCase: 1 },
    { bottleCode: 'FK', volumeInML: 50000, unitsPerCase: 1 }
];
