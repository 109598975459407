import { R3ResolvedDependencyType } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BlendTank } from 'src/app/core/models/distillery-info/blendTank.model';
import { Product } from 'src/app/core/models/production/product.model';
import { DistilleryInfoService } from 'src/app/core/services/distillery-info.service';
import { ProductionService } from 'src/app/core/services/production.service';

@Component({
  selector: 'app-manage-batch-production-dialog',
  templateUrl: './manage-batch-production-dialog.component.html',
  styleUrls: ['./manage-batch-production-dialog.component.scss'],
})
export class ManageBatchProductionDialogComponent implements OnInit {
  batchProductionForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  products: Product[] = [];
  blendTanks: BlendTank[]  = [];
  saveDisabled: boolean;
  // purposeTypes = ['Local', 'Export'];
  purposeTypes = ['Local', 'Export', 'CSD'];
  productionTypes = ['Manual', 'Semi Manual', 'Automation'];
  healStartCodes: any[];
  sizes: any[];
  packTypes: any[];
  startProductionData: any;
  submitData: any;
  submitData1: any;
  brandProducts: any = [];
  batchList: any = [];
  batchNumberData: any = [];
  brandCodeData: any=[];
  brandNameData: any=[];
  unitsPerCaseData: any = [];
  productCodeData: any = [];
  liquorTypeData: any = [];
  packTypeData: any = [];
  sizeData: any = [];

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageBatchProductionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private productionService: ProductionService, private disitilleryInfoService: DistilleryInfoService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.batchProductionForm = this.fb.group({
      /*  selectProductionType:[''], */
      healStartCode: [''],
      brandCode: [''],
      brandName: [''],
      size: [''],
      packType: [''],
      productCode: [''],
      unitsPerCase: [''],
      mrp: [''],
      batchId: [''],
      batchQuantity: [''],
      manufacturingDate: [''],
      /* operater: [''], */
      /*  printerFileName: [''], */
      purpose: [this.purposeTypes[0]],
      productionType: [''],
      strength: [''],
      /*  switch:[''], */
      targetCasesCount: [''],
      printerName: [''],
      liquorType: [''],
      healCodes: ['']
    });
    this.productionService.getStartProduction(this.data.productionId).subscribe(data => {
      this.startProductionData = data;
      this.setBatchDropDownData(data);
      this.batchProductionForm.patchValue({
        targetCasesCount: this.startProductionData.lineProduction['targetCasesCount'],
        printerName: this.startProductionData.lineProduction['printerName']
        //liquorType
      })
      if(this.startProductionData?.firstHealCode.length >=1 && this.batchProductionForm.controls.liquorType.value !=='BEER') {
        this.batchProductionForm.controls.healStartCode.setValidators([Validators.required])
      }
      this.submitData = {};
      this.submitData.targetCasesCount = this.startProductionData.lineProduction['targetCasesCount'];
      this.submitData.printerName = this.startProductionData.lineProduction['printerName'];
      this.spinner.hide();
    })
    this.getBrandCodes();
    this.getTankName();

    if (this.data && this.data._id) {
      this.batchProductionForm.patchValue({
        selectProductionType : this.data.selectProductionType,
        healStartCode: this.data.healStartCode,
        brandCode: this.data.brandCode,
        brandName: this.data.brandName,
        size: this.data.size,
        packType: this.data.packType,
        productCode: this.data.productCode,
        unitPerCase: this.data.unitPerCase,
        mRP: this.data.mRP,
        batchNo: this.data.batchNo,
        batchQty: this.data.batchQty,
        manufacturingDate: this.data.manufacturingDate,
        operater: this.data.operater,
        printerFileName: this.data.printerFileName,
        purpose: this.data.purpose,
        productionType: this.data.productionType,
        switch: this.data.switch
      });
    }
    this.batchProductionForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    })
  }

  setBatchDropDownData(data) {
    const totalBatchList = [];
    data.batches.forEach(batch => {
      const batchList = data.batches.filter(record => record.batchId === batch.batchId);
        const isBatchExist = totalBatchList.some(item => item && item.batchId === batch.batchId)
        if(!isBatchExist){
          totalBatchList.push({batchId: batch.batchId, brandDetails: batchList})
        }
    });
    this.batchList = totalBatchList;
    this.batchNumberData = totalBatchList.map(data => data.batchId);//data.batches.map(data => data.batchId);
    data.batches.forEach(batch => {
      if(this.brandCodeData.indexOf(batch.brandCode) == -1) {
        this.brandCodeData.push(batch.brandCode);
        this.brandNameData.push(batch.brandName);
      }
     
    })
  }

  setProductDropDownData(data) {
    this.productCodeData = data.map(data => data.productCode);
    this.sizeData = data.map(data => data.size);
    this.unitsPerCaseData = data.map(data => data.unitsPerCase);

    data.forEach(batch => {
      if(this.packTypeData.indexOf(batch.packType) == -1) {
        this.packTypeData.push(batch.packType);
        this.liquorTypeData.push(batch.liquorType);

      }
     
    })
  }
  get f(){
    return this.batchProductionForm.controls;
  }

  getBrandCodes() {
    // this.productionService.getProduct(0, 100).subscribe((res) => {
    //   this.products.push(...res.data);
    // });
  }

  getTankName(){
    // if (this.data && this.data._id) {
    //   this.disitilleryInfoService.getBlendTanks(0, 100, '').subscribe((res) => {
    //     this.blendTanks.push(...res.data);
    //   })
    // } else {
    //   this.disitilleryInfoService.getBlendTanks(0, 100, '', 'BTS-NIU-01').subscribe((res) => {
    //     this.blendTanks.push(...res.data);
    //   })
    // }

  }

  dataChange(event,name) {
    if(name === 'batchId'){
        this.brandNameData = this.batchList.find(data => data.batchId == event.value).brandDetails;
    }
    if(name === 'brandName'){
      console.log('eventL:::::::', event.value);
       this.brandProducts = event.value.brandProducts;
       this.setProductDropDownData(event.value.brandProducts);
      this.batchProductionForm.patchValue({
        brandCode: event.value.brandCode,
        batchId: event.value.batchId,
        strength: event.value.strength,
        batchQuantity: event.value.batchQuantity,
        
      });
    }
    // this.startProductionData.batches.forEach(data => {
    //   if(data[name] == event.value) {
    //     this.brandProducts = data.brandProducts;
    //     this.setProductDropDownData(data.brandProducts);
    //     this.batchProductionForm.patchValue({
    //       brandCode: data.brandCode,
    //       brandName: data.brandName,
    //       batchId: data.batchId,
    //       strength: data.strength,
    //       batchQuantity: data.batchQuantity,
          
    //     });
    //   }
    // });
  }

  productChange(event,name) {

    if(name == 'productCode') {

      this.brandProducts.forEach(brand => {
        if(event.value == brand.productCode) {
          this.batchProductionForm.patchValue({
            size: brand.size,
            packType: brand.packType,
            unitsPerCase: brand.unitsPerCase,
            mrp: brand.mrp,
            liquorType: brand.liquorType
  
           
          });
        }
      });
    }

    // else if((name == 'size' && this.batchProductionForm.get('packType').value) || (name == 'packType' && this.batchProductionForm.get('size').value)) {
    //   this.brandProducts.forEach(brand => {
    //     if(event.value == brand[name]) {
    //       this.batchProductionForm.patchValue({
    //         productCode: brand.productCode,
    //         size: brand.size,
    //         packType: brand.packType,
    //         unitsPerCase: brand.unitsPerCase,
    //         mrp: brand.mrp,
    //         liquorType: brand.liquorType
  
           
    //       });
    //     }
    //   });
    // }
    /* if(this.batchProductionForm.get('size').value) {

    } */

   /*  this.batchProductionForm.patchValue({
      productCode: event.value,
      brandName: event.value,
      batchQuantity: event.value
     
    }); */
  }

  selectProduct(e) {

  }

  createBatchProduction() {
    // this.saveDisabled = true;
    const req_Data = {
      action : 'initiate_prod',
      data : {
        ...this.batchProductionForm.value,
        brandName: this.batchProductionForm.value.brandName.brandName
      }
    }

     req_Data.data['productionId'] = this.data.productionId;
     if(this.data.productionType == 'BEER') {
       req_Data.data.productionType = this.data.productionType;
     }
     if(req_Data.data['productionType'] !== 'Semi Manual'){
      delete req_Data.data.healCodes;
    }
  /*  req_Data.data['manufacturingDate'] = this.batchProductionForm.value.manufacturingDate;
    req_Data.data['healStartCode'] = this.batchProductionForm.value.healStartCode; */
//healStartCode
    this.productionService.createBatchProduction(req_Data).subscribe((res: any) => {
      this.dialogRef.close('Batch Initiated Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  updateBatchProduction() {
    // this.saveDisabled = true;
    // const obj = [{
    //   action: 'edit_batch',
    //   data: this.batchProductionForm.value
    // }]
    // this.productionService.putBatch(obj).subscribe((res: any) => {
    //   this.dialogRef.close('Batch Udated Successfully');
    // }, (err) => {
    //   this.error = err.error.message;
    // });
  }

  // getTitle() {
  //   return this.data && this.data._id ? `Update Batch Production for ${this.data.batchNumber}` : 'Create Batch Production';
  // }

}
