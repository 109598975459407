import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Bars } from 'src/app/core/models/licensee-profiles/bars.model';
import { LicenseeProfilesService } from 'src/app/core/services/licensee-profiles.service';

@Component({
  selector: 'app-open-bars-customer-details-dialog',
  templateUrl: './open-bars-customer-details-dialog.component.html',
  styleUrls: ['./open-bars-customer-details-dialog.component.scss']
})
export class OpenBarsCustomerDetailsDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'no',
    // 'fatherName',
    // 'addressProof',
    'village',
    'mandal',
    'district',
    // 'action'
  ];
  dataSource: MatTableDataSource<Bars> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;

  constructor(public dialogRef: MatDialogRef<OpenBarsCustomerDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,
    private licenseeProfilesService: LicenseeProfilesService) { }

  ngOnInit(): void {
    console.log(this.data)
  }
}
