import { UtilService } from 'src/app/core/utils/utility-helper';
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CommonsService } from 'src/app/core/services/commons.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare const JsBarcode: any;
declare const jsPDF: any;
declare const html2canvas: any;
@Component({
  selector: "app-payment-confomation-dialog",
  templateUrl: "./payment-confomation-dialog.component.html",
  styleUrls: ["./payment-confomation-dialog.component.scss"],
})
export class PaymentConfomationDialogComponent implements OnInit {
  amount: any;
  distileryName:any;
  disableRedirect: any = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public util: UtilService,
    private commonService: CommonsService,
    public dialogRef: MatDialog,
    private spinner: NgxSpinnerService
  ) {
    this.distileryName = sessionStorage.getItem('lappCurrentUserModules') ? JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).entity_id: '';

  }

  ngOnInit() {
    console.log(this.data);
    if (this.data.paymentData) {
      this.printPDF();
    }
  }
  sendPdf(base64): any {
    if (this.data.paymentData.FLAG === 'Y') {
      const reqData: any = {
        action: 'upload_label_pdf_url',
        data: {
          applicationId: this.data.paymentData.APPLICATION_ID,
          base64key: base64 
        }
      }
      this.commonService.paymentResponse(reqData).subscribe((res: any) => {
        this.disableRedirect = false;
        this.spinner.hide();
      }, err => {
        this.spinner.hide();
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to upload label documents' });
      });
    } else {
      this.spinner.hide();
    }
  }

  print() {
    let printContents, popupWin;
    printContents = document.getElementById("print-section").innerHTML;
    popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
    popupWin.document.write(`${printContents}`);
    popupWin.document.close();
  }
  redirect(): any {
    let query = '';
    for (let entry in this.data.paymentData) {
      query += entry + '=' + encodeURIComponent(this.data.paymentData[entry]) + '&';
    }
    query = query.substring(0, query.length - 1);
    // const url = 'http://103.129.72.183/APIndus_Prod/UserInterface/SingleWindowServicesApplication/UserAccount/ExciseRetentionDetails.aspx';
    const url = 'http://103.129.72.183/APIndus_Prod/UserInterface/SingleWindowServicesApplication/UserAccount/ExciseAcknowldegment.aspx';
    window.location.href = `${url}?${query}`;
  }
  printPDF(): any {
    this.spinner.show();
    setTimeout(() => {
      if (document.getElementById('print-pdf-file')) {
        let DATA: any = document.getElementById('print-pdf-file');
        html2canvas(DATA, { scale: 5 }).then((canvas: any) => {
          let fileWidth = 208;
          let fileHeight = (canvas.height * fileWidth) / canvas.width;
          const FILEURI = canvas.toDataURL('image/jpeg');
          let doc = new jsPDF('p', 'mm', 'a4');
          let position = 0;
          doc.addImage(FILEURI, 'JPEG', 0, position, fileWidth, fileHeight);
          const base64 = doc.output('datauristring');
          this.sendPdf(base64);
        });
      }
    }, 500);
  }
}
