import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HealInventory } from '../models/holograms/heal-inventory';
import { HealPurchaseIndent } from '../models/holograms/heal-purchase-indents';
import { HealDamage } from '../models/holograms/heal-damage.model';
import { SuppliedTo } from '../models/holograms/supplied-to.model';
import { ProvidedBy } from '../models/holograms/provided-by.model';
import { HealDailyIndent } from '../models/holograms/heal-daily-indent.model';
import { HealProcess } from './../models/holograms/heal-process.model';

const baseUrl = environment.baseUrl;
const hologramsUrl = baseUrl + 'holograms';

const healInventoryUrl = baseUrl + 'healinventory';
const healPurchaseIndentsUrl = baseUrl + 'healpurchaseindent';
const healUsageUrl = baseUrl + 'healusage';
const healDamageUrl = baseUrl + 'healdamages';
const healVerificationUrl = baseUrl + 'healverification';
const suppliedToUrl = baseUrl + 'holograms/suppliedTo';
const providedByUrl = baseUrl + 'holograms/providedBy';
const healDailyIndentUrl = baseUrl + 'healdailyindent';
const healProcessUrl = baseUrl + 'healProcess';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Holograms'] && userModules.mod_feats['Holograms'].features ? userModules.mod_feats['Holograms'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class HologramsService {

  constructor(private http: HttpClient) { }

  createHealInventory(healInventoryobj: any){
    return this.http.post(hologramsUrl, healInventoryobj);
  }
  getHealInventory(offset, limit, search?: string, status?: string): Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    status ? paramObj.status = status : status = null;
    return this.http.get(healInventoryUrl, {params: paramObj});
  }

  getChallanDetails(params): Observable<any> {
    return this.http.get(hologramsUrl, {params});
  }

  getDownloadHeals(params): Observable<any> {
    return this.http.put(hologramsUrl, params);
  }

  putHealInventory(healInventoryObj){
    return this.http.put(hologramsUrl, healInventoryObj);
  }

  createHealPurchaseIndents(obj: any){
    return this.http.post(hologramsUrl, obj);
  }

  updateHealPurchaseIndents(obj: any){
    return this.http.put(hologramsUrl, obj);
  }

  getHealPurchaseIndents(offset, limit, search?: string): Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    return this.http.get(healPurchaseIndentsUrl, {params: paramObj});
  }

  putHealPurchaseIndents(healPurchaseIndentsObj){
    return this.http.put<HealPurchaseIndent>(`${healPurchaseIndentsUrl}`, healPurchaseIndentsObj);
  }

  getHealUsage(offset, limit, search?: string): Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    return this.http.get(healUsageUrl, {params: paramObj});
  }

  getHealDamage(offset, limit, search?: string): Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    return this.http.get(healDamageUrl, {params: paramObj});
  }

  putHealDamage(healDamageObj){
    return this.http.put<HealDamage>(`${healDamageUrl}`, healDamageObj);
  }

  getIndentManagementFeaturesByParams(paramObj): any {
    const headerDict = {
      feature
    };
    return this.http.get(hologramsUrl, { headers: headerDict, params: paramObj });
  }

  createHealDamage(healDamageobj: any){
    return this.http.post(healDamageUrl, healDamageobj);
  }

  createSuppliedTo(suppliedToobj: SuppliedTo){
    return this.http.post(suppliedToUrl, suppliedToobj);
  }

  getSuppliedTo(offset, limit, search?: string): Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    return this.http.get(suppliedToUrl, {params: paramObj});
  }

  putSuppliedTo(suppliedToObj){
    return this.http.put<SuppliedTo>(`${suppliedToUrl}`, suppliedToObj);
  }

  creatProvidedBy(providedByobj: ProvidedBy){
    return this.http.post(providedByUrl, providedByobj);
  }

  getProvidedBy(offset, limit, search?: string): Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    return this.http.get(providedByUrl, {params: paramObj});
  }

  putProvidedBy(providedByObj){
    return this.http.put<ProvidedBy>(`${providedByUrl}`, providedByObj);
  }

  getHealDailyIndentDetails(offset,limit,search) {
    return this.http.get('assets/samples/heal-daily-indent.json');
  }

  createHealDailyIndent(obj: any){
    return this.http.post(hologramsUrl, obj);
  }

  getHealInventoryDetails(offset,limit,search) {
    return this.http.get('assets/samples/heal-inventory.json');
  }

  getHealUsageDetails(offset,limit,search) {
    return this.http.get('assets/samples/heal-usage.json');
  }

  getHealDamageDetails(offset,limit,search) {
    return this.http.get(healDamageUrl);
  }

  getHealVerificationDetails(offset,limit,search) {
    return this.http.get(healVerificationUrl);
  }

  getHealPurchaseIndentDetails(offset,limit,search) {
    return this.http.get('assets/samples/heal-purchase-Indent.json');
  }

  createHealProcess(healProcessobj: HealProcess){
    return this.http.post(healProcessUrl, healProcessobj);
  }

  getHealProcess(offset, limit, search?: string, status?: string): Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    status ? paramObj.status = status : status = null;
    return this.http.get(healProcessUrl, {params: paramObj});
    // return this.http.get('assets/samples/heal-process.json');
  }

  getDepotHologramsDetails(offset,limit,search) {
    return this.http.get('assets/samples/depot.json');
  }

  getDistilleryHologramsDetails(offset,limit,search) {
    return this.http.get('assets/samples/depot.json');
  }

  getHologramsFeatures(reqObj: any = '') {
    const headerDict = {
      feature
    };
    return this.http.get(hologramsUrl, { headers: headerDict, params: reqObj });
  }

  getHpiDetails(q,action){
    const paramObj: any = {};
    q ? (paramObj.q = q) : (q = null);
    action ? (paramObj.action = action) : (action = null);
    return this.http.get(hologramsUrl, {params: paramObj});
  }

  onValidateCartons(q,action){
    const paramObj: any = {};
    q ? (paramObj.q = q) : (q = null);
    action ? (paramObj.action = action) : (action = null);
    return this.http.get(hologramsUrl, {params: paramObj});
  }

  completeSpoolRequest(obj: any){
    return this.http.put(hologramsUrl, obj);
  }

  deallocatedHeals(obj: any){
    return this.http.put(hologramsUrl, obj);
  }

}
