import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonsService } from 'src/app/core/services/commons.service';
import { IndentManagementService } from 'src/app/core/services/indent-management.service';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { MessageService } from 'primeng/api';
import { DynamicConfirmationDialogComponent } from 'src/app/core/components/dynamic-confirmation-dialog/dynamic-confirmation-dialog.component';
@Component({
  selector: 'app-open-action-update-bar-indent',
  templateUrl: './open-action-update-bar-indent.component.html',
  styleUrls: ['./open-action-update-bar-indent.component.scss']
})
export class OpenActionUpdateBarIndentComponent implements OnInit {

  icdcData = [];
  isUpdated: boolean = false;
  productsData: any;

  constructor(public dialogRef: MatDialogRef<OpenActionUpdateBarIndentComponent>, 
    @Inject(MAT_DIALOG_DATA)public data,
    public shipmentService: ShipmentsService,
    public indentManagementService: IndentManagementService,
    private spinner: NgxSpinnerService,
    public commonService: CommonsService,
    public util: UtilService,
    private messageService: MessageService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.productsData = JSON.parse(JSON.stringify(this.data));
  }

  dialogClose(): void{
    this.dialogRef.close();
  }
  cancelIndent(): any {
    this.spinner.show();    
    const revertPayload = {
      action: 'cancel_vendor_indent',
      data: {
        indentId: this.data.indentId,
        status: this.data.status
      }
    };
    this.indentManagementService.updateIndents(revertPayload).subscribe((res: any) => {
      if (res.status === 'SUCCESS') {
        this.dialogRef.close('Indent Cancelled Successfully');
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Cancelled' });
      }
      this.spinner.hide();
    }, (err) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Cancelled' });
      this.spinner.hide();
      //this.error = err.error.message;
    });
  }

  revertIndent(action){
    this.spinner.show();
   
    const productData = this.productsData;
  
    let productDetailsData = [];
    this.data.productDetails.forEach(item => {
      let changedProduct = productData.productDetails.find(product => product.productCode === item.productCode);
      productDetailsData.push({
          ...item,
          preApprovedCases: item.approvedCases,
          preApprovedBottles: item.approvedBottles,
          approvedCases: changedProduct ? changedProduct.approvedCases : item.approvedCases,
          approvedBottles: changedProduct ? changedProduct.approvedBottles : item.approvedBottles,
          isDeleted:changedProduct ? false: true
        });
    });

      const revertPayload = {
        action:action === 'update' ? 'update_bar_indents' : 'cancel_bar_indents',
        data: {
          icdcCode: this.data.icdcCode,
          indentId: this.data.indentId,
          productDetails: productDetailsData,
          // changedProductsDetails: changedProducts.filter(item => item !== undefined)
        }
      };
      this.indentManagementService.updateIndents(revertPayload).subscribe((res: any) => {
        if (res.status === 'SUCCESS') {
          this.dialogRef.close('Bar Indent Updated Successfully');
          this.isUpdated = false;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: res.error ? res.error : 'Not Updated' });
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        //this.error = err.error.message;
      });
  
  }


  quantityCheck(productData) {
    const selectedProduct = productData;
    const matchedProducts = this.data.productDetails.find(item => item.productCode === selectedProduct.productCode);
    if (selectedProduct) {
      let unitsPerCase = this.util.productCodeToDetails(selectedProduct.productCode).unitsPerCase
      const scannedQty = selectedProduct.totalScannedCases ? selectedProduct.totalScannedCases : selectedProduct.casesCount ? selectedProduct.casesCount : 0;
      const totalScannedQty = scannedQty + (selectedProduct?.totalScannedBottles ? (selectedProduct?.totalScannedBottles / unitsPerCase) : 0);
      return productData.approvedCases === null || productData.approvedCases === 0 || productData.approvedCases < totalScannedQty || productData.approvedCases > matchedProducts.approvedCases;
    }
  }
  quantityCheckBottles(productData) {
    const selectedProduct = productData;
    const matchedProducts = this.data.productDetails.find(item => item.productCode === selectedProduct.productCode);
    if (selectedProduct) {
      // let unitsPerCase = this.util.productCodeToDetails(selectedProduct.productCode).unitsPerCase
      // const scannedQty = selectedProduct.totalScannedCases ? selectedProduct.totalScannedCases : selectedProduct.casesCount ? selectedProduct.casesCount : 0;
      // const totalScannedQty = scannedQty + (selectedProduct?.totalScannedBottles ? (selectedProduct?.totalScannedBottles / unitsPerCase) : 0);
      return productData.approvedBottles === null || productData.approvedBottles === 0 || productData.approvedBottles < selectedProduct.bottlesCount || productData.approvedBottles > matchedProducts.approvedBottles;
    }
  }

  getCaseCount(item){
    if(item.breakages){
      if(item.breakages.bottleBreakages) {
        this.util.getTotalScannedCasesBottles(item);
        // let unitsPerCase = this.util.productCodeToDetails(item.productCode).unitsPerCase
        // let totalScannedBottles = (item.breakages.bottleBreakages.length * unitsPerCase) - item.breakages.bottles;
        // item.totalScannedCases = item.casesCount + Math.floor(totalScannedBottles/unitsPerCase);
        // item.totalScannedBottles = totalScannedBottles % unitsPerCase;
      }
    }
    return item;
  }

  deleteProduct(productDetails, index){
    const icdcProductsData = this.productsData.productDetails;
    icdcProductsData.splice(index, 1);
    this.productsData.productDetails = icdcProductsData;
    this.isUpdated =  true;
  }

  checkForError(){
    if(this.productsData){
      const outofRangeData = this.productsData.productDetails.map((product,i) =>{
        return this.quantityCheck(product);
      });
  
      return outofRangeData.some(item => item === true);
    }
  }
  dialogConfirm(mode): any {
    const data: any = {};
    data.content = `${mode} Indent?`;
    const dialogRef = this.dialog.open(DynamicConfirmationDialogComponent, {
      width: '25%',
      data:data,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result.message}`);
      if (result.message === 'Success') {
        if (mode === 'cancel') {
          this.cancelIndent();
        } else if (mode === 'update') {
          this.revertIndent('update');
        }
      }
    });
  }

}
