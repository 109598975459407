import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BottlingLine } from 'src/app/core/models/distillery-info/bottling-line';
import { HealInventory } from 'src/app/core/models/holograms/heal-inventory';
import { Batch } from 'src/app/core/models/production/batch.model';
import { DistilleryInfoService } from 'src/app/core/services/distillery-info.service';
import { HologramsService } from 'src/app/core/services/holograms.service';
import { ProductionService } from 'src/app/core/services/production.service';
import { Product } from '../../../../core/models/production/product.model';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-manage-production-line-dialog',
  templateUrl: './manage-production-line-dialog.component.html',
  styleUrls: ['./manage-production-line-dialog.component.scss']
})
export class ManageProductionLineDialogComponent implements OnInit {
  productionLineForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  scannerTypes = ['Machine', 'Machine(TSC)'];
  printerTypes = ['DeskTop Printer', 'IP based Printer'];
  offset: number;
  lines: any[];
  liquorTypeList: any = ['IML', 'BEER'];
  newEdit: string = 'New';
  selectedLine:any;
  loader: any = false;
  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageProductionLineDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private productionService: ProductionService,
    private hologramService: HologramsService, private distilleryInfoService: DistilleryInfoService,private messageService:MessageService) { }

  ngOnInit(): void {

    this.getLines();
    this.productionLineForm = this.fb.group({
      lineName: ['',Validators.required],
      lineId: ['',Validators.required],
      port: ['',Validators.required],
      ipAddress: ['',Validators.required],
      scannerType: [''],
      printerType: ['',Validators.required],
      barcodePrinterName: ['',Validators.required],
      lineType: ['',Validators.required]
    });
    if (this.data) {
      // this.newEdit = 'Edit'
      this.newEdit = ''
    }
    this.productionLineForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
    
  }
  getLines() {
    this.distilleryInfoService.getFeatureData('Bottling Lines').subscribe(res => {
      this.lines = res['Bottling Lines'];
      
      if (this.data) {
        this.lines.forEach(res => {
          if(this.data.lineName == res.lineName) {
            this.getLineId(res);
          }
        })
        this.productionLineForm.patchValue({
          lineName: this.data.lineName,
          lineId: this.data.lineId,
          // port: this.data.port,
          // ipAddress: this.data.ipAddress,
          //
          // scannerType: this.data.scannerType,
          // printerType: this.data.printerType,
          barcodePrinterName: this.data.barcodePrinterName,
          lineType: this.data.lineType
        });
      }
    });
  }

  getLineId(line) {
    this.selectedLine=line;
    this.productionLineForm.patchValue({
      lineId: line.lineId,
      port: line.port,
      scannerType: line.scannerType,
      ipAddress: line.ipAddress,
      printerType: line.printerType,
      barcodePrinterName: line.barcodePrinterName
    });
  }
  // get f() {
  //   return this.productionLineForm.controls;
  // }

  createProductionLine() {    
    this.productionLineForm.patchValue({
      createdOn: new Date().toISOString()
    });
    this.saveDisabled = true;
    const plObj = this.productionLineForm.value;
    const req_Data = {
      action: 'add_production_line',
      data: plObj
    }
    delete req_Data.data.ipAddress;
      // if(this.productionService.productionLineData.some((line) => line.lineName == this.selectedLine.lineName)){
      //   this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Production Line already exist' });

      // }else{
        this.loader = true;
        this.productionService.createproduction(req_Data).subscribe((res: any) => {
          this.loader = false;
          this.dialogRef.close('Production Line Created Successfully');
        }, (err) => {
          this.loader = false;
          this.error = err.error.message;
        });     
      //  }
  }

  updateProductionLine() {
    this.saveDisabled = true;
    const obj = {
      action: 'edit_production_line',
      update: this.productionLineForm.value
    };
    delete obj.update.ipAddress;
    this.productionService.putProduction(obj).subscribe((res: any) => {
      this.dialogRef.close('Production Line Updated Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  // getTitle() {
  //   return this.data && this.data._id ? `Update production Line for ${this.data.productCode}` : 'Create Production Line';
  // }

}
