import { CommonsService } from 'src/app/core/services/commons.service';
import {Component, Inject} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OtpVerificationDialogComponent } from '../../components/otp-verification-dialog/otp-verification-dialog.component';
import { HologramsService } from '../../services/holograms.service';
import { IndentManagementService } from '../../services/indent-management.service';
@Component({
    selector: 'status-details-dialog',
    templateUrl: './status-details-dialog.component.html',
    styleUrls: ['./status-details-dialog.component.scss']
})
export class StatusDetailsDialogComponent {
    error: any;
    roleName: string;
    addCartonsSpooolsForm: FormGroup;
    cartons:FormArray;
    spoolsQty: number = 0;
  
    constructor(private dialogRef: MatDialogRef<StatusDetailsDialogComponent>,
      @Inject(MAT_DIALOG_DATA)public data, private hologramsService: HologramsService, private indentManagementService : IndentManagementService, private fb: FormBuilder,private commponService:CommonsService,private dialog: MatDialog) {
        this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
      }
  
    ngOnInit(): void {
    // this.dataSource = new MatTableDataSource(this.data);
      console.log(this.data,"25:::::");
      this.addCartonsSpooolsForm = this.fb.group({
        cartons: this.fb.array([this.createBarCode()]),
      });
      this.cartons = this.addCartonsSpooolsForm.get('cartons') as FormArray;
  
    }
  
    createBarCode() {
      return this.fb.group({
        cartonBarcode: '',
        spools:'',
        qty: 0,
        spoolsLength: 0
      });
    }
  
    addBarCode() {
      (this.addCartonsSpooolsForm.controls['cartons'] as FormArray).push(this.createBarCode())
    }
  
    updateStatus(status) {
      if(status == 'Approved'){
        // const req_otp ={
        //   action: "send_otp",
        //   data:{
        //    ...this.data
        //   }
        // }
        
        // this.commponService.sendVerifyOtp(req_otp).subscribe((res:any)=>{
          
          
        // })
        // const dialogRef = this.dialog.open(OtpVerificationDialogComponent, {
        //   width: '25%',
        //   height:'45%',
        //   autoFocus: false
        // });
    
        // dialogRef.afterClosed().subscribe((result) => {
        //   if(result.message == 'SUCCESS'){
            const req_Data = {
              action : 'approve_indent',
              data : {status, indentNo: this.data.indentId ? this.data.indentId : this.data.indentNo, supplierCode: this.data.supplierCode}
            }
            this.indentManagementService.updateIndents(req_Data).subscribe((res: any) => {
              this.dialogRef.close('Indent updated Successfully');
            }, (err) => {
              this.error = err.error.message;
            });
        //   }
        // });
      }else{
        const req_Data = {
          action : 'approve_indent',
          data : {status, indentNo: this.data.indentId ? this.data.indentId : this.data.indentNo, supplierCode: this.data.supplierCode}
        }
        this.indentManagementService.updateIndents(req_Data).subscribe((res: any) => {
          this.dialogRef.close('Indent updated Successfully');
        }, (err) => {
          this.error = err.error.message;
        });
      }


    }
  
    onValidateCartons(e, i) {
      console.log('e', e)
      const qdata={
        cartonBarcode:e.target.value,
        healIndentId: this.data.healIndentId
      }
      this.hologramsService.getHpiDetails(JSON.stringify(qdata),'validate_carton').subscribe((res: any) => {
        console.log('res', res);
        (<FormArray>this.addCartonsSpooolsForm.get('cartons')).at(i).patchValue({cartonBarcode: e.target.value,
          spools: res.spoolBarcodes, spoolsLength: res.spoolBarcodes.length, qty: res.spoolBarcodes.length * 20000});
        // this.spoolsQty += res.spoolsBarcodes.length * 20000;
      })
    }
  
    checkQty() {
      if(this.cartons.value.length > 0) {
        this.spoolsQty = 0;
        for(let carton of this.cartons.value) {
          this.spoolsQty += carton.qty;
        }
        return !(this.spoolsQty == this.data.approvedQuantity);
      } else {
        return true;
      }
    }
  }
  
