import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, Subject } from 'rxjs';
import { LocationsService } from 'src/app/core/services/locations.service';
import { CommonsService } from 'src/app/core/services/commons.service';
import { MessageService } from 'primeng/api';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-manage-location-dialog',
  templateUrl: './manage-location-dialog.component.html',
  styleUrls: ['./manage-location-dialog.component.scss']
})
export class ManageLocationDialogComponent implements OnInit {

  locationForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  offset: number;
  exciseChemicalLabs = [];
  asstCommnDisilleries = [];
  revenueDistricts = [];
  indianStates = [];
  exciseDistricts = [];
  nodeTypes = ['Self', 'Sublease', 'Tieup'];
  licenseeTypes = ['Distillery','Sublease']
  locationTypes = ['Supplier', 'Non Local Supplier', 'Distillery', 'Breweries', 'Non Local Breweries', 'Company'];
  categoryTypes = ['Local','Non Local','Local Beer','Non Local Beer','Foreign','Foreign Beer']
  suppliers = [];
  roles: any;
  public supplierFilterCtrl: FormControl = new FormControl();
  saveDisabled = true;
  requiredFields = ['supplierCode', 'supplierName', 'address', 'town', 'revenueDistrict', 'exciseDistrict', 'pincode'];
  filteredSuppliers: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  protected _onDestroy = new Subject<void>();


  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageLocationDialogComponent>, private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data, public util: UtilService, private locationsService: LocationsService, private CommonsService: CommonsService) {
      dialogRef.disableClose = true;
      this.filteredSuppliers.next([]); 
  }

  ngOnInit(): void {
    this.getSuppliers();
    this.getCommonList();
    this.locationForm = this.fb.group({
      supplierCode: ['', [Validators.required, Validators.min(1000), Validators.max(9999)]],
      category:['',[Validators.required]],
      locationType: '',
      supplierName: '',
      coordinates: ['0 , 0'],
      licenseeType:[''],
      address: '',
      pincode: '',
      revenueDistrict: '',
      town: '',
      state: '',
      exciseDistrict: '',
      phoneNumber: ['', Validators.compose([Validators.required,Validators.pattern(this.util.phoneNumberValidation())])],
      asstCommDistillery: '',
      exciseChemicalLab: '',
      nodeType: '',
      parentDistillery: '',
      linesCount: '',
      uploadLogo: '',
      supplierFilter: ''
    });
    console.log(this.data);
    if (this.data) {
      this.locationForm.patchValue({
        id: this.data.id,
        locationType: this.data.locationType,
        supplierCode: this.data.supplierCode.split('-')[0],
        supplierName: this.data.supplierName,
        coordinates: this.data.distilleryAddress && this.data.distilleryAddress.length > 0 ? this.data.distilleryAddress[0].coordinates : '',
        address: this.data.officeAddresses && this.data.officeAddresses.length > 0 ? this.data.officeAddresses[0].address : '',
        pincode: this.data.officeAddresses && this.data.officeAddresses.length > 0 ? this.data.officeAddresses[0].pincode : '',
        state: this.data.officeAddresses && this.data.officeAddresses.length > 0 ? this.data.officeAddresses[0].state : '',
        town: this.data.officeAddresses && this.data.officeAddresses.length > 0 ? this.data.officeAddresses[0]['town/village'] : '',
        revenueDistrict: this.data.distilleryAddress && this.data.distilleryAddress.length > 0 ? this.data.distilleryAddress[0].revenue_district : '',
        exciseDistrict: this.data.distilleryAddress && this.data.distilleryAddress.length > 0 ? this.data.distilleryAddress[0].excise_district : '',
        phoneNumber: this.data.officeAddresses && this.data.officeAddresses.length > 0 ? this.data.officeAddresses[0].phoneNumbers && this.data.officeAddresses[0].phoneNumbers.length > 0 ? this.data.officeAddresses[0].phoneNumbers[0].number : '' : '',
        asstCommDistillery: this.data.locationInfo ? this.data.locationInfo.asstCommDistillery : '',
        exciseChemicalLab: this.data.locationInfo ? this.data.locationInfo.exciseChemicalLab : '',
        nodeType: this.data.locationInfo ? this.data.locationInfo.nodeType : '',
        linesCount: this.data.linesCount || '',
        uploadLogo: this.data.uploadLogo || ''
        
      });
      let disbaleFields = ['locationType','supplierCode','supplierName','parentDistillery','asstCommDistillery',
      'exciseChemicalLab','revenueDistrict','exciseDistrict','coordinates'];
      this.disableFormField(disbaleFields);

}
    this.suppliers.forEach(res => {
      if (this.data.locationInfo && this.data.locationInfo.parentDistillery && res.supplierCode == this.data.locationInfo.parentDistillery.supplierCode) {
        this.locationForm.patchValue({
          parentDistillery: res
        });
      }
    })
    this.locationForm.valueChanges.subscribe((change) => {
      let requiredFields = this.requiredFields;
      if(this.data){
        requiredFields = ['address', 'town', 'pincode'];
      }else{
        // console.log(change.locationType.indexOf('Non Local') !== -1,'::::::dddd:::::::', change.locationType );
        if (change.locationType === 'Supplier') {
          requiredFields = ['supplierCode', 'supplierName', 'address', 'parentDistillery'];
        }else if (change.locationType && (change.locationType.indexOf('Non Local') !== -1 || change.locationType.indexOf('Breweries') !== -1)) {
          requiredFields = ['supplierCode', 'supplierName', 'address', 'town', 'pincode'];
        }else if(change.locationType === 'Non Local Supplier'){
          requiredFields = ['licenseeType'];
        }
  
      }
      this.saveDisabled = this.util.isFormValid(change, requiredFields);
     
    });

    this.util.matSelectFilter(this, "supplierFilterCtrl", "filteredSuppliers", "suppliers");
  }

  disableFormField(formFieldsList){
    formFieldsList.forEach(field =>{
      this.locationForm.controls[field].disable();
    })
  }

  checkPhoneLength(event) {
    this.saveDisabled = event.target.value.length < 10;
  }

  getSuppliers() {
    let paramObj = {
      action: 'common_apis',
      q: '{"data": ["get_distilleries"]}'
    }
    this.CommonsService.getCommonEntites(paramObj).subscribe((res) => {
      this.suppliers = res['get_distilleries'];
      this.filteredSuppliers.next(this.suppliers);
      this.suppliers.forEach(res => {
        if (this.data && this.data.locationInfo && this.data.locationInfo.parentDistillery && res.supplierCode == this.data.locationInfo.parentDistillery.supplierCode) {
          this.locationForm.patchValue({
            parentDistillery: res
          });
        }
      })
    });
  }

  getCommonList() {
    const req_Data = {
      action: 'common_apis',
      q: `{"data": {"action": "get_static_codes", "value": ["exciseChemicalLabs", "asstCommnDisilleries", "revenueDistricts", "exciseDistricts", "indianStates"]}}`
    }
    this.CommonsService.getCommonEntites(req_Data).subscribe(result => {
      for (let key in result) {
        this[key] = result[key];
        if (this.locationForm.value.locationType === 'Supplier' || this.locationForm.value.locationType === 'Distillery' || this.locationForm.value.locationType == 'Breweries') {
        }
      }
    })
  }

  get f() {
    return this.locationForm.controls;
  }

  saveLocation() {
    if (this.data) {
      const obj = {
        action: 'update_location',
        data: this.locationForm.value
      };
      delete obj.data.supplierFilter

      obj.data.supplierType = obj.data.state == 'Andhra Pradesh' ? 'Local' : 'Non-Local';
      
      obj.data = {
        ...obj.data,
        locationType: this.data.locationType,
        supplierCode: this.data.supplierCode,
        supplierName: this.data.supplierName,
        parentDistillery: this.data.locationInfo && this.data.locationInfo.parentDistillery  ? this.data.locationInfo.parentDistillery : '',
        asstCommDistillery: this.data.locationInfo ? this.data.locationInfo.asstCommDistillery : '',
        exciseChemicalLab: this.data.locationInfo ? this.data.locationInfo.exciseChemicalLab : '',
        revenueDistrict: this.data.distilleryAddress && this.data.distilleryAddress.length > 0 ? this.data.distilleryAddress[0].revenue_district : '',
        exciseDistrict: this.data.distilleryAddress && this.data.distilleryAddress.length > 0 ? this.data.distilleryAddress[0].excise_district : '',
        coordinates: this.data.distilleryAddress && this.data.distilleryAddress.length > 0 ? this.data.distilleryAddress[0].coordinates : ''

        
      };
      
      this.locationsService.putLocation(obj).subscribe((res: any) => {
        if (res.status) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: res.status });
        }
        else {
          this.dialogRef.close('Updated Successfully');
        }
      }, (err) => {
        this.error = err.error.message;
      });
    }
    else {
      const obj = {
        action: this.locationForm.get('locationType').value.indexOf('Breweries') !== -1 ? 'add_brewery' : 'add_location',
        data: this.locationForm.value
      };
      delete obj.data.supplierFilter
      obj.data.supplierCode = String(obj.data.supplierCode);
      obj.data.supplierType = obj.data.state == 'Andhra Pradesh' ? 'Local' : 'Non-Local';
      
      this.locationsService.postLocation(obj).subscribe((res: any) => {
        if (res.status && res.status === "SUCCESS") {
          this.dialogRef.close('Created Successfully');
        }
        else {
          this.dialogRef.disableClose = true;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: res && res.error ? res.error : res.status ? res.status : 'Not Created'});
        }
      }, (err) => {
        this.error = err.error.message;
      });
    }
  }

  onLocationChange(event) {
    if (event.value === 'Supplier' || event.value === 'Company') {
      this.locationForm.get('nodeType').setValue('Sublease');
    } else {
      this.locationForm.get('nodeType').setValue('Self');
    }
    if (this.locationForm.value.locationType === 'Supplier' || this.locationForm.value.locationType === 'Distillery' || this.locationForm.value.locationType == 'Breweries') {
      this.locationForm.patchValue({
        state: 'Andhra Pradesh'
      })
    }
    else {
      this.indianStates.splice(this.indianStates.indexOf("Andhra Pradesh"), 1);
      this.locationForm.patchValue({
        state: ''
      })
    }
  }

  onParentDistilleryChange(value) {
    const [supplierObj] = this.suppliers.filter(item => item.supplierCode === value.supplierCode);    
    this.locationForm.patchValue({ 
      asstCommDistillery: supplierObj.locationInfo.asstCommDistillery, 
      exciseChemicalLab: supplierObj.locationInfo.exciseChemicalLab,
      revenueDistrict: supplierObj.distilleryAddress && supplierObj.distilleryAddress[supplierObj.distilleryAddress.length -1].revenue_district,
      exciseDistrict: supplierObj.distilleryAddress && supplierObj.distilleryAddress[supplierObj.distilleryAddress.length -1].excise_district
    });
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

}