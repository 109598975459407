import { Component, Inject, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonsService } from 'src/app/core/services/commons.service';
import { LabService } from 'src/app/core/services/lab.service';

@Component({
  selector: "app-manage-finished-goods-dialog",
  templateUrl: "./manage-finished-goods-dialog.component.html",
  styleUrls: ["./manage-finished-goods-dialog.component.scss"],
})
export class ManageFinishedGoodsDialogComponent implements OnInit {
  // displayedColumns: string[] = [
  //   'source',
  //   'batchNo',
  //   'quantity',
  //   'sendersFacsimileSeal',
  //   'proof'
  //   ];

  finishedGoodsInformation: FormArray;
  finishedGoodsForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  types = ["Manual", "Automatic"];
  refList: any = [];
  ceCRNoList: any = [];
  refListData: any = [];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ManageFinishedGoodsDialogComponent>,
    private commonsService: CommonsService,
    @Inject(MAT_DIALOG_DATA) public data,
    private labService: LabService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    console.log("this.data", this.data);
    this.spinner.show();
    this.finishedGoodsForm = this.fb.group({
      uploadDate: ["", [Validators.required]],
      ceCRNo: ["", [Validators.required]],
      referenceNo: ["", [Validators.required]],
      sentThrough: ["", [Validators.required]],
      signatureOfHC: [""],
      finishedGoodsInformation: this.fb.array([this.updateLabInformation()]),
    });
    if (this.data) {
      this.finishedGoodsForm.patchValue({
        uploadDate: this.data.uploadDate,
        type: this.data.type,
        referenceNo: this.data.referenceNo,
        sentThrough: this.data.sentThrough,
        signatureOfHC: this.data.signatureOfHC,
      });
      const finishedGoodsInformation = this.finishedGoodsForm.get(
        "finishedGoodsInformation"
      ) as FormArray;
      finishedGoodsInformation.clear();
      this.data.finishedGoodsInformation.forEach((b) => {
        finishedGoodsInformation.push(
          this.fb.group({
            checkedValue: b.checkedValue,
            descriptionOfSample: b.descriptionOfSample,
            dtpNoLotNo: b.dtpNoLotNo,
            sampleQuantity: b.sampleQuantity,
            sendersFacsimileSeal: b.sendersFacsimileSeal,
            percentageOfProofSpirit: b.percentageOfProofSpirit,
          })
        );
      });
    }
    this.finishedGoodsInformation = this.finishedGoodsForm.get(
      "finishedGoodsInformation"
    ) as FormArray;
    this.finishedGoodsForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });

    const req_Data = {
      action: "common_apis",
      q: '{"data": ["get_ref_list"]}',
    };
    this.commonsService.getCommonEntites(req_Data).subscribe((res) => {
      this.refListData = res["get_ref_list"];
      this.refList = res["get_ref_list"].map((res) => res.referenceNo);
      this.ceCRNoList = res["get_ref_list"].map((res) => res.ceCRNo);
      this.spinner.hide();
    });
  }

  updateLabInformation() {
    return this.fb.group({
      batchId: "",
      descriptionOfSample: "",
      dtpNoLotNo: "",
      percentageOfProofSpirit: "",
      sampleQuantity: "",
      sampleStatus: "",
      sendersFacsimileSeal: "",
    });
  }

  addUnit() {
    const control = <FormArray>(
      this.finishedGoodsForm.controls["finishedGoodsInformation"]
    );
    control.push(this.updateLabInformation());
    console.log(this.finishedGoodsInformation);
  }
  checkControls() {

    if (
      this.finishedGoodsInformation.length != 0 &&
      this.finishedGoodsForm.get("finishedGoodsInformation").value[
        this.finishedGoodsInformation.length - 1
      ]["batchId"] != "" &&
      this.finishedGoodsForm.get("finishedGoodsInformation").value[
        this.finishedGoodsInformation.length - 1
      ]["descriptionOfSample"] != "" &&
      this.finishedGoodsForm.get("finishedGoodsInformation").value[
        this.finishedGoodsInformation.length - 1
      ]["dtpNoLotNo"] != ""
      &&
      this.finishedGoodsForm.get("finishedGoodsInformation").value[
        this.finishedGoodsInformation.length - 1
      ]["percentageOfProofSpirit"] != ""
      &&
      this.finishedGoodsForm.get("finishedGoodsInformation").value[
        this.finishedGoodsInformation.length - 1
      ]["sampleQuantity"] != ""
      &&
      this.finishedGoodsForm.get("finishedGoodsInformation").value[
        this.finishedGoodsInformation.length - 1
      ]["sampleStatus"] != ""
      &&
      this.finishedGoodsForm.get("finishedGoodsInformation").value[
        this.finishedGoodsInformation.length - 1
      ]["sendersFacsimileSeal"] != ""
    ) {
      return false;
    } else {
      return true;
    }
  }

  removeUnit(i: number) {
    const control = <FormArray>(
      this.finishedGoodsForm.controls["finishedGoodsInformation"]
    );
    control.removeAt(i);
    this.checkControls();
  
    
  }

  get f() {
    return this.finishedGoodsForm.controls;
  }

  changeInfo(e) {
    let info: any = this.refListData.filter(
      (res) => res.ceCRNo == e.value || res.referenceNo == e.value
    );
    info = info[0];
    this.finishedGoodsForm.patchValue({
      uploadDate: new Date(info.uploadDate),
      ceCRNo: info.ceCRNo,
      referenceNo: info.referenceNo,
      sentThrough: info.sentThrough,
      signatureOfHC: info.signatureOfHC,
    });
    console.log(e, info, this.finishedGoodsForm, "124::::");
    /* batchId: "BT-111"
descriptionOfSample: "tester"
dtpNoLotNo: "1111111111"
percentageOfProofSpirit: "42"
sampleQuantity: "5"
sampleStatus: "PASS" */
    const finishedGoodsInformation = this.finishedGoodsForm.get(
      "finishedGoodsInformation"
    ) as FormArray;
    finishedGoodsInformation.clear();
    info.labInformation.forEach((b) => {
      finishedGoodsInformation.push(
        this.fb.group({
          batchId: b.batchId,
          descriptionOfSample: b.descriptionOfSample,
          dtpNoLotNo: b.dtpNoLotNo,
          percentageOfProofSpirit: b.percentageOfProofSpirit,
          sampleQuantity: b.sampleQuantity,
          sampleStatus: b.sampleStatus,
          sendersFacsimileSeal: b.sendersFacsimileSeal,
        })
      );
    });
  }

  createFinishedGoods() {
    this.saveDisabled = true;
    const req_Data = {
      action: "add_finished_goods",
      data: this.finishedGoodsForm.value,
    };

    req_Data.data.finishedGoodsInformation.forEach((res) => {
      delete res.checkedValue;
    });
    this.labService.createFinishedGoodsDetails(req_Data).subscribe(
      (res: any) => {
        this.dialogRef.close("Samples Created Successfully");
      },
      (err) => {
        this.error = err.error.message;
      }
    );
  }

  dialogClose(): void {
    this.dialogRef.close();
  }
}
