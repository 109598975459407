import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HologramsService } from 'src/app/core/services/holograms.service';

@Component({
  selector: 'app-manage-heal-damage-dialog',
  templateUrl: './manage-heal-damage-dialog.component.html',
  styleUrls: ['./manage-heal-damage-dialog.component.scss']
})
export class ManageHealDamageDialogComponent implements OnInit {

  healDamageForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  spoolBarCodes: any;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageHealDamageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private hologramsService: HologramsService) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.getSpoolBarCodes();
    this.healDamageForm = this.fb.group({
      spoolBarCode: [''],
      healCode : ['']
    });
    if (this.data && this.data._id) {
      this.healDamageForm.patchValue({
        spoolBarCode : this.data.spoolBarCode,
        healCode : this.data.healCode,
      });
    }
    this.healDamageForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    })
  }
  getSpoolBarCodes() {
    this.hologramsService.getHealInventory(0,100).subscribe(res => {
      this.spoolBarCodes = res.data;
    })
  }

  get f(){
    return this.healDamageForm.controls;
  }

  createHealDamage() {
    this.saveDisabled = true;
    const req_Data = {
      action : 'heal_damage',
      data : this.healDamageForm.value
    }
    this.hologramsService.createHealDamage(req_Data).subscribe((res: any) => {
      console.log(res);
      this.dialogRef.close('Heal Damage Created Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  updateHealDamage() {
    this.saveDisabled = true;
    const obj = [{
      _id: this.data._id,
      update: this.healDamageForm.value
    }]
    this.hologramsService.putHealDamage(obj).subscribe((res: any) => {
      console.log(res);
      this.dialogRef.close('Heal Damage Updated Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  getTitle() {
    return this.data && this.data._id ? `Update Heal Damage for ${this.data.healDamageName}` : 'Create Heal Damage';
  }

  dialogClose(): void{
    this.dialogRef.close();
  }

}
