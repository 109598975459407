import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HologramsService } from 'src/app/core/services/holograms.service';

@Component({
  selector: 'app-manage-heal-daily-indent-dialog',
  templateUrl: './manage-heal-daily-indent-dialog.component.html',
  styleUrls: ['./manage-heal-daily-indent-dialog.component.scss']
})
export class ManageHealDailyIndentDialogComponent implements OnInit {

  healDailyIndentForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  purposes = [' Local (Light Green)', 'Export (Purple) ', 'CSD (Light Brown)', ' CSD Local (Light Brown)', 'CSD Export (Light Brown)'];


  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageHealDailyIndentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private hologramsService: HologramsService) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.saveDisabled = true;
    this.healDailyIndentForm = this.fb.group({
      quantity: ['', Validators.required],
      purpose: ['', Validators.required],
    });
    if (this.data && this.data._id) {
      this.healDailyIndentForm.patchValue({
        quantity: this.data.quantity,
        purpose: this.data.purpose,
      });
    }
    this.healDailyIndentForm.valueChanges.subscribe((change) => {
      if (change.quantity && change.quantity % 20000 === 0) {
        this.saveDisabled = false;
      } else {
        this.saveDisabled = true;
        this.healDailyIndentForm.controls.quantity.setErrors({ 'incorrect': true });
      }
    })
  }

  get f() {
    return this.healDailyIndentForm.controls;
  }

  createHealDailyIndent() {
    this.saveDisabled = true;
    const req_data = {
      action: 'add_heal_daily_indent',
      data: { ...this.healDailyIndentForm.value }
    };
    this.hologramsService.createHealDailyIndent(req_data).subscribe((res: any) => {

      this.dialogRef.close('Heal Daily Indent Created Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  updateHealDailyIndent() {
    // this.saveDisabled = true;
    // const obj = [{
    //   _id: this.data._id,
    //   update: this.healDailyIndentForm.value
    // }]
    // this.spiritService.putBatch(obj).subscribe((res: any) => {
    //   this.dialogRef.close('Batch Udated Successfully');
    // }, (err) => {
    //   this.error = err.error.message;
    // });
  }


}
