import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { CommonsService } from 'src/app/core/services/commons.service';
import { UserAccountsService } from 'src/app/core/services/user-accounts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BarDepotReassignService } from './../../../core/services/bar-depot-reassign.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-manage-retailer-reshuffing',
  templateUrl: './manage-retailer-reshuffing.component.html',
  styleUrls: ['./manage-retailer-reshuffing.component.scss']
})
export class ManageRetailerReshuffingComponent implements OnInit {

  depots = [];
  filteredDepots: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  selectedDepot:string = "";
  serviceCall: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef < ManageRetailerReshuffingComponent >,
    private commonsService: CommonsService,
    private userAccountsService: UserAccountsService,
    private barDepotReassignService: BarDepotReassignService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    if (this.data.title === 'Re-Shuffling') {
      this.getDepots();
    }
    if (this.data.moduleName === 'Bar Depot Reassign') {
      this.serviceCall = this.barDepotReassignService;
    } else {
      this.serviceCall = this.userAccountsService;
    }
  }

  onSubmit(){
    const payload: any = this.data.title === 'Re-Shuffling' ? {
      action: this.data.moduleName === 'Bar Depot Reassign' ? 'update_vendors' : 'update_retailers',
      data:{
        newAssignedDepot: this.selectedDepot,
        assignedDepot: this.data.assignedDepot,
        status : this.data.status
      }
    } : {
      action: 'update_retailers',
      data:{
        status : this.data.status,
      }
    }
    if (this.data.featureName === 'Retailers') {
      payload.data.retailerId = this.data.retailerId;
    } else if (this.data.featureName === 'Vendors' || this.data.featureName === 'Bars') {
      payload.data.vendorId = this.data.vendorId;
    }
    // this.userAccountsService.createRetailer(payload).subscribe((res: any) => {
    //   this.dialogRef.close('Created Retailer Successfully');
    // }, (err) => {
    //   // this.error = err.error.message;
    // });
    this.spinner.show();
    this.serviceCall.update(payload).subscribe((res: any) => {
      if (res && res.status === 'Updated Successfully'  || res.status === 'SUCCESS') {
        this.messageService.add({ severity: 'success', detail: 'Updated Successfully' });
        this.dialogRef.close({message: 'SUCCESS'});
      } else {
        this.messageService.add({ severity: 'error', detail : 'Not Updated' });
      }
      this.spinner.hide();
    }, (err) => {
      this.messageService.add({ severity: 'error', detail : 'Not Updated' });
      this.spinner.hide();
      // this.error = err.error.message;
    });
  }

  getDepots() {
    let paramObj = {
      action: 'common_apis',
      q: '{"data": ["get_depots"]}'
    }
    this.commonsService.getCommonEntites(paramObj).subscribe((res) => {
      this.depots = res['get_depots'].map((depot) => depot.depotCode);
      this.filteredDepots.next(this.depots);
    });
  }
}
