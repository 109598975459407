import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

const baseUrl = environment.baseUrl;
const paymentUrl = baseUrl + 'payment';


@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(private http:HttpClient) { }

  managePayment(obj:any){
   return this.http.put(paymentUrl,obj)
  }
}
