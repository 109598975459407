import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Get_PurchaseOrder, PurchaseOrder } from 'src/app/core/models/shipments/purchase-order.model';
import { SupplyIndents } from 'src/app/core/models/supply-delivery/supply-indents';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { SupplyDeliveryService } from 'src/app/core/services/supply-delivery.service';

@Component({
  selector: 'app-open-ofs-dialog',
  templateUrl: './open-ofs-dialog.component.html',
  styleUrls: ['./open-ofs-dialog.component.scss']
})
export class OpenOfsDialogComponent implements OnInit {

  ofsForm: FormGroup;
  error: string;
  roleName: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  purchaseOrders: PurchaseOrder[];
  outWardData:any;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<OpenOfsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private supplyDeliveryService: SupplyDeliveryService, 
    private shipmentService: ShipmentsService) { 
      this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
      this.outWardData = this.data.tp_data
    }

  ngOnInit(): void {
  
  }
 

}
