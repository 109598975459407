import { CommonsService } from 'src/app/core/services/commons.service';
import { Validators } from '@angular/forms';
import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HologramsService } from 'src/app/core/services/holograms.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { OtpVerificationDialogComponent } from 'src/app/core/components/otp-verification-dialog/otp-verification-dialog.component';
import { MessageService } from 'primeng/api';
declare const JsBarcode: any;
@Component({
  selector: 'app-distillery-hologram-status-details-dialog',
  templateUrl: './distillery-hologram-status-details-dialog.component.html',
  styleUrls: ['./distillery-hologram-status-details-dialog.component.scss']
})
export class DistilleryHologramStatusDetailsDialogComponent implements OnInit, AfterViewInit {
  approveHPIForm: FormGroup;
  error: string;
  addCartonsSpooolsForm: FormGroup;
  locationForm: FormGroup;
  cartons: FormArray;
  statusData: any;
  colors = {
    "Local": "Light Green",
    "Export": "Light Yellow",
    "CSD": "Light Yellow",
    "CSD Local": "Light Brown",
    "CSD Export": "Light Brown"
  }
  spoolsQty: number;
  roleName: any;
  disableDownLoad = false;
  vehicleNo: any = '';
  invoiceNo: any = '';
  userDetails: any = [];
  distilleryName: any = '';
  distilleryAddress = [];

  constructor(private dialogRef: MatDialogRef<DistilleryHologramStatusDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private fb: FormBuilder,
     private hologramsService: HologramsService, public util: UtilService,
     private dialog: MatDialog,private commponService:CommonsService,
     private messageService: MessageService
     ) {
      this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
     }
    

  ngOnInit(): void {
    this.userDetails = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
    if (this.userDetails.distillery_unit && this.userDetails.distillery_address) {
      this.distilleryName = this.userDetails.distillery_unit;
      this.distilleryAddress = this.userDetails.distillery_address[0];
    }
    this.data['title'] = 'Distillery Holograms';
    if (this.data.status === 'Dispatched' || this.data.status === 'InProgress') {
      this.getHpiDetails();
    }


    if (this.data.status === 'Reviewed') {
      this.approveHPIForm = this.fb.group({
        requestedEntity: this.data.entityType,
        supplierCode: this.data.entityId,
        healIndentId: this.data.healIndentId,
        requestedQuantity: Number(this.data.requestedQuantity),
        approvedQuantity: Number(this.data.requestedQuantity)
      })
    }
    else if (this.data.status === 'Approved') {
      this.addCartonsSpooolsForm = this.fb.group({
        requestedEntity: this.data.entityType,
        supplierCode: this.data.entityId,
        healIndentId: this.data.healIndentId,
        requestedQuantity: Number(this.data.requestedQuantity),
        purpose: this.data.purpose,
        colour: this.colors[this.data.purpose],
        cartons: this.fb.array([this.createBarCode()]),
      });
      this.cartons = this.addCartonsSpooolsForm.get('cartons') as FormArray;
    }
    else if (this.data.status === 'Completed') {
      this.locationForm = this.fb.group({
        requestedEntity: this.data.entityType,
        supplierCode: this.data.entityId,
        healIndentId: this.data.healIndentId,
        requestedQuantity: Number(this.data.requestedQuantity),
        cartons: this.fb.array([this.createBarCode()]),
        location: this.data.location,
        vehicleNo: this.vehicleNo,
        invoiceNo: this.invoiceNo
      });
      this.cartons = this.locationForm.get('cartons') as FormArray;
    }
  }

  ngAfterViewInit(): any {
    if (this.data.indentNo) {
      JsBarcode('#barcode-dm2', this.data.indentNo,
        {
          width: 1,
          height: 40
        }
      );
    }
    if (this.data.deliveryChllan) {
      JsBarcode('#barcode-delivery-challan', this.data.deliveryChllan,
        {
          width: 1,
          height: 40
        }
      );
    }
  }

  completeSpoolRequest(){
    const req_Data = {
      action: 'completed_cartons_spools',
      data: {
        entityId: this.data.entityId,
        healIndentId: this.data.healIndentId
      }
    }
    this.hologramsService.completeSpoolRequest(req_Data).subscribe(res => {     
      this.dialogRef.close('Heal Purchase Indent Request Completed Successfully');        
    });
  }

  getHpiDetails() {
    const qdata = {
      entityType: 'Distillery',
      supplierId: this.data.supplierId,
      healIndentId: this.data.healIndentId,
      status: this.data.status
    }
    this.hologramsService.getHpiDetails(JSON.stringify(qdata), 'get_hpi_by_id').subscribe(res => {
     
      this.data = { ...this.data, ...res };
    });
  }

  createBarCode() {
    return this.fb.group({
      cartonBarcode: '',
      spoolBarcodes: ''
    });
  }

  approveQty(action) {
    if(action == 'approve_hpi'){
      // const req_otp ={
      //   action: "send_otp",
      //   data:{
      //     requestedEntity: this.data.entityType,
      //     supplierCode: this.data.entityId
      //   }
      // }
      
      // this.commponService.sendVerifyOtp(req_otp).subscribe((res:any)=>{
        
        
      // })
      // const dialogRef = this.dialog.open(OtpVerificationDialogComponent, {
      //   width: '25%',
      //   height:'45%',
      //   autoFocus: false
      // });
  
      // dialogRef.afterClosed().subscribe((result) => {
      //   if(result.message == 'SUCCESS'){
        const req_Data = {
          action: action,
          data: {
            ...this.approveHPIForm.value,
            challanId: this.data.challanId
          }
        }
      this.hologramsService.updateHealPurchaseIndents(req_Data).subscribe((res: any) => {
        let message = action == 'approve_hpi' ? 'Heal Purchase Indent approved Successfully' : 'Heal Purchase Indent Rejected Successfully';
        this.dialogRef.close(message);
      }, (err) => {
        this.error = err.error.message;
      });    
        // }
      // });
    }else{
      const req_Data = {
        action: action,
        data: {
          ...this.approveHPIForm.value,
          challanId: this.data.challanId
        }
      }
    this.hologramsService.updateHealPurchaseIndents(req_Data).subscribe((res: any) => {
      let message = action == 'approve_hpi' ? 'Heal Purchase Indent approved Successfully' : 'Heal Purchase Indent Rejected Successfully';
      this.dialogRef.close(message);
    }, (err) => {
      this.error = err.error.message;
    });  
    }

  }

  onAddSpools(e) {

  }

  downLoadHeals(){
    this.disableDownLoad =  true;
    const req_Data = {
      action: "get_spool_bundles",
      data: {
        supplierCode: this.data.entityId,
        entityType: this.data.entityType,
        approvedQty: JSON.parse(this.data.approvedQuantity),
        healDownloadStatus : 'Downloaded',
        healIndentId : this.data.healIndentId,
        updatedDateTime : this.data.updatedDateTime
      }
    }
    this.hologramsService.getDownloadHeals(req_Data).subscribe((res: any) => {
      this.export(res['get_spool_bundles']);
    }, (err) => {
      this.error = err.error.message;
    });
  }

  export(healData){
    const spoolBarCodeList = Object.keys(healData);
     spoolBarCodeList.forEach((spoolBarCode) => {
      const data = healData[spoolBarCode];
      this.downloadFile(data, spoolBarCode);
    });
  }

  downloadFile(data, filename) {
    let csvData = this.ConvertToCSV(data);
   
    let blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray) {
    let str = '';
    for (let i = 0; i < objArray.length; i++) {
      let line = objArray[i];
      str += line + '\r\n';
    }
    return str;
  }

  addBarCode() {
    (this.addCartonsSpooolsForm.controls['cartons'] as FormArray).push(this.createBarCode())
  }

  removeBarCode(index) {
    this.cartons.removeAt(index);
  }

  getTitle() {
    return this.data.status === 'Reviewed' ? 'Enter Quantity to Approve' :
      this.data.status === 'InProgress' ? 'Dispatch' : '';

  }

  onSpoolBarcode() {
    const req_Data = {
      action: 'add_cartons_spools',
      data: this.addCartonsSpooolsForm.value
    }
    this.hologramsService.updateHealPurchaseIndents(req_Data).subscribe((res: any) => {
      this.dialogRef.close('Spools added Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  onDispatch() {
    this.locationForm.controls.vehicleNo.setValue(this.vehicleNo);
    this.locationForm.controls.invoiceNo.setValue(this.invoiceNo);
    const req_Data = {
      action: 'dispatch_spools',
      data: this.locationForm.value
    }
    this.hologramsService.updateHealPurchaseIndents(req_Data).subscribe((res: any) => {
      if(res.error){
        this.messageService.add({severity: 'error', summary: '', detail: res.error});
      }else{
        this.dialogRef.close('Spools dispatched Successfully');
      }
    }, (err) => {
      this.error = err.error.message;
    });
  }

  checkApproveQty(){
        return this.approveHPIForm.get('approvedQuantity').value > this.data.requestedQuantity;
  }

  checkSpoolsQty() {
    let disableSubmit = [];
    if (this.cartons.value.length > 0) {
      this.spoolsQty = 0;
      for (let carton of this.cartons.value) {
        this.spoolsQty += carton.spoolBarcodes.length * 20000;
        disableSubmit.push(!!Object.values(carton).filter(item => !item).length);
      }
      disableSubmit.push(!(this.spoolsQty === Number(this.data.approvedQuantity)));
    }
    return !!disableSubmit.filter(item => item).length;
  }
  getPrint(id): any {
    let printContents;
    let popupWin;
    setTimeout(() => {
      if (document.getElementById(id)) {
        printContents = document.getElementById(id).innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.write(`${printContents}`);
        popupWin.document.close();
      }
    }, 500);
  }

removeZero(value){
  const valueStr = value.toString();
  const valueWithoutZerosStr = valueStr.replace(/0/g, '');
  return parseInt(valueWithoutZerosStr, 10);
}
noOfCartoons(approvedQuantity:any){
  const approvedQty = approvedQuantity ? parseInt(approvedQuantity) : 0
  return Math.ceil(approvedQty / 100000);

}

}
