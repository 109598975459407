import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { DepotShipment } from 'src/app/core/models/shipments/depot-shipment.model';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-show-tp-details-dialog',
  templateUrl: './show-tp-details-dialog.component.html',
  styleUrls: ['./show-tp-details-dialog.component.scss']
})
export class ShowTpDetailsDialogComponent implements OnInit {
  isChecked: any = false;
  displayedColumns: string[] = ['brandNumber',
    'productName',
    'orderQuantityCases',
    'orderQuantityBottles',
  ];
  dataSource: MatTableDataSource<DepotShipment> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;
  tpDetailsLength: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;

  constructor(
    public util: UtilService, public dialogRef: MatDialogRef<ShowTpDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,
    private shipmentsService: ShipmentsService) { }

  ngOnInit(): void {
    this.getTpDetails();
  }

  getTpDetails() {
    if(this.data){
      let data=this.data?.consignment?.map((consignment:any)=>{
        let obj={
          OrderQtyCases:0,
          OrderQtyBottles:0,
          branchNumber:'',
          ProductName:''
        }
        // this.data.indentId=consignment.indentId
        this.data.indentId ? this.data.indentId : this.data.indentId=consignment.indentId
        consignment?.productDetails?.map((productDetails:any)=>{
         this.util.getTotalScannedCasesBottles(productDetails)
          obj.OrderQtyCases+=productDetails.totalScannedCases ? productDetails.totalScannedCases :0
          obj.OrderQtyBottles+=productDetails.totalScannedBottles ? productDetails.totalScannedBottles : 0
          obj.branchNumber=productDetails.brandCode ? productDetails.brandCode :''
          obj.ProductName=productDetails.brandName ? productDetails.brandName :''
        })
        return obj
      })
      this.dataSource = new MatTableDataSource(data);
    }else{
      this.shipmentsService.getDepotShipment(this.offset, this.limit, this.search).subscribe((res: any) => {
        console.log('res', res);
        this.dataSource = new MatTableDataSource(res.data);
        this.tpDetailsLength = res.count;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    }
  }
  ngAfterViewInit() {
    this.util.ngAfterViewInit(this.dataSource, this.paginator, this.sort, this.input.nativeElement, this.getTpDetails);
  }
  applyFilter() {
    this.util.applyFilter(this.dataSource, this.search);
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

  onPaginateChange(e) {
    this.limit = e.pageSize;
    this.offset = e.pageIndex * e.pageSize;
    this.getTpDetails();
  }

}
