import { Component,  Inject, OnInit } from '@angular/core';
import {  FormBuilder,  } from '@angular/forms';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-manage-icdc-dialog',
  templateUrl: './manage-icdc-dialog.component.html',
  styleUrls: ['./manage-icdc-dialog.component.scss']
})
export class ManageIcdcDialogComponent implements OnInit {
  userInfo: any;
  tabIndex: any = 0;
  error: any;
  selectedTabIndex:any = 0;
  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageIcdcDialogComponent> ,
              @Inject(MAT_DIALOG_DATA) public data) {
      this.userInfo = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
    }

  ngOnInit(): void {

  }

  switchTab(tab) {
    console.log(tab,"136:::;")
  }

  onTabChanged(event) {
    console.log(event,"133:::");
    this.tabIndex = event.index;
  }

}
