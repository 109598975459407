import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IndentManagementService } from 'src/app/core/services/indent-management.service';

@Component({
  templateUrl: './manage-blend-indent-dialog.component.html',
  styleUrls: ['./manage-blend-indent-dialog.component.scss']
})
export class ManageBlendIndentDialogComponent implements OnInit {

  // displayedColumns: string[] = [
  //   'blendTankNo',
  //   'quantity',
  //   'brandName',
  //   'category',
  //   'bottleSize',
  //   'lineNo'
  //   ];

  labInformation: FormArray;
  BlendIndentForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  req_data: any;
  kindOfSpirits = ['ENA1', 'ENA2'];


  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageBlendIndentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private indentManagementService: IndentManagementService) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.BlendIndentForm = this.fb.group({
      indentNo: ['', [Validators.required, ]],
      date: ['', [Validators.required, ]],
      distilleryChemistSign: ['', [Validators.required, ]],
      labInformation: this.fb.array([this.updateLabInformation()])
    });
    if (this.data && this.data._id) {
      this.BlendIndentForm.patchValue({
        indentNo : this.data.indentNo,
        date: this.data.date,
        distilleryChemistSign: this.data.distilleryChemistSign,
      });
      const labInformation = this.BlendIndentForm.get('labInformation') as FormArray;
      labInformation.clear();
      this.data.labInformation.forEach(b => {
        labInformation.push(
          this.fb.group({
            checkedValue: b.checkedValue,
            blendTankNo: b.blendTankNo,
            quantity: b.quantity,
            brandName: b.brandName,
            category: b.category,
            bottleSize: b.bottleSize,
            batchNo: b.batchNo,
            lineNo: b.lineNo
          })
        );
      });
    }
    this.labInformation = this.BlendIndentForm.get('labInformation') as FormArray;
    this.BlendIndentForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
  }

  updateLabInformation(){
    return this.fb.group({
      checkedValue: '',
      blendTankNo: ['', Validators.required],
      brandName: ['', Validators.required],
      category: ['', Validators.required],
      bottleSize: ['', Validators.required],
      quantity: ['', Validators.required],
      batchNo: ['', Validators.required],
      lineNo: ['', Validators.required]
    });
  }

  addUnit() {
    const control = <FormArray>this.BlendIndentForm.controls["labInformation"];
    control.push(this.updateLabInformation());
  }


  removeUnit(i: number) {
    const control = <FormArray>this.BlendIndentForm.controls["labInformation"];
    control.removeAt(i);
  }

  get f(){
    return this.BlendIndentForm.controls;
  }

  createBlendIndent() {
    this.saveDisabled = true;
    const req_data ={
      action: 'add_blend_indents',
      data: {...this.BlendIndentForm.value}
    };
    this.indentManagementService.createBlendIndent(req_data).subscribe((res: any) => {
      this.dialogRef.close('BlendIndent Created Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  updateBlendIndent() {
    // this.saveDisabled = true;
    // const obj = [{
    //   _id: this.data._id,
    //   update: this.BlendIndentForm.value
    // }]
    // this.spiritService.putBatch(obj).subscribe((res: any) => {
    //   this.dialogRef.close('Batch Udated Successfully');
    // }, (err) => {
    //   this.error = err.error.message;
    // });
  }

  dialogClose(): void{
    this.dialogRef.close();
  }

}
