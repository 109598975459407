import { Input } from '@angular/core';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { ProductionService } from 'src/app/core/services/production.service';

@Component({
  templateUrl: './spool-details-dialog.component.html',
  styleUrls: ['./spool-details-dialog.component.scss']
})
export class SpoolDetailsDialogComponent implements OnInit {

  @Input() featuresData: any;

  spoolDetailsForm: FormGroup;
  isEditable: boolean = false;
  error: string;
  saveDisabled: boolean;
  spoolsScannedLength: any;

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<SpoolDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)public data, private productionService: ProductionService,
    private messageService: MessageService
    ) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.spoolsScannedLength = this.data.spools ? this.data.spools.length : 0;
    this.spoolDetailsForm = this.fb.group({
      productionId: this.data.productionId,
      lineName: this.data.lineName,
      spoolsScanned: [],
    });
    if (this.data) {
      this.spoolDetailsForm.patchValue({
        productionId: this.data.productionId,
        lineName: this.data.lineName,
      });
    }
  }

  dialogClose(): void{
    this.dialogRef.close();
  }

  onAddSpools(e) {

  }

  allocateSpools() {
    const obj = {
      action:"heal_feed",
      data: this.spoolDetailsForm.value
    }
    this.productionService.putProduction(obj).subscribe((res: any) => {
      if(res.status === "SUCCESS"){
        this.dialogRef.close('Production Line Updated Successfully');
      }else{
        this.messageService.add({severity: 'error', summary: '', detail: 'Please Try with another spoolCode'});
        this.spoolDetailsForm.controls.spoolsScanned.reset();
      }
    }, (err) => {
      this.error = err.error.message;
    });
  }


}
