import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const importUrl = baseUrl + 'import';
const importIndentsUrl = baseUrl + 'import-indents';
const importPermitChallanEditUrl = baseUrl + 'importpermitchallanedit';
const shipmentUrl = baseUrl + 'shipment';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Import'] && userModules.mod_feats['Import'].features ? userModules.mod_feats['Import'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class ImportService {

  constructor(private http: HttpClient) { }

  getImportIndents(offset,limit,search) {
    return this.http.get('assets/samples/import-indents.json');
  }

  getImportFeature(offset,limit,search) {
    return this.http.get('assets/samples/import-feature.json');
  }

  getShipment(offset,limit,search) {
    return this.http.get('assets/samples/shipment-dept.json');
  }

  putShipment(shipmentDetails) {
    return this.http.put(shipmentUrl, shipmentDetails);
  }

  getExtensionOfImport(offset,limit,search) {
    return this.http.get('assets/samples/extension-of-import.json');
  }

  getCancelImportPermit(offset,limit,search) {
    return this.http.get('assets/samples/cancelled-import-permit.json');
  }


  createImportPermitChallan(importPermitChallanEditObj){
    return this.http.post(importPermitChallanEditUrl, importPermitChallanEditObj)
  }

  getImportFeatures() {
    const headerDict = {
      feature
    };
    return this.http.get(importUrl, { headers: headerDict });
  }

  getImportFeaturesByParams(paramObj): any {
    const headerDict = {
      feature
    };
    return this.http.get(importUrl, { headers: headerDict, params: paramObj });
  }

  createIndent(indentObj:any){
    return this.http.post(importUrl, indentObj)
  }

  updateIndent(indentObj:any){
    return this.http.put(importUrl, indentObj)
  }

  getIndents(){
    return this.http.get(importUrl)
  }
}
