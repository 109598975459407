import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Shipment } from 'src/app/core/models/import/shipment.model';
import { ImportService } from 'src/app/core/services/import.service';

@Component({
  selector: 'app-shipment-details-dialog',
  templateUrl: './shipment-details-dialog.component.html',
  styleUrls: ['./shipment-details-dialog.component.scss']
})
export class ShipmentDetailsDialogComponent implements OnInit {
  displayedColumns: string[] = ['no', 'productCode', 'brandName', 'size', 'shipmentInQty', 'batchNumber'];
  dataSource: MatTableDataSource<Shipment> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;

  constructor(public dialogRef: MatDialogRef<ShipmentDetailsDialogComponent>, private importService: ImportService) { }

  ngOnInit(): void {
    this.getShipmentDetails();
  }

  getShipmentDetails() {
    this.importService.getShipment(this.offset, this.limit, this.search).subscribe((res: any) => {
      console.log('res', res);
      this.dataSource = new MatTableDataSource(res.data);
    });
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

}
