import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Clubs } from 'src/app/core/models/licensee-profiles/clubs.model';
import { LicenseeProfilesService } from 'src/app/core/services/licensee-profiles.service';

@Component({
  selector: 'app-open-clubs-customer-details-dialog',
  templateUrl: './open-clubs-customer-details-dialog.component.html',
  styleUrls: ['./open-clubs-customer-details-dialog.component.scss']
})
export class OpenClubsCustomerDetailsDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'no',
    'fatherName',
    'addressProof',
    'village',
    'mandal',
    'district',
    'action'
  ];
  dataSource: MatTableDataSource<Clubs> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;

  constructor(public dialogRef: MatDialogRef<OpenClubsCustomerDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,
    private licenseeProfilesService: LicenseeProfilesService) { }

  ngOnInit(): void {
    this.getStockDetails();
  }

  getStockDetails() {
    this.licenseeProfilesService.getShopsDetails(this.offset, this.limit, this.search).subscribe((res: any) => {
      console.log('res', res);
      this.dataSource = new MatTableDataSource(res.data);
    });
  }

}
