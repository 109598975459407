import { MessageService } from 'primeng/api';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { take, takeUntil } from 'rxjs/operators';
import { CommonsService } from 'src/app/core/services/commons.service';
import { CsdService } from 'src/app/core/services/csd.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-manage-csd-dialog',
  templateUrl: './manage-csd-dialog.component.html',
  styleUrls: ['./manage-csd-dialog.component.scss']
})
export class ManageCsdDialogComponent implements OnInit {


  csdForm: FormGroup;
  productInformation: FormArray;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  offset: number;
  search: string;
  isBrandNameDisable = false;
  isSizeDisable = false;
  isProductCodeDisable = false;
  consignmentList = ['CSD'];
  productInfo: any;
  csdList: any;
  entityId: any;
  todayDate: any = new Date();
  filteredData: any = [];
  isDisable: any;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ManageCsdDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private csdService: CsdService,
    private commonsService: CommonsService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private util:UtilService ) {
      this.entityId = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
    }

  ngOnInit(): void {
    const paramObj = '?action=common_apis&q={"data": ["get_dist_depot","get_prod_details_by_entity_id","get_batches"]}';
    this.spinner.show();
    this.commonsService.getList(paramObj).subscribe((data: any) => {
      this.csdList = data.get_dist_depot.csds.map(csd => csd.destination);
      this.productInfo = data.get_prod_details_by_entity_id;
      this.getProductDetails();
      this.spinner.hide();
    });
    this.csdForm = this.fb.group({
      consignmentType: [this.consignmentList[0], [Validators.required]],
      destination: [''],
      applicantName: ['', [Validators.required,]],
      address: ['', [Validators.required,]],
      challanNo: ['', [Validators.required,]],
      challanDate: ['', [Validators.required,]],
      challanAmount: ['', [Validators.required,]],
      licenseNo: ['', [Validators.required,]],
      purpose: [''],
      route: [''],
      productInformation: this.fb.array([this.updateproductInformation()])
    });
    if (this.data) {
      // this.csdForm.patchValue({
      //   sourceDistilleryName: this.data.sourceDistilleryName,
      //   depotName: this.data.depotName,
      //   indentId: this.data.indentId,
      //   ofsValidityDate: this.data.ofsValidityDate,
      //   date: this.data.date,
      // });
      const productInformation = this.csdForm.get('productInformation') as FormArray;
      productInformation.clear();
      this.data.productInformation.forEach(b => {
        productInformation.push(
          this.fb.group({
            productCode: b.productCode,
            brandName: b.brandName,
            requestedQty: Number(b.requestedQty),
            size: b.size,
          })
        );
      });
    }
    this.productInformation = this.csdForm.get('productInformation') as FormArray;
    this.saveDisabled = true;
  }


  getProductDetails(): any {
    // const code = this.entityId.distillery_unit;
    const code = this.entityId.entity_id;
    this.filteredData = [];
    this.filteredData = this.productInfo.filter(info => {
      return info.supplierCode === code;
    });
    console.log(this.filteredData);
  }
  isDisabled(value){
    return this.productInformation.value.some(item => item.productCode === value); 
  }
  productChange(event, i): any {
    const productInformation = (this.csdForm.get('productInformation') as FormArray).at(i);
    const [bindData] = this.filteredData.filter(data => {
      return data.productCode === event.value;
    });
    this.isBrandNameDisable = true;
    this.isSizeDisable = true;
    productInformation.patchValue({
      productCode: bindData.productCode,
      brandName: bindData.brandName,
      size: bindData.size,
      requestedQty: '',
      mrp:bindData.taxes[0]['Final Mrp'],
      value :''
    });
    this.validateForm();

  }
  updateproductInformation(): any {
    return this.fb.group({
      productCode: ['', Validators.required],
      size: ['', Validators.required],
      brandName: ['', Validators.required],
      requestedQty: ['', Validators.required],
      mrp:['', Validators.required],
      value:['', Validators.required]
    });
  }  
  validateForm() {
    // const control = <FormArray>this.csdForm.controls["productInformation"];
    // this.saveDisabled = !!control.controls.filter(item => !!Object.keys(item.value).filter(obj =>
    //   obj !== "batchId" && !item.value[obj]
    // ).length).length;
    // console.log(this.saveDisabled);

  }
  addUnit() {
    const control = <FormArray>this.csdForm.controls["productInformation"];
    control.push(this.updateproductInformation());
    this.isBrandNameDisable = false;
    this.isProductCodeDisable = false;
    this.isSizeDisable = false;
    this.saveDisabled = true;
  }


  removeUnit(i: number) {
    const control = <FormArray>this.csdForm.controls["productInformation"];
    control.removeAt(i);
    this.validateForm();
    this.isBrandNameDisable = false;
    this.isProductCodeDisable = false;
    this.isSizeDisable = false;
  }



  get f() {
    return this.csdForm.controls;
  }

  createDistilleryIndent(): any {
    this.isDisable = true;
    if (this.csdForm.status !== 'VALID') {
      return;
    }
    this.saveDisabled = true;
    const reqData = {
      action: 'add_csd_indent',
      data: { ...this.csdForm.value }
    };
    reqData.data.productDetails =  reqData.data.productInformation;
    reqData.data.productDetails.forEach(res => {
      this.productInfo.forEach(resp => {
        if (res.brandName === resp.brandName) {
            res.brandCode = resp.brandCode;
          }
        });
      });
      delete reqData.data.productInformation;
      delete reqData.data.indentId;
      this.spinner.show();
      this.csdService.createCsdIndent(reqData).subscribe((res: any) => {
        if (res.status === 'SUCCESS') {
          this.dialogRef.close('Created Successfully');
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'CSD not created' });
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        this.error = err.error.message;
      });
  }

  quantityChange(event, i) {
    const productInformation = (<FormArray>this.csdForm.get("productInformation")).at(i);
    console.log(productInformation)
    const unitsPerCase = this.util.productCodeToDetails(productInformation.value.productCode).unitsPerCase
    if (productInformation.get('mrp').value) {
      productInformation.patchValue({
        value: Number(productInformation.get('mrp').value) * unitsPerCase * Number(event.target.value)
      })
    }

    this.validateForm();
  }
}
