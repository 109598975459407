import { Component, OnInit,Inject, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonsService } from 'src/app/core/services/commons.service';
import { RecoveryService } from 'src/app/core/services/recovery.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-manage-recovery-dialog',
  templateUrl: './manage-recovery-dialog.component.html',
  styleUrls: ['./manage-recovery-dialog.component.scss']
})
export class ManageRecoveryDialogComponent implements OnInit {
  recoveryForm:FormGroup;
  public filteredRetailers: ReplaySubject<any> = new ReplaySubject<any>(1);
  retailers: any[];
  public retailerFitlerCtrl: FormControl = new FormControl();
  loader: any = false;
  public _onDestroy = new Subject<void>();
  @Input() featuresData: any;
  features: any;
  isDuplicate:any;
  errorMessage:any = "Recovery already exsist"

  constructor(private fb:FormBuilder, @Inject(MAT_DIALOG_DATA) public data,private commonService:CommonsService,
  private spinner: NgxSpinnerService,private recoveryService:RecoveryService,private util:UtilService,
  private dialogRef: MatDialogRef<ManageRecoveryDialogComponent>) { }

  ngOnInit(): void {
    this.recoveryForm = this.fb.group({
      destnEntityId:['',[Validators.required]],
      destnEntityName:['',[Validators.required]],
      barAddress:['',[Validators.required]],
      recoveryBalance:['',[Validators.required]],
      receivedAmount:['',[Validators.required]],
      closingBalance:['',[Validators.required]],
      tallyRefNumber:['',[Validators.required]],
      referenceDate:['',[Validators.required]],
    })
    console.log(this.data)
    if(this.data){
      this.recoveryForm.patchValue({
        destnEntityId:this.data.destnEntityId,
        destnEntityName:this.util.formatStringByParamAndIndex(this.data.destnEntityId, '-', 1),
        barAddress:this.data.barAddress,
        recoveryBalance:this.data.recoveryBalance,
        closingBalance:this.data.closingBalance,
        receivedAmount:this.data.receivedAmount,
        tallyRefNumber:this.data.tallyRefNumber,
        referenceDate:new Date(this.data.referenceDate)
      })
    }
    if(!this.data){
      this.getRetailers();
      this.getFeatures();
      this.retailerFitlerCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRetailers();
      });
    }
  
  }

  filterRetailers() {
    if (!this.retailers || (this.retailers && this.retailers.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.retailerFitlerCtrl.value;
    if (!search) {
      this.filteredRetailers.next(this.retailers);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredRetailers.next(
      this.retailers.filter(retailer => JSON.stringify(retailer.destnEntityId).toLowerCase().indexOf(search) > -1)
    );
  }

  getFeatures() {
    this.spinner.show();
    const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).mod_feats;
    this.features = userModules['Recovery'].features;
    this.recoveryService.getRecoveryFeatures().subscribe(res => {
      this.featuresData = res['Recovery'];
      console.log(this.featuresData)
      this.spinner.hide();
    });
  }

  getRetailers(){
    let paramObj = {
      action: 'common_apis',
      q: '{"data": ["get_recovery_details"]}'
    }
    this.spinner.show();
    this.commonService.getCommonEntites(paramObj).subscribe((res) => {
      console.log(res['get_recovery_details'])
      this.retailers = res['get_recovery_details'];
      this.filterRetailers();
      this.spinner.hide();
    },(err)=>{
      this.spinner.hide();
    })
  }
  selectedRetailer(event){
    this.isDuplicate = this.featuresData.some(res=>res.destnEntityId === event.value);
    this.retailers.forEach((item:any)=>{
      if(item.destnEntityId === event.value){
        console.log(item)
        this.recoveryForm.patchValue({
          destnEntityName:this.util.formatStringByParamAndIndex(item.destnEntityId, '-', 1),
          barAddress:item.vendorAddress ? item.vendorAddress.address_new.district : '-',
          recoveryBalance:item.open_indentValue,
          closingBalance:item.closing_indentValue
        })
      }
    })
  }
  createRecovery(status){
    console.log(status)
    let data = {...this.recoveryForm.value}
    if(status === 'update'){
      data.recoveryId = this.data.recoveryId;
    }
    const req_Data={
      action: status === 'create' ? 'add_recovery_details' : 'update_recovery_details',
      data:data
    }
    const apiCall = status === 'create' ? this.recoveryService.createRecovery(req_Data) : this.recoveryService.updateRecovery(req_Data)
    apiCall.subscribe((res:any)=>{
      console.log(res)
      this.dialogRef.close(status === 'create' ?  {message: 'SUCCESS'} : {message: 'UPDATE SUCCESS'});
    })
  }
  postData(postData){
    console.log(postData)
    const req_Data={
      action:'',
      data:postData
    }
  }

}
