import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const gateRegisteUrl = baseUrl + 'gate_register';
// const gateInwardRegisteUrl = baseUrl + 'add_inward_register';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Gate Register'] && userModules.mod_feats['Gate Register'].features ? userModules.mod_feats['Gate Register'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class GateRegisterService {

  constructor(private http: HttpClient) { }

  getInwardRegisterDetails(offset, limit, search) {
    return this.http.get('assets/samples/inward-register.json');
  }

  getOutwardRegisterDetails(offset, limit, search) {
    return this.http.get('assets/samples/outward-register.json');
  }

  getGateRegisteFeatures() {
    const headerDict = {
      feature
    }
    return this.http.get(gateRegisteUrl, { headers: headerDict });
  }

  getProducts(q, action) {
    const paramObj: any = {};
    q ? (paramObj.q = q) : (q = null);
    action ? (paramObj.action = action) : (action = null);
    return this.http.get(gateRegisteUrl, { params: paramObj });
  }

  addInwardGateRegister(obj: any) {
    return this.http.post(gateRegisteUrl, obj);
  }

  addGateRegister(obj: any) {
    return this.http.post(gateRegisteUrl, obj);
  }

  updateGateRegister(obj: any) {
    return this.http.put(gateRegisteUrl, obj);
  }
}
