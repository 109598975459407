import { InterDepotService } from './../../../../core/services/inter-depot.service';
import { UtilService } from './../../../../core/utils/utility-helper';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CommonsService } from 'src/app/core/services/commons.service';
import { ShipmentsService } from 'src/app/core/services/shipments.service';

@Component({
  selector: 'app-tp-request-dialog',
  templateUrl: './tp-request-dialog.component.html',
  styleUrls: ['./tp-request-dialog.component.scss']
})
export class TpRequestDialogComponent implements OnInit {
  tpRequestForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  title: any = 'Create';
  saveDisabled: boolean;
  public routeFilterCtrl: FormControl = new FormControl();
  consignmentTypes = ['OFS', 'CSD', 'CSD Local', 'CSD Export', 'Export Non-Local', 'Export IST', 'Import Permit'];
  routes = [];
  depots:any=[];
  public filteredDepots: ReplaySubject<any> = new ReplaySubject<any>(1);
  public _onDestroy = new Subject<void>();
  @ViewChild('singleRouteSelect') singleRouteSelect: MatSelect;
  routeList: any;
  user: any;
  sourceDepotCode:any;
  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<TpRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private shipmentsService: ShipmentsService, private commonsService: CommonsService, public utilService: UtilService,private interDepotService:InterDepotService,) {
    this.user = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
    this.sourceDepotCode=this.user.entity_id;
  }

  ngOnInit(): void {
    if (this.user.entity_type === 'Depot') {
      this.consignmentTypes = ['ICDC', 'OFIDT'];
    }
    this.tpRequestForm = this.fb.group({
      sourceDepotCode:['',Validators.required],
      destinationDepotCode:['',Validators.required],
      consignmentType: ['',Validators.required],
      route: [''],
      vehicleNo: ['',Validators.required],
      transporterName: ['',Validators.required],
      lrNumber: [''],
      lrDate: [''],
      invoiceNo: ['',Validators.required],
      estimatedDeliveryTime: [''],
      distance: ['',Validators.pattern("^[0-9]*$")]
    });
    this.getDepots();
    if (this.data) {
      this.title = 'Update';
      const tpDetails = this.data.transporterDetails[this.data.transporterDetails.length - 1] ? this.data.transporterDetails[this.data.transporterDetails.length - 1] : this.data.transporterDetails;
      this.tpRequestForm.patchValue({
        consignmentType: this.data.consignmentType,
        route: this.data.route,
        vehicleNo: tpDetails ? tpDetails.vehicleNo : '',
        transporterName: tpDetails ? tpDetails.transporterName : '',
        lrNumber: tpDetails ? tpDetails.lrNumber : '',
        lrDate: tpDetails ? tpDetails.lrDate : '',
        invoiceNo: this.data.invoiceNo,
        estimatedDeliveryTime: this.data.estimatedDeliveryTime,
        distance:this.data.distance
      });
      
    }
    this.tpRequestForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });

    // listen for search field value changes
    this.routeFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRoutes();
      });
  }

  get f() {
    return this.tpRequestForm.controls;
  }

  filterRoutes() {
    if (!this.routes || (this.routes && this.routes.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.routeFilterCtrl.value;
    if (!search) {
      this.filteredDepots.next(this.routes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredDepots.next(
      this.routes.filter(name => name.toLowerCase().indexOf(search) > -1)
    );
  }

  getDepots() {
    let paramObj = {
      action: 'common_apis',
      q: '{"data": ["get_depots"]}'
    }
    this.commonsService.getCommonEntites(paramObj).subscribe((res) => {
      this.depots = res['get_depots']
      this.filteredDepots.next(this.depots);  
    });
  }

  createTPRequest() {
    this.saveDisabled = true;
    let data = { ...this.tpRequestForm.value };
    const req_data = {
      action: 'add_tp',
      data
    };
    this.interDepotService.createIDT(req_data).subscribe((res: any) => {
      if(res.status == 'Successfully added tp'){
        this.dialogRef.close('TP Request Added Successfully');
      }
     
    }, (err) => {
      this.error = err.error.message;
    });

  }

  selectDate(type: string, event: MatDatepickerInputEvent<Date>) {
    // console.log(event, type, "124");
    /*  this.tpRequestForm.patchValue({
       lrDate: event.value
     }); */

  }
  setInitialValue() {
    this.filteredDepots
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleRouteSelect.compareWith = (a, b) => a && b && a === b;
      });
  }
  updateTPRequest() {
    this.saveDisabled = true;
    let data = {
      "route": this.tpRequestForm.get('route').value,
      "consignmentType": this.tpRequestForm.get('consignmentType').value,
      "tpNo": this.data.tpNo,
      "vehicleNo": this.tpRequestForm.get('vehicleNo').value,
      "estimatedDeliveryTime": this.tpRequestForm.get('estimatedDeliveryTime').value,
      "transporterDetails": [{
        invoiceNo: this.tpRequestForm.get('invoiceNo').value,
        lrDate: this.tpRequestForm.get('lrDate').value,
        lrNumber: this.tpRequestForm.get('lrNumber').value,
        transporterName: this.tpRequestForm.get('transporterName').value,
      }
      ]
    };
    const req_data = {
      action: 'edit_tp',
      data
    };
    // this.shipmentsService.updateTPRequest(req_data).subscribe((res: any) => {
    //   this.dialogRef.close('TP Updated Successfully');
    // }, (err) => {
    //   this.error = err.error.message;
    // });

  }

}