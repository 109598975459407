import { NgxSpinnerService } from 'ngx-spinner';
import { Component, Inject, Input, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from 'primeng/api';
import { Get_Shipments, Shipments } from 'src/app/core/models/shipments/shipments.model';
import { CommonsService } from 'src/app/core/services/commons.service';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { ProductCodeDialogComponent } from 'src/app/features/stock/local/product-code-dialog/product-code-dialog.component';
import { OfsProductBranchDetailsDialogComponent } from './ofs-product-branch-details-dialog/ofs-product-branch-details-dialog.component';
import * as moment from 'moment';
import { of, ReplaySubject, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DynamicConfirmationDialogComponent } from 'src/app/core/components/dynamic-confirmation-dialog/dynamic-confirmation-dialog.component';

declare const JsBarcode: any;
@Component({
  selector: 'app-ofs-details-dialog',
  templateUrl: './ofs-details-dialog.component.html',
  styleUrls: ['./ofs-details-dialog.component.scss']
})
export class OfsDetailsDialogComponent implements OnInit {
  value = this.data.tpNo;
  lineColor = '#000000';
  width = "1";
  height = "30";
  // get values(): string[] {
  //   return this.value.split('\n');
  // }
  ofsCode = '';
  exportCode:'';
  csdCode: any = '';
  selectedOfsForPrint: any;
  ofsScanCode = '';
  exportScanCode: '';
  @Input() statusData: any;
  displayedColumns: string[] = ['ofsNo', 'productCode', 'productName', 'orderQty', 'allocateQty', 'action'];
  dataSource: MatTableDataSource<Shipments> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;
  roleName: string;
  ofsData = [];
  csdData = [];
  exportData = [];
  caseCode: any;
  valid: any;
  caseCount: any;
  isEdit: boolean = false;
  isQtyValid: boolean;
  cttDetails: any;
  ofsChangeData: any;
  exportChangeData: any;

  isDisable: boolean = true;
  panelOpenState: boolean =false;
  ofsIndex: any;
  exportIndex:any;
  csdInfoData: any;
  csdScanCode: any;
  csdIndex: any;
  csdChangeData: any;
  caseDetails: any;
  totalDatas: any = [];
  userDetails: any = [];
  distilleryName: any = [];
  distilleryAddress: any = [];
  selectedOfs: any;
  selectedCsd:any;
  productDetails: { supplierCode: any; liquorType: any; brandCode: any; packType: any; size: any; };
  entityId: any = '';
  canDeleteOfs: any = {};
  differenceAmount: number = 0;
  ofsDropDownDetails: string[] = [''];
  filteredOfsDropdown: string[] = [''];
  ofsFilterCtrl: string = '';
  noData:boolean = false;
  @ViewChild('addOfsDialog') addOfsDialog: TemplateRef<any>;
  private ofsAddDialogRef: MatDialogRef<TemplateRef<any>>;
  selectedExport:any;


  
  public ofscodeFilterCtrl: FormControl = new FormControl();
  filteredOfscode: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  ofscodes: any = [];
  public _onDestroy = new Subject<void>();
  showRemainingQtyColumn: any = {};

  constructor(public dialogRef: MatDialogRef<OfsDetailsDialogComponent>,
    private util: UtilService, private commonsService: CommonsService,
    private messageService: MessageService, 
    private shipmentsService: ShipmentsService, @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialog,
    public utilService: UtilService,
    private spinner: NgxSpinnerService) { 
      this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
      this.entityId = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).entity_id;
      if (this.roleName === 'Brewery Manager') {
        this.data.tpNo = this.data.btpNo;
      } else if (this.roleName === 'NonLocal Brewery Manager') {
        this.data.tpNo = this.data.bipNo;
      }
    }
  ofsAddFields = [];
  ngOnInit(): void {
    this.util.matSelectFilter(this, "ofscodeFilterCtrl", "filteredOfscode", "ofscodes");
    this.getOfsData();
    this.userDetails = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
    if (this.roleName === 'Brewery Manager' || this.roleName === 'NonLocal Brewery Manager') {
      this.distilleryName = this.userDetails.entity_id ? this.utilService.formatString(this.userDetails.entity_id) : '';
      this.distilleryAddress = this.userDetails.distillery_address ? this.userDetails.distillery_address[0] : {};
    } else {
      if (this.userDetails.distillery_unit && this.userDetails.distillery_address) {
        this.distilleryName = this.utilService.formatString(this.userDetails.distillery_unit);
        this.distilleryAddress = this.userDetails.distillery_address[0];
      }
    }
    if (this.statusData) {
      this.data = this.statusData;
      
    }
    this.data.consignmentType === 'OFS' || this.data.consignmentType === 'Import Permit' ?  this.getOfsDetails() : this.getCsdDetails();
    if(this.data.consignmentType === 'Export'){
      this.getExportDetails();
    }
    // this.getCttFetails();
    this.ofsAddFields = [
      { ofsCode: '', productCode: '', productName: '', casesCount: '', approvedQty: '' }
    ]
   const test = this.utilService.productCodeToDetails('9289W0883GNN');
   console.log(test);
   console.log(this.data)
   
  }
  
  generateBarcode(ofsCode): any {
    JsBarcode('#ofsBarCode', ofsCode,
    {
      width: 1,
      height: 40
    });
  }

  getCttFetails() {
    let data = JSON.stringify({ "data": ["get_ctt"] });

    const req_Data = {
      action: 'common_apis',
      q: data

    }
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {
      this.cttDetails = result['get_ctt'];
    })
  }


  getCsdDetails() {
    this.getCsdinfoDetails();
    let tpNo = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
    const req_Data = {
      action: 'common_apis',
      q: '{"data":  ["get_approved_csd"]}'

    }
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {

      this.csdData = result['get_approved_csd'];
     /*  if (this.ofsData) {

        this.ofsData.forEach(ofs => {
          ofs.productDetails.forEach(product => {
            product['shipmentQty'] = product.shipmentQty ? product.shipmentQty : product.approvedQty;
          })
        })
      } */
    })

  }

  csdChange() {
    console.log(this.csdCode,"90::")
  }
 

  getCsdinfoDetails() {
    let tpNo = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
    const req_Data = {
      action: 'common_apis',
      q: '{"data":  {"action": "get_added_csd", "value": "' + tpNo + '"}}'

    }
    this.spinner.show();
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {

      this.ofsData = result['get_added_csd'];
      this.spinner.hide();
     /*  if (this.ofsData) {

        this.ofsData.forEach(ofs => {
          ofs.productDetails.forEach(product => {
            product['shipmentQty'] = product.shipmentQty ? product.shipmentQty : product.approvedQty;
          })
        })
      } */
    })
  }

  addCsd(): any {
    let data: any = {};
    this.data.ofsData.forEach(ofs => {
      if (this.ofsCode === ofs.ofsCode) {
        data = ofs;
      }
      data['tpNo'] = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
      if(!data['tpNo']) {
        data['ipNo'] =this.data.ipNo;
      }
      data['consignmentType'] = this.data.consignmentType;
      data['destnEntityId'] = this.data.destnEntityId;
    });
    const req_Data = {
      action: 'add_tp_products',
      data
    };
    let isDestinationMatches
    console.log(this.selectedCsd.depotCode , this.data.destnEntityId)
    const duplicateCsd = this.csdInfoData && this.csdInfoData.some(csd => csd.csdCode === this.csdCode);
    if(this.data.consignmentType === 'CSD'){
       isDestinationMatches = this.selectedCsd.depotCode === this.data.destnEntityId;
    }else{
       isDestinationMatches = this.data.depotCode === this.data.destnEntityId;

    }
    const errorMessage = duplicateCsd ? 'Duplicate CSD is not allowed' : 'Destination need to match';
    if(duplicateCsd || !isDestinationMatches){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: errorMessage });
    }else{
      this.spinner.show();
      this.shipmentsService.putOfs(req_Data).subscribe(res => {
        this.spinner.hide();
        this.getCsdinfoDetails();
      });
    }
  }

  filterOfsData(){
    if (this.ofsFilterCtrl === '') {
      this.filteredOfsDropdown = this.ofsDropDownDetails;
      this.ofsCode = '';
    }
    this.filteredOfsDropdown = this.ofsDropDownDetails.filter(res => {
      if (res && res !== undefined) {
        return res.toLowerCase().includes(this.ofsFilterCtrl.toLowerCase());
      }
    });
  }

  getOfsData(){
    const req_Data = {
      action: 'common_apis',
      q: '{"data": {"action": "get_ofs_details", "value": "' + this.data.destnEntityId + '"}}'
    }
    this.ofscodes = [];
    this.spinner.show();
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {
      if(result["get_ofs_details"] && result["get_ofs_details"].length) {
        // this.ofsDropDownDetails =  result["get_ofs_details"].map((item)=>{return item.ofsCode});
        // this.filteredOfsDropdown = this.ofsDropDownDetails;
        this.ofscodes = result.get_ofs_details.map(el => el.ofsCode);
        this.filteredOfscode.next(this.ofscodes);
      }
      this.spinner.hide();
    });
  }

  closeAddOfsData(){    
    this.ofsAddDialogRef.close();
  }
  getAddedOfsData(){
    if(this.ofsCode){
      const req_Data = {
        action: 'common_apis',
        q: '{"data": {"action": "get_added_ofs_data", "value": "' + this.ofsCode + '"}}'
  
      }
      this.spinner.show();
      this.commonsService.getCommonEntites(req_Data).subscribe(result => {
        if(result && result['get_added_ofs_data']){
          this.selectedOfs = result['get_added_ofs_data'][0];
        }
        // if(this.selectedOfs){   
        //   let balanceAmount = this.selectedOfs?.challanDetails?.balanceAmount;
        //   let totalExciseDuty = this.selectedOfs.productDetails.reduce((acc, cur)=>{
        //     acc += cur.exciseDuty || 0;
        //     return acc;
        //   }, 0);
        //   this.differenceAmount = Math.abs(balanceAmount - totalExciseDuty); 
        //   if(balanceAmount < totalExciseDuty){
        //     this.ofsAddDialogRef = this.dialog.open(this.addOfsDialog);
        //   } else {
        //     this.addOfs();
        //   }  
        // }
        this.spinner.hide();
      })
    }
    
  }

  getAddedCsdData(){
    console.log(this.ofsCode)
    if(this.ofsCode){
      const req_Data = {
        action: 'common_apis',
        q: '{"data": {"action": "get_added_ofs_data", "value": "' + this.ofsCode + '"}}'
  
      }
      this.commonsService.getCommonEntites(req_Data).subscribe(result => {
        this.selectedCsd = result['get_added_ofs_data'][0];
        console.log(this.selectedCsd)
      })
    }
    
  }
  getAddedExportData(){
    console.log(this.exportCode)
    if(this.exportCode){
      const req_Data = {
        action: 'common_apis',
        q: '{"data": {"action": "get_added_export_data", "value": "' + this.exportCode + '"}}'
  
      }
      this.commonsService.getCommonEntites(req_Data).subscribe(result => {
        console.log(result)
        this.selectedExport = result['get_added_export_data'][0];
      })
    }
  }
  getOfsDetails() {
    console.log('dd', this.data);
    let tpNo = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
    if(!tpNo) {
      tpNo =this.data.ipNo;
    }
    const payload: any = {
      data: { action: 'get_added_ofs', value: this.data.bipNo ? this.data.bipNo : this.data.btpNo ? this.data.btpNo : tpNo }
    };
    const req_Data = {
      action: 'common_apis',
      q: JSON.stringify(payload)
    };
    this.showRemainingQtyColumn = {};
    this.ofsData = [];
    this.spinner.show();
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {
      if (result['get_added_ofs'] && result['get_added_ofs'].length) {
        this.ofsData = result['get_added_ofs'];
        if (this.ofsData) {
          this.ofsData.forEach((ofs, j) => {
            this.showRemainingQtyColumn[j] = false;
            this.canDeleteOfs[ofs.ofsCode] = false;
            const isScanned: any = ofs.productDetails.some(el => el.casesCount >= 0);
            if (!isScanned) {
              this.canDeleteOfs[ofs.ofsCode] = true;
            }
            this.showRemainingQtyColumn[j] = ofs.productDetails.some(el => el.remainingQuantity > 0);
            ofs.productDetails.forEach(product => {
              if(product.remainingQuantity || product.remainingQuantity == 0 ){
                product['shipmentQty'] = product.shipmentQty ? product.shipmentQty : product.remainingQuantity;
              }else{
                product['shipmentQty'] = product.shipmentQty ? product.shipmentQty : product.approvedQty;
              }
            })
          })
        }
      }
      this.noData=true;
      this.spinner.hide();
    })
    console.log('this.canDeleteOfs', this.canDeleteOfs);

  }

  shipmentQuantityChange(item) {
    return item.approvedQty < item.shipmentQty || item.shipmentQty < item.casesCount;
  }
  changeShipmentQuantity(item){
    console.log(item)
    return item.remainingQuantity < item.shipmentQty || item.shipmentQty < item.casesCount;
  }
  addOfs() {
    if (!this.selectedOfs) {
      return;
    }
    if (this.selectedOfs.supplierCode !== this.entityId) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'This OFS cannot be added, Please Check the OFS belongs to current distillery' });
      return;
    }
    let data: any = {};
    data = this.selectedOfs;
    data['tpNo'] = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
    if (this.roleName === 'Brewery Manager') {
      data.btpNo = this.data.tpNo;
    } else if (this.roleName === 'NonLocal Brewery Manager') {
      data.bipNo = this.data.bipNo;
    }
    if(!data['tpNo']) {
      data['ipNo'] =this.data.ipNo;
    }
    data['consignmentType'] = this.data.consignmentType;
    data['destnEntityId'] = this.data.destnEntityId;
    if(this.data.consignmentType === 'Import Permit'){
      this.selectedOfs.productDetails.map((item:any)=>{
        item.shipmentQty = item.approvedQty
      })

    }
    data.productDetails = data.productDetails.filter(el => el.remainingQuantity != 0)
    const req_Data = {
      action: 'add_tp_products',
      data
    }
    console.log(req_Data)
    const duplicateOfs = this.ofsData && this.ofsData.some(ofs => ofs.ofsCode === this.ofsCode);
    const isDestinationMatches = data.depotCode === data.destnEntityId; 
    const remainingQty =  this.selectedOfs && this.selectedOfs.productDetails.every(res=> res.remainingQuantity == 0);
    let errorMessage = duplicateOfs ? 'Duplicate OFS is not allowed' : !isDestinationMatches ? 'Destination need to match' : remainingQty ? 'Remaining Quantity is 0 for this OFS Number': '';
    if(duplicateOfs || !isDestinationMatches || remainingQty){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: errorMessage });
    }else if(!this.checkOfsValidity(data)){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Ofs expired.Please enter a valid ofs' });
    }else{
      this.spinner.show();
      this.shipmentsService.putOfs(req_Data).subscribe(res => {
        this.ofsCode = '';
        this.spinner.hide();
        this.getOfsDetails();
      })
    }
  }



  addExport() {
    console.log(this.data)
    let data: any = {};
    data = this.selectedExport;
    data['tpNo'] = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
    if(!data['tpNo']) {
      data['ipNo'] =this.data.ipNo;
    }
    data['consignmentType'] = this.data.consignmentType;
    data['destnEntityId'] = this.data.destnEntityId;
    const req_Data = {
      action: 'add_tp_export',
      data
    }
      req_Data.data.productDetails.forEach((item:any)=>{
    
        if(item.apBasic >= 0){
          delete item.apBasic;
        }if(item.apbclValue >= 0){
          delete item.apbclValue;
        }if(item.approvedQty){
          item.approvedQty = item.approvedQty ? parseInt(item.approvedQty) :0;
          item.shipmentQty = item.approvedQty ? parseInt(item.approvedQty) :0
        }if(item.bls >= 0){
          delete item.bls;
        }if(item.commision >= 0){
          delete item.commision;
        }if(item.concernedInvoiceBasic >= 0){
          delete item.concernedInvoiceBasic;
        }if(item.cst >= 0){
          delete item.cst;
        }if(item.exportFee >= 0){
          delete item.exportFee;
        }if(item.invoiceValue >= 0){
          delete item.invoiceValue;
        }if(item.other >= 0){
          delete item.other;
        }if(item.tcs >= 0){
          delete item.tcs;
        }
        
      })
    console.log(data)
    console.log(req_Data)
    const duplicateExport = this.exportData && this.exportData.some(ofs => ofs.exportCode === this.exportCode);
    const isDestinationMatches = data.nameOfTheImporter === data.destnEntityId; 
    const remainingQty =  this.selectedOfs && this.selectedOfs.productDetails.some(res=> res.remainingQuantity == 0);
    let errorMessage = duplicateExport ? 'Duplicate Export is not allowed' : !isDestinationMatches ? 'Destination need to match' : remainingQty ? 'Remaining Quantity is 0 for this OFS Number': '';
    if(duplicateExport || !isDestinationMatches || remainingQty){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: errorMessage });
    }else{
      this.spinner.show();
      this.shipmentsService.putOfs(req_Data).subscribe(res => {
        this.exportCode = '';
        this.spinner.hide();
        this.getExportDetails();
      })
    }
  }

  getExportDetails() {
    let tpNo = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
    if(!tpNo) {
      tpNo =this.data.ipNo;
    }
    const req_Data = {
      action: 'common_apis',
      q: '{"data": {"action": "get_added_export", "value": "' + tpNo + '"}}'

    }
    this.spinner.show();
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {
      this.exportData = result['get_added_export'];
      console.log(this.exportData)
      if (this.exportData) {
        this.exportData.forEach(ofs => {
          ofs.productDetails.forEach(product => {
            if(product.remainingQuantity || product.remainingQuantity == 0 ){
              product['shipmentQty'] = product.shipmentQty ? product.shipmentQty : product.remainingQuantity;
            }else{
              product['shipmentQty'] = product.shipmentQty ? product.shipmentQty : product.approvedQty;
            }
          })
        })
      }
      this.spinner.hide();
    })

  }

  checkOfsValidity(ofsValue){
    const ofsDate = new Date(ofsValue.validityDate);
    const currentDate = new Date();
    return ofsDate >= currentDate;
  }

  shipmentEdit(item) {
    item.isEdit = true;
  }

  shipmentSave(item, ofs) {
    console.log(ofs)
      if (item.approvedQty < item.shipmentQty || item.shipmentQty < item.casesCount) {
        return;
      }
      if(item.remainingQuantity){
        if (item.remainingQuantity < item.shipmentQty || item.shipmentQty < item.casesCount) {
          return;
        }
      }
      let req_Data;
      if(this.data.consignmentType == 'Export'){
        req_Data = {
          action: 'update_export_shipment_qty',
          data: { "tpNo": this.data.tpNo, "exportIndentNumber":ofs.exportIndentNumber,"indentId":ofs.exportIndentNumber, "productCode": item.productCode, "shipmentQty": item.shipmentQty, "batchId": item.batchId }
        }
      }else{
        req_Data = {
          action: 'update_shipment_qty',
          data: { "tpNo": this.data.tpNo, "ofsCode": ofs.ofsCode, "productCode": item.productCode, "shipmentQty": item.shipmentQty, "batchId": item.batchId }
        }
      }
      console.log(req_Data)
    
    this.shipmentsService.saveShipmentQuantity(req_Data).subscribe(result => {
      if (result.status && result.status === 'SUCCESS') {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Updated Successfully' });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: result.error ? result.error : 'Not Updated' });
      }
    });
    item.isEdit = false;
  }


  shipmentCsdSave(item, csd) {
    if (item.approvedQty < item.shipmentQty || item.shipmentQty < item.casesCount) {
      return;
    }
    const req_Data = {
      action: 'update_shipment_qty',
      data: { "tpNo": this.data.tpNo,"ofsCode":this.data.ofsCode, "indentId": csd.indentId, "productCode": item.productCode, "shipmentQty": item.shipmentQty, "batchId": item.batchId }
    }
    this.shipmentsService.saveShipmentQuantity(req_Data).subscribe(result => {
      if (result.status && result.status === 'SUCCESS') {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Updated Successfully' });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: result.error ? result.error : 'Not Updated' });
      }
    })
    item.isEdit = false;
  }
  dataFill(data) {
    let flag = false;
    Object.keys(data).forEach(val => {
      flag = !data[val];
    })
    return flag;
  }

  deleteOfs(i) {
    this.ofsAddFields.splice(i, 1);
  }

  caseCodeChange() {
    console.log(this.caseCode,this.ofsScanCode,this.exportScanCode)
    if (this.caseCode && (this.ofsScanCode || this.exportScanCode || this.csdScanCode)) {
    const caseReq_Data = {
      action: 'common_apis',
      q: '{"data": {"action": "get_case_details", "value": "' + this.caseCode + '"}}'
    }

    this.commonsService.getCommonEntites(caseReq_Data).subscribe(result => {
      this.caseDetails = result['get_case_details'];
      if(this.caseDetails){
        const isTpAvailable = this.caseDetails.tpNo;
        const matchesCaseDetails = this.ofsChangeData.productDetails.some(product => {
          if (this.caseDetails.productCode.length === 11) {
            this.caseDetails.productCode = this.utilService.convertProductCodeLength11TO12(this.caseDetails.productCode);
          }
          return this.caseDetails.productCode === product.productCode;
        }); 
        const scannedQtyAllowed = this.ofsChangeData.productDetails.some(product => this.caseDetails.productCode === product.productCode && (!product.casesCount || product.shipmentQty > product.casesCount)); 
        const caseError = (isTpAvailable || this.caseDetails.location !== "Distillery")? 'Case already scanned' : !matchesCaseDetails ? 'Wrong Product Case Scanned' : 'Shipment Quantity Reached';
        if(isTpAvailable || !matchesCaseDetails || !scannedQtyAllowed){
          this.messageService.add({ severity: 'error', summary: 'Error', detail: caseError });
        }else if(matchesCaseDetails && scannedQtyAllowed){
          this.scanBarCode();
        }
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid Case' });
      }
    })
     
    }
  }

  scanBarCode(){
    let req_Data;
    if(this.data.consignmentType === 'OFS' || this.data.consignmentType === 'CSD'){
       req_Data = {
        action: 'add_casecodes',
        data: { "tpNo": this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo, "ofsCode": this.ofsScanCode, "caseCode": this.caseCode, "depotCode": this.data.destnEntityId, "productDetails": this.ofsData[this.ofsIndex].productDetails }
  
      }
    }else if(this.data.consignmentType == 'Export'){
      this.exportData[this.exportIndex].productDetails.map((res:any)=>{
        res.shipmentQty = res.shipmentQty ? res.shipmentQty : res.approvedQty;
      })
      req_Data = {
        action: 'add_export_casecodes',
        data: { "tpNo": this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo, "exportIndentNumber": this.exportScanCode, "caseCode": this.caseCode, "depotCode": this.data.destnEntityId, "productDetails": this.exportData[this.exportIndex].productDetails }
  
      }
    }
    console.log(req_Data)
 
 this.shipmentsService.scanBarCode(req_Data).subscribe(res => {
        console.log(res);

        this.valid = res.status;
        this.caseCode = '';
        
        if(this.data.consignmentType == 'Export'){
          this.getExportDetails();
        }else{
          this.getOfsDetails();
        }
      })
  }

  caseCodeCsdChange() {
    let pbData = [];


    /*    this.cttDetails.forEach(ctt => {
         if(ctt.caseCode == this.caseCode) {
           cttData = ctt;
         }
       }) */
    if (this.caseCode && this.csdScanCode) {
    //   let pbDetails = this.ofsChangeData.productDetails.filter(product => {
    //     return Number(product.shipmentQty) > Number(product.casesCount) || !product.casesCount;
    //   })
    //   pbData = pbDetails.map(ofs => {
    //     return { "batchId": ofs.batchId, "productCode": ofs.productCode }
    //   })
      const req_Data = {
        action: 'add_csd_casecodes',
        data: { "tpNo": this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo, "indentId": this.csdScanCode, "caseCode": this.caseCode, "depotCode": this.data.destinationEntityId, "productDetails": this.csdInfoData[this.csdIndex].productDetails }

      }

      this.shipmentsService.scanBarCode(req_Data).subscribe(res => {
        console.log(res);

        this.valid = res.status;
        this.caseCode = '';
        this.getCsdinfoDetails();
        //  this.ofsData.forEach(ofs => {
        //   console.log(ofs.ofsCode, this.ofsChangeData, res.productCode, res.batchId, "155:::::")
        //   if (ofs.ofsCode == this.ofsChangeData.ofsCode) {
        //     ofs.productDetails.forEach(product => {
        //       if (product.productCode == res.productCode && product.batchId == res.batchId) {
        //         product.casesCount = product.casesCount ? product.casesCount++ : 1;
        //       }
        //     })
        //   }
        // }) 

      })
    }
  }

  changeOfs(code, i) {
    this.isDisable = false;
    this.ofsScanCode = code.ofsCode;
    this.ofsIndex = i;
    this.ofsChangeData = code;
  }

  changeExport(code,i){
    this.isDisable = false;
    this.exportScanCode = code.exportIndentNumber;
    this.exportIndex = i;
    this.ofsChangeData = code;
  }

  changeCsd(code, i) {
    this.isDisable = false;
    this.csdScanCode = code.indentId;
    this.ofsIndex = i;
    this.ofsChangeData = code;
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

  print(ofsItem){
    console.log(ofsItem)
    this.generateBarcode(ofsItem.ofsCode);
    this.selectedOfsForPrint = ofsItem;
    this.totalDatas = [];
    this.totalDatas.approvedQty = 0;
    this.totalDatas.shipmentQty = 0;
    this.totalDatas.bulkLitres = 0;
    this.totalDatas.proofLitres = 0;
    this.totalDatas.exciseDuty = 0;
    this.totalDatas.basicPricing = 0;
    this.totalDatas.value = 0;
    ofsItem.productDetails.map(row => {
      this.totalDatas.approvedQty += row.approvedQty;
      this.totalDatas.bulkLitres += row.bulkLitres;
      // this.totalDatas.bulkLitres += (row.shipmentQty*row.size*row.unitsPerCase)/1000;
      this.totalDatas.proofLitres += row.proofLitres;
      this.totalDatas.basicPricing += this.data.consignmentType === 'Import Permit' ? (row.basicPricing[0].price ? row.basicPricing[0].price : 0) : row.basicPricing ? row.basicPricing :0;
      this.totalDatas.exciseDuty += row.exciseDuty;
       this.totalDatas.shipmentQty += row.shipmentQty;
      if (row.value) {
        this.totalDatas.value += row.value;
      }
    });

    setTimeout(() => {
      this.getPrint();
    }, 100);
  }
  getPrint(){
    // if (this.statusData && this.statusData.tpNo) {
    //   JsBarcode('#barcode', this.statusData.tpNo);
    // }
    let printContents, popupWin;
    if (this.roleName === 'Brewery Manager' || this.roleName === 'NonLocal Brewery Manager') {
      printContents = document.getElementById('print-order-for-supply-beer').innerHTML;  
    } else {
      printContents = document.getElementById('print-order-for-supply').innerHTML;
    }
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.write(`${printContents}`);
    popupWin.document.close();
  }
  convertToDate(date) {
    return date ? moment(date).format('DD/MM/YY, h:mm a') : '-';
  }
  getBatchList(productDetails, item): any {
    if (this.data.btpNo || this.data.bipNo || this.data.ipNo) {
      this.getBatchDialog(productDetails, item);
    } else {
      const actions: any = (this.data.bipNo || this.data.btpNo || this.data.ipNo) ? 'get_batches' : 'get_batches_by_prod';
      const values: any = (this.data.bipNo || this.data.btpNo || this.data.ipNo) ? this.entityId : productDetails.productCode;
      // let payload = '?action=common_apis&q={"data": {"action": ' + action + ', "value" : "'+ productDetails.productCode+'"}}';
      const payload = `?action=common_apis&q={"data": {"action": "${actions}", "value" : "${values}"}}`;
      this.commonsService.getBatchProdList(payload).subscribe(resp => {
        console.log('rrr::::::', resp[actions].length);
        if (resp[actions].length) {
          this.getBatchDialog(productDetails, item, resp[actions]);
        }
      });
    }

  }

  getBatchDialog(productDetails, item, batchIds = []){
    let batchData;
    console.log(item)
    if(this.data.consignmentType == 'OFS' || this.data.consignmentType == 'Import Permit'){
      batchData = {productDetails: productDetails, tpNo: this.data.tpNo, ofsCode: item.ofsCode, batchIdListData: batchIds,consignmentType:this.data.consignmentType }
    }else if(this.data.consignmentType == 'Export'){
      batchData = {productDetails: productDetails, tpNo: this.data.tpNo, exportCode: item.indentId, batchIdListData: batchIds,consignmentType:this.data.consignmentType }
    }
    const dialogRef = this.dialog.open(OfsProductBranchDetailsDialogComponent, {
      width: '75%',
      autoFocus: false,
      data: batchData
    });


    dialogRef.afterClosed().subscribe((result) => {
      if(result){
        if(this.data.consignmentType == 'OFS' || this.data.consignmentType == 'Import Permit') {
          this.getOfsDetails();
        }
       else if(this.data.consignmentType == 'Export'){
          this.getExportDetails();
        }
      }
    });
  }
  // canShowDeleteOfs(ofsData) {
  //   let canDeleteOfs: boolean = false;
  //   console.log(ofsData);
  //   if (ofsData && ofsData.productDetails) {
  //     const isNotScanned: any = ofsData.productDetails.every(el => !el.shipmentQty);
  //     if (isNotScanned) {
  //       canDeleteOfs = true;
  //     }
  //   }
  //   return canDeleteOfs;
  // }
  deleteOfsData(ofsCode): any {
    const req_Data = {
      action: 'edit_shipment_ofs',
      data: {
        ofsCode: ofsCode,    
        tpNo: this.data.bipNo ? this.data.bipNo : this.data.btpNo ? this.data.btpNo : this.data.ipNo ? this.data.ipNo : this.data.tpNo
      }
    }
    console.log(this.data, req_Data, "77DeleteOFS::::::::::")
    this.shipmentsService.saveShipmentQuantity(req_Data).subscribe((res: any) => {
      if (res && res.status === 'SUCCESS') {
        this.getOfsDetails();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Deleted Successfully' });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: res && res.error ? res.error : 'OFS has not been deleted' });
      }
    }, (err) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'OFS has not been deleted' });
    });
  }

  deleteOfsProductData(ofsCode, productDetails): any {
    const req_Data = {
      action: 'edit_batch_ofs',
      data: {
        ofsCode: ofsCode,    
        tpNo: this.data.bipNo ? this.data.bipNo : this.data.btpNo ? this.data.btpNo : this.data.ipNo ? this.data.ipNo : this.data.tpNo,
        batchId : productDetails.batchIds,
        productCode : productDetails.productCode
      }
    }
    this.shipmentsService.saveShipmentQuantity(req_Data).subscribe((res: any) => {
      if (res && res.status === 'SUCCESS') {
        this.getOfsDetails();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'OFS Product Deleted Successfully' });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: res && res.error ? res.error : 'OFS Product has not been deleted' });
      }
    }, (err) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'OFS Product has not been deleted' });
    });
  }
  updateShipmentQty(productDetails, item): any {
    // const isNotValid = productDetails.some(el => (el.approvedQty < el.shipmentQty)
    //   || (el.approvedQty < el.shipmentQty || el.shipmentQty < el.casesCount));
    // if (isNotValid) {
    //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please check the shipment quantity' });
    //   return;
    // }
    const datas: any = [];
    productDetails.forEach(el => {
      const data: any = {
        // btpNo: this.data.btpNo,
        ofsCode: item.ofsCode,
        productCode: el.productCode,
        shipmentQty: el.shipmentQty,
        batchId: el.batchId
      };
      if (this.roleName === 'Brewery Manager') {
        data.btpNo = this.data.btpNo;
      } else if (this.roleName === 'NonLocal Brewery Manager') {
        data.bipNo = this.data.bipNo;
      } else {
        data.tpNo = this.data.tpNo;
      }
      datas.push(data);
    });
    const reqData = {
      action: 'update_shipment_qty',
      data: datas
    };
    console.log(reqData);
    this.shipmentsService.saveShipmentQuantity(reqData).subscribe(res => {
      if (res) {
        if (res.status && res.status === 'SUCCESS') {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Updated' });
          this.dialogClose();
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: res.error ? res.error : 'Not Updated' });
        }
      }
    });
  }
  isDisableUpdateShipment(productDetails): any {
    return productDetails.some(el => !el.batchId || !el.shipmentQty || el.shipmentQty > el.approvedQty || el.shipmentQty < 1);
  }
  confirm(ofsCode, comp: any = ''): any {
    console.log(ofsCode)
    const data: any = {};
    data.content = comp === '' ? 'delete all products ? ' : 'delete product';
    const dialogRef = this.dialog.open(DynamicConfirmationDialogComponent, {
      width: '25%',
      data:data,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result.message}`);
      if (result.message === 'Success') {
        if (comp === '') {
          this.deleteOfsData(ofsCode);
        } else {
          this.deleteOfsProductData(ofsCode, comp);
        }
      }
    });
  }

}
