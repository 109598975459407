import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-open-ofs-details-dialog',
  templateUrl: './open-ofs-details-dialog.component.html',
  styleUrls: ['./open-ofs-details-dialog.component.scss']
})
export class OpenOfsDetailsDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'no',
    'indentNumber',
    'ofsNumber',
    'productCode',
    'brandName',
    'ifsQty',
    'ofsQty',
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  constructor(public dialogRef: MatDialogRef<OpenOfsDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA)public data) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data);
  }

  dialogClose(): void{
    this.dialogRef.close();
  }

}
