import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonsService } from 'src/app/core/services/commons.service';
import { StockReturnService } from 'src/app/core/services/stock-return.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-manage-stock-return-drainout-dialog',
  templateUrl: './manage-stock-return-drainout-dialog.component.html',
  styleUrls: ['./manage-stock-return-drainout-dialog.component.scss']
})
export class ManageStockReturnDrainoutDialogComponent implements OnInit {

  stockReturnDrainoutForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  depots: any = [];
  productDetails: FormArray;
  products: any = [];
  brands: any = [];
  productList: any = [];
  filteredProducts: any = [];
  roleName: any = '';
  disable: any = false;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ManageStockReturnDrainoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private commonsService: CommonsService,
    private stockReturnService: StockReturnService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    public util: UtilService
  ) { }

  ngOnInit(): void {
    const userInfo = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
    this.roleName = userInfo['role'];
    // console.log(this.roleName);
    console.log('this.data', this.data);
    this.getDepots();
    // this.getProducts();
    this.stockReturnDrainoutForm = this.fb.group({
      stockType: ['', [Validators.required]],
      depotCode: ['', [Validators.required]],
      productDetails: this.fb.array([this.updateProduct()])
    });
    this.productDetails = this.stockReturnDrainoutForm.get('productDetails') as FormArray;
    if (this.data && this.data.indentId) {
      this.productDetails.controls.shift();
      this.stockReturnDrainoutForm.patchValue({
        stockType: this.data.stockType,
        depotCode: this.data.sourceEntityId,
      });
      // if (this.data.sourceEntityId) {
      //   this.getProducts(this.data.sourceEntityId);
      // }
      const productDetails = this.productDetails;
      this.data.productDetails && this.data.productDetails.forEach((b, i) => {
        productDetails.push(
          this.fb.group({
            brandCode: b.brandCode,
            productCode: b.productCode,
            size: b.size,
            requestedCases: b.requestedCases,
            requestedBottles: b.requestedBottles,
            availableCases: b.availableCases,
            availableBottles: b.availableBottles,
            approvedCases: b.approvedCases ? b.approvedCases : b.requestedCases,
            approvedBottles: b.approvedBottles ? b.approvedBottles : b.requestedBottles,
          })
        );
        // if (!this.filteredProducts[i]) {
        //   this.filteredProducts[i] = [];  
        // }
        // this.filteredProducts[i] = this.productList.filter(el => el.brandCode === b.brandCode);
      });
      this.productDetails = this.stockReturnDrainoutForm.get('productDetails') as FormArray;
      // console.log(this.filteredProducts);
      // console.log(this.productDetails);
      // this.productDetails.controls.shift();
      // this.stockReturnDrainoutForm.disable();
    }
    // this.stockReturnDrainoutForm.valueChanges.subscribe((change) => {
    //   this.saveDisabled = false;
    // })
    this.stockReturnDrainoutForm.valueChanges.subscribe(el => this.disableBtn());
  }
  updateProduct(){
    return this.fb.group({
      brandCode: ['', [Validators.required]],
      productCode: ['', [Validators.required]],
      size: ['', [Validators.required]],
      requestedCases: [],
      requestedBottles: [],
      availableCases: [''],
      availableBottles: [''],
      approvedCases: [''],
      approvedBottles: [''],
    });
  }
  get f(){
    return this.stockReturnDrainoutForm.controls;
  }

  create() {
    this.saveDisabled = true;
    const req_Data = {
      action : 'add_stock_return',
      data : this.stockReturnDrainoutForm.value
    }
    req_Data.data.productDetails.forEach(element => {
      delete element.availableCases;
      delete element.availableBottles;
      delete element.approvedCases;
      delete element.approvedBottles;
      if (element.requestedCases === 0) {
        element.requestedCases = ''
      }
      if (element.requestedBottles === 0) {
        element.requestedBottles = ''
      }
    });
    console.log(req_Data);
    this.spinner.show();
    this.stockReturnService.create(req_Data).subscribe((res: any) => {
      if (res && res.status === 'SUCCESS') {
        this.dialogRef.close('Created Successfully');
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: res && res.error ?  res.error : 'Not Created' });
      }
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      this.error = err.error.message;
    });
  }
  approve(mode = '') {
    this.saveDisabled = true;
    const payLoad: any = {
      indentId: this.data.indentId,
      status: mode === 'reject' ? 'Rejected' : 'Approved',
      productDetails: this.stockReturnDrainoutForm.value.productDetails 
    }
    let req_Data: any = {
      action : 'update_stock_return',
      data : payLoad
    }
    // req_Data = {...req_Data, ...payLoad};
    req_Data.data.productDetails.forEach(element => {
      delete element.availableCases;
      delete element.availableBottles;
    });
    console.log(req_Data);
    this.spinner.show();
    this.stockReturnService.update(req_Data).subscribe((res: any) => {
      this.dialogRef.close(`${req_Data.data.status} Successfully`);
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      this.error = err.error.message;
    });
  }

  addUnit() {
    const control = <FormArray>this.stockReturnDrainoutForm.controls["productDetails"];
    control.push(this.updateProduct());
  }
  

  removeUnit(i: number) {
    const control = <FormArray>this.stockReturnDrainoutForm.controls["productDetails"];
    control.removeAt(i);
  }
  getDepots(): any {
    let paramObj = '?action=common_apis&q={"data": ["get_dist_depot"]}';
    this.commonsService.getList(paramObj).subscribe(data => {
      this.depots = data['get_dist_depot']['depots'];
    });
  }
  getProducts(depotCode) {
    this.spinner.show();
    if (this.productDetails.controls.length) {
      this.productDetails.controls.forEach(el => {
        Object.keys(el['controls']).forEach(key => {
          el['controls'][key].setValue('');
        });
      });
    }
    const req_Data = {
      "action": "common_apis",
      "q": '{"data": {"action": "get_stock_srd", "value":"' +depotCode+'"}}'
    };
    this.commonsService.getCommonEntites(req_Data).subscribe((res: any) => {
      console.log('res:999', res);
      if (res && res.get_stock_srd && res.get_stock_srd.length === 0) {
        this.messageService.add({ severity: 'error', detail: res && res.error ?  res.error : 'Brands/Products are not available' });
      }
      if (res && res.get_stock_srd) {
        this.productList = res['get_stock_srd'];
        this.brands = res['get_stock_srd'];
        this.products = res['get_stock_srd'].map(res => res.productCode);
        // if (this.data) {
        //   const productDetails: any = this.stockReturnDrainoutForm.get('productDetails') as FormArray;
        //   productDetails.controls.forEach((el, i) => {
        //     if (!this.filteredProducts[i]) {
        //       this.filteredProducts[i] = [];
        //     }
        //     this.filteredProducts[i] = [];
        //     // this.stockReturnDrainoutForm.get('productDetails')['controls'][i].controls.productCode.setValue('');
        //     const productData: any = this.productList.find(el => el.productCode === this.stockReturnDrainoutForm.get('productDetails')['controls'][i].controls.productCode.value);
        //     this.filteredProducts[i] = this.productList.filter(el => el.brandCode === this.stockReturnDrainoutForm.get('productDetails')['controls'][i].controls.brandCode.value);
        //     if (productData) {
        //       this.stockReturnDrainoutForm.get('productDetails')['controls'][i].controls.availableCases.setValue(productData.onlineClosingBalCases);
        //       this.stockReturnDrainoutForm.get('productDetails')['controls'][i].controls.availableBottles.setValue(productData.onlineClosingBalBottles);
        //     }
        //     this.disableBtn();
        //   });
        // } 
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }
  changeBrand(value, i): any {
    if (!this.filteredProducts[i]) {
      this.filteredProducts[i] = [];
    }
    this.filteredProducts[i] = [];
    // this.stockReturnDrainoutForm.controls.product
    this.stockReturnDrainoutForm.get('productDetails')['controls'][i].controls.productCode.setValue('');
    this.filteredProducts[i] = this.productList.filter(el => el.brandCode === value);
    if (this.filteredProducts[i] && this.filteredProducts[i].length === 1) {
      this.stockReturnDrainoutForm.get('productDetails')['controls'][i].controls.productCode.setValue(this.filteredProducts[i][0].productCode);
      this.stockReturnDrainoutForm.get('productDetails')['controls'][i].controls.size.setValue(this.filteredProducts[i][0].size);
      this.stockReturnDrainoutForm.get('productDetails')['controls'][i].controls.availableCases.setValue(this.filteredProducts[i][0].onlineClosingBalCases);
      this.stockReturnDrainoutForm.get('productDetails')['controls'][i].controls.availableBottles.setValue(this.filteredProducts[i][0].onlineClosingBalBottles);
    }
  }
  changeProduct(productCode, i): any {
    const productData: any = this.productList.find(el => el.productCode === productCode);
    if (productData) {
      this.stockReturnDrainoutForm.get('productDetails')['controls'][i].controls.size.setValue(productData.size);
      this.stockReturnDrainoutForm.get('productDetails')['controls'][i].controls.availableCases.setValue(productData.onlineClosingBalCases);
      this.stockReturnDrainoutForm.get('productDetails')['controls'][i].controls.availableBottles.setValue(productData.onlineClosingBalBottles);
    }
  }
  isDisable(brandCode): any {
    return this.stockReturnDrainoutForm.get('productDetails')['controls'].some(el => el.controls.brandCode.value === brandCode);
  }
  disableBtn(): any {
    const productDetails: any = this.stockReturnDrainoutForm.get('productDetails') as FormArray;
    let status: any = false;
    if (!this.data) {
      status = productDetails.controls.some(el => 
        (el.controls.requestedCases.value > el.controls.availableCases.value || el.controls.requestedBottles.value > el.controls.availableBottles.value)
        || (!el.controls.requestedCases.value && !el.controls.requestedBottles.value)
        );
    } else {
      // status = productDetails.controls.some(el => (el.controls.requestedCases.value && el.controls.approvedCases.value === 0 || el.controls.requestedBottles.value && el.controls.approvedBottles.value === 0) || el.controls.approvedCases.value > el.controls.requestedCases.value || el.controls.approvedBottles.value > el.controls.requestedBottles.value
      //  || ((el.controls.requestedCases.value && !el.controls.approvedCases.value) || (el.controls.requestedBottles.value && !el.controls.approvedBottles.value)));
      status = productDetails.controls.some(el => (el.controls.approvedCases.value > el.controls.requestedCases.value || el.controls.approvedBottles.value > el.controls.requestedBottles.value)
      || el.controls.requestedCases.value && (el.controls.approvedCases.value === 0 || !el.controls.approvedCases.value)
      || el.controls.requestedBottles.value && (el.controls.approvedBottles.value === 0 || !el.controls.approvedBottles.value)
      || !el.controls.requestedCases.value && (el.controls.approvedCases.value === 0 || el.controls.approvedCases.value)
      || !el.controls.requestedBottles.value && (el.controls.approvedBottles.value === 0 || el.controls.approvedBottles.value));
    }
    // console.log(status);
    if (status) {
      this.disable = true;
    } else {
      this.disable = false;
    }
    // console.log(this.stockReturnDrainoutForm);
    return this.disable;
  }

}
