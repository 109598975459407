import { CommonsService } from 'src/app/core/services/commons.service';
import { MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-otp-verification-dialog",
  templateUrl: "./otp-verification-dialog.component.html",
  styleUrls: ["./otp-verification-dialog.component.scss"],
})
export class OtpVerificationDialogComponent implements OnInit {
  otpForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<OtpVerificationDialogComponent>,private messageService:MessageService,private commonService:CommonsService
  ) {}

  ngOnInit(): void {
    this.otpForm = this.fb.group({
      otp: ["", [Validators.required, Validators.pattern(/^-?(0|[0-9]\d*)?$/),Validators.minLength(6)]],
    });
  }
  verifyOtp() {
    const verify_otp = {
      action: "verify_otp",
      data: { ...this.otpForm.value },
    };
    this.commonService.sendVerifyOtp(verify_otp).subscribe((res:any)=>{
    
      if(res['status']['status'] != "SUCCESS"){
        this.messageService.add({ severity: 'error', summary: 'Error Message', detail: 'Please enter valid OTP' });
      }else{
      
        this.dialogRef.close({
          message: res['status']['status'],
        });
      }
    })
  }
}
