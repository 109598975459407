import { MessageService } from 'primeng/api';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private messageService: MessageService
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const currentUser = window.sessionStorage.getItem('lappCurrentUserToken');
      this.getWithExpiry(state);
      if (currentUser) {
          // authorised so return true
          return true;
      }

      // not logged in so redirect to login page with the return url
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      return false;
  }
  async getWithExpiry(state): Promise<any> {
    const currentUserModules = sessionStorage.getItem('lappCurrentUserModules');
    if (!currentUserModules) {
      return null;
    }
    const item = JSON.parse(currentUserModules);
    const now = Math.floor(Date.now() / 1000);
    const expiry = parseInt(item.exp, 10);
    if (now > expiry) {
      this.messageService.add({ severity: 'error', detail: 'Session Timed out, Please login...' });
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      return false;
    }
    return true;
  }
}
