import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Procurement } from '../models/spirit-register/procurement.model';
import { environment } from 'src/environments/environment';
import { Receipts } from '../models/spirit-register/receipts.model';
import { RS } from '../models/spirit-register/rs.model';
import { Blend } from '../models/spirit-register/blend.model';
import { ENA } from '../models/spirit-register/ena.model';
import { Observable } from 'rxjs';

const baseUrl = environment.baseUrl;
const procurementUrl = baseUrl + 'spiritprocurement';
const receiptsUrl = baseUrl + 'spiritreceipts';
const rsUrl = baseUrl + 'spiritquanities';
const enaUrl = baseUrl + 'spiritquanities';
const blendUrl = baseUrl + 'spiritquanities';
const spiritRegisterUrl = baseUrl + 'spirit_register';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Spirit Register'] && userModules.mod_feats['Spirit Register'].features ? userModules.mod_feats['Spirit Register'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class SpiritRegisterService {

  constructor(private http: HttpClient) { }

  getProcurementDetails(offset, limit, search) {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    return this.http.get(procurementUrl);
  }
  createRmpIndent(reqData): Observable<any> {
    return this.http.post(spiritRegisterUrl, reqData);
  }
  getReceiptsDetails(offset, limit, search) {
    return this.http.get(receiptsUrl);
  }

  submitProcurement(req_Data) {
    return this.http.put(spiritRegisterUrl, req_Data);

  }

  getRSDetails(offset, limit, search) {
    return this.http.get('assets/samples/rs.json');
  }

  getENADetails(offset, limit, search) {
    return this.http.get('assets/samples/ena.json');
  }

  getBlendDetails(offset, limit, search) {
    return this.http.get('assets/samples/blend.json');
  }

  createProcurement(procurementObj: any) {
    return this.http.post(procurementUrl, procurementObj);
  }

  createReceipt(obj: Receipts) {
    return this.http.post(spiritRegisterUrl, obj);
  }

  createRS(obj: any) {
    return this.http.post(spiritRegisterUrl, obj);
  }

  createENAStock(obj: any) {
    return this.http.post(spiritRegisterUrl, obj);
  }

  createENABlending(obj: any) {
    return this.http.post(spiritRegisterUrl, obj);
  }

  createBlendStock(obj: any) {
    return this.http.post(spiritRegisterUrl, obj);
  }

  createBlendForBottling(obj: any) {
    return this.http.post(spiritRegisterUrl, obj);
  }

  getSpirirRegisterFeatures() {
    const headerDict = {
      feature
    };
    return this.http.get(spiritRegisterUrl, { headers: headerDict });
  }
}
