import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { spiritTypes } from 'src/app/core/constants/liquor-type.constants';
import { BrandRegistrationService } from 'src/app/core/services/brand-registration.service';
import { CommonsService } from 'src/app/core/services/commons.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-manage-brand-registration-dialog',
  templateUrl: './manage-brand-registration-dialog.component.html',
  styleUrls: ['./manage-brand-registration-dialog.component.scss']
})
export class ManageBrandRegistrationDialogComponent implements OnInit {
  addBrandForm: FormGroup;
  liquorTypeList: any = [];
  segmentList: any = [];
  liquorData: any = {};
  brandsData: any
  spiritTypeList: any = Object.keys(spiritTypes).map(res => spiritTypes[res]);
  selectedSpiritTypes: any;
  brandCodeDuplicate = false;
  brandNameDuplicate = false;
  strength: number;
  brandTypes: any = ['BEER', 'IML'];
  loader: any = false;


  constructor(private fb: FormBuilder, private messageService: MessageService,
    private commonsService: CommonsService,
    public util: UtilService,
    private spinner: NgxSpinnerService,
    private brService: BrandRegistrationService,
    private dialogRef: MatDialogRef<ManageBrandRegistrationDialogComponent>,) { }

  ngOnInit(): void {
    this.spinner.show();
    this.addBrandForm = this.fb.group({
      brandType: ['', Validators.required],
      brandName: ['', Validators.required],
      brandCode: ['', Validators.required]
    });

    const req_Data = {
      "action": "common_apis",
      "q": '{"data": ["get_liquor_types"]}'
    }
    this.commonsService.getCommonEntites(req_Data).subscribe(res => {
      this.liquorData = res["get_liquor_types"];
      this.liquorTypeList = Object.keys(res["get_liquor_types"]);
      this.segmentList = Object.keys(res["get_liquor_types"]).map(val => res["get_liquor_types"][val]);
    })

    this.brService.getBrandRegistrationFeatures().subscribe(res => {
      this.spinner.hide();
      this.brandsData = res['Brand Registration'];
      this.brandsData.forEach(brand => {
        brand.brandNumber = brand.brandCode.split('-')[0];
      })
      console.log('lllll:::::', this.brandsData);

    })

  }

  checkDuplicate(fieldName: string) {
    if (fieldName == 'code') {
      this.brandCodeDuplicate = this.brandsData.some(brand => brand.brandNumber === this.addBrandForm.get('brandCode').value);
    } else {
      this.brandNameDuplicate = this.brandsData.some(brand => brand.brandNumber === this.addBrandForm.get('brandName').value);
    }
  }

  // createSpiritTypeList() {
  //   return this.fb.group({
  //     spiritType: '',
  //     strength: ''
  //   });
  // }


  // addSpiritType() {
  //   (this.addBrandForm.controls['spiritTypesList'] as FormArray).push(this.createSpiritTypeList())
  // }


  // liquorTypeChange(e) {
  //   // console.log(e, 'LIQUORtYPE:::::')
  //   this.addBrandForm.patchValue({
  //     liquorCode: this.liquorData[e.value]
  //   })
  // }

  // segmentChange(e) {
  //   // console.log(e, 'segment:::::')

  //   this.liquorTypeList.forEach(val => {
  //     if (this.liquorData[val] == e.value) {
  //       this.addBrandForm.patchValue({
  //         liquorType: val
  //       })
  //     }
  //   });
  // }

  // spiritChange(e) {
  //   // console.log(e, 'spirit:::::')
  // }

  // showAddRow() {
  //   if (this.selectedSpiritTypes.length >= 1) {
  //     if(this.addBrandForm.get('spiritTypesList').value[this.selectedSpiritTypes.length - 1]['spiritType'].length > 0 && this.addBrandForm.get('spiritTypesList').value[this.selectedSpiritTypes.length - 1]['strength'] && this.addBrandForm.get('spiritTypesList').value[this.selectedSpiritTypes.length - 1]['strength'] != '') {
  //        return false;
  //     } else {
  //       return true;
  //     }
  //     // return (!this.addBrandForm.get('spiritTypesList').value[this.selectedSpiritTypes.length - 1]['spiritType'] && !this.addBrandForm.get('spiritTypesList').value[this.selectedSpiritTypes.length - 1]['strength'])
  //   }
  //   else {
  //     return false;
  //   }
  // }

  // deleteComposition(i) {
  //   (this.addBrandForm.controls['spiritTypesList'] as FormArray).removeAt(i);
  // }

  createBrand() {
    const req_Data = {
      action: "add_brand",
      data: this.addBrandForm.value
    }
    this.loader=true;
    this.brService.creteBrand(req_Data).subscribe(res => {
      if (res['status'] != "SUCCESS") {
        this.messageService.add({ severity: 'error', summary: 'Error Message', detail: res['status'] });
        this.loader=false;
      }
      else {
        this.dialogRef.close("Brand Created Successfully")
        this.loader=false;
      }
    })

  }
  
}
