import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CommonsService } from 'src/app/core/services/commons.service';
import { ThroughTransportPermitService } from 'src/app/core/services/through-transport-permit.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { MatDialog } from '@angular/material/dialog';
import { DynamicConfirmationDialogComponent } from 'src/app/core/components/dynamic-confirmation-dialog/dynamic-confirmation-dialog.component';
declare const JsBarcode: any;

@Component({
  selector: 'app-ttp-details-dialog',
  templateUrl: './ttp-details-dialog.component.html',
  styleUrls: ['./ttp-details-dialog.component.scss']
})
export class TtpDetailsDialogComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = [
    'intoxicantType',
    'brandName',
    'unitsPerCase',
    'size',
    'strength',
    'cases',
    'bulkLitres',
    'proofLitres'
  ];
  roleName: any = '';
  comments: any = '';
  commentsDialogRef: any;
  loader: any = false;
  s3Url: any = '';

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<TtpDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private ttpService: ThroughTransportPermitService,
    private messageService:MessageService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    public util: UtilService
  ) { }

  ngOnInit(): void {
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    this.s3Url = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).s3_url;
    this.totals();
  }
  ngAfterViewInit(): any {
    if (this.data && this.data.ttpNo) {
      JsBarcode('#barcode-ttpNo', this.data.ttpNo,
        {
          width: 1,
          height: 40
        }
      );
    }
  }
  dialogConfirm(mode, statusCode): any {
    const data: any = {};
    data.content = `${mode} ..?`;
    const dialogRef = this.dialog.open(DynamicConfirmationDialogComponent, {
      width: '25%',
      data:data,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result.message}`);
      if (result.message === 'Success') {
        this.reviewTTP(mode, statusCode);
      }
    });
  }
  reviewTTP(mode, statusCode): any {
    const reqPayload: any = {
      action: 'update_ttp',
      data: {
        status: statusCode,
        ttpNo: this.data.ttpNo
      }
    };
    // const status = this.data.status;
    // console.log('status', status);
    // reqPayload.data.status = mode === 'approve' ? 'Approved' : (mode === 'review' ? 'Reviewed' : (mode === 'reject' ? 'Rejected' : ''));
    this.spinner.show();
    this.ttpService.putMethod(reqPayload).subscribe((res: any) => {
      if (res.status === 'SUCCESS'){
        // this.messageService.add({ severity: 'success', summary: 'Success', detail: `TTP ${mode} Successfully` });
        this.dialogRef.close(`TTP ${mode} Successfully`);
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: `TTP ${mode} failed` });
      }
      this.spinner.hide();
    }, err => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong' });
      this.spinner.hide();
    });
    
  }
  downloadFile(file): any {
    var a = document.createElement('a');
    a.href = 'data:application/pdf;' + file.source;
    a.download = `${file.name}`;
    a.click();
  }
  isBase64(str): any {
    let status = false;
    if (str) {
      const isStatus = str.match('base64,');
      if (isStatus !== null) {
        status = true;
      }
    }
    return status;
  }
  totals(): any {
    this.data.cases = 0;
    this.data.bulkLitres = 0;
    this.data.proofLitres = 0;
    this.data.brandDetails.forEach(el => {
      this.data.cases += el.cases;
      this.data.bulkLitres += el.bulkLitres;
      this.data.proofLitres += el.proofLitres;
    });
  }
  print(): any {
    setTimeout(() => {
      let printContents;
      let popupWin;
      printContents = document.getElementById('print-permit-for-through-transport').innerHTML;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.write(`${printContents}`);
      popupWin.document.close();
    }, 500);
  }
  addComments(): any {
    console.log('addComments', this.comments);
    if (this.comments) {
      const req_Data = {
        action: 'update_ttp',
        data: {
          comments: this.comments,
          ttpNo: this.data.ttpNo,
          status: this.data.status,
          partialUpdate: true,
        }
      }
      console.log(req_Data)
      this.loader = true;
      this.spinner.show();
      this.ttpService.putMethod(req_Data).subscribe((res: any) => {
        if (res && res.status === 'SUCCESS') {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Comments added successfully' });
          if (res.ttp_data) {
            this.data = res.ttp_data;
          }
          this.comments = '';
          this.closeCommentsDialog();
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'TTP Updation Failed' });
        }
        this.loader = false;
        this.spinner.hide();
      }, (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server Error' });
        this.closeCommentsDialog();
        this.loader = false;
        this.spinner.hide();
      });
    }
  }
  openCommentsDialog(templateRef): any {
    this.commentsDialogRef = this.dialog.open(templateRef, {
      width:'50%',
      disableClose: true
    });    
  }
  closeCommentsDialog(): any {
    this.comments = '';
    this.commentsDialogRef.close();
  }
  toFixed(val): any {
    return val ? val.toFixed(2) : '';
  }

}
