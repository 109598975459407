import { CommonsService } from './../../../core/services/commons.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormArray, FormGroup } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { take, takeUntil } from 'rxjs/operators';
import { InterDepotService } from 'src/app/core/services/inter-depot.service';
import { MessageService } from 'primeng/api';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-inder-depot-transfer-distillery',
  templateUrl: './inder-depot-transfer-distillery.component.html',
  styleUrls: ['./inder-depot-transfer-distillery.component.scss']
})
export class InderDepotTransferDistilleryComponent implements OnInit {
  public _onDestroy = new Subject<void>();
  interDepotTransferDistilleryForm:FormGroup;
  productDetails:FormArray;
  consignmentTypes=['Inter Depot Transfer'];
  productData:any = [];
  depots = [];
  filteredData: any[];
  filteredDepots: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  selectedSourceDepotName:any;
  selectedSDestinationDepotName:any;
  public productFitlerCtrl: FormControl = new FormControl();
  @Output() refreshPage = new EventEmitter<boolean>();
  loader: any = false;

  constructor(private fb: FormBuilder,private commonsService:CommonsService, private interDepotService:InterDepotService,private messageService:MessageService, private dialogRef: MatDialogRef<InderDepotTransferDistilleryComponent>) { }

  ngOnInit(): void {
    this.interDepotTransferDistilleryForm = this.fb.group({
      consignmentType: ['',Validators.required],
      sourceDepotName:['',Validators.required],
      destinationDepotName:['',Validators.required],
      productDetails: this.fb.array([this.updateProductsList()]),
    });
    this.productDetails = this.interDepotTransferDistilleryForm.get('productDetails') as FormArray;
    this.getDepotsData();
    this.productFitlerCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterProducts();
    });
  }
  addProduct(){
    const control = <FormArray>this.interDepotTransferDistilleryForm.controls["productDetails"];
    control.push(this.updateProductsList());
  }
  updateProductsList() {
    return this.fb.group({
      productCode: [''],
      brandName: [''],
      size: [''],
      availableCases:[''],
      requestedCases:['']
    });
  }
  removeProduct(i:number){
    const control = <FormArray>this.interDepotTransferDistilleryForm.controls["productDetails"];
    control.removeAt(i);
  }
  getDepotsData(){
    let paramObj = {
      action: 'common_apis',
      q: '{"data": ["get_depots"]}'
    }
    this.commonsService.getCommonEntites(paramObj).subscribe((res) => {
      this.depots = res['get_depots']
      this.filteredDepots.next(this.depots);
    });
  }
  filterProducts() {
    if (!this.depots || (this.depots && this.depots.length == 0)) {
      return;
    }
    // get the search keyword
    let search = this.productFitlerCtrl.value;
    if (!search) {
      this.filteredDepots.next(this.depots);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredDepots.next(
      this.depots.filter(product => JSON.stringify(product).toLowerCase().indexOf(search) > -1)
    );
  }
  selectedSourceDepot(event:any){
    this.selectedSourceDepotName = event.value;
    let qData = {
      "data": { "value": event.value, "action": "get_depot_stock_supplier" }
    };
    const req_Data = {
      "action": "common_apis",
      "q": JSON.stringify(qData)
    };
    
    this.commonsService.getCommonEntites(req_Data).subscribe((res) => {
      this.productData = res['get_depot_stock_supplier'];
    });

  }

  getSelectedDestinationDepot(event:any){
    this.selectedSDestinationDepotName = event.value;
  }

  brandCodeChange(event:any){

  }
  productCodeChange(event,i){    
    const productDetails = (<FormArray>this.interDepotTransferDistilleryForm.get("productDetails")).at(i);
    let [bindData] = this.productData.filter(data => {
      return data.productCode == event.value;
    })

    productDetails.patchValue({
      size: bindData.size,
      brandName:bindData.brandName,
      availableCases:bindData.onlineClosingBalCases

    })
    
  }
  save(){
    this.loader = true;
    const data ={
      consignmentType:this.interDepotTransferDistilleryForm.controls['consignmentType'].value,
      sourceDepotName:this.interDepotTransferDistilleryForm.controls['sourceDepotName'].value,
      destinationDepotName:this.interDepotTransferDistilleryForm.controls['destinationDepotName'].value,
      productDetails:this.productDetails.value

    }
    this.productDetails.value.forEach((item,i)=>{
      delete item.availableCases;
    })
    const reqData = {
      action:"add_idt",
      data: data
    }
    this.interDepotService.createIDT(reqData).subscribe((res:any)=>{
      if(res.status == 'SUCCESS'){
        this.dialogRef.close('Data added successfully');
      }
      this.loader = false;
    }, (err) => {
      this.loader = false;
    });
    

  }
  qtyCheck(){
    const control = <FormArray>this.interDepotTransferDistilleryForm.controls["productDetails"];
    return control.value.some(el=>el.requestedCases > el.availableCases);
  }
  checkDuplicate(productCode){
    const control = <FormArray>this.interDepotTransferDistilleryForm.controls["productDetails"];
      return control.value.some(el=>el.productCode === productCode);
  }
  dialogClose(): void{
    this.dialogRef.close();
  }
}
