import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-closing-stock-details-dialog',
  templateUrl: './closing-stock-details-dialog.component.html',
  styleUrls: ['./closing-stock-details-dialog.component.scss']
})
export class ClosingStockDetailsDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ClosingStockDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA)public data) { }

  ngOnInit(): void {
  }

  dialogClose(): void{
    this.dialogRef.close();
  }

}
