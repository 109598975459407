import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { LocationsService } from 'src/app/core/services/locations.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { ManageLocationDialogComponent } from '../location/manage-location-dialog/manage-location-dialog.component';

@Component({
  selector: 'app-manage-location-csd-dialog',
  templateUrl: './manage-location-csd-dialog.component.html',
  styleUrls: ['./manage-location-csd-dialog.component.scss']
})
export class ManageLocationCsdDialogComponent implements OnInit {
  csdForm: FormGroup;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageLocationDialogComponent>,
    private messageService: MessageService, public util: UtilService, @Inject(MAT_DIALOG_DATA) public data,
   private locationsService: LocationsService) { }

  ngOnInit(): void {
    this.csdForm = this.fb.group({
      csdCode: ['', [Validators.required, Validators.min(10)]],
      csdName: ['', Validators.required],
      address: ['', Validators.required],
      pincode: ['', Validators.required],
      town: ['', Validators.required],
      state: ['Andhra Pradesh', Validators.required],
      phoneNumber: ['', Validators.compose([Validators.required, Validators.pattern(this.util.phoneNumberValidation())])],
    });
    // if (this.data) {
    //   this.csdForm.patchValue({
    //     csdCode: this.data.csdCode.split('-')[0],
    //     csdName: this.data.csdName,
    //     address: this.data.address ? this.data.address.address : '',
    //     pincode: this.data.address ? this.data.address.pincode : '',
    //     town: this.data.address ? this.data.address['town/village'] : '',
    //     state: this.data.address ? this.data.address.state : '',
    //     phoneNumber: this.data.address && this.data.address.phoneNumbers ? this.data.address.phoneNumbers[0].number : '',
    //   });      
    // }
  }

  saveDepot() {
    if (this.data) {
      const obj = {
        action: 'update_csd',
        data: this.csdForm.value
      };
      this.locationsService.putDepot(obj).subscribe((res: any) => {
        if (res.status && res.status !="SUCCESS") {
          this.dialogRef.disableClose = true;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: res.status});
        }
        else {
          this.dialogRef.close('Updated Successfully');
        }
      }, (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: err.error.message});
      });
    }
    else {
      const obj = {
        action: 'add_csd',
        data: this.csdForm.value
      };

      this.locationsService.postDepot(obj).subscribe((res: any) => {
        if (res.status && res.status !="SUCCESS") {
          this.dialogRef.disableClose = true;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: res.status});
        }
        else {
          this.dialogRef.close('Added Successfully');
        }
      }, (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: err.error.message});
      });
    }

  }

  dialogClose(): void {
    this.dialogRef.close();
  }

}