import { GroupRationing } from './../models/rationing/group-rationing.model';
import { Rationing } from './../models/rationing/rationing.model';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const baseUrl = environment.baseUrl;
const rationingUrl = baseUrl + 'rationing';
const actionUrl = baseUrl + 'rationing';
const groupRationingUrl = baseUrl + 'group-rationing';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Rationing'] && userModules.mod_feats['Rationing'].features ? userModules.mod_feats['Rationing'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class RationingService {

  currentUser: any;

  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(
      sessionStorage.getItem('lappCurrentUserDetails')
    );
  }

  getRationingDetails(offset,limit,search) {
    return this.http.get('assets/samples/rationing.json');
  }


  createAction(obj: any){
    return this.http.post(actionUrl, obj);
  }
  createRationing(obj: any){
    return this.http.post(rationingUrl, obj);
  }

  updateRationing(obj: any){
    return this.http.put(rationingUrl, obj);
  }

  getGroupRationingDetails(offset,limit,search) {
    return this.http.get('assets/samples/group-rationing.json');
  }

  createGroupRationing(obj: GroupRationing){
    return this.http.post(groupRationingUrl, obj);
  }

  getRationingFeatures() {
    const headerDict = {
      feature
    };
    return this.http.get(rationingUrl, { headers: headerDict });
  }

  getProducts() {
    const paramObj = {q: JSON.stringify({
      data: { getProducts: true }
    }), action: 'get_rationing'};
    return this.http.get(rationingUrl, {params: paramObj});

  }

}
