import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CSDLocation } from '../models/csd/csd-location.model';
import { CSD } from '../models/csd/csd.model';

const baseUrl = environment.baseUrl;
const csdLocationUrl = baseUrl + 'csd-location';
const csdUrl = baseUrl + 'csd';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['CSD'] && userModules.mod_feats['CSD'].features ? userModules.mod_feats['CSD'].features.map(feat => feat.feature_name) : [];


@Injectable({
  providedIn: 'root'
})
export class CsdService {

  constructor(private http: HttpClient) { }
  getCsdDetails(offset,limit,search) {
    return this.http.get('assets/samples/csd.json');
  }

  getCsdLocationDetails(offset,limit,search) {
    return this.http.get('assets/samples/csd-locations.json');
  }

  createCsdIndent(obj: any){
    return this.http.post(csdUrl, obj);
  }

  createCSDLocation(obj: any){
    return this.http.post(csdUrl, obj);
  }

  createCSD(obj: any){
    return this.http.post(csdUrl, obj);
  }

  updateCsdIndents(obj: any){
    return this.http.put(csdUrl, obj);
  }

  getCSDFeatures() {
    const headerDict = {
      feature
    };
    return this.http.get(csdUrl, { headers: headerDict });
  }

}
