import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthService } from '../../services/auth.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { MatDialog } from '@angular/material/dialog';
import { NewUpdatesDialogComponent } from '../new-updates-dialog/new-updates-dialog.component';
import { CommonsService } from '../../services/commons.service';
import { NgxSpinnerService } from 'ngx-spinner';
const parsedUrl = new URL(window.location.href);
const baseUrl = parsedUrl.origin;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user: any;
  items: MenuItem[];
  userName: any = '';
  lastLogin: any = '';
  lastLoginDet: any = { day: '', hours: '', minutes: ''};
  newUpdates: any;
  isTime:any=false;
  showLoader: any = false;
  isDayTool:any=false;

  @ViewChild('dayTool', { static: true }) dayTool: TemplateRef<any>;


  constructor(
    private authService: AuthService,
    private router: Router,
    private util: UtilService,
    private dialog: MatDialog,
    private messageService: MessageService, private CommonsService: CommonsService,
    private loadSpinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
    // this.showUpdates();
    this.userName = this.util.getUserName();
    if (this.user && this.user.lastLoginTime) {
      const lastLogin = this.user.lastLoginTime.loginTime;
      if (lastLogin) {
        this.lastLogin = new Date(lastLogin * 1000);
        this.getLastLoginDetail();
      }
    }
    this.items = [{

    }]
    const currentTime = new Date();
    const dayToolValidTime = new Date().setHours(16,0,0,0); // set the time to 4 PM
    if (currentTime.getTime() > dayToolValidTime) {
      this.isTime =true;
    } else {
      this.isTime = false;
    }
    baseUrl && baseUrl === 'https://apsbcltd.ap.gov.in' ?  this.isDayTool = true :  this.isDayTool = false;
  }
  getLastLoginDetail(): any {
    const startTime: any = new Date(this.lastLogin);
    const endTime: any = new Date()
    const difference = endTime - startTime;
    const differenceInMinutes = difference / 1000 / 60;
    let hours = Math.floor(differenceInMinutes / 60);
    if (hours < 0) {
      hours = 24 + hours;
    }
    let minutes = Math.floor(differenceInMinutes % 60);
    if (minutes < 0) {
      minutes = 60 + minutes;
    }
    let day = 0;
    if (hours>24){
      day = (hours / 24);
      hours = (hours % 24);
    }else{
      hours = (hours);
    }
    this.lastLoginDet = {day: Math.floor(day), hours: hours, minutes: minutes};
  }

  onLogout() {
    sessionStorage.removeItem('lappCurrentUserDetails');
    sessionStorage.removeItem('lappCurrentUserModules');
    this.router.navigate(['/login']);
    // this.authService.onLogout().subscribe(res => {
    //   sessionStorage.removeItem('lappCurrentUserDetails');
    //   this.router.navigate(['/login']);
    // });
  }
  showUpdates(): any {
    if (this.user.version_history && this.user.version_history) {
      const dialogRef = this.dialog.open(NewUpdatesDialogComponent, {
        width: '50%',
        autoFocus: false,
        disableClose: true,
        data: this.user.version_history
      });
      dialogRef.afterClosed().subscribe((result) => {
        // this.router.navigate(['/home']);
      });
      return;
    }
  }
  openConfirmation(){
      this.dialog.open(this.dayTool,{
        width:'35%'
      });
  }
  checkStock(){
    let paramObj = {
      action: 'common_apis',
      q: '{"data": ["stock_day_close"]}'
    }
    this.loadSpinner.show();
    this.showLoader = true;
    this.CommonsService.getCommonEntites(paramObj).subscribe((res) => {
      if(res.status === 'SUCCESS'){
        this.dialog.closeAll();
        this.loadSpinner.hide();
        this.showLoader = false;
        this.messageService.add({ severity: 'success',summary: 'Success', detail: 'Day Tool Running Completed' });
      }else{
        this.loadSpinner.hide();
        this.showLoader = false;
        this.messageService.add({ severity: 'error',summary: 'Error', detail: 'Day Tool Running Failed' });
      }
    }, err => {
      console.log(err);
      this.showLoader = false;
      this.messageService.add({ severity: 'error', detail : 'Day Tool Running Failed' });
      this.loadSpinner.hide();
    }); 
  }
}
