import { CommonsService } from 'src/app/core/services/commons.service';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Get_Order, Order } from 'src/app/core/models/inter-depot/orders.model';
import { InterDepotService } from 'src/app/core/services/inter-depot.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
declare const JsBarcode: any;
@Component({
  selector: 'app-orders-details-dialog',
  templateUrl: './orders-details-dialog.component.html',
  styleUrls: ['./orders-details-dialog.component.scss']
})
export class OrdersDetailsDialogComponent implements OnInit {

  displayedColumns: string[] = ['indentNo', 'productCode', 'brandName', 'sizeInMl', 'indentQty','remainingQty','availStockCases','availStockPart','allocateQtyCases','allocateQtyBtls'];
  displayedColumns1: string[] = ['indentNo', 'productCode', 'brandName', 'sizeInMl', 'indentQty'];

  dataSource: MatTableDataSource<Order> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;
  ordersData:any = [];
  filteredData:any=[];
  products:any = [];
  userDetails: any = [];
  distilleryName: any = [];
  distilleryAddress: any = '';
  isError:Boolean;
  isApprovedQty:Boolean;
  isErrorText='Allocate Quantity should not be morethan Order Quantity';
  approvedQtyError='Quantity should not be morethan approved Quantity'
  validityDate:any;
  totalDatas:any=[];
 
  constructor(public dialogRef: MatDialogRef<OrdersDetailsDialogComponent>,
    private orderService: InterDepotService, @Inject(MAT_DIALOG_DATA) public data,private commonService:CommonsService,public utilService: UtilService,
    private messageService: MessageService, private spinner: NgxSpinnerService) { 

      this.userDetails = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
      
      if (this.userDetails.distillery_unit && this.userDetails.distillery_address) {
        this.distilleryName = this.utilService.formatString(this.userDetails.distillery_unit);
        this.distilleryAddress = this.userDetails.distillery_address[0];
      } else if (this.data.distilleryAddress) {
        this.distilleryAddress = this.data.distilleryAddress[0];
      }
    }


  ngOnInit(): void {
    this.getOrderDetails();
    this.getProducts();
  
    this.data.productDetails.forEach(element => {
      element['allocateQtyCases'] = element.allocateQtyCases ? element.allocateQtyCases : element.requestedCases;
      element['allocateQtyBtls'] = element.allocateQtyBtls ? element.allocateQtyBtls : 0;     
    });
    console.log(this.data)
    var currentDate = new Date(this.data.createdOn);
     currentDate.setDate(currentDate.getDate() + 12);
     this.validityDate = currentDate.toLocaleDateString()
  }
  isDisabled(){
    let status:any = true;
     this.data.productDetails.some(item => {
      if(parseInt(item.allocateQtyCases) >= 0 && parseInt(item.allocateQtyBtls) >= 0 ){
        status = false;
        return
      }
    })    
    return status
  }
  checkQty(item:any,e,status){
    const enteredQty = parseInt(e.target.value)
    // if(enteredQty === 0){
    //   this.isApprovedQty = true;
    //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Quantity should not be 0'});
    // }
    // item.allocateQtyCases > item.requestedCases ? this.isError = true : this.isError = false;
    if(status === 'Approved'){
      this.data.productDetails.forEach((data:any)=>{
        if(data.productCode === item.productCode){
          data.updatedApprovedQty = enteredQty;
        }
      })
      if(enteredQty > item.approvedCases){
        this.isApprovedQty = true;
      }else{
        this.isApprovedQty = false;
      }
     
    }
    if(status === 'Requested'){
      this.data.productDetails.forEach((data:any)=>{
        if(data.productCode === item.productCode){
          data.updatedRequestedQty = enteredQty;
        }
      })
      if(enteredQty > item.requestedCases){
        this.isApprovedQty = true;
      }else{
        this.isApprovedQty = false;
      }
     
    }

  }
  getProducts(){
    let qData = {
      "data": ["get_depot_stock"]
    }
    const req_Data = {
      "action": "common_apis",
      "q": JSON.stringify(qData)
    };

    this.commonService.getCommonEntites(req_Data).subscribe((res) => { 
      this.products = res['get_depot_stock'];
      this.getOrderDetails();
    })
  }

  getOrderDetails() {
    let productData:any;
    this.products.map((item:any,i)=>{
      this.data.productDetails.map((productDetails:any,j)=>{
        if(item.productCode === productDetails.productCode){
        
          this.data.productDetails[j].closingBalBottles= item.closingBalBottles;
          this.data.productDetails[j].closingBalCases= item.closingBalCases;
          this.data.productDetails[j].onlineClosingBalCases= item.onlineClosingBalCases;
          this.data.productDetails[j].onlineClosingBalBottles= item.onlineClosingBalBottles;
        }
      })
    })
    
    
      this.dataSource = new MatTableDataSource(this.data.productDetails);
  }
  saveOrder(){
    delete this.data['colName'];
    let prodData = this.data;
    
    prodData.productDetails.forEach((res,i) => {
      res.approvedQty = parseInt(res.allocateQtyCases)
      delete res.allocateQtyCases;
      delete res.allocateQtyBtls;
      delete res.closingBalBottles;
      delete res.closingBalCases;
      delete res.onlineClosingBalBottles;
      delete res.onlineClosingBalCases;
    
    })
    const req_Data ={
      action:"update_ofidt",
      data:prodData
    }
  
    this.orderService.updateIDT(req_Data).subscribe((res:any)=>{
      if(res.status == 'SUCCESS'){
        this.dialogRef.close('Order updated successfully');
      }
    
      
    })

  }
  dialogClose(): void {
    this.dialogRef.close();
  }
  cancelOfidt(){
    delete this.data['colName'];
    this.data["status"] = 'Cancelled'
    const req_data = {
      action:'cancel_ofidt',
      data:this.data
    }
    console.log(req_data);
    this.orderService.updateIDT(req_data).subscribe((res:any)=>{
      if(res.status == 'SUCCESS'){
        this.dialogRef.close('OFIDT Cancelled successfully');
      }
    })
    
  }

  print(id,data){
    console.log(data)
    // this.selectedOfsForPrint = ofsItem;
    this.totalDatas = [];
    this.totalDatas.totalCases = 0;
    this.totalDatas.totalBtls = 0;
    data.productDetails.map(row => {
      this.totalDatas.totalCases += data.status === 'Approved' ? row.approvedCases : row.requestedCases;
      this.totalDatas.totalBtls += data.status === 'Approved' ? row.approvedBtls : row.requestedBtls;

    });

    setTimeout(() => {
      this.getPrint(id);
    }, 100);
  }
  getPrint(id){
    let printContents, popupWin;
    printContents = document.getElementById(id).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.write(`${printContents}`);
    popupWin.document.close();
  }

  ngAfterViewInit(): any {
    JsBarcode('#ofidtBarCode', this.data.ofidtNo,
      {
        width: 1,
        height: 40
      }
    );
    JsBarcode('#barcode', this.data.indentId,
    {
      width: 1,
      height: 40
    }
  );
    JsBarcode('#barcode-test', '2174XXXXXXXXXEE',
      {
        width: 1,
        height: 40
      }
    );
  }

  saveUpdatedQty(){
    console.log(this.data)
    this.data.productDetails.forEach((prod:any)=>{
      if(prod.updatedApprovedQty){
        prod.preApprovedCases = prod.approvedCases;
        prod.approvedCases = prod.updatedApprovedQty;
      }else if(prod.updatedRequestedQty){
        prod.preRequestedCases = prod.approvedCases;
        prod.approvedBtls = prod.updatedRequestedQty
      }
    })
    const req_Data:any={
      action:this.data.indent === 'Ctel' ? 'update_manual_ofidt_qty' : 'update_ofidt_qty',
      data:this.data
    }
    console.log(req_Data)
    this.spinner.show()
    this.orderService.updateIDT(req_Data).subscribe((res:any)=>{
      if(res.status == 'SUCCESS'){
        this.dialogRef.close('Order updated successfully');
        this.spinner.hide()
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Updation Failed'});
        this.spinner.hide()
      }
    },(err)=>{
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Updation Failed'});
      this.spinner.hide()

    })
  }
}
