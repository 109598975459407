import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Get_SupplyIndents, SupplyIndents } from '../models/supply-delivery/supply-indents';
import { Ofs } from '../models/supply-delivery/ofs';
import { TransportPermit } from '../models/supply-delivery/transport-permit';

const baseUrl = environment.baseUrl;

const supplyDeliveryUrl = baseUrl + 'SupplyDelivery';
const supplyIndentsUrl = baseUrl + 'indentmanagement/distillerysupplyindents';
const ifsUrl = baseUrl + 'supplyindents';
const ofsUrl = baseUrl + 'orderforsupply';
const transportPermitUrl = baseUrl + 'shipments/transportpermits';
const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['SupplyDelivery'] && userModules.mod_feats['SupplyDelivery'].features ? userModules.mod_feats['SupplyDelivery'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class SupplyDeliveryService {

  constructor(private http: HttpClient) { }

  createSupplyIndents(supplyIndentsObj){
    return this.http.post(supplyDeliveryUrl, supplyIndentsObj)
  }

  getSupplyIndents(offset, limit, search?: string):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    return this.http.get(supplyIndentsUrl, {params: paramObj});
  }

  putSupplyIndents(supplyIndentObj){
    return this.http.put<SupplyIndents>(`${supplyIndentsUrl}`, supplyIndentObj)
  }

  createOfs(ofsObj){
    return this.http.post(ofsUrl, ofsObj)
  }

  getOfs(offset, limit, search?: string):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    return this.http.get(ofsUrl, {params: paramObj});
  }

  putOfs(ofsObj){
    return this.http.put<Ofs>(`${ofsUrl}`, ofsObj);
  }

  createTransportPermit(transportPermitObj){
    return this.http.post(transportPermitUrl, transportPermitObj);
  }

  getTransportPermit(offset, limit, search?: string, incoming ?: boolean): Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    incoming ? paramObj.incoming = incoming : incoming = null;
    return this.http.get(transportPermitUrl, {params: paramObj});
  }

  putTransportPermit(transportPermitObj){
    return this.http.put<TransportPermit>(`${transportPermitUrl}`, transportPermitObj);
  }

  getOfsDetails(offset, limit, search){
    return this.http.get(ofsUrl);
  }

  getIfsDetails(offset, limit, search){
    return this.http.get(ifsUrl);
  }

  getSupplyDeliveryFeature(){
    const headerDict = {
      feature
    };
    return this.http.get(supplyDeliveryUrl, { headers: headerDict });
  }

}
