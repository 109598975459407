import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImportService } from 'src/app/core/services/import.service';

@Component({
  selector: 'app-manage-shipment-dialog',
  templateUrl: './manage-shipment-dialog.component.html',
  styleUrls: ['./manage-shipment-dialog.component.scss']
})
export class ManageShipmentDialogComponent implements OnInit {

  shipmentImportPermitForm: FormGroup;
  error: string;
  saveDisabled: boolean;
  batchInfo: FormArray;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageShipmentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private importService: ImportService) { }

  ngOnInit(): void {
    this.shipmentImportPermitForm = this.fb.group({
      tpBarcode: ['', [Validators.required]],
      supplierName: ['', [Validators.required]],
      capacity: ['', [Validators.required]],
      depot: ['', [Validators.required]],
      batchInfo: this.fb.array([this.editBatchInfo()]),
    });

    if (this.data && this.data._id) {
      this.shipmentImportPermitForm.patchValue({
        tpBarcode : this.data.tpBarcode,
        supplierName: this.data.supplierName,
        capacity: this.data.capacity,
        depot: this.data.depot,
      });
      const batchInfo = this.shipmentImportPermitForm.get('batchInfo') as FormArray;
      batchInfo.clear();
      this.data.batchInfo.forEach(b => {
        batchInfo.push(
          this.fb.group({
            productCode: b.productCode,
            quantity: b.quantity,
            batchNumber: b.batchNumber
          })
        )
      });
    }
    this.batchInfo = this.shipmentImportPermitForm.get('batchInfo') as FormArray


    this.shipmentImportPermitForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
  }
  editBatchInfo(){
    return this.fb.group({
      productCode: ['', Validators.required],
      quantity: ['', Validators.required],
      batchNumber: ['', Validators.required]
    });
  }

  get f(){
    return this.shipmentImportPermitForm.controls;
  }


  updateShipmentImportPermit() {
    this.saveDisabled = true;
    const obj = [{
      _id: this.data._id,
      update: this.shipmentImportPermitForm.value

    }];
    this.importService.putShipment(obj).subscribe((res: any) => {
      console.log(res);
      this.dialogRef.close('Import Permit Updated Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

}
