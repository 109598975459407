import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { IndentManagementService } from '../../services/indent-management.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  loader: any = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data,private dialogRef: MatDialogRef<ConfirmationDialogComponent>,private messageService:MessageService,private indentManagementService: IndentManagementService) { }

  ngOnInit(): void {
    console.log(this.data)
  }
  confirm(){
    console.log(this.data);
    const reqData :any={
      action:'create_icdc',
      data: {
        "indentId" :this.data.indentId,
        'indent': this.data.indent
      }
    }
    console.log(reqData)
    this.loader = true;
    this.indentManagementService.createRetailerIndent(reqData).subscribe((res:any)=>{
      console.log(res);
      if(res.status ==='SUCCESS'){
        this.loader = false;
        this.dialogRef.close({
          message: 'Success',
        });
       }else if(res.error){
        this.loader = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'ICDC Not added' });

      }
    })
  }

}
