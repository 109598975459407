import { UtilService } from './../../../../core/utils/utility-helper';
import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { OtpVerificationDialogComponent } from 'src/app/core/components/otp-verification-dialog/otp-verification-dialog.component';
import { CommonsService } from 'src/app/core/services/commons.service';
import { DepotInfoService } from 'src/app/core/services/depot-info.service';
import { IndentManagementService } from 'src/app/core/services/indent-management.service';
import { ProductionService } from 'src/app/core/services/production.service';
import { RetailersService } from 'src/app/core/services/retailers.service';
declare const JsBarcode: any;
@Component({
  templateUrl: './licensee-status-dialog.component.html',
  styleUrls: ['./licensee-status-dialog.component.scss']
})
export class LicenseeStatusDialogComponent implements OnInit, AfterViewInit {
  userInfo: any;
  error: string;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  displayedColumns = ['no', 'productCode', 'casesCount', 'approvedCasesCount'];
  imlBeerCount: any = {};
  totalDatas: any = {};
  loader: any = false;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<LicenseeStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private depotService: DepotInfoService,
    private productionService: ProductionService, private indentManagementService: IndentManagementService, private commonService: CommonsService, private spinner: NgxSpinnerService,private dialog: MatDialog,
    private retailerService: RetailersService,private commponService:CommonsService,
    public util: UtilService) {
    this.userInfo = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'))
  }

  ngOnInit(): void {
    this.data.productDetails.map(product => {
      delete product['approvedQty']
      if (product.requestedBottles && product.approvedBottles == undefined) {
        product.approvedBottles = product.requestedBottles
      }
      else if(product.approvedCases == undefined){
        product.approvedCases = product.requestedCases
      }

    });
    console.log(this.data);
    this.dataSource = new MatTableDataSource(this.data.productDetails);
    this.getImlBeerCount();

  }
  ngAfterViewInit(): any {
    if (this.data && this.data.indentId) {
      JsBarcode('#barcode', this.data.indentId,
        {
          width: 1,
          height: 40
        }
      );
    }
  }

  changeQuantity(i, e) {
    if (this.data.productDetails[i].requestedBottles) {
      this.data.productDetails[i].approvedBottles = parseInt(e.target.value);
    }
    else {
      this.data.productDetails[i].approvedCases = parseInt(e.target.value);
    }
    console.log('after', this.data.productDetails[i], e.target.value);
  }

  quantityCheck(i) {
    if (this.data.productDetails[i]['requestedCases']) {
      return this.data.productDetails[i]['requestedCases'] < this.data.productDetails[i].approvedCases || this.data.productDetails[i].approvedCases < 1;
    } else if (this.data.productDetails[i]['requestedBottles']) {
      return this.data.productDetails[i]['requestedBottles'] < this.data.productDetails[i].approvedBottles || this.data.productDetails[i].approvedBottles < 1;
    }
  }
  
  checkForError(){
    const outofRangeData = this.data.productDetails.map((product,i) =>{
      return this.quantityCheck(i);
    });

    return outofRangeData.some(item => item === true);
  }

  generateICDC(status) {
    if(status == 'Approved'){
      // const req_otp ={
      //   action: "send_otp",
      //   data:{
      //    ...this.data
      //   }
      // }
      
      // this.commponService.sendVerifyOtp(req_otp).subscribe((res:any)=>{
        
        
      // })
      // const dialogRef = this.dialog.open(OtpVerificationDialogComponent, {
      //   width: '25%',
      //   height:'45%',
      //   autoFocus: false
      // });
  
      // dialogRef.afterClosed().subscribe((result) => {
      //   if(result.message == 'SUCCESS'){
            /*  const prodDetails = this.data.productDetails.map((prod)=> {
         prod.approvedCases = parseInt(prod.approvedQty);
         // prod.casesCount = parseInt(prod.requestedCases)
         return prod;
       }); */
      const obj = {
        action: 'generate_icdc',
        data: {
          indentId: this.data.indentId,
          productDetails: this.data.productDetails,
          status
        }
      }
      console.log(obj, "71");
      this.loader = true;
      this.indentManagementService.updateIndents(obj).subscribe(res => {
        this.loader = false;
        console.log('res', res);
        this.dialogRef.close('Generated ICDC Successfully');
      })
      //   }
      // });
    }else{
              /*  const prodDetails = this.data.productDetails.map((prod)=> {
         prod.approvedCases = parseInt(prod.approvedQty);
         // prod.casesCount = parseInt(prod.requestedCases)
         return prod;
       }); */
       const obj = {
        action: 'generate_icdc',
        data: {
          indentId: this.data.indentId,
          productDetails: this.data.productDetails,
          status
        }
      }
      console.log(obj, "71");
      this.loader = true;
      this.indentManagementService.updateIndents(obj).subscribe(res => {
        console.log('res', res);
        this.loader = false;
        this.dialogRef.close('Generated ICDC Successfully');
      })
    }


  }
  print(): any {
    setTimeout(() => {
      let printContents;
      let popupWin;
      printContents = document.getElementById('print-retailer-proforma-indent').innerHTML;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.write(`${printContents}`);
      popupWin.document.close();
    }, 500);
  }
  getImlBeerCount(): any {
    this.imlBeerCount.beer = 0;
    this.imlBeerCount.iml = 0;
    this.totalDatas = 0;
    if (this.data && this.data.productDetails.length) {
      this.data.productDetails.map((res: any) => {
        const productKeys = this.util.productCodeToDetails(res.productCode);
        if(res.approvedBottles){
          res.bottlePrice = ((res.issuePrice ? res.issuePrice :0) / productKeys.unitsPerCase).toFixed(2);
        }
        if (productKeys.liquorType === 'B') {
          if(res.approvedCases){
            this.imlBeerCount.beer += res.approvedCases;
          }else{
            this.imlBeerCount.beer += res.approvedBottles;
          }
        } else {
          if(res.approvedCases){
            this.imlBeerCount.iml += res.approvedCases;
          }else{
            this.imlBeerCount.iml += res.approvedBottles;
          }
        }
        if(res.approvedBottles){
          this.totalDatas += (res.bottlePrice * (res.approvedBottles ? res.approvedBottles :0));
        }else{
          this.totalDatas += (res.issuePrice * res.approvedCases);
        }
      });
    }
  }

}
