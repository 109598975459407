import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

const baseUrl = environment.baseUrl;
const ttpUrl = baseUrl + 'ttp';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Through Transport Permit'] && userModules.mod_feats['Through Transport Permit'].features ? userModules.mod_feats['Through Transport Permit'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})

export class ThroughTransportPermitService {

  constructor(private http: HttpClient) {
  }

  putMethod(reqData) {
    return this.http.put(ttpUrl, reqData);
  }
  
  postMethod(reqData) {
    return this.http.post(ttpUrl, reqData);
  }

  getMethod(paramObj: any = '') {
    const headerDict = {
      feature
    };
    return this.http.get(ttpUrl, { headers: headerDict, params: paramObj });
  }

  getFeatures(paramObj: any = '') {
    const headerDict = {
      feature
    };
    return this.http.get(ttpUrl, { headers: headerDict, params: paramObj });
  }
}
