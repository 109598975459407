import { ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonsService } from 'src/app/core/services/commons.service';
import { MessageService } from 'primeng/api';
import { ThroughTransportPermitService } from './../../../core/services/through-transport-permit.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-manage-ttp-route-dialog',
  templateUrl: './manage-ttp-route-dialog.component.html',
  styleUrls: ['./manage-ttp-route-dialog.component.scss']
})
export class ManageTtpRouteDialogComponent implements OnInit {
  
  @ViewChild('ttpHtmlForm') ttpHtmlForm: any;
  ttpForm: FormGroup;
  userInfo: any;
  loader: any = false;
  startPoints: any = [
    'ICP, Purushothapuram (Srikakulam dt.)',
    'ICP, Naraharipet (Chittoor dt.)',
    'ICP, B V Palem ( Nellore dt.)',
    'Tetagunta',
    'Kothuru',
    'Renigunta',
    'Palamaner',
    'Penukonda'
  ];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ManageTtpRouteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private ttpService: ThroughTransportPermitService,
    private messageService:MessageService,
    private spinner: NgxSpinnerService,
  ) {
    this.userInfo = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
  }

  ngOnInit(): void {
    this.ttpForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      startPoint: ['', Validators.required],
      endPoint: ['', Validators.required],
      supplierName: [this.userInfo && this.userInfo.entity_id ? this.userInfo.entity_id : '', Validators.required],
    });
    if (this.data && this.data.name) {
      this.ttpForm.patchValue({
        name: this.data.name,
        description: this.data.description,
        startPoint: this.data.startPoint,
        endPoint: this.data.endPoint,
        supplierName: this.data.supplierName,
      });
    }
  }
  create() {
    if (this.ttpForm.status === 'VALID') {
      const req_Data = {
        action: 'create_ttp_route',
        data: this.ttpForm.value
      }
      this.loader = true;
      this.spinner.show();
      this.ttpService.postMethod(req_Data).subscribe((res: any) => {
        if (res && res.status === 'SUCCESS') {
          this.dialogRef.close('TTP Route Created Successfully');
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'TTP Route Creation Failed' });
        }
        this.loader = false;
        this.spinner.hide();
      }, (err) => {
        this.loader = false;
        this.spinner.hide();
      });
    }
  }
  update(): any {
    if (this.ttpForm.status === 'VALID') {
      const req_Data = {
        action: 'update_ttp_route',
        data: this.ttpForm.value
      }
      this.loader = true;
      this.spinner.show();
      this.ttpService.putMethod(req_Data).subscribe((res: any) => {
        if (res && res.status === 'SUCCESS') {
          this.dialogRef.close('TTP Route Updated Successfully');
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'TTP Route Update Failed' });
        }
        this.loader = false;
        this.spinner.hide();
      }, (err) => {
        this.loader = false;
        this.spinner.hide();
      });
    }
  }

  resetForm(): any {
    this.ttpHtmlForm.nativeElement.reset();    
  }

}
