import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InterDepotService } from 'src/app/core/services/inter-depot.service';


@Component({
  selector: 'app-manage-retailers-user-dialog',
  templateUrl: './manage-retailers-user-dialog.component.html',
  styleUrls: ['./manage-retailers-user-dialog.component.scss']
})
export class ManageRetailersUserDialogComponent implements OnInit {

  retailerUserform: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageRetailersUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private orderService: InterDepotService) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.retailerUserform = this.fb.group({
      indent: ['', [Validators.required, ]],
      icdc: ['', [Validators.required, ]],
      brands: ['', [Validators.required, ]],
      sizes: ['', [Validators.required, ]],
      cases: ['', [Validators.required, ]],
      bottles: ['', [Validators.required, ]],
      status: '',
    });
    if (this.data && this.data._id) {
      this.retailerUserform.patchValue({
        indent : this.data.indent,
        icdc: this.data.icdc,
        brands: this.data.brands,
        sizes: this.data.sizes,
        cases: this.data.cases,
        bottles: this.data.bottles,
        status: this.data.status,
      });
    }
    this.retailerUserform.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    })
  }


  get f(){
    return this.retailerUserform.controls;
  }

  createRetailer() {
    this.saveDisabled = true;
    // this.retailerUserform.patchValue({manufactureDate: new Date().toISOString() });
    const oObj = this.retailerUserform.value;
    delete oObj.status;
    const req_Data = {
      action : '',
      data : oObj
    }
    this.orderService.createOrder(req_Data).subscribe(res => {
      this.dialogRef.close();
    }, (err) => {
      this.error = err.error.message;
    });
  }

  updateRetailer() {
    this.saveDisabled = true;
    const obj = [{
      _id: this.data._id,
      update: this.retailerUserform.value
    }]
    this.orderService.putOrder(obj).subscribe(res => {
      this.dialogRef.close();
    }, (err) => {
      this.error = err.error.message;
    });
  }

  // getTitle() {
  //   return this.data && this.data._id ? `Update order for ${this.data.indent}` : 'Create order';
  // }


}
