import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BasicPrices } from '../models/liquor-pricing/basic-prices.model';
import { Calculations } from '../models/liquor-pricing/calculations.model';
import { Reports } from '../models/liquor-pricing/reports.model';
import { RetailPrices } from '../models/liquor-pricing/retail-prices.model';
import { Taxes } from '../models/liquor-pricing/taxes.model';

const baseUrl = environment.baseUrl;
const liquorPricingUrl = baseUrl + 'pricing';

const retailPricesUrl = baseUrl + 'liquorpricing/retailprices';
const basicPricesUrl = baseUrl + 'liquorpricing/basicprices';
const calculationsUrl = baseUrl + 'liquorpricing/calculations';
const taxesUrl = baseUrl + 'liquorpricing/taxes';
const reportsUrl = baseUrl + 'liquorpricing/reports';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['pricing'] && userModules.mod_feats['pricing'].features ? userModules.mod_feats['pricing'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class LiquorPricingService {

  constructor(private http: HttpClient) { }

  createRetailPrices(retailPricesObj: any){
    return this.http.post(retailPricesUrl, retailPricesObj)
  }
  getRetailPrices(offset, limit, type?: string):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    type ? paramObj.type = type : type = null;
    limit ? paramObj.limit = limit : limit = null;
    return this.http.get(retailPricesUrl, {params: paramObj});
  }

  putRetailPrices(retailPricesObj){
    return this.http.put<RetailPrices>(`${retailPricesUrl}`, retailPricesObj)
  }

  createBasicPrices(basicPricesObj: any){
    return this.http.post(basicPricesUrl, basicPricesObj);
  }
  getBasicPrices(offset, limit, type?: string):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    type ? paramObj.type = type : type = null;
    limit ? paramObj.limit = limit : limit = null;
    return this.http.get(basicPricesUrl, {params: paramObj});
  }

  putBasicPrices(basicPricesObj){
    return this.http.put<BasicPrices>(`${basicPricesUrl}`, basicPricesObj)
  }

  createCalculations(calculationsObj: Calculations){
    return this.http.post(calculationsUrl, calculationsObj);
  }
  getCalculations(offset, limit, type?: string):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    type ? paramObj.type = type : type = null;
    limit ? paramObj.limit = limit : limit = null;
    return this.http.get(calculationsUrl, {params: paramObj});
  }

  putCalculations(calculationsObj){
    return this.http.put<Calculations>(`${calculationsUrl}`, calculationsObj);
  }

  createTaxes(taxesObj: Taxes){
    return this.http.post(taxesUrl, taxesObj)
  }
  getTaxes(offset, limit, type?: string):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    type ? paramObj.type = type : type = null;
    limit ? paramObj.limit = limit : limit = null;
    return this.http.get(taxesUrl, {params: paramObj});
  }

  putTaxes(taxesObj){
    return this.http.put<Taxes>(`${taxesUrl}`, taxesObj);
  }

  createReports(reportsObj: Reports){
    return this.http.post(reportsUrl, reportsObj)
  }
  getReports(offset, limit, type?: string):Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    type ? paramObj.type = type : type = null;
    limit ? paramObj.limit = limit : limit = null;
    return this.http.get(reportsUrl, {params: paramObj});
  }

  putReports(reportsObj){
    return this.http.put<Reports>(`${reportsUrl}`, reportsObj);
  }

  getLiquorPricingFeatures(){
    const headerDict = {
      feature
    };
    return this.http.get(liquorPricingUrl, { headers: headerDict });
  }

}
