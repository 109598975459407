import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-licensee-bank-details-for-bars-dialog',
  templateUrl: './licensee-bank-details-for-bars-dialog.component.html',
  styleUrls: ['./licensee-bank-details-for-bars-dialog.component.scss']
})
export class LicenseeBankDetailsForBarsDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'bankName',
    'accountHolderName',
    'accountNumber',
    'ifcCode',
    'branch',
    'town'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;

  constructor(public dialogRef: MatDialogRef<LicenseeBankDetailsForBarsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    console.log(this.data.get_vendor_bank_details, '29::')
    this.dataSource = new MatTableDataSource(this.data['get_vendor_bank_details']);
  }

  dialogClose(): void {
    this.dialogRef.close();
  }



}
