import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-open-tp-details-dialog',
  templateUrl: './open-tp-details-dialog.component.html',
  styleUrls: ['./open-tp-details-dialog.component.scss']
})
export class OpenTpDetailsDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'no',
    'tpNumber',
    'vechicleNumber',
    'transporterName',
    'issueDate',
    'validityDate',
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  constructor(public dialogRef: MatDialogRef<OpenTpDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA)public data) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data);
  }

  dialogClose(): void{
    this.dialogRef.close();
  }


}
