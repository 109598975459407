import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { OtpVerificationDialogComponent } from 'src/app/core/components/otp-verification-dialog/otp-verification-dialog.component';
import { CommonsService } from 'src/app/core/services/commons.service';
import { RawMaterialProcurementService } from 'src/app/core/services/raw-material-procurement.service';
import { SpiritRegisterService } from 'src/app/core/services/spirit-register.service';

@Component({
  selector: 'app-procurement-status-dialog',
  templateUrl: './procurement-status-dialog.component.html',
  styleUrls: ['./procurement-status-dialog.component.scss']
})
export class ProcurementStatusDialogComponent implements OnInit {
  roleName: any;
  validityForm: FormGroup;
  todayDate:Date = new Date();
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  displayedColumns = [ "no", "pricePerPL", "requestedQtyBLs", "requestedQtyPLs", "strength", "type", "value"];
  constructor(private dialogRef: MatDialogRef<ProcurementStatusDialogComponent>,private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data, private spiritRegisterService: SpiritRegisterService, private commonsService: CommonsService, private rmpService : RawMaterialProcurementService,private commonService:CommonsService,private dialog: MatDialog) {
      this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
     }

  ngOnInit(): void {
    console.log(this.data, 'data')
    this.dataSource = new MatTableDataSource(this.data.spiritDetails)
    this.validityForm = this.fb.group({
      validityDate: ['',[Validators.required]],
      hrs: ['',[Validators.required]],
      mins: ['', [Validators.required]]
    })
  }


  selectDate( event) {

    let sDate =  new Date(event.value);
    let cDate = new Date();
    let time = Number(sDate.getTime() - cDate.getTime());
    let hours = Math.floor(time/1000/60/60);
    let minutes = Math.floor((time/1000/60/60 - hours)*60);
    this.validityForm.patchValue({
      hrs: hours,
      mins: minutes
    })
  }

  calcuateTime(event) {
      if(event.target.valueAsNumber > 2 && event.target.placeholder == "Validity Minutes") {
        event.preventDefault();
      }
      let hours = this.validityForm.get('hrs').value ? (this.validityForm.get('hrs').value)*1000*60*60 : 0;
      let minutes = this.validityForm.get('mins').value ? (this.validityForm.get('mins').value)*1000*60 : 0;

      let validateTime = new Date().getTime() + hours + minutes;

      this.validityForm.patchValue({
        validityDate: new Date(validateTime)
      })

  }

  procurementSubmit(status) {
    if(status == 'Approved'){
      // const req_otp ={
      //   action: "send_otp",
      //   data:{
      //    ...this.data
      //   }
      // }
      
      // this.commonService.sendVerifyOtp(req_otp).subscribe((res:any)=>{
        
        
      // })
      // const dialogRef = this.dialog.open(OtpVerificationDialogComponent, {
      //   width: '25%',
      //   height:'45%',
      //   autoFocus: false
      // });
  
      // dialogRef.afterClosed().subscribe((result) => {
      //   if(result.message == 'SUCCESS'){
          const req_Data = {
            action : this.roleName === 'Distillery Officer' ? 'review_rmp_indent' : 'approved_rmp_indent',
            data: this.data
          }
      
      
          req_Data.data.status = status;
          if(this.roleName === 'Distillery Officer' ) {
            this.spiritRegisterService.submitProcurement(req_Data).subscribe(res => {
              this.dialogRef.close("Procurement Updated Successfully");
            })
          }
          else {
            req_Data.data['validityDateTime'] = this.validityForm.get('validityDate').value;
            this.rmpService.submitProcurement(req_Data).subscribe(res => {
              this.dialogRef.close("Procurement Updated Successfully");
            })
          }
      //   }
      // });
    }else{
      const req_Data = {
        action : this.roleName === 'Distillery Officer' ? 'review_rmp_indent' : 'approved_rmp_indent',
        data: this.data
      }
  
  
      req_Data.data.status = status;
      if(this.roleName === 'Distillery Officer' ) {
        this.spiritRegisterService.submitProcurement(req_Data).subscribe(res => {
          this.dialogRef.close("Procurement Updated Successfully");
        })
      }
      else {
        req_Data.data['validityDateTime'] = this.validityForm.get('validityDate').value;
        this.rmpService.submitProcurement(req_Data).subscribe(res => {
          this.dialogRef.close("Procurement Updated Successfully");
        })
      }
    }



  }

}
