import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonsService } from 'src/app/core/services/commons.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { SpiritRegisterService } from '../../../../core/services/spirit-register.service';

@Component({
  selector: 'app-manage-procurement-dialog',
  templateUrl: './manage-procurement-dialog.component.html',
  styleUrls: ['./manage-procurement-dialog.component.scss']
})
export class ManageProcurementDialogComponent implements OnInit {
  rmpForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  productionTypes = ['Local', 'Export'];
  healStartCodes: any[];
  sizes: any[];
  packTypes: any[];
  startProductionData: any;
  submitData: any;
  submitData1: any;
  brandProducts: any = [];
  batchNumberData: any = [];
  brandCodeData: any = [];
  brandNameData: any = [];
  unitsPerCaseData: any = [];
  productCodeData: any = [];
  liquorTypeData: any = [];
  packTypeData: any = [];
  sizeData: any = [];
  spiritTypes = ['ENA', 'RS', 'MALT', 'GRAPE', 'HTS'];
  litreTypeList = ['/BL', '/PL'];
  price: any = '';
  litreType: any = '/BL';
  sourceEntityList: any = [];
  purposeList: any = [];
  selectedBrandList: any = [];
  showBrandList: boolean = false;
  constructor(private fb: FormBuilder, public util: UtilService, private dialogRef: MatDialogRef<ManageProcurementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private spiritRegisterService: SpiritRegisterService, private commonsService: CommonsService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    //this.spinner.show();
    this.rmpForm = this.fb.group({
      /*  selectProductionType:[''], */
      indentId: ['', Validators.required],
      sourceEntityType: [''],
      sourceEntityId: ['', Validators.required],
      // destinationEntityType:[''],
      // destinationEntityId:[''],
      type: ['', Validators.required],
      strength: ['', [Validators.required, Validators.min(100), Validators.max(1000)]],
      requestedQtyBLs: ['', [Validators.required, Validators.min(1)]],
      requestedQtyPLs: [''],
      brand: [[]],
      requestedOn: [''],
      pricePerBl: [''],
      price: ['', [Validators.required, Validators.min(1)]],
      value: ['', [Validators.required, Validators.min(1)]]
    });
    if (this.data && this.data._id) {
      this.rmpForm.patchValue({

      });
    }
    this.rmpForm.valueChanges.subscribe((change) => {
      //this.saveDisabled = false;
    })

    let paramObj = {
      action: 'common_apis',
      q: '{"data": ["get_primary_dist", "get_brand_code"]}'
    }
    this.commonsService.getCommonEntites(paramObj).subscribe(res => {
      console.log(res, "72::::")
      this.sourceEntityList = res['get_primary_dist'].map(entity => entity.primaryCode);
      this.purposeList = res['get_brand_code'].map(entity => entity.brandCode);
      this.purposeList = this.purposeList.filter((p, i) => i == this.purposeList.lastIndexOf(p))
    })
  }


  get f() {
    return this.rmpForm.controls;
  }

  brandDropDown() {
    console.log("89:::")
    this.showBrandList = !this.showBrandList;
    document.getElementById('brand-dropdown').click();
  }

  brandChange(event) {
    console.log(event, "97:::");
    if (this.selectedBrandList.indexOf(event) == -1) {
      this.selectedBrandList.push(event)
    }
    this.rmpForm.patchValue({
      brand: this.selectedBrandList
    })
  }

  onAddSpools(e) {
    console.log(this.rmpForm.get('brand').value, "101::")
  }
  onRemoveSpools(e) {
    console.log(e, "99:::")
    this.selectedBrandList.splice(this.selectedBrandList.indexOf(e.value), 1);
    this.rmpForm.patchValue({
      brand: this.selectedBrandList
    })
    document.getElementById('brand-dropdown').click();

  }
  calculatePls() {
    this.rmpForm.patchValue({
      requestedQtyPLs: Number.parseFloat(((this.rmpForm.get('requestedQtyBLs').value * this.rmpForm.get('strength').value) / 100).toFixed(3))
    });
    this.priceChange();
  }

  priceChange() {
    const requestedQtyBLs = this.rmpForm.get('requestedQtyBLs').value;
    const requestedQtyPLs = this.rmpForm.get('requestedQtyPLs').value;

    if (!isNaN(requestedQtyBLs) && !isNaN(requestedQtyPLs)) {
      this.rmpForm.patchValue({
        value: this.litreType == "/BL" ? Number.parseFloat((requestedQtyBLs * this.rmpForm.get('price').value).toFixed(2)) : Number.parseFloat((requestedQtyPLs * this.rmpForm.get('price').value).toFixed(2))
      })
    }
  }

  createIndent() {
    let data = {
      indentId: this.rmpForm.value.indentId,
      sourceEntityId: this.rmpForm.value.sourceEntityId,
      purpose: this.rmpForm.value.brand,
      spiritDetails: [
        {
          type: this.rmpForm.value.type,
          strength: this.rmpForm.value.strength,
          requestedQtyBLs: this.rmpForm.value.requestedQtyBLs,
          requestedQtyPLs: this.rmpForm.value.requestedQtyPLs,
          value: this.rmpForm.value.value
        }
      ]
    }
    if (this.litreType == 'BL') {
      data.spiritDetails[0]['pricePerBL'] = this.rmpForm.get('price').value;
    }
    else {
      data.spiritDetails[0]['pricePerPL'] = this.rmpForm.get('price').value;
    }



    const req_Data = {
      action: 'add_rmp_indent',
      data
    }



    console.log(req_Data, this.rmpForm.value, "146:::")
    this.spiritRegisterService.createRmpIndent(req_Data).subscribe(res => {
      this.dialogRef.close('Added RMP Indent Successfully')
    });
  }
  updateIndent() {
    const req_Data = {
      action: 'initiate_prod',
      data: this.rmpForm.value
    }

    console.log(req_Data, this.rmpForm.value, "146:::")
    /* this.productionService.createBatchProduction(req_Data).subscribe((res: any) => {
      this.dialogRef.close('Batch Initiated Successfully');
    }, (err) => {
      this.error = err.error.message;
    }); */
  }

}
