import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  templateUrl: './show-bank-details-dialog.component.html',
  styleUrls: ['./show-bank-details-dialog.component.scss']
})
export class ShowBankDetailsDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'bankName',
    'accountHolderName',
    'accountNumber',
    'ifcCode',
    'branch',
    'town'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;

  constructor(public dialogRef: MatDialogRef<ShowBankDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data['get_vendor_bank_details']);
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

}
