import { Component, OnInit } from '@angular/core';
import MODULES from '../../constants/modules.constants';
import { KeyValue } from '@angular/common';


@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {
  modules: any;
  modulesConstants = MODULES;

  valueOrder = (a: KeyValue<number,any>, b: KeyValue<number,any>): number => {
    
    return a.value.m_idx - b.value.m_idx;
  }

  constructor() {
  }

  ngOnInit(): void {
    this.getAllModulesandFeatures();
  }
  getAllModulesandFeatures() {
    this.modules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).mod_feats;
    if(this.modules["Reports"]){
      this.modules["Reports"].m_idx=1000
    }
  }

  getModule(module) {
    return this.modulesConstants.find(mod => mod.moduleId === module.moduleId);
  }

  getModuleName(module) {
    return this.getModule(module).moduleName;
  }

  getRouteName(module) {
    return module.replace(/\s+/g, '-').toLowerCase();
  }

}
