import { CommonsService } from 'src/app/core/services/commons.service';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OtpVerificationDialogComponent } from 'src/app/core/components/otp-verification-dialog/otp-verification-dialog.component';
import { CsdService } from 'src/app/core/services/csd.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-status-csd-dialog',
  templateUrl: './status-csd-dialog.component.html',
  styleUrls: ['./status-csd-dialog.component.scss']
})
export class StatusCsdDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'no',
    'productCode',
    'brandName',
    'size',
    'approvedQuantity',
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  addQuantityForm: FormGroup;
  limit: number;
  offset: number;
  search: string;
  error: any;
  roleName: any;
  vailidityDate: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;
  constructor(
    public util: UtilService, private fb: FormBuilder, public dialogRef: MatDialogRef<StatusCsdDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, private csdService: CsdService,private commonService:CommonsService,private dialog: MatDialog,private messageService:MessageService) {
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
  }

  ngOnInit(): void {

    this.vailidityDate = this.getValidityDate();
    this.vailidityDate.setDate(0);
    this.vailidityDate = this.vailidityDate.toDateString();
    this.data.productDetails.forEach(element => {
      element['approvedQty'] = element.approvedQty ? element.approvedQty : element.requestedQty;
      element['value'] =  element.casePrice * element.approvedQty;
    });

    this.dataSource = new MatTableDataSource(this.data.productDetails);

    this.addQuantityForm = this.fb.group({
      poNo: [this.data.purchaseOrderDetails ? this.data.purchaseOrderDetails[0].poNo : '']

    });
  }


  getValidityDate() {
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth() < 10 ? currentDate.getMonth() + 2 : currentDate.getMonth() - 10;
    let currentYear = currentDate.getFullYear();
    return new Date(new Date(currentMonth + '/' + 1 + '/' + currentYear).getTime() - (6 * 60 * 60 * 1000));
  }
  ngAfterViewInit() {
    this.util.ngAfterViewInit(this.dataSource, this.paginator, this.sort, null, null);
  }

  applyFilter() {
    this.util.applyFilter(this.dataSource, this.search);
  }
  updateStatus(status) {
    if(status == 'Approved'){
      // const req_otp ={
      //   action: "send_otp",
      //   data:{
      //    ...this.data
      //   }
      // }
      
      // this.commonService.sendVerifyOtp(req_otp).subscribe((res:any)=>{
        
        
      // })
      // const dialogRef = this.dialog.open(OtpVerificationDialogComponent, {
      //   width: '25%',
      //   height:'45%',
      //   autoFocus: false
      // });
  
      // dialogRef.afterClosed().subscribe((result) => {
      //   if(result.message == 'SUCCESS'){
          const req_Data = {
            action: 'approve_csd',
            data: this.data
          }
          req_Data.data.productDetails = req_Data.data.productDetails.map(res => {
            res.approvedQty = Number(res.approvedQty);
            return res;
          })
          delete req_Data['data']['colName'];
          req_Data['data']['status'] = status;
          this.data.validityDate = this.getValidityDate();
          console.log(this.data, req_Data, "77::::::::::")
          this.csdService.updateCsdIndents(req_Data).subscribe((res: any) => {
            if(res.status === 'SUCCESS'){
              this.dialogRef.close('Indent Approved Successfully');
            }else{
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Indent Approval failed' });
            }
            this.data.status = status;
          }, (err) => {
            this.error = err.error.message;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Indent Approval failed' });

          });
      //   }
      // });
    }else{
      const req_Data = {
        action: 'approve_csd',
        data: this.data
      }
      req_Data.data.productDetails = req_Data.data.productDetails.map(res => {
        res.approvedQty = Number(res.approvedQty);
        return res;
      })
      delete req_Data['data']['colName'];
      req_Data['data']['status'] = status;
      this.data.validityDate = this.getValidityDate();
      console.log(this.data, req_Data, "77::::::::::")
      this.csdService.updateCsdIndents(req_Data).subscribe((res: any) => {
        this.data.status = status;
        if(res.status === 'SUCCESS'){
          this.dialogRef.close('Indent Rejected Successfully');
        }else{
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Indent Rejection failed' });
        }
      }, (err) => {
        this.error = err.error.message;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Indent Rejection failed' });
      });
    }

  }

  setPrice(row) {
    row.value = row.casePrice * row.approvedQty;
  }
  onPaginateChange(e) {
    this.limit = e.pageSize;
    this.offset = e.pageIndex * e.pageSize;
  }
  dialogClose(): void {
    this.dialogRef.close();
  }


}
