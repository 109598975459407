import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

const baseUrl = environment.baseUrl;
const indentManagementUrl = baseUrl + 'indent_management';
const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const indentManagementFeature = userModules && userModules.mod_feats && userModules.mod_feats['Indent Management'] && userModules.mod_feats['Indent Management'].features ? userModules.mod_feats['Indent Management'].features.map(feat => feat.feature_name) : [];
@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {

  constructor(private http:HttpClient) { }

  updateIndents(obj: any) {
    return this.http.put(indentManagementUrl, obj);
  }

  getIndentManagementFeatures(paramObj: any = '') {
    const headerDict = {
      indentManagementFeature
    };
    return this.http.get(indentManagementUrl, { headers: headerDict, params: paramObj });
  }
}
