import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonsService } from 'src/app/core/services/commons.service';
import { SpiritRegisterService } from 'src/app/core/services/spirit-register.service';
import { ManageEnaStockDialogComponent } from '../../ena/manage-ena-stock-dialog/manage-ena-stock-dialog.component';

@Component({
  selector: 'app-manage-blend-stock-dialog',
  templateUrl: './manage-blend-stock-dialog.component.html',
  styleUrls: ['./manage-blend-stock-dialog.component.scss']
})
export class ManageBlendStockDialogComponent implements OnInit {

  blendStockForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  blendTanks: Array<string>;
  saveDisabled: boolean;
  units = ['OP', 'UP'];


  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageEnaStockDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private commonsService: CommonsService, private spiritService: SpiritRegisterService) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.getBlendTanks();

    this.blendStockForm = this.fb.group({
      date: ['', [Validators.required,]],
      blendTankNumber: ['', [Validators.required,]],
      brandName: ['', [Validators.required,]],
      category: ['', [Validators.required,]],
      batchNumber: ['', [Validators.required,]],
      enaVatNo: ['', [Validators.required,]],
      enaIssued: ['', [Validators.required,]],
      strengthEna: ['', [Validators.required,]],
      unit: ['', [Validators.required,]],
      maltVatNo: ['', [Validators.required,]],
      maltIssued: ['', [Validators.required,]],
      strengthMV: ['', [Validators.required,]],
      otherSpiritsVatNo: ['', [Validators.required,]],
      otherSpiritsIssued: ['', [Validators.required,]],
      strengthOS: ['', [Validators.required,]],
      blendPrepared: ['', [Validators.required,]],
      strength: ['', [Validators.required,]],
    });
    if (this.data && this.data._id) {
      this.blendStockForm.patchValue({
        date: this.data.date,
        blendTankNumber: this.data.blendTankNumber,
        brandName: this.data.brandName,
        category: this.data.category,
        batchNumber: this.data.batchNumber,
        enaVatNo: this.data.enaVatNo,
        enaIssued: this.data.enaIssued,
        strengthEna: this.data.strengthEna,
        unit: this.data.unit,
        maltVatNo: this.data.maltVatNo,
        maltIssued: this.data.maltIssued,
        strengthMV: this.data.strengthMV,
        otherSpiritsVatNo: this.data.otherSpiritsVatNo,
        otherSpiritsIssued: this.data.otherSpiritsIssued,
        strengthOS: this.data.strengthOS,
        blendPrepared: this.data.blendPrepared,
        strength: this.data.strength
      });
    }
    this.blendStockForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    })
  }

  get f() {
    return this.blendStockForm.controls;
  }

  createBlendStock() {
    this.saveDisabled = true;
    const req_Data = {
      action: 'add_blend_stock',
      data: this.blendStockForm.value
    }
    this.spiritService.createBlendStock(req_Data).subscribe((res: any) => {
      this.dialogRef.close('Blend Stock Created Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  getBlendTanks() {
    const req_Data = {
      action: 'common_apis',
      q: '{"data": {"action": "get_tanks_by_type", "value": "Blend Tank"}}'
    }
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {
      this.blendTanks = result['get_tanks_by_type'];
    })
  }

  updateBlendStock() {
    // this.saveDisabled = true;
    // const obj = [{
    //   _id: this.data._id,
    //   update: this.blendStockForm.value
    // }]
    // this.spiritService.putBatch(obj).subscribe((res: any) => {
    //   this.dialogRef.close('Batch Udated Successfully');
    // }, (err) => {
    //   this.error = err.error.message;
    // });
  }


}
