import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductsService } from 'src/app/core/services/products.service';

@Component({
  selector: 'app-add-brand-barcode',
  templateUrl: './add-brand-barcode.component.html',
  styleUrls: ['./add-brand-barcode.component.scss']
})
export class AddBrandBarcodeComponent implements OnInit {
  lineForm: FormGroup;
  error: string;
  saveDisabled: boolean;
  entityId: any;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<AddBrandBarcodeComponent>, private productsService: ProductsService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }


  ngOnInit(): void {
    console.log('this.data', this.data);
    this.lineForm = this.fb.group({
      brandBarcode: ['', Validators.required],
    });
    if (this.data && this.data._id) {
      this.lineForm.patchValue({
        brandBarcode: this.data.brandBarcode
      });
    }
    this.lineForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    })
  }

  todayDate = new Date();

  get f() {
    return this.lineForm.controls;
  }

  createBrandBarCode() {
    this.saveDisabled = true;
    const req_Data = {
      action: 'add_brand_barcode',
      data: this.lineForm.value
    }
    req_Data.data.brandCode = this.data.brandCode;
    req_Data.data.productCode = this.data.productCode;
   
  
    this.productsService.putbrandCodes(req_Data).subscribe((res: any) => {
      if(res.error) {
        this.error = res.error;
      }
      else {
        this.error = '';
        this.dialogRef.close('Brand Code Successfully');
      }
    }, (err) => {
      this.error = err.error.message;
    });
  }

  createLine() {
    // this.saveDisabled = true;
    // const req_Data = {
    //   action: 'add_line',
    //   data: this.lineForm.value
    // }
    // const blObj = this.lineForm.value;
    // delete blObj.status;
    // this.distilleryInfoService.createBottlingLine(req_Data).subscribe((res: any) => {
    //   this.dialogRef.close('Bottling Line Created Successfully');
    // }, (err) => {
    //   this.error = err.error.message;
    // });
  }

  // getTitle() {
  //   return this.data && this.data._id ? `Update Line for ${this.data.lineName}` : 'Create Line';
  // }

}
