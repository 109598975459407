import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Samples } from '../models/lab/samples.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


const baseUrl = environment.baseUrl;
const lrUrl = baseUrl + 'label_registration';

/* const samplesUrl = baseUrl + 'labsamples';
const failedSamplesUrl = baseUrl + 'labsamples';
const finishedGoodsUrl = baseUrl + 'labsamples';
const labReportsUrl = baseUrl + 'labreports'; */

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Label Registration'] && userModules.mod_feats['Label Registration'].features ? userModules.mod_feats['Label Registration'].features.map(feat => feat.feature_name) : [];


@Injectable({
  providedIn: 'root'
})
export class LabelRegistrationService {
  featuresData: any;

  constructor(private http: HttpClient) { }

  getBrandData(params) {
    return this.http.get(lrUrl, { params })
  }

  createLabel(req_Data) {
    return this.http.put(lrUrl, req_Data)
  }

  getlrFeatures() {
    const headerDict = {
      feature
    };
    return this.http.get(lrUrl, { headers: headerDict });
  }

  uploadLabelDocuments(req_Data) {
    return this.http.post(lrUrl, req_Data)
  }
  updateLabelDocuments(req_Data) {
    return this.http.put(lrUrl, req_Data)
  }

  verifyLabelDocuments(req_Data){
    return this.http.post(lrUrl, req_Data)
  }
}
