import { NgxSpinnerService } from 'ngx-spinner';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CsdService } from 'src/app/core/services/csd.service';

@Component({
  selector: 'app-manage-csd-locations-dialog',
  templateUrl: './manage-csd-locations-dialog.component.html',
  styleUrls: ['./manage-csd-locations-dialog.component.scss']
})
export class ManageCsdLocationsDialogComponent implements OnInit {

  csdLocationForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  locationTypes = ['CSD'];


  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageCsdLocationsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private csdService: CsdService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.csdLocationForm = this.fb.group({
      locationType: [this.locationTypes[0], [Validators.required]],
      csdName: ['',[Validators.required]],
      destination: ['',[Validators.required]],
      address: ['',[Validators.required]],
      licenceNo: ['',[Validators.required]],
    });
    if (this.data && this.data._id) {
      this.csdLocationForm.patchValue({
        locationType : this.data.locationType,
        csdName: this.data.csdName,
        destination: this.data.destination._id,
        address: this.data.address,
        licenceNo: this.data.licenceNo,
      });
    }
    this.csdLocationForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
  }

  get f(){
    return this.csdLocationForm.controls;
  }

  createCSDLocation() {
    this.saveDisabled = true;
    const req_data ={
      action: 'add_csd_location',
      data: {...this.csdLocationForm.value}
    };
    this.spinner.show();
    this.csdService.createCSDLocation(req_data).subscribe((res: any) => {
      this.dialogRef.close('CSD Location Created Successfully');
      this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      this.error = err.error.message;
    });
  }

  updateCSDLocation() {
    // this.saveDisabled = true;
    // const obj = [{
    //   _id: this.data._id,
    //   update: this.csdLocationForm.value
    // }]
    // this.spiritService.putBatch(obj).subscribe((res: any) => {
    //   this.dialogRef.close('Batch Udated Successfully');
    // }, (err) => {
    //   this.error = err.error.message;
    // });
  }

}
