import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const usermanualUrl = baseUrl + 'users';
const distilleryUrl = baseUrl + 'distillery_info'
const supplierUrl = baseUrl + 'supplierUrl';
const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['UserManual'] && userModules.mod_feats['UserManual'].features ? userModules.mod_feats['UserManual'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class UserManualService {

  constructor(private http: HttpClient) { }

  getAPBCLlDetails(offset, limit, search) {
    return this.http.get('assets/samples/apbcl.json');
  }

  createSupplier(supplierObj: any) {
    return this.http.post(usermanualUrl, supplierObj);
  }

  createStaffUser(supplierObj: any) {
    return this.http.post(distilleryUrl, supplierObj);
  }

  getUserManualFeature() {
    const headerDict = {
      feature
    };
    return this.http.get(usermanualUrl, { headers: headerDict });
  }

  createDepot(obj) {
    return this.http.post(usermanualUrl, obj);
  }

  createVendor(obj) {
    return this.http.post(usermanualUrl, obj);
  }

  createAPSBCL(obj) {
    return this.http.post(usermanualUrl, obj);
  }
}
