import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Get_Shipments, Shipments } from 'src/app/core/models/shipments/shipments.model';
import { CommonsService } from 'src/app/core/services/commons.service';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { ProductCodeDialogComponent } from 'src/app/features/stock/local/product-code-dialog/product-code-dialog.component';
import { MessageService } from 'primeng/api';
import { UtilService } from 'src/app/core/utils/utility-helper';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
declare const JsBarcode: any;
@Component({
  selector: 'app-icdc-details-dialog',
  templateUrl: './icdc-details-dialog.component.html',
  styleUrls: ['./icdc-details-dialog.component.scss']
})
export class IcdcDetailDialogComponent implements OnInit {
  ofsCode = '';
  ofsScanCode = '';
  @Input() statusData: any;
  displayedColumns: string[] = ['ofsNo', 'productCode', 'productName', 'orderQty', 'allocateQty', 'action'];
  dataSource: MatTableDataSource<Shipments> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;
  icdcData = [];
  caseCode: any;
  valid: any;
  caseCount: any;
  isEdit: boolean = false;
  isQtyValid: boolean;
  cttDetails: any;
  ofsChangeData: any;
  isDisable: boolean = true;
  isScanned: boolean;
  ofsIndex: any;
  userDetails:any;
  depotName:any;
  distilleryAddress:any;
  totalDatas:any=[];
  selectedIcdcDatas: any = [];
  selectedIcdc:any;
  indentType:Boolean;

  constructor(public dialogRef: MatDialogRef<IcdcDetailDialogComponent>, private commonsService: CommonsService,
    private shipmentsService: ShipmentsService, @Inject(MAT_DIALOG_DATA) public data,
    private messageService:MessageService,public util:UtilService,
    private spinner: NgxSpinnerService) { 
    this.userDetails = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
    this.depotName =  this.userDetails.entity_id;
    this.distilleryAddress = this.userDetails.depot_address;

  }
  ofsAddFields = [];
  ngOnInit(): void {
    
    // if (this.data.icdc && this.data.consignment) {
    //   const icdcDatas = this.data.icdc.filter(res => res.icdcCode === this.data.consignment[0].icdcCode);
    //   if (icdcDatas) {
    //     this.selectedIcdcDatas = icdcDatas[0];
    //   }
    // }
    if (this.statusData) {
      this.data = this.statusData;
    }
    this.getOfsDetails();
    this.ofsAddFields = [
      { ofsCode: '', productCode: '', productName: '', casesCount: '', approvedQty: '' }
    ]
    
    
    if(this.data.consignment){
     var indentNo = this.data.consignment[0].indentId.substring(0,3);
     if(indentNo == 'RET'){
      this.indentType = true;
     }else{
      this.indentType = false;
     }
    }
  }

  convertToDate(date): any {
    return date ? moment(date).format('DD/MM/YY, h:mm a') : '-';
  }
  getCttFetails() {
    let data = JSON.stringify({ "data": ["get_ctt"] });

    const req_Data = {
      action: 'common_apis',
      q: data

    }
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {
      this.cttDetails = result['get_ctt'];
    })
  }
  ngAfterViewInit(): any {  
      JsBarcode('#barcode-bar-tp', this.data.tpNo,
        {
          width: 1,
          height: 40
        }
      );
      if(this.data.consignment){
          JsBarcode('#barcode-bar-icdc', this.data.consignment[0].icdcCode,
          {
            width: 0.7,
            height: 20,
            fontSize: 14,
          }
        );
        JsBarcode('#barcode-bar-indent-id', this.data.consignment[0].indentId,
          {
            width: 1,
            height: 40,
          }
        );
      }
      JsBarcode('#barcode-gatepass', this.data.tpNo,
      {
        width: 1,
        height: 40,
      }
    );
  }
  getOfsDetails() {
    let tpNo = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
    const req_Data = {
      action: 'common_apis',
      q: '{"data": {"action": "get_added_icdc", "value": "' + tpNo + '"}}'

    }
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {
      this.icdcData = result['get_added_icdc'];
      if (this.icdcData) {
        this.selectedIcdcDatas = this.icdcData[0];
        this.icdcData.forEach(ofs => {
          ofs.isShowShortages = false;
          ofs.isShowBreakages = false;
           ofs.productDetails.forEach(prod => {
            // prod.casesCount = prod.approvedCases;
            // delete prod.approvedBottles;
            // delete prod.bottlesCount;
            // prod.approvedCases = 5;
            // prod.casesCount = prod.casesCount - 1;
            // prod.shortages.bottles = 47;
            // prod.breakages = {
            //   bottleBreakages: [
            //     {
            //       caseCode: 'xxxxx'
            //     }
            //   ],
            //   bottles: 2
            // };
            const upc = this.util.productCodeToDetails(prod.productCode).unitsPerCase;
            prod.unitsPerCase = upc;
            prod.approvedBottlesQty = ((prod.approvedCases ? prod.approvedCases : 0) * upc) + (prod.approvedBottles ? prod.approvedBottles : 0);
            this.util.getTotalScannedCasesBottles(prod);
            prod.shortBottle = prod.shortageBottles ? prod.shortageBottles : 0;
            prod.totalQty = ((prod.totalScannedCases * upc) + (prod.breakageCases * upc) + (prod.shortageCases * upc) + (prod.totalScannedBottles + prod.breakageBottles + prod.shortageBottles));
            if (!ofs.isShowBreakages) {
              if (prod.breakageCases || prod.breakageBottles) {
                ofs.isShowBreakages = true;
              }
            }
            if (!ofs.isShowShortages) {
              if (prod.shortageCases || prod.shortageBottles) {
                ofs.isShowShortages = true;
              }
              if (ofs.indentType !== 'bottles_indent' && prod.approvedBottlesQty === prod.totalQty) {
                ofs.isShowShortages = true;
              }
            }
           });
          //  ofs.isShowShortages = ofs.productDetails.some(prod => (prod.approvedCases * prod.unitsPerCase) + (prod.approvedBottles ? prod.approvedBottles : 0) === this.getTotalQty(prod));
         })
         console.log('this.icdcData', this.icdcData);
      }
    })
  }
  // getTotalQty(prod): any {
  //   const upc = prod.unitsPerCase;
  //   prod.totalQty = ((prod.totalScannedCases * upc) + (prod.breakageCases * upc) + (prod.shortageCases * upc) + (prod.totalScannedBottles + prod.breakageBottles + prod.shortageBottles));
  //   console.log('prod', prod);
  //   return prod.totalQty;
  // }

  shipmentQuantityChange(item) {
    return item.approvedQty < item.shipmentQty || item.shipmentQty < item.casesCount;
  }


  addOfs() {
    let data = {};
    this.data.icdc.forEach(ofs => {
      if (this.ofsCode == ofs.icdcCode) {
        data = ofs;
      }
      data['tpNo'] = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
    });
    const req_Data = {
      action: 'add_icdc',
      data
    }
    this.shipmentsService.putOfs(req_Data).subscribe(res => {
      
      if(res.status == 'ICDC Already exists'){
        this.messageService.add({ severity: 'error', summary: 'Error Message', detail: res['status'] });
      }
      this.getOfsDetails();
    })
  }
  
  formatDepotName(depotName){  
    depotName.replace("Excise", ""); 
    return depotName;
  }

  shipmentEdit(item) {
    item.isEdit = true;
  }

  shipmentSave(item, ofs) {
    if (item.approvedQty < item.shipmentQty || item.shipmentQty < item.casesCount) {
      return;
    }
   
    const req_Data = {
      action: 'update_shipment_qty',
      data: { "tpNo": this.data.tpNo, "icdc": ofs.icdcCode, "productCode": item.productCode, "shipmentQty": item.shipmentQty }
    }
    this.shipmentsService.saveShipmentQuantity(req_Data).subscribe(result => {

    })
    item.isEdit = false;
  }

  dataFill(data) {
    let flag = false;
    Object.keys(data).forEach(val => {
      flag = !data[val];
    })
    return flag;
  }

  deleteOfs(i) {
    this.ofsAddFields.splice(i, 1);
  }

  caseCodeChange() {
    let pbData = [];

    
    /*    this.cttDetails.forEach(ctt => {
         if(ctt.caseCode == this.caseCode) {
           cttData = ctt;
         }
       }) */
    if (this.caseCode && this.ofsScanCode) {
      let pbDetails = this.ofsChangeData.productDetails.filter(product => {
        return product.shipmentQty !== product.casesCount;
      })
      pbData = pbDetails.map(ofs => {
        return { "batchId": ofs.batchId, "productCode": ofs.productCode }
      })
      const req_Data = {
        action: 'add_casecodes',
        // data: { "tpNo": this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo, "icdcCode": this.ofsScanCode, "caseCode": this.caseCode, "pbDetails": pbData }
        data: { "tpNo": this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo, "icdcCode": this.ofsScanCode, "caseCode": this.caseCode, "depotCode": this.icdcData[this.ofsIndex].productDetails.destnEntityId, "productDetails": this.icdcData[this.ofsIndex].productDetails }
        // "depotCode": this.data.destnEntityId, "productDetails": this.ofsData[this.ofsIndex].productDetails
      }
      if (this.ofsChangeData.productDetails && this.ofsChangeData.productDetails[0]['approvedCases']) {
        req_Data.data['type'] = 'cases';
      }
      else {
        req_Data.data['type'] = 'bottles';
      }
      

      this.shipmentsService.scanBarCode(req_Data).subscribe(res => {

      

        this.valid = res.status;
        this.caseCode = '';
        this.getOfsDetails();
        //  this.ofsData.forEach(ofs => {
        //   console.log(ofs.ofsCode, this.ofsChangeData, res.productCode, res.batchId, "155:::::")
        //   if (ofs.ofsCode == this.ofsChangeData.ofsCode) {
        //     ofs.productDetails.forEach(product => {
        //       if (product.productCode == res.productCode && product.batchId == res.batchId) {
        //         product.casesCount = product.casesCount ? product.casesCount++ : 1;
        //       }
        //     })
        //   }
        // }) 
        
      /*   console.log(res);
        this.isScanned = true;
        this.valid = res.status;
        this.caseCode = '';
        this.icdcData.forEach(ofs => {
          console.log(ofs.ofsCode, this.ofsChangeData, res.productCode, ofs.productDetails, res.batchId, "155:::::")
          if (ofs.icdcCode == this.ofsChangeData.icdcCode) {
            ofs.productDetails.forEach(product => {
              if (product.productCode == res.productCode) {
                console.log(ofs.ofsCode, this.ofsChangeData, res.productCode, res.batchId, "155:::::")
                product.casesCount = product.casesCount ? product.casesCount++ : 1;
              }
            })
          }
        })
        console.log(this.icdcData, "163:::::") */

      })
    }
  }

  checkIndent(){
    if(this.data){
      const is2C = this.data.consignment[0].indentId.substring(0,4);
      const is2TD = this.data.consignment[0].indentId.substring(0,2);
      if(is2C === 'INDC'){
        this.totalDatas.totalTcsVal = 0;
      }else if(is2TD === 'TD'){
        this.totalDatas.totalTcsVal = 0;
        this.totalDatas.totalSplPrevFee = 0; 
      }
    }

  }

  changeOfs(code,i) {
    this.isDisable = false;
    this.ofsScanCode = code.icdcCode;
    this.ofsIndex = i;
    this.ofsChangeData = code;
  }
  dialogClose(): void {
    this.dialogRef.close();
  }
  printIndent(id){
    if (id) {
      setTimeout(() => {
        let printContents, popupWin;
        printContents = document.getElementById(id).innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.write(`${printContents}`);
        popupWin.document.close();
      }, 500);
    }
  }
  print(data,id){
    this.selectedIcdc = data;
    this.totalValue();
    setTimeout(() => {
      let printContents, popupWin;
      const parent = document.getElementById(id); 
      const isExist = this.data.pdfDetails && this.data.pdfDetails.find(el => el.tpNo === this.data.tpNo && el.fileName === id && el.login === this.userDetails.activeUsersName); 
      if (id === 'print-gro-icdc' && this.data.pdfDetails && isExist) {
        let panel = document.getElementById(id);
        let printWindow = window.open('', '', 'scrollbars=1,landscape=1');
        printWindow.document.write("<html><head><title></title></head>");
        printWindow.document.write('<body id="print-label-details-form">');
        printWindow.document.write(panel.innerHTML);
        printWindow.document.write('</body></html>');
        let pHeight=((this.selectedIcdc.productDetails.length <= 12 ? 2 : Math.ceil(this.selectedIcdc.productDetails.length/6)) * 1000)
        let windowHeight = 1070 ;
        let noOfWatermark = pHeight/windowHeight;
        for(let i=0;i<noOfWatermark;i++){
          printWindow.document.write( `<div style="color: #e6e4e1;position: absolute;left: 5%;top: ${(windowHeight*i)+500}px;font-size: 5.5rem;transform: rotate(-40deg);opacity:0.3">Duplicate Copy</div>`);
        }
      printWindow.document.close();
      } else {
        this.commonsService.updateDuplicateCopy(id, 'tpNo', this.data.tpNo);
        printContents = parent.innerHTML;
        if ((id === 'print-gro-icdc' && !this.data.pdfDetails) || !isExist) {
          this.data.pdfDetails = [];
          const d = {
            fileName: id,
            login: this.userDetails.activeUsersName,
            tpNo: this.data.tpNo
          }
          this.data.pdfDetails.push(d);
        }
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.write(`${printContents}`);
        popupWin.document.close();
      }      
    }, 500);
 
  }
  totalValue(): any {
    this.totalDatas = [];
    this.totalDatas.approvedCases = 0;
    this.totalDatas.approvedBottles = 0;
    this.totalDatas.casesCount = 0;
    this.totalDatas.subTotal = 0;
    this.totalDatas.barSubTotal = 0;
    this.totalDatas.InvCases = 0;
    this.totalDatas.InvBtls = 0;
    this.totalDatas.brkCases = 0;
    this.totalDatas.brkBtls = 0;
    this.totalDatas.beerCases = 0;
    this.totalDatas.beerBottles = 0;
    this.totalDatas.imlCases = 0;
    this.totalDatas.imlBottles = 0;
    this.totalDatas.breakBtls=0;
    this.totalDatas.imlApprovedCases = 0;
    this.totalDatas.imlApprovedBottles = 0;
    this.totalDatas.beerApprovedCases = 0;
    this.totalDatas.beerApprovedBottles = 0;
    this.totalDatas.totalImlBreakagesCases = 0;
    this.totalDatas.totalImlBreakagesBottles = 0;
    this.totalDatas.beerBreakagesCases = 0;
    this.totalDatas.beerBreakagesBottles = 0;
    this.totalDatas.totalCartoonCases = 0;
    this.totalDatas.totalCartoonBottles = 0;
    this.totalDatas.totalShortageImlCases = 0;
    this.totalDatas.totalShortageImlBottles = 0;
    this.totalDatas.totalShortageBeerCases = 0;
    this.totalDatas.totalShortageBeerBtls = 0;

    this.totalDatas.totalInvoiceVal = 0;
    this.totalDatas.totalTcsVal = 0;
    this.totalDatas.totalSplMrgnVal = 0;
    this.totalDatas.totalAretVal = 0;
    this.totalDatas.totalNetInvVal = 0;
    this.totalDatas.totalEdVal = 0;
    this.totalDatas.totalVatVal = 0;
    this.totalDatas.totalAsstFeeVal = 0;
    this.totalDatas.totalAetVal = 0;
    this.totalDatas.totalAedVal= 0;
    this.totalDatas.totalPdVal= 0;
    this.totalDatas.totalRoSplMargin =0;
    this.totalDatas.totalaretVal =0;
    this.totalDatas.totalSplPrevFee = 0;


console.log(this.data.consignment[0].icdc_bar_data)
    if(this.data.consignment[0].icdc_bar_data && this.data.consignment[0].icdc_bar_data.length){
      this.data.consignment[0].icdc_bar_data.forEach((data:any)=>{
        this.totalDatas.totalInvoiceVal += 0;
        this.totalDatas.totalTcsVal += data.tcsIcdc ?  data.tcsIcdc :0;
        this.totalDatas.totalSplMrgnVal += data.splmarginIcdc ? data.splmarginIcdc :0;
        // this.totalDatas.totalAretVal += data.tatIcdc ? data.tatIcdc :0;
        this.totalDatas.totalNetInvVal += 0;
        this.totalDatas.totalEdVal += data.exciseDuty ? data.exciseDuty :0;
        this.totalDatas.totalVatVal += data.vat ? data.vat :0;
        this.totalDatas.totalAsstFeeVal += data.afee_amount ? data.afee_amount :0;
        this.totalDatas.totalAedVal += data.aed ? data.aed :0;
        this.totalDatas.totalAetVal += 0;
        this.totalDatas.totalaretVal += data.tatIcdc ? data.tatIcdc :0;
        this.totalDatas.totalRoSplMargin += data.totalRateOfSmP ? data.totalRateOfSmP : 0;
        this.totalDatas.totalPdVal += data.pdPrice ? data.pdPrice :0;
        this.totalDatas.totalSplPrevFee  += data.splFeeIcdc ? data.splFeeIcdc :0; 
      })
    }
    if(this.selectedIcdc && this.selectedIcdc.productDetails.length){
      // this.data.consignment.map(row => {
        this.totalDatas.beerBreakagesbottles = 0;
        this.selectedIcdc.productDetails.map(item => {
          // this.getCaseCount(item);
          this.util.getTotalScannedCasesBottles(item);
          // if (item.breakages && item.breakages.bottleBreakages) {
          //   this.getCaseCount(item);
          // } else {
          //   item.totalScannedCases = item.casesCount ? item.casesCount : 0;
          // }
          const productKeys = this.util.productCodeToDetails(item.productCode);
          if(item.breakages && item.breakages.cases){
            this.totalDatas.brkCases += item.breakages.cases;
          }
          if(item.breakages && item.breakages.bottles){
            this.totalDatas.brkBtls += item.breakages.bottles;
          }
          if (productKeys.liquorType === 'B') {
            this.totalDatas.beerApprovedCases += item.approvedCases ? item.approvedCases : 0;
            this.totalDatas.beerApprovedBottles += item.approvedBottles ? item.approvedBottles : 0;
            this.totalDatas.beerCases += item.totalScannedCases ? item.totalScannedCases : 0;
            this.totalDatas.beerBottles += item.totalScannedBottles ? item.totalScannedBottles : 0;
            this.totalDatas.beerBreakagesCases += item.breakages && item.breakages.cases ? parseInt(item.breakages.cases,10) : 0;
            this.totalDatas.beerBreakagesbottles +=  item.breakages && item.breakages.bottles ? parseInt(item.breakages.bottles,10): 0;
            this.totalDatas.totalShortageBeerCases += item.shortageCases ? item.shortageCases : 0;
            this.totalDatas.totalShortageBeerBtls += item.shortageBottles ? item.shortageBottles : 0;
          } else {
            this.totalDatas.imlApprovedCases += item.approvedCases ? item.approvedCases : 0;
            this.totalDatas.imlApprovedBottles += item.approvedBottles ? item.approvedBottles : 0;
            this.totalDatas.imlCases += item.totalScannedCases ? item.totalScannedCases : 0;
            this.totalDatas.imlBottles += item.totalScannedBottles ? item.totalScannedBottles : 0;
            this.totalDatas.totalShortageImlCases += item.shortageCases ? item.shortageCases : 0;
            this.totalDatas.totalShortageImlBottles += item.shortageBottles ? item.shortageBottles : 0;
            if (item.breakages && item.breakages.cases) {
              console.log('cases', item.breakages.cases);
              // this.totalDatas.imlBreakagesCases += item.breakages.cases ? item.breakages.cases : 0;
              this.totalDatas.totalImlBreakagesCases += item.breakages.cases ? item.breakages.cases : 0;
            }
            if (item.breakages && item.breakages.bottles) {
              console.log('Btls', item.breakages.bottles);
              this.totalDatas.totalImlBreakagesBottles += item.breakages.bottles ? item.breakages.bottles : 0;
              // this.totalDatas.imlBreakagesbottles += item.breakages.bottles ? item.breakages.bottles : 0;
            }
          }
          item.subTotal = ((item.totalScannedCases * item.unitsPerCase) + (item.totalScannedBottles ? item.totalScannedBottles : 0))
            * item?.retailPricings;

          item.barSubTotal = ((item.totalScannedCases * (item.issuePrice ? item.issuePrice : 0))) + ((item.totalScannedBottles ? item.totalScannedBottles : 0) * (+((item.issuePrice ? item.issuePrice : 0)/ item?.unitsPerCase).toFixed(2)));


          this.totalDatas.approvedCases += item.approvedCases ? item.approvedCases : 0;
          this.totalDatas.approvedBottles += item.approvedBottles ? item.approvedBottles : 0;
          this.totalDatas.casesCount += item.totalScannedCases ? item.totalScannedCases : 0;
          this.totalDatas.breakBtls += item.totalScannedBottles ? item.totalScannedBottles  : 0;
          this.totalDatas.InvCases += item.scannedCases ? item.scannedCases : 0;
          this.totalDatas.InvBtls += item.totalScannedBottles ? item.totalScannedBottles : 0;


          this.totalDatas.ImflCartoons = this.totalDatas.InvCases + this.totalDatas.brkCases;
          this.totalDatas.FlCartoons = this.totalDatas.InvBtls + this.totalDatas.brkBtls;
          this.totalDatas.CartoonsTtlCases = this.totalDatas.InvCases + this.totalDatas.InvCases
          + this.totalDatas.beerCases + this.totalDatas.imlCases;
          this.totalDatas.CartoonsTtlBtls = this.totalDatas.InvBtls + this.totalDatas.brkBtls
          + this.totalDatas.beerBottles + this.totalDatas.imlBottles;
          this.totalDatas.totalCartoonCases = (this.totalDatas.imlCases ? this.totalDatas.imlCases : 0) +
            (this.totalDatas.beerCases ? this.totalDatas.beerCases : 0) +
            (this.totalDatas.beerBreakagesCases ? this.totalDatas.beerBreakagesCases : 0) +
            (this.totalDatas.totalImlBreakagesCases ? this.totalDatas.totalImlBreakagesCases : 0);
          this.totalDatas.totalCartoonBottles = (this.totalDatas.imlBottles ? this.totalDatas.imlBottles : 0) +
            (this.totalDatas.beerBottles ? this.totalDatas.beerBottles : 0) +
            (this.totalDatas.beerBreakagesbottles ? this.totalDatas.beerBreakagesbottles : 0) +
            (this.totalDatas.totalImlBreakagesBottles ? this.totalDatas.totalImlBreakagesBottles : 0);
          this.totalDatas.subTotal += item.subTotal ? item.subTotal : 0;
          this.totalDatas.barSubTotal += item.barSubTotal ? item.barSubTotal : 0;


        });
        console.log(this.totalDatas);
      // });
    }
    this.checkIndent();
 
  }
  roundAMount(value){
    return Math.ceil(value)
  }
  convertToNumber(icdcData, taxes, total){
    const balanceAmount = (icdcData?.previousBalance + parseInt(icdcData?.transactionAmount))
     - (this.totalDatas.totalSplMrgnVal + this.totalDatas.totalaretVal+ total.barSubTotal + this.roundAMount(this.totalDatas.totalTcsVal) + this.roundAMount(this.totalDatas.totalSplPrevFee))
    return balanceAmount.toFixed(2);
  }
  getCaseCount(item){
    // if(item.breakages){
      // if(item.breakages.bottleBreakages){
        // this.util.getTotalScannedCasesBottles(item);
        // let unitsPerCase = this.util.productCodeToDetails(item.productCode).unitsPerCase
        // let bottleBreakagesLength = item.breakages.bottleBreakages.length; 
        // if (item.breakages.bottleBreakages.length > 1) {
        //   const caseCodes: any = item.breakages.bottleBreakages.map(el => el.caseCode);
        //   const isDuplicate: any = caseCodes.filter((c, idx) => caseCodes.indexOf(c) != idx);
        //   if (isDuplicate.length) {
        //     bottleBreakagesLength = bottleBreakagesLength - isDuplicate.length;
        //   }
        // }
        // let totalScannedBottles = (bottleBreakagesLength * unitsPerCase) - item.breakages.bottles;
        // item.totalScannedCases = item.casesCount + Math.floor(totalScannedBottles/unitsPerCase);
        // item.totalScannedBottles = totalScannedBottles % unitsPerCase;
      // }
    // }
    this.util.getTotalScannedCasesBottles(item);
    return item;
  }

  getSubTotal(challanAmt, prevBal){
    return (parseInt(challanAmt) + prevBal);
  }
  updateDamage(icdcCode, product): any {
    const req_Data: any = {
      action: 'add_manual_f2',
      data: {
        tpNo: this.data.tpNo,
        icdcCode: icdcCode,
        productCode: product.productCode,
        bottles: product.bottles,
        approvedCases: product.approvedCases,
        shipmentQty: product.approvedCases,
        casesCount: product.casesCount - Math.ceil(product.bottles / product.unitsPerCase)
      }
    };
    this.spinner.show();
    this.shipmentsService.f2Breakage(req_Data).subscribe((result: any) => {
      if (result && result.status && result.status === 'SUCCESS') {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'F2 Breakages created successfully' });
        this.getOfsDetails();
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'F2 Breakages creation failed' });
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server Error' });
    });
  }
  updateShortage(ofs, product): any {
    const req_Data: any = {
      action: 'add_f2_shortages',
      data: {
        tpNo: this.data.tpNo,
        icdcCode: ofs.icdcCode,
        productCode: product.productCode,
        approvedCases: product.approvedCases,
        shipmentQty: product.approvedCases,
        // shortageBottle: product.shortBottle,
        casesCount: product.casesCount - Math.ceil(product.shortBottle / product.unitsPerCase)
      }
    };
    if (product.shortBottle >= product.unitsPerCase) {
      req_Data.data.shortageCases = Math.floor(product.shortBottle / product.unitsPerCase);
      req_Data.data.shortageBottle = product.shortBottle - (req_Data.data.shortageCases * product.unitsPerCase);
    } else {
      req_Data.data.shortageBottle = product.shortBottle;
    }
    this.spinner.show();
    this.shipmentsService.f2Breakage(req_Data).subscribe((result: any) => {
      if (result && result.status && result.status === 'SUCCESS') {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'F2 Shortges updated successfully' });
        this.getOfsDetails();
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'F2 Shortges creation failed' });
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server Error' });
    });
  }
}
