import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-licensee-bank-details-dialog',
  templateUrl: './licensee-bank-details-dialog.component.html',
  styleUrls: ['./licensee-bank-details-dialog.component.scss']
})
export class LicenseeBankDetailsDialogComponent implements OnInit {
  bankDetails: any;
  displayedColumns: string[] = [
    'bankName',
    'accountHolderName',
    'accountNumber',
    'ifcCode',
    'branch',
    'town'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;

  constructor(public dialogRef: MatDialogRef<LicenseeBankDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data.get_shop_bank_details[0].bankDetails);
  }


  dialogClose(): void {
    this.dialogRef.close();
  }




}
