import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DistilleryInfoService } from 'src/app/core/services/distillery-info.service';
import { InputValidator } from 'src/app/core/utils/input.validator';
import { liquorTypes, spiritTypes } from 'src/app/core/constants/liquor-type.constants';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-manage-blend-tank-dialog',
  templateUrl: './manage-blend-tank-dialog.component.html',
  styleUrls: ['./manage-blend-tank-dialog.component.scss'],
})
export class ManageBlendTankDialogComponent implements OnInit, OnChanges {
  @Input() featuresData: any;

  blendTankForm: FormGroup;
  error: string;
  saveDisabled: boolean;
  statuses: string[];
  tankTypes: string[];
  liquorTypes = liquorTypes;
  spiritTypes = spiritTypes;
  purposeList = [];
  enableCreate: boolean

  todayDate = new Date();
  entityId: string;
  selectedTank:any;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageBlendTankDialogComponent>, public util: UtilService,private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data, private distilleryInfoService: DistilleryInfoService) { }

  ngOnInit(): void {
    this.entityId = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'))
    this.entityId = this.entityId['entity_id'].split('-')[0];
    this.statuses = ['Empty', 'Maintenance', 'Damaged'];
    this.tankTypes = ['Blend Tank', 'Storage Tank'];
    this.saveDisabled = true;

    this.blendTankForm = this.fb.group({
      tankName: [this.entityId + '-Tank-', [Validators.required]],
      tankType: ['' , [Validators.required]],
      purpose: ['' , [Validators.required]],
      capacity: ['', [Validators.required, Validators.min(0)]],
      status: ['',]
    });

    if (this.data) {
      /*  this.blendTankForm = this.fb.group({
         tankName: [{value: this.data.tankName, disabled: true}],
         tankType: [''],
         purpose: [''],
         capacity: [''],
         status: ['']
       }) */
      this.blendTankForm.patchValue({
        tankName: this.data.tankName,
        tankType: this.data.tankType,
        purpose: this.data.purpose,
        capacity: this.data.capacity,
        status: this.data.status,
      });
      this.blendTankForm.controls['tankName'].disable();
      this.getPurposeList({ value: this.data.tankType })
      this.checkSaveDisabled(this.data);
      if (this.data.status && this.data.status === 'InUse') {
        this.blendTankForm.disable();
      }
    }
    this.blendTankForm.valueChanges.subscribe((data) => {
      this.checkSaveDisabled(data);
    });
  }

  ngOnChanges(): void {

  }

  checkSaveDisabled(data) {
    const emptyValues = [];
    Object.keys(data).forEach(val => {
      if (val === 'tankName' && !data[val].split('-')[2]) {
        emptyValues.push(val);
      }
      else if (val !== 'purpose' && val !== 'status' && !data[val]) {
        emptyValues.push(val);
      }
    });
    this.saveDisabled = !!emptyValues.length;
  }


  get f() {
    return this.blendTankForm.controls;
  }

  // avoid spacing while adding
  tankFormat(event) {
    const defaultTankName = this.entityId + '-Tank-'
    if (event.code === "Backspace" || event.code === "Delete" || event.target.value ==this.entityId + '-Tank-0' ) {
      this.blendTankForm.patchValue({
        tankName: defaultTankName
      })
      event.preventDefault();
    }
    // else if (event.code.indexOf("Num") == -1 && event.code.indexOf("Digit") == -1 && event.code !== "Tab") {
    //   event.preventDefault();
    // }

  }



  createBlendTanks() {
    this.saveDisabled = true;
    // this.supplier.blendTankInfo.push(this.blendTanksForm.value);
    let btObj = this.blendTankForm.value;
    delete btObj.status;
    btObj['capacity'] = Number(btObj['capacity']);
    const req_Data = {
      action: 'add_tank',
      data: btObj
    }
    // console.log(req_Data.data);
    if(req_Data.data.capacity < 100){
      this.dialogRef.disableClose = true;
      this.messageService.add({severity:'error', summary: 'Error', detail: 'Capacity should be atleat 100 Liters'});
    }else{
      this.distilleryInfoService.createBlendTank(req_Data).subscribe((res: any) => {
        // console.log(res);
        if(res.action_resp){
          this.dialogRef.close(`${this.selectedTank} Created Successfully`);
        } else if(res.status) {
          this.messageService.add({severity:'error', summary: 'Error', detail: res.status});
        }
        if(res.tank){
          this.dialogRef.disableClose = true;
          this.messageService.add({severity:'error', summary: 'Error', detail: res.tank});
        }
        
      }, (err) => {
        this.error = err.error.message;
      });
    }

  }

  updateBlendTank() {
    this.saveDisabled = true;
    const obj = {
      action: 'edit_tank',
      data: this.blendTankForm.value

    };

    obj.data['tankName'] = this.data.tankName;
    this.distilleryInfoService.putBlendTank(obj).subscribe((res: any) => {
      // console.log(res);
      if(res.response){
        this.dialogRef.close(`${this.selectedTank} Updated Successfully`);
      }
    
        // this.dialogRef.close('Blend Tank Updated Successfully');
      
     
    }, (err) => {
      this.error = err.error.message;
    });
  }

  getPurposeList(event) {
    if (event.value === 'Blend Tank') {
      this.purposeList = Object.keys(this.liquorTypes).map(l => {
        return this.liquorTypes[l];
      })
    }
    if (event.value === 'Storage Tank') {
      this.purposeList = Object.keys(this.spiritTypes).map(l => {
        return this.spiritTypes[l];
      })
    }
    this.selectedTank = event.value;
    // console.log(this.selectedTank);
    // this.blendTankForm.controls.purpose.reset();
  }
  // getTitle() {
  //   return this.data && this.data._id ? `Update Blend Tank for ${this.data.tankName}` : 'Create Blend Tank';
  // }
}
