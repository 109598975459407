import { MatCardModule } from '@angular/material/card';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { ChipsModule } from 'primeng/chips';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import {MatRadioModule} from '@angular/material/radio';
import { ToastModule } from 'primeng/toast';
import { HealCodesDialogComponent } from './commons/heal-codes-dialog/heal-codes-dialog.component';
import { HeaderComponent } from './components/header/header.component';
import { ManageProfileComponent } from './components/header/manage-profile/manage-profile.component';
import { SelectSearchInfiniteScrollComponent } from './components/select-search-infinite-scroll/select-search-infinite-scroll.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { StaticTableComponent } from './components/static-table/static-table.component';
import { StatusDetailsDialogComponent } from './commons/status-details-dialog/status-details-dialog.component';
import { CurrencyDirective } from './utils/currency.directive';
import { OtpVerificationDialogComponent } from './components/otp-verification-dialog/otp-verification-dialog.component';
import { OpenActionUpdateIndentDialogComponent } from '../features/shipments/updated-indent/open-update-indent-dialog/open-action-update-indent-dialog.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { PaymentConfomationDialogComponent } from '../features/excise-duty/payment-confomation-dialog/payment-confomation-dialog.component';
import { RetailerTableComponent } from './components/retailer-table/retailer-table.component';
import { DynamicConfirmationDialogComponent } from './components/dynamic-confirmation-dialog/dynamic-confirmation-dialog.component';
import { UserinfoRetailersDialogComponent } from './components/retailer-table/userinfo-retailers-dialog/userinfo-retailers-dialog.component';
import { AddChallanComponent } from './components/add-challan/add-challan.component';
import { NewUpdatesDialogComponent } from './components/new-updates-dialog/new-updates-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { ManageRetailerReshuffingComponent } from '../features/user-accounts/manage-retailer-reshuffing/manage-retailer-reshuffing.component';

@NgModule({
  declarations: [HeaderComponent, SidebarMenuComponent, StatusDetailsDialogComponent, OpenActionUpdateIndentDialogComponent, SelectSearchInfiniteScrollComponent, ManageProfileComponent, HealCodesDialogComponent, StaticTableComponent, CurrencyDirective, OtpVerificationDialogComponent,PaymentConfomationDialogComponent, RetailerTableComponent, ManageRetailerReshuffingComponent, DynamicConfirmationDialogComponent, UserinfoRetailersDialogComponent,AddChallanComponent, NewUpdatesDialogComponent,FooterComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    ChipsModule,
    MatPaginatorModule,
    InputTextModule,
    RouterModule,
    MatTableModule,
    MatExpansionModule,
    MatTabsModule,
    NgxSpinnerModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatIconModule,
    MatTooltipModule,
    ToastModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDialogModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    MatSelectInfiniteScrollModule,
    MatRadioModule,
    MenuModule,
    MatCardModule,
  ],
  exports: [
    HeaderComponent,
    SidebarMenuComponent,
    SelectSearchInfiniteScrollComponent,
    StaticTableComponent,
    StatusDetailsDialogComponent,
    CurrencyDirective,
    RetailerTableComponent,
    ManageRetailerReshuffingComponent,
    DynamicConfirmationDialogComponent,
    AddChallanComponent,
    NewUpdatesDialogComponent,
    DatePipe,
    FooterComponent
  ],
  providers: [CurrencyPipe, DatePipe]
})
export class CoreModule { }