import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const baseUrl = environment.baseUrl;
const breakagesUrl = baseUrl + 'breakage';
const f3BreakagesUrl = baseUrl + 'stock_management';
const storageUrl = baseUrl + 'storage';
const transitUrl = baseUrl + 'transit';
const breakageVerificationUrl = baseUrl + 'breakageVerification';
const caseAlreadyScannedUrl = baseUrl + 'casealreadyscanned';
const shortagesUrl = baseUrl + 'shortages';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Breakage'] && userModules.mod_feats['Breakage'].features ? userModules.mod_feats['Breakage'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class BreakagesService {
  currentUser: any;

  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(
      sessionStorage.getItem('lappCurrentUserDetails')
    );
  }

  getBreakages(offset, limit, damageType, search?: string): Observable < any > {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? (paramObj.limit = limit) : (limit = null);
    search ? (paramObj.search = search) : (search = null);
    damageType ? (paramObj.damageType = damageType) : (damageType = null);
    return this.http.get(breakagesUrl, {params: paramObj});
  }

  createBreakages(breakageObj) {
    return this.http.put(breakagesUrl, breakageObj);
  }

  createRetailerBreakages(breakageObj) {
    return this.http.put(f3BreakagesUrl, breakageObj);
  }

  getBreakageDetails(offset, limit, search) {
    return this.http.get(breakagesUrl);
  }

  getShortagesDetails(offset, limit, search) {
    return this.http.get(shortagesUrl);
  }

  getStorageDetails(offset, limit, search) {
    return this.http.get('assets/samples/storage.json');
  }

  getTransitDetails(offset, limit, search) {
    return this.http.get('assets/samples/transit.json');
  }

  getBreakageVerificationDetails(offset, limit, search) {
    return this.http.get('assets/samples/breakage-verification.json');
  }

  getCaseAlreadyScannedDetails(q, action) {
    const paramObj: any = {};
    q ? (paramObj.q = q) : (q = null);
    action ? (paramObj.action = action) : (action = null);
    return this.http.get(breakagesUrl, {params: paramObj});
  }

  getDepot(offset, limit, search) {
    return this.http.get('assets/samples/depot.json');
  }

  getDistillery(offset, limit, search) {
    return this.http.get('assets/samples/distillery.json');
  }

  getBreakageFeatures(paramObj: any = '') {
    const headerDict = {
      feature
    };
    return this.http.get(breakagesUrl, { headers: headerDict, params: paramObj });
  }
    getBreakagesFeatures(paramObj: any = '') {
    const headerDict = {
      feature
    };
    return this.http.get(breakagesUrl, { headers: headerDict, params: paramObj });
  }
}
