import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExciseDutyService } from '../../../../core/services/excise-duty.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { CommonsService } from 'src/app/core/services/commons.service';


@Component({
  selector: 'app-manage-remitance-dialog',
  templateUrl: './manage-remitance-dialog.component.html',
  styleUrls: ['./manage-remitance-dialog.component.scss']
})
export class ManageRemitanceDialogComponent implements OnInit {

  remitanceForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  description: string;
  challanNumber: string;
  transactionNumber:string;
  depositAmount: string;
  bankName: string;
  challanType: string;
  transactionDate: string;
  transactionDetails: any;
  imgName: any;
  selectedImage: string;
  sources = ['Source', 'Distillery', 'Location'];
  challanTypes = ['CSDs', 'Local', 'Breakage'];
  loader: any = false;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ManageRemitanceDialogComponent>,
    private exciseDutyService: ExciseDutyService,
    private spinner: NgxSpinnerService,
    private commonService: CommonsService,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.saveDisabled = true;
    this.remitanceForm = this.fb.group({
      transactionNumber: ['',Validators.required],
      challanNumber: ['',Validators.required],
      depositAmount: ['',Validators.required],
      bankName: ['',Validators.required],
      challanType: ['',Validators.required],
      transactionDate: ['',Validators.required],
    });
    if (this.data && this.data._id) {
      this.remitanceForm.patchValue({
        transactionNumber: this.data.cfmsTransId,
        challanNumber: this.data.challanNumber,
        depositAmount: this.data.depositAmount,
        bankName: this.data.bankName,
        challanType: this.data.challanType,
        transactionDate: this.data.transactionDate,
      });
    }
  }

  get f() {
    return this.remitanceForm.controls;
  }

  createRemitance() {
    this.saveDisabled = true;
    const req_data = {
      action: 'add_remittance',
      data: { ...this.remitanceForm.value
        // amountDeposited:JSON.parse(this.transactionDetails['amountDeposited'])
       },
      
      
    };
    this.loader = true;
    this.exciseDutyService.createRemitance(req_data).subscribe((res: any) => {
      this.loader = false;
      this.dialogRef.close('Remittance added successfully');
    }, (err) => {
      this.loader = false;
      this.error = err.error.message;
    });
  }

  // getRemitanceTransactionDetails(event, data) {
  //   if (data.length) {
  //     event.stopPropagation();
  //     this.spinner.show();
  //     let qdata: any;
  //     qdata = {
  //       "cfms_txn_id": data
  //     }
  //     if (event && event.target.getAttribute('formcontrolname') === 'challanNumber') {
  //       qdata = {
  //         "challan_id": data
  //       }
  //     }
  //     try {
  //       this.exciseDutyService.getTransactionDetails(JSON.stringify(qdata), 'validate_challan_txn').subscribe((res: any) => {
  //         if (res) {
  //           console.log(res);
  //           const errMsg: any = this.getErrorMessage(qdata);
  //           if (res.error) {
  //             this.messageService.add({ severity: 'error', summary: 'Error Message', detail: errMsg });
  //           }
  //           if (res.status) {
  //             this.saveDisabled = true;
  //             this.remitanceForm.patchValue({
  //               transactionNumber:'',
  //               challanNumber: '',
  //               depositAmount: '',
  //               bankName: '',
  //               challanType: '',
  //               transactionDate: '',
  //               description: '',
  //             });
  //             this.spinner.hide();
  //             return;
  //           }
  //           delete res.headers;
  //           this.transactionDetails = res;
  //           this.remitanceForm.patchValue({
  //             transactionNumber:res.cfmsTransId,
  //             challanNumber: res.challanNo,
  //             depositAmount: res.amountDeposited,
  //             bankName: res.bankRefNum,
  //             challanType: res.challanType,
  //             transactionDate: res.paymentDate,
  //             description: res.statusDesc,
  //           });
  //           this.saveDisabled = false;
  //         }
  //         this.spinner.hide();
  //       });
  //     } catch (error) {
  //       this.spinner.hide();
  //     }
  //   }

  // }

  checkDuplicateChallans(event){
    this.exciseDutyService.getExciseDutyFeatures().subscribe(res => {
      this.challanNumber =  event.target.value;
      const duplicateTransaction= res['Remittance'].some(challanRecord => challanRecord.transactionNo === event.target.value);
      if(duplicateTransaction){
        const error = `Transaction Number already Used, Please enter the correct transaction number`;
        this.messageService.add({ severity: 'error', detail: error });
      }else{
        this.getRemitanceTransactionDetails(event);
      }
    })
  }

  getRemitanceTransactionDetails(event) {
    
    let qData = {
      "data": { "value": event.target.value, "action": "get_exciseduty_payment_details" }
    };
    const req_Data = {
      "action": "common_apis",
      "q": JSON.stringify(qData)
    };

    this.commonService.getCommonEntites(req_Data).subscribe((res) => {
      this.transactionDetails = res['get_exciseduty_payment_details'];
      if(this.transactionDetails){
        this.remitanceForm.patchValue({
          challanNumber: this.challanNumber,
          depositAmount: this.transactionDetails.amount,
          bankName: 'Bank Of Baroda', //this.transactionDetails.bankName,
          challanType: this.transactionDetails.purpose,
          transactionDate: this.transactionDetails.bankTransactionDate,
        });
      }else{
        this.messageService.add({ severity: 'error', detail: 'Invalid Transation Number.Please enter a valid one' });

      }
    });
  }

  updateRemitance() {
    // this.saveDisabled = true;
    // const obj = [{
    //   _id: this.data._id,
    //   update: this.remitanceForm.value
    // }]
    // this.spiritService.putBatch(obj).subscribe((res: any) => {
    //   this.dialogRef.close('Batch Udated Successfully');
    // }, (err) => {
    //   this.error = err.error.message;
    // });
  }
  getErrorMessage(qData): any {
    let msg: any = '';
    if (qData.cfms_txn_id) {
      msg = `Transaction Number wrongly entered, Please enter the correct transaction number`;
    } else if (qData.challan_id){
      msg = `Challan Number wrongly entered, Please enter the correct challan number`;
    }
    return msg;
  }


}
