import { FinishedGoods } from 'src/app/core/models/lab/finished-goods.model';
import { FailedSamples } from './../models/lab/failed-samples.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Samples } from '../models/lab/samples.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


const baseUrl = environment.baseUrl;
const labUrl = baseUrl + 'lab';

const samplesUrl = baseUrl + 'labsamples';
const failedSamplesUrl = baseUrl + 'labsamples';
const finishedGoodsUrl = baseUrl + 'labsamples';
const labReportsUrl = baseUrl + 'labreports';
const sampleUrl =  baseUrl + 'lab';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Lab'] && userModules.mod_feats['Lab'].features ? userModules.mod_feats['Lab'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})

export class LabService {

  constructor(private http: HttpClient) { }

  getSampleTypes(paramsObj): Observable<any>{
    return this.http.get(labUrl,{params: paramsObj});
  }
  getSamplesDetails(offset, limit, search) {
    return this.http.get('assets/samples/samples.json');
  }

  createSamplesDetails(obj: any){
    return this.http.post(labUrl, obj);
  }
  updateSamplesDetails(obj: any){
    return this.http.put(labUrl, obj);
  }

  updatelabStatus(obj: any) {
    return this.http.put(labUrl, obj);
  }

  getFailedSamplesDetails(offset, limit, search) {
    return this.http.get(failedSamplesUrl);
  }

  createFailedSamplesDetails(obj: FailedSamples){
    return this.http.post(failedSamplesUrl, obj);
  }

  getFinishedGoodsDetails(offset, limit, search) {
    return this.http.get('assets/samples/finished-goods.json');
  }

  createFinishedGoodsDetails(obj: any){
    return this.http.post(labUrl, obj);
  }

  batchDeactivationStatus(paramsObj) : Observable<any> {
    return this.http.post(labUrl , paramsObj);
  }

  getLabReportsDetails(paramObj): Observable<any>{
    return this.http.get(labUrl+paramObj);
  }

  /* getLabReportsDetails(offset, limit, search?: string, status?: string): Observable<any>{
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? paramObj.limit = limit : limit = null;
    search ? paramObj.search = search : search = null;
    status ? paramObj.status = status : status = null;
    return this.http.get('assets/samples/lab-reports.json');
  } */

  getBatchDeactivationDetails(offset, limit, search) {
    return this.http.get('assets/samples/batch-deactivation.json');
  }

  getLabFeatures() {
    const headerDict = {
      feature
    };
    return this.http.get(labUrl, { headers: headerDict });
  }

  getLabFeaturesData(paramObj: any = '') {
    const headerDict = {
      feature
    };
    return this.http.get(labUrl, { headers: headerDict, params: paramObj });
  }


  updatelabSampleStatus(obj: any) {
    return this.http.put(sampleUrl, obj);
  }
}
