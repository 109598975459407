import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IndentManagementService } from 'src/app/core/services/indent-management.service';
import { StockService } from 'src/app/core/services/stock.service';

@Component({
  selector: 'app-add-event-permit-dialog',
  templateUrl: './add-event-permit-dialog.component.html',
  styleUrls: ['./add-event-permit-dialog.component.scss']
})
export class AddEventPermitDialogComponent implements OnInit {

  eventPermitForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<AddEventPermitDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private indentManagementService: IndentManagementService) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.eventPermitForm = this.fb.group({
      eventPermit: ['', [Validators.required, ]],
    });
    if (this.data && this.data._id) {
      this.eventPermitForm.patchValue({
        eventPermit : this.data.eventPermit,
      });
    }
    this.eventPermitForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
  }

  get f(){
    return this.eventPermitForm.controls;
  }

  createEventPermit() {
    this.saveDisabled = true;
    const req_Data = {
      action : '',
      data : this.eventPermitForm.value
    }
    this.indentManagementService.createEventPermit(req_Data).subscribe((res: any) => {
      this.dialogRef.close('Batch Created Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  // updateEventPermit() {
  //   this.saveDisabled = true;
  //   const obj = [{
  //     _id: this.data._id,
  //     update: this.eventPermitForm.value
  //   }]
  //   this.spiritService.putBatch(obj).subscribe((res: any) => {
  //     this.dialogRef.close('Batch Udated Successfully');
  //   }, (err) => {
  //     this.error = err.error.message;
  //   });
  // }

}
