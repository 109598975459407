import { UtilService } from 'src/app/core/utils/utility-helper';
import { Component, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgxSpinnerService } from 'ngx-spinner';

declare const jsPDF: any;
declare const html2canvas: any;

@Component({
  selector: 'app-get-challan-details',
  templateUrl: './get-challan-details.component.html',
  styleUrls: ['./get-challan-details.component.scss']
})
export class GetChallanDetailsComponent {
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public util: UtilService,
    public dialogRef: MatDialog,
    private spinner: NgxSpinnerService
  ) { }

  print(): any {
    this.spinner.show();
    const thiss = this;
    setTimeout(() => {
      if (document.getElementById('print-section')) {
        let DATA: any = document.getElementById('print-section');
        html2canvas(DATA, { scale: 5 }).then((canvas: any) => {
          let fileWidth = 208;
          let fileHeight = (canvas.height * fileWidth) / canvas.width;
          const FILEURI = canvas.toDataURL('image/jpeg');
          let doc = new jsPDF('p', 'mm', 'a4');
          let position = 2;
          doc.addImage(FILEURI, 'JPEG', 1, position, fileWidth, fileHeight);
          doc.save(`challan-details-${thiss.data.challanId}.pdf`);
          thiss.spinner.hide();
        });
      }
    }, 500);
  }
}
