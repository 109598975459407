import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-icdc-reason-dialog',
  templateUrl: './icdc-reason-dialog.component.html',
  styleUrls: ['./icdc-reason-dialog.component.scss']
})
export class IcdcReasonDialogComponent implements OnInit {


  constructor( public dialogRef: MatDialogRef<IcdcReasonDialogComponent>, @Inject(MAT_DIALOG_DATA)public data) { }


  ngOnInit(): void { }


  dialogClose(): void{
    this.dialogRef.close();
  }

}
