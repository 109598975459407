import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BrandCodes } from '../models/products/brand-codes.model';
import { ProductPrice } from '../models/products/product-price.model';
import { RationValue } from '../models/products/ration-value.model';

const baseUrl = environment.baseUrl;
const brandCodesUrl = baseUrl + 'products/brandcodes';
const productPriceUrl = baseUrl + 'production/products';
const rationValueUrl = baseUrl + 'depotstock/ration';
const productsUrl = baseUrl + 'products'

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Products'] && userModules.mod_feats['Products'].features ? userModules.mod_feats['Products'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  currentUser: any;

  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(
      sessionStorage.getItem('lappCurrentUserDetails')
    );
  }
  createBrandCodes(brandCodesobj: BrandCodes) {
    return this.http.post(brandCodesUrl, brandCodesobj);
  }
  getbrandCodes(offset, limit): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);

    limit ? (paramObj.limit = limit) : (limit = null);
    return this.http.get(brandCodesUrl, {params: paramObj});
  }

 /*  addBrandCode(req_Data): Observable<any> {

  } */

  addBrandPacking(req_Data): Observable<any> {
    return this.http.post(productsUrl, req_Data);
  }

  editBrandPacking(req_Data): Observable<any> {
    return this.http.put(productsUrl, req_Data);
  }

  getbrandCodesDetails(q, action): Observable<any> {
    const paramObj: any = {};
    q ? (paramObj.q = q) : (q = null);
    action ? (paramObj.action = action) : (action = null);
    return this.http.get(productsUrl, {params: paramObj});
  }

  putbrandCodes(brandCodesObj) {
    return this.http.put<BrandCodes>(`${productsUrl}`, brandCodesObj);
  }

  putAPi(brandCodesObj) {
    return this.http.put(`${productsUrl}`, brandCodesObj);
  }

  createProductPrice(productPriceobj: ProductPrice) {
    return this.http.post(productPriceUrl, productPriceobj);
  }
  getProductPrice(offset, limit, search?:string): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);

    limit ? (paramObj.limit = limit) : (limit = null);
    search ? (paramObj.search = search) : (search = null);

    return this.http.get(productPriceUrl, {params: paramObj});
  }

  putProductPrice(productPriceObj) {
    return this.http.put<ProductPrice>(`${productPriceUrl}`, productPriceObj);
  }

  createRationValue(rationValueobj: any) {
    return this.http.post(rationValueUrl, rationValueobj);
  }
  getRationValue(offset, limit, search?: string): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? (paramObj.limit = limit) : (limit = null);
    search ? (paramObj.search = search) : (search = null);
    return this.http.get(rationValueUrl, {params: paramObj});
  }

  putRationValue(rationValueObj) {
    return this.http.put<RationValue>(`${rationValueUrl}`, rationValueObj);
  }

  getProductsFeatures(){
    const headerDict = {
      feature
    };
    return this.http.get(productsUrl, { headers: headerDict });
  }

getApi(paramObj:any){
  // return this.http.get(productsUrl, data);

  const headerDict = {
    feature
  };
  // return this.http.get(shipmentUrl, { headers: headerDict });
  return this.http.get(productsUrl, { headers: headerDict, params: paramObj });
}
}
