import { LabService } from 'src/app/core/services/lab.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabInformation, Samples } from 'src/app/core/models/lab/samples.model';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { CommonsService } from 'src/app/core/services/commons.service';
import { OtpVerificationDialogComponent } from 'src/app/core/components/otp-verification-dialog/otp-verification-dialog.component';
import { SampleAnalysisService } from 'src/app/core/services/sample-analysis.service';

@Component({
  selector: 'app-manage-samples-dialog',
  templateUrl: './manage-samples-dialog.component.html',
  styleUrls: ['./manage-samples-dialog.component.scss']
})
export class ManageSamplesDialogComponent implements OnInit {

  // displayedColumns: string[] = [
  //   'select',
  //   'descriptionOfSample',
  //   'dtpNo/lotNo',
  //   'sampleQty',
  //   'senderSeal',
  //   'percentageAlcohol'
  //   ];
  // dataSource: MatTableDataSource<Samples>;
  // selection = new SelectionModel<Samples>(true, []);

  samplesForm: FormGroup;
  labInformation: FormArray;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  offset: number;
  search: string;
  sampleStatusList = ['Pass', 'Fail'];
  sampleTypes = ['ENA', 'RS', 'BLEND', 'HTS', 'MALT', 'GRAPE', 'CAB', 'HBS', 'MAIZE',
                 'JOWAR', 'BROKEN RICE', 'BAJRA', 'MOLASSES', 'CJS', 'Scotch Concentrate', 'Spirit','Matured Malt Spirit','Matured Grape Spirit',
                 'French Brandy Concentrate','Concentrate Alcoholic Beverage','Grape Spirit','Fresh Grape Spirit','Vatted Malt Spirit','Fresh Malt Sprit'];
  roleName: string;
  batchesList: any;

// image code needs start
imageSrc;
sellersPermitFile: any;
DriversLicenseFile: any;
InteriorPicFile: any;
ExteriorPicFile: any;
subleasedEntites: any;
//base64s
sellersPermitString: string;
DriversLicenseString: string;
InteriorPicString: string;
ExteriorPicString: string;
//json
finalJson = {};

currentId: number = 0;
  selectedSignature: string;
  senderSignature: any;
  items: any;
  index: any;
  imgName: any;
  tanksList: any;
  userInfo: any;
  selectedSupplier:any;
  loader: any = false;
  liquoreTypes:any=[];


// image code needs end

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageSamplesDialogComponent>, private commonService: CommonsService,
              @Inject(MAT_DIALOG_DATA) public data, private labService: LabService,private dialog: MatDialog,private sampleAnalysisService:SampleAnalysisService) {
                
                this.userInfo = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
                this.roleName = this.userInfo['role'];
                
               }

  ngOnInit(): void {
  //  this.getSampleTypeData();
    console.log('this.data', this.data);
    this.samplesForm = this.fb.group({
      supplierCode: '',
      date: ['', [Validators.required, ]],
      sampleType: ['', [Validators.required, ]],
      referenceNo: ['', [Validators.required, ]],
      sentThrough: ['', [Validators.required, ]],
      signatureOfHC: [''],
      labInformation: this.fb.array([this.updateLabInformation()])
    });
   /*  if (this.data ) {
      this.samplesForm.clearValidators();
      this.samplesForm.patchValue({
        date: this.data.date,
        sampleType: this.data.sampleType,
        referenceNo: this.data.referenceNo,
        sentThrough: this.data.sentThrough,
        signatureOfHC: this.data.signatureOfHC,
      });
      const labInformation = this.samplesForm.get('labInformation') as FormArray;
      labInformation.clear();
      this.data.labInformation.forEach(b => {
        labInformation.push(
          this.fb.group({
            checkedValue: b.checkedValue,
            descriptionOfSample: b.descriptionOfSample,
            dtpNoLotNo: b.dtpNoLotNo,
            sampleQuantity: b.sampleQuantity,
            sendersFacsimileSeal: b.sendersFacsimileSeal,
            percentageOfProofSpirit: b.percentageOfProofSpirit,
            sampleStatus: b.sampleStatus
          })
        );
      });
      this.samplesForm.disable()
    } */
    this.labInformation = this.samplesForm.get('labInformation') as FormArray;
    this.samplesForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
     this.setTanksList();
     // this.setBatchesList();
     this.setSubleasedEntites();
    
  }

  // setBatchesList(){
  //   const req_Data = {
  //     "action": "common_apis",
  //     "q": '{"data": ["get_all_batches"]}'
  //   };
  //   this.commonService.getCommonEntites(req_Data).subscribe((res) => {
  //       this.batchesList = res["get_all_batches"];
  //   })
  // }

  getBatchesListBySupplierCode(event){
    this.selectedSupplier =event.value;
    let action = this.userInfo.entity_type === 'Depot' ? 'get_batch_by_depo_id' :'get_batch_by_id'
    const req_Data = {
      "action": "common_apis",
      "q": '{"data": {"action":"'+action+'", "value":"' +event.value+'"}}'
    };
    this.commonService.getCommonEntites(req_Data).subscribe((res) => {
        this.batchesList = res[action];
    })
  }

  setSubleasedEntites(){
  let payLoad = this.roleName === 'Data Processing Officer' ? 'get_non_local_suppliers' : 'get_subleased_entites';   
     const quaries= {
      data:[payLoad]
    }
    const req_Data = {
      "action": "common_apis",
      "q": JSON.stringify(quaries)
    };
    this.commonService.getCommonEntites(req_Data).subscribe((res) => {
        this.subleasedEntites = res[payLoad];
    })
  }

  setTanksList() {
    // get_tanks_by_type 
    const req_Data = {
      "action": "common_apis",
      "q": '{"data": {"action": "get_tanks_by_type", "value": "Storage Tank"}}'
    };
    this.commonService.getCommonEntites(req_Data).subscribe((res) => {
        this.tanksList = res["get_tanks_by_type"];
    })
  }

  updateLabInformation(){
    return this.fb.group({
      // checkedValue: '',
      batchId: ['',Validators.required],
      tankName: [''],
      descriptionOfSample: ['', Validators.required],
      dtpNoLotNo: ['', Validators.required],
      sampleQuantity: [''],
      sendersFacsimileSeal: [''],
      percentageOfProofSpirit: ['', Validators.required],
      liquoreTypes:[[]],
      sampleStatus: [''],
      liquorType:['']
    });
  }

  public picked(event, items, index) {
    this.items = items;
    this.index = index;
   console.log(event,"134::::")

    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      // if (field == 1) {
       
        this.handleInputChange(file); //turn into base64
      // }
     /*  else if (field == 2) {
        this.DriversLicenseFile = file;
        this.handleInputChange(file); //turn into base64
      } */
     /*  else if (field == 3) {
        this.InteriorPicFile = file;
        this.handleInputChange(file); //turn into base64
      }
      else if (field == 4) {
        this.ExteriorPicFile = file;
        this.handleInputChange(file); //turn into base64

      } */
    }
    else {
      alert("No file selected");
    }
  }

  handleInputChange(files) {
    var file = files;
    this.imgName = file.name;
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onloadend = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    var base64result = reader.result.substr(reader.result.indexOf(',') + 1);
    //this.imageSrc = base64result;
    // let id = this.currentId;
    
        if(this.items == 'single') {
          this.selectedSignature = "data:image/png;base64,"+ base64result;
          this.samplesForm.patchValue({
            'signatureOfHC': {name: this.imgName, source: this.selectedSignature}
          })
        }
        else {
          let labInformation = this.samplesForm.get('labInformation').value;
            labInformation[this.index]['sendersFacsimileSeal'] = {name: this.imgName, source: "data:image/png;base64,"+ base64result}
          console.log(labInformation,"184:::")
         this.samplesForm.patchValue({
          labInformation
         })
        }
      
      //  this.selectedSignature =  "data:image/png;base64,"+ this.sellersPermitString;
    }

  


  getSampleTypeData() {
    const req_Data = {action: 'get_samples_by_status',
                      q: {}
                    }
    this.labService.getSampleTypes(req_Data).subscribe( res => {
      console.log(res);
    }) 
  }
  // isAllSelected() {
  //   const numSelected = this.selection.selected.length;
  //   const numRows = this.dataSource.data.length;
  //   return numSelected === numRows;
  // }

  // removeRow() {
  //   this.selection.selected.forEach(item => {
  //     let index: number = this.data.findIndex(d => d === item);
  //     console.log(this.data.findIndex(d => d === item));
  //     this.data.splice(index,1)
  //   });
  // }

  // /** Selects all rows if they are not all selected; otherwise clear selection. */
  // masterToggle() {
  //   this.isAllSelected() ?
  //       this.selection.clear() :
  //       this.dataSource.data.forEach(row => this.selection.select(row));
  // }

  // getSamplesDetails() {
  //   this.labService.getSamplesDetails(this.offset, this.limit, this.search).subscribe ((res: any) => {
  //     console.log('res', res);
  // this.dataSource = new MatTableDataSource(res.data);
  //   });
  // }

  approveLab(testStatus) {
    // const req_otp ={
    //   action: "send_otp",
    //   data:{
    //    ...this.data
    //   }
    // }
    
    // this.commonService.sendVerifyOtp(req_otp).subscribe((res:any)=>{
      
      
    // })
    // const dialogRef = this.dialog.open(OtpVerificationDialogComponent, {
    //   width: '25%',
    //   height:'45%',
    //   autoFocus: false
    // });

    // dialogRef.afterClosed().subscribe((result) => {
    //   if(result.message == 'SUCCESS'){
        const req_Data = {
          action : 'approve_sample',
          data: {ceCRNo: this.data.ceCRNo, testStatus}
        }
        if(!this.data) {
          this.samplesForm.get('labInformation').value.forEach(res => {
            delete res.sampleStatus
          } )
        }
       /*  const req_Data = {
          action : 'approve_sample',
          data: {data: this.samplesForm, testStatus}
        } */
        this.loader = true;
        this.labService.updatelabStatus(req_Data).subscribe(res => {
          if(res['response'] != "None") {
            this.dialogRef.close('Samples Status Updated Successfully');
            this.loader = false;

          }
        })
    //   }
    // });


  }

  removeImg(e,i) {
    console.log(i,e, "285::::")
e.preventDefault()
  }

  addUnit() {
    const control = <FormArray>this.samplesForm.controls["labInformation"];
    control.push(this.updateLabInformation());
    this.setValidators(this.samplesForm.get('sampleType').value);
  }


  removeUnit(i: number) {
    const control = <FormArray>this.samplesForm.controls["labInformation"];
    control.removeAt(i);
  }



  get f(){
    return this.samplesForm.controls;
  }

  createSamples() {
    this.saveDisabled = true;
    let exciseLab;
    let formattedLabInformation = this.samplesForm.value.labInformation.map(labInfo =>({
      ...labInfo,
      // brandName: this.batchesList.find(batch => batch.batchId === labInfo.batchId).brandCode
    }))
    const req_Data = {
      action : 'add_sample',
      data : {
        ...this.samplesForm.value,
        labInformation: formattedLabInformation
      }
    };
    if(this.roleName === 'Data Processing Officer'){
      if(this.subleasedEntites && this.subleasedEntites.length){
        this.subleasedEntites.forEach((item:any)=>{
          if(item.supplierCode === this.selectedSupplier){
            exciseLab = item.locationInfo.exciseChemicalLab;
          }
        })
      }
    }
    req_Data.data.labInformation.forEach(res => {
      delete res.checkedValue
      delete res.liquoreTypes
  })
  if(this.roleName === 'Data Processing Officer'){
    req_Data.data['exciseLab'] = exciseLab  
  }
    req_Data.data['ceCRNo'] = JSON.stringify(Math.floor(Math.random() * 100000000));
  console.log(req_Data)
  // req_Data.data['attachments'] = {"fileInfo": "", "reportDate" : new Date()}
  this.loader = true;
    const apiCall =  this.roleName === 'Data Processing Officer' ?  this.sampleAnalysisService.createSamplesDetails(req_Data) : this.labService.createSamplesDetails(req_Data)
      apiCall.subscribe((res: any) => {
      if(res.status==='SUCCESS'){
        this.dialogRef.close('Samples Created Successfully');
        this.loader = false;
      }
    }, (err) => {
      this.error = err.error.message;
      this.loader = false;
    });
  }

  dialogClose(): void{
    this.dialogRef.close();
  }
  getBatchDetails(data, i): any {
    if (data) {
      const labInfoGroup = this.labInformation.at(i) as FormGroup;
    
      // tslint:disable-next-line:no-string-literal
      this.samplesForm.get('labInformation')['controls'][i].controls.descriptionOfSample.setValue(data.brandCode);
      // this.samplesForm.get('labInformation')['controls'][i].controls.liquorType.setValue(data.liquorType);
      labInfoGroup.get('liquoreTypes').setValue(this.getLiquoreTypesByType(data.liquorType));

    }
  }

  getLiquoreTypesByType(liquorType: string): string[] {
    switch (liquorType) {
      case 'WHISKY':
        return ['Malt/Grain Whisky', 'Blended Malt/Grain Whisky', 'Whisky'];
      case 'BRANDY':
        return ['Grape Brandy', 'Blended Grape Brandy', 'Brandy'];
      case 'RUM':
        return ['Rum', 'White Rum', 'Premix Rum'];
      case 'GIN':
        return ['Gin', 'Premix Gin'];
      case 'VODKA':
        return ['Vodka', 'Premix Vodka'];
      default:
        return [];
    }
  }
  selectedLiquore(e,i){
   
    const liquorType = e.value
    this.samplesForm.get('labInformation')['controls'][i].controls.liquorType.setValue(liquorType);
  }
  setValidators(evtVal: any): any {
    if (evtVal === 'BLEND') {
      // tslint:disable-next-line:no-string-literal
      this.samplesForm.get('labInformation')['controls'].forEach(res => {
          res.controls.dtpNoLotNo.setValue('');
          res.controls.dtpNoLotNo.clearValidators();
          res.controls.dtpNoLotNo.setValidators(null);
          res.controls.dtpNoLotNo.updateValueAndValidity();
          res.controls.batchId.setValidators([Validators.required]);
          res.controls.liquorType.setValidators([Validators.required]);
        }
      );
    } else {
      // tslint:disable-next-line:no-string-literal
      this.samplesForm.get('labInformation')['controls'].forEach(res => {
        res.controls.dtpNoLotNo.setValidators([Validators.required]);
        res.controls.batchId.setValue('');
        res.controls.batchId.clearValidators();
        res.controls.batchId.setValidators(null);
        res.controls.batchId.updateValueAndValidity();
      });
    }
  }
}
