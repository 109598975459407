import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HoldOnStock } from '../models/stock/hold-on-stock.model';
import { OutletReturnStock } from '../models/stock/outlet-return-stock.model';
import { DrainOut } from '../models/stock/drain-out.model';
import { BrandBarcode } from '../models/stock/brand-barcode.model';

const baseUrl = environment.baseUrl;
const stockUrl = baseUrl + 'stock';
const stockInventoryUrl = baseUrl + 'stock/stockinventory';
const holdOnStockUrl = baseUrl + 'depotstock/stockonhold';
const outletReturnStockUrl = baseUrl + 'outletreturnstock';
const drainOutUrl = baseUrl + 'drainout';
const stockReturnDrainOutUrl = baseUrl + 'stockreturndrainout';

const brandBarcodeUrl = baseUrl + 'brandBarcode';


const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Stock'] && userModules.mod_feats['Stock'].features ? userModules.mod_feats['Stock'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root',
})
export class StockService {
  currentUser: any;

  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(
      sessionStorage.getItem('lappCurrentUserDetails')
    );
  }

  getStockInventory(offset, limit, search?: string, divisionCode?: string): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? (paramObj.limit = limit) : (limit = null);
    search ? (paramObj.search = search) : (search = null);
    divisionCode ? (paramObj.divisionCode = divisionCode) : (divisionCode = null);
    return this.http.get(stockInventoryUrl, {params: paramObj});
  }

  createHoldOnStock(holdOnStockobj: any) {
    return this.http.post(holdOnStockUrl, holdOnStockobj);
  }
  getHoldOnStock(offset, limit, search?: any): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? (paramObj.limit = limit) : (limit = null);
    search ? (paramObj.search = search) : (search = null);
    return this.http.get(holdOnStockUrl, {params: paramObj});
  }

  putHoldOnStock(holdOnStockObj) {
    return this.http.put(`${stockUrl}`, holdOnStockObj);
  }
  createHoldOnStock1(holdOnStockObj) {
    return this.http.post(`${stockUrl}`, holdOnStockObj);
  }

  createOutletReturnStock(outletReturnStockobj: any) {
    return this.http.post(outletReturnStockUrl, outletReturnStockobj);
  }
  getOutletReturnStock(offset, limit, search?: any): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? (paramObj.limit = limit) : (limit = null);
    search ? (paramObj.search = search) : (search = null);
    return this.http.get(outletReturnStockUrl, {params: paramObj});
  }

  putOutletReturnStock(outletReturnStockObj) {
    return this.http.put<OutletReturnStock>(
      `${outletReturnStockUrl}`,
      outletReturnStockObj
    );
  }

  createDrainOut(drainOutobj: any) {
    return this.http.post(drainOutUrl, drainOutobj);
  }
  getDrainOut(offset, limit, search?: any): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? (paramObj.limit = limit) : (limit = null);
    search ? (paramObj.search = search) : (search = null);
    return this.http.get(drainOutUrl, {params: paramObj});
  }

  putDrainOut(drainOutObj) {
    return this.http.put<DrainOut>(`${drainOutUrl}`, drainOutObj);
  }

  getStockDetails(offset, limit, search) {
    return this.http.get('assets/samples/stock.json');
  }

  getLocalDetails(offset, limit, search) {
    return this.http.get('assets/samples/local.json');
  }

  getNonLocalDetails(offset, limit, search) {
    return this.http.get('assets/samples/non-local.json');
  }

  getComputedPriceListDetails(offset, limit, search) {
    return this.http.get('assets/samples/computed-price-list.json');
  }

  getBrandBarcodeDetails(offset, limit, search) {
    return this.http.get('assets/samples/brand-barcode.json');
  }

  createBrandBarcode(obj: any){
    return this.http.post(stockUrl, obj);
  }

  postMethod(obj: any){
    return this.http.post(stockUrl, obj);
  }

  getHoldOnStockDetails(offset, limit, search) {
    return this.http.get('assets/samples/hold-on-stock.json');
  }

  getOutletReturnStockDetails(offset, limit, search) {
    return this.http.get('assets/samples/outlet-return-stock.json');
  }

  getStockReturnDrainOut(offset, limit, search) {
    return this.http.get('assets/samples/stock-return-drain-out.json');
  }

  getCompletedStockReturn(offset, limit, search) {
    return this.http.get('assets/samples/completed-stock-return.json');
  }

  getCompletedStockDrainOut(offset, limit, search) {
    return this.http.get('assets/samples/completed-stock-drain-out.json');
  }

  getStockFeatures(paramObj: any = '') {
    const headerDict = {
      feature
    };
    return this.http.get(stockUrl, { headers: headerDict, params: paramObj });
  }


}
