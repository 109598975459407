import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-open-description-dialog',
  templateUrl: './open-description-dialog.component.html',
  styleUrls: ['./open-description-dialog.component.scss']
})
export class OpenDescriptionDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'no',
    'productCode',
    'brandName',
    'quantity',
    'quantityPart'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  constructor(public dialogRef: MatDialogRef<OpenDescriptionDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.dataSource = this.data.consignment? new MatTableDataSource(this.data.consignment[0].productDetails): new MatTableDataSource(this.data);
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

}
