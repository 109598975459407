import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


const baseUrl = environment.baseUrl;
const tpRevalidationUrl = baseUrl + 'tp_revalidation';
const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['TPRevalidation'] && userModules.mod_feats['TPRevalidation'].features ? userModules.mod_feats['TPRevalidation'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})

export class TpRevalidationService {

  constructor(private http: HttpClient) { }

  getTpRevalidation(offset, limit, search) {
    return this.http.get('assets/samples/tp-revalidation.json');
  }

  createTpRevalidation(search?: string): Observable < any >{
    const paramObj: any = {};
    search ? paramObj.search = search : search = null;
    return this.http.get(tpRevalidationUrl, {params: paramObj});
  }

  getTPRevalidationFeature(){
    const headerDict = {
      feature
    };
    return this.http.get(tpRevalidationUrl, { headers: headerDict });
  }

  getStatus(q, action){
    const paramObj: any = {};
    q ? (paramObj.q = q) : (q = null);
    action ? (paramObj.action = action) : (action = null);
    return this.http.get(tpRevalidationUrl, {params: paramObj});
  }

  revalidatePermit(permitObj) {
    return this.http.put(tpRevalidationUrl, permitObj);
  }


}
