import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { ImportService } from 'src/app/core/services/import.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-manage-pastors-location',
  templateUrl: './manage-pastors-location.component.html',
  styleUrls: ['./manage-pastors-location.component.scss']
})
export class ManagePastorsLocationComponent implements OnInit {
  locationForm:FormGroup;
  locationsList:any=[];
  checkDuplicate:any = false;
  loader: any = false;
  @Output() refreshPage = new EventEmitter<boolean>();


  constructor(private fb:FormBuilder,public util:UtilService,private importService:ImportService,
    private messageService:MessageService,private dialogRef: MatDialogRef<ManagePastorsLocationComponent>) { }

  ngOnInit(): void {
    this.locationForm = this.fb.group({
      locationType:['Pastors',[Validators.required]],
      locationID:['',[Validators.required]],
      locationName:['',[Validators.required]],
      Address1:['',[Validators.required]],
      Address2:['',[Validators.required]],
      city:['',[Validators.required]],
      pincode:['',[Validators.required,Validators.minLength(6)]],
      district:['',[Validators.required]],
      phoneNumber:['',[Validators.required,Validators.minLength(10)]]
    })
    this.getLocations();
  }

  getLocations(){
    this.importService.getIndents().subscribe((res:any)=>{
      this.locationsList = res['Pastors Location'];
    })
  }
  checkLocationId(event:any){
    this.locationsList.forEach((item:any)=>{
      if(item.locationID == event.target.value){
        this.checkDuplicate = true;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Location Id already exist' });
      }
    })
  }
  saveLocation(){
    const req_Data:any={
      action:'add_pastors_location',
      data:this.locationForm.value
    }
    console.log(req_Data)
    this.loader = true;
    this.importService.createIndent(req_Data).subscribe((res:any)=>{
      if(res.status === 'SUCCESS'){
        this.loader = false;
        this.dialogRef.close('Location added successfully');
      }else{
        this.loader = false;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Location adding failed' });
      }
    }, (err) => {
      this.loader = false;
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Location adding failed' });
    })

  }
  
}
