import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from 'primeng/api';
import { Get_Local, Local } from 'src/app/core/models/stock/local.model';
import { CommonsService } from 'src/app/core/services/commons.service';
import { StockService } from 'src/app/core/services/stock.service';
import { CaseBarCodeDetailsDialogComponent } from './case-bar-code-details-dialog/case-bar-code-details-dialog.component';

@Component({
  selector: 'app-product-code-dialog',
  templateUrl: './product-code-dialog.component.html',
  styleUrls: ['./product-code-dialog.component.scss']
})
export class ProductCodeDialogComponent implements OnInit {

  displayedColumns: string[] = ['no', 'caseBarcode', 'productName'];
  dataSource: MatTableDataSource<Local> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;
  casesDetails: any;


  constructor(public dialogRef: MatDialogRef<ProductCodeDialogComponent>,
    private commonsService: CommonsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private messageService: MessageService
    ) { }

  ngOnInit(): void {
    this.getProductCodeDetails(this.data);
  }

  getProductCodeDetails(productData) {
    this.casesDetails = productData.caseCodes.map(caseCode => {
      return {
        caseBarcode:caseCode,
        productName: productData.brandName
      };
    });
    this.dataSource = new MatTableDataSource(this.casesDetails);
  }

  caseBarCodeDetails(caseBarCodedata) {
    const req_Data = {
      "action": "common_apis",
        "q": '{"data": {"action": "get_case_details", "value":"' + caseBarCodedata.caseBarcode.caseCode+'"}}'

    }

    this.commonsService.getCaseDetails(req_Data).subscribe(result => {
      if(result['get_case_details'] && !result['get_case_details'].error){
        const data = [result['get_case_details']];
        const dialogRef = this.dialog.open(CaseBarCodeDetailsDialogComponent, {
          width: '50%',
          height: '50%',
          autoFocus: false,
          data
        });
      }else if(result['get_case_details'] && result['get_case_details'].error) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: result['get_case_details'].error });
      }
     })

  }


  dialogClose(): void {
    this.dialogRef.close();
  }

}
