import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-product-status-dialog',
  templateUrl: './product-status-dialog.component.html',
  styleUrls: ['./product-status-dialog.component.scss']
})
export class ProductStatusDialogComponent implements OnInit {
  columnsToDisplay: string[] = [
    'depot',
    'lms',
    'depotEligibility',
    'closingBalance',
    'pendingOfs',
    'waitingTrucks',
    'netEligibility'
  ];

  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  constructor() { }

  ngOnInit(): void {
  }

}
