import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  templateUrl: './show-address-details-dialog.component.html',
  styleUrls: ['./show-address-details-dialog.component.scss']
})
export class ShowAddressDetailsDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'name',
    'fatherName',
    'addressProff',
    'village',
    'mandal',
    'district',
    'action'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;

  constructor(public dialogRef: MatDialogRef<ShowAddressDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data.get_vendor_info);
  }


  dialogClose(): void {
    this.dialogRef.close();
  }

}
