import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { Shipments } from 'src/app/core/models/shipments/shipments.model';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-case-barcode-dialog',
  templateUrl: './case-barcode-dialog.component.html',
  styleUrls: ['./case-barcode-dialog.component.scss']
})
export class CaseBarcodeDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'transportPermit'
  ];
  validityForm: FormGroup;
  dataSource: MatTableDataSource<Shipments> = new MatTableDataSource([]);
  todayDate: Date;
  roleName: any;
  showPrint: any = false;
  printId: any;
  consignmentDatas: any = [];
  chemicalReportDatas: any = [];
  entityType:any;
  loader: any = false;
  dataConditions: { [key: number]: { hasData: boolean; hasNoData: boolean } } = {};

  constructor(private messageService: MessageService,private fb: FormBuilder, public dialogRef: MatDialogRef<CaseBarcodeDialogComponent>,
     @Inject(MAT_DIALOG_DATA) public data, private spinner: NgxSpinnerService,
     private shipmentService: ShipmentsService,
     private util: UtilService,) { 
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    this.entityType = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).entity_type;
  }


  ngOnInit(): void {
    this.todayDate = new Date();
    this.validityForm = this.fb.group({
      validityDate: ['', [Validators.required]],
      hrs: ['', [Validators.required]],
      mins: ['', [Validators.required]]
    })
    this.getConsignmentDatas();
    if(this.entityType !== 'Distillery'){
      this.validityForm.controls.validityDate.valueChanges.subscribe((el) => {
        const ofsNos = this.isOfsStatusUpdated();
        if (ofsNos.length) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: `Please check and update the OFS:, ${ofsNos}` });
        }
      });
    } else {
      this.validityForm.controls.validityDate.valueChanges.subscribe((el) => {
        const isStatus = this.isValid();
        if (!isStatus) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: `Please scan all the shipped quantity` });
        }
      });
    }
  }

  selectDate(event) {
    if(this.roleName === 'NonLocal Brewery Manager' && !this.data.updateShipment){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "Please update shipment quantity for this TP" });
    }
    let cDate = new Date();
    let sDate = new Date(event.value);
    let time = Number(sDate.getTime() - cDate.getTime());

    let hours = time > 0 ? Math.floor(time / 1000 / 60 / 60) : 0;
    let minutes = time > 0 ? Math.floor((time / 1000 / 60 / 60 - hours) * 60) : 0;
    this.validityForm.patchValue({
      hrs: hours,
      mins: minutes
    })
  }

  isScannedCasesValid(){
    const matchesScannedCases = this.data.consignment ? this.data.consignment.filter(indent => indent.productDetails.some(product => product.shipmentQty !== product.casesCount)): []; 
    return matchesScannedCases.length === 0;
  }

  calcuateTime(event) {
    if(this.roleName === 'NonLocal Brewery Manager' && !this.data.updateShipment){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "Please update shipment quantity for this TP" });
    }
    if (event.target.valueAsNumber > 2 && event.target.placeholder == "Validity Minutes") {
      event.preventDefault();
    }
    let hours = this.validityForm.get('hrs').value ? (this.validityForm.get('hrs').value) * 1000 * 60 * 60 : 0;
    let minutes = this.validityForm.get('mins').value ? (this.validityForm.get('mins').value) * 1000 * 60 : 0;

    let validateTime = new Date().getTime() + hours + minutes;

    this.validityForm.patchValue({
      validityDate: new Date(validateTime)
    })

  }

  validateTime() {
    const req_Data: any = {
      action: 'generate_tp',
      data: { "validityDateTime": this.validityForm.get('validityDate').value, "tpNo": this.data.tpNo }
    }
    if (this.data.btpNo) {
      delete req_Data.data.tpNo;
      req_Data.data.btpNo = this.data.btpNo;
    } else if (this.data.bipNo) {
      delete req_Data.data.tpNo;
      req_Data.data.bipNo = this.data.bipNo;
    }
    this.loader=true;
    this.shipmentService.validityDateTime(req_Data).subscribe(result => {
      this.dialogRef.close('Date Validated Successfully');
      this.loader=false;
    })
  }

  tpComplete() {
    this.spinner.show();
    delete this.data['colName'];
   
     const req_Data = {
      action: this.data.consignmentType === 'Export' ? 'tp_export_complete' : this.roleName === 'NonLocal Brewery Manager' ? 'ip_complete' : 'tp_complete',
      data: this.data
      }
    if (this.validityForm.get('validityDate').value) {
      req_Data.data.validityDateTime = this.validityForm.get('validityDate').value;
    }
    if (this.validityForm.get('validityDate').value) {
      req_Data.data.validityDateTime = this.validityForm.get('validityDate').value;
    }
    this.loader=true;
    this.shipmentService.postTpComplete(req_Data).subscribe(result => {
      if(result.status === 'SUCCESS'){
        this.dialogRef.close('TP Completed Successfully');
        this.loader=false;
      }else{
        this.dialogRef.close();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: result.status });
        this.loader=false;
      }
      this.spinner.hide();
    })
  }

  ipComplete() {
    this.spinner.show();
    delete this.data['colName'];
    const req_Data = {
      action: 'ip_complete',
      data: this.data
    }
    this.loader=true;
    this.shipmentService.postTpComplete(req_Data).subscribe(result => {
      this.dialogRef.close('IP Completed Successfully');
      this.spinner.hide();
      this.loader=false;
    })
  }

  dialogClose(): void {
    this.dialogRef.close();
  }
  doPrint(id, data:any = []): any {
    this.chemicalReportDatas = {}
    this.printId = id;
    console.log(data)
    if (id === 'print-chemical-report' || id ==='print-blend-edit-analysis-report') {
      if (this.data.Lab) {
      const proofDatas =  this.gerProofLiters(data.labInformation[0].batchId)
        this.chemicalReportDatas = data;
        this.chemicalReportDatas.proofLitres =  proofDatas && proofDatas.length ? proofDatas[0].proofLitres : '-';
      }
    }
    this.showPrint = true;
    this.getPrint(id);
  }
  gerProofLiters(batchId:any){
    console.log(batchId)
    return this.data.Lab.filter((el=> el.batchId === batchId))
  }
  getPrint(id): any {
    let printContents;
    let popupWin;
    setTimeout(() => {
      if (document.getElementById(id)) {
        printContents = document.getElementById(id).innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.write(`${printContents}`);
        popupWin.document.close();
        this.showPrint = false;
        // setTimeout(() => {
        //   this.showPrint = false;
        // }, 500);
      }
    }, 500);
  }
  getConsignmentDatas(): any {
    this.consignmentDatas = [];
    if (this.data.Lab) {
      this.data.Lab.map(row => {
        // row.labInformation.map(row2 => {
          this.consignmentDatas.push(row.labInformation);
        // });
      });
    }
    this.consignmentDatas.forEach((consign, index) => {
      this.dataConditions[index] = this.checkDataConditions(consign);
    });
  }
  getTrackAndTraceFee(): any {
    let bottlesCount = 0;
    this.data.consignment.forEach(cons => {
      cons && cons.productDetails && cons.productDetails.forEach(prod => {
       const unitPerCase = this.util.productCodeToDetails(prod.productCode).unitsPerCase;
        bottlesCount += prod.shipmentQty ? (prod.shipmentQty * unitPerCase) : 0;
      });
    });
    return (bottlesCount * 0.0733).toFixed(3);
    // return (bottlesCount * 0.0826).toFixed(3);
  }
  isValid(): any {
    let isStatus = [];
    if (this.data.consignment && this.data.consignment) {
      this.data.consignment.forEach(cons => {
        if (cons.productDetails && cons.productDetails.length) {
          if(this.data.consignmentType != 'OFS'){
            isStatus.push(cons.productDetails.every(prod => prod.batchApprovedQty ? prod.batchApprovedQty : prod.approvedQty === prod.shipmentQty && prod.casesCount));
          }else if(this.data.consignmentType === 'OFS'){
            isStatus.push(cons.productDetails.every(prod => prod.shipmentQty === prod.casesCount));
          }
        }
      });
    }
    return isStatus.every(el => el === true);
  }
  isOfsStatusUpdated(): any {
    let isStatus = [];
    if (this.data.consignment && this.data.consignment) {
      this.data.consignment.forEach(cons => {
        if (cons.productDetails && cons.productDetails.length) {
          cons.productDetails.find(prod => {
            if ((prod.batchApprovedQty ? prod.batchApprovedQty : prod.approvedQty) !== prod.shipmentQty || !prod.casesCount) {
              isStatus.push(cons.ofsCode);
            }
          });
        }
      });
    }
    return isStatus;
  }

  checkDataConditions(consign: any): { hasData: boolean; hasNoData: boolean } {
    const hasData = (consign.brandyData && consign.brandyResult) || (consign.whiskyData && consign.whiskyResult) || 
                    (consign.vodkaData && consign.vodkaResult) || (consign.rumData && consign.rumResult) || 
                    (consign.maltGrainWhisky && consign.maltGrainWhiskyResult) || 
                    (consign.blendMaltGrainWhisky && consign.blendMaltGrainWhiskyResult) ||
                    (consign.blendGrapeBrandy && consign.blendGrapeBrandyResult) || 
                    (consign.whiteRum && consign.whiteRumResult) || (consign.premixVodka && consign.premixVodkaResult) ||
                    (consign.ginData && consign.ginResult) || (consign.premixGin && consign.premixGinResult) || 
                    (consign.premixRum && consign.premixRumResult) || (consign.grapeBrandy && consign.grapeBrandyResult);

    const hasNoData = !hasData;

    return { hasData, hasNoData };
  }


}
