import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ImportedPacking } from '../models/packing/imported-packing.model';
import { environment } from '../../../environments/environment';
import { DamageRepacking } from '../models/packing/damage-repacking.model';
import { BehaviorSubject } from 'rxjs';

const baseUrl = environment.baseUrl;
const packingUrl = baseUrl + 'packing';

const importedPackingUrl = baseUrl + 'packing/importedpacking';
const damageRepackingUrl = baseUrl + 'packing/damagerepacking';
const casePackingUrl = baseUrl + 'stock/stockinventory/cases';

const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
const feature = userModules && userModules.mod_feats && userModules.mod_feats['Packing'] && userModules.mod_feats['Packing'].features ? userModules.mod_feats['Packing'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class PackingService {

  public packing = new BehaviorSubject([]);
  public packingData = this.packing.asObservable();
  currentUser: any;

  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(
      sessionStorage.getItem('lappCurrentUserDetails')
    );
   }

  createImportedPacking(importedPackingobj) {
    return this.http.post(importedPackingUrl, importedPackingobj);
  }
  getImportedPacking(offset, limit, search?: string): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? (paramObj.limit = limit) : (limit = null);
    search ? (paramObj.search = search) : (search = null);

    return this.http.get(importedPackingUrl, {params: paramObj});
  }

  putImportedPacking(importedPackingObj) {
    return this.http.put<ImportedPacking>(`${importedPackingUrl}`, importedPackingObj);
  }

  createDamageRepacking(damageRepackingobj: any) {
    return this.http.post(damageRepackingUrl, damageRepackingobj);
  }
  getDamageRepacking(offset, limit): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);

    limit ? (paramObj.limit = limit) : (limit = null);
    return this.http.get(damageRepackingUrl, {params: paramObj});
  }

  putDamageRepacking(damageRepackingObj) {
    return this.http.put<DamageRepacking>(`${damageRepackingUrl}`, damageRepackingObj);
  }


  getCasePacking(offset, limit, search?: string): Observable<any> {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? (paramObj.limit = limit) : (limit = null);
    search ? (paramObj.search = search) : (search = null);
    return this.http.get(casePackingUrl, {params: paramObj});
  }

  createCasePacking(packObj: any) {
    return this.http.post(casePackingUrl, packObj);
  }

  putCasePacking(packObj) {
    return this.http.put(casePackingUrl, packObj);
  }

  getPackingData() {
    return this.packingData;
  }

  getPackingDetails(offset,limit,search){
    return this.http.get('assets/samples/packing-feature.json');
  }

  getPackingFeaturesData() {
    const headerDict = {
      feature
    };
    return this.http.get(packingUrl, { headers: headerDict });
  }

  getImportedPackingDetails(){
    const headerDict = {
      // feature,
      action: 'get_import_packing_details'
    };
    return this.http.get(packingUrl, {headers: headerDict});
  }

  postPacking(importedPackingDetail) {
    return this.http.post(packingUrl, importedPackingDetail);
  }

  getRePrintCase() {
    const headerDict = {
      feature,
      action: 'reprint_case'
    };
    return this.http.get(packingUrl, { headers: headerDict });
  }

  getPackageFeatures(paramObj: any = '') {
    const headerDict = {
      feature
    };
    return this.http.get(packingUrl, { headers: headerDict, params: paramObj });
  }

}
