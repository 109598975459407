import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/core/utils/utility-helper';

const baseUrl = environment.baseUrl;
const commonsUrl = baseUrl + 'commons';
const reportAnIssueFeatures = baseUrl + 'report_an_issue';
const userModules = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
//const feature = userModules && userModules.mod_feats && userModules.mod_feats['Alerts'] && userModules.mod_feats['Alerts'].features ? userModules.mod_feats['Alerts'].features.map(feat => feat.feature_name) : [];

@Injectable({
  providedIn: 'root'
})
export class CommonsService {
  userDetails: any = {};
  constructor(private http: HttpClient,
    private util: UtilService) {
      this.userDetails = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
    }
  getList(params) {
    return this.http.get(commonsUrl + params).pipe(
      map((data) => {
        data['get_dist_depot']['depots'] = data['get_dist_depot']['depots'].map(res => res.depotCode);
        data['get_dist_depot']['all_distilleries'] = data['get_dist_depot']['distilleries'];
        data['get_dist_depot']['distilleries'] = data['get_dist_depot']['distilleries'].map(res => res.supplierCode);
        console.log(data, "21:::")
        return data;
      }), catchError(error => {
        return throwError('Something went wrong!');
      })
    );
  }

  getBatchProdList(params) {
    return this.http.get(commonsUrl + params);
  }
  getproductList(params): Observable<any> {
    return this.http.get(commonsUrl, { params });

  }

  getRefList(params): Observable<any> {
    return this.http.get(commonsUrl, { params });
  }

  getofsData(params): Observable<any> {
    return this.http.get(commonsUrl, { params });

  }

  getCaseDetails(params): Observable<any> {
    return this.http.get(commonsUrl, { params });

  }

  getLiquorTypeList(params): Observable<any> {
    return this.http.get(commonsUrl, { params });
  }

  getBatchQuantity(params): Observable<any> {
    return this.http.get(commonsUrl, { params });
  }

  getBrandProducts(params): Observable<any> {
    return this.http.get(commonsUrl, { params });
  }

  getRoutes(params): Observable<any> {
    return this.http.get(commonsUrl, { params });
  }

  getChallanDetails(params): Observable<any> {
    return this.http.get(commonsUrl, { params });
  }

  getCommonEntites(params): Observable<any> {
    return this.http.get(commonsUrl, { params });
  }

  getCommonLists(params): Observable<any> {
    return this.http.get(commonsUrl, { params });
  }
  
  getImportPermitDetails(params): Observable<any> {
    return this.http.get(commonsUrl, { params });
  }

  getPrimaryDist(params): Observable<any> {
    return this.http.get(commonsUrl, { params });

  }

  sendVerifyOtp(obj){
    return this.http.put(commonsUrl, obj);
  }
  getreportAnIssues(): any {
    return this.http.get(reportAnIssueFeatures);
  }
  DistSuppreportAnIssues(params): any {
    return this.http.get(reportAnIssueFeatures, {params});
  }
  postreportAnIssues(req_Data){
    return this.http.post(reportAnIssueFeatures, req_Data)
  }
  putreportAnIssues(obj: any){
    return this.http.put(reportAnIssueFeatures, obj);
  }
  getreportEntityType(params: any){
    return this.http.get(commonsUrl, {params});
  }
  paymentResponse(obj){
    return this.http.post(commonsUrl, obj);
  }
  paymentDetails(obj): any {
    return this.http.get(commonsUrl, {params: obj});
  }
  verifyPaymentDetails(obj): any {
    return this.http.put(commonsUrl, obj);
  }
  // cfm_validator
  postChallanPaymentData(obj): any {
    return this.http.post(baseUrl + 'challan_status', obj);
  }
  postRetailerChallanPaymentData(obj): any {
    return this.http.post(baseUrl + 'challan_status', obj);
  }
  getGitDetails(): any {
    const url = window.location.href;
    const domain: any = (new URL(url));
    const newUrl = `${domain.protocol}//${domain.hostname}:4214/get_git_status`;
    return this.http.get(newUrl);
  }
  getPyGitDetails(): any {
    const url = window.location.href;
    const domain: any = (new URL(url));
    const newUrl = `${domain.protocol}//${domain.hostname}:5000/get_git_status`;
    return this.http.get(newUrl);
  }
  putMethod(payload): any {
    return this.http.put(commonsUrl, payload);
  }
  updateDuplicateCopy(id, key, val): any {
    const reqData = {
      action: 'update_duplicate_copy',
      data: {
        fileName: id,
        login: this.userDetails.activeUsersName
      }
    };
    reqData.data[key] = val;
    this.putMethod(reqData).subscribe(result => {
      console.log(result);
    });
  }
  redirectLabelDetailsPayment(params): Observable<any> {
    console.log('params', params);
    const url = 'http://103.129.72.183/APIndus_Prod/UserInterface/SingleWindowServicesApplication/UserAccount/ExciseRetentionDetails.aspx';
    return this.http.get(url, { params });
  }
}
