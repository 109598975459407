import { ElementRef, Inject, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { StockService } from 'src/app/core/services/stock.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
declare const JsBarcode: any;

@Component({
  selector: 'app-outlet-return-stock-dialog',
  templateUrl: './outlet-return-stock-dialog.component.html',
  styleUrls: ['./outlet-return-stock-dialog.component.scss']
})
export class OutletReturnStockDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'no',
    'productCode',
    'productName',
    'qtyCases',
    'qtyBottles',
  ];
  roleName:any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;
  productList:any=[];
  healCodes:any=[];
  caseBarCodes:any=[];
  totalDatas:any;
  depotAddress:any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;
  constructor(public dialogRef: MatDialogRef<OutletReturnStockDialogComponent>, @Inject(MAT_DIALOG_DATA)public data,
  public util:UtilService,
  private stockService:StockService,
  private messageService:MessageService,
  public spinner: NgxSpinnerService,) {
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    this.depotAddress = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).depot_address;
    console.log(this.data)

   }

  ngOnInit(): void {
    console.log(this.data)
    this.filterData();
    this.healCaseBarCodes();
  }
  healCaseBarCodes(){
    this.data.productDetails.forEach((item:any)=>{
      if(item.imlHealCode && item.imlHealCode.length){
        item.imlHealCode.forEach((code:any)=>{
            this.healCodes.push(code)
        })
      }
      if(item.imlCaseCode && item.imlCaseCode.length){
        item.imlCaseCode.forEach((code:any)=>{
          this.caseBarCodes.push(code)
        })
      }
    })
    console.log(this.caseBarCodes)
    console.log(this.healCodes)

  }
  filterData(){
    this.dataSource = new MatTableDataSource(this.data.productDetails);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  
  }
  ngAfterViewInit(): any {
    if (this.data && this.data.indentId) {
      JsBarcode('#barcode', this.util.formatStringByParamAndIndex(this.data.indentId, '-', 0),
        {
          width: 1,
          height: 40
        }
      );
    }
  }

  dialogClose(): void{
    this.dialogRef.close(false);
  }
  complete(){
    this.spinner.show();
    const req_Data:any={
      action:'ors_complete',
      data:{indentId:this.data.indentId}
    }
    this.stockService.putHoldOnStock(req_Data).subscribe((res:any)=>{
      if(res.status==='SUCCESS'){
        this.dialogRef.close(true);

        // this.messageService.add({severity: 'success', summary: 'Success', detail: 'Completed Successfully'});
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: '' });
      }
      this.spinner.hide();
    })
  }
  approve(){
    this.spinner.show();
    let data: any = {};
    const req_Data:any={
      action:'update_ors',
      data:data
    }
    req_Data.data.indentId =this.data.indentId
    req_Data.data.productDetails = this.data.productDetails
    console.log(req_Data)
    this.stockService.putHoldOnStock(req_Data).subscribe((res:any)=>{
      if(res.status==='SUCCESS'){
        this.dialogRef.close(true);
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Approved Successfully'});
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: '' });
      }
      this.spinner.hide();

    })
  }

  printCaseCodes(){
    this.totalValue();
    setTimeout(() => {
      let printContents, popupWin;
      printContents = document.getElementById('print-case-codes').innerHTML;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.write(`${printContents}`);
      popupWin.document.close();
    }, 500);
 
  }
  printHealCodes(){
    this.totalValue();
    setTimeout(() => {
      let printContents, popupWin;
      printContents = document.getElementById('print-heal-codes').innerHTML;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.write(`${printContents}`);
      popupWin.document.close();
    }, 500);
  }
  printBeer(){
    this.totalValue();
    setTimeout(() => {
      let printContents, popupWin;
      printContents = document.getElementById('print-beer').innerHTML;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.write(`${printContents}`);
      popupWin.document.close();
    }, 500);
  }
  totalValue(){
    this.totalDatas = {};
    this.totalDatas.totalImlCases=0;
    this.totalDatas.totalImlBtls=0;
    this.totalDatas.totalBeerCases=0;
    this.totalDatas.totalBeerBtls=0;
    this.data.productDetails.forEach((item:any)=>{
      this.totalDatas.totalImlCases += item.imlCasesCount ? item.imlCasesCount :0;
      this.totalDatas.totalImlBtls += item.imlHealsCount ? item.imlHealsCount :0;
      this.totalDatas.totalBeerCases += item.qtyCases ? item.qtyCases :0;
      this.totalDatas.totalBeerBtls += item.qtyBtls ? item.qtyBtls :0;

    })

  }
}
