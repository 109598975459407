import { Input, ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { distilleryConstants } from 'src/app/core/constants/distillery-actions.constants';
import { FeaturePermissionService } from 'src/app/core/services/feature-permission.service';

@Component({
  selector: 'app-manage-distillery-feature-dialog',
  templateUrl: './manage-distillery-feature-dialog.component.html',
  styleUrls: ['./manage-distillery-feature-dialog.component.scss']
})
export class ManageDistilleryFeatureDialogComponent implements OnInit {
  @Input() featuresData: any;

  displayedColumns: string[] = [
    'role',
    'actions',
    'delete'
    ];
  permissionData =[];
  dataSource = new MatTableDataSource(this.permissionData);

  distilleryFeatureForm: FormGroup;
  error: string;
  saveDisabled: boolean;
  roles= ['Distillery Manager', 'Distillery Officier', 'Distillery Chemist'];
  actionsList: string[] = [];
  filteredActions: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  protected _onDestroy = new Subject<void>();
  enableAdd: boolean = false;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageDistilleryFeatureDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private featurePermissionService: FeaturePermissionService) {
                const user = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
                // for(let a in distilleryConstants) {
                //   this.actionsList.push(distilleryConstants[a]);
                // }
               this.filteredActions.next([]);
               this.getActions();
               }

  ngOnInit(): void {
    this.distilleryFeatureForm = this.fb.group({
      role: ['', Validators.required],
      actions: ['', Validators.required],
      actionsFilter: ''
    });

    if (this.data) {
      for(let role in this.data) {
        if(role !== 'featureName' && role !== 'moduleName') {
          this.permissionData.push({ role, actions: this.data[role]});
        }
      }
      this.dataSource = new MatTableDataSource(this.permissionData);
    }

    this.distilleryFeatureForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });

    this.distilleryFeatureForm.get('actionsFilter').valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterActions();
    });
  }

  getActions() {
    const actionData = {
      action: 'get_modfeat_actions',
      data: `{'entityType': 'Distillery','moduleName': '${this.data.moduleName}','featureName': '${this.data.featureName}'}`
    };
    this.featurePermissionService.getActions(actionData).subscribe((res: any) => {
      this.actionsList = res.actions;
      this.filteredActions.next(res.actions);
    });
  }

  addAction() {
    const obj ={
      action: 'add_action',
      data: {
        moduleName: this.data.moduleName,
        featureName: this.data.featureName,
        actionName: [...this.actionsList, this.distilleryFeatureForm.get('actionFilter')]
      }
    }
    this.featurePermissionService.editFeaturePermissionFeatures(obj).subscribe((res)=> {
      console.log('res', res);
    });
  }
  filterActions() {
    if (!this.actionsList) {
      return;
    }
    let search = this.distilleryFeatureForm.get('actionsFilter').value;
    if (!search) {
      this.filteredActions.next(this.actionsList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    let list =  this.actionsList.filter(action => action.toLowerCase().indexOf(search) > -1);
    console.log('list length', list.length);
    list.length === 0 ? this.enableAdd = true : this.enableAdd = false;
    this.filteredActions.next(list);
  }

  ngOnChanges(): void {
  }

  addPermissions(){
    this.permissionData.push(this.distilleryFeatureForm.value)
    this.dataSource = new MatTableDataSource(this.permissionData);
    this.distilleryFeatureForm.reset();
  }

  get f(){
    return this.distilleryFeatureForm.controls;
  }

  createPermission() {
    this.saveDisabled = true;
    const btObj = this.distilleryFeatureForm.value;
    delete btObj.status;
    const req_Data = {
      action : '',
      data : btObj
    }
    this.featurePermissionService.createFeaturePermissionFeatures(req_Data).subscribe((res: any) => {
      console.log(res);
      this.dialogRef.close('Successfully Created');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  updateDistilleryPermission() {
    this.saveDisabled = true;
    const obj = [{
      _id: this.data._id,
      update: this.distilleryFeatureForm.value

    }];
    this.featurePermissionService.editFeaturePermissionFeatures(obj).subscribe((res: any) => {
      console.log(res);
      this.dialogRef.close('Updated Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  removeAt(index: number) {
      const data = this.dataSource.data;
      data.splice(index, 1);
      this.dataSource.data = data;
  }


}
