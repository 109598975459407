import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { GateRegisterService } from 'src/app/core/services/gate-register.service';

@Component({
  templateUrl: './manage-outward-gate-register-dialog.component.html',
  styleUrls: ['./manage-outward-gate-register-dialog.component.scss']
})
export class ManageOutwardGateRegisterDialogComponent implements OnInit {

  displayedColumns: string[] = [
    'no',
    'indentNumber',
    'brandName',
    'retailerCode',
    'quantity',
    'quantityPart'
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  outwardGRForm: FormGroup;
  userInfo: any;
  outwardData: any;

  constructor(public dialogRef: MatDialogRef<ManageOutwardGateRegisterDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder, private gateRegisterService: GateRegisterService) {
    this.userInfo = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'))
  }

  ngOnInit(): void {
    this.outwardGRForm = this.fb.group({
      tp: ['', Validators.required],
    });
    this.dataSource = new MatTableDataSource([]);
  }

  dialogClose(): void {
    this.dialogRef.close();
  }

  onScan() {
    const qdata = {
      entityType: this.userInfo.entity_type,
      tpNo: this.outwardGRForm.get('tp').value,
    }
    this.gateRegisterService.getProducts(JSON.stringify(qdata), 'scan_tp').subscribe((res: any) => {
      this.outwardData = res['scan_tp'];
    });
  }

  addOutwardRegister() {
    const obj = {
      action: 'add_outward_register',
      data: {
        tpNo: this.outwardGRForm.get('tp').value,
        entityType: this.userInfo.entity_type,
      }
    }
    this.gateRegisterService.addGateRegister(obj).subscribe(res => {
      this.dialogRef.close('out ward registery Successfully');
    });
  }
}
