import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { ExciseLicenseeService } from 'src/app/core/services/excise-licensee.service';

@Component({
  selector: 'app-edit-registration-for-bars',
  templateUrl: './edit-registration-for-bars.component.html',
  styleUrls: ['./edit-registration-for-bars.component.scss']
})
export class EditRegistrationForBarsComponent implements OnInit {

  registrationForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  offset: number;
  genderType = ['Male', 'Female', 'TG'];
  addressIds = ['Aadhaar', 'Driving License', 'Voter Id', 'Passport', 'PAN Card']
  barDetails: any;


  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef < EditRegistrationForBarsComponent > ,
              @Inject(MAT_DIALOG_DATA) public data, private exciseLicenseeService: ExciseLicenseeService) {}

  ngOnInit(): void {
    this.barDetails = this.data && this.data.get_vendor_details ? this.data.get_vendor_details[0]:'';
    console.log('this.data', this.data);
    this.registrationForm = this.fb.group({
      revenueDistrict: ['', [Validators.required, ]],
      exciseDistrict: ['', [Validators.required, ]],
      depot: ['', [Validators.required, ]],
      exciseCircle: ['', [Validators.required, ]],
      locationZone: ['', [Validators.required, ]],
      licenseeCategory: ['', [Validators.required, ]],
      licenseeType: ['', [Validators.required, ]],
      licenseeCode: ['', [Validators.required, ]],
      licenseeAmount: ['', [Validators.required, ]],
      licenseDate: ['', [Validators.required, ]],
      expiryDate: ['', [Validators.required, ]],
      apgstNo: ['', [Validators.required, ]],
      apgstDate: ['', [Validators.required, ]],
      incomeTax: ['', [Validators.required, ]],
      purchaseTax: ['', [Validators.required, ]],
      tcsLimit: ['', [Validators.required, ]],
      tcsValidityDate: ['', [Validators.required, ]],
      retailerCode: ['', [Validators.required, ]],
      gazetteCode: ['', [Validators.required, ]],
      vbCode: ['', [Validators.required, ]],
      professionalTax: ['', [Validators.required, ]],


      licenseeName: ['', [Validators.required, ]],
      panNo: ['', [Validators.required, ]],
      aadharNo: ['', [Validators.required, ]],
      tinNo: ['', [Validators.required, ]],
      dob: ['', [Validators.required, ]],
      gender: ['', [Validators.required, ]],

      mobileNo: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      pinCode: ['', [Validators.required, ]],
      address: ['', [Validators.required, ]],
      shopName: ['', [Validators.required, ]],
      fathersName: ['', [Validators.required, ]],
      addressNo: ['', [Validators.required, ]],
      addressId: ['', [Validators.required, ]],


      nowkarnamaId: ['', [Validators.required, ]],
      name: ['', [Validators.required, ]],
      fatherName: ['', [Validators.required, ]],
      addressProofId: ['', [Validators.required, ]],
      addressProofNo: ['', [Validators.required, ]],
      village: ['', [Validators.required, ]],
      mandal: ['', [Validators.required, ]],
      district: ['', [Validators.required, ]],

      routeName: ['', [Validators.required, ]],
      source: ['', [Validators.required, ]],
      destination: ['', [Validators.required, ]],
      routeDescription: ['', [Validators.required, ]],
    });
    if (this.barDetails) {
      this.registrationForm.patchValue({
        revenueDistrict: this.barDetails.address.revenue_district,
        exciseDistrict: this.barDetails.exciseDistrict,
        depot: this.barDetails.assignedDepot,
        exciseCircle: this.barDetails.address.excise_circle,
        locationZone: this.barDetails.address.zone,
        licenseeCategory : this.barDetails.licenseeCategory,
        licenseeType: this.barDetails.locationType,
        licenseeCode: this.barDetails.licenseeCode,
        licenseeAmount : this.barDetails.licenseeAmount,
        licenseDate: this.barDetails.licenseDate,
        expiryDate: this.barDetails.expiryDate,
        apgstNo : this.barDetails.apgstNo,
        apgstDate : this.barDetails.apgstDate,
        incomeTax : this.barDetails.incomeTax,
        purchaseTax : this.barDetails.purchaseTax,
        tcsLimit : this.barDetails.tcsLimit,
        tcsValidityDate : this.barDetails.tcsValidityDate,
        retailerCode : this.barDetails.vendorId,
        gazetteCode : this.barDetails.gazetteCode,
        vbCode : this.barDetails.vbCode,

        professionalTax : this.barDetails.professionalTax,
        licenseeName: this.barDetails.licenseeName,
        panNo: this.barDetails.panNo,
        aadharNo : this.barDetails.aadharNo,
        tinNo: this.barDetails.tinNo,
        dob: this.barDetails.dob,
        gender : this.barDetails.gender,
        mobileNo : this.barDetails?.address?.phoneNumbers[0]?.number.replace(/\D/g, ''),
        pinCode : this.barDetails.address.pincode,
        address : this.barDetails.address.address,
        shopName : this.barDetails.name,
        fathersName : this.barDetails.fathersName,
        addressNo : this.barDetails.addressNo,
        addressId : this.barDetails.addressId,

        nowkarnamaId: this.barDetails.nowkarnamaId,
        name: this.barDetails.name,
        fatherName: this.barDetails.fatherName,
        addressProofId : this.barDetails.addressProofId,
        addressProofNo: this.barDetails.addressProofNo,
        village: this.barDetails.address.village,
        mandal : this.barDetails.address.mandal,
        district : this.barDetails.address.revenue_district,

        routeName : this.barDetails.routeName,
        source: this.barDetails.source,
        destination : this.barDetails.destination,
        routeDescription: this.barDetails.routeDescription,
      });
    }
    this.registrationForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
  }

  get f() {
    return this.registrationForm.controls;
  }

  onSave() {
    const obj = {
      action: 'edit_bar_details',
      data: { ...this.registrationForm.value }
    };
    this.exciseLicenseeService.updateLicensee(obj).subscribe(res => {
      console.log('res', res);
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  dialogClose(): void{
    this.dialogRef.close();
  }

}
