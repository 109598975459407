import { InterDepotService } from 'src/app/core/services/inter-depot.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, Inject, Input, OnInit, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from 'primeng/api';
import { Shipments } from 'src/app/core/models/shipments/shipments.model';
import { CommonsService } from 'src/app/core/services/commons.service';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import * as moment from 'moment';

declare const JsBarcode: any;
@Component({
  selector: 'app-ofidt-details-dialog',
  templateUrl: './ofidt-details-dialog.component.html',
  styleUrls: ['./ofidt-details-dialog.component.scss']
})
export class OfidtDetailsDialogComponent implements OnInit, AfterViewInit  {
  value = this.data.tpNo;
  lineColor = '#000000';
  width = "1";
  height = "30";
  // get values(): string[] {
  //   return this.value.split('\n');
  // }
  ofidtNo = '';
  csdCode: any = '';
  selectedOfsForPrint: any;
  ofsScanCode = '';
  @Input() statusData: any;
  displayedColumns: string[] = ['ofsNo', 'productCode', 'productName', 'orderQty', 'allocateQty', 'action'];
  dataSource: MatTableDataSource<Shipments> = new MatTableDataSource([]);
  limit: number;
  offset: number;
  search: string;
  roleName: string;
  ofidtData = [];
  csdData = [];
  caseCode: any;
  valid: any;
  caseCount: any;
  isEdit: boolean = false;
  isQtyValid: boolean;
  cttDetails: any;
  ofsChangeData: any;
  isDisable: boolean = true;
  panelOpenState: boolean =false;
  ofsIndex: any;
  csdInfoData: any;
  csdScanCode: any;
  csdIndex: any;
  csdChangeData: any;
  caseDetails: any;
  totalDatas: any = [];
  userDetails: any = [];
  distilleryName: any = [];
  distilleryAddress: any = [];
  selectedofidtNo: any;
  invalidOfidt:any=false;
  constructor(
    public dialogRef: MatDialogRef<OfidtDetailsDialogComponent>,
    public util: UtilService, private commonsService: CommonsService,
    private messageService: MessageService,
    private interDepotService: InterDepotService,
    @Inject(MAT_DIALOG_DATA) public data,
    private shipmentService: ShipmentsService,
    private dialog: MatDialog,
    public utilService: UtilService,
    private spinner: NgxSpinnerService) { 
      this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    }
  ofidtAddFields = [];
  ngOnInit(): void {
    this.userDetails = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
    if (this.userDetails.distillery_unit && this.userDetails.distillery_address) {
      this.distilleryName = this.utilService.formatString(this.userDetails.distillery_unit);
      this.distilleryAddress = this.userDetails.distillery_address[0];
    }
    if (this.statusData) {
      this.data = this.statusData;
      
    }
    this.data.consignmentType === 'OFIDT' || this.data.consignmentType === 'Import Permit' ?  this.getOfidtDetails() : this.getCsdDetails();
    
    // this.getCttFetails();
    this.ofidtAddFields = [
      { ofidtNo: '', productCode: '', productName: '', casesCount: '', approvedCases: '' }
    ]
    console.log(this.data)
  }

  getCttFetails() {
    let data = JSON.stringify({ "data": ["get_ctt"] });

    const req_Data = {
      action: 'common_apis',
      q: data

    }
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {
      this.cttDetails = result['get_ctt'];
    })
  }

  ngAfterViewInit(): void {
    if (this.data && this.data.consignment) {
      JsBarcode('#barcode', this.data.consignment[0].ofidtNo,
        {
          width: 1,
          height: 40
        }
      );
    }
  }
  getCsdDetails() {
    this.getCsdinfoDetails();
    let tpNo = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
    const req_Data = {
      action: 'common_apis',
      q: '{"data":  ["get_approved_csd"]}'

    }
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {

      this.csdData = result['get_approved_csd'];
     /*  if (this.ofidtData) {

        this.ofidtData.forEach(ofs => {
          ofs.productDetails.forEach(product => {
            product['shipmentQty'] = product.shipmentQty ? product.shipmentQty : product.approvedQty;
          })
        })
      } */
    })

  }

  csdChange() {
    console.log(this.csdCode,"90::")
  }
 

  getCsdinfoDetails() {
    let tpNo = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
    const req_Data = {
      action: 'common_apis',
      q: '{"data":  {"action": "get_added_csd", "value": "' + tpNo + '"}}'

    }
    this.spinner.show();
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {

      this.csdInfoData = result['get_added_csd'];
      this.spinner.hide();
     /*  if (this.ofidtData) {

        this.ofidtData.forEach(ofs => {
          ofs.productDetails.forEach(product => {
            product['shipmentQty'] = product.shipmentQty ? product.shipmentQty : product.approvedQty;
          })
        })
      } */
    })
  }

  addCsd(): any {
    let data: any = {};
    this.data.ofidtData.forEach(ofs => {
      if (this.csdCode === ofs.ofidtNo) {
        data = ofs;
      }
      data['tpNo'] = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
      if(!data['tpNo']) {
        data['ipNo'] =this.data.ipNo;
      }
      data['consignmentType'] = this.data.consignmentType;
      data['destnEntityId'] = this.data.destnEntityId;
    });
    const req_Data = {
      action: 'add_tp_products',
      data
    };
    const duplicateCsd = this.csdInfoData && this.csdInfoData.some(csd => csd.csdCode === this.csdCode);
    const isDestinationMatches = this.data.depotCode === this.data.destnEntityId;
    const errorMessage = duplicateCsd ? 'Duplicate CSD is not allowed' : 'Destination need to match';
    if(duplicateCsd || !isDestinationMatches){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: errorMessage });
    }else{
      this.spinner.show();
      this.interDepotService.putOfs(req_Data).subscribe(res => {
        this.spinner.hide();
        this.getCsdinfoDetails();
      });
    }
  }
  getOfidtDetails() {
    let tpNo = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
    if(!tpNo) {
      tpNo =this.data.ipNo;
    }
    const asnId = this.data.asnId ? this.data.asnId : '';
    const req_Data = {
      action: 'common_apis',
      q: '{"data": {"action": "get_added_ofidt", "value": "' + tpNo + '"}}'
    };
    this.spinner.show();
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {
      this.ofidtData = result['get_added_ofidt'];
      console.log("OFIDT data",this.ofidtData)
      if (this.ofidtData) {

        this.ofidtData.forEach(ofs => {
          ofs.productDetails.forEach(product => {
            product['shipmentQty'] = product.shipmentQty ? product.shipmentQty : product.approvedCases;
          })
        })
      }
      this.spinner.hide();
    })

  }

  shipmentQuantityChange(item) {
    return item.approvedCases < item.shipmentQty || item.shipmentQty < item.casesCount;
  }

  addOfidt() {
    let data: any = {};
    data = this.selectedofidtNo;
    data['tpNo'] = this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo;
    if(!data['tpNo']) {
      data['ipNo'] =this.data.ipNo;
    }
    data['consignmentType'] = this.data.consignmentType;
    data['destnEntityId'] = this.data.destnEntityId;
    const req_Data = {
      action: 'add_ofidt',
      data
    }

     const scannedOfidt = this.selectedofidtNo.status === "InProgress";
     const isDestinationMatches = data.depotCode === data.destnEntityId; 
     const errorMessage = scannedOfidt ? 'OFIDT is already Scanned' : 'Destination need to match';
    if(scannedOfidt){ //|| !isDestinationMatches){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: errorMessage });
    }else{
      this.spinner.show();
      this.shipmentService.addOfidt(req_Data).subscribe(res => {
        this.ofidtNo = '';
        this.spinner.hide();
        this.getOfidtDetails();
      })
    }
  }

  shipmentEdit(item) {
    item.isEdit = true;
  }

  shipmentSave(item, ofs) {
    if (item.approvedCases < item.shipmentQty || item.shipmentQty < item.casesCount) {
      return;
    }
    const req_Data = {
      action: 'update_shipment_qty',
      data: { "tpNo": this.data.tpNo, "ofidtNo": ofs.ofidtNo, "productCode": item.productCode, "shipmentQty": item.shipmentQty, "batchId": item.batchId }
    }
    this.shipmentService.saveShipmentQuantity(req_Data).subscribe(result => {

    })
    item.isEdit = false;
  }


  shipmentCsdSave(item, csd) {
    if (item.approvedCases < item.shipmentQty || item.shipmentQty < item.casesCount) {
      return;
    }
    const req_Data = {
      action: 'update_shipment_qty',
      data: { "tpNo": this.data.tpNo, "indentId": csd.indentId, "productCode": item.productCode, "shipmentQty": item.shipmentQty, "batchId": item.batchId }
    }
    this.shipmentService.saveShipmentQuantity(req_Data).subscribe(result => {

    })
    item.isEdit = false;
  }
  dataFill(data) {
    let flag = false;
    Object.keys(data).forEach(val => {
      flag = !data[val];
    })
    return flag;
  }

  deleteOfs(i) {
    this.ofidtAddFields.splice(i, 1);
  }

  caseCodeChange() {
    if (this.caseCode && this.ofsScanCode) {
    const caseReq_Data = {
      action: 'common_apis',
      q: '{"data": {"action": "get_case_details", "value": "' + this.caseCode + '"}}'
    }

    this.commonsService.getCommonEntites(caseReq_Data).subscribe(result => {
      this.caseDetails = result['get_case_details'];
      if(this.caseDetails){
        const isTpAvailable = this.caseDetails.tpNo;
        const matchesCaseDetails = this.ofsChangeData.productDetails.some(product => {
          if (this.caseDetails.productCode.length === 11) {
            this.caseDetails.productCode = this.utilService.convertProductCodeLength11TO12(this.caseDetails.productCode);
          }
          return this.caseDetails.productCode === product.productCode && this.caseDetails.batchId === product.batchId;
        }); 
        const scannedQtyAllowed = this.ofsChangeData.productDetails.some(product => this.caseDetails.productCode === product.productCode && this.caseDetails.batchId === product.batchId && (!product.casesCount || product.shipmentQty > product.casesCount)); 
        const caseError = (isTpAvailable || this.caseDetails.location !== "Distillery")? 'Case already scanned' : !matchesCaseDetails ? 'Wrong Product Case Scanned' : 'Shipment Quantity Reached';
        if(isTpAvailable || !matchesCaseDetails || !scannedQtyAllowed){
          this.messageService.add({ severity: 'error', summary: 'Error', detail: caseError });
        }else if(matchesCaseDetails && scannedQtyAllowed){
          this.scanBarCode();
        }
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid Case' });
      }
    })
     
    }
  }

  scanBarCode(){
    const req_Data = {
      action: 'add_casecodes',
      data: { "tpNo": this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo, "ofidtNo": this.ofsScanCode, "caseCode": this.caseCode, "depotCode": this.data.destnEntityId, "productDetails": this.ofidtData[this.ofsIndex].productDetails }

    }
 this.interDepotService.scanBarCode(req_Data).subscribe(res => {
        console.log(res);

        this.valid = res.status;
        this.caseCode = '';
        this.getOfidtDetails();
      })
  }

  caseCodeCsdChange() {
    let pbData = [];


    /*    this.cttDetails.forEach(ctt => {
         if(ctt.caseCode == this.caseCode) {
           cttData = ctt;
         }
       }) */
    if (this.caseCode && this.csdScanCode) {
    //   let pbDetails = this.ofsChangeData.productDetails.filter(product => {
    //     return Number(product.shipmentQty) > Number(product.casesCount) || !product.casesCount;
    //   })
    //   pbData = pbDetails.map(ofs => {
    //     return { "batchId": ofs.batchId, "productCode": ofs.productCode }
    //   })
      const req_Data = {
        action: 'add_csd_casecodes',
        data: { "tpNo": this.data.tpData ? this.data.tpData.tpNo : this.data.tpNo, "indentId": this.csdScanCode, "caseCode": this.caseCode, "depotCode": this.data.destinationEntityId, "productDetails": this.csdInfoData[this.csdIndex].productDetails }

      }

      this.interDepotService.scanBarCode(req_Data).subscribe(res => {
        console.log(res);

        this.valid = res.status;
        this.caseCode = '';
        this.getOfidtDetails();
        //  this.ofidtData.forEach(ofs => {
        //   console.log(ofs.ofidtNo, this.ofsChangeData, res.productCode, res.batchId, "155:::::")
        //   if (ofs.ofidtNo == this.ofsChangeData.ofidtNo) {
        //     ofs.productDetails.forEach(product => {
        //       if (product.productCode == res.productCode && product.batchId == res.batchId) {
        //         product.casesCount = product.casesCount ? product.casesCount++ : 1;
        //       }
        //     })
        //   }
        // }) 

      })
    }
  }

  changeOfs(code, i) {
    this.isDisable = false;
    this.ofsScanCode = code.ofidtNo;
    this.ofsIndex = i;
    this.ofsChangeData = code;
  }

  changeCsd(code, i) {
    this.isDisable = false;
    this.csdScanCode = code.indentId;
    this.csdIndex = i;
    this.csdChangeData = code;
  }

  dialogClose(): void {
    this.dialogRef.close();
  }
  print(data,id){
    this.selectedOfsForPrint = data;
    console.log(this.selectedOfsForPrint)
    this.totalValue();
    setTimeout(() => {
      let printContents, popupWin;
      printContents = document.getElementById(id).innerHTML;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.write(`${printContents}`);
      popupWin.document.close();
    }, 500);
 
  }

  totalValue(): any {
    this.totalDatas = [];
    this.totalDatas.approvedCases = 0;
    this.totalDatas.bulkLitres = 0;
    this.totalDatas.proofLitres = 0;
    this.totalDatas.exciseDuty = 0;
    this.totalDatas.value = 0;
    this.totalDatas.casesCount =0;
    this.totalDatas.breakBtls=0;
    this.totalDatas.InvCases=0;
    this.totalDatas.InvBtls =0;
    this.selectedOfsForPrint.productDetails.map(row => {
      this.totalDatas.approvedCases += row.approvedCases;
      this.totalDatas.bulkLitres += row.bulkLitres;
      this.totalDatas.proofLitres += row.proofLitres;
      this.totalDatas.exciseDuty += row.exciseDuty;


      this.totalDatas.casesCount += row.casesCount ? row.casesCount : 0;
      this.totalDatas.breakBtls += row.totalScannedBottles ? row.totalScannedBottles  : 0;
      this.totalDatas.InvCases += row.scannedCases ? row.scannedCases : 0;
      this.totalDatas.InvBtls += row.totalScannedBottles ? row.totalScannedBottles : 0;
      console.log(row)
      if (row.value) {
        this.totalDatas.value += row.value;
      }
      if (row.breakages && row.breakages.bottleBreakages) {
        this.getCaseCount(row);
      } else {
        row.totalScannedCases = row.casesCount ? row.casesCount : 0;
      }
    });
  }
  getBatchList(productDetails, item){
    let payload = '?action=common_apis&q={"data": {"action": "get_batches_by_prod", "value" : "'+ productDetails.productCode+'"}}';
    this.commonsService.getBatchProdList(payload).subscribe(resp => {
      console.log('rrr::::::', resp['get_batches_by_prod'].length);
      if(resp['get_batches_by_prod'].length){
        this.getBatchDialog(productDetails, item,resp['get_batches_by_prod']);
      }
    })

  }
  convertToDate(date): any {
    return date ? moment(date).format('DD/MM/YY, h:mm a') : '-';
  }
  getAddedOfidtData(){
    this.invalidOfidt =false;
    if(this.ofidtNo){
      const req_Data = {
        action: 'common_apis',
        q: '{"data": {"action": "get_added_ofidt_data", "value": "' + this.ofidtNo + '"}}'
  
      }
      this.commonsService.getCommonEntites(req_Data).subscribe(result => {
        console.log(result)
        if(result['get_added_ofidt_data']== null){
          this.invalidOfidt = true;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Invalid OFIDT Code' });
        }
        this.selectedofidtNo = result['get_added_ofidt_data'][0];
        console.log(this.selectedofidtNo)
      })
    }
  }
  getCaseCount(item){
    if(item.breakages && item.breakages.bottleBreakages){
        let unitsPerCase = this.util.productCodeToDetails(item.productCode).unitsPerCase
        let totalScannedBottles = (item.breakages.bottleBreakages.length * unitsPerCase) - item.breakages.bottles;
        item.totalScannedCases = item.casesCount + Math.floor(totalScannedBottles/unitsPerCase);
        item.totalScannedBottles = totalScannedBottles % unitsPerCase;
      
    }
    return item;
  }
  getBatchDialog(productDetails, item, batchIds){
    // const dialogRef = this.dialog.open(OfsProductBranchDetailsDialogComponent, {
    //   width: '75%',
    //   height:'75%',
    //   autoFocus: false,
    //   data: {productDetails: productDetails, tpNo: this.data.tpNo, ofidtNo: item.ofidtNo, batchIdListData: batchIds }
    // });

    // dialogRef.afterClosed().subscribe((result) => {
    //   if(result){
    //     this.getOfidtDetails();
    //   }
    // });
  }


}
