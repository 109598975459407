import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormArray } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { CommonsService } from 'src/app/core/services/commons.service';
// import { ExciseLicenseeService } from 'src/app/core/services/excise-licensee.service';
import { UserAccountsService } from 'src/app/core/services/user-accounts.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-manage-retailer-registration',
  templateUrl: './manage-retailer-registration.component.html',
  styleUrls: ['./manage-retailer-registration.component.scss']
})
export class ManageRetailerRegistrationComponent implements OnInit {

  retailerForm: FormGroup;
  supervisorAddress: FormArray;
  address: FormArray;
  bankDetails: FormArray;
  error: string;
  page: number;
  limit: number;
  depots = [];
  revenueDistricts = [];
  exciseDistricts = [];
  saveDisabled: boolean;
  offset: number;
  public depotFilterCtrl: FormControl = new FormControl();
  shopDetails: any;
  filteredDepots: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  exciseCircles: any = [];

  constructor(
    private fb: FormBuilder, private dialogRef: MatDialogRef < ManageRetailerRegistrationComponent > ,
    @Inject(MAT_DIALOG_DATA) public data, private commonsService: CommonsService,
    public util: UtilService, private userAccountsService: UserAccountsService
  ) {}

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.getDepots();
    this.getCommonList();
    this.retailerForm = this.fb.group({
      retailerCode: ['', [Validators.required, ]],
      retailerId: [''],
      name: ['', [Validators.required, ]],
      address: this.fb.array([this.updateAddressInformation()]),
      licenseInfo: [''],
      assignedDepot: ['', [Validators.required, ]],
      bankDetails: this.fb.array([this.updateBankInformation()]),      
      operationStartDate: [''],
      gslNo: ['', [Validators.required, ]],
      status: ['Active', [Validators.required, ]],
      locationType: [''],
      supervisorName: ['', [Validators.required, ]],
      supervisorAddress: this.fb.array([this.updateSupervisorAddressInformation()]),
      powerCutDuration: [''],
    });
    this.retailerForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
    this.util.matSelectFilter(this, "depotFilterCtrl", "filteredDepots", "depots");
  }

  get f() {
    return this.retailerForm.controls;
  }
  updateAddressInformation(){
    return this.fb.group({
      address: ['', [Validators.required, ]],
      divisonName: ['', [Validators.required, ]],
      excise_circle: ['', [Validators.required, ]],
      revenue_district: ['', [Validators.required, ]],
      zone: [''],
      state: [''],
      pincode: ['', [Validators.minLength(6), Validators.maxLength(6)]],
      emailIds: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phoneNumbers: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(14)]],
    });
  }
  updateSupervisorAddressInformation(){
    return this.fb.group({
      mandal: [''],
      village: [''],
      residenceDistance: [''],
      travelTime: [''],
    });
  }
  updateBankInformation(){
    return this.fb.group({
      bankName: [''],
      village: [''],
      mandal: [''],
      district: [''],
      distance: [''],
      travelTime: [''],
    });
  }

  getCommonList() {
    const req_Data = {
      action: 'common_apis',
      q: `{"data": {"action": "get_static_codes", "value": ["revenueDistricts", "exciseDistricts"]}}`
    }
    this.commonsService.getCommonEntites(req_Data).subscribe(result => {
      this.revenueDistricts = result.revenueDistricts;
      this.exciseDistricts = result.exciseDistricts;
    })
  }

  getDepots() {
    let paramObj = {
      action: 'common_apis',
      q: '{"data": ["get_depots"]}'
    }
    this.commonsService.getCommonEntites(paramObj).subscribe((res) => {
      this.depots = res['get_depots'].map((depot) => depot.depotCode);
      this.filteredDepots.next(this.depots);
    });
  }
  changeRevenueDistrict(value): any {
    this.exciseCircles = [];
    this.retailerForm.get('address')['controls'][0].controls.excise_circle.setValue('');
    const excCircle = this.exciseDistricts.filter(el => el.distName === value);
    if (excCircle && excCircle.length && excCircle[0].exciseCircles) {
      this.exciseCircles = excCircle[0].exciseCircles;
    }
  }

  createRetailer() {
    const payload: any = {
      action: 'add_retailers',
      data: this.retailerForm.value
    };
    console.log(payload);
    if (payload.data.retailerCode && payload.data.retailerCode.length === 4) {
      payload.data.retailerCode = '0' + payload.data.retailerCode;
    }
    payload.data.retailerId = payload.data.retailerCode + '-' + payload.data.name;
    delete payload.data.retailerCode;
    payload.data.name = payload.data.retailerId;
    payload.data.address = payload.data.address[0];
    const emailIds = payload.data.address.emailIds; 
    payload.data.address.emailIds = [];
    payload.data.address.emailIds[0] = {mailId: emailIds, type: '', isVerified: 'True'};
    const phoneNumbers = payload.data.address.phoneNumbers; 
    payload.data.address.phoneNumbers = [];
    payload.data.address.phoneNumbers[0] = {number: phoneNumbers, type: '', isVerified: 'True'};
    console.log(payload);
    this.userAccountsService.createRetailer(payload).subscribe((res: any) => {
      this.dialogRef.close('Created Retailer Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  dialogClose(): void{
    this.dialogRef.close();
  }
}
