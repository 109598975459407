import { ViewPriceLabelRegistrationComponent } from './../features/label-registration/label-registration-feature/manage-label-registration-dialog/view-price-label-registration/view-price-label-registration.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageLabelRegistrationDialogComponent } from 'src/app/features/label-registration/label-registration-feature/manage-label-registration-dialog/manage-label-registration-dialog.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatRadioModule } from '@angular/material/radio';
import { CoreModule } from 'src/app/core/core.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { ButtonModule } from 'primeng/button';
import { ChipsModule } from 'primeng/chips';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { RippleModule } from 'primeng/ripple';
import { ToastModule } from 'primeng/toast';
import { MatExpansionModule } from '@angular/material/expansion';
import { OfidtDetailsDialogComponent } from '../features/inter-depot/inter-depot-transfer/ofidt-details-dialog/ofidt-details-dialog.component';
import { StockReturnIcdcPackingListDialogComponent } from '../features/stock-return/stock-return-or-drainout/stock-return-icdc-packing-list-dialog/stock-return-icdc-packing-list-dialog.component';
import { ManageSamplesDialogComponent } from '../features/lab/samples/manage-samples-dialog/manage-samples-dialog.component';
import { LoaderBtnComponent } from './components/loader-btn/loader-btn.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ManageImportIndentComponent } from '../features/shipments/manage-import-indent/manage-import-indent.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { PrintImportComponent } from '../features/import/print-import/print-import.component';
import { ManageTpRequestsDialogComponent } from '../features/indent-management/licensee-indents/manage-tp-requests-dialog/manage-tp-requests-dialog.component';
import { IcdcCaseBarcodeDialogComponent } from '../features/shipments/licensee-shipments/case-barcode-dialog/icdc-case-barcode-dialog.component';
import { IcdcDetailDialogComponent } from '../features/shipments/licensee-shipments/icdc-details-dialog/icdc-details-dialog.component';
import { SupplierEvcInfoDialogComponent } from '../features/shipments/supplier-shipments/supplier-evc-info-dialog/supplier-evc-info-dialog.component';
import { ApnDetailsForSupplierDialogComponent } from '../features/shipments/supplier-shipments/apn-details-for-supplier-dialog/apn-details-for-supplier-dialog.component';
import { GetChallanDetailsComponent } from './components/get-challan-details/get-challan-details.component';

@NgModule({
  declarations: [
    ManageLabelRegistrationDialogComponent,
    ViewPriceLabelRegistrationComponent,
    OfidtDetailsDialogComponent,
    StockReturnIcdcPackingListDialogComponent,
    ManageSamplesDialogComponent,
    LoaderBtnComponent,
    ManageImportIndentComponent,
    PrintImportComponent,
    ManageTpRequestsDialogComponent,
    IcdcCaseBarcodeDialogComponent,
    IcdcDetailDialogComponent,
    SupplierEvcInfoDialogComponent,
    GetChallanDetailsComponent,
    ApnDetailsForSupplierDialogComponent
  ],
  imports: [
  CommonModule,
    NgxSpinnerModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    MaterialFileInputModule,
    MatRadioModule,
    CoreModule,
    MessagesModule,
    MessageModule,
    RippleModule,
    MatTooltipModule,
    ButtonModule,
    ToastModule,
    NgxMatNativeDateModule,
    MatSelectInfiniteScrollModule,
    NgxMatSelectSearchModule,
    MatMenuModule,
    ChipsModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatCheckboxModule
  ],
  exports: [
    ManageLabelRegistrationDialogComponent,
    ViewPriceLabelRegistrationComponent,
    OfidtDetailsDialogComponent,
    StockReturnIcdcPackingListDialogComponent,
    ManageSamplesDialogComponent,
    LoaderBtnComponent,
    PrintImportComponent,
    ManageTpRequestsDialogComponent,
    IcdcCaseBarcodeDialogComponent,
    IcdcDetailDialogComponent,
    SupplierEvcInfoDialogComponent,
    GetChallanDetailsComponent,
    ApnDetailsForSupplierDialogComponent
  ],
})
export class SharedModule { }
