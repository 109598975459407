import { ViewChild } from '@angular/core';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonsService } from 'src/app/core/services/commons.service';
import { MessageService } from 'primeng/api';
import { ThroughTransportPermitService } from './../../../core/services/through-transport-permit.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-manage-ttp-location-dialog',
  templateUrl: './manage-ttp-location-dialog.component.html',
  styleUrls: ['./manage-ttp-location-dialog.component.scss']
})
export class ManageTtpLocationDialogComponent implements OnInit {
  
  @ViewChild('ttpHtmlForm') ttpHtmlForm: any;
  ttpForm: FormGroup;
  userInfo: any;
  loader: any = false;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ManageTtpLocationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private ttpService: ThroughTransportPermitService,
    private messageService:MessageService,
    private spinner: NgxSpinnerService,
    public util: UtilService
  ) {
    this.userInfo = JSON.parse(sessionStorage.getItem('lappCurrentUserModules'));
  }

  ngOnInit(): void {
    this.ttpForm = this.fb.group({
      locationCode: [''],
      locationName: ['', Validators.required],
      address1: ['', Validators.required],
      address2: ['', Validators.required],
      city: ['', Validators.required],
    });
    if (this.data && this.data.entityId) {
      this.ttpForm.patchValue({
        locationCode: this.data.locationCode,
        locationName: this.data.locationName,
        address1: this.data.address1,
        address2: this.data.address2,
        city: this.data.city
      });
      this.ttpForm.controls.locationCode.setValidators(null);
      this.ttpForm.controls.locationCode.updateValueAndValidity();
      this.ttpForm.disable();
    }
  }
  create() {
    if (this.ttpForm.status === 'VALID') {
      const req_Data = {
        action: 'add_ttp_location',
        data: this.ttpForm.value
      }
      delete req_Data.data.locationCode;
      this.loader = true;
      this.spinner.show();
      this.ttpService.postMethod(req_Data).subscribe((res: any) => {
        if (res && res.status === 'SUCCESS') {
          this.dialogRef.close('TTP Location Created Successfully');
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'TTP Location Creation Failed' });
        }
        this.loader = false;
        this.spinner.hide();
      }, (err) => {
        this.loader = false;
        this.spinner.hide();
      });
    }
  }
  update(): any {
    if (this.ttpForm.status === 'VALID') {
      const req_Data = {
        action: 'update_ttp_location',
        data: this.ttpForm.value
      }
      this.loader = true;
      this.spinner.show();
      this.ttpService.putMethod(req_Data).subscribe((res: any) => {
        if (res && res.status === 'SUCCESS') {
          this.dialogRef.close('TTP Location Updated Successfully');
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'TTP Location Update Failed' });
        }
        this.loader = false;
        this.spinner.hide();
      }, (err) => {
        this.loader = false;
        this.spinner.hide();
      });
    }
  }

  resetForm(): any {
    this.ttpHtmlForm.nativeElement.reset();    
  }
  test(): any {
    console.log(this.ttpForm);
  }

}
