import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
// import {StatusDetailsDialogComponent} from './status-details-dialog.component';
import { FormsModule } from '@angular/forms';
@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
       
    ],
    exports: [
       CommonModule
    ]
})
export class StatusDetailsDialogModule {}
