import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-icdc-info-dialog',
  templateUrl: './icdc-info-dialog.component.html',
  styleUrls: ['./icdc-info-dialog.component.scss']
})
export class IcdcInfoDialogComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data) { }
  displayedColumns = ['no', 'productCode', 'brandName', 'approvedCases', 'approvedBottles', 'mrp', 'totalValue', 'status']
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data.productDetails);
  
  }

}
