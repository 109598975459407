import { NgxSpinnerService } from 'ngx-spinner';
import { UtilService } from 'src/app/core/utils/utility-helper';
import { CommonsService } from 'src/app/core/services/commons.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ShipmentsService } from 'src/app/core/services/shipments.service';
import { MessageService } from 'primeng/api';
declare const JsBarcode: any;

@Component({
  templateUrl: './supplier-evc-info-dialog.component.html',
  styleUrls: ['./supplier-evc-info-dialog.component.scss']
})
export class SupplierEvcInfoDialogComponent implements OnInit {

  displayedColumns: any[] = [
    'no',
    'productCode',
    'brandName',
    'batchId',
    'sizeCode',
    'shippedCases',
    'recievedCases',
    'recievedBottles',
    'damagedCases',
    'damagedBottles',
    'shortageCases',
    'shortageBottles' 
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  productData: any[];
  ofsData: any = [];
  roleName: any;
  totalShipped:any=0;
  receivedQtyFull:any=0;
  receivedQtyPart:any=0; 
  breakageCases: any = 0;
  breakageBtl:any=0;
  depotName:any;
  address:any;
  selectedOfs: any;
  totalDatas: any = [];
  newScannedCasesBottles: any = [];
  selectedOfsIndex: any;
  shortages: any = [];
  shortageLimit: any = [];
  remainingQtys: any = [];
  qtys: any = {};
  disableEvc:any=false;
  disableVerify: any = false;

  constructor(public dialogRef: MatDialogRef<SupplierEvcInfoDialogComponent>,
     @Inject(MAT_DIALOG_DATA)public data, private shipmentsService: ShipmentsService,
              private dialog: MatDialog, private commonService: CommonsService,
              public util: UtilService,
              private spinner: NgxSpinnerService,
              private messageService: MessageService
  ) {
    this.roleName = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).role;
    this.depotName =JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).entity_id;
    if (this.roleName === 'Distillery Manager') {
      this.address = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).distillery_address;
    } else {
      this.address = JSON.parse(sessionStorage.getItem('lappCurrentUserModules')).depot_address;
    }
   }

  ngOnInit(): void {
    console.log('this.data.get_evc', this.data.get_evc);
    console.log(this.data,"33::::")
    this.productData = [];
    this.ofsData = this.data['consignment'];
    if (this.roleName === 'Store Officer' && this.data.status === 'TPReceived') {
      this.displayedColumns.push('action-update');
    }
    this.getScannedBottle();
  }

  async getScannedBottle(): Promise<any> {
    const isShortageStatus: any = [];
    let repackBottleCount = {};
    this.ofsData.forEach((ofs: any, j) => {
      if (ofs.ofidtNo) {
        ofs.ofsCode = ofs.ofidtNo; 
      }
      // const repackProductIndex = ofs.productDetails.findIndex(product => product.breakages && product.breakages.bottles && product.breakages.bottleBreakages && product.breakages.bottleBreakages.length === 0);
      const repackProductDatas = ofs.productDetails.find(product => product.breakages && product.breakages.bottles && product.breakages.bottleBreakages && product.breakages.bottleBreakages.length === 0);
      if (repackProductDatas) {
        if (!repackBottleCount[repackProductDatas.productCode]) {
          repackBottleCount[repackProductDatas.productCode] = 0;
        }
        repackBottleCount[repackProductDatas.productCode] += repackProductDatas.breakages.bottles;
      }
    });
    this.ofsData.forEach((ofs: any, j) => {
      this.shortages[j] = [];
      if (!this.newScannedCasesBottles[j]) {
        this.newScannedCasesBottles[j] = [];
        this.qtys[j] = [];
      }
      ofs.productDetails.forEach((product: any, i) => {
        if (this.data.consignmentType === 'OFIDT') {
          product.shipmentQty = product.approvedCases;
        }
        if (!this.newScannedCasesBottles[j][i]) {
          this.newScannedCasesBottles[j][i] = {};
          this.newScannedCasesBottles[j][i].cases = 0;
          this.newScannedCasesBottles[j][i].bottles = 0;
          this.newScannedCasesBottles[j][i].breakageBottles = 0;
          this.newScannedCasesBottles[j][i].breakageCases = 0;
          this.newScannedCasesBottles[j][i].breakageCaseQty = 0;
          this.qtys[j][i] = {};
          this.qtys[j][i].shortages = {};
          this.qtys[j][i].shortages = {cases: 0, bottles: 0};
          this.qtys[j][i].breakages = {cases: 0, bottles: 0};
        }
        product.unitsPerCase = this.util.productCodeToDetails(product.productCode).unitsPerCase;
        const productType = this.util.getProductType(product.productCode);
        let cases: any = product.casesCount ? product.casesCount : 0;
        let bottles: any = 0;
        const breakageCases: any = product.breakages && product.breakages.cases ? product.breakages.cases : 0;
        let breakageBottles: any = product.breakages && product.breakages.bottles ? product.breakages.bottles : 0;
        let bottleBreakagesLength = product.breakages && product.breakages.bottleBreakages ? product.breakages.bottleBreakages.length : 0;
        const shortageCases: any = product.shortages && product.shortages.cases ? product.shortages.cases : 0;
        const shortageBottles: any = product.shortages && product.shortages.bottles ? product.shortages.bottles : 0;
        if (productType === 'Beer') {
          bottles = ((product.shipmentQty * product.unitsPerCase) - (cases * product.unitsPerCase)) - ((breakageCases * product.unitsPerCase) + breakageBottles + (shortageCases * product.unitsPerCase) + shortageBottles);
          cases = cases + Math.floor(bottles/product.unitsPerCase);
          bottles = bottles % product.unitsPerCase;
        } else {
          if (bottleBreakagesLength) { 
            const caseCodes: any = product.breakages.bottleBreakages.map(el => el.caseCode);
            const isDuplicate: any = caseCodes.filter((c, idx) => caseCodes.indexOf(c) != idx);
            if (isDuplicate.length) {
              bottleBreakagesLength = bottleBreakagesLength - isDuplicate.length;
            }
            bottles = (bottleBreakagesLength * product.unitsPerCase) - (breakageBottles);
          }
          if (shortageBottles) {
            if (shortageBottles > 0 && shortageBottles <= bottles) {
              bottles = bottles - shortageBottles; 
            } else if (shortageBottles > 0 && shortageBottles > bottles) {
              // if (shortageBottles > 0 && shortageBottles <= bottles) {
              //  bottles = bottles - shortageBottles;
              // } else if (shortageBottles > 0 && shortageBottles > bottles) {
              //   // bottles = (product.unitsPerCase - shortageBottles) + bottles;
              //   bottles = (product.unitsPerCase - ((shortageBottles + bottles) % product.unitsPerCase));
              // }
              const sQty = product.shipmentQty * product.unitsPerCase;
              const rQty = cases * product.unitsPerCase;
              bottles = sQty - rQty - breakageBottles - shortageBottles;
            }
          }
        }
        if (product.breakages && product.breakages.bottles && product.breakages.bottleBreakages && product.breakages.bottleBreakages.length === 0) {
          bottles = 0;
          breakageBottles = 0;
        }
        if (repackBottleCount[product.productCode] && repackBottleCount[product.productCode] > 0 && product.breakages && product.breakages.bottles && product.breakages.bottleBreakages.length > 0) {
          bottles = bottles - repackBottleCount[product.productCode];
          breakageBottles = breakageBottles + repackBottleCount[product.productCode];
          repackBottleCount[product.productCode] = 0;
        }
        this.shortages[j][i] = {cases: parseInt(shortageCases, 10), bottles: parseInt(shortageBottles, 10)};
        this.newScannedCasesBottles[j][i] = {cases: cases, bottles: bottles, breakageBottles: breakageBottles,breakageCases:breakageCases,breakageCaseQty:0};
        this.qtys[j][i].shortages.cases = shortageCases;
        this.qtys[j][i].shortages.bottles = shortageBottles;
        this.qtys[j][i].breakages.cases = breakageCases;
        this.qtys[j][i].breakages.bottles = breakageBottles;
        let approvedQty=0;
        let scannedQty =0;
        if(this.data.consignmentType === 'Import Permit'){
          approvedQty += product.batchApprovedQty ? product.batchApprovedQty :product.shipmentQty;
          scannedQty += product.casesCount ? product.casesCount : 0;
          const totalBottles = approvedQty * product.unitsPerCase;
          const scannedBottles = scannedQty * product.unitsPerCase;
          const totalScannedBottles = scannedBottles + (breakageCases * product.unitsPerCase) + breakageBottles;
          console.log(totalBottles,totalScannedBottles)
          if(totalBottles != totalScannedBottles){
            console.log("NE")
            this.disableEvc = true;
          }

        }
      });
      const status: any = ofs.productDetails.every(el => el.caseStatus && el.bottleStatus);
      isShortageStatus.push(status);
    });
    if (this.roleName === 'Store Officer' && this.data.status === 'TPReceived') {
      this.checkTotalBottles();
    }
    const status = isShortageStatus.every(el => el === true);
    if (status === true) {
      const indx: any = this.displayedColumns.findIndex(el => el === 'action-update');
      if (indx !== -1 && indx === this.displayedColumns.length - 1) {
        this.displayedColumns.pop();
      }
    }
  
    this.getShortageLimit();
    
    this.getSubTotal();
  }
  getShortageLimit(): any {
    this.ofsData.forEach((ofs: any, j) => {
      this.shortageLimit[j] = [];
      ofs.productDetails.forEach((product: any, i) => {
        const receivedCases = this.newScannedCasesBottles[j][i].cases;
        const receivedBottles = this.newScannedCasesBottles[j][i].bottles;
        const bottlesToCases: any = Math.floor(receivedBottles / product.unitsPerCase);
        const availableCases: any = bottlesToCases + receivedCases;
        const availableBottles: any = receivedBottles - (bottlesToCases * product.unitsPerCase);  
        this.shortageLimit[j][i] = {};
        this.shortageLimit[j][i].cases = availableCases;
        this.shortageLimit[j][i].bottles = availableBottles;
      });
    });
  }
  checkShortageLimit(j, i, product): any {
    const receivedCases = this.newScannedCasesBottles[j][i].cases;
    const receivedBottles = this.newScannedCasesBottles[j][i].bottles;
    const bottlesToCases: any = Math.floor(receivedBottles / product.unitsPerCase);
    const availableCases: any = bottlesToCases + receivedCases;
    const availableBottles: any = receivedBottles - (bottlesToCases * product.unitsPerCase);
    if (this.shortages[j][i].cases < receivedCases) {
      this.shortageLimit[j][i].cases = availableCases - 1;
      this.shortageLimit[j][i].bottles = product.unitsPerCase - 1;
    } else {
      this.shortageLimit[j][i].cases = availableCases;
      this.shortageLimit[j][i].bottles = availableBottles;
    }

  }

  updateAction(){
    this.spinner.show();
    let totalScannedproductCount = 0;
    let totalScannedCount = 0;
    let caseBottlesData:any=[];
    let datas:any=[]
    this.ofsData.forEach((ofs: any,j) => {
      ofs.productDetails.forEach((item: any,i) => {
        if (item.scannedBottlesCount){
          totalScannedproductCount += item.scannedBottlesCount;
          ofs.scannedBottlesCount = totalScannedproductCount;
        }
        // let productsList:any={}
        // const batchId = item.batchId ? item.batchId : '';
        // const productCode = item.productCode
        // const caseCounts = this.newScannedCasesBottles[j][i].cases
        // const totalscannedBottles = this.newScannedCasesBottles[j][i].bottles
        // const ofsCode = ofs.ofsCode
        // productsList = {productCode,caseCounts,totalscannedBottles,ofsCode, batchId}
        const productsDatas: any = {
          productCode: item.productCode,
          caseCounts: this.newScannedCasesBottles[j][i].cases,
          totalscannedBottles: this.newScannedCasesBottles[j][i].bottles,
          ofsCode: ofs.ofsCode,
        };
        this.changeOfsToOfIDT(productsDatas, ofs.ofsCode);
        if (this.data.sourceEntityType === 'Breweries' || this.data.consignmentType === 'Import Permit' ) {
          productsDatas.batchId = item.batchId ? item.batchId : '';
        }
        datas.push(productsDatas)
      });
    });
    let payload:any;
    if(this.data.consignmentType === 'Import Permit'){
      payload = {
        "action": "add_checkList",
        data: {
          ipNo: this.data.ipNo,
          productData: datas
        }
      }
    }else{
      payload = {
        "action": "add_checkList",
        data: {
          tpNo: this.data.tpNo,
          scannedBottles: totalScannedCount,
          productData: datas
        }
      }
    }
    if (this.data.btpNo) {
      delete payload.data.tpNo;
      payload.data.btpNo = this.data.btpNo;
    } else if (this.data.bipNo) {
      delete payload.data.tpNo;
      payload.data.bipNo = this.data.bipNo;
    }
    this.shipmentsService.generateActions(payload).subscribe((result:any) => {
      if(result.status === 'SUCCESS') {
        this.dialogRef.close('Verified successfully');
      }else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'EVC verification failed' });
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    })
  }

  updateStatus(status) {
    if(status == 'Approved'){
          const req_Data = {
            action: 'approve_evc',
            data: {
                status
            }
          }
      
          if(this.data.consignmentType === "Import Permit"){
            req_Data.data['ipNo'] = this.data.ipNo;
          }else{
            req_Data.data['tpNo'] = this.data.tpNo;
          }
          this.spinner.show();
          this.shipmentsService.approveEvc(req_Data).subscribe(result => {
            this.spinner.hide();
            this.dialogRef.close("EVC Updated Successfully")
          }, err => {
            this.spinner.hide();
          }) 
    }else{
      const req_Data = {
        action: 'approve_evc',
        data: {
            status
        }
      }
  
      if(this.data.consignmentType === "Import Permit"){
        req_Data.data['ipNo'] = this.data.ipNo;
      }else{
        req_Data.data['tpNo'] = this.data.tpNo;
      }
      this.spinner.show();
      this.shipmentsService.approveEvc(req_Data).subscribe(result => {
        this.spinner.hide();
        this.dialogRef.close("EVC Updated Successfully")
      }, err => {
        this.spinner.hide();
      }) 
    }


  }
    ngAfterViewInit(): any {
      if (this.data && this.data.evcNo) {
        JsBarcode('#barcode-evc', this.data.evcNo,
          {
            width: 1,
            height: 40
          }
        );
      }
  }
  dialogClose(): void{
    this.dialogRef.close('');
  }
  Print(item, j){
    this.selectedOfs = item;
    this.selectedOfsIndex = j;
    setTimeout(() => {
      let printContents, popupWin;
      printContents = document.getElementById("print-excise-verification-certificate").innerHTML;
      popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
      popupWin.document.write(`${printContents}`);
      popupWin.document.close();
    }, 500);
  }
  getSubTotal(): any {
    this.totalDatas = {};
    this.ofsData.forEach((ofs: any, j) => {
      this.totalDatas[ofs.ofsCode] = {};
      this.totalDatas[ofs.ofsCode].shipmentQty = 0;
      this.totalDatas[ofs.ofsCode].casesCount = 0;
      this.totalDatas[ofs.ofsCode].totalScannedBottles = 0;
      this.totalDatas[ofs.ofsCode].breakageCases = 0;
      this.totalDatas[ofs.ofsCode].breakageBottles = 0;
      this.totalDatas[ofs.ofsCode].shortageCases = 0;
      this.totalDatas[ofs.ofsCode].shortageBottles = 0;
      ofs.productDetails.forEach((item: any, i) => {
        this.totalDatas[ofs.ofsCode].productCode = item.productCode;
        this.totalDatas[ofs.ofsCode].shipmentQty += item.shipmentQty ? parseInt(item.shipmentQty, 10) : 0;
        this.totalDatas[ofs.ofsCode].casesCount += this.newScannedCasesBottles[j][i].cases ? this.newScannedCasesBottles[j][i].cases : 0;
        this.totalDatas[ofs.ofsCode].totalScannedBottles += this.newScannedCasesBottles[j][i].bottles ? this.newScannedCasesBottles[j][i].bottles : 0;
        this.totalDatas[ofs.ofsCode].breakageCases += item.breakages && item.breakages.cases ? item.breakages.cases : 0;
        this.totalDatas[ofs.ofsCode].breakageBottles += this.newScannedCasesBottles[j][i].breakageBottles ? this.newScannedCasesBottles[j][i].breakageBottles : 0;
        this.totalDatas[ofs.ofsCode].shortageCases += item.shortages && item.shortages.cases ? item.shortages.cases : 0;
        this.totalDatas[ofs.ofsCode].shortageBottles += item.shortages && item.shortages.bottles ? item.shortages.bottles : 0;
        if(item.batchIds && item.batchIds.length){
          const batchIdData = item.batchIds.map(batch => batch).filter((value, index, self) => self.indexOf(value) === index);
          item.batchList = batchIdData
        }
      });
    });
  }
  updateDamage(ofsCode, product, j, i): any {
    const casesBottlesCount = this.getCasesCount(product, j, i, 'damage');
    const req_Data: any = {
      action: 'add_beer_breakages',
      data: {
        btpNo: this.data.btpNo,
        ofsCode: ofsCode,
        productCode: product.productCode,
        bottles: parseInt(this.newScannedCasesBottles[j][i].breakageBottles, 10),
        shipmentQty: product.shipmentQty,
        casesCount: casesBottlesCount.casesCount,
        partBottles: casesBottlesCount.bottlesCount,
        batchId: product.batchId ? product.batchId : ''
      }
    };
    this.changeOfsToOfIDT(req_Data.data, ofsCode);
    if (this.data.bipNo) {
      delete req_Data.data.btpNo;
      req_Data.data.bipNo = this.data.bipNo;
    }
    this.spinner.show();
    this.shipmentsService.saveShipmentQuantity(req_Data).subscribe((result: any) => {
      if (result && result.response === 'None') {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Updated' });
        this.spinner.hide();
        return;
      }
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Damage bottles updated' });
      if (result && result.status && result.status.consignment) {
        this.ofsData = result.status.consignment;
      }
      this.getScannedBottle();
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Updated' });
    });
  }

  updateDamageCases(ofsCode, product, j, i): any {
    const casesBottlesCount = this.getCasesCount(product, j, i, 'damage');
    const req_Data: any = {
      action: 'add_ip_case_breakages',
      data: {
        ipNo: this.data.ipNo,
        ofsCode: ofsCode,
        productCode: product.productCode,
        cases: parseInt(this.newScannedCasesBottles[j][i].breakageCaseQty, 10),
        shipmentQty: product.shipmentQty,
        casesCount: casesBottlesCount.casesCount,
        partBottles: casesBottlesCount.bottlesCount,
        batchId: product.batchId ? product.batchId : ''
      }
    };
    this.changeOfsToOfIDT(req_Data.data, ofsCode);
    if (this.data.bipNo) {
      delete req_Data.data.btpNo;
      req_Data.data.bipNo = this.data.bipNo;
    }
    console.log(req_Data)
    this.spinner.show();
    this.shipmentsService.saveShipmentQuantity(req_Data).subscribe((result: any) => {
      if (result && result.response === 'None') {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Updated' });
        this.spinner.hide();
        return;
      }
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Damage cases updated' });
      if (result && result.status && result.status.consignment) {
        this.ofsData = result.status.consignment;
      }
      this.getScannedBottle();
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Updated' });
    });
  }
  updateShortage(value, product, ofsCode, productCode, j, i): any {
    const casesBottlesCount = this.getCasesCount(product, j, i, 'shortage');
    value = parseInt(value, 10);
    const req_Data: any = {
      action: 'add_shortages',
      data: {
        tpNo: this.data.tpNo,
        ofsCode: ofsCode,
        productCode: productCode,
        casesCount: casesBottlesCount.casesCount,
        partBottles: casesBottlesCount.bottlesCount,
        batchId: product.batchId ? product.batchId : ''
      }      
    };
    this.changeOfsToOfIDT(req_Data.data, ofsCode);
    if (this.data.btpNo) {
      delete req_Data.data.tpNo;
      req_Data.data.btpNo = this.data.btpNo;
    } else if (this.data.bipNo) {
      delete req_Data.data.tpNo;
      req_Data.data.bipNo = this.data.bipNo;
    } else if (this.data.ipNo) {
      delete req_Data.data.tpNo;
      req_Data.data.ipNo = this.data.ipNo;
    }
    if (this.shortages[j][i].cases && !product.caseStatus) {
      req_Data.data.cases = parseInt(this.shortages[j][i].cases, 10);
    }
    if (this.shortages[j][i].bottles && !product.bottleStatus) {
      req_Data.data.bottles = parseInt(this.shortages[j][i].bottles, 10);
    }
    this.spinner.show();
    this.shipmentsService.saveShipmentQuantity(req_Data).subscribe((result: any) => {
      if (result && result.response === 'None') {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Updated' });
        this.spinner.hide();
        return;
      }
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Shortages Updated' });
      if (result && result.status && result.status.consignment) {
        this.ofsData = result.status.consignment;
      }
      this.getScannedBottle();
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Updated' });
    });
  }
  getRemainingQty(row, j, i, mode = ''): any {
    const shipmentQty = row.shipmentQty * row.unitsPerCase;
    if (this.shortages[j] && this.shortages[j][i] && this.newScannedCasesBottles[j] && this.newScannedCasesBottles[j][i]) {
      if (!this.remainingQtys[j]) {
        this.remainingQtys[j] = [];
      }
      if (!this.remainingQtys[j][i]) {
        this.remainingQtys[j][i] = {};
        this.remainingQtys[j][i].remainingBottlesQty = 0;
        this.remainingQtys[j][i].remainingCases = 0;
      }
      if (mode === 'damage') {
        this.shortages[j][i].cases = this.qtys[j][i].shortages.cases;
        this.shortages[j][i].bottles = this.qtys[j][i].shortages.bottles;
      } else if (mode === 'shortage') {
        this.newScannedCasesBottles[j][i].breakageBottles = this.qtys[j][i].breakages.bottles;
      }
      const shortageCases = this.shortages[j][i].cases;
      const receivedCases = this.newScannedCasesBottles[j][i].cases;
      const receivedBottles = this.newScannedCasesBottles[j][i].bottles;
      const shortageBottles = this.shortages[j][i].bottles;
      const shortageQty = (shortageCases * row.unitsPerCase) + shortageBottles;
      const breakageCases = row.breakages && row.breakages.cases ? parseInt(row.breakages.cases, 10) : 0;
      const breakageBottles = this.newScannedCasesBottles[j][i].breakageBottles;
      const breakageQty = (breakageCases * row.unitsPerCase) + breakageBottles;
      const receivedQty = (receivedCases * row.unitsPerCase) + receivedBottles;
      if(this.data.ipNo){
        this.remainingQtys[j][i].remainingBottlesQty = shipmentQty - (receivedQty + shortageQty + breakageQty);
      }else{
        this.remainingQtys[j][i].remainingBottlesQty = shipmentQty - shortageQty - breakageQty;
      }
      this.remainingQtys[j][i].remainingCases = Math.floor(this.remainingQtys[j][i].remainingBottlesQty/row.unitsPerCase);
      if (this.remainingQtys[j][i].remainingBottlesQty < 0 || this.remainingQtys[j][i].remainingCases < 0) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Cases/Bottles exceeded' });
      }
      if (mode === 'shortage' && shortageCases > row.casesCount) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Cases exceeded' });
      }
    }
  }
  getCasesCount(product, j, i, mode = ''): any {
    let casesCount = 0;
    let bottlesCount = 0;
    const upc = product.unitsPerCase ? product.unitsPerCase : 0;
    const receivedCases = product.casesCount ? parseInt(product.casesCount, 10) : 0;
    const receivedBottles = this.newScannedCasesBottles[j] && this.newScannedCasesBottles[j][i] ? parseInt(this.newScannedCasesBottles[j][i].bottles, 10) : 0;
    const breakageCases = product.breakages && product.breakages.cases ? parseInt(product.breakages.cases, 10) : 0;
    const breakageBottles = this.newScannedCasesBottles[j] && this.newScannedCasesBottles[j][i] ? parseInt(this.newScannedCasesBottles[j][i].breakageBottles, 10) : 0;
    const breakageBottlesQty = (breakageCases * upc) + breakageBottles;
    const shortageCases = this.shortages[j] && this.shortages[j][i] && this.shortages[j][i].cases ? parseInt(this.shortages[j][i].cases, 10) : 0;
    const shortageBottles = this.shortages[j] && this.shortages[j][i] && this.shortages[j][i].bottles ? parseInt(this.shortages[j][i].bottles, 10) : 0;
    const shortageBottlesQty = (shortageCases * upc) + shortageBottles;
    if (mode === 'damage' && receivedBottles > 0 && breakageBottles > 0 && breakageBottles <= receivedBottles && receivedBottles < upc) {
      casesCount = receivedCases;
      bottlesCount = receivedBottles - breakageBottles;
    } else if (mode === 'shortage' && receivedBottles > 0 && shortageBottles > 0 && shortageBottles <= receivedBottles && receivedBottles < upc) {
      casesCount = receivedCases;
      bottlesCount = receivedBottles - shortageBottles;
    } else {
      casesCount = (product.shipmentQty) - Math.ceil((breakageBottlesQty + shortageBottlesQty)/upc);
      bottlesCount = (product.shipmentQty * upc) - (casesCount * upc) - (breakageBottlesQty + shortageBottlesQty);
    }
    return {casesCount: casesCount, bottlesCount: bottlesCount};
  }
  changeOfsToOfIDT(obj: any = {}, value): any {
    if (this.data.consignmentType === 'OFIDT') {
      delete obj.ofsCode;
      obj.ofidtNo = value;
    }
  }
  checkTotalBottles(): any {
    this.disableVerify = false;
    this.disableVerify = this.ofsData.some((ofs, j) => {
      return ofs.productDetails.some((prod, i) => {
        const upc = prod.unitsPerCase;
        const shippedBottles = prod.shipmentQty * upc;
        const receivedBottles = (this.newScannedCasesBottles[j][i].cases * upc) + this.newScannedCasesBottles[j][i].bottles;
        const breakageBottles = (this.newScannedCasesBottles[j][i].breakageCases * upc) + this.newScannedCasesBottles[j][i].breakageBottles;
        const shortageBottles = (prod.shortages && prod.shortages.cases ? prod.shortages.cases * upc : 0) + (prod.shortages && prod.shortages.bottles ? prod.shortages.bottles : 0);
        return shippedBottles !== (receivedBottles + breakageBottles + shortageBottles);
      });
    });
  }

}
