const liquorTypes = {
    'W': 'WHISKY',
    'B': "BEER",
    'R': 'RUM',
    'V': 'VODKA',
    'T': 'TEQUILA',
    'E': 'WINE',
    'Y': 'BRANDY',
    'M': 'BREEZER',
    'L': 'LIQUEUR',
    'G': 'GIN',
    'P': 'SPIRITS',
    'C': 'SAMBUCA'
}

const spiritTypes = {
    'E': 'ENA',
    'R': 'RS',
    'M': 'MALT',
    'G': 'GRAPE',
    'H': 'HTS',
    'O': 'OTHER SPIRITS'
}

export { liquorTypes, spiritTypes }