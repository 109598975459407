import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { CommonsService } from 'src/app/core/services/commons.service';
import { HologramsService } from 'src/app/core/services/holograms.service';
import { UtilService } from 'src/app/core/utils/utility-helper';

@Component({
  selector: 'app-manage-heal-purchase-indent-dialog',
  templateUrl: './manage-heal-purchase-indent-dialog.component.html',
  styleUrls: ['./manage-heal-purchase-indent-dialog.component.scss']
})
export class ManageHealPurchaseIndentDialogComponent implements OnInit {
  healPurchaseIndentForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  balanceAmount: any;
  saveDisabled: boolean;
  challanType: string = '';
  purposes = ['Local', 'Export', 'CSD', 'CSD Local', 'CSD Export','Import Permit'];
  todayDate = new Date();
  HpiList:any=[];
  challanNumber:Boolean;
  transactionDetails:any;
  invalidChallanNo:Boolean;
  challanNoError='Invalid Challan No.';
  duplicateError = "Challan Number already exist";
  loader: any = false;
  isValid:any=false;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageHealPurchaseIndentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, 
    private messageService: MessageService,
    private hologramsService: HologramsService, public util: UtilService,
    private commonService: CommonsService) { }

  ngOnInit(): void {
    this.healPurchaseIndentForm = this.fb.group({
      // indentNumber: ['', [Validators.required, ]],
      purpose: ['Local'],
      requestedQuantity: new FormControl('', [
        Validators.max(110000000), Validators.required
      ]),
      indentValue: ['', Validators.required],
      challanId: ['', Validators.required],
      challanAmount: [''],
      challanDate: [''],
      carrierName: ['', Validators.required],
      stoBank: ['']
      // createdOn: '',
    });
    this.healPurchaseIndentForm.patchValue({
      // indentNumber : this.data.indentNumber,
      purpose: 'Local',
    });
    //if (this.data && this.data._id) {
    if (this.data) {
      this.healPurchaseIndentForm.patchValue({
        // indentNumber : this.data.indentNumber,
        purpose: this.data.purpose,
        requestedQuantity: this.data.requestedQuantity,
        challanId: this.data.challanId,
        challanAmount: this.data.challanAmount,
        challanDate: this.data.challanDate,
        carrierName: this.data.carrierName,
        stoBank: this.data.stoBank
      });
    }
   
    this.getHealPurchaseIndents()
    
    
    // this.commonService.getCommonEntites(req_Data).subscribe((res) => {
    //   if(res['get_challan_amount']){
    //     const formattedPrice = new Intl.NumberFormat("en-US", {
    //       style: "currency",
    //       currency: "INR",
    //     }).format(res['get_challan_amount'].balanceAmount);
    //     this.balanceAmount = formattedPrice;
    //   }
    // });

    this.healPurchaseIndentForm.valueChanges.subscribe((change) => {
      if (change.indentValue > change.challanAmount) {
        this.saveDisabled = true;
      }
    });
  }

  get f() {
    return this.healPurchaseIndentForm.controls;
  }
  changePurpose(){
    this.healPurchaseIndentForm.controls.requestedQuantity.setValue('');
    this.healPurchaseIndentForm.controls.indentValue.setValue('')
    this.healPurchaseIndentForm.controls.challanId.setValue('')
    this.healPurchaseIndentForm.controls.challanAmount.setValue('')
    this.healPurchaseIndentForm.controls.challanDate.setValue('')
    this.healPurchaseIndentForm.controls.carrierName.setValue('')
    this.healPurchaseIndentForm.controls.stoBank.setValue('')


  }

  getHealPurchaseIndents(){
    this.hologramsService.getHologramsFeatures().subscribe((res:any)=>{
      this.HpiList= res['HEAL Purchase Indent'];
     
    })
  }

  checkDuplicateChallans(event){
    this.loader = true;
    this.hologramsService.getHologramsFeatures().subscribe(res => {
      this.challanNumber =  event.target.value;
      const duplicateTransaction= res['HEAL Purchase Indent'].some(challanRecord => challanRecord.challanId === event.target.value);
      if(duplicateTransaction){
        this.messageService.add({ severity: 'error', detail: this.duplicateError });
      }else{
        this.validateChallan(event);
      }
      this.loader = false;
    })
  }

  validateChallan(evt){   
      let qData = {
        "data": { "value": evt.target.value, "action": "get_heal_purchase_payment_details" }
      };
      const req_Data = {
        "action": "common_apis",
        "q": JSON.stringify(qData)
      };
  
      this.commonService.getCommonEntites(req_Data).subscribe((res) => {
        this.transactionDetails = res['get_heal_purchase_payment_details'];
        if(!this.transactionDetails.status){
          this.healPurchaseIndentForm.patchValue({
            challanAmount: this.transactionDetails.amount,
            stoBank: 'Bank Of Baroda',
            challanDate: this.transactionDetails.bankTransactionDate,
          });
          this.requestedChange(this.transactionDetails.amount);
        }else{
          this.messageService.add({ severity: 'error', detail: 'Invalid challan Number.Please enter a valid one' });
        }
        
      });

  }
  requestedChange(amount) {
    let amountData = (((amount) * 100) / 42.73).toFixed()
    this.healPurchaseIndentForm.patchValue({
      requestedQuantity: Number(amountData),
      indentValue: amount
    })
  }
  checkQuantity(e:any){
    const value = e.target.value;
    const isValid = value % 20000;
    console.log(isValid)
    isValid === 0 ? this.isValid = false : this.isValid = true;
   
  }
 
  createHealPurchaseIndents() {
    this.saveDisabled = true;
    const req_Data = {
      action: this.healPurchaseIndentForm.value.purpose === 'Import Permit' ? 'add_ip_heal_purchase_indent' : 'add_heal_purchase_indent',
      data: this.healPurchaseIndentForm.value
    }
    if(this.healPurchaseIndentForm.value.purpose === 'Import Permit'){
      delete req_Data.data.indentValue
      delete req_Data.data.challanId
      delete req_Data.data.challanAmount
      delete req_Data.data.challanDate
      delete req_Data.data.stoBank
      delete req_Data.data.carrierName
    }
    console.log(req_Data);
    this.loader = true;
    this.hologramsService.createHealPurchaseIndents(req_Data).subscribe((res: any) => {
      if(res.status === 'SUCCESS'){
        this.dialogRef.close('Heal Purchase Indent added Successfully');
        this.loader = false;
      }else{
        this.messageService.add({ severity: 'error', detail: 'Heal Purchase Indent adding failed' });
        this.loader = false;
      }

    }, (err) => {
      this.error = err.error.message;
      this.loader = false;
    });
  }

}
