import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IndentManagementService } from 'src/app/core/services/indent-management.service';

@Component({
  templateUrl: './manage-ena-indent-dialog.component.html',
  styleUrls: ['./manage-ena-indent-dialog.component.scss']
})
export class ManageEnaIndentDialogComponent implements OnInit {

  ENAIndentForm: FormGroup;
  error: string;
  page: number;
  limit: number;
  saveDisabled: boolean;
  kindOfSpirits = ['ENA1', 'ENA2'];


  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageEnaIndentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private indentManagementService: IndentManagementService) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
    this.ENAIndentForm = this.fb.group({
      indentNo: ['',[Validators.required]],
      date: ['',[Validators.required]],
      serialNo: ['',[Validators.required]],
      spiritKind: ['ENA'],
      storageTankNo: ['',[Validators.required]],
      enaTankNo: ['',[Validators.required]],
      quantityInBls: ['',[Validators.required]],
      quantityInPls: ['',[Validators.required]],
      brandName: ['',[Validators.required]],
      category: ['',[Validators.required]],
      batchNo: ['',[Validators.required]],
    });
    if (this.data && this.data._id) {
      this.ENAIndentForm.patchValue({
        indentNo : this.data.indentNo,
        date: this.data.tank._id,
        serialNo: this.data.serialNo,
        spiritKind: 'ENA',
        storageTankNo: this.data.storageTankNo,
        enaTankNo: this.data.enaTankNo,
        quantityInBls: this.data.quantityInBls,
        quantityInPls: this.data.quantityInPls,
        brandName: this.data.brandName,
        category: this.data.category,
        batchNo: this.data.batchNo,
      });
    }
    this.ENAIndentForm.valueChanges.subscribe((change) => {
      this.saveDisabled = false;
    });
  }

  get f(){
    return this.ENAIndentForm.controls;
  }

  createENAIndent() {
    this.saveDisabled = true;
    const req_data ={
      action: 'add_blend_indents',
      data: {...this.ENAIndentForm.value}
    };
    this.indentManagementService.createENAIndent(req_data).subscribe((res: any) => {
      this.dialogRef.close('ENAIndent Created Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  updateENAIndent() {
    // this.saveDisabled = true;
    // const obj = [{
    //   _id: this.data._id,
    //   update: this.ENAIndentForm.value
    // }]
    // this.spiritService.putBatch(obj).subscribe((res: any) => {
    //   this.dialogRef.close('Batch Udated Successfully');
    // }, (err) => {
    //   this.error = err.error.message;
    // });
  }

}
