import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Product } from 'src/app/core/models/production/product.model';
import { TransportPermit } from 'src/app/core/models/supply-delivery/transport-permit';
import { BreakagesService } from 'src/app/core/services/breakages.service';
import { ProductionService } from 'src/app/core/services/production.service';
import { ManageStorageDialogComponent } from '../../breakages/storage/manage-storage-dialog/manage-storage-dialog.component';

@Component({
  selector: 'app-manage-breakages-entry-dialog',
  templateUrl: './manage-breakages-entry-dialog.component.html',
  styleUrls: ['./manage-breakages-entry-dialog.component.scss']
})
export class ManageBreakagesEntryDialogComponent implements OnInit {

  breakageForm: FormGroup;
  error: string;
  products: Product[];
  transportPermits: TransportPermit[];
  saveDisabled: boolean;
  damages: FormArray;

  constructor(private fb: FormBuilder, private dialogRef: MatDialogRef<ManageStorageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private breakageService: BreakagesService) { }

  ngOnInit(): void {
    this.breakageForm = this.fb.group({
      icdcCode: ['', Validators.required],
      damages: this.fb.array([this.createDamages()]),
    });
    this.damages = this.breakageForm.get('damages') as FormArray;
  }

  createDamages() {
    return this.fb.group({
      caseCode : '',
      healCodes : '',
      bottleCount : 0
    });
  }

  handleHeals(evt,i) {
    (this.breakageForm.controls['damages'] as FormArray).at(i).get('bottleCount').setValue((this.breakageForm.controls['damages'] as FormArray).at(i).get('healCodes').value.length);
  console.log((this.breakageForm.controls['damages'] as FormArray).at(i).get('healCodes').value,'ccccc:::::', evt.value);
  }

  addDamages() {
    (this.breakageForm.controls['damages'] as FormArray).push(this.createDamages());
  }

  createBreakageRecord() {
    let data = this.breakageForm.value;

    data.damages = data.damages.map((damage,i) => {
      if(!damage.healCodes) {
        delete damage['healCodes'];
      }
      if(!damage.caseCode) {
        delete damage['caseCode'];
      }
      if(!damage.bottleCount) {
        delete damage['bottleCount'];
      }
      return damage;
    })
    data.damages = data.damages.filter(damage => {
      return Object.keys(damage).length>0
    })
    this.saveDisabled = true;
    const req_Data = {
      action : 'add_f3_breakages',
      data 
    };

    console.log(this.breakageForm.value,data, "60::::");
    this.breakageService.createRetailerBreakages(req_Data).subscribe((res: any) => {
      console.log(res)
      this.dialogRef.close('Breakage Entry Created Successfully');
    }, (err) => {
      this.error = err.error.message;
    });
  }

  get f(){
    return this.breakageForm.controls;
  }

}
