import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/distillery-info/user.model';

const baseUrl = environment.baseUrl;
const depotUrl = baseUrl + 'depots/depotinfo';
const userUrl = baseUrl + 'users';

@Injectable({
  providedIn: 'root',
})
export class DepotInfoService {
  currentUser: any;
  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(
      sessionStorage.getItem('lappCurrentUserDetails')
    );
  }

  getDepotInfo(all?: boolean): Observable<any> {
    const paramObj: any = {};
    all === true ? paramObj.all = true : null;
    return this.http.get(depotUrl, {params: paramObj});
  }

  putDepotInfo(depotDetails) {
    return this.http.put(depotUrl, depotDetails);
  }

  getUsers(offset, limit): Observable < any > {
    const paramObj: any = {};
    offset !== undefined ? (paramObj.offset = offset) : (offset = null);
    limit ? (paramObj.limit = limit) : (limit = null);
    return this.http.get < User[] > (userUrl, {params: paramObj});
  }

  putUser(userDetail) {
    return this.http.put(userUrl, userDetail);
  }

  createUser(userDetail: any) {
    return this.http.post(userUrl, userDetail);
  }

  // createGateRegister(gateRegisterobj: GateRegister){
  //   return this.http.post(gateRegisterUrl, gateRegisterobj)
  // }
  // getGateRegister(page, limit):Observable<any>{
  //   const paramObj: any = {};
  //   paramObj.page = page;
  //   paramObj.code = this.currentUser.divisionCode;
  //   limit ? paramObj.limit = limit : limit = null;
  //   return this.http.get(gateRegisterUrl, paramObj);
  // }
}
